import { DateReferenceOption } from "stores/ComponentStores/DatePickerComponentStore";
import {
    AgentCoaching,
    AgentCoachingStatus,
} from "../models/AgentModels/AgentCoaching";
import Question from "../models/Question";
import BaseService from "./BaseService";

export interface AgentCoachingMetrics {
    id: string;
    fullName: string;
    level: string;
    levelMember: string;
    completedEvals: number;
    coachedEvals: number;
    moduleScores: Record<string, number>;
}

export interface AgentCoachingResult {
    rows: any[];
    cols: any[];
    details: { label: string; value: string }[];
    // scoredModules: string[];
    // metrics: AgentCoachingMetrics[];
}
export interface AgentEvaluations {
    all: AgentCoachingData[];
    coached: AgentCoachingData[];
    notCoached: AgentCoachingData[];
}
export interface AgentCoachingEvaluationScore {
    avgScore: number;
    evaluationId: string;
    moduleName: string;
}
export interface AgentCoachingData {
    id: string;
    evaluationQbId?: number;
    submittedDate?: string;
    agentCoachingId?: string;
    interactionDate?: string;
    coachedOn?: string;
    agentId?: string;
    coachId?: string;
    improvementNotes?: string;
    agentDoWellNotes?: string;
    focusAreas?: string;
    acknowledged?: boolean;
    responseNotes?: string;
    analystUserName?: string;
    firstName?: string;
    lastName?: string;
    scores?: AgentCoachingEvaluationScore[];
    coachingStatus?: AgentCoachingStatus;
    evalAcknowledged?: boolean;
}

export class AgentCoachingService extends BaseService {
    protected urlBase: string = "api/AgentCoaching";

    public async getAgents(
        startDate: string,
        endDate: string,
        dateReference?: DateReferenceOption,
    ) {
        let body = {
            startDate,
            endDate,
        };

        if (dateReference) {
            body["dateReference"] = dateReference;
        }

        const res = await this.post<AgentCoachingResult>(
            JSON.stringify(body),
            new URLSearchParams({}),
            "Agents",
        );

        return res;
    }

    public async getAgentEvaluations(
        agentId: string,
        startDate: string,
        endDate: string,
        dateReference?: DateReferenceOption,
        isAgentAsUser?: boolean,
        hierarchyIds?: string[],
        showMachineEvals?: boolean,
    ) {
        const params = new URLSearchParams();

        let body = {
            agentId: agentId,
            startDate: startDate,
            endDate: endDate,
            dateReference: dateReference,
            hierarchyIds: hierarchyIds,
            isAgentAsUser: isAgentAsUser?.toString(),
            evalType: showMachineEvals ? undefined : "BottomOfTheFunnel",
        };

        const res = await this.post<AgentEvaluations>(
            JSON.stringify(body),
            params,
            "AgentEvaluations",
        );
        return res;
    }

    public async getMyDrafts(agentId: string) {
        const params = new URLSearchParams({ agentId });
        const drafts = await this.get<AgentCoaching[]>(params, "MyDrafts");
        // Need an actual instance of the AgentCoaching class since we use the methods on it
        // inside of eval review.
        return drafts.map((draft) => Object.assign(new AgentCoaching(), draft));
    }

    public async deleteDrafts(draftIds: string[]) {
        const params = new URLSearchParams();
        return this.delete(
            params,
            "DeleteDrafts",
            JSON.stringify({ draftIds }),
        );
    }

    public async getEvaluationQuestions() {
        const params = new URLSearchParams({});
        const res = await this.get<Question[]>(params, "EvaluationQuestions");
        return res;
    }

    public async createCoachingAsCompleted(agentCoaching: AgentCoaching) {
        agentCoaching.coachingStatus = AgentCoachingStatus.Completed;
        const params = new URLSearchParams();
        const res = await this.post(
            JSON.stringify(agentCoaching),
            params,
            "AgentCoaching",
        );
        return res;
    }

    public async createCoachingAsDraft(agentCoaching: AgentCoaching) {
        agentCoaching.coachingStatus = AgentCoachingStatus.InProgress;
        const params = new URLSearchParams();
        const res = await this.post(
            JSON.stringify(agentCoaching),
            params,
            "AgentCoaching",
        );
        return res;
    }

    public async updateCoachingDraft(agentCoaching: AgentCoaching) {
        return this.put(
            new URLSearchParams(),
            "UpdateAgentCoachingDraft",
            JSON.stringify(agentCoaching),
        );
    }

    public async updateAgentCoaching(agentCoaching: AgentCoaching) {
        const params = new URLSearchParams();
        const res = await this.post(
            JSON.stringify(agentCoaching),
            params,
            "UpdateAgentCoaching",
        );
        return res;
    }

    public async getModuleScoresForEvaluations(evaluationIds: any[]) {
        const params = new URLSearchParams();
        const res = await this.post(
            JSON.stringify({
                evaluationIds: evaluationIds,
            }),
            params,
            "ModuleScoresForEvaluations",
        );
        return res;
    }

    public async getModuleScoreForEvaluations(evaluationIds: any[]) {
        const params = new URLSearchParams();
        const res = await this.post(
            JSON.stringify({
                evaluationIds: evaluationIds,
            }),
            params,
            "ModuleScoreForEvaluations",
        );
        return res;
    }
}
