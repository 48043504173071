import SoundClipEditorStore from "components/SoundClipEditor/Stores/SoundClipEditorStore";
import AcxDataGridStore from "components/UI/AcxDataGrid/AcxDataGridStore";
import { ChromeTab } from "components/UI/SubTabs/ChromeTabs";
import { delay } from "lodash";
import {
    action,
    autorun,
    computed,
    makeObservable,
    observable,
    reaction,
    runInAction,
    toJS,
} from "mobx";
import { computedFn, moveItem } from "mobx-utils";
import { DataProcessingOption } from "models/DataProcesses";
import SoundClip from "models/SoundClip";
import type { Moment } from "moment";
import moment from "moment";
import { SyntheticEvent } from "react";
import { SoundClipService } from "services/SoundClipService";
import { DatePickerComponentStore } from "stores/ComponentStores/DatePickerComponentStore";
import type { IRootStore } from "stores/RootStore";
import { AcxStore } from "stores/RootStore";
import { v4 as uuid } from "uuid";
import MontageModel from "../../../models/MontageModel";
import AudioContextProvider, {
    IdAudioBuffer,
} from "../../../providers/AudioContextProvider";
import { MontageService } from "../../../services/MontageService";
import { BaseStore } from "../../../stores/BaseStore";
import { MontageListStore } from "./MontageListStore";

export const SaveDownloadMontageOp = "Save and Download Montage";
export const LoadRedactedClip = "Download Redacted Sound Clip-";
export const UndoRedactedClip = "Undo Redacted Sound Clip-";

@AcxStore
export class MontageSoundClipStore extends BaseStore {
    readonly datePickerStore = new DatePickerComponentStore();

    @observable orgId: string;

    @observable soundClipDgStore: AcxDataGridStore;
    @observable soundClips: SoundClip[] = [];
    @observable soundClipCount: number = 0;
    @observable showOrgSelector: boolean = true;

    @observable tabIndex: number = 0;
    tabs: ChromeTab[] = [];

    @observable qbEvalIdSearch: string = "";

    @observable filterValue: string;

    @observable loading: boolean = false;
    @observable rowsPerPage: number = 100;
    @observable page: number = 0;

    montageService: MontageService = new MontageService();
    soundClipService: SoundClipService = new SoundClipService();

    @observable hiddenIndex: number = -1;

    audioContextProvider: AudioContextProvider = new AudioContextProvider();
    audioCtx: AudioContext = new AudioContext();
    @observable.ref combinedClip?: SoundClip;
    @observable activeSoundClip?: SoundClip;
    @observable activeIndex: number;
    @observable combinedBlob?: Blob;
    @observable.ref combinedBuffer?: AudioBuffer;
    @observable montage: MontageModel = new MontageModel();

    @observable undoAudioStackMap: Map<
        string,
        { startTime: number; endTime: number }[]
    > = new Map<string, { startTime: number; endTime: number }[]>();

    @observable montageDirectory: string;
    @observable saveDialogOpen: boolean = false;
    @observable redactDrawerOpen: boolean = false;

    silentBuffer: AudioBuffer;
    beepBuffer: AudioBuffer;

    @observable clipsRedactionOptions: Array<{
        clipId: string;
        voiceRedactionFactor: number;
        options: DataProcessingOption[];
    }> = new Array<{
        clipId: string;
        voiceRedactionFactor: number;
        options: DataProcessingOption[];
    }>();
    @observable clipsLoading: Array<{ clipId: string; isLoading: boolean }> =
        new Array<{ clipId: string; isLoading: boolean }>();
    @observable clipsRedacted: Array<string> = observable.array(
        new Array<string>(),
    );
    @observable private toneBuffersSet: Map<
        string,
        Array<{
            type: "silent" | "tone" | undefined;
            audioBuffer: AudioBuffer | null;
            id: string;
        }>
    > = new Map<
        string,
        Array<{
            type: "silent" | "tone" | undefined;
            audioBuffer: AudioBuffer | null;
            id: string;
        }>
    >();
    @observable audioBuffersSet: Map<
        string,
        {
            index: number;
            audioBuffer: AudioBuffer;
            redactedAudioBufer: AudioBuffer | undefined;
        }
    > = new Map<
        string,
        {
            index: number;
            audioBuffer: AudioBuffer;
            redactedAudioBufer: AudioBuffer | undefined;
        }
    >();

    @observable initialAudioBuffersSet: Map<
        string,
        {
            index: number;
            audioBuffer: AudioBuffer;
            redactedAudioBufer: AudioBuffer | undefined;
        }
    > = new Map<
        string,
        {
            index: number;
            audioBuffer: AudioBuffer;
            redactedAudioBufer: AudioBuffer | undefined;
        }
    >();

    @observable viewModels: Map<string, SoundClipEditorStore> = new Map<
        string,
        SoundClipEditorStore
    >();

    constructor(public rootStore: IRootStore) {
        super("MontageSoundClipStore");

        makeObservable(this);

        this.initSoundClipStore();

        autorun(
            async () => {
                const silent = await this.audioContextProvider.fetchAudio([
                    "/Silent.mp3",
                ]);
                if (silent.length > 0) {
                    this.silentBuffer = silent[0];
                }
            },
            {
                delay: 400,
                name: "SilentAudioLoader",
            },
        );

        autorun(
            async () => {
                const beep = await this.audioContextProvider.fetchAudio([
                    "/BeepTone.mp3",
                ]);
                if (beep.length > 0) {
                    this.beepBuffer = beep[0];
                }
            },
            {
                delay: 200,
                name: "BeepAudioLoader",
            },
        );

        reaction(
            (r) => this.orgId,
            (orgId) => {
                this.soundClipDgStore.clearSelected();
                this.soundClipDgStore.reset();
                this.clearAudioBufferAndToneSets();
            },
        );

        reaction(
            () => {
                return {
                    orgid: this.orgId,
                    arg1: this.datePickerStore.beginDate,
                    arg2: this.datePickerStore.endDate,
                    activeLocation: this.rootStore.activeLocation,
                };
            },
            async (params) => {
                if (
                    params.activeLocation &&
                    params.activeLocation.location !== "/app/montagelibrary"
                ) {
                    return;
                }

                await this.getAllClips(
                    this.datePickerStore.beginDate,
                    this.datePickerStore.endDate,
                    this.qbEvalIdSearch,
                );
            },
        );

        reaction(
            () => {
                return { arg3: this.qbEvalIdSearch };
            },
            async () => {
                await this.getAllClips(
                    this.datePickerStore.beginDate,
                    this.datePickerStore.endDate,
                    this.qbEvalIdSearch,
                );
            },
            { delay: 900 },
        );

        this.montageDirectory = moment().format("MMMM-YYYY");
    }

    initSoundClipStore = () => {
        this.soundClipDgStore = new AcxDataGridStore(
            "MontageSoundClipStore",
            "Montage",
        );
        this.soundClipDgStore.removeHeight = "150px";
    };

    @action
    pushUndoStack = (start: number, end: number, id: string) => {
        let existingUndoStack = this.undoAudioStackMap.get(id);
        let startEndObject = { startTime: start, endTime: end };

        if (!existingUndoStack) {
            existingUndoStack = [startEndObject];
        } else {
            existingUndoStack.push(startEndObject);
        }

        this.undoAudioStackMap.set(id, existingUndoStack);
    };

    @action
    popUndoStack = (id: string, index: number) => {
        let currentUndoStack = this.getAudioUndoStack(id);
        currentUndoStack.pop();

        if (!currentUndoStack.length) {
            if (this.combinedBuffer) {
                this.combinedBuffer =
                    this.initialAudioBuffersSet.get(id)!.audioBuffer;
            } else {
                this.audioBuffersSet.set(
                    id,
                    this.initialAudioBuffersSet.get(id)!,
                );
            }
        } else {
            let tempBuffer = { ...this.initialAudioBuffersSet.get(id)! };
            var model = this.viewModels.get(id);

            currentUndoStack.forEach((undoItem) => {
                tempBuffer.audioBuffer = model?.removeUndoSegment(
                    undoItem.startTime,
                    undoItem.endTime,
                    tempBuffer.audioBuffer,
                ) as AudioBuffer;

                //need to be able to undo the redacted buffer too
                if (tempBuffer.redactedAudioBufer !== undefined) {
                    tempBuffer.redactedAudioBufer = model?.removeUndoSegment(
                        undoItem.startTime,
                        undoItem.endTime,
                        tempBuffer.redactedAudioBufer,
                    );
                }
            });

            if (this.combinedBuffer) {
                this.combinedBuffer = tempBuffer.audioBuffer;
            } else {
                this.audioBuffersSet.set(id, tempBuffer);
            }
        }
    };

    getAudioUndoStack = (id: string) => {
        return this.undoAudioStackMap.get(id) ?? [];
    };

    @action
    addViewModel = (viewModel: SoundClipEditorStore, clipId: string) => {
        var obj = this.viewModels.get(clipId);
        if (!obj) {
            this.viewModels.set(clipId, viewModel);
        }
    };

    @action
    setOrgSelectorVisible = (show: boolean) => {
        this.showOrgSelector = show;
    };

    @action removeAudioBufferFromSet(id: string) {
        this.audioBuffersSet.delete(id);
        this.toneBuffersSet.delete(id);
    }

    toneForClip = computedFn(function (
        this: MontageSoundClipStore,
        id: string,
    ) {
        return this.toneBuffersSet
            .get(id)
            ?.map((value) => ({ type: value.type, id: value.id }));
    });

    @action
    clearAudioBufferAndToneSets() {
        this.toneBuffersSet.clear();
        this.audioBuffersSet.clear();
    }

    @action
    removeToneBufferFromSet = (id: string, toneId: string) => {
        const toneBufferElemArray = this.toneBuffersSet.get(id);
        if (toneBufferElemArray) {
            const indx = toneBufferElemArray.findIndex(
                (value) => value.id === toneId,
            );
            toneBufferElemArray.splice(indx, 1);
            this.toneBuffersSet.set(id, toneBufferElemArray);
        }
    };

    @action
    addToneBufferToSet = (
        id: string,
        type: "silent" | "tone",
        toneId: string,
    ) => {
        let toneArry = this.toneBuffersSet.get(id);

        if (toneArry) {
            toneArry.push({
                type: type,
                audioBuffer:
                    type === "silent" ? this.silentBuffer : this.beepBuffer,
                id: toneId,
            });
        } else {
            toneArry = [
                {
                    type: type,
                    audioBuffer:
                        type === "silent" ? this.silentBuffer : this.beepBuffer,
                    id: toneId,
                },
            ];
        }

        this.toneBuffersSet.set(id, toneArry);
    };

    addAudioBufferToSet = (arg: IdAudioBuffer) => {
        var audioBuffer = this.audioBuffersSet.get(arg.id);

        if (audioBuffer) {
            //this case is for drag and drop, need to assign the new index to the buffer object
            if (audioBuffer.index !== arg.index) {
                this.audioBuffersSet.set(arg.id, {
                    index: arg.index,
                    audioBuffer: audioBuffer.audioBuffer,
                    redactedAudioBufer: audioBuffer.redactedAudioBufer,
                });
            }
            //this case is for clip cuts to set the correct buffer
            else {
                var isRedacted = this.clipsRedacted.find((value) => {
                    return value === arg.id;
                })
                    ? true
                    : false;

                this.audioBuffersSet.set(arg.id, {
                    index: arg.index,
                    audioBuffer: isRedacted
                        ? audioBuffer.audioBuffer
                        : arg.audioBuffer,
                    redactedAudioBufer: isRedacted
                        ? arg.audioBuffer
                        : audioBuffer.redactedAudioBufer,
                });
            }
        }
        //this case if to initially set up the audio buffer
        else {
            this.audioBuffersSet.set(arg.id, {
                index: arg.index,
                audioBuffer: arg.audioBuffer,
                redactedAudioBufer: undefined,
            });
        }

        //this is to initially set up the inital buffer
        if (!this.initialAudioBuffersSet.get(arg.id)) {
            this.initialAudioBuffersSet.set(arg.id, {
                index: arg.index,
                audioBuffer: arg.audioBuffer,
                redactedAudioBufer: undefined,
            });
        }

        let toneBufferArray = this.toneBuffersSet.get(arg.id);
        if (!toneBufferArray) {
            toneBufferArray = [];
            this.toneBuffersSet.set(arg.id, toneBufferArray);
        }
    };

    get toneTypeArray() {
        const toneBuffSet = toJS(this.toneBuffersSet);
        const rv = this.soundClipOrderArray.map(
            (value) => toneBuffSet[value] ?? [],
        );

        return rv;
    }

    get soundClipOrderArray(): string[] {
        return [...this.audioBuffersSet.entries()]
            .sort((a, b) => a[1].index - b[1].index)
            .map((value) => value[0]);
    }

    combineBuffers() {
        let orderedBuffers = [...this.audioBuffersSet.entries()]
            .sort((a, b) => a[1].index - b[1].index)
            .map((value) => {
                let isRedacted = this.clipsRedacted.find((clipId) => {
                    return clipId === value[0];
                })
                    ? true
                    : false;

                return [
                    isRedacted
                        ? value[1].redactedAudioBufer
                        : value[1].audioBuffer,
                    ...(this.toneBuffersSet
                        .get(value[0])
                        ?.flatMap((value1) => value1.audioBuffer) ?? []),
                ];
            });

        const buffers: AudioBuffer[] = orderedBuffers
            .flatMap((value) => value)
            .filter((value) => value !== null) as any;
        return this.audioContextProvider.concatBuffers(buffers);
    }

    @computed
    get anyClipsLoading() {
        return this.clipsLoading.some((value) => value.isLoading);
    }

    @action
    setClipLoading = (id: string, isLoading: boolean) => {
        const indx = this.clipsLoading.findIndex(
            (value) => value.clipId === id,
        );

        if (indx >= 0) {
            if (isLoading) {
                this.clipsLoading[indx].isLoading = true;
            } else {
                this.clipsLoading.splice(indx, 1);
            }
        } else {
            if (isLoading) {
                this.clipsLoading.push({ isLoading: true, clipId: id });
            }
        }
    };

    @action
    setClipRedacted = (id: string, isRedacted: boolean) => {
        const indx = this.clipsRedacted.findIndex((value) => value === id);

        if (indx >= 0) {
            if (!isRedacted) {
                this.clipsRedacted.splice(indx, 1);

                let currentUndoStack = this.getAudioUndoStack(id);
                let tmpAudioBuffer = { ...this.audioBuffersSet.get(id) };
                let tmpInitialBuffer = {
                    ...this.initialAudioBuffersSet.get(id),
                };

                var model = this.viewModels.get(id);
                //when undoing a redaction, we need to apply any clip edits that were made to the original buffer
                currentUndoStack.forEach((undoItem) => {
                    //@ts-ignore
                    tmpAudioBuffer.audioBuffer = model?.removeUndoSegment(
                        undoItem.startTime,
                        undoItem.endTime,
                        //@ts-ignore
                        tmpAudioBuffer.audioBuffer as AudioBuffer,
                    );
                });

                //we need to set the redacted buffer back to undefined
                this.audioBuffersSet.set(id, {
                    //@ts-ignore
                    index: tmpAudioBuffer.index as number,
                    //@ts-ignore
                    audioBuffer: tmpAudioBuffer.audioBuffer as AudioBuffer,
                    redactedAudioBufer: undefined,
                });

                this.initialAudioBuffersSet.set(id, {
                    //@ts-ignore
                    index: tmpInitialBuffer.index as number,
                    //@ts-ignore
                    audioBuffer: tmpInitialBuffer.audioBuffer as AudioBuffer,
                    redactedAudioBufer: undefined,
                });

                this.undoRedactedClip(id);
            }
        } else {
            if (isRedacted) {
                this.clipsRedacted.push(id);
            }
        }
    };

    @action resetAll = () => {
        this.resetMontage();
        this.resetRedactionOptions();
        this.clearAudioBufferAndToneSets();
        this.undoAudioStackMap.clear();
        this.soundClipDgStore.clearSelected();
        this.viewModels.clear();
    };

    @action resetMontage = () => {
        this.combinedBlob = undefined;
        this.combinedBuffer = undefined;
        this.combinedClip = undefined;
    };

    @action resetRedactionOptions = () => {
        this.clipsRedactionOptions = [];
        this.clipsRedacted = [];
        this.activeSoundClip = undefined;
    };

    @action removeRedactionOptionsById(soundClipId: string) {
        var index = this.clipsRedactionOptions.findIndex(
            (value) => value.clipId === soundClipId,
        );

        if (index >= 0) this.clipsRedactionOptions.splice(index, 1);
    }

    @action loadRedactedClip = async () => {
        var clipId = (this.activeSoundClip as SoundClip).id;

        await this.setupAsyncTask(LoadRedactedClip + clipId, () =>
            this.downloadAndDecodeRedactedClipInternal(
                clipId,
                this.getVoiceRedactionFactorForSoundClip(),
                this.getRedactOptionsForSoundClip(),
            ),
        );
    };

    @action undoRedactedClip = async (soundClipId: string) => {
        //this is needed so when I undo a redacted it does not break.
        //it seems if that there isnt a re render and we change the audio buffer there is an expection in the plauer
        await this.setupAsyncTask(UndoRedactedClip + soundClipId, async () => {
            const sleep = (delay) =>
                new Promise((resolve) => setTimeout(resolve, delay));
            await sleep(250);
        });
    };

    @action saveMontage = async () => {
        await this.setupAsyncTask(SaveDownloadMontageOp, () =>
            this.saveMontageInternal(),
        );
    };

    @action closeSaveDialog = () => {
        this.saveDialogOpen = false;
    };

    @action openSaveDialog = () => {
        this.saveDialogOpen = true;
    };

    @action
    setShowRedactDrawer = (isVisible: boolean) => {
        this.redactDrawerOpen = isVisible;
    };

    private downloadAndDecodeRedactedClipInternal = async function (
        this: MontageSoundClipStore,
        soundClipId: string,
        voiceRedactionFactor: number,
        dataProcessingOptions: DataProcessingOption[],
    ) {
        try {
            var arrayBuffer = await this.montageService.streamRedactedSoundClip(
                voiceRedactionFactor,
                this.orgId,
                soundClipId,
                dataProcessingOptions,
            );

            if (arrayBuffer.byteLength > 0) {
                var audioBuffer = await this.audioCtx.decodeAudioData(
                    arrayBuffer,
                );

                const res = this.audioContextProvider.export(audioBuffer!);

                var clipObj = this.audioBuffersSet.get(soundClipId);
                var initialObj = this.initialAudioBuffersSet.get(soundClipId);

                if (clipObj && initialObj) {
                    //set the initial buffer
                    this.initialAudioBuffersSet.set(soundClipId, {
                        index: initialObj.index,
                        audioBuffer: initialObj.audioBuffer,
                        redactedAudioBufer: res.audioBuffer,
                    });

                    let currentUndoStack = this.getAudioUndoStack(soundClipId);

                    if (currentUndoStack.length) {
                        let tempBuffer = {
                            ...this.initialAudioBuffersSet.get(soundClipId)!,
                        };

                        var model = this.viewModels.get(soundClipId);
                        //apply clip edits to the newly redacted clip
                        currentUndoStack.forEach((undoItem) => {
                            tempBuffer.redactedAudioBufer =
                                model?.removeUndoSegment(
                                    undoItem.startTime,
                                    undoItem.endTime,
                                    tempBuffer.redactedAudioBufer as AudioBuffer,
                                );
                        });

                        this.audioBuffersSet.set(soundClipId, {
                            index: clipObj.index as number,
                            audioBuffer: clipObj.audioBuffer as AudioBuffer,
                            redactedAudioBufer: tempBuffer.redactedAudioBufer,
                        });
                    } else {
                        this.audioBuffersSet.set(soundClipId, {
                            index: clipObj.index as number,
                            audioBuffer: clipObj.audioBuffer as AudioBuffer,
                            redactedAudioBufer: res.audioBuffer,
                        });
                    }

                    this.setClipRedacted(soundClipId, true);
                }
            }
        } catch (err) {
            console.error(
                `MontageSoundClipStore failed to download redacted clip: ${err}`,
            );

            throw err;
        }
    };

    private saveMontageInternal = async function (this: MontageSoundClipStore) {
        if (this.combinedBuffer) {
            const toneArray: any[] = this.toneTypeArray.map((v) =>
                v.map((k) => k.type),
            );
            const orderArray: string[] = this.soundClipOrderArray;

            await new Promise((resolve, reject) => {
                this.audioContextProvider.exportMP3(
                    this.combinedBuffer!,
                    async (error, blob) => {
                        if (error) {
                            reject(error);
                        } else if (blob) {
                            try {
                                const newMontage =
                                    await this.montageService.createMontage(
                                        this.orgId,
                                        this.montage.name,
                                        this.montageDirectory,
                                        blob,
                                        orderArray,
                                        toneArray,
                                    );

                                const montageListStore =
                                    this.rootStore.getStore(MontageListStore);

                                montageListStore.dgStore.clearSelected();

                                this.onTabChange(2);

                                await montageListStore.getAllMontages(
                                    montageListStore.datePickerStore
                                        .offsetBeginDate,
                                    montageListStore.datePickerStore
                                        .offsetEndDate,
                                    newMontage.id,
                                );

                                resolve(blob);

                                this.montage.name = "";
                                this.closeSaveDialog();

                                runInAction(() => {
                                    this.resetMontage();
                                });
                            } catch (error) {
                                reject(error);
                            }
                        }
                    },
                );
            });
        }
    };

    @action insertTone = (id: string, type: "tone" | "silent") => {
        this.addToneBufferToSet(id, type, uuid());
    };

    @action downloadMontage = (blob?: Blob) => {
        if (this.combinedBlob && this.montage.name) {
            this.audioContextProvider.download(
                blob ?? this.combinedBlob,
                this.montage.name,
            );
        }
    };

    @action setMontageName = (evt: SyntheticEvent<HTMLInputElement>) => {
        this.montage.name = evt.currentTarget.value;
    };

    @action setMontageDirectoryName = (
        evt: SyntheticEvent<HTMLInputElement>,
    ) => {
        this.montageDirectory = evt.currentTarget.value;
    };

    @action
    setActiveSoundClip = (soundclip: SoundClip, index: number) => {
        this.activeSoundClip = soundclip;
        this.activeIndex = index;
    };

    @action
    generateMontage = () => {
        const res = this.audioContextProvider.export(this.combineBuffers());
        this.combinedBlob = res.blob;
        this.combinedBuffer = res.audioBuffer;
        const combinedClip = new SoundClip();
        combinedClip.id = `${Math.random()}_combinedClip_Montage`;

        this.initialAudioBuffersSet.set(combinedClip.id, {
            index: 0,
            audioBuffer: this.combinedBuffer,
            redactedAudioBufer: undefined,
        });

        const selectedAr = this.soundClipDgStore.getSelectedRows();

        combinedClip.tags = selectedAr
            .filter((value) => value.tags)
            .map((value) => value.tags)
            .join(",,");

        combinedClip.note = selectedAr
            .filter((value) => value.note)
            .map((value) => value.note)
            .join("; ");

        combinedClip.transcriptionText = selectedAr
            .filter((value) => value.transcriptionText)
            .map((value) => value.transcriptionText)
            .join("; ");

        combinedClip.endTime = res.audioBuffer.duration;
        combinedClip.startTime = 0;
        combinedClip.segmentName = "Montage";

        this.combinedClip = combinedClip;
    };

    @action
    removeSelectedClipById = (clipId: string) => {
        this.soundClipDgStore.removeSelectedById(clipId);
        this.removeAudioBufferFromSet(clipId);
        this.removeRedactionOptionsById(clipId);
        this.viewModels.delete(clipId);
    };

    @action
    moveCard = (dragIndex: number, hoverIndex: number) => {
        const selectedAr = this.soundClipDgStore.SelectedRows;
        const dragId = selectedAr[dragIndex]?.id?.toString();
        const hoverId = selectedAr[hoverIndex]?.id?.toString();
        moveItem(selectedAr as any, dragIndex, hoverIndex);

        setTimeout(() => {
            if (dragId) {
                const currObj = this.audioBuffersSet.get(dragId);
                if (currObj) {
                    this.addAudioBufferToSet({
                        id: dragId,
                        index: hoverIndex,
                        audioBuffer: currObj.audioBuffer,
                    });
                }
            }

            if (hoverId) {
                const currObj = this.audioBuffersSet.get(hoverId);
                if (currObj) {
                    this.addAudioBufferToSet({
                        id: hoverId,
                        index: dragIndex,
                        audioBuffer: currObj.audioBuffer,
                    });
                }
            }
        }, 60);
    };

    @action
    onStartBuildMontage = () => {
        this.clearAudioBufferAndToneSets();
        this.resetRedactionOptions();
        this.undoAudioStackMap.clear();
        this.viewModels.clear();

        this.tabIndex = 1;
    };

    @action
    updateFilterValue(value: string) {
        this.filterValue = value;
    }

    @action
    onTabChange = (newValue: number) => {
        this.tabIndex = newValue;
    };

    @action
    onSoundClipRedactOptionsChange = (
        processingOptions: DataProcessingOption[],
    ) => {
        const indx = this.clipsRedactionOptions.findIndex(
            (value) => value.clipId === this.activeSoundClip?.id,
        );

        if (indx >= 0) {
            this.clipsRedactionOptions[indx].options = processingOptions;
        } else {
            this.clipsRedactionOptions.push({
                clipId: this.activeSoundClip?.id as string,
                voiceRedactionFactor: 0,
                options: processingOptions,
            });
        }
    };

    @action
    onVoiceRedactionFactorChange = (factor: number) => {
        const indx = this.clipsRedactionOptions.findIndex(
            (value) => value.clipId === this.activeSoundClip?.id,
        );

        if (indx >= 0)
            this.clipsRedactionOptions[indx].voiceRedactionFactor = factor;
        else {
            this.clipsRedactionOptions.push({
                clipId: this.activeSoundClip?.id as string,
                voiceRedactionFactor: factor,
                options: [],
            });
        }
    };

    @action
    async getAllClips(
        bDate: Moment,
        eDate: Moment,
        qbEvalId: string,
    ) {
        this.loading = true;
        this.soundClipDgStore.isLoading = true;
        this.soundClips.splice(0, this.soundClips.length);

        try {
            const clips = await this.soundClipService.getAllSoundClips(
                bDate,
                eDate,
                qbEvalId,
            );

            runInAction(() => {
                this.soundClips.splice(0, this.soundClips.length);
                this.soundClips = [...clips];
                this.soundClipDgStore.rows = clips;
                this.loading = false;
                delay(() => (this.soundClipDgStore.isLoading = false), 100);
            });
        } catch (error) {
            this.loading = false;
            this.soundClipDgStore.isLoading = false;
        }
    }

    @action
    getRedactOptionsForSoundClip = () => {
        var index = this.clipsRedactionOptions.findIndex(
            (value) => value.clipId === this.activeSoundClip?.id,
        );

        if (index >= 0) return this.clipsRedactionOptions[index].options;
        else return [];
    };

    @action
    getVoiceRedactionFactorForSoundClip = () => {
        var index = this.clipsRedactionOptions.findIndex(
            (value) => value.clipId === this.activeSoundClip?.id,
        );

        if (index >= 0)
            return this.clipsRedactionOptions[index].voiceRedactionFactor;
        else return 0;
    };
}
