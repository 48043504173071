import React from "react";
import { Grid, styled } from "@mui/material";
import { MessageAuthor } from "./Models/MessageAuthor";
import { AgentAvatar } from "./Branding/AgentAvatar";
import { UserAvatar } from "./Branding/UserAvatar";
import AcxButton from "components/UI/AcxButton";
import Message from "./Messages/Message";
import { useStore } from "utils/useStore";
import AgentChatStore from "./AgentChatStore";
import { observer } from "mobx-react";
import { MakeAChoiceMessage } from "./Messages/ActionMessages/MakeAChoice";
import { IThreadMessageGroup } from "./Models/ThreadMessageGroup";

type ThreadMessageGroupProps = {
    isMostRecent: boolean;
} & IThreadMessageGroup;

const SuggestionActionButton = styled(AcxButton)(({ theme }) => ({
    paddingInline: theme.spacing(2),
    paddingBlock: theme.spacing(1),
    border: "1px solid",
    borderColor: theme.palette.primary[500],
    color: theme.palette.primary[500],
    backgroundColor: theme.palette.white.main,
    borderRadius: "9999px",
    height: "min-content",
    textWrap: "wrap",
    textAlign: "start",
    ":hover": {
        backgroundColor: theme.palette.primary[100],
    },
}));

export const ThreadMessageGroup = observer((props: ThreadMessageGroupProps) => {
    const store = useStore(AgentChatStore);

    const isUserMessage = props.author === MessageAuthor.User;
    const isAgentMessage = props.author === MessageAuthor.Agent;

    return (
        <Grid
            container
            item
            direction="column"
            flexWrap="nowrap"
            gap={1}
            alignSelf={isUserMessage ? "self-end" : "self-start"}
            width="300px"
        >
            <Grid
                container
                item
                alignItems="flex-end"
                flexWrap="nowrap"
                gap={1}
            >
                {isAgentMessage && (
                    <Grid item>
                        <AgentAvatar />
                    </Grid>
                )}
                <Grid
                    container
                    item
                    direction="column"
                    rowGap={1}
                    flexWrap="nowrap"
                    alignItems={isUserMessage ? "flex-end" : "flex-start"}
                >
                    {props.messages.map((message, index) => (
                        <Message
                            key={message.id}
                            author={props.author}
                            {...message}
                        />
                    ))}
                    {props.isMostRecent && props.transientMessage && (
                        <Message
                            key={props.transientMessage.id}
                            author={props.author}
                            {...props.transientMessage}
                        />
                    )}
                    {props.actions && (
                        <React.Fragment>
                            {!!props.actions.MakeAChoice && (
                                <MakeAChoiceMessage
                                    choices={props.actions.MakeAChoice}
                                />
                            )}
                        </React.Fragment>
                    )}
                </Grid>
                {isUserMessage && (
                    <Grid item>
                        <UserAvatar />
                    </Grid>
                )}
            </Grid>
            {props.suggestions && props.isMostRecent && (
                <Grid
                    className={"pendo-ignore"}
                    container
                    item
                    alignItems="flex-end"
                    flexWrap="nowrap"
                    gap={1}
                    direction={isAgentMessage ? "row" : "row-reverse"}
                >
                    <Grid item width="28px"></Grid>
                    <Grid
                        container
                        item
                        direction="column"
                        gap={1}
                        flexWrap="nowrap"
                        alignItems={isUserMessage ? "flex-end" : "flex-start"}
                    >
                        {props.suggestions.map((suggestion) => (
                            <Grid item>
                                <SuggestionActionButton
                                    leftRightSpacing={0}
                                    fullWidth={false}
                                    onClick={() =>
                                        store.handleSuggestion(suggestion)
                                    }
                                >
                                    {suggestion.content}
                                </SuggestionActionButton>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
});
