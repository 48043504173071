import { Divider, Typography } from "@mui/material";
import AcxMainTextField from "components/UI/AcxMainTextField";
import AcxSelectSingle from "components/UI/Select/BaseSelectComponents/AcxSelectSingle";
import { AgentModel } from "models/AgentModel";
import { FieldFormatter, MetadataSpec } from "models/MetadataSpec";
import React from "react";
import theme from "Theme/AppTheme";
import { isUndefinedType } from "utils/TypeGuards";
import { buildAgentFormatterFromType } from "./ColumnMap";

export type AgentNameFormatterType =
    | "AgentCode"
    | "FirstName LastName"
    | "LastName, FirstName"
    | "LastName,FirstName"
    | "LastName FirstInitial"
    | "unknown"
    | "AgentEmail";

export const agentNameFormatters: AgentFormatterOption[] = [
    { id: "LastName FirstInitial", value: "LastName FirstInitial" },
    { id: "LastName, FirstName", value: "LastName, FirstName" },
    { id: "LastName,FirstName", value: "LastName,FirstName" },
    { id: "FirstName LastName", value: "FirstName LastName" },
    { id: "AgentCode", value: "AgentCode" },
    { id: "AgentEmail", value: "AgentEmail" },
];

export type AgentFormatterOption = {
    id: AgentNameFormatterType;
    value: AgentNameFormatterType;
};

export function generateFormatterFromAgentField(
    agentField: (MetadataSpec.FieldType & FieldFormatter<any>) | undefined,
) {
    let formatter: ((arg: AgentModel) => string) | undefined = undefined;
    let formatterType: AgentNameFormatterType | undefined = undefined;

    if (!isUndefinedType(agentField)) {
        const agentFormat: AgentNameFormatterType =
            agentField?.format as AgentNameFormatterType;
        const aFmtOption = agentNameFormatters.find(
            (a) => a.id === agentFormat,
        );
        formatter = buildAgentFormatterFromType(aFmtOption);
        formatterType = agentFormat;
    }
    return [formatter, formatterType] as const;
}

interface IFormatOption {
    format: string | AgentFormatterOption;
    onChange: (format: string | AgentFormatterOption | undefined) => void;
}

export function AgentFormatOption(props: IFormatOption) {
    const [format, setFormat] = React.useState<
        AgentFormatterOption | undefined
    >(props.format as AgentFormatterOption);
    const onSelect = (arg: AgentFormatterOption) => {
        const afmt = agentNameFormatters.find((a) => a.id === arg.id);
        setFormat(afmt);
        props.onChange?.(afmt);
    };

    return (
        <AcxSelectSingle
            // inputLabel={"Agent Formatter"}
            // isDisabled={props.disabled}
            defaultValue={agentNameFormatters.find(
                (value) => value.id === format?.id,
            )}
            id={"SamplerAgentNameFormatter"}
            labelField={"value"}
            onChange={onSelect}
            valueField={"id"}
            options={agentNameFormatters}
            enableNoSelection
        />
    );
    // return <AcxMainTextField value={format} id="column-agent-fmt" />;
}

export function DateFormatOption(props: IFormatOption) {
    const [formatValue, setFormatValue] = React.useState<string>(
        props.format as string,
    );

    const handleDate = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const val = evt.target.value;
        setFormatValue(val);
        props.onChange(val);
        // const label = col?.label;
        // if (fmt === "") {
        //     fmt = ParseFormat(label);
        // }
        // const m = moment(label, fmt);
        // setOutput(m.format("LL"));
        // setFormat(fmt.toString());
        // doChange(col, fmt.toString());
    };
    return (
        <AcxMainTextField
            onChange={handleDate}
            value={formatValue}
            id="column-dt-fmt"
            helpPopperText={<DateFormatHelperText />}
        />
    );
}

export const DateFormatHelperText = () => {
    return (
        <div style={{ maxWidth: "300px", padding: theme.spacing(1) }}>
            <Typography variant="body2">Examples:</Typography>
            <Typography variant="h3">
                20200518 (yyyyMMdd)
                <br />
                2020-05-18 (yyyy-MM-dd)
                <br />
                20200518083047 (yyyyMMddhhmmss)
                <br />
            </Typography>
            <Divider />
            <Typography variant="body2">Details:</Typography>
            <Typography variant="h3">
                Year:
                <br />
                yy 20
                <br />
                yyyy 2020
                <br />
            </Typography>
            <Typography variant="h3">
                Month:
                <br />
                M 1-12
                <br />
                MM 01-12
                <br />
                MMM Month abbrev Aug
                <br />
            </Typography>
            <Typography variant="h3">
                Day:
                <br />
                d 1-31
                <br />
                dd 01-31
                <br />
            </Typography>
            <Typography variant="h3">
                Hours:
                <br />
                h 1..12
                <br />
                hh 01..12
                <br />
                H 0..23
                <br />
                HH 00..23
                <br />
            </Typography>
            <Typography variant="h3">
                Minutes - always lowercase
                <br />
                m 0..59
                <br />
                mm 00..59
                <br />
            </Typography>
            <Typography variant="h3">
                Seconds - always lowercase
                <br />
                s 0..59
                <br />
                ss 00..59
                <br />
            </Typography>
        </div>
    );
};

const FormatOptions = () => {
    // if (props.rowLabel === "Call Direction") {
    //     return "";
    // } else if (props.rowLabel === "Interaction Date") {
    //     return <DateFormatOption />;
    //     // return <DateFormatInput />
    // } else if (props.rowLabel === "Agent Name") {
    //     return <AcxMainTextField value={format} id="column-agent-fmt" />;
    // } else if (props.rowLabel === "Filename") {
    //     return "";
    // } else {
    //     return "";
    // }
    return <></>;
};
export default FormatOptions;
