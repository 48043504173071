import theme from "Theme/AppTheme";
import AcxChip from "components/UI/AcxChip";
import React from "react";

export default function BetaChip() {
    return (
        <AcxChip
            size="small"
            color="green"
            label="Beta"
            style={{
                backgroundColor: "transparent",
                border: "1px solid " + theme.palette.green.main,
                marginLeft: "6px",
            }}
        />
    );
}
