import { Box, Grid, Popover, TextField } from "@mui/material";
import ScissorsSvg from "SvgIcons/ScissorsSvg";
import theme from "Theme/AppTheme";
import AcxButton from "components/UI/AcxButton";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import SoundClipEditorStore, {
    CreateClipTask,
    UpdateClipTask,
} from "../Stores/SoundClipEditorStore";
import {
    formatHmsToSeconds,
    formatSecondsToHms,
    validateHmsFormat,
} from "../utils";
import { styled } from "@material-ui/core";

const TextFieldInput = styled(TextField)({
    border: "1px solid #F6B100",
    borderRadius: theme.spacing(0.5),
    background: "#FCF1C5",
    width: "67px",
    height: theme.spacing(4.5),
});

interface TimeRangePopoverProps {
    viewModel: SoundClipEditorStore;
}

const TimeRangePopover: React.FC<TimeRangePopoverProps> = observer(
    ({ viewModel }) => {
        const { popoverX: x, canvasRect, popoverOpen: open } = viewModel;
        const { startTime, endTime } = viewModel.latestSegment ?? {};
        const invert = (canvasRect?.top ?? 0) < window.innerHeight / 2;

        const [startTimeHms, setStartTimeHms] = useState<string>(
            formatSecondsToHms(startTime) ?? "00:00",
        );
        const [endTimeHms, setEndTimeHms] = useState<string>(
            formatSecondsToHms(endTime) ?? "00:00",
        );
        const [timeError, setTimeError] = useState({
            start: false,
            end: false,
        });

        const onStartChange = (time: string) => {
            setStartTimeHms(time);
            const isBeforeEndTime =
                formatHmsToSeconds(time) < formatHmsToSeconds(endTimeHms);
            if (!validateHmsFormat(time) || !isBeforeEndTime) {
                setTimeError({ ...timeError, start: true });
            } else {
                viewModel.setLatestSegmentStartTime(formatHmsToSeconds(time));
                setTimeError({ ...timeError, start: false });
            }
        };

        const onEndChange = (time: string) => {
            setEndTimeHms(time);
            const isAfterStartTime =
                formatHmsToSeconds(startTimeHms) < formatHmsToSeconds(time);
            const isLessThanTotalDuration =
                viewModel.clipAudioBuff &&
                formatHmsToSeconds(time) <= viewModel.clipAudioBuff.duration;
            if (
                !validateHmsFormat(time) ||
                !isAfterStartTime ||
                !isLessThanTotalDuration
            ) {
                setTimeError({ ...timeError, end: true });
            } else {
                viewModel.setLatestSegmentEndTime(formatHmsToSeconds(time));
                setTimeError({ ...timeError, end: false });
            }
        };

        const onStartKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (
            e,
        ) => {
            if (!validateHmsFormat(startTimeHms)) return;
            if (e.key === "Enter") {
                e.preventDefault();
                handleClick();
                return;
            }
            const seconds = formatHmsToSeconds(startTimeHms);
            if (
                e.key === "ArrowUp" &&
                seconds + 1 < formatHmsToSeconds(endTimeHms)
            ) {
                e.preventDefault();
                onStartChange(formatSecondsToHms(seconds + 1));
            }
            if (e.key === "ArrowDown" && seconds - 1 >= 0) {
                e.preventDefault();
                onStartChange(formatSecondsToHms(seconds - 1));
            }
        };

        const onEndKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (
            e,
        ) => {
            if (!validateHmsFormat(endTimeHms)) return;
            if (e.key === "Enter") {
                e.preventDefault();
                handleClick();
                return;
            }
            const seconds = formatHmsToSeconds(endTimeHms);
            if (e.key === "ArrowUp" && viewModel.clipAudioBuff) {
                e.preventDefault();
                onEndChange(
                    formatSecondsToHms(
                        seconds + 1 <= viewModel.clipAudioBuff.duration
                            ? seconds + 1
                            : viewModel.clipAudioBuff.duration,
                    ),
                );
            }
            if (
                e.key === "ArrowDown" &&
                seconds - 1 > formatHmsToSeconds(startTimeHms)
            ) {
                e.preventDefault();
                onEndChange(formatSecondsToHms(seconds - 1));
            }
        };

        useEffect(() => {
            if (startTime) {
                setStartTimeHms(formatSecondsToHms(startTime));
            }
            if (endTime) {
                setEndTimeHms(formatSecondsToHms(endTime));
            }
        }, [endTime, startTime]);

        if (!startTime || !endTime) return null;

        const top = invert
            ? Number(canvasRect?.y ?? 0) + (canvasRect?.height ?? 0) - 12
            : Number(canvasRect?.y ?? 0) - (canvasRect?.height ?? 0) - 4;
        const left = Number(x ?? 0) - 100;

        const disabled =
            viewModel.isMaxClipTimeExceeded ||
            viewModel.getTaskLoading(CreateClipTask) ||
            viewModel.getTaskLoading(UpdateClipTask) ||
            timeError.start ||
            timeError.end;

        const handleClick = () => {
            if (disabled) return;
            viewModel.isExistingClip
                ? viewModel.canUpdateClips &&
                  viewModel.updateLatestClipFromSegment()
                : viewModel.createClipFromSegment();
            viewModel.setPopoverOpen(false);
        };

        return (
            <Popover
                open={open}
                onClose={() => {
                    viewModel.setPopoverOpen(false);
                }}
                anchorPosition={{ left, top }}
                elevation={0}
                anchorReference="anchorPosition"
                PaperProps={{
                    style: {
                        padding: theme.spacing(2),
                        backgroundColor: "transparent",
                    },
                }}
                TransitionProps={{
                    onExited: () => {
                        viewModel.removeHighlightFromSelectedSegment();
                        viewModel.unsetSelectedSegmentId();
                        viewModel.closeSegmentMenu();
                    },
                }}
            >
                <Box
                    sx={{
                        backgroundColor: theme.palette.white.main,
                        position: "relative",
                        border: `${theme.palette.gray[200]} 2px solid`,
                        borderRadius: "8px",
                        "&::before": viewModel.showZoomView
                            ? {}
                            : {
                                  backgroundColor: "white",
                                  content: '""',
                                  display: "block",
                                  position: "absolute",
                                  width: 12,
                                  height: 12,
                                  top: invert ? -7 : 96,
                                  transform: "rotate(45deg)",
                                  left: "calc(50% - 6px)",
                                  borderBottom: invert
                                      ? "none"
                                      : `${theme.palette.gray[200]} 2px solid`,
                                  borderRight: invert
                                      ? "none"
                                      : `${theme.palette.gray[200]} 2px solid`,
                                  borderTop: !invert
                                      ? "none"
                                      : `${theme.palette.gray[200]} 2px solid`,
                                  borderLeft: !invert
                                      ? "none"
                                      : `${theme.palette.gray[200]} 2px solid`,
                              },
                    }}
                >
                    <Grid
                        container
                        style={{
                            width: `165px`,
                            height: `100px`,
                        }}
                    >
                        <Grid
                            item
                            container
                            justifyContent="space-around"
                            alignItems="center"
                        >
                            <Grid
                                item
                                style={{ marginLeft: theme.spacing(0.5) }}
                            >
                                <TextFieldInput
                                    id="clip-start-time"
                                    error={timeError.start}
                                    label=""
                                    autoComplete="off"
                                    placeholder="Start"
                                    value={startTimeHms}
                                    type="text"
                                    onKeyDown={onStartKeyDown}
                                    onChange={(e) => {
                                        onStartChange(e.target.value);
                                    }}
                                    variant="standard"
                                    InputProps={{
                                        disableUnderline: true,
                                        style: {
                                            height: theme.spacing(4.5),
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            textAlign: "center",
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item>-</Grid>
                            <Grid
                                item
                                style={{
                                    marginRight: theme.spacing(0.5),
                                }}
                            >
                                <TextFieldInput
                                    id="clip-end-time"
                                    error={timeError.end}
                                    label=""
                                    autoComplete="off"
                                    placeholder="End"
                                    value={endTimeHms}
                                    type="text"
                                    onKeyDown={onEndKeyDown}
                                    onChange={(e) => {
                                        onEndChange(e.target.value);
                                    }}
                                    variant="standard"
                                    InputProps={{
                                        disableUnderline: true,
                                        style: {
                                            height: theme.spacing(4.5),
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            textAlign: "center",
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            justifyContent="space-around"
                            alignItems="center"
                        >
                            <AcxButton
                                variant="text"
                                sx={{
                                    color: theme.palette.text.primary,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.primary[50],
                                        color: theme.palette.primary.main,
                                    },
                                }}
                                onClick={handleClick}
                                buttonDisabled={disabled}
                            >
                                {!viewModel.isExistingClip && (
                                    <ScissorsSvg color="currentColor" />
                                )}
                                {viewModel.isExistingClip ? "Update" : "Clip"}
                            </AcxButton>
                        </Grid>
                    </Grid>
                </Box>
            </Popover>
        );
    },
);

export default TimeRangePopover;
