import { Button, Grid, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import clsx from "clsx";
import FocusPageNotificationMenuIcon from "components/Notifications/FocusPageNotificationMenuIcon";
import React from "react";
import { useNavigate } from "react-router-dom";
import { BarLoader } from "react-spinners";
import useStyles from "Styles/Styles";
import logo from "../../authenticx-green.png";
import AcxButton from "../../components/UI/AcxButton";
import theme from "../../Theme/AppTheme";
import { useResizeObserver } from "../../utils/useResizeObserver";
// import hexToRGB from "utils/hexToRGB"

const styles = (theme: Theme) =>
    createStyles({
        FocusNavBar: {
            height: 64,
            backgroundColor: theme.palette.primary.main,
            zIndex: theme.zIndex.drawer + 1,
            position: "relative",
        },
        horizontalSpacing: {
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
            height: 30,
            cursor: "pointer",
        },
        Button: {
            backgroundColor: "#5d83dd",
            width: "81px",
            height: "1.86rem",
            paddingTop: "4px",
            paddingBottom: "4px",
            color: theme.palette.white.main,
            lineHeight: "20px",
            fontSize: "13px",
            textTransform: "none",
            fontWeight: theme.typography.fontWeightBold as any,
        },
        SaveButton: {
            backgroundColor: theme.palette.primary.main,
            width: "81px",
            height: "28px",
            paddingTop: "4px",
            paddingBottom: "4px",
            color: theme.palette.white.main,
            lineHeight: "20px",
            fontSize: "13px",
            textTransform: "none",
            fontWeight: theme.typography.fontWeightBold as any,
        },
    });

type Props = {
    title: string;
    onCancelClick?: () => void;
    onSaveClick?: () => Promise<void>;
    onSaveAsClick?: () => void;
    showSave?: boolean;
    showSaveAs?: boolean;
    saveAsText?: string;
    saveText?: string;
    cancelText?: string;
    hideCancelButton?: boolean;
    loading?: boolean;
    saveTooltip?: string;
    saveAsTooltip?: string;
};

function FocusNavBar(props: Props) {
    const classes = useStyles(styles);
    const navigate = useNavigate();

    const {
        elementHeight: focusPageBarHeight,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        elementWidth,
        theRef,
    } = useResizeObserver<HTMLDivElement>({
        name: "focusPageNavToolbar",
        pixelDelta: 1,
        debounceWait: 50,
    });

    function renderCancelButton() {
        if (props.onCancelClick) {
            return (
                <Grid item key={"cancel-button"}>
                    <Button
                        variant="contained"
                        disableElevation
                        className={clsx(
                            classes.Button,
                            classes.horizontalSpacing,
                        )}
                        onClick={props.onCancelClick}
                    >
                        {props.cancelText ?? "Done"}
                    </Button>
                </Grid>
            );
        }
    }

    function renderSaveButton() {
        if (props.onSaveClick && props.showSave) {
            return (
                <Grid item key={"save-button"} style={{ marginRight: "20px" }}>
                    <AcxButton
                        color={"primary"}
                        leftRightSpacing={0}
                        loading={props.loading}
                        onClick={props.onSaveClick}
                        tooltip={
                            props.saveTooltip ?? "Save changes to this report"
                        }
                    >
                        {props.saveText ?? "Save"}
                    </AcxButton>
                </Grid>
            );
        }
        return false;
    }

    function renderSaveAsFilterButton() {
        if (props.onSaveAsClick && props.showSaveAs) {
            return (
                <Grid item key={"save-as-button"}>
                    <Grid
                        item
                        xs={12}
                        container
                        spacing={2}
                        style={{
                            marginRight: "16px",
                            paddingTop: "8px",
                        }}
                    >
                        <AcxButton
                            buttonDisabled={false}
                            tooltip={
                                props.saveAsTooltip ??
                                "Save new report from existing"
                            }
                            color={"inherit"}
                            size={"large"}
                            onClick={props.onSaveAsClick}
                            fullWidth={false}
                            leftRightSpacing={0}
                            customRootStyles={{
                                color: "black",
                                backgroundColor: "white",
                            }}
                        >
                            {props.saveAsText ?? "Save As"}
                        </AcxButton>
                    </Grid>
                </Grid>
            );
        }
        return false;
    }

    const onLogoClick = () => {
        navigate(-1);
    };

    return (
        <Grid
            ref={theRef}
            className={classes.FocusNavBar}
            container
            justifyContent="space-between"
            alignItems="center"
            wrap={"nowrap"}
        >
            <Grid container item xs={6} wrap={"nowrap"} alignItems={"stretch"}>
                <Grid item>
                    <img
                        className={classes.horizontalSpacing}
                        alt=""
                        style={{ marginRight: "20px" }}
                        onClick={onLogoClick}
                        src={logo}
                    />
                </Grid>
                <Grid item zeroMinWidth>
                    <Typography
                        sx={{
                            color: theme.palette.white.main,
                            fontSize: 20,
                            lineHeight: "28px",
                        }}
                    >
                        {props.title}
                    </Typography>
                    <BarLoader
                        color={theme.palette.primary.main}
                        loading={props.loading ?? false}
                    />
                </Grid>
            </Grid>
            <Grid container item xs={6} justifyContent={"flex-end"}>
                {[
                    <Grid
                        item
                        key={"notification-icon-button"}
                        style={{
                            marginRight: props.hideCancelButton
                                ? "16px"
                                : "8px",
                        }}
                    >
                        <FocusPageNotificationMenuIcon
                            drawerOffsetPx={focusPageBarHeight}
                        />
                    </Grid>,
                    props.hideCancelButton ? null : renderCancelButton(),
                    props.showSaveAs ? renderSaveAsFilterButton() : null,
                    renderSaveButton(),
                ]}
            </Grid>
        </Grid>
    );
}

export default FocusNavBar;
