import { Grid, Typography } from "@mui/material";
import { Question } from "components/Admin/Organizations/types/Module.type";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import AcxButton from "components/UI/AcxButton";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import AcxMainTextField from "components/UI/AcxMainTextField";
import AcxSelectSingle from "components/UI/Select/BaseSelectComponents/AcxSelectSingle";
import React from "react";
import QuestionMetadataService, {
    QuestionMetadataRequest,
} from "services/QuestionMetadataService";
import { useStore } from "utils/useStore";

const selectCustomStyle = {
    menuPortal: (provided) => ({ ...provided, zIndex: 10000 }),
};

interface OrganizationModuleQuestionMetadataFormProps {
    organizationId?: string;
    question?: Question;
}

const OrganizationModuleQuestionMetadataForm = (
    props: OrganizationModuleQuestionMetadataFormProps,
) => {
    const service = new QuestionMetadataService();
    const messageStore = useStore(MessageStore);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [metadataFields, setMetadataFields] = React.useState<any[]>([]);
    const [selectedMetadataField, setSelectedMetadataField] =
        React.useState<any>();
    const [questionsMetadata, setQuestionsMetadata] = React.useState<any[]>([]);
    const [metadataValueTagMap, setMetadataValueTagMap] = React.useState<any[]>(
        [],
    );

    const createMetadataValueTagMapFromResponse = (res) => {
        let tempArr = [] as any;
        for (const key in res) {
            tempArr.push({
                metaValue: key,
                tagId: res[key],
            });
        }
        return tempArr;
    };

    React.useEffect(() => {
        const getMetadataFields = async () => {
            setLoading(true);
            const metadataFieldResponse = await service.getMetadataFields(

            );
            setMetadataFields(metadataFieldResponse);

            const questionMetadataResponse = await service.getQuestionMetadata(
                props.organizationId,
            );
            setQuestionsMetadata(questionMetadataResponse);

            const questionToUpdate = questionMetadataResponse.find(
                (data) => data.questionId === props.question?.id,
            );

            if (questionToUpdate?.metadataValueTagMap) {
                setMetadataValueTagMap(
                    createMetadataValueTagMapFromResponse(
                        questionToUpdate?.metadataValueTagMap,
                    ),
                );
            }
            setLoading(false);
        };
        getMetadataFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnSubmit = async () => {
        setLoading(true);
        let formattedRequestBody: QuestionMetadataRequest = {
            OrganizationId: props.organizationId ?? "",
            MetadataName: selectedMetadataField?.item1,
            QuestionId: props.question?.id ?? "",
            MetadataType: selectedMetadataField?.item2,
            metadataValueTagMap: null,
        };

        if (metadataValueTagMap.length) {
            let tempObj = {};
            metadataValueTagMap.forEach(
                (item) => (tempObj[item.metaValue] = item.tagId),
            );
            formattedRequestBody.metadataValueTagMap = tempObj;
        }

        try {
            const questionToUpdate = questionsMetadata.find(
                (data) => data.questionId === props.question?.id,
            );
            if (questionToUpdate) {
                await service.updateQuestionMetadata(props.organizationId, [
                    { ...formattedRequestBody, Id: questionToUpdate.id },
                ]);
            } else {
                await service.createQuestionMetadata(props.organizationId, [
                    formattedRequestBody,
                ]);
                const questionMetadataResponse =
                    await service.getQuestionMetadata(props.organizationId);
                setQuestionsMetadata(questionMetadataResponse);
            }

            setLoading(false);
            messageStore.logInfo("Successfully updated question metadata");
        } catch {
            messageStore.logError("Failed to update question metadata");
            setLoading(false);
        }
    };
    React.useEffect(() => {
        const defaultValue = questionsMetadata.find(
            (data) => data.questionId === props.question?.id,
        );
        if (
            defaultValue &&
            defaultValue?.metadataName &&
            defaultValue?.metadataType !== undefined
        ) {
            setSelectedMetadataField({
                item1: defaultValue?.metadataName,
                item2: defaultValue?.metadataType,
            });
        } else {
            setSelectedMetadataField(undefined);
        }
        if (defaultValue && defaultValue.metadataValueTagMap) {
            setMetadataValueTagMap(
                createMetadataValueTagMapFromResponse(
                    defaultValue.metadataValueTagMap,
                ),
            );
        } else {
            setMetadataValueTagMap([]);
        }
    }, [props.question?.id, questionsMetadata]);

    const metadataValueTagMapSet = new Set(
        metadataValueTagMap.map((item) => item.metaValue),
    );

    const hasDuplicates =
        metadataValueTagMapSet.size !== metadataValueTagMap.length;

    const isNotValid =
        metadataValueTagMap?.filter((item) => !item.metaValue || !item.tagId)
            .length > 0 ||
        !selectedMetadataField ||
        hasDuplicates;

    return (
        <Grid>
            {loading ? (
                <AcxLoadingIndicator
                    size={48}
                    style={{ zIndex: 9999 }}
                    alternate="PuffLoader"
                />
            ) : (
                <>
                    <Grid
                        container
                        item
                        xs={12}
                        alignItems="center"
                        style={{ paddingTop: "1rem" }}
                    >
                        <AcxSelectSingle
                            options={metadataFields ?? []}
                            id="metadataSelect"
                            labelField={"item1"}
                            valueField={"item1"}
                            onChange={(item) => setSelectedMetadataField(item)}
                            customStyle={selectCustomStyle}
                            inputLabel="Associated Metadata Field"
                            defaultValue={selectedMetadataField}
                        />
                    </Grid>

                    {props.question?.variation?.answerTypeName !==
                        "Question Group" && (
                        <>
                            <Grid
                                container
                                item
                                xs={12}
                                style={{
                                    padding: "1rem",
                                    paddingLeft: 0,
                                    fontWeight: "bold",
                                }}
                            >
                                Metadata Value Tag Map
                            </Grid>
                            {!!metadataValueTagMap.length && (
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    alignItems="center"
                                >
                                    <Grid
                                        container
                                        item
                                        xs={6}
                                        justifyContent="center"
                                    >
                                        Metadata Value
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        xs={6}
                                        justifyContent="center"
                                    >
                                        Tag
                                    </Grid>
                                </Grid>
                            )}
                            {metadataValueTagMap.map((item, index) => (
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    alignItems="center"
                                    key={index}
                                    spacing={1}
                                >
                                    <Grid item xs={6}>
                                        <AcxMainTextField
                                            id={index + "meta-tag"}
                                            type="text"
                                            onChange={(e) => {
                                                setMetadataValueTagMap(
                                                    (prev) => {
                                                        prev[index].metaValue =
                                                            e.target.value;
                                                        prev = [...prev];
                                                        return prev;
                                                    },
                                                );
                                            }}
                                            value={item.metaValue}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <AcxSelectSingle
                                            options={props.question?.tags ?? []}
                                            id="ext-metadata-tag-select"
                                            labelField="value"
                                            valueField="id"
                                            customStyle={selectCustomStyle}
                                            defaultValue={props.question?.tags?.find(
                                                (tag) => tag.id === item.tagId,
                                            )}
                                            onChange={(value) => {
                                                setMetadataValueTagMap(
                                                    (prev) => {
                                                        prev[index].tagId =
                                                            value.id;
                                                        prev = [...prev];
                                                        return prev;
                                                    },
                                                );
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            ))}
                            <Grid
                                container
                                item
                                xs={12}
                                justifyContent="space-between"
                                style={{ paddingTop: "1rem" }}
                            >
                                <Grid>
                                    <AcxButton
                                        onClick={() => {
                                            setMetadataValueTagMap((prev) => [
                                                ...prev,
                                                { metaValue: "", tagId: "" },
                                            ]);
                                        }}
                                        color="secondary"
                                    >
                                        Add
                                    </AcxButton>
                                </Grid>
                                {!!metadataValueTagMap.length && (
                                    <Grid>
                                        <AcxButton
                                            onClick={() => {
                                                setMetadataValueTagMap((prev) =>
                                                    prev.slice(0, -1),
                                                );
                                            }}
                                        >
                                            Remove
                                        </AcxButton>
                                    </Grid>
                                )}
                            </Grid>
                            {hasDuplicates && (
                                <Grid item xs={12}>
                                    <Typography color="error">
                                        No duplicate meta values allowed
                                    </Typography>
                                </Grid>
                            )}
                        </>
                    )}

                    <Grid
                        container
                        item
                        xs={12}
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <AcxButton
                            buttonDisabled={isNotValid}
                            fullWidth={false}
                            color="secondary"
                            rootStyle={{
                                padding: "1rem",
                                marginTop: "1.75rem",
                            }}
                            onClick={handleOnSubmit}
                        >
                            Apply Changes
                        </AcxButton>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default OrganizationModuleQuestionMetadataForm;
