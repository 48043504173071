// https://developers.heap.io/reference/client-side-apis-overview

export interface HeapEvents {
    identity: string;
    identify: (identify: string) => void;
    addUserProperties: (properties: Object) => void;
}

declare global {
    interface Window {
        heap: HeapEvents;
    }
}

const checkHeap = () => {
    if (window.heap === undefined) {
        console.error("window.heap is not initialized");
        return false;
    }
    return true;
};

namespace hevent {
    export function identify(identifyData: string) {
        if (!checkHeap()) return;

        window.heap.identify(identifyData);
    }

    export function addUserProperties(properties: Object) {
        if (!checkHeap()) return;
        window.heap.addUserProperties(properties);
    }
}

export default hevent;
