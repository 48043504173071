import asyncDebounce from "awesome-debounce-promise";
import { AuthStore } from "../stores/AuthStore";
import RootStore from "../stores/RootStore";
import { fetch_retry } from "../utils/FetchRetry";

export class ServiceError extends Error {
    public url: string;
    public response: Response;
    public errorMessage?: string;
    public errorJson?: any;

    public constructor(
        requestType: string,
        url: string,
        response: Response,
        errorMessage?: string,
        errorJson?: any,
    ) {
        const message = `${requestType} to ${url} Failed: ${response.statusText} ${errorMessage}`;
        super(message);

        try {
            const responseError = JSON.parse(errorMessage ?? "");
            this.errorMessage = responseError.message;
            this.errorJson = errorJson ?? {};

            if (this.errorMessage?.includes("expired")) {
                const origin = window.location.href;

                window.location.href = `/authentication/login?returnUrl=${encodeURIComponent(
                    origin,
                )}`;
            }
        } catch (e) {
            this.errorMessage = errorMessage ?? "Something went wrong";
            this.errorJson = errorJson ?? {};
        }

        this.url = url;
        this.response = response;
    }
}

export abstract class BaseService {
    protected abstract urlBase: string;
    protected apiVersion: string = "1.0";

    //authFailureHandler is wired up in the RootStore
    //handler is found in AuthStore
    constructor(public authFailureHandler?: () => Promise<void>) {}

    protected post: <T = any>(
        body: URLSearchParams | string | any | null | FormData,
        urlParams: URLSearchParams,
        url: string,
        httpOptions?: { returnJson?: boolean },
        debounceKey?: string,
        abortSignal?: AbortSignal,
        asRawResponse?: boolean,
    ) => Promise<T> = asyncDebounce(
        async <T = any>(
            body: URLSearchParams | string | any | null,
            urlParams: URLSearchParams,
            url: string,
            httpOptions?: { returnJson?: boolean },
            debounceKey?: string,
            abortSignal?: AbortSignal,
            asRawResponse?: boolean,
        ): Promise<T> => {
            let headers = RootStore().getStore(AuthStore).getRequestHeader();
            headers = { ...headers, "api-version": this.apiVersion };
            // Let the browser determine the content type based on the FormData body
            if (body instanceof FormData) {
                delete headers["Content-Type"];
            }
            let options = {
                method: "POST",
                headers: headers,
                body: body,
            };
            if (abortSignal) {
                options["signal"] = abortSignal;
            }
            const response = await fetch_retry(
                `${this.urlBase}/${url}?${urlParams}`,
                options,
                2,
            );
            const clonedResp = response.clone();
            if (!response.ok) {
                if (response.status === 401) {
                    await this.authFailureHandler?.();
                }

                const returnJson = httpOptions?.returnJson && response.json;
                const errorData = returnJson
                    ? await response.json().catch((err) => {
                          return clonedResp.text();
                      })
                    : await response.text();

                throw new ServiceError(
                    "HttpPost",
                    url,
                    clonedResp,
                    !returnJson && errorData,
                    returnJson && errorData,
                );
            }

            if (asRawResponse) return response as T;
            return response.json().catch(() => {});
        },
        255,
        {
            key: (
                body: URLSearchParams | string | any | null,
                urlParams: URLSearchParams,
                url: string,
                httpOptions?: { returnJson?: boolean },
                debounceKey?: string,
            ) =>
                `HttpPost-${this.urlBase}-${
                    debounceKey ?? `${url}-${urlParams?.toString()}`
                }`,
        },
    );

    protected get: <T = any>(
        urlParams: URLSearchParams,
        url: string,
        abortSignal?: AbortSignal,
        debounceKey?: string,
    ) => Promise<T> = asyncDebounce(
        async <T = any>(
            urlParams: URLSearchParams,
            url: string,
            abortSignal?: AbortSignal,
            debounceKey?: string,
        ): Promise<T> => {
            let headers = RootStore().getStore(AuthStore).getRequestHeader();
            headers = { ...headers, "api-version": this.apiVersion };
            let options = {
                method: "GET",
                headers: headers,
            };
            if (abortSignal) {
                options["signal"] = abortSignal;
            }
            const response = await fetch_retry(
                `${this.urlBase}/${url}?${urlParams}`,
                options,
                2,
            );
            const clonedResp = response.clone();
            if (!response.ok) {
                if (response.status === 401) {
                    await this.authFailureHandler?.();
                }

                throw new ServiceError(
                    "HttpGet",
                    `${this.urlBase}/${url}`,
                    clonedResp,
                    await response.text(),
                );
            }

            return response.json();
        },
        255,
        {
            key: (
                urlParams: URLSearchParams,
                url: string,
                abortSignal?: AbortSignal,
                debounceKey?: string,
            ) => {
                return `HttpGet-${this.urlBase}-${
                    debounceKey ?? `${url}-${urlParams?.toString()}`
                }`;
            },
        },
    );

    protected delete: (
        urlParams: URLSearchParams,
        url: string,
        body?: URLSearchParams | string | any | null,
        httpOptions?: { returnJson?: boolean },
        debounceKey?: string,
    ) => Promise<Response> = asyncDebounce(
        async (
            urlParams: URLSearchParams,
            url: string,
            body?: URLSearchParams | string | any | null,
            httpOptions?: { returnJson?: boolean },
            debounceKey?: string,
        ) => {
            let headers = RootStore().getStore(AuthStore).getRequestHeader();
            headers = { ...headers, "api-version": this.apiVersion };
            const options = {
                method: "DELETE",
                headers: headers,
                body: body,
            };
            const response = await fetch_retry(
                `${this.urlBase}/${url}?${urlParams}`,
                options,
                2,
            );
            const clonedResp = response.clone();
            if (!response.ok) {
                if (response.status === 401) {
                    await this.authFailureHandler?.();
                }

                const returnJson = httpOptions?.returnJson && response.json;
                const errorData = returnJson
                    ? await response.json()
                    : await response.text();

                throw new ServiceError(
                    "HttpDelete",
                    `${this.urlBase}/${url}`,
                    clonedResp,
                    !returnJson && errorData,
                    returnJson && errorData,
                );
            }

            return response;
        },
        255,
        {
            key: (
                urlParams: URLSearchParams,
                url: string,
                body?: URLSearchParams | string | any | null,
                debounceKey?: string,
            ) =>
                `HttpDelete-${this.urlBase}-${
                    debounceKey ?? `${url}-${urlParams?.toString()}`
                }`,
        },
    );

    protected put: <T = any>(
        urlParams: URLSearchParams,
        url: string,
        body?: URLSearchParams | string | any | null,
        httpOptions?: { returnJson?: boolean },
        debounceKey?: string,
    ) => Promise<T> = asyncDebounce(
        async <T = any>(
            urlParams: URLSearchParams,
            url: string,
            body?: URLSearchParams | string | null,
            httpOptions?: { returnJson?: boolean },
            debounceKey?: string,
        ): Promise<T> => {
            let headers = RootStore().getStore(AuthStore).getRequestHeader();
            headers = { ...headers, "api-version": this.apiVersion };
            const options = {
                method: "PUT",
                headers: headers,
                body: body,
            };
            const response = await fetch_retry(
                `${this.urlBase}/${url}?${urlParams}`,
                options,
                2,
            );
            const clonedResp = response.clone();
            if (!response.ok) {
                if (response.status === 401) {
                    await this.authFailureHandler?.();
                }
                const returnJson = httpOptions?.returnJson && response.json;
                const errorData = returnJson
                    ? await response.json()
                    : await response.text();

                throw new ServiceError(
                    "HttpPut",
                    `${this.urlBase}/${url}`,
                    clonedResp,
                    !returnJson && errorData,
                    returnJson && errorData,
                );
            }

            return response.json().catch(() => {});
        },
        255,
        {
            key: (
                urlParams: URLSearchParams,
                url: string,
                body?: URLSearchParams | string | null,
                httpOptions?: { returnJson?: boolean },
                debounceKey?: string,
            ) =>
                `HttPut-${this.urlBase}-${
                    debounceKey ?? `${url}-${urlParams?.toString()}`
                }`,
        },
    );

    protected patch: <T = any>(
        body: URLSearchParams | string | any | null,
        urlParams: URLSearchParams,
        url: string,
        httpOptions?: { returnJson?: boolean },
        debounceKey?: string,
        abortSignal?: AbortSignal,
        addtlOptions?: RequestInit,
    ) => Promise<T> = asyncDebounce(
        async <T = any>(
            body: URLSearchParams | string | any | null,
            urlParams: URLSearchParams,
            url: string,
            httpOptions?: { returnJson?: boolean },
            debounceKey?: string,
            abortSignal?: AbortSignal,
            addtlOptions?: RequestInit,
        ): Promise<T> => {
            let headers = RootStore().getStore(AuthStore).getRequestHeader();
            headers = { ...headers, "api-version": this.apiVersion };
            let options = {
                ...addtlOptions,
                method: "PATCH",
                headers: headers,
                body: body,
            };
            if (abortSignal) {
                options["signal"] = abortSignal;
            }
            const response = await fetch_retry(
                `${this.urlBase}/${url}?${urlParams}`,
                options,
                2,
            );
            const clonedResp = response.clone();
            if (!response.ok) {
                if (response.status === 401) {
                    await this.authFailureHandler?.();
                }

                const returnJson = httpOptions?.returnJson && response.json;
                const errorData = returnJson
                    ? await response.json().catch((err) => {
                          return clonedResp.text();
                      })
                    : await response.text();

                throw new ServiceError(
                    "HttpPatch",
                    url,
                    clonedResp,
                    !returnJson && errorData,
                    returnJson && errorData,
                );
            }

            return response.json().catch(() => {});
        },
        255,
        {
            key: (
                body: URLSearchParams | string | any | null,
                urlParams: URLSearchParams,
                url: string,
                httpOptions?: { returnJson?: boolean },
                debounceKey?: string,
            ) =>
                `HttpPatch-${this.urlBase}-${
                    debounceKey ?? `${url}-${urlParams?.toString()}`
                }`,
        },
    );

    protected getArrayBuffer: (
        urlParams: URLSearchParams,
        url: string,
        debounceKey?: string,
    ) => Promise<ArrayBuffer> = asyncDebounce(
        async (
            urlParams: URLSearchParams,
            url: string,
            debounceKey?: string,
        ): Promise<ArrayBuffer> => {
            const headers = RootStore().getStore(AuthStore).getRequestHeader();
            const options = {
                method: "GET",
                headers: headers,
            };
            const response = await fetch_retry(
                `${this.urlBase}/${url}?${urlParams}`,
                options,
                2,
            );
            const clonedResp = response.clone();
            if (!response.ok) {
                if (response.status === 401) {
                    await this.authFailureHandler?.();
                }

                throw new ServiceError(
                    "HttpGet ArrayBuffer",
                    `${this.urlBase}/${url}`,
                    clonedResp,
                    await response.text(),
                );
            }

            return response.arrayBuffer();
        },
        255,
        {
            key: (
                urlParams: URLSearchParams,
                url: string,
                debounceKey?: string,
            ) =>
                `ArrayBuffer-${this.urlBase}-${
                    debounceKey ?? `${url}-${urlParams?.toString()}`
                }`,
        },
    );

    protected getBlob: (
        urlParams: URLSearchParams,
        url: string,
        debounceKey?: string,
    ) => Promise<Blob> = asyncDebounce(
        async (
            urlParams: URLSearchParams,
            url: string,
            debounceKey?: string,
        ): Promise<Blob> => {
            const headers = RootStore().getStore(AuthStore).getRequestHeader();
            const options = {
                method: "GET",
                headers: headers,
            };
            const response = await fetch_retry(
                `${this.urlBase}/${url}?${urlParams}`,
                options,
                2,
            );
            const clonedResp = response.clone();
            if (!response.ok) {
                if (response.status === 401) {
                    await this.authFailureHandler?.();
                }

                throw new ServiceError(
                    "HttpGet Blob",
                    `${this.urlBase}/${url}`,
                    clonedResp,
                    await response.text(),
                );
            }

            return response.blob();
        },
        255,
        {
            key: (
                urlParams: URLSearchParams,
                url: string,
                debounceKey?: string,
            ) =>
                `Blob-${this.urlBase}-${
                    debounceKey ?? `${url}-${urlParams?.toString()}`
                }`,
        },
    );
}

export default BaseService;
