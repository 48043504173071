import {
    Divider,
    Grid,
    IconButton,
    Paper,
    Theme,
    Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import AcxCalendarPopup from "components/UI/Calendar/AcxCalendarPopup";
import AcxSelectSingle from "components/UI/Select/BaseSelectComponents/AcxSelectSingle";
import AcxSelectSingleAsync from "components/UI/Select/BaseSelectComponents/AcxSelectSingleAsync";
import _ from "lodash";
import { observer } from "mobx-react";
import Agentv2 from "models/Agentv2";
import React from "react";
import Scrollbars from "react-custom-scrollbars-2";
import AgentService from "services/AgentService";
import { AuthStore } from "stores/AuthStore";
import { useStore } from "utils/useStore";
import { InteractionType } from "../../../models/InteractionType";
import AcxMainTextField from "../../UI/AcxMainTextFieldGrid";
import { EvalStore } from "../Stores/EvalStore";
import { isTextMediaType } from "./EvalPage";
import QuestionAnswerList from "./Modules/QuestionAnswerList";
import EditIcon from "@mui/icons-material/Edit";
import { EvaluationStatus } from "models/Evaluation";
import WorkflowSidebar from "./WorkflowSidebar/WorkflowSidebar";
export interface InteractionMetaProps {
    drawerPixelOffset: number;
    interactionType?: InteractionType;
    rightOffset?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
    labelText: {
        color: "#1F1F1F",
        fontFamily: "Inter",
        fontSize: "13px",
        letterSpacing: "0",
        lineHeight: "20px",
    },
    content: {
        height: "100%",
        width: "100%",
        boxShadow: "2px 0 2px -2px #888",
        paddingTop: "12px",
        minWidth: "141px",
    },
    title: {
        paddingLeft: "12px",
        paddingRight: "12px",
        paddingBottom: "12px",
        paddingTop: "6px",
    },
    detailsSection: {
        paddingLeft: "12px",
        paddingRight: "12px",
        paddingTop: "4px",
        paddingBottom: "4px",
    },
    mainSection: {
        paddingLeft: "12px",
        paddingRight: "12px",
        marginTop: theme.spacing(2),
    },
    scrollable: {
        overflowX: "hidden",
    },
    classifierSection: {
        paddingLeft: "12px",
        paddingRight: "12px",
        paddingTop: "4px",
        paddingBottom: "8px",
    },
    logo: {
        height: 16,
        width: 16,
        marginRight: theme.spacing(1),
    },
    logoText: {
        fontSize: "13px",
        fontWeight: "bold",
    },
}));

const callDirections = [
    { id: "Inbound", value: "Inbound" },
    { id: "Outbound", value: "Outbound" },
    { id: "Transfer", value: "Transfer" },
    { id: "N/A", value: "N/A" },
];

const selectCustomStyles = {
    menuPortal: (provided, state) => ({ ...provided, zIndex: 9999 }),
};

const InteractionMeta = observer((props: InteractionMetaProps) => {
    const classes = useStyles(props);
    const evalStore = useStore(EvalStore);
    /*const [interactionSummaryChange, setInteractionSummaryChange] =
        React.useState(true);*/
    const interaction = evalStore.currentEval?.interaction;
    const authStore = useStore(AuthStore);

    const leftRenderModules = evalStore.leftRenderPlacementModuleUiModels;

    function onSummaryChange(evt: React.ChangeEvent<HTMLInputElement>) {
        interaction?.setCallSummary(evt.currentTarget.value);
        //setInteractionSummaryChange(false);
    }

    function onCallDirectionChange(arg: { id: string } | undefined) {
        interaction?.setInteractionDirection(arg?.id);
    }

    const onAgentCreate = (arg) => {
        evalStore.openCreateAgent();
    };
    const onAgentSelect = (agent: Agentv2) => {
        interaction?.setAgent(agent);
    };

    const showReassignHierarchyButton =
        evalStore.currentEval?.isEditable &&
        evalStore.authStore.canUserEdit("Reassign Hierarchy");

    const agentSearch = async (inputValue: string) =>
        new Promise<Agentv2[]>(async (resolve) => {
            const a = new AgentService();
            const agts = await a.searchAgents(
                evalStore.authStore.orgStore.selectedOrganization?.id,
                inputValue,
            );
            resolve(agts);
        });

    const getOptionLabel = (data) => {
        let lbl = "";
        if (_.has(data, "fullName")) {
            if (data?.fullName !== " ") {
                lbl = data.fullName;
            } else {
                lbl = data.agentEmail;
            }
            if (data.isActive) {
                lbl = lbl + " - Active";
            } else {
                lbl = lbl + " - Inactive";
            }
        } else {
            lbl = data.label;
        }
        return lbl;
    };

    const getAgentDefault = () => {
        const d = evalStore.agentList[0] || null;

        return d;
    };
    return (
        <Paper square className={clsx(classes.content)}>
            <div className={classes.title}>
                <Typography variant="h2" gutterBottom={true}>
                    Interaction Details
                </Typography>
            </div>

            <Divider variant={"fullWidth"} />

            {authStore.canUserView("Interaction Modules") && (
                <div
                    className={classes.scrollable}
                    style={{
                        height: `calc(100% - ${
                            isTextMediaType(props.interactionType)
                                ? 50
                                : props.drawerPixelOffset + 50
                        }px)`,
                    }}
                >
                    <Scrollbars autoHide>
                        {authStore.canUserView("Analyst") && (
                            <>
                                <div className={classes.detailsSection}>
                                    <Typography
                                        style={{ marginTop: "4px" }}
                                        variant="h6"
                                    >
                                        Analyst
                                    </Typography>
                                    <Typography
                                        style={{ marginBottom: "4px" }}
                                        variant="body2"
                                    >
                                        {evalStore.currentEval?.analyst
                                            ? evalStore.currentEval.analyst
                                                  .userName
                                            : "Unassigned"}
                                    </Typography>
                                    {evalStore.currentEval &&
                                        evalStore.currentEval
                                            .evaluationStatus ===
                                            EvaluationStatus.Completed &&
                                        evalStore.currentEval
                                            .analystTimeSpent && (
                                            <>
                                                <Typography
                                                    style={{
                                                        marginTop: "4px",
                                                    }}
                                                    variant="h6"
                                                >
                                                    Time to Evaluate
                                                </Typography>
                                                <Typography
                                                    style={{
                                                        marginBottom: "4px",
                                                    }}
                                                    variant="body2"
                                                >
                                                    {
                                                        evalStore.currentEval
                                                            .analystTimeSpent
                                                    }
                                                </Typography>
                                            </>
                                        )}
                                </div>
                                <Divider variant={"fullWidth"} />
                            </>
                        )}
                        {!!evalStore.currentEvalVisibleWorkflowInstances
                            .length && (
                            <>
                                <WorkflowSidebar
                                    instances={
                                        evalStore.currentEvalVisibleWorkflowInstances
                                    }
                                    orgId={
                                        evalStore.authStore.orgStore
                                            .selectedOrganization?.id
                                    }
                                />
                                <Divider
                                    variant={"fullWidth"}
                                    style={{ marginBottom: "8px" }}
                                />
                            </>
                        )}
                        <div className={classes.detailsSection}>
                            <Grid container>
                                <Grid
                                    item
                                    xs={showReassignHierarchyButton ? 11 : 12}
                                >
                                    {evalStore.interactionHierarchyLevels?.map(
                                        (value) => (
                                            <React.Fragment key={value.id}>
                                                <Typography
                                                    style={{ marginTop: "4px" }}
                                                    variant="h6"
                                                >
                                                    {value.levelName}
                                                </Typography>

                                                <Typography
                                                    style={{
                                                        marginBottom: "4px",
                                                    }}
                                                    variant="body2"
                                                >
                                                    {value.name}
                                                </Typography>
                                            </React.Fragment>
                                        ),
                                    ) ?? (
                                        <>
                                            <Typography
                                                style={{ marginTop: "4px" }}
                                                variant="h6"
                                            >
                                                Vendor
                                            </Typography>
                                            <Typography
                                                style={{ marginBottom: "4px" }}
                                                variant="body2"
                                            >
                                                Not Available
                                            </Typography>
                                            <Typography
                                                style={{ marginBottom: "4px" }}
                                                variant="h6"
                                            >
                                                Brand
                                            </Typography>
                                            <Typography
                                                style={{ marginBottom: "4px" }}
                                                variant="body2"
                                            >
                                                Not Available
                                            </Typography>
                                        </>
                                    )}
                                </Grid>

                                {showReassignHierarchyButton && (
                                    <Grid
                                        item
                                        xs={1}
                                        container
                                        justifyContent="flex-end"
                                    >
                                        <Grid item>
                                            <IconButton
                                                onClick={() => {
                                                    evalStore.reassignHierarchyDialogStore.open();
                                                }}
                                                disabled={
                                                    !evalStore.currentEval
                                                        ?.isEditable ?? false
                                                }
                                                size="large"
                                            >
                                                <EditIcon fontSize={"small"} />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </div>

                        <Divider
                            variant={"fullWidth"}
                            style={{ marginBottom: "8px" }}
                        />

                        {/* {Boolean(
                        evalStore.positiveClassifiers &&
                            evalStore.positiveClassifiers.length > 0,
                    ) && (
                        <>
                            <div className={classes.classifierSection}>
                                <Grid
                                    container
                                    justifyContent={"flex-start"}
                                    alignItems={"flex-start"}
                                    wrap={"wrap"}
                                    alignContent={"flex-start"}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        container
                                        style={{ marginBottom: "8px" }}
                                    >
                                        <Grid item>
                                            <img
                                                className={classes.logo}
                                                alt=""
                                                src={logo}
                                            />
                                        </Grid>

                                        <Grid item>
                                            <Typography
                                                className={classes.logoText}
                                            >
                                                What we heard
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <AcxChipList
                                        size={"small"}
                                        color={"blue"}
                                        values={evalStore.positiveClassifiers
                                            ?.filter(
                                                (value) => value.classifier,
                                            )
                                            ?.map(
                                                (value) =>
                                                    value.classifier.name,
                                            )}
                                    />
                                </Grid>
                            </div>

                            <Divider
                                variant={"fullWidth"}
                                style={{ marginBottom: "8px" }}
                            />
                        </>
                    )} */}

                        <div
                            className={classes.mainSection}
                            style={
                                props.rightOffset
                                    ? { paddingRight: props.rightOffset + "px" }
                                    : {}
                            }
                        >
                            <AcxSelectSingle
                                required={!interaction?.customerType}
                                options={evalStore.customerTypes ?? []}
                                customStyle={selectCustomStyles}
                                enableNoSelection
                                menuPlacement={"auto"}
                                menuPortalTarget={document.body}
                                id={`insights-module-cust-type`}
                                isDisabled={
                                    !evalStore.currentEval ||
                                    !evalStore.currentEval.isEditable
                                }
                                fullWidth
                                onChange={interaction?.setCustomerType}
                                defaultValue={interaction?.customerType}
                                inputLabel={"Customer Type"}
                                valueField={"id"}
                                labelField={"name"}
                            />

                            <div style={{ height: "1.05rem" }} />

                            {evalStore.agentList && (
                                <AcxSelectSingleAsync
                                    inputLabel="Agent"
                                    required={!interaction?.agentId}
                                    fullWidth
                                    defaultValue={getAgentDefault()}
                                    defaultOptions={evalStore.agentList}
                                    onCreate={onAgentCreate}
                                    loadOptions={agentSearch}
                                    cacheOptions
                                    isLoading={false}
                                    isClearable={true}
                                    onChange={onAgentSelect}
                                    enableNoSelection={true}
                                    valueField="id"
                                    labelField={getOptionLabel}
                                    id={"test-select-async-1"}
                                    noOptionMessage={
                                        "Please enter First, Last, Agent Code, or Email"
                                    }
                                    isDisabled={
                                        !evalStore.currentEval ||
                                        !evalStore.currentEval.isEditable
                                    }
                                />
                            )}

                            <div style={{ height: "1.05rem" }} />

                            <AcxSelectSingle
                                fullWidth
                                isDisabled={
                                    !evalStore.currentEval ||
                                    !evalStore.currentEval.isEditable
                                }
                                required={!interaction?.interactionDirection}
                                customStyle={selectCustomStyles}
                                menuPlacement={"auto"}
                                menuPortalTarget={document.body}
                                enableNoSelection
                                defaultValue={
                                    interaction?.interactionDirection
                                        ? {
                                              id: interaction?.interactionDirection,
                                              value: interaction?.InteractionDirectionDisplay,
                                          }
                                        : undefined
                                }
                                options={callDirections}
                                inputLabel="Interaction Direction"
                                id="eval-calldirection-selection"
                                valueField="id"
                                labelField="value"
                                onChange={onCallDirectionChange}
                            />

                            <div style={{ height: "1.05rem" }} />

                            <AcxCalendarPopup
                                onSelect={interaction?.setInteractionDate}
                                required
                                disabled={
                                    !evalStore.currentEval ||
                                    !evalStore.currentEval.isEditable ||
                                    !authStore.canUserEdit("Interaction Date")
                                }
                                inputLabel="Interaction Date"
                                currentDate={
                                    interaction?.InteractionDateAsMoment
                                }
                                id={"iteraction-calendar"}
                            />

                            <div style={{ height: "1.05rem" }} />

                            <Grid
                                container
                                alignItems={"flex-start"}
                                justifyContent={"flex-start"}
                            >
                                {leftRenderModules?.map((arg) => {
                                    const moduleName = arg[0];
                                    const moduleUIModel = arg[1];

                                    return (
                                        <React.Fragment key={moduleName}>
                                            <QuestionAnswerList
                                                store={evalStore}
                                                standalone
                                                module={
                                                    moduleUIModel.licensedModule
                                                }
                                            />
                                        </React.Fragment>
                                    );
                                })}
                            </Grid>

                            <div
                                style={{
                                    height: "1px",
                                    marginTop: "0.75rem",
                                    marginBottom: "1.35rem",
                                }}
                            />
                            <Grid
                                container
                                alignItems={"flex-start"}
                                justifyContent={"flex-start"}
                            >
                                {/*interactionSummaryChange &&
                                    interaction?.callSummary && (
                                        <Grid item style={{ display: "flex" }}>
                                        <Tooltip title="The Summary below was created by our AI model. We use an Encoder-Decoder Transformer that learns how to capture long range dependencies and encode all the important information present in the interaction. It then maps/decodes this information to a shorter sequence of words while still encapsulating all the information it originally captured. ">
                                            <span
                                                style={{
                                                    whiteSpace: "nowrap",
                                                    paddingTop: "0.40rem",
                                                }}
                                            >
                                                Interaction Summary
                                            </span>
                                            </Tooltip>
                                            {/* <div
                                                style={{
                                                    paddingLeft: "2.25rem",
                                                }}
                                            >
                                               <AcxStatusSticker
                                                    title={"Smart Predict"}
                                                />
                                            </div>*
                                        </Grid>
                                    )*/}
                                <Grid
                                    container
                                    direction="column"
                                    wrap="nowrap"
                                >
                                    <Grid item style={{ display: "flex" }}>
                                        <span
                                            style={{
                                                whiteSpace: "nowrap",
                                                paddingTop: "0.40rem",
                                            }}
                                        >
                                            Interaction Summary
                                        </span>
                                    </Grid>
                                    <Grid
                                        style={{ display: "flex" }}
                                        item
                                        className="pendo-ignore"
                                    >
                                        <AcxMainTextField
                                            onChange={onSummaryChange}
                                            labelText=""
                                            multiLine
                                            rows={5}
                                            rowsMax={6}
                                            placeholderText={
                                                "What were your key takeaways?"
                                            }
                                            id="interaction-summary-id"
                                            disabled={
                                                !evalStore.currentEval ||
                                                !evalStore.currentEval
                                                    .isEditable
                                            }
                                            value={
                                                interaction?.callSummary ?? ""
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Scrollbars>
                </div>
            )}
        </Paper>
    );
});

export default InteractionMeta;
