import { Typography } from "@mui/material";
import type { PopoverPosition } from "@mui/material";
import {
    action,
    computed,
    makeObservable,
    observable,
    reaction,
    runInAction,
} from "mobx";
import { computedFn } from "mobx-utils";
import type Classifier from "models/ClassifierModel";
import React, { SyntheticEvent } from "react";
import { ServiceError } from "services/BaseService";
import ClassifierService from "services/ClassifierService";
import { OrgSelectorComponentStore } from "stores/ComponentStores/OrgSelectorComponentStore";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";
import { isUndefinedType } from "../../../utils/TypeGuards";

const classifierService = new ClassifierService();

@AcxStore
export class ClassifierBuilderStore {
    orgSelectStore = new OrgSelectorComponentStore();

    @observable isLoadDialogOpen = false;
    @observable isDeleteDialogOpen = false;
    @observable isPublishDialogOpen = false;

    @observable isClassifierListLoading = false;
    @observable isDialogLoading = false;

    @observable classifiers: Classifier[] = [];

    @observable selectedClassifier?: Classifier;

    @observable errorMessage = "";

    @observable editorMenuPopperElement?: any | null = null;

    @observable editorContextMenu?: PopoverPosition;

    operatorDocumentationMap: Map<string, any>;

    @observable temporaryClassifier?: Classifier;

    public constructor(public rootStore: IRootStore) {
        makeObservable(this);
        reaction(
            (r) => ({
                orgId: this.orgSelectStore.orgId,
            }),
            (args) => {
                this.loadClassifiers();
            },
        );

        this.operatorDocumentationMap = getDocumentation();
    }

    documentationContentForMenuItem = computedFn(function (
        this: ClassifierBuilderStore,
        menuItemId?: string,
    ) {
        if (isUndefinedType(menuItemId)) {
            return undefined;
        }

        return this.operatorDocumentationMap.get(menuItemId);
    });

    @action
    storeTemporaryValue = (value: Classifier) => {
        this.temporaryClassifier = value;
    };

    @action
    removeTemporaryValue = () => {
        this.temporaryClassifier = undefined;
    };

    @action onEditorContextMenuClick = (
        event: React.MouseEvent<HTMLDivElement>,
    ) => {
        event.preventDefault();
        this.editorContextMenu = { left: event.clientX, top: event.clientY };
        return false;
    };

    @action onEditorContextMenuClose = () => {
        this.onMenuItemLeave();
        this.editorContextMenu = undefined;
    };

    @action onMenuItemEnter = (event: SyntheticEvent) => {
        event.preventDefault();
        this.editorMenuPopperElement = event.currentTarget;
    };

    @action onMenuItemLeave = () => {
        this.editorMenuPopperElement = null;
    };

    @action openLoadDialog = () => {
        this.isLoadDialogOpen = true;
    };
    @action closeLoadDialog = () => {
        this.isLoadDialogOpen = false;
    };

    @action openDeleteDialog = () => {
        this.isDeleteDialogOpen = true;
    };
    @action closeDeleteDialog = () => {
        this.isDeleteDialogOpen = false;
    };

    @action openPublishDialog = () => {
        this.isPublishDialogOpen = true;
    };
    @action closePublishDialog = () => {
        this.isPublishDialogOpen = false;
    };

    @computed
    get controlsDisabled() {
        return (
            this.isDialogLoading ||
            this.selectedClassifier === null ||
            this.selectedClassifier === undefined
        );
    }

    @action setDialogLoading = () => {
        this.isDialogLoading = true;
    };
    @action setDialogNotLoading = () => {
        this.isDialogLoading = false;
    };

    @action
    setSelectedClassifier = (newClassifier?: Classifier) => {
        this.selectedClassifier = newClassifier;
    };

    @action deleteClassifier = async () => {
        if (!this.selectedClassifier?.id) {
            this.errorMessage = "No classifier selected";
            return;
        }

        this.errorMessage = "";
        this.setDialogLoading();

        try {
            await classifierService.deleteClassifier(
                this.selectedClassifier.id,
            );
            await this.loadClassifiers();

            runInAction(async () => {
                this.setDialogNotLoading();
                this.setSelectedClassifier(undefined);
                this.closeDeleteDialog();
            });
        } catch (error) {
            //@ts-ignore
            console.error(error.message);
            this.setDialogNotLoading();
            //@ts-ignore
            this.errorMessage = error.message;
        }
    };

    @action toggleClassifierPublished = async () => {
        if (!this.selectedClassifier?.id) {
            this.errorMessage = "Invalid classifier selection";
            return;
        }
        this.errorMessage = "";
        this.setDialogLoading();
        let resultClassy: Classifier;
        try {
            if (this.selectedClassifier.isPublished) {
                resultClassy = await classifierService.unpublishClassifier(
                    this.selectedClassifier.id,
                );
            } else {
                resultClassy = await classifierService.publishClassifier(
                    this.selectedClassifier.id,
                );
            }
            await this.loadClassifiers();
            runInAction(() => {
                this.setSelectedClassifier(resultClassy);
                this.setDialogNotLoading();
                this.closePublishDialog();
            });
        } catch (error) {
            const serviceError = error as ServiceError;
            const resp = await serviceError.response.json();
            runInAction(() => {
                this.setDialogNotLoading();
                this.errorMessage = resp.message;
            });
        }
    };

    @action loadClassifiers = async () => {
        this.isClassifierListLoading = true;
        const classifiers =
            await classifierService.loadClassifiersForCurrentUser(
                this.orgSelectStore.orgId,
            );
        runInAction(() => {
            this.classifiers = classifiers;
            this.isClassifierListLoading = false;
        });
    };
}

function getDocumentation() {
    const operatorDocumentation: Map<string, any> = new Map();

    operatorDocumentation.set(
        "WildCardOperator",
        <Typography style={{ fontFamily: "monospace", padding: "12px" }}>
            Lucene supports single and multiple character wildcard searches
            within single terms (not within phrase queries). To perform a single
            character wildcard search use the "?" symbol{" "}
            <code style={{ fontWeight: "bold" }}>te?t</code>.<br />
            To perform a multiple character wildcard search use the "*" symbol.
            <br />
            <code style={{ fontWeight: "bold" }}>te*t</code>
            <br />
        </Typography>,
    );
    operatorDocumentation.set(
        "ProximityOperator",
        <Typography style={{ fontFamily: "monospace", padding: "12px" }}>
            Lucene supports finding words are a within a specific distance away.
            To do a proximity search use the tilde, "~", symbol at the end of a
            Phrase. For example to search for a "apache" and "jakarta" within 10
            words of each other in a document use the search:
            <br />
            <pre>
                <code style={{ fontWeight: "bold" }}>"jakarta apache"~10</code>
            </pre>
        </Typography>,
    );
    operatorDocumentation.set(
        "FuzzyOperator",
        <Typography style={{ fontFamily: "monospace", padding: "12px" }}>
            Lucene supports fuzzy searches based on the Levenshtein Distance, or
            Edit Distance algorithm. To do a fuzzy search use the tilde, "~",
            symbol at the end of a Single word Term. For example to search for a
            term similar in spelling to "roam" use the fuzzy search:
            <br />
            <pre>
                <code style={{ fontWeight: "bold" }}>roam~0.8</code>
            </pre>{" "}
            The value is between 0 and 1, with a value closer to 1 only terms
            with a higher similarity will be matched.
        </Typography>,
    );
    operatorDocumentation.set(
        "ExclusionOperator",
        <Typography style={{ fontFamily: "monospace", padding: "12px" }}>
            The "-" or prohibit operator excludes documents that contain the
            term after the "-" symbol. To search for documents that contain
            "jakarta apache" but not "Apache Lucene" use the query:
            <br />
            <pre>
                <code style={{ fontWeight: "bold" }}>
                    "jakarta apache" -"Apache Lucene"
                </code>
            </pre>
        </Typography>,
    );
    operatorDocumentation.set(
        "BooleanOperator",
        <Typography style={{ fontFamily: "monospace", padding: "12px" }}>
            Boolean operators allow terms to be combined through logic
            operators. Lucene supports AND, "+", OR, NOT and "-" as Boolean
            operators(Note: Boolean operators must be ALL CAPS).
        </Typography>,
    );

    return operatorDocumentation;
}
