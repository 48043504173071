// REFERENCE DOCUMENTATION HERE: https://quilljs.com/

import { Grid, Theme, Tooltip, Typography } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import CloseIcon from "@mui/icons-material/Close";
import AcxButton from "components/UI/AcxButton";
import AcxMainTextField from "components/UI/AcxMainTextField";
import { observer } from "mobx-react";
import "quill/dist/quill.snow.css"; // snow theme
import React from "react";
import { useQuill } from "react-quilljs";
import useStyles from "Styles/Styles";
import OrganizationWorkflowEmailStore, {
    CREATE_EMAIL,
    UPDATE_EMAIL,
} from "../Stores/OrganizationWorkflowEmailStore";
import "./EmailEditor.css";
import OrganizationWorkflowEmailContextMenu from "./OrganizationWorkflowEmailContextMenu";

export interface Setter {
    variable: string;
    isLink?: boolean;
    linkType?: "Workflow" | "Evaluation";
}

const styles = (theme: Theme) =>
    createStyles({
        quillContainer: {
            height: "50vh",
            marginTop: "1.5rem",
        },
    });

type Props = {
    emailStore: OrganizationWorkflowEmailStore;
};

const OrganizationWorkflowsEmailEditor: React.FC<Props> = observer(
    ({ emailStore }) => {
        const classes = useStyles(styles);
        const theme = emailStore.theme;
        const placeholder = emailStore.placeholder;
        const modules = emailStore.modules;
        const formats = emailStore.formats;

        const { quill, quillRef } = useQuill({
            theme,
            placeholder,
            modules,
            formats,
        });

        const createAnchor = (
            variable: Setter["variable"],
            linkType: Setter["linkType"],
        ) => {
            return `<a
                              style="color:#3564D5 !important; font-size: 16px; font-weight: bold; letter-spacing: 0; line-height: 24px; margin: auto; text-decoration-line: none; text-align: center; white-space: nowrap;"
                              href={${variable}}
                          >
                              Link to ${linkType}
                          </a>`;
        };

        const setSubjectVariable = (
            variable: Setter["variable"],
            isLink?: Setter["isLink"],
            linkType?: Setter["linkType"],
        ) => {
            if (isLink || linkType) {
                return;
            }

            emailStore.handleSubjectChange({
                target: {
                    value: `${emailStore.subject}${
                        emailStore.subject ? " " : ""
                    }{${variable}}`,
                },
            });
        };

        const setEmailVariable = (
            variable: Setter["variable"],
            isLink?: Setter["isLink"],
            linkType?: Setter["linkType"],
        ) => {
            if (!isLink && !linkType) {
                quill.editor.isBlank()
                    ? (quill.root.innerText = `{${variable}}`)
                    : quill.root.lastElementChild.append(` {${variable}}`);
            } else {
                if (linkType) {
                    quill.editor.isBlank()
                        ? (quill.root.innerHTML = createAnchor(
                              variable,
                              linkType,
                          ))
                        : (quill.root.innerHTML =
                              quill.root.innerHTML +
                              createAnchor(variable, linkType));
                }
            }

            setTimeout(() => {
                quill.setSelection(quill.root.innerHTML.length);
                quill.focus();
            }, 0);

            emailStore.getBodyFromQuill(quill);
        };

        React.useEffect(() => {
            if (emailStore.emailTemplateId && emailStore.bodyText) {
                if (quill) {
                    quill.root.innerHTML = emailStore.bodyText;
                }
            }
        }, [emailStore.bodyText, emailStore.emailTemplateId, quill]);

        return (
            <Grid item xs={12}>
                <Grid container item xs={12}>
                    <AcxButton
                        color="secondary"
                        leftRightSpacing={0}
                        rootStyle={{
                            padding: "1rem",
                            minWidth: "max-content",
                        }}
                        fullWidth={false}
                        startIcon={<CloseIcon />}
                        onClick={() => emailStore.setEmailEditorIsOpen(false)}
                        loading={emailStore.anyTaskLoading}
                    >
                        Cancel
                    </AcxButton>

                    <AcxButton
                        color="secondary"
                        leftRightSpacing={1}
                        rootStyle={{
                            padding: "1rem",
                            minWidth: "max-content",
                        }}
                        fullWidth={false}
                        onClick={() => emailStore.saveEmailTemplate(quill)}
                        buttonDisabled={
                            emailStore.getTaskLoading(CREATE_EMAIL) ||
                            emailStore.getTaskLoading(UPDATE_EMAIL) ||
                            !emailStore.templateName ||
                            !emailStore.subject
                        }
                        loading={
                            emailStore.getTaskLoading(CREATE_EMAIL) ||
                            emailStore.getTaskLoading(UPDATE_EMAIL)
                        }
                    >
                        Save Email Template
                    </AcxButton>
                </Grid>
                <Tooltip title="To define a cadence batcher email template please input Daily, Weekly, or Monthly.">
                    <Grid item xs={10} style={{ margin: "1rem 0" }}>
                        <AcxMainTextField
                            value={emailStore.templateName ?? ""}
                            labelText="Template Name"
                            required
                            disableTooltip
                            focus
                            onChange={(e) =>
                                emailStore.handleTemplateNameChange(e)
                            }
                            id="organization-workflow-email-template-name-input"
                        />
                    </Grid>
                </Tooltip>
                <Tooltip title="Right-Click to inject template variables into the Subject line.">
                    <Grid item xs={10} style={{ margin: "0 0 1rem 0" }}>
                        <OrganizationWorkflowEmailContextMenu
                            children={
                                <AcxMainTextField
                                    disableTooltip
                                    value={emailStore.subject ?? ""}
                                    labelText="Subject"
                                    required
                                    onChange={(e) =>
                                        emailStore.handleSubjectChange(e)
                                    }
                                    id="organization-workflow-email-subject-input"
                                />
                            }
                            setter={setSubjectVariable}
                            context="SUBJECT"
                        />
                    </Grid>
                </Tooltip>

                <Grid item xs={12} className={classes.quillContainer}>
                    <Typography variant="caption" color="secondary">
                        ** Variables cannot be altered with text markup (bold,
                        italics, etc).
                    </Typography>

                    <Tooltip title="Right-Click to inject template variables into the Body content.">
                        <div style={{ height: "100%", width: "100%" }}>
                            <OrganizationWorkflowEmailContextMenu
                                children={<div ref={quillRef} />}
                                setter={setEmailVariable}
                                context="BODY"
                            />
                        </div>
                    </Tooltip>
                </Grid>
            </Grid>
        );
    },
);

export default OrganizationWorkflowsEmailEditor;
