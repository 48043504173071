import {
    ClickAwayListener,
    Grid,
    IconButton,
    Popper,
    Theme,
    Tooltip,
    Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import DateRangeIcon from "@mui/icons-material/DateRange";
import MenuIcon from "@mui/icons-material/Menu";
import clsx from "clsx";
import AcxInputLabel from "components/UI/AcxInputLabel";
import AcxMainTextField from "components/UI/AcxMainTextField";
import _, { startCase } from "lodash";
import moment, { Moment } from "moment";
import React, { CSSProperties } from "react";
import {
    allowedDateRangeLabels,
    DatePickerComponentStore,
    DateReferenceOption,
    InteractionDateReferenceOption,
    getDateFromDateRangeLabel,
} from "stores/ComponentStores/DatePickerComponentStore";
import { uuidv4 } from "utils/helpers";
import hexToRGB from "utils/hexToRGB";
import Calendar from "../Calendar";
import AcxDateTypeSelector from "./AcxDateTypeSelector";
import { observer } from "mobx-react";
import { useTheme } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
    input: {
        border: "none",
        fontSize: theme.typography.fontSize,
    },
    textContainer: {
        border: "none",
        height: "32px",
        alignItems: "center",
        textAlign: "center",
    },
    container: (props: IAcxDateRangeInput) => ({
        border: "1px solid",
        borderColor: theme.palette.lightgrayBorder.main,
        backgroundColor: theme.palette.white.main,
        borderRadius: "3px",
        height: "32px",
        maxWidth: props.maxWidth ?? "350px",
        minWidth: props.wrapperStyle ? "100%" : "220px",
    }),
    containerErr: {
        borderColor: theme.palette.error.main,
    },
    containerFocus: {
        borderColor: theme.palette.secondary.main,
    },
    dateTypeIcon: {
        color: hexToRGB(theme.palette.black.main, 0.4),
    },
    iconButton: {
        color: hexToRGB(theme.palette.black.main, 0.4),
    },
    iconButtonErr: {
        color: hexToRGB(theme.palette.error.main, 0.8),
    },
    iconButtonFocus: {
        color: hexToRGB(theme.palette.secondary.main, 0.8),
    },
    helperText: {
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.text.primary,
        fontSize: "12px",
        lineHeight: "16px",
    },
    helperTextError: {
        color: theme.palette.error.main,
    },
    labelContainer: {
        opacity: 1,
    },
    textLabel: {
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.text.primary,
        fontSize: "12px",
        lineHeight: "16px",
    },
    required: {
        "&:after": {
            color: theme.palette.red.main,
            content: '" *" !important',
        },
    },
    textLabelFocused: {
        color: theme.palette.primary.main,
        fontSize: "16px",
        fontWeight: 700,
    },
    popper: {
        zIndex: 9001,
        '&[x-placement*="bottom"] $arrow': {
            top: 0,
            left: 0,
            marginTop: "-0.09em",
            width: "3em",
            height: "1em",
            "&::before": {
                borderWidth: "0 1em 1em 1em",
                borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
            },
        },
        '&[x-placement*="top"] $arrow': {
            bottom: 0,
            left: 0,
            marginBottom: "-0.9em",
            width: "3em",
            height: "1em",
            "&::before": {
                borderWidth: "1em 1em 0 1em",
                borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
            },
        },
        '&[x-placement*="right"] $arrow': {
            left: 0,
            marginLeft: "-0.9em",
            height: "3em",
            width: "1em",
            "&::before": {
                borderWidth: "1em 1em 1em 0",
                borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
            },
        },
        '&[x-placement*="left"] $arrow': {
            right: 0,
            marginRight: "-0.9em",
            height: "3em",
            width: "1em",
            "&::before": {
                borderWidth: "1em 0 1em 1em",
                borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
            },
        },
    },
    arrow: {
        position: "absolute",
        fontSize: 9,
        width: "3em",
        height: "3em",
        "&::before": {
            content: '""',
            margin: "auto",
            display: "block",
            width: 0,
            height: 0,
            borderStyle: "solid",
        },
    },
    dateTypeContainer: {
        width: "150px",
    },
    popperContainer: {
        marginTop: theme.spacing(1),
        backgroundColor: theme.palette.white.main,
        padding: theme.spacing(2),
        borderRadius: "8px",
        border: "0.5px solid var(--neutral-200, #D1D5DB)",
        boxShadow: "0px 8px 16px 0px rgba(23, 37, 76, 0.12)",
    },
    dashContainer: {
        textAlign: "center",
        height: "100%",
        lineHeight: "32px",
    },
    dropdownWidth: {
        minWidth: "100%",
    },
}));

interface IAcxDateRangeInput {
    onSelect?: (
        selectedStartDate: moment.Moment,
        selectedEndDate: moment.Moment,
    ) => void;
    onDateTypeChange?(dateType: string): void;
    onBlur?: () => void;
    onFocus?: () => void;
    defaultStartDate?: moment.Moment;
    defaultEndDate?: moment.Moment;
    labelText?: string;
    required?: boolean;
    wrapperStyle?: CSSProperties;
    maxWidth?: string;
    displayDateType?: boolean;
    dateReferenceOptions?:
        | DateReferenceOption[]
        | InteractionDateReferenceOption[];
    datePickerStore?: DatePickerComponentStore;
    inputStyle?: CSSProperties;
    innerStyles?: CSSProperties;
    hideLabel?: boolean;
    sideBar?: boolean;
    doubleCalendar?: boolean;
}

export default observer(function AcxDateRangeInput(props: IAcxDateRangeInput) {
    const classes = useStyles(props);
    const theme = useTheme();
    const containerRef = React.useRef<HTMLDivElement>(null);

    const [openCalendarPopover, setOpenCalendarPopover] = React.useState(false);
    const [openDateTypePopover, setOpenDateTypePopover] = React.useState(false);

    const [arrowRef, setArrowRef] = React.useState<HTMLSpanElement | null>(
        null,
    );

    const [warning, setWarning] = React.useState(false);

    const [selectedStartDate, setSelectedStartDate] = React.useState<
        Moment | undefined
    >(props.defaultStartDate ?? moment().subtract(30, "days"));

    const [selectedEndDate, setSelectedEndDate] = React.useState<
        Moment | undefined
    >(props.defaultEndDate ?? moment());

    const [choosingBegin, setChoosingBegin] = React.useState(false);

    const [dateChange, setDateChanged] = React.useState(false); //used to figure out what the user did with the range calendar

    const [shouldOnSelect, setShouldOnSelect] = React.useState(false);

    const [hasFocus, setHasFocus] = React.useState(false);
    const [endFocus, setEndFocus] = React.useState(false);
    const [beginFocus, setBeginFocus] = React.useState(false);

    const onDateClick = (dt: moment.Moment) => {
        if (choosingBegin) {
            if (dt.valueOf() !== selectedStartDate?.valueOf()) {
                setDateChanged(true);
            }

            setSelectedStartDate(dt);
            setChoosingBegin(false);
        } else {
            setSelectedEndDate(dt);

            setOpenCalendarPopover(false);
            setShouldOnSelect(true);
        }
    };

    const onCalendarClick = () => {
        setChoosingBegin(true);
        setOpenCalendarPopover(!openCalendarPopover);
    };

    const onDateTypeClick = () => {
        setOpenDateTypePopover(!openDateTypePopover);
    };

    const handleClickAway = () => {
        setChoosingBegin(false);
        setOpenCalendarPopover(false);
        setOpenDateTypePopover(false);

        if (dateChange) {
            setDateChanged(false);
            setShouldOnSelect(true);
        }
    };

    const onSelect = React.useCallback(() => {
        if (selectedStartDate && selectedEndDate) {
            props.onSelect?.(
                selectedStartDate.clone(),
                selectedEndDate.clone(),
            );
        }
    }, [props, selectedEndDate, selectedStartDate]);

    const debouncedOnSelect = React.useMemo(
        () => _.debounce(() => onSelect(), 500),
        [onSelect],
    );

    const debouncedSetWarning = React.useMemo(
        () => _.debounce((val: boolean) => setWarning(val), 500),
        [],
    );

    React.useEffect(() => {
        if (shouldOnSelect) {
            debouncedOnSelect();
            setShouldOnSelect(false);
        }
    }, [selectedStartDate, selectedEndDate, debouncedOnSelect, shouldOnSelect]);

    React.useEffect(() => {
        setSelectedStartDate(props.defaultStartDate);
        setSelectedEndDate(props.defaultEndDate);
    }, [props.defaultStartDate, props.defaultEndDate]);

    const validDateString = (dt: string) => {
        const reg =
            /^([0-9]|[0-1][0-2])\/([1-9]|[0-2][0-9]|[3][0-1])\/(19|20)\d{2}$/;

        const fmtTest = reg.test(dt);
        return fmtTest;
    };

    const onStartDateChange = (el: React.ChangeEvent<HTMLInputElement>) => {
        const val = el.currentTarget.value;
        debouncedOnSelect.cancel();
        if (!validDateString(val)) {
            debouncedSetWarning(true);
        } else {
            setShouldOnSelect(true);
            setSelectedStartDate(moment(val));
            debouncedSetWarning.cancel();
            setWarning(false);
        }
    };

    const onEndDateChange = (el: React.ChangeEvent<HTMLInputElement>) => {
        const val = el.currentTarget.value;
        debouncedOnSelect.cancel();
        if (!validDateString(val)) {
            debouncedSetWarning(true);
        } else {
            setShouldOnSelect(true);
            setSelectedEndDate(moment(val));
            // setRenderEndDate(moment(val));
            debouncedSetWarning.cancel();
            setWarning(false);
        }
    };

    const onBlur = () => {
        setHasFocus(false);
        props.onBlur?.();
    };

    const onFocus = () => {
        setHasFocus(true);
        props.onFocus?.();
    };

    const handleSelectedDateOptionChange = (
        inputVal: allowedDateRangeLabels,
    ) => {
        props.datePickerStore?.setDateRangePreset(inputVal);
        let [startDate, endDate] = getDateFromDateRangeLabel(inputVal);
        setSelectedStartDate(startDate);
        setSelectedEndDate(endDate);
        setShouldOnSelect(true);
        setOpenCalendarPopover(false);
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div style={props?.wrapperStyle ?? { maxWidth: "300px" }}>
                <Grid container>
                    <Grid item style={props?.inputStyle ?? {}}>
                        {!props.hideLabel && (
                            <AcxInputLabel
                                id={`date-range-label-${uuidv4()}`}
                                labelText={
                                    props.labelText ??
                                    (props.displayDateType
                                        ? `Select ${startCase(
                                              props.datePickerStore
                                                  ?.referenceOption,
                                          )} Range`
                                        : "")
                                }
                                focused={hasFocus}
                                className={clsx({
                                    [classes.textLabelFocused]: hasFocus,
                                    [classes.required]:
                                        props.required &&
                                        !selectedStartDate &&
                                        !selectedEndDate,
                                    [classes.textLabel]: true,
                                })}
                            />
                        )}

                        <Grid
                            container
                            item
                            xs={12}
                            sx={{
                                border: "1px solid",
                                borderColor: theme.palette.lightgrayBorder.main,
                                backgroundColor: theme.palette.white.main,
                                borderRadius: "3px",
                                height: "32px",
                                maxWidth: props.maxWidth ?? "350px",
                                minWidth: props.wrapperStyle ? "100%" : "220px",
                            }}
                            className={clsx(classes.container, {
                                [classes.containerErr]: warning,
                                [classes.containerFocus]: hasFocus && !warning,
                            })}
                            ref={containerRef}
                            style={props.innerStyles ?? {}}
                        >
                            <Grid container item xs={1}>
                                <Tooltip
                                    title="Select calendar input"
                                    placement="top"
                                >
                                    <IconButton
                                        size="small"
                                        sx={{
                                            color: hasFocus
                                                ? theme.palette.primary.main
                                                : hexToRGB(
                                                      theme.palette.black.main,
                                                      0.4,
                                                  ),
                                        }}
                                        className={clsx(classes.iconButton, {
                                            [classes.iconButtonErr]: warning,
                                            [classes.iconButtonFocus]:
                                                hasFocus && !warning,
                                        })}
                                        onClick={onCalendarClick}
                                    >
                                        <DateRangeIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>

                            <Grid
                                container
                                item
                                xs={
                                    props.datePickerStore &&
                                    props.displayDateType
                                        ? 10
                                        : 11
                                }
                                justifyContent={"center"}
                                alignItems={"center"}
                            >
                                <Grid item xs={4}>
                                    <AcxMainTextField
                                        onBlur={() => {
                                            onBlur();
                                            setBeginFocus(false);
                                        }}
                                        onFocus={onFocus}
                                        onChange={onStartDateChange}
                                        value={selectedStartDate?.format(
                                            "M/D/YYYY",
                                        )}
                                        placeholderText="begindate"
                                        id={`date-input-begin-${uuidv4()}`}
                                        focus={beginFocus}
                                        onLeaveRight={() => setEndFocus(true)}
                                        textContainerClass={
                                            classes.textContainer
                                        }
                                        centerAlignInputText
                                        disablePadding
                                        fullWidth
                                        containerClass="calInput"
                                    />
                                </Grid>

                                <Grid item xs={1}>
                                    <Typography component="div">
                                        <div
                                            style={{
                                                textAlign: "center",
                                                height: "100%",
                                                lineHeight: "32px",
                                            }}
                                        >
                                            -
                                        </div>
                                    </Typography>
                                </Grid>

                                <Grid item xs={4}>
                                    <AcxMainTextField
                                        onBlur={() => {
                                            onBlur();
                                            setEndFocus(false);
                                        }}
                                        onFocus={onFocus}
                                        onChange={onEndDateChange}
                                        value={selectedEndDate?.format(
                                            "M/D/YYYY",
                                        )}
                                        placeholderText="enddate"
                                        focus={endFocus}
                                        onLeaveLeft={() => {
                                            setEndFocus(false);
                                            setBeginFocus(true);
                                        }}
                                        id={`date-input-end-${uuidv4()}`}
                                        textContainerClass={
                                            classes.textContainer
                                        }
                                        centerAlignInputText
                                        disablePadding
                                        fullWidth
                                        containerClass="calInput"
                                    />
                                </Grid>
                            </Grid>

                            {props.datePickerStore && props.displayDateType && (
                                <Grid
                                    container
                                    item
                                    xs={1}
                                    style={{
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    <Tooltip
                                        placement="top"
                                        title="Select date type"
                                    >
                                        <IconButton
                                            size="small"
                                            onClick={onDateTypeClick}
                                            className={clsx(
                                                classes.dateTypeIcon,
                                            )}
                                        >
                                            <MenuIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>

                {warning && (
                    <AcxInputLabel
                        id={`AcxDateRangeInput-BottomLabel-${uuidv4()}`}
                        className={clsx({
                            [classes.helperText]: true,
                            [classes.helperTextError]: warning,
                        })}
                    >
                        {"Please enter a date in M/D/YYYY format"}
                    </AcxInputLabel>
                )}

                <Popper
                    anchorEl={containerRef.current}
                    open={openCalendarPopover}
                    disablePortal={false}
                    className={classes.popper}
                    placement="bottom-start"
                    modifiers={[
                        {
                            name: "flip",
                            enabled: false,
                        },
                        {
                            name: "preventOverflow",
                            enabled: true,
                            options: {
                                boundariesElement: "window", // Note: 'boundariesElement' has been replaced with 'boundary' in Popper.js v2
                            },
                        },
                        {
                            name: "arrow",
                            enabled: true,
                            options: {
                                element: arrowRef,
                            },
                        },
                    ]}
                >
                    <span className={classes.arrow} ref={setArrowRef} />

                    <div className={classes.popperContainer}>
                        <Calendar
                            onSelection={onDateClick}
                            selectedStartDate={selectedStartDate}
                            selectedEndDate={selectedEndDate}
                            sideBar={props.sideBar}
                            handleSelectedDateOptionChange={
                                handleSelectedDateOptionChange
                            }
                            doubleCalendar={props.doubleCalendar}
                            choosingBegin={choosingBegin}
                            isRangeSelect
                        />
                    </div>
                </Popper>
                <Popper
                    anchorEl={containerRef.current}
                    open={openDateTypePopover}
                    disablePortal={false}
                    className={classes.popper}
                    placement="bottom-end"
                    modifiers={[
                        {
                            name: "flip",
                            enabled: false,
                        },
                        {
                            name: "preventOverflow",
                            enabled: true,
                            options: {
                                boundariesElement: "window", // Note: 'boundariesElement' has been replaced with 'boundary' in Popper.js v2
                            },
                        },
                        {
                            name: "arrow",
                            enabled: true,
                            options: {
                                element: arrowRef,
                            },
                        },
                    ]}
                >
                    <span className={classes.arrow} ref={setArrowRef} />

                    <div className={classes.popperContainer}>
                        {props.datePickerStore && props.displayDateType && (
                            <Grid item className={classes.dateTypeContainer}>
                                <AcxDateTypeSelector
                                    datePickerStore={props.datePickerStore}
                                    onChange={props.onDateTypeChange}
                                    dateReferenceOptions={
                                        props.dateReferenceOptions ?? []
                                    }
                                />
                            </Grid>
                        )}
                    </div>
                </Popper>
            </div>
        </ClickAwayListener>
    );
});
