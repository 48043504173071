import { Paper, Table, TableContainer, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import { Observer, useLocalObservable } from "mobx-react-lite";
import React from "react";
import useStyles from "Styles/Styles";
import AcxLoadingIndicator from "../AcxLoadingIndicator";
import AcxTableBody from "./AcxTableBody";
import AcxTableHeader from "./AcxTableHeader";
import AcxTablePageFooter from "./AcxTablePageFooter";
import AcxTableViewModel from "./AcxTableViewModel";

const styles = (theme: Theme) =>
    createStyles({
        tableContainer: {
            boxShadow:
                "0 0 3px 0 rgba(0, 0, 0, 0.05), 0 1px 1px 0 rgba(0, 0, 0, 0.05)",
            overscrollBehaviorX: "contain",
            overscrollBehaviorY: "auto",
        },
    });

export interface IAcxTableColumn {
    headerLabel: string;
    dataKey: string;
    formatter?:
        | ((arg0: any, row: any) => React.ReactElement)
        | ((arg0: any, row: any) => string);
    valueGetter?: (dataValue: any) => any;
}

export interface IAcxTableRow {
    [key: string]: any;
}

export interface Props {
    rows: IAcxTableRow[];
    columns: IAcxTableColumn[];
    enableSort?: boolean;
    enableCheck?: boolean;
    keyField?: string;
    onSelecteditems?: (selecteditems: any[]) => void;
    isLoading?: boolean;
    selectedItems?: string[];
    disabledItems?: string[];
    virtualize?: boolean; //Overrides paging. Renders rows on scroll
    showPaging?: boolean;
    onPageChange?: (page: number) => void;
    rowCount?: number;
    rowsPerPage?: number;
    onRowsPerPageChange?: (rowsPerPage) => void;
    enableStripes?: boolean;
    enableHover?: boolean;
    cellDense?: boolean;
    removeHeightInPx?: string;
    skeletonRows?: number;
}

const AcxTable = (props: Props) => {
    const classes = useStyles(styles);
    const viewMod = useLocalObservable(() => new AcxTableViewModel());

    React.useEffect(() => {
        viewMod.onSelectedItem = props.onSelecteditems;
        viewMod.onPageChange = props.onPageChange;
        viewMod.onRowsPerPageChange = props.onRowsPerPageChange;
        viewMod.enableSort = props.enableSort ?? false;
        viewMod.keyField = props.keyField ?? "id";
        viewMod.enableCheck = props.enableCheck ?? false;
        viewMod.tableCols.splice(0, viewMod.tableCols.length, ...props.columns);

        viewMod.isLoading = props.isLoading ? props.isLoading : false;
        viewMod.skeletonRows = props.skeletonRows || 0;
        viewMod.count = props.rowCount ?? 0;
        viewMod.rowsPerPage = props.rowsPerPage ?? 25;
        viewMod.enableDense = props.cellDense ?? false;
        viewMod.enableHover = props.enableHover ?? false;
        viewMod.enableStripes = props.enableStripes ?? true;
        viewMod.removeHeightInPx = props.removeHeightInPx ?? "0px";
        viewMod.virtualize = props.virtualize ?? false;
        if (props.virtualize) {
            viewMod.showPaging = false;
        } else {
            viewMod.showPaging = props.showPaging ?? false;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        props.onSelecteditems,
        props.onPageChange,
        props.onRowsPerPageChange,
        props.enableSort,
        props.keyField,
        props.isLoading,
        props.columns,
        props.rowCount,
        props.rowsPerPage,
        props.enableHover,
        props.cellDense,
        props.enableStripes,
        props.removeHeightInPx,
        props.virtualize,
        props.enableCheck,
        props.showPaging,
    ]);

    React.useEffect(() => {
        viewMod.rows = [...props.rows];

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.rows]);

    React.useEffect(() => {
        if (props.selectedItems) {
            viewMod.selectedItems.replace(props.selectedItems);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedItems]);

    React.useEffect(() => {
        if (!props.disabledItems) return;
        viewMod.disabledItems = [...props.disabledItems];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.disabledItems]);

    return (
        <Observer>
            {() => (
                <>
                    <TableContainer
                        className={classes.tableContainer}
                        component={Paper}
                        style={{
                            position: "relative",
                            maxHeight:
                                "calc(100% - " + viewMod.removeHeightInPx + ")",
                        }}
                    >
                        <Table style={{ height: "100%" }}>
                            <AcxTableHeader viewModel={viewMod} />
                            <AcxTableBody viewMod={viewMod} />
                            {viewMod.showPaging && (
                                <AcxTablePageFooter viewModel={viewMod} />
                            )}
                        </Table>

                        {viewMod.isLoading && !viewMod.skeletonRows && (
                            <AcxLoadingIndicator
                                color="primary"
                                alternate="PuffLoader"
                                size={100}
                                style={{ margin: "1rem 0" }}
                            />
                        )}
                    </TableContainer>
                </>
            )}
        </Observer>
    );
};

export default AcxTable;
