import React from "react";
import { AgentMessage } from "../AgentMessage";
import { useStore } from "utils/useStore";
import AgentChatStore from "../../AgentChatStore";
import { ButtonBase, Grid, styled } from "@mui/material";
import { MakeAChoiceActionValue } from "components/Agent/Models/ThreadAction";

interface MakeAChoiceProps {
    choices: MakeAChoiceActionValue;
}

const ChoiceButton = styled(ButtonBase)(({ theme }) => ({
    textDecoration: "underline",
}));

export function MakeAChoiceMessage(props: MakeAChoiceProps) {
    const store = useStore(AgentChatStore);

    return (
        <AgentMessage
            className={"pendo-ignore"}
            container
            item
            direction="column"
            rowGap={1}
            flexWrap="nowrap"
        >
            {props.choices.map((choice) => (
                <Grid item>
                    <ChoiceButton
                        onClick={() =>
                            store.streamingSubmitInput({
                                manualInput: !choice.Description
                                    ? choice.Name
                                    : `${choice.Name} - ${choice.Description}`,
                            })
                        }
                    >
                        {choice.Name}
                    </ChoiceButton>
                    {choice.Description && ` - ${choice.Description}`}
                </Grid>
            ))}
        </AgentMessage>
    );
}
