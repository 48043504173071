import { CircularProgress, Grid } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import Gutters from "Layouts/Responsive/Gutters";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import AcxButton from "components/UI/AcxButton";
import AcxDataGrid from "components/UI/AcxDataGrid/AcxDataGrid";
import { dateTimeColumnType } from "components/UI/AcxDataGrid/ColumnTypes/DateColTypes";
import IColDef from "components/UI/AcxDataGrid/IColDef";
import AcxDateRangeInput from "components/UI/Calendar/DateRange/AcxDateRangeInput";
import VerticalDrawerContentTemplate from "components/UI/Drawer/VerticalDrawerContentTemplate";
import { Observer, observer } from "mobx-react";
import ProcessFailure from "models/ProcessFailure";
import { Moment } from "moment";
import React, { useEffect } from "react";
import { AuthStore } from "stores/AuthStore";
import { useStore } from "utils/useStore";
import AcxSelect from "../../../../UI/Select/BaseSelectComponents/AcxSelect";
import { ReprocessFailureLocations } from "./Models/RequeueModels";
import RequeueStore from "./RequeueStore";

const Requeue = observer(() => {
    const store = useStore(RequeueStore);
    const authStore = useStore(AuthStore);
    const messageStore = useStore(MessageStore);

    const reprocessFailureOptions = [
        {
            label: "Location to Reprocess",
            value: ReprocessFailureLocations.LocationToReprocess,
        },
        {
            label: "Audio Video Metadata Processor",
            value: ReprocessFailureLocations.AudioVideoMetadataProcessor,
        },
        {
            label: "Transcribe Media",
            value: ReprocessFailureLocations.TranscribeMedia,
        },
        {
            label: "Redaction",
            value: ReprocessFailureLocations.Redaction,
        },
        {
            label: "Index Transcription",
            value: ReprocessFailureLocations.IndexTranscription,
        },
    ];

    const refreshData = async () => {
        try {
            await store.updateFailures();
        } catch {
            messageStore.logError(
                "Unable to fetch pipeline failures. Please retry.",
            );
        }
    };

    const requeueFailures = async () => {
        try {
            await store.requeueFailures();
            messageStore.logMessage(
                "Successfully requeued item(s).",
                "success",
            );
        } catch (error) {
            messageStore.logError("One or more of the requeued items failed.");
            refreshData();
        }
    };

    const reprocessFailures = async () => {
        try {
            await store.reprocessFaults();
            messageStore.logMessage(
                "Successfully reprocessed item(s).",
                "success",
            );
        } catch (error) {
            messageStore.logError(
                "One or more of the reprocessed items failed.",
            );
            refreshData();
        }
    };

    const openFaults = async (params: GridRenderCellParams) => {
        try {
            store.selectedFailure = params.row as ProcessFailure;
            await store.updateFaults(params.row as ProcessFailure);
            store.drawerStore.closeAndResetDrawer();
            store.drawerStore.restoreDefaults();

            store.drawerStore.setContentFactory(() => (
                <Grid
                    container
                    item
                    xs={12}
                    style={{ height: "100%", width: "900px" }}
                >
                    <VerticalDrawerContentTemplate
                        title={"Faults"}
                        subTitle={store.selectedFailure?.fileName}
                        width={"900px"}
                        content={
                            <div style={{ height: "calc(100% - 2rem)" }}>
                                <div style={{ height: "100%" }}>
                                    {store.faults &&
                                    !store.faults.find(
                                        (fault) =>
                                            fault.correlationId !==
                                            store.selectedFailure?.id,
                                    ) ? (
                                        <AcxDataGrid
                                            dataGridStore={store.faultDgStore}
                                        />
                                    ) : (
                                        <CircularProgress />
                                    )}
                                </div>
                            </div>
                        }
                        onClose={() => {}}
                    />
                </Grid>
            ));

            store.drawerStore.setOpen(true);
        } catch (error) {
            store.selectedFailure = undefined;
            messageStore.logError(
                "Unable to fetch pipeline faults. Please retry.",
            );
            store.drawerStore.setOpen(false);
        }
    };

    useEffect(() => {
        if (
            !store.failures ||
            !store.failures.find(
                (failure) =>
                    failure.organizationId ===
                    authStore.orgStore.selectedOrganization?.id,
            )
        ) {
            store.updateFailures();
        }
    }, [authStore.orgStore.selectedOrganization?.id, store]);

    const columns: IColDef[] = [
        {
            headerName: "File Name",
            field: "fileName",
            type: "string",
        },
        {
            headerName: "Failed On",
            field: "processFailedOn",
            ...dateTimeColumnType,
        },
        {
            headerName: "Failure Message",
            field: "message",
            type: "string",
        },
        {
            headerName: "Failure Source",
            field: "source",
            type: "string",
        },
        {
            headerName: "Failure Type",
            field: "exceptionType",
            type: "string",
        },
    ];

    const faultColumns: IColDef[] = [
        {
            headerName: "Id",
            field: "id",
            type: "string",
        },
        {
            headerName: "Faulted On",
            field: "faultedOn",
            ...dateTimeColumnType,
        },
        {
            headerName: "Contract Name",
            field: "contractName",
            type: "string",
        },
        {
            headerName: "Fault Message",
            field: "message",
            type: "string",
        },
        {
            headerName: "Error Details",
            field: "exceptionDetails",
            type: "string",
        },
        {
            headerName: "Correlation Id",
            field: "correlationId",
            type: "string",
        },
        {
            headerName: "Organization Id",
            field: "organizationId",
            type: "string",
        },
        {
            headerName: "Name",
            field: "displayName",
            type: "string",
        },
        {
            headerName: "Description",
            field: "description",
            type: "string",
        },
    ];

    const controls = [
        {
            controlElement: (
                <AcxSelect
                    defaultValue={reprocessFailureOptions[0]}
                    options={reprocessFailureOptions}
                    onChange={(item) => {
                        store.setSelectedFailureLocation(item.value);
                    }}
                    id="reprocess-failures-select-option"
                    valueField="value"
                    labelField="label"
                    placeholder="Select a location for reprocessing"
                />
            ),
            style: { width: "200px" },
        },
        <AcxButton
            tooltip="Send Failures Back to Selected Location in Pipeline"
            onClick={reprocessFailures}
            buttonDisabled={
                !store.requeueDgStore.selectedRows ||
                store.requeueDgStore.selectedRows.length <= 0 ||
                store.requeueDgStore.isLoading ||
                store.getTaskLoading("Requeue")
            }
            loading={store.getTaskLoading("Requeue")}
            leftRightSpacing={0}
        >
            Reprocess Faults
        </AcxButton>,
        <AcxButton
            tooltip="Send Failures Back to Pipeline"
            onClick={requeueFailures}
            buttonDisabled={
                !store.requeueDgStore.selectedRows ||
                store.requeueDgStore.selectedRows.length <= 0 ||
                store.requeueDgStore.isLoading ||
                store.getTaskLoading("Requeue")
            }
            loading={store.getTaskLoading("Requeue")}
            leftRightSpacing={0}
        >
            Requeue Faults
        </AcxButton>,
        <AcxButton
            leftRightSpacing={0}
            tooltip="Refresh data."
            fullWidth={false}
            color="secondary"
            onClick={refreshData}
            customRootStyles={{ height: "32px" }}
            loading={store.requeueDgStore.isLoading}
            buttonDisabled={store.requeueDgStore.isLoading}
        >
            <RefreshIcon />
        </AcxButton>,
        <Observer>
            {() => (
                <AcxDateRangeInput
                    labelText={"Select Date"}
                    defaultStartDate={store.datePickerStore.beginDate}
                    defaultEndDate={store.datePickerStore.endDate}
                    onSelect={(start: Moment, end: Moment) => {
                        store.datePickerStore.setBeginDate(start);
                        store.datePickerStore.setEndDate(end);
                        refreshData();
                    }}
                />
            )}
        </Observer>,
    ];

    //Data grid store setup function
    useEffect(() => {
        if (authStore.isUserUltra()) {
            columns.push({
                headerName: "Fault List",
                field: "fault",
                type: "string",
                width: 250,
                renderCell: (params) => (
                    <AcxButton onClick={() => openFaults(params)}>
                        Open Faults
                    </AcxButton>
                ),
            });
        }
        store.requeueDgStore.setColumns(columns);
        store.requeueDgStore.controls = controls;
        store.faultDgStore.setColumns(faultColumns);
    });

    return (
        <Gutters>
            {<AcxDataGrid dataGridStore={store.requeueDgStore} />}
        </Gutters>
    );
});

export default Requeue;
