import CachedIcon from "@mui/icons-material/Cached";
import { Button, ButtonGroup, Grid } from "@mui/material";
import AcxButton from "components/UI/AcxButton";
import AcxDataGrid from "components/UI/AcxDataGrid/AcxDataGrid";
import { CustomControlItem } from "components/UI/AcxDataGrid/AcxDataGridStore";
import {
    dateColumnType,
    interactionDateColumnType,
} from "components/UI/AcxDataGrid/ColumnTypes/DateColTypes";
import { durationColumnType } from "components/UI/AcxDataGrid/ColumnTypes/DurationColType";
import IColDef from "components/UI/AcxDataGrid/IColDef";
import AcxDateRangeInput from "components/UI/Calendar/DateRange/AcxDateRangeInput";
import { Observer, observer } from "mobx-react";
import { Moment } from "moment";
import React from "react";
import { AuthStore } from "stores/AuthStore";
import { useStore } from "utils/useStore";
import { basicDateReferenceOptions } from "../../../../stores/ComponentStores/DatePickerComponentStore";
import ManagerInteractionsStore, {
    LOAD_UNASSIGNED,
} from "../../Stores/ManagerInteractionsStore";

const UnassignedInteractions = observer(() => {
    const store = useStore(ManagerInteractionsStore);
    const orgStore = useStore(AuthStore).orgStore;

    React.useEffect(() => {
        if (orgStore && orgStore.selectedOrganization) {
            store.orgSelectStore.selectOrg(orgStore.selectedOrganization);
        }
    }, [orgStore, orgStore?.selectedOrganization, store]);

    const getCols = () => {
        const columns: IColDef[] = [];

        columns.push({ headerName: "Name", field: "blobFileKey", flex: 1 });
        columns.push({
            headerName: "Arrived On",
            field: "arrivedOn",
            ...dateColumnType,
        });
        columns.push({
            headerName: "Interaction Date",
            field: "timestamp",
            ...interactionDateColumnType,
        });
        columns.push({ headerName: "Agent", field: "agentName" });
        columns.push({
            headerName: "Call Length",
            field: "callDurationMillis",
            ...durationColumnType,
        });
        return columns;
    };

    const getControls = (): CustomControlItem[] => {
        const dateRange = (
            <Grid item container justifyContent={"flex-end"}>
                <Observer>
                    {() => (
                        <AcxDateRangeInput
                            defaultStartDate={
                                store.unassignedDatePickerStore.beginDate
                            }
                            defaultEndDate={
                                store.unassignedDatePickerStore.endDate
                            }
                            onSelect={(start: Moment, end: Moment) => {
                                store.unassignedDatePickerStore.setBeginDate(
                                    start,
                                );
                                store.unassignedDatePickerStore.setEndDate(end);
                            }}
                            displayDateType
                            dateReferenceOptions={basicDateReferenceOptions}
                            datePickerStore={store.unassignedDatePickerStore}
                        />
                    )}
                </Observer>
            </Grid>
        );

        const assignButton = (
            <Grid item container justifyContent={"flex-end"}>
                <ButtonGroup
                    color="primary"
                    fullWidth
                    style={{ height: "32px" }}
                    disableElevation
                    disabled={
                        !store.allowReassign ||
                        !store.unassignedDgStore.selectedRowIds.length
                    }
                    variant={"outlined"}
                >
                    <Button
                        sx={{
                            textTransform: "none",
                            fontWeight: "bold",
                            textDecoration: "none",
                        }}
                        onClick={() => store._dialogStore.dialogStore.open()}
                    >
                        Assign
                    </Button>
                </ButtonGroup>
            </Grid>
        );

        const refreshTable = (
            <Observer>
                {() => (
                    <Grid
                        item
                        container
                        justifyContent="flex-end"
                        style={{ paddingRight: "0.5rem" }}
                    >
                        <AcxButton
                            fullWidth={false}
                            leftRightSpacing={0}
                            buttonDisabled={store.getTaskLoading(
                                LOAD_UNASSIGNED,
                            )}
                            marginLeftAuto={true}
                            marginRightAuto={true}
                            height="32px"
                            tooltip="Refresh Table"
                            color="secondary"
                            onClick={() =>
                                store.reloadInteractions("Unassigned")
                            }
                        >
                            <CachedIcon />
                        </AcxButton>
                    </Grid>
                )}
            </Observer>
        );

        return [
            { controlElement: dateRange },
            { controlElement: assignButton },
            { controlElement: refreshTable },
        ];
    };

    const controls = getControls();
    store.unassignedDgStore.setColumns(getCols());
    store.unassignedDgStore.controls = controls;
    store.unassignedDgStore.controlsColumnSpan = 12;
    store.unassignedDgStore.density = "compact";
    store.unassignedDgStore.checkboxSelection = true;

    return (
        <>
            <div
                style={{
                    height: "calc(100% - 24px)",
                    paddingLeft: "16px",
                    paddingRight: "16px",
                    paddingBottom: "16px",
                }}
            >
                <AcxDataGrid dataGridStore={store.unassignedDgStore} />
            </div>
        </>
    );
});

export default UnassignedInteractions;
