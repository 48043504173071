import { LicenseInfo } from "@mui/x-data-grid-pro";
import AdminApp from "components/Admin/AdminNavigation/AdminAppContainer";
import AuthorizeRouteV6 from "components/Auth/api-authorization-next/AuthorizeRouteV6";
import ChangePasswordUser from "components/Auth/ChangePasswordUser";
import ForgotPasswordUser from "components/Auth/ForgotPasswordUser";
import LoginUser from "components/Auth/LoginUser";
import PasswordSentUser from "components/Auth/PasswordSentUser";
import Saml from "components/Auth/Saml";
import SessionExpiredUser from "components/Auth/SessionExpiredUser";
import EvalMain from "components/Evaluation/EvalMain";
import Layout from "components/Navigation/App";
import TranscriptionDashboard from "components/NLP/TranscriptionDashboard";
import AcxIdleTimer from "components/UtilityComponents/AcxIdleTimer";
import FocusContainer from "Layouts/Focus/FocusContainer";
import FocusContainerRv2 from "Layouts/Focus/FocusContainerRv2";
import { configure } from "mobx";
import { Observer } from "mobx-react";
import React, { Context } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import WebFontLoader from "webfontloader";
import {
    ApplicationPaths,
    LoginActions,
    LogoutActions,
} from "./components/Auth/api-authorization/ApiAuthorizationConstants";
import { Login } from "./components/Auth/api-authorization/Login";
import { Logout } from "./components/Auth/api-authorization/Logout";
import { Routes as AppRoutes } from "./components/Navigation/Routes";
import * as serviceWorker from "./serviceWorker";
import { IRootStore, newRootStore } from "./stores/RootStore";
import EnterSubdomain from "components/Auth/EnterSubdomain";
import Consent from "components/Auth/Consent";
import WorkflowReviewContainer from "components/WorkflowReview/WorkflowReviewContainer";
import SaveConnection from "components/Auth/SaveConnection";

configure({
    enforceActions: "never", //always
    computedRequiresReaction: false,
    reactionRequiresObservable: false,
    observableRequiresReaction: false,
    disableErrorBoundaries: false,
    safeDescriptors: true,
});

LicenseInfo.setLicenseKey(
    "74a6e1b4e2fb8e9e0f350fd7d1639e28T1JERVI6MjA3ODMsRVhQSVJZPTE2NDM2MzIxMjIwMDAsS0VZVkVSU0lPTj0x",
);

const webFontConfig = {
    custom: {
        families: ["Inter", "Inter Bold", "Catamaran"],
        urls: ["/Fonts/fonts.css"],
    },
};
// function loginAction(name) {
//     return <Login action={name}></Login>;
// }

// function logoutAction(name) {
//     return <Logout action={name}></Logout>;
// }

class App {
    private rootContext;
    public RootStoreContext = () => {
        if (this.rootContext === undefined) {
            this.rootContext = React.createContext(newRootStore());
        }
        return this.rootContext as Context<IRootStore>;
    };

    execute = () => {
        WebFontLoader.load(webFontConfig);

        const root = (
            <Observer>
                {() => (
                    <>
                        {
                            <BrowserRouter>
                                <Routes>
                                    <Route
                                        path={
                                            ApplicationPaths.ApiAuthorizationPrefix
                                        }
                                    >
                                        <Route
                                            path={ApplicationPaths.Login}
                                            element={
                                                <Login
                                                    action={LoginActions.Login}
                                                />
                                            }
                                        />
                                        <Route
                                            path={ApplicationPaths.LoginFailed}
                                            element={
                                                <Login
                                                    action={
                                                        LoginActions.LoginFailed
                                                    }
                                                />
                                            }
                                        />
                                        <Route
                                            path={
                                                ApplicationPaths.LoginCallback
                                            }
                                            element={
                                                <Login
                                                    action={
                                                        LoginActions.LoginCallback
                                                    }
                                                />
                                            }
                                        />
                                        <Route
                                            path={ApplicationPaths.Profile}
                                            element={
                                                <Login
                                                    action={
                                                        LoginActions.Profile
                                                    }
                                                />
                                            }
                                        />
                                        <Route
                                            path={ApplicationPaths.Register}
                                            element={
                                                <Login
                                                    action={
                                                        LoginActions.Register
                                                    }
                                                />
                                            }
                                        />
                                        <Route
                                            path={ApplicationPaths.LogOut}
                                            element={
                                                <Logout
                                                    action={
                                                        LogoutActions.Logout
                                                    }
                                                />
                                            }
                                        />
                                        <Route
                                            path={
                                                ApplicationPaths.LogOutCallback
                                            }
                                            element={
                                                <Logout
                                                    action={
                                                        LogoutActions.LogoutCallback
                                                    }
                                                />
                                            }
                                        />
                                        <Route
                                            path={ApplicationPaths.LoggedOut}
                                            element={
                                                <Logout
                                                    action={
                                                        LogoutActions.LoggedOut
                                                    }
                                                />
                                            }
                                        />
                                    </Route>
                                    <Route
                                        path="/auth/LoginUser"
                                        element={<LoginUser />}
                                    />

                                    <Route
                                        path="/connect"
                                        element={<EnterSubdomain />}
                                    />

                                    <Route
                                        path="/connect/consent"
                                        element={<Consent />}
                                    />

                                    <Route
                                        path={ApplicationPaths.SaveConnection}
                                        element={<SaveConnection />}
                                    />

                                    <Route
                                        path="/reset"
                                        element={<ForgotPasswordUser />}
                                    />

                                    <Route
                                        path="/resetmessage"
                                        element={<PasswordSentUser />}
                                    />

                                    <Route
                                        path="/changepassword"
                                        element={<ChangePasswordUser />}
                                    />

                                    <Route
                                        path="/sessionexpired"
                                        element={<SessionExpiredUser />}
                                    />

                                    <Route path="/saml" element={<Saml />} />

                                    <Route
                                        path="/admin/*"
                                        element={
                                            <AuthorizeRouteV6>
                                                <AcxIdleTimer>
                                                    <AdminApp />
                                                </AcxIdleTimer>
                                            </AuthorizeRouteV6>
                                        }
                                    ></Route>

                                    <Route
                                        path="/transcription/status"
                                        element={
                                            <AuthorizeRouteV6>
                                                <AcxIdleTimer>
                                                    <TranscriptionDashboard />
                                                </AcxIdleTimer>
                                            </AuthorizeRouteV6>
                                        }
                                    ></Route>

                                    <Route
                                        path={AppRoutes.EVALUATION}
                                        element={
                                            <AuthorizeRouteV6>
                                                <AcxIdleTimer>
                                                    <EvalMain />
                                                </AcxIdleTimer>
                                            </AuthorizeRouteV6>
                                        }
                                    ></Route>
                                    <Route
                                        path={AppRoutes.WORKFLOW_REVIEW}
                                        element={
                                            <AuthorizeRouteV6>
                                                <AcxIdleTimer>
                                                    <WorkflowReviewContainer />
                                                </AcxIdleTimer>
                                            </AuthorizeRouteV6>
                                        }
                                    />
                                    <Route
                                        path="/focus/*"
                                        element={
                                            <AuthorizeRouteV6>
                                                <AcxIdleTimer>
                                                    <FocusContainer />
                                                </AcxIdleTimer>
                                            </AuthorizeRouteV6>
                                        }
                                    ></Route>

                                    <Route
                                        path="/RV2/focus/*"
                                        element={
                                            <AuthorizeRouteV6>
                                                <AcxIdleTimer>
                                                    <FocusContainerRv2 />
                                                </AcxIdleTimer>
                                            </AuthorizeRouteV6>
                                        }
                                    ></Route>

                                    <Route
                                        path="/app/*"
                                        element={
                                            <AuthorizeRouteV6>
                                                <AcxIdleTimer>
                                                    <Layout />
                                                </AcxIdleTimer>
                                            </AuthorizeRouteV6>
                                        }
                                    ></Route>
                                    <Route
                                        path="/"
                                        element={<Navigate to="/app" replace />}
                                    />
                                </Routes>
                            </BrowserRouter>
                        }
                    </>
                )}
            </Observer>
        );

        const app = document.getElementById("root") as HTMLElement;
        const domRoot = createRoot(app);
        domRoot.render(root);

        // ReactDOM.render(root, document.getElementById("root") as HTMLElement);

        // If you want your app to work offline and load faster, you can change
        // unregister() to register() below. Note this comes with some pitfalls.
        // Learn more about service workers: https://bit.ly/CRA-PWA
        serviceWorker.unregister();
    };
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new App();
