import { Grid, IconButton, Paper, Theme, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import clsx from "clsx";
import { Routes } from "components/Navigation/Routes";
import AcxMicroPlayer from "components/UI/Audio/AcxMicroPlayer";
import { observer } from "mobx-react";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useStore } from "utils/useStore";
import ConversationsStore from "../../../Stores/ConversationsStore";
import ConversationSummary from "./ConversationSummary";
import { ConversationData } from "services/ConversationService";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.05)",
        borderRadius: "8px",
    },
    linkContainer: {
        cursor: "pointer",
        minHeight: "40px",
    },
    link: {
        textDecoration: "none",
        width: "100%",
    },
    playButton: {
        color: theme.palette.secondary.main,
        fontSize: "40px",
    },
    pauseButton: {
        color: theme.palette.secondary.main,
        fontSize: "50px",
    },
    padding: {
        padding: theme.spacing(2),
    },
    noPadding: { padding: 0 },
}));

type Props = {
    conversation: ConversationData;
};

const UnfocusedConversation: React.FC<Props> = observer(({ conversation }) => {
    const classes = useStyles();
    const store = useStore(ConversationsStore);
    const navigate = useNavigate();

    const agentFullName = conversation.agentFirstName
        ? conversation.agentFirstName + " " + conversation.agentLastName
        : "Unknown Agent";

    return (
        <Paper className={classes.container} elevation={0}>
            <Grid item container>
                <Grid
                    container
                    className={clsx("pendo-ignore", classes.linkContainer)}
                    item
                    xs={11}
                >
                    <Link
                        to={`${conversation.conversationId}/`}
                        className={classes.link}
                        onClick={() =>
                            store.setSelectedConversationId(
                                conversation.conversationId,
                            )
                        }
                    >
                        <ConversationSummary
                            summary={conversation.summary}
                            callDurationMillis={conversation.callDurationMillis}
                            timestamp={conversation.timestamp}
                            conversationClassifiers={
                                conversation.conversationClassifiers
                            }
                            agentFullName={agentFullName}
                            selectedClassifierIds={
                                store.selectedContainsClassifierIds
                            }
                            highlights={conversation.highlights}
                            hasTranslation={conversation.hasTranslation}
                            isChat={
                                conversation.mediaType === 6 &&
                                conversation.callDurationMillis === 0
                            }
                            chatMessageCount={
                                conversation.chatTranscription?.length
                            }
                        />
                    </Link>
                </Grid>
                <Grid
                    item
                    container
                    xs={1}
                    alignContent="space-between"
                    justifyContent="center"
                    className={classes.padding}
                >
                    <Grid item xs={12} container justifyContent="flex-end">
                        {conversation.evalType ? (
                            <IconButton
                                className={classes.noPadding}
                                onClick={() => {
                                    if (
                                        store.authStore.orgStore
                                            .selectedOrganization?.id &&
                                        conversation.evaluationId
                                    ) {
                                        navigate(
                                            Routes.makeEvaluationRoute(
                                                store.authStore.orgStore
                                                    .selectedOrganization?.id,
                                                conversation.evaluationId,
                                            ),
                                        );
                                    }
                                }}
                                size="large"
                            >
                                <CheckCircleOutlineIcon
                                    color={
                                        conversation.evalType ===
                                        "MiddleOfTheFunnel"
                                            ? "primary"
                                            : "secondary"
                                    }
                                />
                            </IconButton>
                        ) : (
                            <span>
                                {!!store.authStore.orgStore
                                    .selectedOrganization &&
                                store.authStore.canUserEdit(
                                    "Sample Single Conversation",
                                ) ? (
                                    <Tooltip
                                        title={
                                            conversation.evalType ===
                                            "MiddleOfTheFunnel"
                                                ? "AI Evaluation"
                                                : conversation.evalType
                                                ? "Human Evaluation"
                                                : !!store.authStore.orgStore
                                                      .selectedOrganization
                                                ? "Sample this Conversation"
                                                : "No Evaluation"
                                        }
                                    >
                                        <IconButton
                                            className={classes.noPadding}
                                            onClick={() => {
                                                store.getSingleConversation(
                                                    conversation.conversationId,
                                                );
                                                store.toggleEvaluateConversationDialogOpen();
                                            }}
                                            size="large"
                                        >
                                            <AddCircleOutlineIcon color="disabled" />
                                        </IconButton>
                                    </Tooltip>
                                ) : (
                                    <IconButton
                                        className={classes.noPadding}
                                        disabled
                                        size="large"
                                    >
                                        <AddCircleOutlineIcon color="disabled" />
                                    </IconButton>
                                )}
                            </span>
                        )}
                    </Grid>
                    <Grid item xs={12} container justifyContent="flex-end">
                        {!!conversation.callDurationMillis && (
                            <AcxMicroPlayer
                                mediaUrl={conversation.mediaUrl}
                                style={{
                                    color: "#3364D6",
                                }}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
});

export default UnfocusedConversation;
