import { Grid, Paper, Box } from "@material-ui/core";
import AcxChip from "components/UI/AcxChip";
import AcxPieGraph from "components/UI/Visualization/AcxPieGraph";
import { observer } from "mobx-react";
import React, { useEffect, useCallback } from "react";
import theme from "Theme/AppTheme";
import AcxSelectSingle from "components/UI/Select/BaseSelectComponents/AcxSelectSingle";
import WorkflowStore from "components/Workflows/Stores/WorkflowStore";
import { uuidv4 } from "utils/helpers";
import { toJS } from "mobx";
import { useStore } from "utils/useStore";
import AWOverviewChartStore from "../../Stores/AWOverviewChartStore";

type Props = {
    store: WorkflowStore;
};

const AWOverviewChart: React.FC<Props> = observer(({ store }) => {
    const chartStore = useStore(AWOverviewChartStore);

    const overviewChartFilterId = "overview-chart-filter";

    useEffect(() => {
        let pieSeriesNames: string[] = [];

        const prioritySeriesNames = ["Priority", "Normal"];
        const statusSeriesNames = ["In Progress", "Closed", "Overdue"];

        const pieDataCounts: [string, number][] = [];

        if (chartStore.selectedChartType === "Priority") {
            pieSeriesNames = prioritySeriesNames;
        } else if (chartStore.selectedChartType === "Status") {
            pieSeriesNames = statusSeriesNames;
        }

        pieSeriesNames.forEach((seriesName: string) => {
            const countCurrentRow = (row) => {
                const rowIsVisible =
                    !!store.dgStore.filterModel?.items.length &&
                    store.dgStore.apiRef?.current?.state?.visibleRows
                        ?.visibleRows
                        ? store.dgStore.apiRef?.current?.state?.visibleRows?.visibleRows?.includes(
                              row.id,
                          )
                        : true;
                if (chartStore.selectedChartType === "Status") {
                    let statusRealName = seriesName;
                    if (seriesName === "In Progress") {
                        statusRealName = "Open";
                    }
                    return row.currentStatus === statusRealName && rowIsVisible;
                } else if (chartStore.selectedChartType === "Priority") {
                    return seriesName === "Priority"
                        ? row.highPriority && rowIsVisible
                        : !row.highPriority && rowIsVisible;
                } else {
                    return false;
                }
            };

            const seriesCount =
                store.dgStore.rows.filter(countCurrentRow).length;

            pieDataCounts.push([seriesName, seriesCount]);
        });

        chartStore.setChartData([["Status", "Counts"], ...pieDataCounts]);
    }, [
        store.dgStore.rows,
        store.dgStore.filterModel,
        chartStore.selectedChartType,
        store.dgStore.apiRef,
        chartStore,
    ]);

    const clearOnlyChartFilterItems = useCallback(() => {
        const newFilterModel = toJS(store.dgStore?.filterModel);
        if (newFilterModel?.items) {
            newFilterModel.items = newFilterModel.items.filter(
                (i) => {
                    return typeof i.id !== "string" ? true : false;
                }, // default filter items have numeric ids, filter items added from overviewchart have string ids
            );
            store.dgStore.setFilterModel(newFilterModel);
        }
    }, [store.dgStore]);

    const handleFilterFromOverviewChart = (seriesName: string) => {
        if (chartStore.selectedSeriesName === seriesName) {
            handleResetChartFilters();
            return;
        }

        if (chartStore.selectedSeriesName) {
            // need to clear previous filter if it was already filtered
            clearOnlyChartFilterItems();
        }

        chartStore.setSelectedSeriesName(seriesName);

        if (chartStore.selectedChartType === "Priority") {
            store.dgStore.setFilterModel({
                ...store.dgStore.filterModel,
                items: [
                    ...(store.dgStore.filterModel?.items ?? []),
                    {
                        columnField: "highPriority",
                        id: overviewChartFilterId + uuidv4(),
                        operatorValue:
                            seriesName === "Normal" ? "isEmpty" : "isNotEmpty",
                        value: undefined,
                    },
                ],
            });
        } else if (chartStore.selectedChartType === "Status") {
            store.dgStore.setFilterModel({
                ...store.dgStore.filterModel,
                items: [
                    ...(store.dgStore.filterModel?.items ?? []),
                    {
                        columnField: "currentStatus",
                        id: overviewChartFilterId + uuidv4(),
                        operatorValue: "contains",
                        value: seriesName,
                    },
                ],
            });
        }
    };

    const buildChipForPieData = (i: string[], index: number) => {
        const seriesName = i[0];
        const seriesCount = Number(i[1]);

        const seriesBgColor = chartStore.chartColors[seriesName]?.[2];

        const chipColor = chartStore.chartColors[seriesName]?.[1] as any;

        const countEl = (
            <Grid
                container
                item
                style={{ width: "100%", height: "100%" }}
                alignItems="center"
                xs={12}
                wrap="nowrap"
            >
                <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={{
                        color: "white",
                        backgroundColor: seriesBgColor,
                        height: "36px",
                        width: "48px",
                        marginRight: theme.spacing(1),
                        fontSize: "12px",
                    }}
                >
                    {seriesCount.toLocaleString()}
                </Grid>
                <Grid item style={{ lineHeight: "initial" }}>
                    {seriesName}
                </Grid>
            </Grid>
        );

        return (
            <Grid item container xs={6} key={index}>
                <AcxChip
                    size="medium"
                    color={chipColor}
                    label={countEl}
                    style={{
                        width: "100%",
                        height: "36px",
                        justifyContent: "flex-start",
                        border:
                            seriesName === chartStore.selectedSeriesName
                                ? "1px solid"
                                : "",
                        borderColor:
                            seriesName === chartStore.selectedSeriesName
                                ? seriesBgColor
                                : "",
                        opacity:
                            chartStore.selectedSeriesName &&
                            seriesName !== chartStore.selectedSeriesName
                                ? 0.3
                                : 1,
                    }}
                    onClick={() => {
                        handleFilterFromOverviewChart(seriesName);
                    }}
                    removeLeftLabelPadding
                />
            </Grid>
        );
    };

    const noOverviewDataPlaceholder = (
        <Grid
            container
            item
            justifyContent={"center"}
            style={{
                height: "100%",
                width: "100%",
                paddingTop: "12px",
            }}
        >
            <div
                style={{
                    border: "60px solid",
                    borderColor: theme.palette.neutralGray.main,
                    borderRadius: "50%",
                    height: "270px",
                    width: "270px",
                }}
            ></div>
        </Grid>
    );

    const handleResetChartFilters = () => {
        clearOnlyChartFilterItems();
        chartStore.setSelectedSeriesName("");
    };

    const handleChartSelect = (series, x, y) => {
        if (x) {
            handleFilterFromOverviewChart(x.toString());
        }
    };

    useEffect(() => {
        // check if filter from chart was cleared from filter panel
        if (
            !store.dgStore.filterModel?.items.some((i) =>
                i.id?.toString().includes(overviewChartFilterId),
            )
        ) {
            chartStore.setSelectedSeriesName("");
        }
    }, [chartStore, store.dgStore.filterModel]);

    return (
        <Paper>
            <Grid
                container
                item
                style={{ padding: theme.spacing(2) }}
                justifyContent="space-between"
            >
                <Grid item>
                    <Box fontWeight={600} fontSize={16}>
                        Workflow Overview
                    </Box>
                </Grid>
                <Grid item>
                    <AcxSelectSingle
                        options={[
                            { value: "Status", label: "Status" },
                            {
                                value: "Priority",
                                label: "Priority",
                            },
                        ]}
                        id="workflow-overview-type-select"
                        valueField="value"
                        labelField="label"
                        defaultValue={{
                            value: chartStore.selectedChartType,
                            label: chartStore.selectedChartType,
                        }}
                        onChange={(item) => {
                            chartStore.setCurrentChartType(
                                item.value as "Priority" | "Status",
                            );
                            handleResetChartFilters();
                        }}
                    />
                </Grid>
            </Grid>
            <Grid
                item
                style={{
                    height: "300px",
                }}
                onClick={() => {
                    if (chartStore.selectedSeriesName) {
                        handleResetChartFilters();
                    }
                }}
            >
                {chartStore.chartData.filter((i) => i[1]).length > 1 ? (
                    <AcxPieGraph
                        data={chartStore.chartData}
                        legendPosition="none"
                        sliceText="none"
                        colorScheme={Object.entries(chartStore.chartColors).map(
                            (i) => i[1][0],
                        )}
                        onSelect={handleChartSelect}
                        useAlternateRowLabel
                        containerHeight="100%"
                    />
                ) : (
                    noOverviewDataPlaceholder
                )}
            </Grid>
            <Grid
                container
                item
                style={{ padding: theme.spacing(2) }}
                spacing={1}
            >
                {chartStore.chartData.slice(1).map(buildChipForPieData)}
            </Grid>
        </Paper>
    );
});

export default AWOverviewChart;
