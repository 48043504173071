import {
    GridCellValue,
    GridColDef,
    GridColTypeDef,
    GridComparatorFn,
    GridFilterInputValueProps,
    GridFilterItem,
    GridFilterOperator,
    GridSortCellParams,
    GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import AcxCalendarPopup from "components/UI/Calendar/AcxCalendarPopup";
import _ from "lodash";
import moment, { Moment } from "moment";
import React from "react";
import {
    parseFromISO,
    standardDateFormat,
    standardDateTimeFormat,
} from "utils/DateTimeUtils";
import { CUSTOM_FILTERS } from "../FilterOverride/CustomFilters";

export const dateComparator: GridComparatorFn = (
    v1: GridCellValue,
    v2: GridCellValue,
    param1: GridSortCellParams,
    param2: GridSortCellParams,
): number => {
    const row1 = param1.api.getRow(param1.id);
    const row2 = param2.api.getRow(param2.id);

    const p1 = _.get(row1, param1.field)?.toString();
    const p2 = _.get(row2, param2.field)?.toString();

    if (!p1) return 1;
    if (!p2) return -1;
    return p1.localeCompare(p2);
};

export const interactionDateComparator: GridComparatorFn = (
    v1: GridCellValue,
    v2: GridCellValue,
    param1: GridSortCellParams,
    param2: GridSortCellParams,
): number => {
    const row1 = param1.api.getRow(param1.id);
    const row2 = param2.api.getRow(param2.id);

    let value1 = _.get(row1, param1.field)?.toString();
    let value2 = _.get(row2, param2.field)?.toString();

    value1 = interactionDateHelper(value1);
    value2 = interactionDateHelper(value2);

    const p1 = standardDateFormat(parseFromISO(value1, false)) ?? "";
    const p2 = standardDateFormat(parseFromISO(value2, false)) ?? "";

    if (!p1) return 1;
    if (!p2) return -1;
    return p1.localeCompare(p2);
};

export const dateComparatorMoment: GridComparatorFn = (
    v1: GridCellValue,
    v2: GridCellValue,
    param1: GridSortCellParams,
    param2: GridSortCellParams,
): number => {
    const row1 = param1.api.getRow(param1.id);
    const row2 = param2.api.getRow(param2.id);

    const p1 = moment(_.get(row1, param1.field)?.toString());
    const p2 = moment(_.get(row2, param2.field)?.toString());

    if (!p1.isValid()) return 1;
    if (!p2.isValid()) return -1;
    if (p1.isSame(p2)) return 0;
    return p1.isBefore(p2) ? -1 : 1;
};

export const DateFilter = (props: GridFilterInputValueProps) => {
    const { item, applyValue } = props;
    const [dt, setDt] = React.useState(moment(item.value) ?? moment());

    const handleFilterChange = (d: Moment) => {
        setDt(d);
        applyValue({
            ...item,
            value: standardDateFormat(parseFromISO(d.toISOString(), false)),
        });
    };

    return (
        <AcxCalendarPopup
            inputLabel="Select Date"
            id="data-grid-date-filter"
            currentDate={dt}
            onSelect={handleFilterChange}
            popperPlacement="left"
        />
    );
};

export const dateFilterOperators: GridFilterOperator[] = [
    {
        label: "On",
        value: "on",
        getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
            if (
                !filterItem.columnField ||
                !filterItem.value ||
                !filterItem.operatorValue
            ) {
                return null;
            }

            return (params): boolean => {
                const rowValue = _.get(params.row, column.field);
                const filterValue = filterItem.value;

                if (rowValue === null || rowValue === "") {
                    return false;
                }

                return moment(getDateString(rowValue)).isSame(filterValue);
            };
        },
        InputComponent: DateFilter,
    },
    {
        label: "Before",
        value: "before",
        getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
            if (
                !filterItem.columnField ||
                !filterItem.value ||
                !filterItem.operatorValue
            ) {
                return null;
            }

            return (params): boolean => {
                const rowValue = _.get(params.row, column.field);
                const filterValue = filterItem.value;

                if (rowValue === null || rowValue === "") {
                    return false;
                }

                return moment(getDateString(rowValue)) < moment(filterValue);
            };
        },
        InputComponent: DateFilter,
    },
    {
        label: "After",
        value: "after",
        getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
            if (
                !filterItem.columnField ||
                !filterItem.value ||
                !filterItem.operatorValue
            ) {
                return null;
            }

            return (params): boolean => {
                const rowValue = _.get(params.row, column.field);
                const filterValue = filterItem.value;

                if (rowValue === null || rowValue === "") {
                    return false;
                }

                return moment(getDateString(rowValue)) > moment(filterValue);
            };
        },
        InputComponent: DateFilter,
    },
    {
        label: "On or Before",
        value: "onOrBefore",
        getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
            if (
                !filterItem.columnField ||
                !filterItem.value ||
                !filterItem.operatorValue
            ) {
                return null;
            }

            return (params): boolean => {
                const rowValue = _.get(params.row, column.field);
                const filterValue = filterItem.value;

                if (rowValue === null || rowValue === "") {
                    return false;
                }

                return moment(getDateString(rowValue)) <= moment(filterValue);
            };
        },
        InputComponent: DateFilter,
    },
    {
        label: "On or After",
        value: "onOrAfter",
        getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
            if (
                !filterItem.columnField ||
                !filterItem.value ||
                !filterItem.operatorValue
            ) {
                return null;
            }

            return (params): boolean => {
                const rowValue = _.get(params.row, column.field);
                const filterValue = filterItem.value;

                if (rowValue === null || rowValue === "") {
                    return false;
                }

                return moment(getDateString(rowValue)) >= moment(filterValue);
            };
        },
        InputComponent: DateFilter,
    },
    ...CUSTOM_FILTERS,
];

export const dateTimeColumnType: GridColTypeDef = {
    extendType: "dateTime",
    valueFormatter: (params: GridValueFormatterParams) => {
        const value = _.get(params.row, params.field)?.toString();
        return value ? standardDateTimeFormat(parseFromISO(value, false)) : "";
    },
    sortComparator: dateComparator,
    filterOperators: dateFilterOperators,
};

export const dateColumnType: GridColTypeDef = {
    extendType: "date",
    valueFormatter: (params: GridValueFormatterParams) => {
        const value = _.get(params.row, params.field)?.toString();
        return value ? standardDateFormat(parseFromISO(value, false)) : "";
    },
    sortComparator: dateComparator,
    filterOperators: dateFilterOperators,
};

export const dateIgnoreTimeColumnType: GridColTypeDef = {
    extendType: "date",
    valueFormatter: (params: GridValueFormatterParams) => {
        const value = _.get(params.row, params.field)?.toString();
        return value ? standardDateFormat(parseFromISO(value, true)) : "";
    },
    sortComparator: dateComparator,
    filterOperators: dateFilterOperators,
};

export const interactionDateColumnType: GridColTypeDef = {
    extendType: "date",
    valueFormatter: (params: GridValueFormatterParams) => {
        let value = _.get(params.row, params.field)?.toString();
        value = interactionDateHelper(value);
        return value ? standardDateFormat(parseFromISO(value, false)) : "";
    },
    sortComparator: interactionDateComparator,
    filterOperators: dateFilterOperators,
};

export const interactionDateTimeColumnType: GridColTypeDef = {
    extendType: "date",
    valueFormatter: (params: GridValueFormatterParams) => {
        let value = _.get(params.row, params.field)?.toString();
        value = interactionDateHelper(value);
        return value ? standardDateTimeFormat(parseFromISO(value, false)) : "";
    },
    sortComparator: interactionDateComparator,
    filterOperators: dateFilterOperators,
};

export const interactionDateHelper = (
    value?: string | moment.Moment | Date,
): string | moment.Moment | undefined => {
    if (value) {
        value = moment.utc(value);

        // check if timestamp is 00:00:00 and set to noon to avoid day change on local conversion
        if (
            value.hours() === 0 &&
            value.minutes() === 0 &&
            value.seconds() === 0
        ) {
            value.set("hour", 12);
        }
        value = value.local();
    }
    return value;
};

const getDateString = (rowValue: string): string => {
    let dateString;

    if (rowValue.includes("T")) {
        dateString = rowValue.split("T")[0];
    } else {
        // file dashboard arrived on dates are different format from other dates
        dateString = rowValue.split(" ")[0];
    }
    return dateString;
};
