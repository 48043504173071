import { IconButton, InputAdornment, TextField, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import WarningIcon from "@mui/icons-material/Warning";
import React from "react";
import useStyles from "Styles/Styles";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            "& label.Mui-focused": {
                color: theme.palette.blue.main,
            },
            "& MuiFormLabel-root": {
                "&.Mui-error": {
                    borderColor: theme.palette.secondary.main,
                },
            },
        },
        cssLabel: {
            "&$marginDense": {
                transform: "translate(14px, 4px) scale(1)",
            },
            "&$shrink": {
                transform: "translaste(14px, -14px) scales(0.75) !important",
            },
            "&$cssFocused": {
                color: theme.palette.blue.main,
            },
        },
        outlinedRoot: {
            "&$marginDense": {
                transform: "translate(14px, 4px) scale(1)",
            },
            "&$shrink": {
                transform: "translaste(14px, -14px) scales(0.75) !important",
            },
            "& $notchedOutline": {
                borderColor: theme.palette.black.main,
            },
            "&:hover $notchedOutline": {
                borderColor: theme.palette.blue.main,
            },
            "&$focused $notchedOutline": {
                borderColor: theme.palette.blue.main,
            },
        },
        notchedOutline: {},
        focused: {},
        cssFocused: {},
        marginDense: {},
        shrink: {},
    });

function ErrorAdornment(props) {
    return (
        <InputAdornment position="end">
            <IconButton aria-label="error warning" size="small">
                <WarningIcon color="error" fontSize="small" />
            </IconButton>
        </InputAdornment>
    );
}

export interface ITextFieldProps {
    id: string;
    type: string;
    fullWidth: boolean;
    label: string;
    placeholder: string;
    helperText: string;
    error: boolean;
    required: boolean;
    onChange?: (e: any) => void;
    other?: any;
    autoFocus?: boolean;
    autoComplete?: string;
}
export default function AcxTextField(props: ITextFieldProps) {
    const classes = useStyles(styles);

    let InputProps = {
        classes: {
            root: classes.outlinedRoot,
            notchedOutline: classes.notchedOutline,
            focused: classes.focused,
        },
        className: classes.outlinedRoot,
        endAdornment: ErrorAdornment({ color: "red" }),
    };
    const InputLabelProps = {
        classes: {
            root: classes.cssLabel,
            focused: classes.cssFocused,
            outlined: classes.cssLabel,
        },
    };
    const getProps = () => {
        if (!props.error) {
            // @ts-ignore
            delete InputProps.endAdornment;
        }
        return InputProps;
    };

    return (
        <TextField
            id={props.id}
            type={props.type}
            label={props.label}
            placeholder={props.placeholder}
            variant="outlined"
            fullWidth={props.fullWidth}
            required={props.required}
            autoFocus={props.autoFocus}
            error={props.error}
            helperText={props.helperText}
            onChange={props.onChange}
            margin="dense"
            InputLabelProps={InputLabelProps}
            InputProps={getProps()}
            autoComplete={props.autoComplete}
            {...props.other}
        />
    );
}

AcxTextField.defaultProps = {
    autoFocus: false,
    placeholder: "",
    helperText: "",
    error: "",
    fullWidth: false,
    required: false,
};
