import { useTheme } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import AcxButton from "components/UI/AcxButton";
import React from "react";

interface IFilterIcon {
    onClick: () => void;
    filtered: boolean;
    vertIconEnabled: boolean;
    store: any;
}

const FilterIcon = React.forwardRef<HTMLDivElement, IFilterIcon>(
    (props: IFilterIcon, ref) => {
        const theme = useTheme();

        return (
            <div ref={ref}>
                <AcxButton
                    buttonDisabled={props.store.rows.length < 1}
                    onClick={props.onClick}
                    fullWidth={false}
                    color="secondary"
                    leftRightSpacing={1}
                    rootStyle={{
                        padding: "1rem",
                        minWidth: "max-content",
                        margin: `0px ${
                            props.vertIconEnabled ? "0.5rem" : "0px"
                        } 0.5rem 0px`,
                        backgroundColor: props.filtered
                            ? theme.palette.blue.main
                            : theme.palette.white.main,
                        color: props.filtered
                            ? theme.palette.white.main
                            : theme.palette.black.main,
                    }}
                    startIcon={<FilterListIcon />}
                >
                    Filter
                </AcxButton>
            </div>
        );
    },
);

export default FilterIcon;
