import { parseFromISO, standardDateFormat } from "utils/DateTimeUtils";
import { uuidv4 } from "utils/helpers";
import { OrganizationMetadataField } from "./OrganizationMetadataField";
import { OrganizationMetadataFileType } from "./OrganizationMetadataFileType";
import { OrganizationMetadataFileTypeExtension } from "./OrganizationMetadataFileTypeExtension";

export class Configuration {
    prefix?: string;
    nameSpace?: string;
    name?: string;
    metaDataFileType?: OrganizationMetadataFileType;
    metaDataFileTypeExtension?: OrganizationMetadataFileTypeExtension;
    isDebug?: boolean;
    chatFileSchemaHandler?: ChatFileSchemaHandlers;
    fields: MetadataField[] = [];
    campaignIndicatorDefinitions: CampaignIndicatorDefinition[] | null = [];
    languageIndicatorDefinitions: LanguageIndicatorDefinition[] | null = [];
    extendedFields: MetadataExtendedField[] | null = [];
    metaDataExtendedAssociationFields:
        | MetadataExtendedAssociationField[]
        | null = [];
    mediaSegments: MediaSegments | null;
    agentCodeRequired: boolean;
    customDateFormatPatterns: string[] | null;
}

export class OrganizationMetadataConfiguration {
    constructor(orgId: string) {
        this.organizationId = orgId;
    }
    configuration: Configuration = new Configuration();
    createdBy?: string = "";
    createdOn?: string = standardDateFormat(
        parseFromISO(new Date().toISOString(), true),
    );
    id?: string;
    isActive?: boolean;
    modifiedBy?: string = "";
    organizationId?: string;
}

interface MetaDataDefinition {
    id: string;
    isRequired: boolean;
    valuePath: string | null;
}

export interface MediaSegments {
    start: MetaDataDefinition;
    end: {
        id: string;
        primary: MetaDataDefinition;
        alternate: MetaDataDefinition;
    };
    agentName: MetaDataDefinition;
    agentId: MetaDataDefinition;
    id: string;
    rootPath: string;
}

export enum MetadataComparisonOperators {
    Equals,
    Contains,
}

export enum LocaleIds {
    EN = 1033,
    SPAN = 2058,
}

export enum JsonDataTypes {
    String = 3,
    Number = 4,
}

export enum ChatFileSchemaHandlers {
    Default = 0,
    LivePerson = 1,
    LivePersonV2 = 2,
    Abbvie = 3,
    CareSource = 4,
}

export class MetadataField {
    id: string = uuidv4();
    field?: OrganizationMetadataField;
    isRequired: boolean = false;
    xPathString: string = "";
    extractionPattern?: string;
}
export class CampaignIndicatorDefinition {
    id: string = uuidv4();
    fieldPath: string = "";
    campaignId: string = uuidv4();
    comparisonOperator: MetadataComparisonOperators = 0;
    campaignIndicatorStrings: string[] = [];
}

export class LanguageIndicatorDefinition {
    id: string = uuidv4();
    localeId: LocaleIds = 1033;
    fieldPath: string = "";
    comparisonOperator: MetadataComparisonOperators = 0;
    languageIndicatorStrings: string[] = [];
}

export class MetadataExtendedField {
    id: string = uuidv4();
    fieldName?: string;
    fieldPath?: string;
    isIndexed?: boolean;
    isRequired?: boolean;
    jsonDataType?: JsonDataTypes = 3;
    extractionPattern?: string;
}

export class MetadataExtendedAssociationField {
    id: string = uuidv4();
    metaDataField?: number;
    extendedMetadataFieldName?: string;
}
