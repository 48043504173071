import { Grid, Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { observer } from "mobx-react";
import React from "react";
import GhostGrapghSvg from "SvgIcons/GhostGrapghSvg";
import barGraph from "../../../../ghostImages/barGraph.png";
import histogram from "../../../../ghostImages/histogram.png";
import horizontalGraph from "../../../../ghostImages/horizontalGraph.png";
import lineGraph from "../../../../ghostImages/lineGraph.png";
import pieGraph from "../../../../ghostImages/pieGraph.png";
import scatterPlot from "../../../../ghostImages/scatterPlot.png";
import tableGraph from "../../../../ghostImages/tableGraph.png";
import { useStore } from "../../../../utils/useStore";
import { ReportEditorStore } from "../../Stores/ReportEditorStore";
import QuickFilterSection from "./EditorSections/QuickFilterSection";
import ReportDataSection from "./EditorSections/ReportDataSection";
import ReportDetailSection from "./EditorSections/ReportDetailSection";
import ReportPermissionSection from "./EditorSections/ReportPermissionSection";

const useStyles = makeStyles((theme: Theme) => ({
    sectionTitle: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: "bold",
        letterSpacing: "0",
        lineHeight: "24px",
        paddingBottom: theme.spacing(1),
    },
    vertSpace: {
        marginTop: theme.spacing(3),
    },

    sectionSpace: {
        marginTop: theme.spacing(6),
    },
    selectedQuickFiltersText: {
        marginTop: theme.spacing(3),
        fontSize: "16px",
        fontWeight: "bold",
        letterSpacing: "0",
        lineHeight: "24px",
        marginBottom: theme.spacing(1.5),
    },
    mainSectionTitle: {
        paddingTop: theme.spacing(5),
    },
    mainSectionTitleText: {
        fontFamily: "Inter",
        fontSize: "20px",
        paddingRight: theme.spacing(1),
        fontWeight: "bold",
        letterSpacing: "0",
        lineHeight: "28px",
    },
    mainSectionSubtitleText: {
        fontFamily: "Inter",
        opacity: "0.5",
        color: "#1F1F1F",
        fontSize: "16px",
        letterSpacing: "0",
        lineHeight: "24px",
        maxWidth: "290px",
        paddingRight: theme.spacing(1.4),
    },
    bulletedList: {
        // listStyle: "circle",
        fontFamily: "Inter",
        fontSize: "16px",
        letterSpacing: "0",
        lineHeight: "24px",
    },
    root: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(3),
    },
    savAsBtn: {
        marginTop: theme.spacing(2.5),
    },
    elementSpacing: {
        paddingBottom: "17px",
    },
}));

export enum QueryFilterOptions {
    None,
    Custom,
}

export enum QuickFilterOptions {
    STD,
    Custom,
}

export const ToggleFilterOptions = [
    {
        element: `Standard Quick Filters`,
        value: QuickFilterOptions.STD,
    },
    {
        element: `Custom Quick Filters`,
        value: QuickFilterOptions.Custom,
    },
];

interface OwnProps {}

type Props = OwnProps;

const Editor: React.FC<Props> = observer((props) => {
    const classes = useStyles();
    const store = useStore(ReportEditorStore);
    //const reportStore = useStore(ReportsStore);
    //const navigate = useNavigate();
    /*// eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { elementHeight, elementWidth, theRef } =
        useResizeObserver<HTMLDivElement>({
            debounceWait: 33,
        });

    const {
        elementHeight: controlsHeight,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } = useResizeObserver<HTMLDivElement>({
        debounceWait: 33,
    });
    function onSaveAs() {
        store.saveReportAs().then((value) => {
            if (value) {
                reportStore.quickFilterStore.reset();
                reportStore.refreshReports();
                navigate(-1);
            }
        });
    }*/

    function displayGhostImage() {
        console.log(store.currentReport?.vizType);
        return ghostImagesArray[store.currentReport?.vizType || 0];
    }
    const ghostImagesArray = [
        barGraph,
        lineGraph,
        barGraph,
        pieGraph,
        tableGraph,
        histogram,
        horizontalGraph,
        scatterPlot,
    ];
    return (
        <div>
            <Grid container className={classes.root}>
                {/*Report Details section*/}
                <Grid
                    container
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    direction="row"
                    wrap={"nowrap"}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <Grid
                        container
                        item
                        xl={7}
                        lg={7}
                        md={7}
                        sm={7}
                        xs={7}
                        direction="row"
                        wrap={"nowrap"}
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        style={{ paddingLeft: "2.5%" }}
                    >
                        <Grid item xs={12} container>
                            <ReportDetailSection />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        item
                        xl={5}
                        lg={5}
                        md={5}
                        sm={5}
                        xs={5}
                        direction="row"
                        wrap={"nowrap"}
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        style={{ paddingLeft: "1%" }}
                    >
                        <Grid
                            item
                            xs={12}
                            container
                            style={{
                                height: "21em",
                            }}
                        >
                            <GhostGrapghSvg
                                imageStyle={displayGhostImage()}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {/* Permission Section*/}
                <Grid
                    container
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    direction="row"
                    wrap={"nowrap"}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <Grid
                        container
                        item
                        xl={7}
                        lg={7}
                        md={7}
                        sm={7}
                        xs={7}
                        direction="row"
                        wrap={"nowrap"}
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        style={{ paddingLeft: "2.5%" }}
                    >
                        <Grid item xs={12} container>
                            <ReportPermissionSection />
                        </Grid>
                    </Grid>
                </Grid>
                {/* New Report Data Section */}
                <Grid
                    container
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    direction="row"
                    wrap={"nowrap"}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <Grid
                        container
                        item
                        xl={7}
                        lg={7}
                        md={7}
                        sm={7}
                        xs={7}
                        direction="row"
                        wrap={"nowrap"}
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        style={{ paddingLeft: "2.5%", paddingTop: "17px" }}
                    >
                        <Grid item xs={12} container>
                            <ReportDataSection />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        item
                        xl={5}
                        lg={5}
                        md={5}
                        sm={5}
                        xs={5}
                        direction="row"
                        wrap={"nowrap"}
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        style={{
                            paddingTop: "17px",
                            height: "100%",
                        }}
                    >
                        {/*
                        <Grid
                            item
                            xs={12}
                            container
                            style={{ position: "sticky", top: 0 }}
                        >
                            {reportStore.reportDataViewModel.currentReport
                                ?.vizType !== VizType.Table &&
                                buildReportVizType(
                                    reportStore.reportDataViewModel
                                        .currentReport?.vizType,
                                    reportStore.reportDataViewModel
                                        .emptyDataSet,
                                    reportStore.reportDataViewModel
                                        .googleFormattedData,
                                    reportStore.reportDataViewModel.isDrillDown
                                        ? reportStore.reportDataViewModel
                                              .drillReportData
                                        : reportStore.reportDataViewModel
                                              .tableReportData,
                                    reportStore.reportDataViewModel
                                        .currentReport?.xAxisLabel,
                                    reportStore.reportDataViewModel
                                        .currentReport?.yAxisLabel,
                                    reportStore.reportDataViewModel.isDrillDown,
                                    null,
                                    elementHeight,
                                    controlsHeight,
                                    false,
                                    {
                                        ...reportStore.currentReport
                                            ?.vizOptions,
                                    },
                                    reportStore.captureChartRef,
                                )}
                            {reportStore.reportDataViewModel.currentReport
                                ?.vizType === VizType.Table && (
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.elementSpacing}
                                    style={{
                                        height: "500px",
                                        paddingBottom: "30px",
                                    }}
                                >
                                    <AcxDataGrid
                                        dataGridStore={
                                            store.dgStorePreviewReport
                                        }
                                    />
                                </Grid>
                            )}
                        </Grid>
                        */}
                    </Grid>
                </Grid>

                {/* New Report Data Section */}
                {/** Quick Filter Section */}
                {store.availableReportQuickFilterFields && (
                    <Grid
                        container
                        item
                        xs={12}
                        direction="row"
                        wrap={"nowrap"}
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        style={{ paddingLeft: "2.5%", paddingTop: "17px" }}
                    >
                        <Grid item xs={12} container>
                            <QuickFilterSection />
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </div>
    );
});

export default Editor;
