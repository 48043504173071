import { Grid, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import WarningIcon from "@mui/icons-material/Warning";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import AcxLineGraph from "components/UI/Visualization/AcxLineGraph";
import AcxWordCloud from "components/UI/Visualization/AcxWordCloud";
import { memoize } from "lodash";
import { observer } from "mobx-react";
import { NGramPayload, ScoredNGram } from "models/NGram";
import React, { FunctionComponent } from "react";
import useStyles from "../../../../Styles/Styles";
import AcxHorizontalBarGraph from "../../../UI/Visualization/AcxHorizontalBarGraph";
import ClassifierReportStore, {
    CardStatus,
    getClassifierNgramsTaskName,
    getClassifierQueryTaskName,
    getClassifierStatsTaskName,
} from "../ClassifierReportStore";
import ReportCard from "./ReportCard";
import ReportCardQuery from "./ReportCardQuery";

const styles = () => {
    return createStyles({
        reportWrapper: {
            marginTop: "32px",
            width: "100%",
        },
        expansionInfoComponentError: {
            marginLeft: "auto",
            marginRight: "auto",
        },
        graphComponentError: {
            marginRight: "auto",
        },
        ngramComponentError: {
            marginLeft: "auto",
        },
        notification: {
            textAlign: "center",
        },
        boldTitleText: {
            fontWeight: "bold",
            textAlign: "center",
            fontSize: "1.5rem",
        },
    });
};

export type DataGenerator = (data?: NGramPayload) => ScoredNGram[];

interface IReportCardListProps {
    store: ClassifierReportStore;
}

const ReportCardList: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<IReportCardListProps>>> = observer(
    (props) => {
        const classes = useStyles(styles);
        const theme = useTheme();
        const colors = ["primary", "secondary", "orange"];

        // ToDo: without the disable this warns. Why do we need a memoize when useCallback memoizes?
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const GoogleBarChartFormat = React.useCallback(
            memoize((data: ScoredNGram[] | undefined, k: number): any[] => {
                const res =
                    data
                        ?.slice()
                        ?.sort((a, b) => b.score - a.score)
                        ?.slice(0, k) ?? [];

                const header: any[] = [
                    [
                        "Ngram",
                        "Count",
                        {
                            type: "string",
                            role: "annotation",
                        },
                    ],
                ];

                const d1 = res.map((value) => [
                    [value.nGram, value.score, value.nGram],
                ]);

                return header.concat(...d1);
            }),
            [],
        );

        const GoogleLineChartFormat = React.useCallback(
            (data: any[] | undefined): any[] => {
                const header: any[] = [["Date", "Interactions"]];
                const d1 = data?.map((value) => [new Date(value.x), value.y]);
                return header.concat(d1);
            },
            [],
        );

        return <>
            {" "}
            {[...props.store.selectedClassifiers]
                .map((value) => value)
                .map((value, indx) => {
                    const status = props.store.getCardStatus(value.id);
                    const mainColor = theme.palette[colors[indx % 3]].main;
                    const selectedData =
                        props.store.getSelectedWordCloudData(value.id);

                    const graphComponent = props.store.getTaskError(
                        getClassifierStatsTaskName(value.id),
                    ) ? (
                        <Tooltip
                            title={
                                props.store.getTaskError(
                                    getClassifierStatsTaskName(value.id),
                                )?.message ?? ""
                            }
                        >
                            <IconButton className={classes.graphComponentError} size="large">
                                <WarningIcon
                                    style={{
                                        color: theme.palette.red.main,
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <>
                            {props.store.statsForClassifier(value.id) && (
                                <>
                                    <Grid container justifyContent="center">
                                        <Tooltip
                                            title={
                                                "The number of interactions in which the classifier is present over the selected date range.\
                                                 This excludes live interactions."
                                            }
                                            placement={"top"}
                                        >
                                            <Grid
                                                style={{
                                                    marginRight: "5rem",
                                                }}
                                            >
                                                <Grid
                                                    item
                                                    className={
                                                        classes.boldTitleText
                                                    }
                                                >
                                                    {props.store.totalAppearancesForClassifier(
                                                        value.id,
                                                    )}
                                                </Grid>

                                                <Grid item>
                                                    {props.store.totalAppearancesForClassifier(
                                                        value.id,
                                                    ) === 1
                                                        ? "Total Interaction"
                                                        : "Total Interactions"}
                                                </Grid>
                                            </Grid>
                                        </Tooltip>
                                        <Tooltip
                                            title={
                                                "The total percentage of interactions for the selected classifier."
                                            }
                                            placement={"top"}
                                        >
                                            <Grid>
                                                <Grid
                                                    item
                                                    className={
                                                        classes.boldTitleText
                                                    }
                                                >
                                                    {`${props.store
                                                        .percentInteractionsForClassifier(
                                                            value.id,
                                                        )
                                                        .toFixed(1)}%`}
                                                </Grid>
                                                <Grid item>
                                                    Interactions Positively
                                                    Classified
                                                </Grid>
                                            </Grid>
                                        </Tooltip>
                                    </Grid>
                                    <AcxLineGraph
                                        legendPosition="none"
                                        data={GoogleLineChartFormat(
                                            props.store.statsForClassifier(
                                                value.id,
                                            ),
                                        )}
                                        xTitle="Date"
                                        yTitle="Interactions"
                                        asAreaChart
                                        asWidget
                                        fixedHeight
                                        colorScheme={[mainColor]}
                                    />
                                </>
                            )}
                        </>
                    );

                    const bubbledLoading =
                        props.store.classifierBubbledNGramsLoading.get(
                            value.id,
                        );

                    const showLoadingSpinner =
                        bubbledLoading &&
                        props.store.dataSelection.get(value.id) ===
                            "normalized";

                    const wordCloudComponent = props.store.getTaskError(
                        getClassifierNgramsTaskName(value.id),
                    ) ? (
                        <Tooltip
                            title={
                                props.store.getTaskError(
                                    getClassifierNgramsTaskName(value.id),
                                )?.message ?? ""
                            }
                        >
                            <IconButton className={classes.ngramComponentError} size="large">
                                <WarningIcon
                                    style={{
                                        color: theme.palette.red.main,
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <>
                            {" "}
                            {showLoadingSpinner ? (
                                <AcxLoadingIndicator
                                    color="secondary"
                                    alternate="PuffLoader"
                                    size={32}
                                />
                            ) : (
                                props.store.nGramDataForClassifier(
                                    value.id,
                                ) &&
                                !!selectedData.length && (
                                    <AcxWordCloud
                                        mainColor={mainColor}
                                        data={selectedData}
                                        onWordClick={props.store.runSearch}
                                    />
                                )
                            )}
                        </>
                    );

                    const topNComponentData = GoogleBarChartFormat(
                        selectedData,
                        13,
                    );

                    const topNcomponent = props.store.getTaskError(
                        getClassifierNgramsTaskName(value.id),
                    ) ? null : (
                        <>
                            {showLoadingSpinner ? (
                                <AcxLoadingIndicator
                                    color="secondary"
                                    alternate="PuffLoader"
                                    size={32}
                                />
                            ) : (
                                props.store.nGramDataForClassifier(
                                    value.id,
                                ) &&
                                topNComponentData.length > 1 && (
                                    <AcxHorizontalBarGraph
                                        legendPosition={"none"}
                                        chartAreaLeftOffset={0}
                                        chartAreaWidth={"83%"}
                                        annotationTextSize={12}
                                        colorScheme={[mainColor]}
                                        textPosition={"none"}
                                        data={topNComponentData}
                                    />
                                )
                            )}
                        </>
                    );

                    const expansionInfoComponent = props.store.getTaskError(
                        getClassifierQueryTaskName(value.id),
                    ) ? (
                        <Tooltip
                            title={
                                props.store.getTaskError(
                                    getClassifierQueryTaskName(value.id),
                                )?.message ?? ""
                            }
                        >
                            <IconButton
                                className={
                                    classes.expansionInfoComponentError
                                }
                                size="large">
                                <WarningIcon
                                    style={{
                                        color: theme.palette.red.main,
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <>
                            {
                                <ReportCardQuery
                                    query={props.store.queryForClassifier(
                                        value,
                                    )}
                                />
                            }
                        </>
                    );

                    let statusIndicator: React.ReactNode | undefined;
                    if (status === CardStatus.Waiting)
                        statusIndicator = (
                            <Typography
                                variant="subtitle1"
                                color={"textSecondary"}
                                className={classes.notification}
                            >
                                Waiting for prior reports...
                            </Typography>
                        );

                    return (
                        <Grid
                            container
                            item
                            xs={12}
                            key={value.id}
                            className={classes.reportWrapper}
                        >
                            <ReportCard
                                classifier={value}
                                reportTitle={props.store.reportCardTitle(
                                    value.id,
                                )}
                                graphComponent={graphComponent}
                                wordCloudComponent={wordCloudComponent}
                                topNcomponent={topNcomponent}
                                expansionInfoComponent={
                                    expansionInfoComponent
                                }
                                statusIndicator={statusIndicator}
                                isLoading={status === CardStatus.Loading}
                                wordCloudSelection={
                                    props.store.dataSelection.get(
                                        value.id,
                                    ) || "query"
                                }
                                setWordCloudSelection={(newSelection) =>
                                    props.store.setDataSelection(
                                        value.id,
                                        newSelection,
                                    )
                                }
                            />
                        </Grid>
                    );
                })}
        </>;
    },
);

export default ReportCardList;
