import {
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    Paper,
    Switch,
    Theme,
    Tooltip,
    Typography,
    useMediaQuery,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { Observer } from "mobx-react";
import React, { useState } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { InteractionType } from "../../../../models/InteractionType";
import { useStore } from "../../../../utils/useStore";
import { EvalStore } from "../../Stores/EvalStore";
import { isTextMediaType } from "../EvalPage";
import ClipView from "./ClipView";
import EmptyClipList from "./EmptyClipList";
import AcxConfirmationDialog from "components/UI/AcxConfirmationDialog";
import SoundClip from "models/SoundClip";
import ChevronDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ChevronUpIcon from "@mui/icons-material/KeyboardArrowUp";
import InfoIcon from "@mui/icons-material/Info";

export interface ClipViewProps {
    drawerPixelOffset: number;
    interactionType?: InteractionType;
}

const useStyles = makeStyles((theme: Theme) => ({
    content: {
        width: "100%",
        boxShadow: "-2px 0 2px -2px #888",
        height: `100%`,
        overflowX: "hidden",
        overflowY: "hidden",
        paddingTop: "12px",
        minWidth: "141px",
    },
    title: {
        paddingLeft: "12px",
        paddingRight: "12px",
        paddingBottom: "12px",
        paddingTop: "6px",
    },
    subtitle: {
        paddingLeft: "12px",
        paddingRight: "12px",
        paddingBottom: "12px",
    },
    clipList: (props: ClipViewProps) => ({
        overflowX: "hidden",
        height: `100%`,
        paddingBottom: isTextMediaType(props.interactionType) ? "0" : "293px",
    }),
    openCloseButton: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.white.main,
        "&:hover": {
            backgroundColor: theme.palette.secondary.light,
        },
    },
}));

export default function ClipList(props: ClipViewProps) {
    const classes = useStyles(props);
    const store = useStore(EvalStore);

    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [allClipsOpen, setAllClipsOpen] = useState(false);

    // text wrap on "Sync tags with the evaluation" reduces vertical space on smaller screens
    const matches = useMediaQuery("(min-width:1424px)");

    const toggleClipsOpen = () => {
        setAllClipsOpen(!allClipsOpen);
        if (store.openClipId) {
            store.setOpenClipId();
        }
    };

    const onDeleteButton = (soundClip: SoundClip) => {
        store.stageDeleteClip = soundClip;
        setDialogIsOpen(true);
    };

    const onDelete = () => {
        if (store.stageDeleteClip) {
            //store.currentEval?.deleteClip(store.stageDeleteClip);
            store.deleteClip(store.stageDeleteClip);
        }
        onDialogClose();
    };

    const onDialogClose = () => {
        setDialogIsOpen(false);
    };

    const onExit = () => {
        store.stageDeleteClip = null;
    };

    return (
        <Observer>
            {() => (
                <Paper square className={clsx(classes.content)}>
                    <Grid
                        container
                        justifyContent="space-between"
                        className={classes.title}
                        alignItems="center"
                    >
                        <Grid item>
                            <Typography variant="h2" gutterBottom={true}>
                                Clips
                            </Typography>
                        </Grid>

                        <Grid item>
                            <IconButton
                                size="small"
                                onClick={toggleClipsOpen}
                                className={classes.openCloseButton}
                            >
                                {allClipsOpen ? (
                                    <Tooltip title="Collapse">
                                        <ChevronDownIcon />
                                    </Tooltip>
                                ) : (
                                    <Tooltip title="Expand">
                                        <ChevronUpIcon />
                                    </Tooltip>
                                )}
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.subtitle}>
                        <Tooltip title="When enabled, adding a tag to a clip will automatically add that clip to the related Eval Question.">
                            <FormControlLabel
                                control={
                                    <Switch
                                        sx={{ width: 40 }}
                                        checked={store.enableAutoBindClips}
                                        onChange={(val) => {
                                            store.enableAutoBindClips =
                                                val.target.checked;
                                        }}
                                        name="EnableAutoBindClips"
                                        id="EnableAutoBindClips"
                                    />
                                }
                                label={
                                    <Grid container alignItems="center">
                                        <Grid
                                            item
                                            style={{ paddingRight: "8px" }}
                                        >
                                            Sync tags with the evaluation
                                        </Grid>
                                        <InfoIcon
                                            color="disabled"
                                            fontSize="small"
                                        />
                                    </Grid>
                                }
                            />
                        </Tooltip>
                    </Grid>
                    <Divider variant={"fullWidth"} />

                    <div
                        className={classes.clipList}
                        style={
                            !matches && !isTextMediaType(props.interactionType)
                                ? { paddingBottom: "305px" }
                                : {}
                        }
                    >
                        <Scrollbars autoHide>
                            {(store.currentEval?.activeSoundClips?.length ??
                                0) > 0 ? (
                                store.currentEval?.activeSoundClips?.map(
                                    (soundClip, index) => (
                                        <React.Fragment
                                            key={`${soundClip.id}-${index}`}
                                        >
                                            <ClipView
                                                soundClip={soundClip}
                                                mediaUrl={store.getMediaUrlFromSoundClipId(
                                                    soundClip.id,
                                                )}
                                                onDeleteButton={() => {
                                                    onDeleteButton(soundClip);
                                                }}
                                                isOpen={allClipsOpen}
                                                index={index}
                                            />
                                            <Divider />
                                        </React.Fragment>
                                    ),
                                )
                            ) : (
                                <EmptyClipList />
                            )}
                        </Scrollbars>
                        <AcxConfirmationDialog
                            isOpen={dialogIsOpen}
                            fullWidth
                            onClose={onDialogClose}
                            onCancel={onDialogClose}
                            onConfirm={onDelete}
                            title={`Are you sure you want to delete ${store.stageDeleteClip?.segmentName}?`}
                            onTransitionExit={onExit}
                        />
                    </div>
                </Paper>
            )}
        </Observer>
    );
}
