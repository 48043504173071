import React from "react";
import TranscriptionStatusTable from "./TranscriptionStatusTable";
import { ThemeProvider, Theme, StyledEngineProvider } from "@mui/material";
import AppTheme from "Theme/AppTheme";


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


function TranscriptionDashboard() {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={AppTheme}>
                <TranscriptionStatusTable />
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default TranscriptionDashboard;
