import { InfoOutlined } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Grid,
    IconButton,
    Switch,
    Theme,
    Tooltip,
    Typography,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import AcxButton from "components/UI/AcxButton";
import AcxExpansion from "components/UI/AcxExpansion";
import AcxMainTextField from "components/UI/AcxMainTextField";
import AcxDialog from "components/UI/Dialog/AcxDialog";
import AcxDrawer from "components/UI/Drawer/AcxDrawer";
import { observer } from "mobx-react";
import ApplicationPermission from "models/Permission/ApplicationPermission";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "shared/hooks/useForm";
import { AuthStore } from "stores/AuthStore";
import { LayoutDrawerStore } from "stores/Layout/LayoutDrawerStore";
import useStyles from "Styles/Styles";
import { useStore } from "utils/useStore";
import useOrganization from "../../hooks/useOrganization";
import RoleService from "./Role.service";

const styles = (theme: Theme) =>
    createStyles({
        container: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "0px",
            position: "relative",
            width: 424,
            height: 500,
            filter: "drop-shadow(0px 25px 50px rgba(0, 0, 0, 0.25))",
            borderRadius: "8px",
        },
        heading: {
            fontSize: theme.typography.pxToRem(14),
            flexBasis: "60%",
            flexShrink: 0,
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
        },
        hierarchyContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "0px",
            position: "relative",
            width: 600,
            height: 600,
            filter: "drop-shadow(0px 25px 50px rgba(0, 0, 0, 0.25))",
            borderRadius: "8px",
        },
        titleContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "24px",
            paddingBottom: "0.25rem",
            position: "static",
            width: "424px",
            left: "0px",
            top: "0px",
            background: "#FFFFFF",
            flex: "none",
            order: 0,
            flexGrow: 0,
            margin: "0px 0px",
        },
        actions: {
            display: "flex",
            alignItems: "flex-end",
            padding: "24px",
            width: "100%",
        },
        title: {
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "20px",
            lineHeight: "32px",
            color: theme.palette.blackFont.main,
        },
        subtitle: {
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "14px",
            lineHeight: "20px",
            color: "#71717A",
            padding: 24,
        },
        closeButton: {
            position: "absolute",
            top: 16,
            color: "#A1A1AA",
            right: 8,
        },
        closeIcon: {
            height: 20,
            width: 20,
        },
        error: {
            fontSize: "13px",
            color: theme.palette.error.main,
            textAlign: "center",
            padding: theme.spacing(1),
        },
        actionButton: {
            width: "inherit",
        },
        cancel: {
            color: theme.palette.blackFont.main,
            height: "30px",
            overflow: "hidden",
            fontSize: "13px",
            textAlign: "center",
            fontFamily: '"Inter", sans-serif',
            fontWeight: "bold",
            lineHeight: "20px",
            marginLeft: "0",
            whiteSpace: "nowrap",
            marginRight: "0",
            textOverflow: "ellipsis",
            letterSpacing: "0",
            textTransform: "none",
            background: "#FFFFFF",
            border: "1px solid #E4E4E7",
            "&:hover": {
                backgroundColor: theme.palette.white.main,
            },
        },
        fullWidth: {
            width: "100%",
        },
        required: {
            "&:after": {
                color: theme.palette.red.main,
                content: '" *" !important',
            },
        },
        helperText: {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            fontSize: "12px",
            lineHeight: "16px",
        },
        helperTextError: {
            color: `${theme.palette.error.main} !important`,
        },
        accordion: {
            boxShadow: "none",
            "&:before": {
                display: "none",
            },
        },
        accordionSummary: {
            padding: 0,
            marginLeft: -6,
        },
        accordionDetails: {
            padding: 0,
        },
        label: {
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: 12,
            color: "#A1A1AA",
        },
    });

const validation = {};

type Props = {
    orgId: string;
    id: string;
    close: () => void;
    refetch: () => void;
    data?: any;
    isLoadingUsers: boolean;
    isOrgAdministrator: boolean;
};

export type AddRoleRequestV2 = {
    name: string;
    organizationId: string;
    v2Permissions: string[];
    isOrgAdmin: boolean;
};

export type EditRoleRequestV2 = {
    id: string;
    name: string;
    v2Permissions: string[];
    isOrgAdmin: boolean;
};

const OrganizationRolesDetail: React.FC<Props> = observer(
    ({ orgId, id, close, refetch, data, isOrgAdministrator }) => {
        const drawerStore = useStore(LayoutDrawerStore);
        const authStore = useStore(AuthStore);
        const permStore = authStore.permStore;
        const messageStore = useStore(MessageStore);
        const classes = useStyles(styles);
        const [confirmDelete, setConfirmDelete] = useState(false);
        const [isDeleting, setIsDeleting] = useState(false);

        //List of all possible permissions an organization could have
        const [possiblePermissions, setPossiblePermissions] =
            React.useState<ApplicationPermission[]>();
        //List of enabled permissions, that updates as UI updates
        const [rolePermissions, setRolePermissions] =
            React.useState<ApplicationPermission[]>();
        const { organization } = useOrganization({
            id: orgId,
        });
        const [isOrgAdmin, setIsOrgAdmin] =
            React.useState<boolean>(isOrgAdministrator);

        useEffect(() => {
            setIsOrgAdmin(isOrgAdministrator);
        }, [isOrgAdministrator]);

        const updatePermissions = (resource: string, action: string) => {
            const permission = possiblePermissions?.find(
                (perm) => perm.action === action && perm.resource === resource,
            );

            if (permission) {
                const permissionsToChange: ApplicationPermission[] = [
                    permission,
                ];

                if (
                    rolePermissions?.find((perm) => perm.id === permission.id)
                ) {
                    setRolePermissions(
                        rolePermissions?.filter(
                            (perm) =>
                                !permissionsToChange.find(
                                    (p) => p.id === perm.id,
                                ),
                        ),
                    );
                } else {
                    if (permission.action === "Edit") {
                        const viewPermission = possiblePermissions?.find(
                            (perm) =>
                                perm.resource === permission.resource &&
                                perm.action === "View",
                        );
                        if (
                            viewPermission &&
                            !rolePermissions?.includes(viewPermission)
                        ) {
                            permissionsToChange.push(viewPermission);
                        }
                    }
                    setRolePermissions(
                        rolePermissions?.concat(permissionsToChange),
                    );
                }
            }
        };

        let resources;

        if (possiblePermissions && possiblePermissions.length > 0) {
            //Recursive function for building UI
            const createNestedPermissions = (resource: string) => {
                //Grab all the possible permissions for a resource
                let permissions = possiblePermissions.filter(
                    (perm) => perm.resource === resource,
                );
                //Grab all children as list of unique resources for associated permissions
                let children = Array.from(
                    new Set(
                        possiblePermissions
                            .filter((perm) =>
                                permissions
                                    .flatMap((perm) => perm.id)
                                    .includes(perm.parentPermission),
                            )
                            .flatMap((child) => child.resource),
                    ),
                );

                if (children && children.length > 0) {
                    return (
                        <div key={resource} style={{ display: "flex" }}>
                            <AcxExpansion
                                rootStyle={{
                                    minWidth: "100%",
                                    alignSelf: "center",
                                }}
                                expanded={false}
                                header={resource}
                                body={
                                    <div
                                        style={{
                                            marginTop: "1rem",
                                            marginLeft: "26px",
                                        }}
                                    >
                                        {children.map((child) =>
                                            createNestedPermissions(child),
                                        )}
                                    </div>
                                }
                            />
                        </div>
                    );
                } else {
                    return (
                        <Grid
                            key={resource}
                            container
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            wrap={"nowrap"}
                        >
                            <Grid
                                item
                                xs
                                style={{ fontSize: "14px", marginRight: "8px" }}
                            >
                                <Tooltip
                                    title={
                                        <span
                                            style={{ whiteSpace: "pre-line" }}
                                        >
                                            {permissions
                                                .flatMap(
                                                    (perm) =>
                                                        perm.action +
                                                        ": " +
                                                        (perm.description
                                                            ? perm.description
                                                            : "No description available."),
                                                )
                                                .sort(
                                                    (a, b) =>
                                                        b.charCodeAt(0) -
                                                        a.charCodeAt(0),
                                                )
                                                .join("\n\n")}
                                        </span>
                                    }
                                >
                                    <InfoOutlined
                                        fontSize="inherit"
                                        color="secondary"
                                    />
                                </Tooltip>
                            </Grid>

                            <Grid item xs style={{ minWidth: "45%" }}>
                                <Typography
                                    style={{
                                        whiteSpace: "normal",
                                    }}
                                >
                                    {resource}
                                </Typography>
                            </Grid>

                            <Grid
                                container
                                justifyContent={"flex-end"}
                                alignItems={"center"}
                            >
                                <Grid item>
                                    {possiblePermissions.filter(
                                        (perm) =>
                                            perm.action === "View" &&
                                            perm.resource === resource,
                                    ).length > 0 && (
                                        <Switch
                                            sx={{ minWidth: "40px" }}
                                            checked={
                                                (rolePermissions
                                                    ?.filter(
                                                        (perm) =>
                                                            perm.action ===
                                                            "View",
                                                    )
                                                    .flatMap(
                                                        (perm) => perm.resource,
                                                    )
                                                    .includes(resource) ??
                                                    false) ||
                                                (rolePermissions
                                                    ?.filter(
                                                        (perm) =>
                                                            perm.action ===
                                                            "Edit",
                                                    )
                                                    .flatMap(
                                                        (perm) => perm.resource,
                                                    )
                                                    .includes(resource) ??
                                                    false)
                                            }
                                            onChange={() => {
                                                if (
                                                    !rolePermissions?.find(
                                                        (perm) =>
                                                            perm.action ===
                                                                "Edit" &&
                                                            perm.resource ===
                                                                resource,
                                                    )
                                                ) {
                                                    updatePermissions(
                                                        resource,
                                                        "View",
                                                    );
                                                }
                                            }}
                                        />
                                    )}
                                </Grid>
                                <Grid
                                    style={{
                                        minHeight: "38px",
                                        minWidth: "58px",
                                    }}
                                    item
                                >
                                    {possiblePermissions.filter(
                                        (perm) =>
                                            perm.action === "Edit" &&
                                            perm.resource === resource,
                                    ).length > 0 && (
                                        <Switch
                                            sx={{ minWidth: "40px" }}
                                            checked={
                                                rolePermissions
                                                    ?.filter(
                                                        (perm) =>
                                                            perm.action ===
                                                            "Edit",
                                                    )
                                                    .flatMap(
                                                        (perm) => perm.resource,
                                                    )
                                                    .includes(resource) ?? false
                                            }
                                            onChange={() =>
                                                updatePermissions(
                                                    resource,
                                                    "Edit",
                                                )
                                            }
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    );
                }
            };

            //Generate UI recusively from unique list of non-child resources
            resources = Array.from(
                new Set(
                    possiblePermissions
                        .filter((perm) => !perm.parentPermission)
                        .flatMap((perm) => perm.resource),
                ),
            ).map((resource) => createNestedPermissions(resource));
        } else if (possiblePermissions === undefined) {
            const getPermissions = async () => {
                if (organization?.id) {
                    const perms = await permStore.getAllPermissionsForRoles(
                        organization?.id,
                    );
                    setPossiblePermissions(perms);
                }
            };
            getPermissions();
        }

        const getRolePermissions = useCallback(async () => {
            if (organization?.id) {
                const perms = await permStore.getRolePermissions(
                    organization?.id,
                    id,
                );
                setRolePermissions(perms);
            }
        }, [organization, id, permStore]);

        if (rolePermissions === undefined && id !== "add") {
            getRolePermissions();
        }

        useEffect(() => {
            if (id !== "add") {
                getRolePermissions();
            } else {
                setRolePermissions([]);
            }
        }, [id, getRolePermissions]);

        const { value, errors, onChange, isSubmitting, patchValue } =
            useForm<any>(data, validation);

        useEffect(() => {
            patchValue(data);
        }, [data, patchValue]);

        const registerOnChange =
            (prop: keyof any) => (e: React.ChangeEvent<HTMLInputElement>) => {
                onChange(prop, e.target.value);
            };

        const del = () => {
            setConfirmDelete(true);
        };

        const onClose = () => {
            setConfirmDelete(false);
        };

        const deleteRoleConfirm = async () => {
            setIsDeleting(true);
            try {
                await RoleService.deleteRole(id);
                setIsDeleting(false);
                refetch();
                close();
            } catch (err) {
                setIsDeleting(false);
            }
        };

        const saveRolePermissions = async () => {
            if (value.id) {
                const request: EditRoleRequestV2 = {
                    id: value.id,
                    name: value.name,
                    v2Permissions:
                        rolePermissions?.flatMap((perm) => perm.id) ?? [],
                    isOrgAdmin: isOrgAdmin,
                };
                try {
                    await RoleService.updateRole(null, request);
                    messageStore.logMessage(
                        `Successfully updated permission(s)`,
                        "success",
                    );
                    close();
                    refetch();
                } catch {
                    messageStore.logMessage(
                        `Error updating permission(s). Please try again.`,
                        "error",
                    );
                }
            } else {
                const request: AddRoleRequestV2 = {
                    name: value.name,
                    organizationId: orgId,
                    v2Permissions:
                        rolePermissions?.flatMap((perm) => perm.id) ?? [],
                    isOrgAdmin: isOrgAdmin,
                };
                try {
                    await RoleService.createRole(null, request);
                    messageStore.logMessage(
                        `Successfully updated permission(s)`,
                        "success",
                    );
                    close();
                    refetch();
                } catch {
                    messageStore.logMessage(
                        `Error updating permission(s). Please try again.`,
                        "error",
                    );
                }
            }
        };

        return (
            <>
                <AcxDrawer
                    anchor={drawerStore.anchor}
                    open={!!id}
                    collapsible={drawerStore.collapseSize}
                    variant={drawerStore.variant}
                    onDrawerClose={() => drawerStore.setOpen(false)}
                    drawerStore={drawerStore}
                    customRootStyles={drawerStore.customRootStyles}
                    onDrawerOpen={() => drawerStore.setOpen(true)}
                    content={
                        <div style={{ width: 400 }}>
                            <div
                                id="alert-dialog-title"
                                className={classes.titleContainer}
                            >
                                <Typography
                                    component="span"
                                    variant="h1"
                                    className={classes.title}
                                >
                                    {id === "add" ? "New" : "Update"} Role
                                </Typography>
                                <IconButton
                                    aria-label="close"
                                    className={classes.closeButton}
                                    onClick={close}
                                    size="large"
                                >
                                    <CloseIcon className={classes.closeIcon} />
                                </IconButton>
                            </div>
                            <div
                                style={{
                                    width: "100%",
                                    padding: "0 24px 2.25rem",
                                    whiteSpace: "pre-wrap",
                                    fontStyle: "normal",
                                    fontWeight: "normal",
                                    fontSize: 14,
                                    color: "#71717A",
                                }}
                            >
                                {id === "add"
                                    ? "Create a new role and configure its permissions."
                                    : "Update this role and configure its permissions."}
                            </div>
                            <DialogContent className={classes.fullWidth}>
                                <Grid container spacing={1}>
                                    <Grid
                                        container
                                        item
                                        spacing={1}
                                        wrap="wrap"
                                    >
                                        <Grid
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                            }}
                                            item
                                            xs={12}
                                        >
                                            {authStore.canUserEditRoles() &&
                                            organization?.enhancedPermissionStatus ? (
                                                <FormControlLabel
                                                    style={{
                                                        marginLeft: "auto",
                                                    }}
                                                    control={<Checkbox />}
                                                    checked={isOrgAdmin}
                                                    label="Organization Administrator"
                                                    onClick={() =>
                                                        setIsOrgAdmin(
                                                            !isOrgAdmin,
                                                        )
                                                    }
                                                />
                                            ) : (
                                                <></>
                                            )}
                                            <AcxMainTextField
                                                showAllErrors={true}
                                                onChange={registerOnChange(
                                                    "name",
                                                )}
                                                id="name"
                                                labelText="Role Name"
                                                placeholderText='e.g. "Team Member"'
                                                value={value.name}
                                                error={
                                                    !!errors?.fieldErrors?.name
                                                }
                                                helperText={
                                                    errors?.fieldErrors?.name
                                                }
                                                required={true}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <AcxExpansion
                                                expanded={false}
                                                header={
                                                    "App Domain Permissions"
                                                }
                                                body={
                                                    <div
                                                        style={{
                                                            marginLeft:
                                                                "0.5rem",
                                                        }}
                                                    >
                                                        {/* Flex-start for first row item label */}
                                                        <Grid
                                                            container
                                                            item
                                                            xs={6}
                                                            justifyContent={
                                                                "flex-start"
                                                            }
                                                            alignItems={
                                                                "baseline"
                                                            }
                                                            wrap={"nowrap"}
                                                            style={{
                                                                display:
                                                                    "inline-flex",
                                                                marginTop:
                                                                    "1rem",
                                                            }}
                                                        >
                                                            <Grid item xs>
                                                                <Typography
                                                                    color={
                                                                        "textSecondary"
                                                                    }
                                                                >
                                                                    Domain
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid
                                                            container
                                                            item
                                                            xs={6}
                                                            justifyContent={
                                                                "flex-end"
                                                            }
                                                            alignItems={
                                                                "baseline"
                                                            }
                                                            wrap={"nowrap"}
                                                            style={{
                                                                display:
                                                                    "inline-flex",
                                                                marginTop:
                                                                    "1rem",
                                                            }}
                                                        >
                                                            <Grid item xs={4}>
                                                                <Typography
                                                                    color={
                                                                        "textSecondary"
                                                                    }
                                                                    align={
                                                                        "center"
                                                                    }
                                                                >
                                                                    View
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Typography
                                                                    color={
                                                                        "textSecondary"
                                                                    }
                                                                    align={
                                                                        "center"
                                                                    }
                                                                >
                                                                    Edit
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>

                                                        {resources}
                                                    </div>
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Typography
                                    className={classes.error}
                                    component="div"
                                >
                                    {/*TODO JPC and PC look at later*/}
                                    {/*{errors.form?.join("\n")}*/}
                                </Typography>
                            </DialogContent>
                            <DialogActions className={classes.actions}>
                                <div>
                                    <Button
                                        onClick={close}
                                        variant="contained"
                                        disableElevation
                                        className={classes.cancel}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                                <div>
                                    <AcxButton
                                        onClick={del}
                                        color="secondary"
                                        leftRightSpacing={0}
                                        loading={isSubmitting}
                                        buttonDisabled={
                                            isSubmitting ||
                                            !authStore.canUserEditRoles()
                                        }
                                    >
                                        Delete
                                    </AcxButton>
                                </div>
                                <div>
                                    <AcxButton
                                        onClick={saveRolePermissions}
                                        color="secondary"
                                        leftRightSpacing={0}
                                        loading={isSubmitting}
                                        buttonDisabled={
                                            isSubmitting ||
                                            !authStore.canUserEditRoles()
                                        }
                                    >
                                        {id === "add" ? "Create" : "Update"}{" "}
                                        Role
                                    </AcxButton>
                                </div>
                            </DialogActions>
                        </div>
                    }
                />
                {confirmDelete && (
                    <AcxDialog
                        isOpen={!!confirmDelete}
                        onClose={onClose}
                        title="Delete Role"
                        text="Are you sure that you would like to delete this role?"
                    >
                        <AcxButton
                            onClick={deleteRoleConfirm}
                            color="secondary"
                            fullWidth={false}
                            leftRightSpacing={0}
                            loading={isDeleting}
                            buttonDisabled={
                                isDeleting ||
                                !authStore.canUserEditRoles()
                            }
                        >
                            Delete
                        </AcxButton>
                    </AcxDialog>
                )}
            </>
        );
    },
);

export default OrganizationRolesDetail;
