import {
    ApplicationFilters,
    SavedFilter,
} from "stores/ApplicationFilters/ApplicationFiltersStore";
import BaseService from "./BaseService";

// export type ApplicationFilterDomain =
//     | "Conversations" // conversations domain only filter
//     | "Signals" // signals domain only filter
//     | "User" // non-domain specific user only filter
//     | "Organization"; // tenant / org wide filter;

export enum ApplicationFilterDomain {
    /**
     * conversations domain only filter
     * @deprecated signals and conversations now support the same filter set. Use `User` instead.
     */
    Conversations = "Conversations",
    /**
     * signals domain only filter
     * @deprecated signals and conversations now support the same filter set. Use `User` instead.
     */
    Signals = "Signals",
    /**
     * non-domain specific user only filter
     */
    User = "User",
    /**
     * tenant / org wide filter
     */
    Organization = "Organization",
}

export interface CreateApplicationFilter {
    name: string;
    value: ApplicationFilters;
    domain: ApplicationFilterDomain;
}

export interface UpdateApplicationFilter {
    id: string;
    name?: string;
    value?: ApplicationFilters;
    domain?: string;
}

type UnparsedSavedFilter = Omit<SavedFilter, "value"> & { value: string };

export class ApplicationFiltersService extends BaseService {
    protected urlBase = "api/ApplicationFilter";

    public async willReceiveFullData(value: ApplicationFilters) {
        const apiPath = "WillReceiveFullData";
        const urlParams = new URLSearchParams();

        return this.post<boolean>(JSON.stringify(value), urlParams, apiPath);
    }

    public async createApplicationFilter(
        filter: CreateApplicationFilter,
    ): Promise<SavedFilter> {
        const apiPath = "CreateApplicationFilter";
        const urlParams = new URLSearchParams();
        const body = {
            name: filter.name,
            domain: filter.domain,
            value: JSON.stringify(filter.value),
        };

        return this.post<SavedFilter>(JSON.stringify(body), urlParams, apiPath);
    }

    public async updateApplicationFilter(filter: UpdateApplicationFilter) {
        const apiPath = "UpdateApplicationFilter";
        const urlParams = new URLSearchParams();
        const body = {
            id: filter.id,
            name: filter.name,
            domain: filter.domain,
            value: JSON.stringify(filter.value),
        };

        return this.put<void>(urlParams, apiPath, JSON.stringify(body));
    }

    public async updateApplicationFilters(filters: UpdateApplicationFilter[]) {
        const apiPath = "UpdateApplicationFilters";
        const urlParams = new URLSearchParams();

        const body = filters.map((filter) => ({
            ...filter,
            value: filter.value ? JSON.stringify(filter.value) : undefined,
        }));

        return this.put<void>(urlParams, apiPath, JSON.stringify(body));
    }

    public async deleteApplicationFilter(id: string) {
        const apiPath = "DeleteApplicationFilter";
        const urlParams = new URLSearchParams({ id });

        return this.delete(urlParams, apiPath);
    }

    public async deleteApplicationFilters(ids: string[]) {
        const apiPath = "DeleteApplicationFilters";
        const urlParams = new URLSearchParams();
        const body = ids;

        return this.delete(urlParams, apiPath, JSON.stringify(body));
    }

    public async getApplicationFiltersForDomain(
        domain: ApplicationFilterDomain,
    ) {
        const apiPath = "GetApplicationFilterForDomain";
        const urlParams = new URLSearchParams({ domain });

        const unparsed = await this.get<UnparsedSavedFilter[]>(
            urlParams,
            apiPath,
        );

        const parsed: SavedFilter[] = [];

        for (const filter of unparsed)
            parsed.push({ ...filter, value: JSON.parse(filter.value) });

        return parsed;
    }

    public async getApplicationFiltersByDomain() {
        const apiPath = "GetApplicationFiltersByDomain";
        const urlParams = new URLSearchParams();

        const unparsed = await this.get<
            {
                domain: ApplicationFilterDomain;
                filters: UnparsedSavedFilter[];
            }[]
        >(urlParams, apiPath);

        const parsedByDomain: Partial<
            Record<ApplicationFilterDomain, SavedFilter[]>
        > = {};

        for (const value of unparsed) {
            const parsedForDomain: SavedFilter[] = [];

            for (const filter of value.filters) {
                parsedForDomain.push({
                    ...filter,
                    value: JSON.parse(filter.value),
                });
            }

            parsedByDomain[value.domain] = parsedForDomain;
        }

        return parsedByDomain;
    }
}
