import React from "react";
import useStyles from "Styles/Styles";
import { Card, Grid, Typography, Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
    createStyles({
        card: {
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            padding: "1rem",
            margin: "0.5rem",
            marginLeft: "0px",
        },
        title: {
            marginBottom: "1rem",
            padding: "0 1rem",
            fontWeight: "bold",
            color: theme.palette.text.secondary,
        },
        bodyContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        icon: {
            display: "flex",
            marginRight: "0.5rem",
            color: "#3564D5",
        },
    });

export interface AcxKeyInformationCardProps {
    id: any;
    title: string;
    icon: any;
    value: string | number;
    valueLabel?: string;
    label?: string | number;
}

const AcxKeyInformationCard = (props: AcxKeyInformationCardProps) => {
    const classes = useStyles(styles);

    return (
        <Card className={classes.card} id={props.id}>
            <Typography variant="h3" className={classes.title}>
                {props.title}
            </Typography>

            <Grid container className={classes.bodyContainer}>
                <Grid item className={classes.icon}>
                    {props.icon}
                </Grid>

                <Grid item>
                    <Typography variant="h2">
                        {props.value}
                        {props.valueLabel}
                    </Typography>
                </Grid>
            </Grid>
        </Card>
    );
};

export default AcxKeyInformationCard;
