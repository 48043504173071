import { Grid, Link } from "@mui/material";
import {
    GridCellValue,
    GridComparatorFn,
    GridRowData,
    GridSortCellParams,
    GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import _ from "lodash";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import theme from "Theme/AppTheme";
import { Routes } from "../../../Navigation/Routes";
import { Box } from "@mui/material";

export const GenericLinkFormatter = (obj: {
    routeMaker: string;
    routeParams: string[];
    routePrefix?: string;
    orgId?: string;
    orgIdValue?: string;
    fallbackPath?: string;
    nameFormatter?: (linkName: string, row: GridRowData) => string;
    useNewTab?: boolean;
}) => {
    const fn = (params: GridValueFormatterParams) => {
        const flds = obj;

        const orgId = flds.orgId
            ? _.get(params.row, flds.orgId)?.toString()
            : flds.orgIdValue;

        let linkName = _.get(params.row, params.field)?.toString();

        if (!linkName && flds.fallbackPath) {
            linkName = _.get(params.row, flds.fallbackPath)?.toString();
        }

        if (!linkName) {
            return "";
        }

        linkName = flds.nameFormatter?.(linkName, params.row) ?? linkName;

        const args = [orgId] as string[];
        for (let routeParam of flds.routeParams) {
            args.push(_.get(params.row, routeParam)?.toString());
        }

        const res = (
            <Grid
                container
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
            >
                <Grid item>
                    {linkName && (
                        <Box
                            sx={{
                                "&:hover": {
                                    fontWeight: "bolder",
                                },
                            }}
                        >
                            <Link
                                variant="body1"
                                key={`link-item-${linkName}-${orgId}`}
                                style={{
                                    textAlign: "left",
                                    color: theme.palette.neutral[600],
                                    textDecoration: "underline",
                                    textUnderlineOffset: theme.spacing(0.5),
                                    fontWeight: "inherit",
                                }}
                                color="textPrimary"
                                component={RouterLink}
                                to={
                                    (flds.routePrefix ?? "") +
                                    Routes[flds.routeMaker](...args)
                                }
                                target={flds.useNewTab ? "_blank" : undefined}
                            >
                                {linkName}
                            </Link>
                        </Box>
                    )}
                </Grid>
            </Grid>
        );

        return res;
    };
    return fn;
};

export const GenericLinkComparator = (obj: { fallbackPath?: string }) => {
    const fn: GridComparatorFn = (
        v1: GridCellValue,
        v2: GridCellValue,
        param1: GridSortCellParams,
        param2: GridSortCellParams,
    ): number => {
        const flds = obj;

        const row1 = param1.api.getRow(param1.id);
        const row2 = param2.api.getRow(param2.id);

        let p1 = _.get(row1, param1.field)?.toString();
        let p2 = _.get(row2, param2.field)?.toString();

        if (!p1 && flds.fallbackPath) {
            p1 = _.get(row1, flds.fallbackPath)?.toString();
        }
        if (!p2 && flds.fallbackPath) {
            p2 = _.get(row2, flds.fallbackPath)?.toString();
        }

        if (!p1) return 1;
        if (!p2) return -1;
        return p1.localeCompare(p2);
    };
    return fn;
};
