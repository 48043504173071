import { AddSharp, DeleteForeverRounded } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { GridCellValue, GridValueGetterParams } from "@mui/x-data-grid-pro";
import AcxButton from "components/UI/AcxButton";
import AcxDataGrid from "components/UI/AcxDataGrid/AcxDataGrid";
import { hierarchyColumnType } from "components/UI/AcxDataGrid/ColumnTypes/AcxGridColTypes";
import IColDef from "components/UI/AcxDataGrid/IColDef";
import AcxStandaloneToggleButton from "components/UI/AcxStandaloneToggleButton";
import { Observer, observer } from "mobx-react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { AuthStore } from "stores/AuthStore";
import useStyles from "Styles/Styles";
import { useStore } from "utils/useStore";
import CreateNewAgentDialog from "./OrganizationAgentDetails/CreateNewAgentDialog";
import MergeAgentsDialog from "./OrganizationAgentDetails/MergeAgentsDialog";
import UpdateAgentsDialog from "./OrganizationAgentDetails/UpdateAgentDialog";
import { OrganizationAgentStore } from "./stores/OrganizationAgentStore";

type Props = {
    orgId: string;
    inActiveStatus?: any;
};

const OrganizationAgentsList: React.FC<
    React.PropsWithChildren<React.PropsWithChildren<Props>>
> = observer(({ orgId, inActiveStatus = true }) => {
    const classes = useStyles(() => {
        return {};
    });
    const navigate = useNavigate();
    const agentStore = useStore(OrganizationAgentStore);
    const authStore = useStore(AuthStore);

    const getCurrentDisplay = function () {
        if (inActiveStatus) {
            inActiveStatus = false;
            agentStore.loadInActiveAgents();
            agentStore.ActiveAgentsLabel = "Show Active Agents";
        } else {
            inActiveStatus = true;
            agentStore.loadActiveAgents();
            agentStore.ActiveAgentsLabel = "Show Inactive Agents";
        }
    };

    useEffect(() => {
        agentStore.dgStore.clearSelected();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        const columns: IColDef[] = [
            {
                headerName: "Agent Name",
                field: "fullName",
                renderType: "longString",
                width: 150,
            },
            {
                headerName: "First Name",
                field: "firstName",
                renderType: "longString",
                width: 150,
            },
            {
                headerName: "Last Name",
                field: "lastName",
                renderType: "longString",
                width: 150,
            },
            {
                headerName: "Email",
                field: "agentEmail",
                renderType: "longString",
                width: 250,
            },
            {
                headerName: "Service Access",
                field: "displayHierarchies",
                flex: 1,
                ...hierarchyColumnType,
            },
            {
                headerName: "Agent Code",
                field: "agentCode",
                renderType: "longString",
                width: 150,
            },
            {
                headerName: "Agent Status",
                field: "statusDisplay",
                renderType: "longString",
                width: 150,
            },
            {
                headerName: "Agent User",
                field: "user.userName",
                renderType: "longString",
                width: 250,
                valueGetter: (params: GridValueGetterParams) =>
                    (params.row.user.userName
                        ? params.row.user.userName
                        : "") as GridCellValue,
            },
            {
                headerName: "Agent Manager",
                field: "managerFullName",
                renderType: "longString",
                width: 150,
            },
            {
                headerName: "Start Date",
                field: "startDate",
                renderType: "dateFormatterIgnoreTime",
                width: 150,
            },
        ];

        agentStore.orgId = orgId;
        agentStore.dgStore.setColumns(columns);
        agentStore.dgStore.removeHeight = "65px";
        agentStore.dgStore.controlsColumnStyle = {
            justifyContent: "flex-end",
            marginTop: "0.5rem",
        };
        agentStore.dgStore.controlsColumnSpan = 12;
        agentStore.ActiveAgentsLabel = "Show Inactive Agents";
        agentStore.dgStore.controls = [
            <Observer>
                {() => (
                    <div>
                        <AcxStandaloneToggleButton
                            color="secondary"
                            leftRightSpacing={0}
                            rootStyle={{
                                padding: "1rem",
                                minWidth: "max-content",
                            }}
                            onClick={getCurrentDisplay}
                        >
                            {agentStore.ActiveAgentsLabel}
                        </AcxStandaloneToggleButton>
                    </div>
                )}
            </Observer>,
            <Observer>
                {() => (
                    <div>
                        <AcxButton
                            buttonDisabled={!authStore.canUserEdit("Agents")}
                            color="secondary"
                            leftRightSpacing={0}
                            rootStyle={{
                                padding: "1rem",
                                minWidth: "max-content",
                            }}
                            startIcon={<AddSharp />}
                            onClick={agentStore.openCreateAgent}
                        >
                            Add
                        </AcxButton>
                    </div>
                )}
            </Observer>,

            // agent user button

            <Observer>
                {() => (
                    <div>
                        <AcxButton
                            height="33px"
                            buttonDisabled={!authStore.canUserEdit("Agents")}
                            color="secondary"
                            leftRightSpacing={0}
                            startIcon={<AddSharp />}
                            onClick={() => {
                                agentStore.createAgentUser();
                                navigate(
                                    `/admin/organizations/${orgId}/users/add`,
                                );
                            }}
                        >
                            Add Agent User
                        </AcxButton>
                    </div>
                )}
            </Observer>,

            <Observer>
                {() => (
                    <div>
                        <AcxButton
                            buttonDisabled={!authStore.canUserEdit("Agents")}
                            color="secondary"
                            leftRightSpacing={0}
                            rootStyle={{
                                padding: "1rem",
                                minWidth: "max-content",
                            }}
                            startIcon={<EditIcon />}
                            onClick={agentStore.openUpdateAgent}
                        >
                            Update
                        </AcxButton>
                    </div>
                )}
            </Observer>,
            <Observer>
                {() => (
                    <div>
                        <AcxButton
                            buttonDisabled={!authStore.canUserEdit("Agents")}
                            color="secondary"
                            leftRightSpacing={0}
                            rootStyle={{
                                padding: "1rem",
                                minWidth: "max-content",
                            }}
                            startIcon={<DeleteForeverRounded />}
                            onClick={agentStore.deleteAgents}
                        >
                            Delete
                        </AcxButton>
                    </div>
                )}
            </Observer>,
            <Observer>
                {() => (
                    <div>
                        <AcxButton
                            buttonDisabled={!authStore.canUserEdit("Agents")}
                            color="secondary"
                            leftRightSpacing={0}
                            rootStyle={{
                                padding: "1rem",
                                minWidth: "max-content",
                            }}
                            onClick={agentStore.openMergeAgents}
                        >
                            Merge Agents
                        </AcxButton>
                    </div>
                )}
            </Observer>,
        ];

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgId]);

    return (
        <>
            <CreateNewAgentDialog
                isOpen={agentStore.agentCreateDialogStore.isOpen}
                onClose={agentStore.agentCreateDialogStore.close}
                onSubmitHandler={agentStore.onAgentCreateConfirm}
                isLoading={agentStore.getTaskLoading("Create Agent")}
            />

            <UpdateAgentsDialog />

            <MergeAgentsDialog />

            <div className={classes.appContent}>
                <AcxDataGrid dataGridStore={agentStore.dgStore} />
            </div>
        </>
    );
});

export default OrganizationAgentsList;
