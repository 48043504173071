import React, { useContext, useRef, useEffect } from "react";
import TranscriptionVisualAudioView from "./TranscriptionVisualAudioView";
import useServiceData from "utils/useServiceData";
import { AudioFilesService } from "services/AudioFilesService";
import TranscriptionVisualStore from "../../TranscriptionVisualStore";
import { observer } from "mobx-react-lite";

// const txServ = new TranscriptionService();
const audServ = new AudioFilesService();

interface OwnProps {
    transcriptionId: string;
}

const TranscriptionVisualAudioController = observer((props: OwnProps) => {
    const _isMounted = useRef(true); // Initial value _isMounted = true

    useEffect(() => {
        return () => {
            // ComponentWillUnmount in Class Component
            _isMounted.current = false;
        };
    }, []);

    const tvStore = useContext(TranscriptionVisualStore);

    // const txId = txServ.extractIdFromTranscriptionFileName(props.fileName);
    const [audioBlob, isLoading, errorMessage] = useServiceData(() =>
        audServ.getAudioFileBlobForTranscription(props.transcriptionId),
    );

    function handleUpdateCurrentPlayingTime(newTime: number) {
        if (_isMounted.current) tvStore.currentSeekPosition = newTime;
    }

    let audioLocation = "";
    if (audioBlob) audioLocation = URL.createObjectURL(audioBlob);

    return (
        <TranscriptionVisualAudioView
            onUpdateCurrentPlayingTime={handleUpdateCurrentPlayingTime}
            points={tvStore.points}
            {...{ isLoading, errorMessage, audioBlob, audioLocation }}
        />
    );
});

export default TranscriptionVisualAudioController;
