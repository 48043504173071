import {
    GridCellValue,
    GridColDef,
    GridColTypeDef,
    GridFilterItem,
    GridSortCellParams,
    GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import _ from "lodash";
import { CUSTOM_FILTERS } from "../FilterOverride/CustomFilters";
import {
    DurationFilter,
    formatValueToDuration,
} from "../Formatters/DurationFormatter";

export const durationColumnType: GridColTypeDef = {
    sortComparator: (
        v1: GridCellValue,
        v2: GridCellValue,
        param1: GridSortCellParams,
        param2: GridSortCellParams,
    ): number => {
        const row1 = param1.api.getRow(param1.id);
        const row2 = param2.api.getRow(param2.id);

        const p1 = formatValueToDuration(_.get(row1, param1.field)?.toString());
        const p2 = formatValueToDuration(_.get(row2, param2.field)?.toString());

        return p1.localeCompare(p2);
    },
    valueFormatter: (params: GridValueFormatterParams) => {
        const value = _.get(params.row, params.field)?.toString();

        return formatValueToDuration(value);
    },
    filterOperators: [
        {
            label: "contains",
            value: "contains",
            getApplyFilterFn: (
                filterItem: GridFilterItem,
                column: GridColDef,
            ) => {
                if (
                    !filterItem.columnField ||
                    !filterItem.value ||
                    !filterItem.operatorValue
                ) {
                    return null;
                }

                const filterRegex = new RegExp(
                    formatValueToDuration(filterItem.value),
                    "i",
                );
                return (params): boolean => {
                    const rowValue = formatValueToDuration(
                        _.get(params.row, column.field),
                    );

                    return filterRegex.test(rowValue?.toString() || "");
                };
            },
            InputComponent: DurationFilter,
        },
        {
            label: "equals",
            value: "equals",
            getApplyFilterFn: (
                filterItem: GridFilterItem,
                column: GridColDef,
            ) => {
                if (
                    !filterItem.columnField ||
                    !filterItem.value ||
                    !filterItem.operatorValue
                ) {
                    return null;
                }
                return (params): boolean => {
                    const rowValue = _.get(params.row, column.field);

                    return (
                        formatValueToDuration(rowValue) ===
                        formatValueToDuration(filterItem.value)
                    );
                };
            },
            InputComponent: DurationFilter,
        },
        ...CUSTOM_FILTERS,
    ],
};
