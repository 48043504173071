import { Grid } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DateRangeIcon from "@mui/icons-material/DateRange";
import HeadsetIcon from "@mui/icons-material/Headset";
import PhoneIcon from "@mui/icons-material/Phone";
import { observer } from "mobx-react";
import React from "react";
import { useStore } from "utils/useStore";
import ReportOverviewCard from "../../../Reports/Common/ReportOverviewCard";
import ClassifierReportStore from "../ClassifierReportStore";

const ClassifierReportOverview = observer(() => {
    const reportStore = useStore(ClassifierReportStore);

    const stats = reportStore.overviewStats;
    if (stats === null || stats === undefined) return null;

    return (
        <Grid container spacing={3} alignItems="center">
            <ReportOverviewCard
                title="Total Number of Interactions"
                icon={<PhoneIcon color="secondary" />}
                value={reportStore.overviewCount}
                useGrid={true}
                descriptionText={
                    "The total count of interactions analyzed through Speech Analyticx \
                    for the selected date range. All interactions except live interactions are included."
                }
            />

            <ReportOverviewCard
                title="Total Time Analyzed"
                icon={<HeadsetIcon color="secondary" />}
                value={reportStore.overviewHours}
                label="Hours"
                useGrid={true}
                descriptionText={
                    "Total number of hours analyzed in the selected date range."
                }
            />
            <ReportOverviewCard
                title="Daily Average"
                icon={<DateRangeIcon color="secondary" />}
                value={reportStore.overviewHoursPerDay}
                label="Hours/Day"
                useGrid={true}
                descriptionText={
                    "The average number of hours of audio hours analyzed over the \
                    selected date range for those days where audio interactions exist."
                }
            />
            <ReportOverviewCard
                title="Average Call Time"
                icon={<AccessTimeIcon color="secondary" />}
                value={reportStore.overviewMinutesPerCall}
                label="Min/Call"
                useGrid={true}
                descriptionText={
                    "The average amount of time per call for the audio call time analyzed divided by the total number of calls."
                }
            />
        </Grid>
    );
});

export default ClassifierReportOverview;
