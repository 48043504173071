import { Grid, InputAdornment, Zoom } from "@mui/material";
import { debounce, startCase } from "lodash";
import {
    action,
    computed,
    flow,
    makeObservable,
    observable,
    reaction,
    runInAction,
    toJS,
} from "mobx";
import { computedFn, queueProcessor } from "mobx-utils";
import Classifier, { ClassifierType, ResultType } from "models/ClassifierModel";
import RuleSet from "models/RuleSet";
import moment from "moment";
import { User } from "oidc-client";
import React, { CSSProperties, ReactNode } from "react";
import ClassifierService from "services/ClassifierService";
import { MetaLabelService } from "services/MetaLabelService";
import type { MetaLabels } from "services/MetaLabelService";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";
import { v4 as uuidv4 } from "uuid";
import Organization from "../../../../models/Organization";
import Rule, {
    ARBITRARY_STRATIFICATION_OPERATOR,
    EQUAL_STRATIFICATION_OPERATOR,
    Operator,
    REPRESENTATIVE_STRATIFICATION_OPERATOR,
    RuleType,
} from "../../../../models/Rule";
import RuleCombinator, {
    CombinatorRuleType,
} from "../../../../models/RuleCombinator";
import {
    Campaign,
    CampaignService,
} from "../../../../services/CampaignService";
import { RuleSetService } from "../../../../services/RuleSetService";
import { BaseStore } from "../../../../stores/BaseStore";
import EqualToSvg from "../../../../SvgIcons/EqualToSvg";
import GreaterThanEqualSvg from "../../../../SvgIcons/GreaterThanEqualSvg";
import GreaterThanSvg from "../../../../SvgIcons/GreaterThanSvg";
import LessThanEqualSvg from "../../../../SvgIcons/LessThanEqualSvg";
import LessThanSvg from "../../../../SvgIcons/LessThanSvg";
import NotEqualToSvg from "../../../../SvgIcons/NotEqualToSvg";
import { populateAuth } from "../../../../utils/AccessTokens";
import { delay } from "../../../../utils/helpers";
import { isType, isUndefinedType } from "../../../../utils/TypeGuards";
import AcxMainTextField from "../../../UI/AcxMainTextFieldGrid";
import AcxDateRangeInput from "../../../UI/Calendar/DateRange/AcxDateRangeInput";
import AcxSelectSingle from "../../../UI/Select/BaseSelectComponents/AcxSelectSingle";
import AcxClassifierRuleItem from "./Views/MainContent/AcxClassifierRuleItem";
import type { OnDropParams, RuleTypes } from "./Views/MainRuleBuilder";
import { Observer } from "mobx-react";

export const LoadRuleSetsOp = "Load Saved RuleSets";
export const LoadClassifiersOp = "Load Published Classifiers";
export const LoadCampaigns = "Load Campaigns";
export const LoadMetalabels = "Load MetaLabels";
export const SaveRuleSetOp = "Save RuleSet To Server";

const newRuleSym = Symbol("NewRule");

type OperatorListOptionsType = {
    label: any;
    value: Operator;
    notForClassifiers: Set<string>;
};

export function generateDefaultStartDate() {
    return moment().startOf("week").utc(true).toISOString();
}

export function generateDefaultEndDate() {
    return moment().endOf("date").utc(true).toISOString();
}

@AcxStore
export class RuleBuildStore extends BaseStore {
    @observable ruleSets: RuleSet[] = observable.array();
    @observable classifiers: Classifier[] = observable.array();
    @observable orgCampaigns: Campaign[] = observable.array();
    @observable.shallow classifiersAsRules: Classifier[] = [];
    @observable activeRuleSet: RuleSet;
    @observable orgId?: string;
    @observable.ref org?: Organization;

    @observable ruleValiationErrors: Map<string, boolean> = observable.map();
    @observable warnings: Map<string, string> = observable.map();

    @observable modifiedRuleSets: Map<string, Set<string>> = observable.map();
    @observable thresholdFocused: boolean = false;
    @observable showFinishDialog: boolean = false;
    @observable saveToServerFinished: boolean = false;
    @observable isActiveRuleSetNewlyCreated: boolean = true;
    @observable private ruleSetIdToEdit: string | undefined;
    @observable metaLabels: MetaLabels;

    onRuleSetFinished?: (ruleSetId: string) => void;

    private loggedInUser: User;
    private _ruleSet: RuleSet;
    private tempRuleSet?: RuleSet;
    private readonly operatorsList: Map<
        RuleTypes,
        Array<OperatorListOptionsType>
    > = new Map<RuleTypes, Array<OperatorListOptionsType>>();

    private readonly contentGridStyle: CSSProperties = {
        cursor: "inherit",
        overflow: "hidden",
        paddingRight: "8px",
    };
    private readonly cursorPointerStyle: CSSProperties = { cursor: "pointer" };
    private readonly overflowHiddenStyle: CSSProperties = {
        overflow: "hidden",
    };
    private readonly thresholdTextFieldStyle: CSSProperties = {
        maxWidth: "200px",
    };

    private readonly SpeechFilterThresholdOptions = [
        {
            value: "true",
            label: <span style={{ fontWeight: "bolder" }}>true</span>,
        },
        {
            value: "false",
            label: <span style={{ fontWeight: "bolder" }}>false</span>,
        },
    ];
    private readonly callDurationAdornment: ReactNode = (
        <InputAdornment
            style={{
                marginLeft: "8px",
                marginRight: "4px",
                color: "grey",
                fontWeight: "bold",
                userSelect: "none",
            }}
            disableTypography
            position="end"
        >
            mins
        </InputAdornment>
    );

    private ruleSetService = new RuleSetService();
    private classifierService = new ClassifierService();
    private campaignService = new CampaignService();
    private metaLabelService = new MetaLabelService();

    constructor(public rootStore: IRootStore) {
        super("RuleBuilderStore");

        makeObservable(this);

        const theRuleSet = new RuleSet("New RuleSet");
        theRuleSet[newRuleSym] = true;
        this.activeRuleSet = this._ruleSet = theRuleSet;

        const filterOperatorList = [
            {
                value: Operator.Gt,
                label: <GreaterThanSvg />,
                notForClassifiers: new Set<string>([
                    "Agent Name",
                    "Call Direction",
                    ClassifierType.Lucene,
                    ClassifierType.Tensorflow,
                ]),
            },
            {
                value: Operator.GtEq,
                label: <GreaterThanEqualSvg />,
                notForClassifiers: new Set<string>([
                    "Agent Name",
                    "Call Direction",
                    ClassifierType.Lucene,
                    ClassifierType.Tensorflow,
                ]),
            },
            {
                value: Operator.Lt,
                label: <LessThanSvg />,
                notForClassifiers: new Set<string>([
                    "Agent Name",
                    "Call Direction",
                    ClassifierType.Lucene,
                    ClassifierType.Tensorflow,
                ]),
            },
            {
                value: Operator.LtEq,
                label: <LessThanEqualSvg />,
                notForClassifiers: new Set<string>([
                    "Agent Name",
                    "Call Direction",
                    ClassifierType.Lucene,
                    ClassifierType.Tensorflow,
                ]),
            },
            {
                value: Operator.NEq,
                label: <NotEqualToSvg />,
                notForClassifiers: new Set<string>([
                    ClassifierType.Lucene,
                    ClassifierType.Tensorflow,
                ]),
            },
            {
                value: Operator.Eq,
                label: <EqualToSvg />,
                notForClassifiers: new Set<string>([
                    ClassifierType.Lucene,
                    ClassifierType.Tensorflow,
                ]),
            },
            {
                value: Operator.In,
                label: <span style={{ fontWeight: "bolder" }}>In</span>,
                notForClassifiers: new Set<string>([
                    "Interaction Date",
                    "Call Duration",
                    "Call Direction",
                    ClassifierType.Lucene,
                    ClassifierType.Tensorflow,
                ]),
            },
            {
                value: Operator.Between,
                label: <span style={{ fontWeight: "bolder" }}>Between</span>,
                notForClassifiers: new Set<string>([
                    "Call Direction",
                    "Agent Name",
                    ClassifierType.Lucene,
                    ClassifierType.Tensorflow,
                ]),
            },
        ];
        const rankOperatorList = [
            {
                value: Operator.Gt,
                label: <span style={{ fontWeight: "bolder" }}>Desc</span>,
                notForClassifiers: new Set<string>(),
            },

            {
                value: Operator.Lt,
                label: <span style={{ fontWeight: "bolder" }}>Asc</span>,
                notForClassifiers: new Set<string>([
                    ClassifierType.Lucene,
                    ClassifierType.Tensorflow,
                ]),
            },
        ];

        const stratifyOperatorList = [
            {
                value: REPRESENTATIVE_STRATIFICATION_OPERATOR,
                label: (
                    <span style={{ fontWeight: "bolder" }}>Representative</span>
                ),
                notForClassifiers: new Set<string>(),
            },
            {
                value: EQUAL_STRATIFICATION_OPERATOR,
                label: <span style={{ fontWeight: "bolder" }}>Equal</span>,
                notForClassifiers: new Set<string>(),
            },
            {
                value: ARBITRARY_STRATIFICATION_OPERATOR,
                label: <span style={{ fontWeight: "bolder" }}>Arbitrary</span>,
                notForClassifiers: new Set<string>(),
            },
        ];

        this.operatorsList.set("Filter", filterOperatorList);
        this.operatorsList.set("Ranking", rankOperatorList);
        this.operatorsList.set("Stratify", stratifyOperatorList);

        this.setRulesetName.bind(this);

        reaction(
            (r) => this.showFinishDialog,
            (showFinish) => {
                if (!showFinish) {
                    this.saveToServerFinished = false;
                }
            },
            { delay: 100 },
        );

        reaction(
            (r) => this.orgId,
            (orgId) => {
                if (orgId) {
                    this.setupAsyncTask(LoadClassifiersOp, () =>
                        this.loadClassifiers(orgId),
                    );
                    this.setupAsyncTask(LoadCampaigns, () =>
                        this.loadCampaigns(),
                    );
                    this.setupAsyncTask(LoadMetalabels, () =>
                        this.loadMetalabels(),
                    );
                }
            },
            { fireImmediately: true, delay: 100 },
        );

        reaction(
            (r) => [...(this.activeRuleSet.rules ?? [])],
            (activeRules) => {
                for (const value of activeRules) {
                    this.ruleSetValidator(value);
                }
            },
            { delay: 60 },
        );

        reaction(
            (r) => ({ toEdit: this.ruleSetIdToEdit, rulesets: this.ruleSets }),
            (arg) => {
                if (arg.toEdit && arg.rulesets && arg.rulesets.length > 0) {
                    const toBeEdited = arg.rulesets.find(
                        (value) => value.id === arg.toEdit,
                    );
                    this.setActiveRuleSet(toBeEdited);
                    this.ruleSetIdToEdit = undefined;
                }
            },
            { delay: 0, fireImmediately: true },
        );

        queueProcessor(
            this.classifiersAsRules,
            (item) => {
                this.classifiers.push(item);
            },
            80,
        );
    }

    @action
    resetStore() {
        this.orgId = undefined;
        this.org = undefined;
        const theRuleSet = new RuleSet("New RuleSet");
        theRuleSet[newRuleSym] = true;
        this.modifiedRuleSets.clear();
        this.ruleValiationErrors.clear();
        this.activeRuleSet = this._ruleSet = theRuleSet;
        this.saveToServerFinished = false;
        this.showFinishDialog = false;
        this.ruleSetIdToEdit = undefined;
    }

    @action
    partiallyResetStore() {
        const theRuleSet = new RuleSet("New RuleSet");
        theRuleSet[newRuleSym] = true;
        if (this.orgId) {
            this.setupAsyncTask(LoadRuleSetsOp, () => this.loadRuleSets());
        }
        this.modifiedRuleSets.clear();
        this.ruleValiationErrors.clear();
        this.activeRuleSet = this._ruleSet = theRuleSet;
        this.setUserDetails();
        this.saveToServerFinished = false;
        this.showFinishDialog = false;
        this.ruleSetIdToEdit = undefined;
    }

    @action
    setShowFinishDialog(show: boolean) {
        this.showFinishDialog = show;
    }

    @action
    setSaveToServerFinished(finished: boolean) {
        this.saveToServerFinished = finished;
    }

    @action
    setOrg = (org?: Organization) => {
        if (org) {
            this.org = org;
            this.orgId = org.id;
        }
    };

    @computed
    get isActiveRuleSetNew(): boolean {
        return isNewRuleSet(this.activeRuleSet);
    }

    @action
    initializeStoreFromSampler(org: Organization, ruleSetId?: string) {
        if (this.orgId !== org.id) {
            this.setOrg(org);
            this.ruleSetIdToEdit = ruleSetId;
        } else if (ruleSetId) {
            const toBeEdited = this.ruleSets?.find(
                (value) => value.id === ruleSetId,
            );
            this.setActiveRuleSet(toBeEdited);
            this.ruleSetIdToEdit = undefined;
        }
    }

    @action
    setOrgId(orgId: string) {
        this.orgId = orgId;
    }

    @computed
    get isSavable() {
        const invalidRulesInRuleSet = [
            ...this.ruleValiationErrors.values(),
        ].filter((value) => value);
        return (
            invalidRulesInRuleSet.length === 0 &&
            this.activeRuleSet.rules.filter((value) => value.isActive).length >
                0
        );
    }

    saveToServerInternal = flow(function* (this: RuleBuildStore) {
        const orgId = this.orgId;
        if (isUndefinedType(orgId)) {
            console.error(
                `RuleBuildStore attemping to save new ruleset with empty organizationID`,
            );
            return;
        }

        const isCreatingNewRuleSet = isNewRuleSet(this.activeRuleSet);

        if (isUndefinedType(this.loggedInUser)) {
            const { user } = yield populateAuth();
            this.loggedInUser = user;
        }

        if (isCreatingNewRuleSet) {
            this.setUserDetails();
        }

        const ruleSet = toJS(this.activeRuleSet);

        if (!ruleSet.organizationId) {
            ruleSet.organizationId = orgId;
        }

        yield delay(15);

        if (!isCreatingNewRuleSet) {
            ruleSet.modifiedBy = this.loggedInUser.profile.email;
            ruleSet.modifiedOn = moment().utc().toISOString();
        }

        // TODO check that threshold and operators are populated when required;

        ruleSet.rules = ruleSet.rules.filter((value) => value.isActive);

        ruleSet.rules.forEach((value) => {
            value.ruleSet = null;
            if (isCreatingNewRuleSet) {
                // @ts-ignore
                delete value.id;
            }

            if (!isCreatingNewRuleSet) {
                value.modifiedBy = this.loggedInUser.profile.email;
                value.modifiedOn = moment().utc().toISOString();
            }

            // NO need to lowercase inbound
            // if (
            //     value.classifier.name === "Call Direction" &&
            //     value.ruleType === RuleType.Filter
            // ) {
            //     value.threshold = value.threshold;
            // }

            if (
                (value.operator === Operator.Between ||
                    value.operator === Operator.In) &&
                value.ruleType !== RuleType.Stratify
            ) {
                if (value.classifier.name === "Call Duration") {
                    value.resultType = ResultType.NumberType;
                    this.handleJsonArrayThreshold(value, (arg) => {
                        return `${parseFloat(arg ?? "0") * 60000}`;
                    });
                } else {
                    value.resultType = ResultType.StringType;
                    this.handleJsonArrayThreshold(value);
                }
            } else if (value.classifier?.name === "Call Duration") {
                value.resultType = ResultType.NumberType;
                value.threshold = `${
                    parseFloat(value.threshold ?? "0") * 60000
                }`;
            }
        });

        ruleSet.ruleCombinators.forEach((value) => {
            value.ruleSet = null;
            if (isCreatingNewRuleSet) {
                // @ts-ignore
                delete value.id;
            } else {
                value.modifiedBy = this.loggedInUser.profile.email;
                value.modifiedOn = moment().utc().toISOString();
            }
        });

        for (let rule of ruleSet.rules) {
            // @ts-ignore
            delete rule.classifier;
        }

        if (isCreatingNewRuleSet) {
            // @ts-ignore
            delete ruleSet.id;
        }

        yield delay(15);

        const res: RuleSet = yield this.ruleSetService.createRuleSet(
            orgId,
            ruleSet,
        );

        yield delay(15);
        this.saveToServerFinished = true;

        this.setupAsyncTask(LoadRuleSetsOp, () => this.loadRuleSets());

        yield delay(500);
        this.onRuleSetFinished?.(res.id);
    });

    private handleJsonArrayThreshold(
        value: Rule,
        valueTransformer: (arg: string) => string = (arg) => arg,
    ) {
        let args: string[];
        if (value.classifier.name === "Agent Name") {
            args = value.threshold!.split(/,\n/);
        } else {
            args = value.threshold!.split(",");
        }

        const results = args
            .filter((value) => value.trim())
            .map(valueTransformer);

        value.threshold = JSON.stringify(results);
    }

    @action
    saveToServer() {
        if (!this.orgId) {
            throw new Error(
                `Can't Save RuleSet to server with empty OrgId: ${this.orgId}`,
            );
        }

        this.showFinishDialog = true;
        return Promise.resolve();
    }

    @action
    saveToServerActually() {
        const task = this.setupAsyncTask(SaveRuleSetOp, () =>
            this.saveToServerInternal(),
        );

        return task;
    }

    private getOperatorForRule(
        rule: Rule,
        type: "Filter" | "Stratify" | "Ranking",
    ) {
        let op: OperatorListOptionsType | undefined = undefined;
        if (!isUndefinedType(rule.operator)) {
            const opValue = rule.operator.valueOf();
            if (type === "Stratify") {
                if (opValue === REPRESENTATIVE_STRATIFICATION_OPERATOR) {
                    op = this.operatorsList.get(type)?.[0];
                } else if (opValue === EQUAL_STRATIFICATION_OPERATOR) {
                    op = this.operatorsList.get(type)?.[1];
                } else if (opValue === ARBITRARY_STRATIFICATION_OPERATOR) {
                    op = this.operatorsList.get(type)?.[2];
                } else {
                    throw new Error(
                        opValue +
                            " is not a valid operator for stratification.",
                    );
                }
            } else if (
                type === "Ranking" &&
                opValue === Operator.Lt.valueOf()
            ) {
                op = this.operatorsList.get(type)?.[1];
            } else {
                op = this.operatorsList.get(type)?.[opValue];
            }

            return op;
        } else {
            return op;
        }
    }

    @action
    setOperatorForFule = (rule: Rule, operator: Operator) => {
        const activeRule = this.activeRuleSet.rules.find(
            (value) => value.id === rule.id,
        );
        if (activeRule?.operator?.valueOf() === operator.valueOf()) {
            this.ruleSetValidator(activeRule);
            return;
        }
        if (activeRule) {
            if (
                activeRule.operator === Operator.Between ||
                activeRule.operator === Operator.In
            ) {
                if (
                    activeRule.classifier.name !== "Interaction Date" &&
                    activeRule.classifier.name !== "Agent Start Date"
                ) {
                    activeRule.threshold =
                        activeRule.threshold?.split(",")?.[0];
                }
            }

            activeRule.operator = operator;

            this.ruleSetValidator(rule);
        } else {
            console.error("SetOperator couldn't find active rule");
            return;
        }
    };

    getThresholdForRule = function (
        this: RuleBuildStore,
        rule: Rule,
        rangePosition?: "start" | "end",
    ) {
        if (
            !isUndefinedType(rangePosition) &&
            !isUndefinedType(rule.threshold) &&
            rule.ruleType === RuleType.Filter
        ) {
            let res: string | undefined = undefined;

            const existing = rule.threshold.split(",");
            switch (rangePosition) {
                case "start":
                    res = existing?.[0];
                    break;
                case "end":
                    res = existing?.[1];
                    break;
            }
            return res;
        }

        return rule.threshold;
    };

    getThresholdForCampaign = function (this: RuleBuildStore, rule: Rule) {
        return this.orgCampaigns.find(
            (campaign) => campaign.id === rule.threshold,
        );
    };

    setThresholdForRule = action(
        (rule: Rule, arg: string, rangePosition?: "start" | "end") => {
            if (
                rule.classifier.name === "Interaction Date" ||
                rule.classifier.name === "Agent Start Date"
            ) {
                let existingThreshold: string[] = rule.threshold?.split(
                    ",",
                ) ?? [generateDefaultStartDate(), generateDefaultEndDate()];
                switch (rangePosition) {
                    case "start":
                        existingThreshold[0] = arg;
                        break;
                    case "end":
                        existingThreshold[1] = arg;
                        break;
                    default:
                        break;
                }
                rule.threshold = `${existingThreshold[0]},${existingThreshold[1]}`;
            } else if (
                rule.ruleType !== RuleType.Stratify &&
                rule.classifier.name === "Agent Name" &&
                rule.operator === Operator.In
            ) {
                const x = arg
                    .replace(/(\r\n|\r|\n)/g, ",\n")
                    .replace(/, /g, ",\n")
                    .replace(/,,/g, ",");
                rule.threshold = x;
            } else {
                rule.threshold = arg;
            }
            this.ruleSetValidator(rule);
        },
    );

    setThresholdForRuleDebounced = debounce(
        action((rule: Rule, arg: string, rangePosition?: "start" | "end") => {
            this.setThresholdForRule(rule, arg, rangePosition);
        }),
        50,
        { leading: true },
    );

    @action
    trackModifiedRuleSets = flow(function* (
        this: RuleBuildStore,
        arg: Rule | RuleCombinator,
        operatorOrThreshold: "operator" | "threshold",
        isDeactivating = false,
    ) {
        yield delay(60);
        if (isRule(arg)) {
            const rule = arg;
            let currModifiedRules;
            if (isDeactivating) {
                this.ruleValiationErrors.delete(rule.id);

                currModifiedRules =
                    this.modifiedRuleSets.get(this.activeRuleSet.id!) ??
                    new Set<string>();
                if (isNewRuleSet(this.activeRuleSet)) {
                    currModifiedRules.delete(rule.id);
                } else {
                    const ruleExistsOnSaveRuleSet =
                        this.tempRuleSet?.rules.findIndex(
                            (value) => value.id === rule.id,
                        ) ?? -1;
                    if (ruleExistsOnSaveRuleSet >= 0) {
                        currModifiedRules.add(rule.id);
                    } else {
                        currModifiedRules.delete(rule.id);
                    }
                }
            } else {
                this.ruleSetValidator(rule);

                currModifiedRules =
                    this.modifiedRuleSets.get(this.activeRuleSet.id!) ??
                    new Set<string>();
                currModifiedRules.add(rule.id);

                const origRule = this.tempRuleSet?.rules.findIndex(
                    (value) => value.id === rule.id,
                );
                yield delay(60);
                if (!isUndefinedType(origRule) && origRule >= 0) {
                    if (
                        this.tempRuleSet?.rules[origRule][
                            operatorOrThreshold
                        ] === rule[operatorOrThreshold]
                    ) {
                        currModifiedRules.delete(rule.id);
                    }
                }
            }

            this.modifiedRuleSets.set(
                this.activeRuleSet.id!,
                currModifiedRules,
            );
        } else {
            const combo = arg;
            const currModifiedRules =
                this.modifiedRuleSets.get(this.activeRuleSet.id!) ??
                new Set<string>();
            currModifiedRules.add(combo.id);
            const origCombo = this.tempRuleSet?.ruleCombinators.findIndex(
                (value) => value.id === combo.id,
            );
            if (!isUndefinedType(origCombo) && origCombo >= 0) {
                if (
                    this.tempRuleSet?.ruleCombinators[origCombo].combinator ===
                    combo.combinator
                ) {
                    currModifiedRules.delete(combo.id);
                }
            }
            this.modifiedRuleSets.set(
                this.activeRuleSet.id!,
                currModifiedRules,
            );
        }
    });

    @action
    setThresholdBlurred = () => {
        this.thresholdFocused = false;
    };

    @action
    setThresholdFocused = () => {
        this.thresholdFocused = true;
    };

    errorForRule = computedFn(function (this: RuleBuildStore, ruleId: string) {
        return this.ruleValiationErrors.get(ruleId);
    });

    @action
    resetValidator = () => {
        this.ruleValiationErrors.clear();
    };
    @action
    ruleSetValidator = (rule: Rule) => {
        if (rule.ruleType === RuleType.Filter) {
            if (
                isUndefinedType(rule.operator) ||
                isUndefinedType(rule.threshold) ||
                rule.threshold?.trim() === ""
            ) {
                this.ruleValiationErrors.set(rule.id, true);
            } else {
                const filterThresholdValidation =
                    this.thresholdValidatorInternal(rule)?.[0];
                this.ruleValiationErrors.set(
                    rule.id,
                    filterThresholdValidation,
                );
            }
        } else {
            if (isUndefinedType(rule.operator)) {
                this.ruleValiationErrors.set(rule.id, true);
            } else {
                this.ruleValiationErrors.set(rule.id, false);
            }
        }
    };

    thresholdValidator = computedFn(function (
        this: RuleBuildStore,
        rule: Rule,
    ): [boolean, string | undefined] {
        return this.thresholdValidatorInternal(rule);
    });

    private thresholdValidatorInternal(
        rule: Rule,
    ): [boolean, string | undefined] {
        if (rule.ruleType !== RuleType.Filter) {
            return [false, undefined];
        }

        if (rule.threshold?.trim()) {
            const commaSepArgs = rule.threshold
                .split(",")
                .map((value) => value.trim());
            const arg = rule.threshold.trim();

            if (rule.operator === Operator.Between) {
                if (rule.classifier.name === "Interaction Date") {
                    // Interaction Date is always BETWEEN and managed programmatically
                    return [false, undefined];
                }

                if (
                    commaSepArgs.length !== 2 ||
                    (commaSepArgs.length === 2 &&
                        commaSepArgs.some((value) => value === ""))
                ) {
                    // BETWEEN operators require two non-empty arguments
                    return [
                        true,
                        "Between requires two comma-separated arguments",
                    ];
                } else if (
                    rule.classifier.name === "Call Duration" &&
                    commaSepArgs.some(
                        (value) => value && (isNaN(+value) || +value < 0),
                    )
                ) {
                    return [
                        true,
                        "Duration between requires two positive numbers",
                    ];
                }
            } else if (rule.operator === Operator.In) {
                if (rule.classifier.name === "Agent Name") {
                    // requires lines separated arg since agent names legitimately contains commas
                    // const lineSepArgs = rule.threshold.split(/\r|\r\n|\n/);
                    //const lines = arg.match(/\r|\r\n|\n/g)?.length ?? 0;
                    const commas = arg.match(/,\w/g)?.length ?? 0;
                    if (commas > 0) {
                        return [true, "One Agent per line"];
                    }
                } else if (arg.indexOf(",") < 0) {
                    return [true, "In requires comma-separated list"];
                }
                return [false, undefined];
            } else {
                if (
                    rule.classifier.name !== "Agent Name" &&
                    arg &&
                    arg?.indexOf(",") >= 0
                ) {
                    // commas not allowed in Call Duration, Call Duration without BETWEEN or IN operators
                    return [true, "Commas not allowed"];
                }
                if (rule.classifier.name === "Call Duration") {
                    if (arg && (isNaN(+arg) || +arg < 0)) {
                        return [true, "Duration must be positive number"];
                    }
                }
            }
        }
        return [false, undefined];
    }

    ruleContentForType = computedFn(
        function (this: RuleBuildStore, rule: Rule, type: RuleTypes) {
            const adornment =
                rule.classifier.name === "Call Duration"
                    ? this.callDurationAdornment
                    : undefined;

            const validator = this.thresholdValidator(rule);
            const thresholdInput =
                rule.classifier.name === "Interaction Date" ||
                rule.classifier.name === "Agent Start Date" ? (
                    <Grid
                        container
                        style={this.overflowHiddenStyle}
                        direction="row"
                        spacing={3}
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Grid item xs={12}>
                            <Observer>
                                {() => (
                                    <AcxDateRangeInput
                                        defaultStartDate={moment.utc(
                                            this.getThresholdForRule(
                                                rule,
                                                "start",
                                            ),
                                        )}
                                        defaultEndDate={moment.utc(
                                            this.getThresholdForRule(
                                                rule,
                                                "end",
                                            ),
                                        )}
                                        onSelect={(start, end) => {
                                            runInAction(() => {
                                                this.setThresholdForRule(
                                                    rule,
                                                    start
                                                        .startOf("date")
                                                        .toISOString(),
                                                    "start",
                                                );
                                                this.setThresholdForRule(
                                                    rule,
                                                    end
                                                        .endOf("date")
                                                        .toISOString(),
                                                    "end",
                                                );
                                            });
                                        }}
                                        labelText={"Date Range"}
                                    />
                                )}
                            </Observer>
                        </Grid>
                    </Grid>
                ) : rule.classifier.classifierTypeName ===
                      ClassifierType.Lucene ||
                  rule.classifier.classifierTypeName ===
                      ClassifierType.Tensorflow ? (
                    <AcxSelectSingle
                        inputLabel={"ClassifierResult"}
                        valueField="value"
                        labelField="label"
                        defaultValue={this.getSpeechFilterThreshold(rule)}
                        options={this.SpeechFilterThresholdOptions}
                        onChange={(arg) => {
                            this.setThresholdForRuleDebounced(rule, arg.value);
                        }}
                        id={`${rule.id}-col-select`}
                    />
                ) : rule.classifier.name === "Campaign" ? (
                    <AcxSelectSingle
                        inputLabel={"Campaign Threshold"}
                        valueField="id"
                        labelField="externalName"
                        defaultValue={this.getThresholdForCampaign(rule)}
                        options={this.orgCampaigns}
                        onChange={(arg) => {
                            this.setThresholdForRuleDebounced(rule, arg.id);
                        }}
                        id={`${rule.id}-col-select`}
                    />
                ) : (
                    <AcxMainTextField
                        key={rule.id}
                        id={rule.id!}
                        type={"text"}
                        containerStyle={this.thresholdTextFieldStyle}
                        multiLine={rule.classifier.name === "Agent Name"}
                        error={validator[0]}
                        onDblClick={(event) => {
                            event.preventDefault();
                            const tgt = event.target as HTMLTextAreaElement;
                            tgt?.select?.();
                        }}
                        onClick={(event) => {
                            event.preventDefault();
                            const tgt = event.target as HTMLTextAreaElement;
                            tgt?.setSelectionRange?.(
                                tgt.textLength,
                                tgt.textLength,
                            );
                        }}
                        helperText={validator[1]}
                        onBlur={this.setThresholdBlurred}
                        onFocus={this.setThresholdFocused}
                        endAdornment={adornment}
                        labelText={"Threshold"}
                        value={this.getThresholdForRule(rule)}
                        onChange={(evt) =>
                            this.setThresholdForRuleDebounced(
                                rule,
                                evt.target.value,
                            )
                        }
                    />
                );

            const operatorsForType = this.getOperatorOptionsForType(type, rule);

            const content = (
                <Zoom in={true} timeout={600}>
                    <Grid
                        style={this.contentGridStyle}
                        container
                        wrap={"nowrap"}
                        direction="row"
                        spacing={2}
                        alignContent={"flex-start"}
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Grid item style={this.cursorPointerStyle}>
                            {operatorsForType.length > 0 &&
                                ((rule.classifier.name !== "Interaction Date" &&
                                    rule.classifier.name !==
                                        "Agent Start Date") ||
                                    rule.ruleType === RuleType.Rank ||
                                    rule.ruleType === RuleType.Stratify) && (
                                    <AcxSelectSingle
                                        inputLabel={"Operator"}
                                        valueField="value"
                                        labelField="label"
                                        options={operatorsForType}
                                        defaultValue={this.getOperatorForRule(
                                            rule,
                                            type,
                                        )}
                                        onChange={(arg) => {
                                            this.setOperatorForFule(
                                                rule,
                                                arg.value,
                                            );
                                        }}
                                        id={`${rule.id}-col-select`}
                                    />
                                )}
                        </Grid>

                        {rule.ruleType === RuleType.Filter && (
                            <Grid item>{thresholdInput}</Grid>
                        )}
                    </Grid>
                </Zoom>
            );

            return (
                <AcxClassifierRuleItem
                    classifierItem={rule.classifier}
                    validateRule
                    name={rule.classifier.name}
                    id={rule.id!}
                    key={rule.id}
                    store={this}
                    content={content}
                    ruleId={rule.id!}
                    type={rule.classifier.classifierTypeName}
                />
            );
        },
        { name: "ruleContentForType" },
    );

    getOperatorOptionsForType = computedFn(
        function (
            this: RuleBuildStore,
            type: "Filter" | "Stratify" | "Ranking",
            rule: Rule,
        ) {
            return (this.operatorsList.get(type) ?? []).filter((value) =>
                rule.classifier.classifierTypeName === ClassifierType.Lucene ||
                rule.classifier.classifierTypeName ===
                    ClassifierType.Tensorflow ||
                rule.classifier.classifierTypeName ===
                    ClassifierType.ExtendedMetadata
                    ? !value.notForClassifiers.has(
                          rule.classifier.classifierTypeName,
                      )
                    : !value.notForClassifiers.has(rule.classifier.name),
            );
        },
        { name: "getOperatorsForRuleType" },
    );

    getSpeechFilterThreshold = computedFn(
        function (this: RuleBuildStore, rule: Rule) {
            return this.SpeechFilterThresholdOptions.find(
                (value) => value.value === this.getThresholdForRule(rule),
            );
        },
        { name: "getSpeechFilterThreshold" },
    );

    setRulesetName = debounce(
        action((value: string) => {
            this.activeRuleSet.name = value;
        }),
        17,
    );

    @action
    setActiveRuleSet = action((ruleSet?: RuleSet) => {
        if (ruleSet?.id === this.activeRuleSet.id) {
            return;
        }

        this.resetValidator();
        const indx = this.ruleSets.findIndex(
            (value) => value.id === this.activeRuleSet.id,
        );
        if (indx >= 0 && indx < this.ruleSets.length) {
            this.ruleSets[indx] = this.activeRuleSet;
        }

        if (!ruleSet) {
            this.activeRuleSet = this._ruleSet;
            this.isActiveRuleSetNewlyCreated = true;
            this.tempRuleSet = undefined;
        } else {
            if (isNewRuleSet(this.activeRuleSet)) {
                this._ruleSet = this.activeRuleSet;
            }

            this.activeRuleSet = isRuleSet(ruleSet)
                ? ruleSet
                : RuleSet.fromServer(
                      ruleSet,
                      this.activeRuleSet,
                      this.classifiers,
                  );
            this.tempRuleSet = toJS(this.activeRuleSet);
            this.isActiveRuleSetNewlyCreated = false;
        }
    });

    @action
    setCombinatorForRuleType(
        ruleType: RuleTypes,
        combinatorRuleType: CombinatorRuleType,
    ) {
        const combo = this.activeRuleSet.ruleCombinators.find(
            (value) => value.ruleType === ruleTypeStringToEnum(ruleType),
        );
        if (isUndefinedType(combo)) {
            console.error(
                `Couldn't find RuleCombinator for ruleType: ${ruleType}`,
            );
            return;
        }

        combo.combinator =
            combinatorRuleType ?? ((combo.combinator?.valueOf() ?? 0) + 1) % 2;
    }

    getCombinatorForRuleType = computedFn(function (
        this: RuleBuildStore,
        ruleType: RuleTypes,
    ) {
        let combo = this.activeRuleSet.ruleCombinators.find(
            (value) => value.ruleType === ruleTypeStringToEnum(ruleType),
        );

        return combo?.combinator;
    });

    createRule(classifierId: string) {
        return uuidv4();
    }

    ruleListForType = computedFn(function (
        this: RuleBuildStore,
        type: RuleTypes,
    ) {
        return this.activeRuleSet.rules.filter(
            (value) =>
                value.ruleType === ruleTypeStringToEnum(type) && value.isActive,
        );
    });

    @action
    deactivateRule(params: OnDropParams) {
        const ruleIndx = this.activeRuleSet.rules.findIndex(
            (value) => value.id === params.ruleId,
        );

        if (ruleIndx < 0) {
            return;
        }

        const rule = this.activeRuleSet.rules[ruleIndx];
        if (rule && rule.isActive) {
            rule.isActive = false;
            // rule.deletedOn = new Date().toISOString();
            this.activeRuleSet.rules.splice(ruleIndx, 1);
        }

        this.ruleValiationErrors.delete(rule.id);
    }

    @computed
    get latestWarning(): string | undefined {
        const res = [...this.warnings.entries()].shift();
        return res?.[1];
    }

    @action setWarning = (whichOp: string, message: string) => {
        this.warnings.set(whichOp, message);
    };
    @action clearLatestWarning = () => {
        if (this.warnings.size > 0) {
            this.warnings.delete([...this.warnings.keys()][0]);
            return;
        }
    };

    @action
    async associateClassifierWithRuleType(params: OnDropParams) {
        const movedClassifierIndex = this.classifiers.findIndex(
            (value) => value.id === params.classifierId,
        );
        const movedClassifier = this.classifiers[movedClassifierIndex];
        const existing = this.activeRuleSet.rules.find(
            (value) => value.id === params.ruleId,
        );
        if (existing) {
            return;
        }

        const ruleType = ruleTypeStringToEnum(params.ruleType);

        if (ruleType === RuleType.Stratify.valueOf()) {
            // Can't stratify on Lucene classifiers
            if (
                movedClassifier.classifierTypeName === ClassifierType.Lucene ||
                movedClassifier.classifierTypeName ===
                    ClassifierType.Tensorflow ||
                movedClassifier.name === "Campaign" ||
                movedClassifier.classifierTypeName ===
                    ClassifierType.ExtendedMetadata ||
                movedClassifier.classifierTypeName === ClassifierType.Database
            ) {
                const msg =
                    "Stratification on Campaign, Speech, Extended MetaData, ML Classifier, and Agent Start Date rules is prohibited";
                this.setWarning(msg, msg);
                return;
            }

            // Can't stratify on more than one classifier
            if (
                this.activeRuleSet.rules.find(
                    (rule) => rule.ruleType === RuleType.Stratify,
                )
            ) {
                const msg = "Stratification on multiple rules is prohibited";
                this.setWarning(msg, msg);
                return;
            }
        }

        if (ruleType === RuleType.Rank.valueOf()) {
            // Can't rank on more than one classifier
            if (
                this.activeRuleSet.rules.find(
                    (rule) => rule.ruleType === RuleType.Rank,
                )
            ) {
                const msg = "Ranking on multiple rules is prohibited";
                this.setWarning(msg, msg);
                return;
            }
        }

        if (ruleType === RuleType.Rank.valueOf()) {
            // Can't rank on campaign
            if (
                movedClassifier.name === "Campaign" ||
                movedClassifier.name === "Agent Start Date"
            ) {
                const msg =
                    "Ranking on Campaign or Agent Start Date rules is prohibited";
                this.setWarning(msg, msg);
                return;
            }
        }

        const rule = this.activeRuleSet.addRule(
            params.ruleId,
            params.classifierId,
            this.loggedInUser.profile.email,
        );

        if (isUndefinedType(rule) || movedClassifierIndex < 0) {
            if (!rule) {
                console.error(
                    `Can't find rule ${params.ruleId} with type ${params.ruleType} in the active rules`,
                );
            }
            if (movedClassifierIndex < 0) {
                console.error(
                    `Can't find classifier ${params.classifierId} with type ${params.ruleType} in the classifiers`,
                );
            }
            return;
        }

        const classifier = this.classifiers.splice(movedClassifierIndex, 1)[0];
        rule.classifier = classifier;
        rule.ruleType = ruleType;

        if (
            (classifier.name === "Interaction Date" ||
                classifier.name === "Agent Start Date") &&
            rule.ruleType === RuleType.Filter
        ) {
            rule.operator = Operator.Between;
            rule.threshold = [
                generateDefaultStartDate(),
                generateDefaultEndDate(),
            ].join(",");
        } else if (
            classifier.classifierTypeName === "Lucene" ||
            classifier.classifierTypeName === "Tensorflow"
        ) {
            if (rule.ruleType === RuleType.Rank) {
                // NOTE  lucene classifiers only make sense with Descending sort order
                rule.operator = Operator.Gt;
            } else if (rule.ruleType === RuleType.Filter) {
                // NOTE  lucene classifiers for Filter rules are either <true> or <false> indicating presence or absence
                //  of query terms, so EQUAL operator makes sense
                rule.operator = Operator.Eq;
            }
        }

        rule.isActive = true;

        this.classifiersAsRules.push(classifier);
    }

    @action
    loadRuleSets = flow(function* (this: RuleBuildStore) {
        yield delay(0);
        const ruleSets: Promise<RuleSet[]> = this.ruleSetService.getRuleSets();
        yield delay(1000);
        this.ruleSets = observable.array(yield ruleSets);
    });

    @computed
    get speechClassifiers() {
        return this.classifiers.filter((item) => {
            return item.classifierTypeName === ClassifierType.Lucene;
        });
    }

    @computed
    get machineLearningClassifiers() {
        return this.classifiers.filter((item) => {
            return item.classifierTypeName === ClassifierType.Tensorflow;
        });
    }

    @computed
    get metaClassifiers() {
        return this.classifiers
            .filter((item) => {
                return (
                    item.classifierTypeName === ClassifierType.Metadata ||
                    item.classifierTypeName === ClassifierType.Database
                );
            })
            .map((item) => {
                if (item.name.includes("MetaField") && this.orgHasMetaLabels) {
                    const metaFieldNumber = item.name.split("-")[1];
                    if (this.metaLabels["Meta" + metaFieldNumber]) {
                        item.name = startCase(
                            this.metaLabels["Meta" + metaFieldNumber],
                        );
                    }
                }
                return item;
            });
    }

    @computed
    get orgHasMetaLabels() {
        return this.metaLabels && Object.keys(this.metaLabels).length > 0;
    }

    @computed
    get extendedMetaClassifiers() {
        return this.classifiers.filter((item) => {
            return item.classifierTypeName === ClassifierType.ExtendedMetadata;
        });
    }

    @action
    loadClassifiers = flow(function* (this: RuleBuildStore, orgId: string) {
        yield* this.loadUserDetails();

        yield delay(0);
        const classifiers: Promise<Classifier[]> =
            this.classifierService.getPublishedClassifiers(orgId);
        this.classifiers = observable.array(yield classifiers);

        this.setupAsyncTask(LoadRuleSetsOp, () => this.loadRuleSets());
    });

    @action
    loadCampaigns = async () => {
        this.orgCampaigns = await this.campaignService.getCampaigns();
    };

    @action
    loadMetalabels = async () => {
        this.metaLabels = await this.metaLabelService.getMetaLabels();
    };

    private *loadUserDetails() {
        const { user } = yield populateAuth();
        this.loggedInUser = user;
        this.setUserDetails();
    }

    private setUserDetails() {
        if (this.loggedInUser) {
            this._ruleSet.createdBy = this.loggedInUser.profile?.email;
            this._ruleSet.modifiedBy = this.loggedInUser.profile?.email;
            this._ruleSet.ruleCombinators.forEach((value) => {
                value.createdBy = this.loggedInUser.profile?.email;
                value.modifiedBy = this.loggedInUser.profile?.email;
            });
        }
    }
}

export function isNewRuleSet(theRuleSet: RuleSet): boolean {
    return !!Object.getOwnPropertySymbols(theRuleSet).find(
        (value) => value === newRuleSym,
    );
}

function isRule(arg: unknown): arg is Rule {
    return isType<Rule>(arg, "operator");
}

function isRuleSet(arg: unknown): arg is RuleSet {
    return isType<RuleSet>(arg, "addRule");
}

function ruleTypeStringToEnum(ruleTypeStr: RuleTypes) {
    switch (ruleTypeStr) {
        case "Filter":
            return RuleType.Filter.valueOf();
        case "Ranking":
            return RuleType.Rank.valueOf();
        case "Stratify":
            return RuleType.Stratify.valueOf();
        default:
            throw new Error(`Unkown Rule Type: ${ruleTypeStr}`);
    }
}
