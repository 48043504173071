import { observer } from "mobx-react";
import { IApplicationFilterComponent } from "../Filters/IApplicationFilterComponent";
import { Stack } from "@mui/material";
import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { MediaType } from "stores/ApplicationFilters/Filters/MediaType";

const useStyles = makeStyles(() => ({
    checkboxContainer: {
        maxHeight: "28px",
    },
}));

export const MediaTypesFilter = observer(function (
    props: IApplicationFilterComponent,
) {
    const classes = useStyles();

    return (
        <Stack>
            <FormControlLabel
                label="Calls"
                control={
                    <Checkbox
                        onChange={() => {
                            props.store.toggleMediaType(MediaType.Audio);
                        }}
                        checked={props.store.mediaTypes.includes(
                            MediaType.Audio,
                        )}
                    />
                }
                className={classes.checkboxContainer}
            />
            <FormControlLabel
                label="Chats"
                control={
                    <Checkbox
                        onChange={() => {
                            props.store.toggleMediaType(
                                MediaType.ProcessedChat,
                            );
                        }}
                        checked={props.store.mediaTypes.includes(
                            MediaType.ProcessedChat,
                        )}
                    />
                }
                className={classes.checkboxContainer}
            />
        </Stack>
    );
});
