import { AppDomains } from "models/Permission/AppDomains";
import { ITab } from "../../Navigation/NavMenuNew";

export class AdminRoutes {
    static HOME = "/app";
    static SOUNDCLIP_TRANSCRIPTION = "/soundcliptranscribe";
    static PIPELINE_TOOLS = "/pipeline";

    static tabsConfig: Array<ITab> = [
        {
            tabLabel: "Settings",
            routePrefix: "/admin",
            route: "/organizations",
            //roles: ["Ultrausers"],
            domain: AppDomains.Administrative,
        },
        {
            tabLabel: "Organizations",
            routePrefix: "/admin",
            route: "/organizationList",
            //roles: ["Ultrausers"],
            domain: AppDomains.Administrative,
        },
        {
            tabLabel: "Transcribe Sound Clips",
            routePrefix: "/admin",
            route: AdminRoutes.SOUNDCLIP_TRANSCRIPTION,
            //roles: ["Ultrausers"],
            domain: AppDomains.Ultra,
            newDomainName: "Transcribe Sound Clips",
        },
        {
            tabLabel: "Pipeline Tools",
            routePrefix: "/admin",
            route: AdminRoutes.PIPELINE_TOOLS,
            domain: AppDomains.Ultra,
        },
    ];

    static routePrefix = "/admin";
}
