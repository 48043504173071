import { observer } from "mobx-react";
import { IApplicationFilterComponent } from "../Filters/IApplicationFilterComponent";
import React from "react";
import { Grid } from "@mui/material";
import theme from "Theme/AppTheme";
import AcxSelectSingle from "components/UI/Select/BaseSelectComponents/AcxSelectSingle";
import AcxSelectMulti from "components/UI/Select/BaseSelectComponents/AcxSelectMulti";
import { useStore } from "utils/useStore";
import { ApplicationFilterValuesStore } from "stores/ApplicationFilters/ApplicationFilterValuesStore";
import { AuthStore } from "stores/AuthStore";

export const TopicsFilter = observer(function (
    props: IApplicationFilterComponent,
) {
    const valuesStore = useStore(ApplicationFilterValuesStore);
    const authStore = useStore(AuthStore);

    if (!authStore.canUserView("Topics")) return null;

    return (
        <Grid container item direction="column">
            <Grid item>
                <AcxSelectSingle
                    id={"conversations-topics-contains-select"}
                    options={[
                        { label: "is one of", value: true },
                        { label: "is not one of", value: false },
                    ]}
                    labelField="label"
                    valueField="value"
                    defaultValue={{
                        label: props.store.containsTopicsString,
                        value: props.store.containsTopics,
                    }}
                    customStyle={{
                        container: (provided, state) => ({
                            ...provided,
                            backgroundColor: theme.palette.lightgray.main,
                            // cant find a better way to adjust this width
                            width: props.store.containsTopics
                                ? "100px"
                                : "125px",
                        }),
                    }}
                    onChange={(item) => {
                        props.store.setContainsTopics(item.value);
                    }}
                />
            </Grid>
            <Grid
                item
                onClick={() => {
                    if (
                        !valuesStore.topicClusters &&
                        !valuesStore.getTaskLoading(
                            ApplicationFilterValuesStore.Tasks.LOAD_TOPICS,
                        )
                    )
                        valuesStore.loadTopics();
                }}
            >
                <AcxSelectMulti
                    id="conversations-topics-multi-select"
                    fullWidth
                    isLoading={valuesStore.getTaskLoading(
                        ApplicationFilterValuesStore.Tasks.LOAD_TOPICS,
                    )}
                    options={valuesStore.topicClusters ?? []}
                    defaultValue={props.store.topics}
                    onChange={(options) => {
                        props.store.setTopics(!!options ? options : []);
                    }}
                    valueField="id"
                    labelField="topicLabel"
                    containerHeight="auto"
                    maxContainerHeight="96px"
                    alignControls="flex-start"
                    isClearable={true}
                />
            </Grid>
        </Grid>
    );
});
