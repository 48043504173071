import { Grid, Theme, Typography } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import { observer } from "mobx-react";
import React from "react";
import Classifier from "../../../../../../../models/ClassifierModel";
import { ClassifierResultAnswerTag } from "../../../../../../../models/ClassifierResultAnswerTag";
import ClassifierService from "../../../../../../../services/ClassifierService";
import AcxButton from "../../../../../../UI/AcxButton";
import AcxSelect from "../../../../../../UI/Select/BaseSelectComponents/AcxSelect";
import { Question, Tag } from "../../../../types/Module.type";
import theme from "Theme/AppTheme";
import useStyles from "Styles/Styles";

const styles = (theme: Theme) =>
    createStyles({
        tagLabel: {
            color: "#374151",
            fontFamily: "Inter",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "20px",
        },
    });

type TagToClassifier = { [tagId: string]: Classifier };
type TagToResult = { [tagId: string]: ClassifierResultAnswerTag };

export const LabelSmartPredictConfig = observer(
    (props: {
        loading: boolean;
        orgId: string;
        classifiers: Classifier[];
        question?: Question;
        tags: Tag[];
        handleOnSubmit: (
            mappings: ClassifierResultAnswerTag[],
        ) => Promise<void>;
    }) => {
        const classes = useStyles(styles);

        // Below are two state objects (mapping tagId to Classifier and tagId to ClassifierResultAnswerTag)
        const [tagToClassifierMap, setTagToClassifiertMap] =
            React.useState<TagToClassifier>({});

        const [tagToResultMap, setTagToResultMap] = React.useState<TagToResult>(
            {},
        );

        // An internal loading state for when we get the existing config
        const [loadingClassifiersAndResults, setLoadingClassifiersAndResults] =
            React.useState<boolean>(true);

        const removeSelectedResult = (tagId: string) => {
            let tagToResultMapClone = { ...tagToResultMap };
            delete tagToResultMapClone[tagId];
            setTagToResultMap(tagToResultMapClone);
        };

        const handleClassifierChange = (event, tag: Tag) => {
            const selectedClassifier = props.classifiers.find(
                (classifier) => classifier.id === event.id,
            );

            const changed =
                tagToClassifierMap[tag.id] === undefined ||
                tagToClassifierMap[tag.id] !== selectedClassifier;

            if (changed) {
                setTagToClassifiertMap(
                    (prevState) =>
                        ({
                            ...prevState,
                            [tag.id]: selectedClassifier,
                        } as TagToClassifier),
                );
                // If we've changed the classifier, we need to remove the tagId to ClassifierResultAnswerTag
                // so the user has to select a new Result. This is because different classifiers can have different ClassifierOptions (Results)
                removeSelectedResult(tag.id);
            }
        };

        const handleClassifierResultChange = (event, tag: Tag) => {
            // If "No Selection" is selected, remove that ClassifierResult
            if (event === undefined) {
                removeSelectedResult(tag.id);
                return;
            }

            setTagToResultMap(
                (prevState) =>
                    ({
                        ...prevState,
                        [tag.id]: {
                            questionId: props.question?.id,
                            organizationId: props.orgId,
                            classifierId: tagToClassifierMap[tag.id].id,
                            tagId: tag.id,
                            result: event.result,
                            classifierOutputId: event.id,
                        } as ClassifierResultAnswerTag,
                    } as TagToResult),
            );
        };

        React.useEffect(() => {
            setLoadingClassifiersAndResults(true);
            const getExistingConfig = async () => {
                if (props.question && props.question.id && props.orgId) {
                    const existingAutoScoreConfig: ClassifierResultAnswerTag[] =
                        await new ClassifierService().getAutoScoringConfiguration(
                            props.orgId,
                            props.question.id,
                        );

                    // Set TagId to Classifier and Set TagId to Result
                    let tagsToClassifier: TagToClassifier = {};

                    let tagsToResult: TagToResult = {};

                    existingAutoScoreConfig.forEach(
                        (classifierResultAnswerTag) => {
                            tagsToClassifier[classifierResultAnswerTag.tagId] =
                                classifierResultAnswerTag.classifier;

                            tagsToResult[classifierResultAnswerTag.tagId] =
                                classifierResultAnswerTag;
                        },
                    );

                    setTagToClassifiertMap(tagsToClassifier);
                    setTagToResultMap(tagsToResult);
                    setLoadingClassifiersAndResults(false);
                }
            };

            getExistingConfig();
        }, [props.orgId, props.question]);

        return (
            <Grid
                container
                justifyContent="center"
                alignContent="center"
                style={{ margin: "1rem 0" }}
            >
                {props.tags &&
                    props.tags
                        .sort((tag1, tag2) => tag1.order - tag2.order)
                        .map((tag) => {
                            return (
                                <Grid
                                    key={tag.id}
                                    container
                                    justifyContent="space-between"
                                    alignItems="center"
                                    style={{
                                        margin: "0.25rem",
                                        backgroundColor:
                                            theme.palette.lightgray.main,
                                        padding: theme.spacing(1),
                                        borderRadius: theme.shape.borderRadius,
                                    }}
                                >
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        style={{ marginBottom: "5px" }}
                                    >
                                        <Typography
                                            className={classes.tagLabel}
                                        >
                                            Tag: {tag.value}
                                        </Typography>
                                    </Grid>

                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        style={{ marginBottom: "5px" }}
                                    >
                                        <Typography
                                            style={{
                                                ...theme.typography.h6,
                                                opacity: 1,
                                            }}
                                        >
                                            SmartPredict Classifier
                                        </Typography>
                                        <AcxSelect
                                            fullWidth={false}
                                            isDisabled={props.loading}
                                            defaultValue={
                                                tagToClassifierMap[tag.id]
                                            }
                                            id={`auto-scoring-classifier-selector-${props.question?.id}`}
                                            labelField="name"
                                            valueField="id"
                                            options={props.classifiers ?? []}
                                            onChange={(event) =>
                                                handleClassifierChange(
                                                    event,
                                                    tag,
                                                )
                                            }
                                            placeholder={
                                                props.loading ||
                                                loadingClassifiersAndResults
                                                    ? "Loading..."
                                                    : "Classifier"
                                            }
                                        />
                                    </Grid>

                                    <Grid container item xs={12}>
                                        <Typography
                                            style={{
                                                ...theme.typography.h6,
                                                opacity: 1,
                                            }}
                                        >
                                            Result
                                        </Typography>
                                        <AcxSelect
                                            enableNoSelection
                                            fullWidth={false}
                                            isDisabled={
                                                props.loading ||
                                                tagToClassifierMap[tag.id] ===
                                                    undefined
                                            }
                                            defaultValue={
                                                tagToResultMap[tag.id]
                                            }
                                            id={`auto-scoring-classifier-result-selector-${props.question?.id}`}
                                            labelField="result"
                                            valueField="id"
                                            options={
                                                tagToClassifierMap[tag.id]
                                                    ?.classifierOutputs ?? []
                                            }
                                            onChange={(event) =>
                                                handleClassifierResultChange(
                                                    event,
                                                    tag,
                                                )
                                            }
                                            placeholder={
                                                props.loading ||
                                                loadingClassifiersAndResults
                                                    ? "Loading..."
                                                    : "Classifier Result"
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            );
                        })}

                <Grid
                    container
                    item
                    xs={12}
                    justifyContent="flex-end"
                    alignItems="center"
                    style={{
                        margin: "0.25rem",
                    }}
                >
                    <AcxButton
                        buttonDisabled={props.loading}
                        fullWidth={false}
                        color="secondary"
                        leftRightSpacing={0}
                        rootStyle={{
                            padding: "1rem",
                            marginTop: "1.75rem",
                        }}
                        onClick={() =>
                            props.handleOnSubmit(Object.values(tagToResultMap))
                        }
                    >
                        Apply Changes
                    </AcxButton>
                </Grid>
            </Grid>
        );
    },
);
