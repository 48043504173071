import { Paper } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import { IAcxHierarchyComponentProps } from "./AcxHierarchySelector";
import HierarchySelectorFooter from "./HierarchySelectorFooter";
import HierarchySelectorHeader from "./HierarchySelectorHeader";
import HierarchySelectorTree from "./HierarchySelectorTree";

const HierarchySelectorBase: React.FC<IAcxHierarchyComponentProps> = observer(
    (props: IAcxHierarchyComponentProps) => {
        return (
            <Paper
                sx={(theme) => {
                    const paperStyles: any = {
                        margin: ".5rem",
                        width: "350px",
                        backgroundColor: "#ffffff",
                        borderRadius: "4px",
                    };
                    if (props?.fixedPopper) {
                        paperStyles.position = "relative";
                        paperStyles.left = "493px";
                        paperStyles.top = "5px";
                    }
                    if (props.width) {
                        paperStyles.width = props.width;
                    }
                    props.fullHeight
                        ? (paperStyles.height = "100%")
                        : (paperStyles.maxHeight = "703px");
                    return paperStyles;
                }}
            >
                {!props.hideTopContent && (
                    <HierarchySelectorHeader {...props} />
                )}

                <HierarchySelectorTree {...props} />

                {!props.hideBottomContent && (
                    <HierarchySelectorFooter {...props} />
                )}
            </Paper>
        );
    },
);

export default HierarchySelectorBase;
