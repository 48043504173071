import { Avatar } from "@mui/material";
import React from "react";
import { AgentLogo } from "./AgentLogo";

export function AgentAvatar() {
    return (
        <Avatar
            sx={{
                width: "28px",
                height: "28px",
                background:
                    "linear-gradient(131deg, #32D1B7 3.43%, #349AC7 70.89%, #3664D6 100.5%)",
            }}
        >
            <AgentLogo color="white" />
        </Avatar>
    );
}
