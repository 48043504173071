import {
    Box,
    Button,
    Divider,
    Fade,
    Grid,
    Theme,
    Typography,
} from "@mui/material";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import { observer, useLocalStore } from "mobx-react";
import React, { FunctionComponent, useEffect } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { MediaClip } from "../../../models/SoundClip";
import { ChatViewModel } from "./ViewModel/ChatViewModel";
import ChatMessageList from "./Views/ChatMessageList";
import { EddyEffectP2Results } from "models/EddyEffectP2Results";
import AcxLoadingIndicator from "../AcxLoadingIndicator";
import NoRedactionWarning from "./Views/NoRedactionWarning";
import AcxButton from "../AcxButton";
import { customSecondaryButtonStyle } from "utils/Style";

interface StyleProps {
    showHeader?: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
    controlsContainer: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },
    clipMsgsButton: {
        textTransform: "none",
        fontFamily: theme.typography.fontFamily,
        fontWeight: "bold",
        fontSize: "13px",
    },
    fullHeight: {
        height: "100%",
    },
    scrollContainer: (props) => ({
        height: `calc(100% - ${props.showHeader ? "114" : "55"}px)`,
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(0.8),
    }),
    scrollContainerToast: {
        height: `calc(100% - 130px)`,
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(0.8),
    },
    header: {
        paddingTop: theme.spacing(1.5),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(1.5),
        paddingLeft: theme.spacing(2),
    },
}));

export enum ChatPersona {
    Agent = "agent",
    Customer = "customer",
}

export interface IChatRecord {
    text: string | null;
    timestamp: string;
    from: ChatPersona;
}

interface AugmentedText {
    word: string | null;
    eddy?: boolean;
    viewed?: boolean;
}

export interface IAugmentedChatRecord extends IChatRecord {
    words: AugmentedText[];
    clipId?: string;
    color?: string;
    startIndex?: number;
    eddy?: boolean;
}

interface OwnProps {
    records?: IChatRecord[];
    onClip?: (text: string, start: number, end: number) => void;
    mediaClips?: MediaClip[];
    scrollTo?: MediaClip;
    setFocusClip?: (clipId?: string) => void;
    showHeader?: boolean;
    eddyEffectP2Data: EddyEffectP2Results[];
    loading?: boolean;
    showNoRedactionWarning?: boolean;
    secondaryButton?: boolean;
    secondaryButtonLabel?: string;
    secondaryButtonOnClick?: () => void;
    disableClip?: boolean;
}

type Props = OwnProps;

const AcxChatViewer: FunctionComponent<Props> = observer((props) => {
    const classes = useStyles({
        showHeader: props.showHeader,
    });
    const viewModel = useLocalStore(() => new ChatViewModel());

    useEffect(() => {
        viewModel.setOnClipCallback(props.onClip);
        viewModel.setOnFocusClip(props.setFocusClip);
        viewModel.setActiveMediaClips(props.mediaClips);
        viewModel.setRecords(props.records);
        viewModel.setEddyEffectP2Results(props.eddyEffectP2Data);
    }, [
        props.eddyEffectP2Data,
        props.mediaClips,
        props.onClip,
        props.records,
        props.setFocusClip,
        viewModel,
    ]);

    useEffect(() => {
        if (props.scrollTo) {
            viewModel.clippedMsgRefs.get(props.scrollTo.id)?.scrollIntoView();
        }
    }, [props.scrollTo, viewModel.clippedMsgRefs]);

    return (
        <div className={clsx(classes.fullHeight, "pendo-ignore")}>
            {props.showHeader && (
                <Box>
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        className={classes.header}
                    >
                        <Grid item>
                            <Typography variant="h2">
                                Chat Transcript
                            </Typography>
                        </Grid>
                        {props.secondaryButton && (
                            <Grid item>
                                <AcxButton
                                    customRootStyles={{
                                        ...customSecondaryButtonStyle,
                                        marginRight: 0,
                                    }}
                                    onClick={props.secondaryButtonOnClick}
                                >
                                    {props.secondaryButtonLabel}
                                </AcxButton>
                            </Grid>
                        )}
                    </Grid>
                    <Divider variant={"fullWidth"} />
                </Box>
            )}
            {viewModel.isClipping && (
                <Grid
                    container
                    alignItems={"center"}
                    className={classes.controlsContainer}
                >
                    <Grid item container justifyContent={"flex-end"} xs={12}>
                        <Fade in={viewModel.isClipping}>
                            <Button
                                className={classes.clipMsgsButton}
                                variant={"contained"}
                                color={"secondary"}
                                size={"small"}
                                onClick={viewModel.clipRecordsInSet}
                                disableElevation
                            >
                                Clip Messages
                            </Button>
                        </Fade>
                    </Grid>
                </Grid>
            )}

            <div
                className={
                    props.showNoRedactionWarning
                        ? classes.scrollContainerToast
                        : classes.scrollContainer
                }
            >
                {props.showNoRedactionWarning === true && (
                    <NoRedactionWarning />
                )}
                {props.loading ? (
                    <AcxLoadingIndicator size={24} alternate="PuffLoader" />
                ) : (
                    <Scrollbars>
                        <ChatMessageList
                            records={viewModel.augmentedRecords}
                            viewModel={viewModel}
                            disableClip={props.disableClip || !props.onClip}
                        />
                    </Scrollbars>
                )}
            </div>
        </div>
    );
});

export default AcxChatViewer;
