import { Container, Grid } from "@mui/material";
import AcxButton from "components/UI/AcxButton";
import { Observer } from "mobx-react";
import React from "react";
import { AuthStore } from "stores/AuthStore";
import { ClipUIModel } from "../../../models/SoundClip";
import SoundClipEditor from "../../SoundClipEditor/SoundClipEditor";
import MontageClip from "../MontageClip";
import { MontageSoundClipStore } from "../Stores/MontageSoundClipStore";
import { useTheme } from "@mui/system";

export default function MontageClipEditor(props: {
    store: MontageSoundClipStore;
    authStore: AuthStore;
}) {
    const theme = useTheme();
    return (
        <Observer>
            {() => (
                <Container maxWidth={false}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="stretch"
                        spacing={1}
                    >
                        <Grid item container justifyContent={"flex-end"}>
                            {props.store.combinedClip
                                ? [
                                      <Grid item key={"export-btn-grid"}>
                                          <AcxButton
                                              key={"export-btn"}
                                              onClick={
                                                  props.store.openSaveDialog
                                              }
                                              rootStyle={{
                                                  padding: "1rem",
                                                  minWidth: "max-content",
                                              }}
                                              leftRightSpacing={1}
                                          >
                                              Save
                                          </AcxButton>
                                      </Grid>,
                                      <Grid item key={"reset-btn-grid"}>
                                          <AcxButton
                                              key={"reset-btn"}
                                              onClick={props.store.resetMontage}
                                              rootStyle={{
                                                  padding: "1rem",
                                                  minWidth: "max-content",
                                              }}
                                              leftRightSpacing={0}
                                          >
                                              Reset
                                          </AcxButton>
                                      </Grid>,
                                  ]
                                : [
                                      <Grid item key={"merge-clips-grid"}>
                                          <AcxButton
                                              key={"merge-clips"}
                                              loading={
                                                  props.store.anyClipsLoading
                                              }
                                              buttonDisabled={
                                                  !props.store.soundClipDgStore
                                                      .SelectedRows.length ||
                                                  props.store.anyClipsLoading
                                              }
                                              onClick={
                                                  props.store.generateMontage
                                              }
                                              rootStyle={{
                                                  padding: "1rem",
                                                  minWidth: "max-content",
                                                  backgroundColor:
                                                      theme.palette.secondary
                                                          .main,
                                              }}
                                              leftRightSpacing={0}
                                          >
                                              Merge Clips
                                          </AcxButton>
                                      </Grid>,
                                  ]}
                        </Grid>

                        {props.store.combinedClip ? (
                            <Grid
                                item
                                key={
                                    "montage-clip-grid-" +
                                    props.store.combinedClip?.id
                                }
                            >
                                <MontageClip
                                    renderAudioEditor={() => (
                                        <SoundClipEditor
                                            id={props.store.combinedClip?.id!}
                                            index={0}
                                            clip={
                                                props.store
                                                    .combinedClip as ClipUIModel
                                            }
                                            clipAudioBuffer={
                                                props.store.combinedBuffer
                                            }
                                            clipBlob={props.store.combinedBlob}
                                            onClipLoading={
                                                props.store.setClipLoading
                                            }
                                            onAudioBufferReady={(arg) =>
                                                (props.store.combinedBuffer =
                                                    arg.audioBuffer)
                                            }
                                            enableZoom
                                            isMontage
                                        />
                                    )}
                                    id={props.store.combinedClip?.id}
                                    index={0}
                                    store={props.store}
                                    moveCard={props.store.moveCard}
                                    onDelete={
                                        props.store.removeSelectedClipById
                                    }
                                    key={
                                        "montage-clip-" +
                                        props.store.combinedClip?.id
                                    }
                                    clip={props.store.combinedClip}
                                    hideControls
                                    showUndo
                                    showRedaction={false}
                                />
                            </Grid>
                        ) : (
                            props.store.tabIndex === 1 &&
                            props.store.soundClipDgStore.SelectedRows.map(
                                (clip: any, index) => (
                                    <Grid
                                        item
                                        key={"montage-clip-grid-" + clip.id}
                                    >
                                        <MontageClip
                                            renderAudioEditor={() => (
                                                <SoundClipEditor
                                                    id={clip.id}
                                                    index={index}
                                                    clip={clip}
                                                    clipAudioBuffer={
                                                        props.store.clipsRedacted.find(
                                                            (value) => {
                                                                return (
                                                                    value ===
                                                                    clip.id
                                                                );
                                                            },
                                                        ) !== undefined
                                                            ? props.store.audioBuffersSet.get(
                                                                  clip.id,
                                                              )
                                                                  ?.redactedAudioBufer
                                                            : props.store.audioBuffersSet.get(
                                                                  clip.id,
                                                              )?.audioBuffer
                                                    }
                                                    onClipLoading={
                                                        props.store
                                                            .setClipLoading
                                                    }
                                                    onAudioBufferReady={
                                                        props.store
                                                            .addAudioBufferToSet
                                                    }
                                                    enableZoom
                                                    isMontage
                                                />
                                            )}
                                            id={clip.id}
                                            index={index}
                                            store={props.store}
                                            moveCard={props.store.moveCard}
                                            onDelete={
                                                props.store
                                                    .removeSelectedClipById
                                            }
                                            key={"montage-clip-" + clip.id}
                                            clip={clip}
                                            showRedaction={props.authStore.canUserEdit(
                                                "Clip Redaction",
                                            )}
                                        />
                                    </Grid>
                                ),
                            )
                        )}
                    </Grid>
                </Container>
            )}
        </Observer>
    );
}
