import { observer } from "mobx-react";
import { Grid, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import NoData from "./NoData";
import AcxButton from "components/UI/AcxButton";
import { useStore } from "utils/useStore";
import AddIcon from "@mui/icons-material/Add";
import SignalsReportSelectorStore from "../Store/SignalsReportSelectorStore";
import SignalsReportStore from "../Store/SignalsReportStore";

const useStyles = makeStyles((theme: Theme) => ({
    addButton: {
        color: theme.palette.blackFont.main,
        backgroundColor: theme.palette.gray.background,
        width: "115px",
    },
}));

// type Props = { domainName: SignalsDomain };

const SignalsEmptyDashboard: React.FC = observer((props) => {
    const classes = useStyles();
    const store = useStore(SignalsReportSelectorStore);
    const signalsReportStore = useStore(SignalsReportStore);

    return (
        <>
            <Grid item>
                <NoData
                    headerText="No Reports"
                    subheaderText="Add a report to get
            started"
                    dashboardVariant
                />
                {signalsReportStore.dashboardIsEditable && (
                    <Grid container style={{ justifyContent: "center" }}>
                        <Grid item>
                            <AcxButton
                                onClick={() =>
                                    (store.showReportSelector = true)
                                }
                                size="small"
                                leftRightSpacing={0}
                                startIcon={<AddIcon />}
                                buttonClass={classes.addButton}
                            >
                                Add Report
                            </AcxButton>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </>
    );
});

export default SignalsEmptyDashboard;
