import { Grid } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import { observer } from "mobx-react";
import React from "react";
import useStyles from "Styles/Styles";
import ModelDashboardStore from "../Stores/ModelDashboardStore";
import ModelDashboardBodyControls from "./ModelDashboardBodyControls";
import ModelDashboardPrimaryContent from "./ModelDashboardPrimaryContent";
import ModelDashboardStatsBar from "./ModelDashboardStatsBar";

const styles = () =>
    createStyles({
        bodyContentWrapper: {
            width: "100%",
            height: "100%",
        },
        controlsWrapper: {},
        statsWrapper: {
            margin: "1.25rem 0",
        },
        primaryContentWrapper: {
            height: "calc(75% - 1.5rem)",
            overflowY: "scroll",
        },
    });

type IModelDashboardBodyContentProps = {
    store: ModelDashboardStore;
};

const ModelDashboardBodyContent: React.FC<React.PropsWithChildren<React.PropsWithChildren<IModelDashboardBodyContentProps>>> =
    observer(({ store }) => {
        const classes = useStyles(styles);

        return (
            <Grid container item xs={12} className={classes.bodyContentWrapper}>
                <Grid
                    container
                    item
                    xs={12}
                    className={classes.controlsWrapper}
                >
                    {/* <Grid container item xs={2}>
                        <img
                            src={icon}
                            alt="eddy effect icon"
                            style={{ maxWidth: "29px", maxHeight: "29px" }}
                        />
                    </Grid> */}
                    <ModelDashboardBodyControls store={store} />
                </Grid>

                <Grid container item xs={12} className={classes.statsWrapper}>
                    <ModelDashboardStatsBar store={store} />
                </Grid>

                <Grid item xs={12} className={classes.primaryContentWrapper}>
                    <ModelDashboardPrimaryContent store={store} />
                </Grid>
            </Grid>
        );
    });

export default ModelDashboardBodyContent;
