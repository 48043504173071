import { Grid, useTheme } from "@mui/material";
import React, { FunctionComponent } from "react";
import AcxLoadingIndicator from "../AcxLoadingIndicator";

interface OwnProps {
    leftChild?: React.ReactNode;
    leftChildError?: React.ReactNode;
    rightChild?: React.ReactElement;
    rightChildError?: React.ReactNode;
    anotherRightChild?: React.ReactNode;
    bottomChild?: React.ReactNode;
    bottomChildError?: React.ReactNode;
    statusIndicator?: React.ReactNode;
    isLoading?: boolean;
    height?: string;
}

type Props = OwnProps;

const AcxMultiColumnCard: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props) => {
    const theme = useTheme();

    return (
        <div
            style={{
                boxShadow: theme.shadows[2],
                borderRadius: "10px",
                width: "100%",
                backgroundColor: "white",
                padding: "16px",
                height: props.height || "100%",
            }}
        >
            <Grid
                style={{ width: "100%", height: "100%" }}
                container
                justifyContent={"flex-start"}
            >
                <Grid
                    container
                    direction={"row"}
                    justifyContent={"flex-start"}
                    item
                    xs={12}
                    style={{ minHeight: "270px" }}
                >
                    {props.statusIndicator ? (
                        <div
                            style={{
                                display: "flex",
                                width: "100%",
                                height: "100%",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            {props.statusIndicator}
                        </div>
                    ) : null}
                    {props.isLoading && !props.statusIndicator ? (
                        <AcxLoadingIndicator
                            size={120}
                            color="secondary"
                            alternate="PuffLoader"
                        />
                    ) : (
                        <>
                            {!props.statusIndicator && (
                                <>
                                    <Grid
                                        item
                                        xl={
                                            (props.rightChild &&
                                                !Array.isArray(
                                                    props.rightChild?.props
                                                        .children,
                                                )) ??
                                            props.rightChildError
                                                ? 6
                                                : 12
                                        }
                                        lg={12}
                                        style={{ minHeight: "270px" }}
                                        container
                                    >
                                        {props.leftChildError ??
                                            props.leftChild}
                                    </Grid>

                                    {(props.rightChild ??
                                        props.rightChildError) && (
                                        <Grid item xl={6} lg={12} container>
                                            <Grid
                                                item
                                                xs={
                                                    props.anotherRightChild
                                                        ? 8
                                                        : 12
                                                }
                                                style={{ height: "100%" }}
                                            >
                                                {props.rightChildError ??
                                                    props.rightChild}
                                            </Grid>
                                            {props.anotherRightChild && (
                                                <Grid
                                                    item
                                                    xs={4}
                                                    style={{ height: "100%" }}
                                                >
                                                    {props.anotherRightChild}
                                                </Grid>
                                            )}
                                        </Grid>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </Grid>

                <Grid
                    item
                    container
                    xs={12}
                    style={{ height: "5%" }}
                    justifyContent={"center"}
                >
                    {props.bottomChildError ?? props.bottomChild}
                </Grid>
            </Grid>
        </div>
    );
};

export default AcxMultiColumnCard;
