import { action, observable, makeObservable } from "mobx";

export type Task = () => Promise<any>;

export default class TaskQueue {
    @observable tasks: Array<Task>;
    @observable maxConcurrency: number;
    @observable inFlight: number;

    public constructor(maxConcurrency = 0) {
        makeObservable(this);
        if (!(Number.isInteger(maxConcurrency) && maxConcurrency >= 0))
            throw new Error(
                "maxConcurrency must be a positive integer, or 0 to disable",
            );

        this.tasks = new Array<Task>();
        this.maxConcurrency = maxConcurrency;
        this.inFlight = 0;
    }

    @action
    public clear() {
        this.tasks = new Array<Task>();
    }

    @action
    public enqueue(newItem: Task) {
        this.tasks.unshift(newItem);
        this.processNext();
    }

    @action
    private async processNext() {
        if (this.inFlight >= this.maxConcurrency) return;
        const nextTask = this.tasks.pop();
        if (nextTask === undefined) return;

        try {
            this.inFlight += 1;
            await nextTask();
        } finally {
            this.inFlight -= 1;
            this.processNext();
        }
    }
}
