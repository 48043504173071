import { Grid } from "@mui/material";
import LuceneIndexSearchResults from "components/NLP/LuceneIndexSearch/LuceneIndexSearchResults";
import AcxButton from "components/UI/AcxButton";
import AcxDateRangeInput from "components/UI/Calendar/DateRange/AcxDateRangeInput";
import { Moment } from "moment";
import React from "react";
import { LuceneIndexSearchStore } from "../../LuceneIndexSearch/LuceneIndexSearchStore";
import { SearchResult } from "./ClassifierBuilderSearchController";
import { Observer } from "mobx-react";

interface Props {
    searchResultsLoading: boolean;
    searchResults?: SearchResult[];
    buttonLabel: string;
    doSearch: () => void;
    store: LuceneIndexSearchStore;
}

function ClassifierBuilderSearchView(props: Props) {
    React.useEffect(() => {
        props.store.builderPage = true;
    });

    function renderControls() {
        return (
            <Grid container item xs={12} style={{ margin: "1.25rem 0" }}>
                <Grid item>
                    <Observer>
                        {() => (
                            <AcxDateRangeInput
                                labelText="Select Arrived On Date Range"
                                defaultStartDate={
                                    props.store.datePickerStore.beginDate
                                }
                                defaultEndDate={
                                    props.store.datePickerStore.endDate
                                }
                                onSelect={(start: Moment, end: Moment) => {
                                    props.store.datePickerStore.setBeginDate(
                                        start,
                                    );
                                    props.store.datePickerStore.setEndDate(end);
                                }}
                            />
                        )}
                    </Observer>
                </Grid>

                <Grid container item xs={4} alignItems="flex-end">
                    <AcxButton
                        color="secondary"
                        onClick={props.doSearch}
                        customRootStyles={{ height: "32px" }}
                    >
                        {props.buttonLabel}
                    </AcxButton>
                </Grid>
            </Grid>
        );
    }

    return (
        <>
            {renderControls()}

            <LuceneIndexSearchResults
                store={props.store}
                searchResults={props.searchResults}
                isLoading={props.searchResultsLoading}
            />
        </>
    );
}

export default ClassifierBuilderSearchView;
