import AzureTranscription from "models/AzureTranscription";
import ITranscriptionResult from "models/ITranscriptionResult";
import AudioMetadataModel from "../models/AudioMetadataModel";
import { BaseService } from "./BaseService";

export interface ITranscriptionSegment {
    text: string;
    startTime: number;
    endTime: number;
    channel: number;
}

export interface ITranscriptionPayload {
    text: string;
    phrases: ITranscriptionSegment[];
    words: ITranscriptionSegment[];
    success: boolean;
}

export interface IMultiLanguageTranscriptionPayload {
    languageCode: "en" | "es";
    transcription: ITranscriptionPayload;
}

export interface TranscriptionSpeakerPayload {
    name: string;
    channel: number;
    audioMetadataId: string;
    id: string;
    isActive: boolean;
}

export type GetTranscriptionSpeakerPayload = TranscriptionSpeakerPayload[];

export class TranscriptionService extends BaseService {
    protected urlBase: string = "api/Transcription";

    public extractIdFromTranscriptionFileName(fileName: string) {
        return fileName.substring(0, fileName.indexOf("_")).trim();
    }

    public async getMetadataForTranscription(
        transcriptionId: string,
    ): Promise<AudioMetadataModel> {
        const rv = await this.get<AudioMetadataModel>(
            new URLSearchParams(),
            `Metadata/${encodeURIComponent(transcriptionId)}`,
        );

        return rv;
    }

    public static async identityTransformer(
        input: ITranscriptionResult,
    ): Promise<ITranscriptionResult> {
        return input;
    }

    public async getTranscriptionTextForAudioMetadata(
        audioMetadataId: string,
    ): Promise<ITranscriptionPayload> {
        const res = await this.get<ITranscriptionPayload>(
            new URLSearchParams(),
            `TranscriptionTextByAudioMetadata/${encodeURIComponent(
                audioMetadataId,
            )}`,
        );
        return res;
    }

    public async getTranscriptionText(transcriptionId: string) {
        const res = await this.get<ITranscriptionPayload>(
            new URLSearchParams({ TranscriptionId: transcriptionId }),
            `GetTranscriptionText`,
        );
        return res;
    }

    public async getTranscriptions() {
        const res = await this.get<AzureTranscription[]>(
            new URLSearchParams(),
            "Status",
        );
        return res;
    }

    public async clearFinishedTranscriptions() {
        await this.get<void>(new URLSearchParams(), "ClearCompleted");
    }

    public async startSoundClipTranscription(soundClipId: string) {
        const path = "StartSoundClip";
        const params = new URLSearchParams({ soundClipId });
        await this.get<null>(params, path);
    }

    public async getMulitLanguageTranscriptionByAmdId(amdId: string) {
        const path = `MultiLanguageTranscriptionByAudioMetadata/${encodeURIComponent(
            amdId,
        )}`;
        const params = new URLSearchParams();
        return await this.get<IMultiLanguageTranscriptionPayload[]>(
            params,
            path,
        );
    }

    public async getTranscriptionSpeakers(amdId: string) {
        const params = new URLSearchParams({ audioMetadataId: amdId });
        return await this.get<GetTranscriptionSpeakerPayload>(
            params,
            "TranscriptionSpeakers",
        );
    }

    public async createTranscriptionSpeaker(
        amdId: string,
        channel: number,
        name: string,
    ) {
        const params = new URLSearchParams({
            audioMetadataId: amdId,
            channel: channel.toString(),
            name,
        });
        return await this.post<TranscriptionSpeakerPayload>(
            JSON.stringify({}),
            params,
            "TranscriptionSpeaker",
        );
    }

    public async updateTranscriptionSpeaker(
        amdId: string,
        channel: number,
        name: string,
    ) {
        const params = new URLSearchParams({
            id: amdId,
            channel: channel.toString(),
            name,
        });
        return await this.put<TranscriptionSpeakerPayload>(
            params,
            "TranscriptionSpeaker",
        );
    }

    public async deleteTranscriptionSpeaker(amdId: string) {
        const params = new URLSearchParams({ id: amdId });
        return await this.delete(params, "TranscriptionSpeaker");
    }
}
