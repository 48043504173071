import React from "react";
import ClassifierBuilderSearchView from "./ClassifierBuilderSearchView";
import { useStore } from "utils/useStore";
import { ClassifierBuilderSearchStore } from "./ClassifierBuilderSearchStore";
import { observer } from "mobx-react";
import { ClassifierBuilderEditorStore } from "../Editor/ClassifierBuilderEditorStore";

export type CombinedResult = {
    display: string;
};

export type AudioFileResult = {
    combinedResults: CombinedResult[];
    existsInDatabase: boolean;
};

export type SearchResult = {
    "@search.score": number;
    metadata_storage_name: string;
    metadata_storage_path: string;
    audioFileResults: AudioFileResult[];
    existsInDatabase: boolean;
};

export type IndexObj = {
    value: number;
    label: string;
};

const ClassifierBuilderSearchController = observer(() => {
    const store = useStore(ClassifierBuilderSearchStore);
    const editorStore = useStore(ClassifierBuilderEditorStore);
    const searchStore = store.searchStore;

    return (
        <ClassifierBuilderSearchView
            store={searchStore}
            searchResultsLoading={searchStore.searchResultsLoading}
            searchResults={searchStore.searchResults}
            buttonLabel="Search"
            doSearch={() => {
                searchStore.doSearch(
                    store.builderStore.orgSelectStore.orgId,
                    editorStore.editorQuery,
                );

                document
                    .getElementById("lucerne-search-results-wrapper")
                    ?.scrollIntoView({ behavior: "smooth" });
            }}
        />
    );
});

export default ClassifierBuilderSearchController;
