import React from "react";
import EddyIconSvg from "SvgIcons/EddyIconSvg";

const EddyLabel = () => {
    return (
        <>
            <EddyIconSvg
                style={{
                    width: "24px",
                    height: "24px",
                    float: "left",
                }}
            />
            <span style={{ float: "right", marginLeft: "4px" }}>
                Eddy Dashboard
            </span>
        </>
    );
};

export default EddyLabel;
