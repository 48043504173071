import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import IColDef from "components/UI/AcxDataGrid/IColDef";
import { dateColumnType } from "components/UI/AcxDataGrid/ColumnTypes/DateColTypes";

export const columnDefinitions = (
    nameRenderCell: (params: GridRenderCellParams) => React.ReactElement,
): IColDef[] => [
    {
        field: "id",
        headerName: "Id",
        hide: true,
    },
    {
        field: "name",
        headerName: "Classifier Name",
        flex: 1,
        valueFormatter: (params) => params.value?.toString() ?? "",
        renderCell: nameRenderCell,
    },
    {
        field: "modifiedOn",
        headerName: "Last Updated",
        flex: 1,
        ...dateColumnType,
    },
    {
        field: "createdBy",
        headerName: "Owner",
        flex: 2,
    },
    {
        field: "searchTerms",
        headerName: "Search Terms",
        flex: 1,
        valueGetter: ({ value }) => {
            return `${value} ${value === 1 ? "Term" : "Terms"}`;
        },
    },
    {
        field: "classifierCategory",
        headerName: "Category",
        flex: 1,
        valueGetter: ({ row }) => {
            return row.classifierCategory?.name ?? "";
        },
    },
    {
        field: "classifierGroup",
        headerName: "Group",
        flex: 1,
        valueGetter: ({ row }) => {
            return row.classifierGroup?.name ?? "";
        },
    },
    {
        field: "status",
        headerName: "Status",
        flex: 1,
        valueGetter: (params) =>
            params.row.isPublished ? "Published" : "Saved",
    },
];
