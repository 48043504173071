import { observer } from "mobx-react";
import { IApplicationFilterComponent } from "../Filters/IApplicationFilterComponent";
import React from "react";
import AcxToggleButton from "components/UI/AcxToggleButton";
import { SafetyEvent } from "stores/ApplicationFilters/Filters/SafetyEvent";
import { useStore } from "utils/useStore";
import { AuthStore } from "stores/AuthStore";

export const SafetyEventFilter = observer(function (
    props: IApplicationFilterComponent,
) {
    const authStore = useStore(AuthStore);

    if (!authStore.canUserView("Safety Events Model")) return null;

    return (
        <AcxToggleButton
            id="conversations-filter-safety-events-toggle-button"
            exclusive
            items={[
                {
                    element: "Acknowledged",
                    value: SafetyEvent.Acknowledged,
                },
                {
                    element: "Not Acknowledged",
                    value: SafetyEvent.NotAcknowledged,
                },
                {
                    element: "Not Identified",
                    value: SafetyEvent.NotIdentified,
                },
            ]}
            onChange={(value) => {
                props.store.setSafetyEvent(value);
            }}
            defaultValue={props.store.safetyEvent}
            justifyContent="center"
            groupFlexGrow={1}
        />
    );
});
