import {
    GridColDef,
    GridColTypeDef,
    GridFilterItem,
} from "@mui/x-data-grid-pro";
import _ from "lodash";
import {
    statusFormatter,
    StatusFormatterFilter,
} from "../Formatters/StatusFormatter";

export const statusColumnType: GridColTypeDef = {
    extendType: "string",
    renderCell: statusFormatter,
    filterOperators: [
        {
            label: "equals",
            value: "equals",
            getApplyFilterFn: (
                filterItem: GridFilterItem,
                column: GridColDef,
            ) => {
                if (
                    !filterItem.columnField ||
                    !filterItem.value ||
                    !filterItem.operatorValue
                ) {
                    return null;
                }

                return (params): boolean => {
                    const rowValue = _.get(params.row, column.field);
                    return (
                        rowValue?.toString() === filterItem.value?.toString()
                    );
                };
            },
            InputComponent: StatusFormatterFilter,
        },
    ],
};
