import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import { ITab } from "components/Navigation/NavMenuNew";
import AcxButton from "components/UI/AcxButton";
import { IAcxTableColumn } from "components/UI/AcxTable/AcxTable";
import { ChromeTab } from "components/UI/SubTabs/ChromeTabs";
import {
    action,
    computed,
    makeObservable,
    observable,
    reaction,
    runInAction,
} from "mobx";
import { AppDomains } from "models/Permission/AppDomains";
import { User } from "oidc-client";
import React from "react";
import { UserService } from "services/UserService";
import { AuthStore } from "stores/AuthStore";
import { BaseStore } from "stores/BaseStore";
import { DialogComponentStore } from "stores/ComponentStores/DialogComponentStore";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";
import ValidationStore, { ValidateProp } from "stores/ValidationStore";
import { delay } from "utils/helpers";
export class AppUser {
    firstName: string;
    lastName: string;
    userName: string;
    password: string;
    newPassword: string;
    role: string;
    id: string;
    organizationId: string;
}
@AcxStore
export default class UserStore extends BaseStore {
    location: Location;
    @observable tabIndex: number = 0;

    tabsConfig: Array<ITab> = [
        {
            tabLabel: "User Profile",
            routePrefix: "",
            route: "/admin/user/userprofile",
            // roles: ["All"],
            domain: AppDomains.MyDashboard,
        },
    ];

    dialogStore = new DialogComponentStore();
    authStore: AuthStore;

    @observable currentUser: User | null;
    @observable appUser: AppUser;
    @observable passwordError: string = "";
    @observable orgId;

    @observable editUser: AppUser | null = null;

    @observable loading: boolean = false;
    @observable rowsPerPage: number = 10;
    @observable page: number = 0;

    @observable selectedOrgId;
    @observable ogUserList: AppUser[] = [];
    @observable userList: AppUser[] = [];

    @observable userListHeader: IAcxTableColumn[];
    @observable filterValue: string;
    @observable selectedUsers: AppUser[] = [];

    @observable roleList: any[] = [];
    @observable validateStore: ValidationStore;

    //New user creation props
    @observable username: ValidateProp = new ValidateProp(
        "",
        undefined,
        "Invalid username",
        "isEmail",
    );
    @observable password: ValidateProp = new ValidateProp(
        "",
        undefined,
        "Invalid password",
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&?])(?=.*[a-zA-Z\d!@#$%&?]).{8,}$/,
    );

    @observable passwordCheck: ValidateProp = new ValidateProp(
        "",
        undefined,
        "Passwords must match",
        (val) => {
            if (val.length > 0) {
                if (this.password.value !== val) {
                    return false;
                }
            }
            return true;
        },
    );
    @observable firstname: string;
    @observable lastname: string;
    @observable registerMessage: string;

    userService: UserService = new UserService();

    constructor(public rootStore: IRootStore) {
        super("UserStore");
        makeObservable(this);
        this.authStore = rootStore.getStore(AuthStore);
        this.getCurrentUser();
        this.userService.getRoles().then((res) => {
            this.roleList = res;
        });

        this.validateStore = new ValidationStore();
        this.validateStore.register(this.username);
        this.validateStore.register(this.password);
        this.validateStore.register(this.passwordCheck);

        this.userListHeader = [
            // {dataKey: "id", headerLabel: "User Id"},
            { dataKey: "firstName", headerLabel: "First Name" },
            { dataKey: "lastName", headerLabel: "Last Name" },
            { dataKey: "userName", headerLabel: "User Name" },
            {
                dataKey: "disabled",
                headerLabel: "Status",
                formatter: (id, row) => (row.disabled ? "Disabled" : "Active"),
            },
            {
                dataKey: "id",
                headerLabel: "Edit User",
                formatter: this.userActionFormatter,
            },
        ];

        reaction(
            () => this.userTabs,
            (tbs) => {
                if (tbs.length - 1 > this.tabIndex) {
                    this.tabIndex = tbs.length - 1;
                }
            },
        );
        reaction(
            () => this.currentUser,
            () => {
                const appUser = new AppUser();
                appUser.firstName = this.currentUser?.profile.firstName;
                appUser.lastName = this.currentUser?.profile.lastName;
                appUser.userName = this.currentUser?.profile.email!;
                appUser.id = this.currentUser?.profile.uId;

                this.appUser = appUser;
                // debugger;
            },
        );
        reaction(
            () => this.filterValue,
            () => {
                this.filter(this.filterValue);
            },
            { delay: 600 },
        );

        reaction(
            () => this.selectedOrgId,
            () => {
                this.getAllUsers(this.selectedOrgId);
            },
            { delay: 200 },
        );
    }
    cancelResetPassword = () => {
        this.editUser = null;
    };
    resetPassword = async () => {
        if (this.editUser && this.editUser.newPassword !== "") {
            this.dialogStore.isLoading = true;
            await this.userService.adminResetPassword(this.editUser);
            this.dialogStore.isLoading = false;
            await delay(200);
            this.dialogStore.close();
        }
    };
    resetPasswordDialog = (u: AppUser) => {
        this.editUser = u;
        this.dialogStore.open();
    };

    userActionFormatter = (id: string, row: AppUser): React.ReactElement => {
        return (
            <AcxButton
                onClick={() => {
                    this.resetPasswordDialog(row);
                }}
            >
                Reset Password
            </AcxButton>
        );
    };

    @action
    updateFilterValue(value: string) {
        this.filterValue = value;
    }

    @action
    filter = (filterValue: string) => {
        if (filterValue === "") {
            this.userList = [...this.ogUserList];
        } else {
            filterValue = filterValue.toLowerCase();
            const x = this.ogUserList.filter((value, index) => {
                const res =
                    ((value.firstName?.toLowerCase().includes(filterValue) ??
                        false) ||
                        (value.lastName?.toLowerCase().includes(filterValue) ??
                            false) ||
                        value.userName?.toLowerCase().includes(filterValue)) ??
                    false;
                return res;
            });
            this.userList = [...x];
        }
        this.page = 0;
    };

    @action
    selectUsers = (items: any[]) => {
        if (items.length === 0) {
            this.selectedUsers.splice(0, this.selectedUsers.length);
        } else {
            const removeThese = this.selectedUsers.filter(
                (v) => !items.includes(v.id),
            );
            for (let rm of removeThese) {
                const indx = this.selectedUsers.findIndex(
                    (value) => value.id === rm.id,
                );
                if (indx > -1) {
                    this.selectedUsers.splice(indx, 1);
                }
            }

            for (let i = 0; i < items.length; i++) {
                const sc = this.userList.find((f) => f.id === items[i]);
                if (!sc) {
                    continue;
                }
                const scCopy: AppUser = { ...sc } as AppUser;
                if (
                    this.selectedUsers.findIndex((sc) => sc.id === items[i]) ===
                    -1
                ) {
                    this.selectedUsers.push(scCopy);
                }
            }
        }
    };

    @computed
    get userListPage() {
        return this.userList.slice(
            this.page * this.rowsPerPage,
            this.page * this.rowsPerPage + this.rowsPerPage,
        );
    }
    @action
    async getAllUsers(orgId) {
        this.loading = true;
        this.userList.splice(0, this.userList.length);
        try {
            const users = await this.userService.getUsers(orgId);

            runInAction(() => {
                this.userList.splice(0, this.userList.length);
                this.userList.push(...users);
                this.ogUserList = users;
                this.loading = false;
            });
        } catch (error) {
            this.loading = false;
        }
    }
    //Todo: this needs more sophisticated role chek
    private getChromeTabsConfig = (): ChromeTab[] => {
        let cur;
        // if (
        //     this.authStore.permStore.user.role.name === "Ultra" ||
        //     this.authStore.permStore.user.role.name === "Super"
        // ) {
        cur = this.tabsConfig;
        // } else {
        //     cur = this.tabsConfig.filter((t) => t.roles.includes("All"));
        // }
        return cur.map((value) => {
            return { label: value.tabLabel, route: value.route };
        });
    };

    @computed
    get userTabs() {
        const tbs = this.getChromeTabsConfig();
        return tbs;
    }

    validatePasswordMatch = (val: string) => {
        if (val !== this.appUser.newPassword) {
            this.passwordError = "New password does not match";
        } else {
            this.passwordError = "";
        }
    };

    updateUserProfile = async () => {
        try {
            await this.userService.updateUserProfile(this.appUser);
            this.rootStore
                .getStore(MessageStore)
                .logMessage("Profile update successful.", "success");
        } catch (error) {
            this.rootStore
                .getStore(MessageStore)
                .logError("Profile update failed");
        }
    };

    updatePassword = async () => {
        try {
            await this.userService.updatePassword(this.appUser);
            this.rootStore
                .getStore(MessageStore)
                .logMessage("Password update successful.", "success");
        } catch (error) {
            this.rootStore
                .getStore(MessageStore)
                .logError("Password update failed.");
        }
    };

    @computed
    get Roles() {
        return this.roleList;
    }

    createUser = () => {};

    private getCurrentUser = () => {
        this.authStore.getUserObject().then((u) => {
            runInAction(() => (this.currentUser = u));
        });
    };

    @action
    onTabChange = (newValue: number) => {
        this.tabIndex = newValue;
    };
}
