import { Tooltip } from "@mui/material";
import {
    GridFilterInputValueProps,
    GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import AcxMainTextField from "components/UI/AcxMainTextFieldGrid";
import _, { truncate } from "lodash";
import React from "react";

export const DefaultFilter = (props: GridFilterInputValueProps) => {
    const { item, applyValue } = props;
    const [value, setValue] = React.useState(item.value);

    const handleFilterChange = (event) => {
        const val = event.target.value;
        setValue(val);

        applyValue({ ...item, value: event.target.value });
    };

    return (
        <AcxMainTextField
            id="filter-value"
            labelText="Search Value"
            value={value}
            onChange={handleFilterChange}
            textItemStyle={{ height: "32px" }}
        />
    );
};

export const StringFormatter = (params: GridValueFormatterParams) => {
    const value = _.get(params.row, params.field)?.toString();
    const len = params.colDef.width! * 0.14;
    return (
        <Tooltip title={value ?? ""} enterDelay={500}>
            <span>{truncate(value, { length: len })}</span>
        </Tooltip>
    );
};
