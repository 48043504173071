import { Grid, Paper } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import {
    filterableGridColumnsSelector,
    GridCloseIcon,
    GridFilterItem,
    GridFilterOperator,
    GridLinkOperator,
    GridStateColDef,
    useGridSelector,
    useGridSlotComponentProps,
} from "@mui/x-data-grid-pro";
import AcxSelectSingle from "components/UI/Select/BaseSelectComponents/AcxSelectSingle";
import * as React from "react";
import AcxDataGridStore from "../AcxDataGridStore";

export interface GridFilterFormProps {
    item: GridFilterItem;
    hasMultipleFilters: boolean;
    showMultiFilterOperators?: boolean;
    multiFilterOperator?: GridLinkOperator;
    disableMultiFilterOperator?: boolean;
    applyFilterChanges: (item: GridFilterItem) => void;
    applyMultiFilterOperatorChanges: (operator: GridLinkOperator) => void;
    deleteFilter: (item: GridFilterItem) => void;
    store: AcxDataGridStore;
}

const useStyles = makeStyles(
    {
        root: {
            display: "flex",
            justifyContent: "space-around",
            margin: "0.25rem",
            padding: "5px",
        },
        linkOperatorSelect: {
            padding: "0.25rem",
        },
        columnSelect: {
            width: 150,
            padding: "0.25rem",
        },
        operatorSelect: {
            width: 120,
            padding: "0.25rem",
        },
        filterValueInput: {
            width: 190,
            padding: "0.25rem",
        },
    },
    { name: "MuiGridFilterForm" },
);

export function GridFilterForm(props: GridFilterFormProps) {
    const {
        item,
        hasMultipleFilters,
        deleteFilter,
        applyFilterChanges,
        showMultiFilterOperators,
        applyMultiFilterOperatorChanges,
        disableMultiFilterOperator,
    } = props;
    const classes = useStyles();

    const { state, apiRef } = useGridSlotComponentProps();
    const filterableColumns = useGridSelector(
        apiRef,
        filterableGridColumnsSelector as any,
    );

    const getCurrentColumn = React.useCallback(() => {
        if (!item.columnField) {
            return null;
        }
        return apiRef!.current.getColumn(item.columnField)!;
    }, [apiRef, item]);

    const getCurrentOperator = React.useCallback(() => {
        const currentColumn = getCurrentColumn();
        if (!item.operatorValue || !currentColumn) {
            return null;
        }
        return currentColumn.filterOperators?.find(
            (operator) => operator.value === item.operatorValue,
        );
    }, [item, getCurrentColumn]);

    const changeCol = React.useCallback(
        (result: { id: string; value: string }) => {
            const columnField = result.id as string;
            const column = apiRef!.current.getColumn(columnField)!;
            let newOperator = column.filterOperators![0];

            // set default filter operator for number types to != to avoid filtering all rows
            if (column.type === "number") {
                newOperator = column.filterOperators![1];
            }

            applyFilterChanges({
                ...item,
                value: undefined,
                columnField,
                operatorValue: newOperator.value,
            });
        },
        [apiRef, applyFilterChanges, item],
    );

    const colOptions = (): ({ id: string; value: string } | any)[] => {
        const ar: { id: string; value: string }[] = [];
        (filterableColumns as GridStateColDef[]).forEach((val) => {
            if (val.filterable !== false) {
                ar.push({
                    id: val.field,
                    value: val.headerName ? val.headerName : val.field,
                });
            }
        });
        return ar;
    };

    const getOption = (): { id: string; value: string } | undefined => {
        const val = (filterableColumns as GridStateColDef[]).find((value) => {
            return value.field === item.columnField;
        });
        if (val) {
            return {
                id: val?.field,
                value: val.headerName ? val.headerName : val.field,
            };
        }
    };

    const getLinkOperator = () => {
        const currentOperator = linkOperators.find((operator) => {
            return operator.id === state.filter.linkOperator;
        });
        if (currentOperator) {
            return currentOperator;
        } else {
            return linkOperators[0];
        }
    };

    const getOperatorValue = () => {
        let currentOperator;
        if (item.columnField) {
            currentOperator = apiRef.current.state.columns.lookup[
                item.columnField
            ].filterOperators?.find((operator) => {
                return operator.value === item.operatorValue;
            });
        } else {
            currentOperator = getCurrentColumn()?.filterOperators?.[0];
        }
        return currentOperator;
    };

    const changeOp = React.useCallback(
        (operator: GridFilterOperator) => {
            const operatorValue = operator.value as string;
            applyFilterChanges({
                ...item,
                operatorValue,
            });
        },
        [applyFilterChanges, item],
    );

    const changeLinkOperator = React.useCallback(
        (input: { id: string; label: string }) => {
            const linkOperator =
                input.id === GridLinkOperator.And.toString()
                    ? GridLinkOperator.And
                    : GridLinkOperator.Or;
            applyMultiFilterOperatorChanges(linkOperator);
        },
        [applyMultiFilterOperatorChanges],
    );

    const handleDeleteFilter = React.useCallback(() => {
        if (apiRef.current.state.filter.items.length === 1) {
            apiRef.current.hideFilterPanel();
            props.store.updateFiltered(false);
        }
        deleteFilter(item);
    }, [apiRef, deleteFilter, item, props.store]);

    const currentOperator = getCurrentOperator();

    const linkOperators = [
        {
            id: GridLinkOperator.And.toString(),
            label: apiRef!.current.getLocaleText(
                "filterPanelOperatorAnd",
            ) as string,
        },
        {
            id: GridLinkOperator.Or.toString(),
            label: apiRef!.current.getLocaleText(
                "filterPanelOperatorOr",
            ) as string,
        },
    ];

    return (
        <Paper className={classes.root}>
            <Grid
                className={classes.linkOperatorSelect}
                style={{
                    display: hasMultipleFilters ? "block" : "none",
                    visibility: showMultiFilterOperators ? "visible" : "hidden",
                }}
            >
                <AcxSelectSingle
                    options={linkOperators}
                    id="filter-form-link-operator-select"
                    valueField="id"
                    labelField="label"
                    inputLabel="Link Operator"
                    onChange={changeLinkOperator}
                    defaultValue={getLinkOperator()}
                    isDisabled={!!disableMultiFilterOperator}
                />
            </Grid>
            <Grid className={classes.columnSelect}>
                <AcxSelectSingle
                    options={colOptions()}
                    id="filter-form-field-select"
                    valueField="id"
                    labelField="value"
                    inputLabel="Select Field"
                    onChange={changeCol}
                    defaultValue={getOption()}
                />
            </Grid>
            <Grid className={classes.operatorSelect}>
                <AcxSelectSingle
                    options={getCurrentColumn()?.filterOperators ?? []}
                    defaultValue={getOperatorValue()}
                    valueField="value"
                    labelField={
                        getCurrentColumn()?.filterOperators?.[0]?.label
                            ? "label"
                            : "value"
                    }
                    id="filter-operator-select"
                    inputLabel="Select Operator"
                    onChange={changeOp}
                />
            </Grid>
            <Grid className={classes.filterValueInput}>
                {currentOperator?.InputComponent ? (
                    <currentOperator.InputComponent
                        apiRef={apiRef}
                        item={item}
                        applyValue={applyFilterChanges}
                        {...currentOperator.InputComponentProps}
                    />
                ) : null}
            </Grid>
            <Grid>
                <IconButton
                    aria-label={apiRef!.current.getLocaleText(
                        "filterPanelDeleteIconLabel",
                    )}
                    title={apiRef!.current.getLocaleText(
                        "filterPanelDeleteIconLabel",
                    )}
                    onClick={handleDeleteFilter}
                    size="small"
                >
                    <GridCloseIcon fontSize="small" />
                </IconButton>
            </Grid>
        </Paper>
    );
}
