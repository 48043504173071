import React, { useState } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Grid, Button, Collapse } from "@mui/material";
import theme from "Theme/AppTheme";

interface Props {
    query?: string;
}

const buttonStyle = {
    textTransform: "none",
    color: theme.palette.gray.main,
} as React.CSSProperties;
const queryStyle = { color: theme.palette.gray.main } as React.CSSProperties;

function ReportCardQuery(props: Props) {
    const [isExpanded, setIsExpanded] = useState(false);
    const chevron = isExpanded ? (
        <KeyboardArrowUpIcon />
    ) : (
        <KeyboardArrowDownIcon />
    );

    if (!props.query) return null;

    return (
        <Grid container alignItems="center" direction="column">
            <Grid item>
                <Button
                    style={buttonStyle}
                    onClick={() => setIsExpanded(!isExpanded)}
                >
                    {chevron}
                    Classifier Search Terms
                </Button>
            </Grid>
            <Collapse in={isExpanded}>
                <Grid item style={queryStyle}>
                    {props.query}
                </Grid>
            </Collapse>
        </Grid>
    );
}

export default ReportCardQuery;
