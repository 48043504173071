import { Grid, useMediaQuery } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import theme from "../../../Theme/AppTheme";
import { useStore } from "../../../utils/useStore";
import { Routes } from "../../Navigation/Routes";
import ConfirmationDialog from "../../UI/AcxConfirmationDialog";
import {
    AnalystDashboardStore,
    FindNextEvalTask,
} from "./Stores/AnalystDashboardStore";
import AnalystEvaluationTable from "./Views/AnalystEvaluationTable";
import AnalystHistoryCard from "./Views/AnalystHistoryCard";
import Header from "./Views/Header";

const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: theme.palette.white.main,
        boxShadow: "0 0 3px 0 rgba(0,0,0,0.05), 0 1px 1px 0 rgba(0,0,0,0.05)",
        height: "205px",
    },
    main: {
        paddingLeft: theme.spacing(3.5),
        paddingRight: theme.spacing(3.5),
        height: "calc(100% - 205px)",
        width: "100%",
    },
    cardWrapper: {
        marginTop: "2rem",
        marginBottom: "2rem",
    },
}));

interface OwnProps {}

type Props = OwnProps;

const AnalystDashboard: FunctionComponent<Props> = observer((props) => {
    const store = useStore(AnalystDashboardStore);
    const navigate = useNavigate();

    const classes = useStyles(props);
    const mediumDownMatch = useMediaQuery(theme.breakpoints.down('lg'));

    const goToEvalPage = (evalId: string) => {
        const organizationId = store.orgSelectorStore.orgId;

        if (!evalId || !organizationId) {
            throw new Error("EvalId or OrgId empty");
        }

        navigate(Routes.makeEvaluationRoute(organizationId, evalId));
    };

    return (
        <Grid container item xs={12}>
            <ConfirmationDialog
                onClose={store.closeNextEvalDialog}
                title={"Searching queues for your next evaluation"}
                isLoading={store.getTaskLoading(FindNextEvalTask)}
                noControls
                isOpen={store.showNextEvalDialog}
                errorMessage={store.getTaskError(FindNextEvalTask)?.message}
                content={store.noAdditionalEvalMsg}
            />

            <Grid container item xs={12} className={classes.header}>
                <Header goToEvalPage={goToEvalPage} />
            </Grid>

            <Grid
                container
                item
                xs={12}
                className={classes.main}
                direction="row-reverse"
            >
                <Grid item xs={12} lg={2} className={classes.cardWrapper}>
                    <AnalystHistoryCard
                        analystHistory={store.analystHistory}
                        rootCardStyle={{
                            height: "280px",
                        }}
                    />
                </Grid>

                <Grid
                    container
                    item
                    xs={12}
                    lg
                    style={{
                        height: "100%",
                        paddingRight: mediumDownMatch ? "" : "2rem",
                    }}
                >
                    <AnalystEvaluationTable goToEvalPage={goToEvalPage} />
                </Grid>
            </Grid>
        </Grid>
    );
});

export default AnalystDashboard;
