import {
    GridCellValue,
    GridComparatorFn,
    GridSortCellParams,
    GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import { ArrayToCommaSepString } from "../../../../utils/StringUtils";
import { isArrayType } from "../../../../utils/TypeGuards";
import { StringFormatter } from "./DefaultFormatters";

export const ArrayJoinFormatter = (params: GridValueFormatterParams) => {
    const value = params.getValue(params.id, params.field)?.valueOf();
    if (isArrayType(value)) {
        const joinedArray = ArrayToCommaSepString(value);
        return StringFormatter({ ...params, getValue: (field) => joinedArray });
    }
    return "";
};

export const ArrayJoinComparator: GridComparatorFn = (
    v1: GridCellValue,
    v2: GridCellValue,
    cellParams1: GridSortCellParams,
    cellParams2: GridSortCellParams,
): number => {
    const x1 = ArrayToCommaSepString(v1 as any[]);
    const x2 = ArrayToCommaSepString(v2 as any[]);

    return x1 > x2 ? 1 : -1;
};
