import React from "react";
import withStyles from "@mui/styles/withStyles";
import MuiExpansionPanel from "@mui/material/Accordion";
import MuiExpansionPanelDetails from "@mui/material/AccordionDetails";
import MuiExpansionPanelSummary from "@mui/material/AccordionSummary";
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import useStyles from 'Styles/Styles';

// const styles = (theme: Theme) => createStyles({
//     root: {
//       width: '100%',
//     },
//     heading: {
//         backgroundColor: theme.palette.secondary.main,
//         color: theme.palette.white.main,
//         // '&$expanded': {
//         //     margin: 'auto',
//         //   },
//     //   fontSize: theme.typography.pxToRem(15),
//     //   flexBasis: '33.33%',
//     //   flexShrink: 0,
//     },
//     secondaryHeading: {
//       fontSize: theme.typography.pxToRem(15),
//       color: theme.palette.text.secondary,
//     },

//   });
const Accordion = withStyles({
    root: {
        border: "1px solid rgba(0, 0, 0, .125)",
        boxShadow: "none",
        "&:not(:last-child)": {
            borderBottom: 0,
        },
        "&:before": {
            display: "none",
        },
        "&$expanded": {
            margin: "auto",
        },
    },
    expanded: {},
})(MuiExpansionPanel);

const AccordionSummary = withStyles((theme) => ({
    root: {
        //   backgroundColor: 'rgba(0, 0, 0, .03)',
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText + "!important",
        borderBottom: "1px solid rgba(0, 0, 0, .125)",
        marginBottom: -1,
        minHeight: 56,
        "&$expanded": {
            minHeight: 56,
        },
    },
    content: {
        "&$expanded": {
            margin: "12px 0",
        },
    },
    expanded: {},
}))(MuiExpansionPanelSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiExpansionPanelDetails);

export interface AcxExpansionType {
    index: number;
    expanded: number | false;
    onChange: (
        index: number,
    ) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
    header: React.ReactElement;
    content?: React.ReactElement;
}
export default function AcxExpansionAccordion(props: AcxExpansionType) {
    // const classes = useStyles(styles);
    // const [expanded, setExpanded] = React.useState<string | false>(false);

    // const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    //     setExpanded(isExpanded ? panel : false);
    // };

    return (
        <Accordion
            square
            disabled={props.content === undefined ? true : false}
            expanded={props.expanded === props.index}
            onChange={props.onChange(props.index)}
        >
            <AccordionSummary
                aria-controls={props.index + "bh-content"}
                id={"panel_" + props.index}
            >
                {props.header}
            </AccordionSummary>
            <AccordionDetails>{props.content}</AccordionDetails>
        </Accordion>
    );
}
