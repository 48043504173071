import CloseIcon from "@mui/icons-material/Close";
import { Grid, IconButton, keyframes, styled } from "@mui/material";
import PaperAirplaneSvg from "SvgIcons/PaperAirplaneSvg";
import theme from "Theme/AppTheme";
import AcxMainTextField from "components/UI/AcxMainTextFieldGrid";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useStore } from "utils/useStore";
import AgentChatStore from "./AgentChatStore";
import { MessageAuthor } from "./Models/MessageAuthor";
import { MessageLoading } from "./Messages/MessageLoading";
import { AgentLogoWithName } from "./Branding/AgentLogoWithName";
import { ThreadMessageGroup } from "./ThreadMessageGroup";
import AcxButton from "components/UI/AcxButton";
import { AuthStore } from "stores/AuthStore";
import { AgentState } from "./Models/AgentState";
import { Cancel } from "@mui/icons-material";

type Props = {
    close: () => void;
};

const inputExpand = keyframes`
    from {
        width: 0%
    }
    to {
        width: 100%
    }
`;

const inputStyleProps = {
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "24px",
    color: theme.palette.gray[500],
};

const Grow = styled(Grid)(() => ({
    animationDelay: "300ms",
    animation: `${inputExpand} 700ms forwards`,
}));

const NewChatButton = styled(AcxButton)(({ theme }) => ({
    backgroundColor: "transparent",
    color: theme.palette.black.main,
    ":hover": {
        backgroundColor: "transparent",
        opacity: "75%",
    },
}));

const AgentChat: React.FC<Props> = observer(({ close }) => {
    const store = useStore(AgentChatStore);
    const authStore = useStore(AuthStore);

    const scrollableChatRef = useRef<HTMLDivElement>(null);
    const chatEndRef = useRef<HTMLDivElement>(null);

    const [isAutoScrolling, setIsAutoScrolling] = useState(false);

    const submit = useCallback(async () => {
        if (store.state !== AgentState.Idle) return;
        await store.streamingSubmitInput();
    }, [store]);

    useEffect(() => {
        if (!scrollableChatRef.current || !chatEndRef.current) return;
        const scrollRef = scrollableChatRef.current;
        const chatRef = chatEndRef.current;

        const observer = new IntersectionObserver(
            (entries) => {
                const entry = entries[0];
                if (entry.isIntersecting) setIsAutoScrolling(true);
            },
            { threshold: 1 },
        );

        function onUserScroll() {
            setIsAutoScrolling(false);
        }

        observer.observe(chatRef);
        scrollRef.addEventListener("wheel", onUserScroll);

        return () => {
            observer.unobserve(chatRef);
            scrollRef.removeEventListener("wheel", onUserScroll);
        };
    }, []);

    useEffect(() => {
        if (!chatEndRef.current) return;
        const recentMessageGroup = store.thread.recentMessageGroup;
        if (
            recentMessageGroup &&
            recentMessageGroup.author === MessageAuthor.User
        ) {
            chatEndRef.current.scrollIntoView({ behavior: "smooth" });
            setIsAutoScrolling(true);
        }
    }, [store.thread, store.thread.recentMessageGroup]);

    const recentAgentMessage =
        store.thread.recentAgentMessageGroup?.messages[0];
    useEffect(() => {
        if (!isAutoScrolling || !chatEndRef.current) return;
        chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }, [isAutoScrolling, recentAgentMessage?.content]);

    return (
        <Grid
            container
            direction="column"
            flexWrap="nowrap"
            height="90vh"
            width="372px"
            sx={(theme) => ({
                rowGap: theme.spacing(5),
            })}
        >
            <Grid
                container
                item
                alignItems="center"
                justifyContent="space-between"
                flexWrap="nowrap"
                paddingTop={1}
                paddingInline={2}
            >
                <AgentLogoWithName variant="wordmark-inverse" />
                <Grid
                    container
                    item
                    alignItems="center"
                    columnGap={1}
                    sx={{ width: "fit-content" }}
                >
                    {authStore.isUserUltra() && (
                        <Grid item>
                            <NewChatButton
                                onClick={() => store.resetChat()}
                                leftRightSpacing={0}
                                fullWidth={false}
                            >
                                New Chat
                            </NewChatButton>
                        </Grid>
                    )}
                    <Grid item>
                        <IconButton onClick={() => close()} size="small">
                            <CloseIcon
                                style={{ color: theme.palette.gray[400] }}
                            />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                container
                item
                direction="column"
                flexWrap="nowrap"
                rowGap={3}
                flexGrow={1}
                paddingInline={2}
                sx={{ overflowY: "auto" }}
                ref={scrollableChatRef}
            >
                {store.thread.messageGroups.map((item, index) => {
                    return (
                        <ThreadMessageGroup
                            key={`agent-chat-${index}`}
                            {...item}
                            isMostRecent={
                                index === store.thread.messageGroups.length - 1
                            }
                        />
                    );
                })}
                <MessageLoading />
                <Grid ref={chatEndRef}></Grid>
            </Grid>

            <Grow
                item
                paddingInline={2}
                sx={{ bottom: 0 }}
                alignSelf="center"
                className={"pendo-ignore"}
            >
                <AcxMainTextField
                    value={store.currentInput}
                    onChange={(event) =>
                        store.onChangeHandler(event.target.value)
                    }
                    id="agent-chat-user-input"
                    placeholderText="What can I help you with?"
                    endAdornment={
                        store.state === AgentState.Idle ? (
                            <PaperAirplaneSvg
                                style={{
                                    margin: "8px",
                                    marginLeft: "4px",
                                    cursor: store.currentInput
                                        ? "pointer"
                                        : "default",
                                }}
                                onClick={submit}
                            />
                        ) : (
                            <IconButton onClick={() => store.abort()}>
                                <Cancel />
                            </IconButton>
                        )
                    }
                    textContainerSx={(theme) => ({
                        border: `1px solid`,
                        borderColor: theme.palette.gray[200],
                        boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.10)",
                        ":focus-within": {
                            borderColor: theme.palette.gray[300],
                        },
                    })}
                    inputProps={{ style: inputStyleProps }}
                    autoComplete="off"
                    onEnterPressed={submit}
                    autoFocus
                />
            </Grow>
        </Grid>
    );
});

export default AgentChat;
