import { CssBaseline, ThemeProvider, Theme, StyledEngineProvider } from "@mui/material";
import React from "react";
import { useLocation } from "react-router";
import theme from "../../../../../Theme/AppTheme";
import TranscriptionVisualController from "./TranscriptionVisualController";


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


function ClassifierTranscription() {
    const query = new URLSearchParams(useLocation().search);
    const transcriptionId = query.get("transcriptionId");
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {transcriptionId ? (
                    <TranscriptionVisualController
                        transcriptionId={transcriptionId}
                    />
                ) : (
                    <div>Route requires transcription fileName URL paramter</div>
                )}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default ClassifierTranscription;
