import { Grid, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import { BeatLoader } from "react-spinners";
import { DashboardStore } from "../../../stores/Layout/DashboardStore";
import theme from "../../../Theme/AppTheme";
import { useResizeObserver } from "../../../utils/useResizeObserver";
import { BaseWidgetDefinition } from "../Definitions/BaseWidgetDefinition";
import WidgetMenu from "./WidgetMenu";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        // width: "100%", height: "100%",
        // position: "relative"
    },

    cardBody: (props: Props) => ({
        padding: props.styles?.padding ?? theme.spacing(1.5),
        // margin: theme.spacing(2.5),
        backgroundColor:
            props.styles?.backgroundColor ?? theme.palette.white.main,
        boxShadow:
            props.styles?.boxShadow ??
            "0 0 2px 1px rgba(0, 0, 0, 0.03), 0 6px 10px 2px rgba(0, 0, 0, 0.08)",
        borderRadius: 5,
    }),
    cardText: {
        userSelect: "none",
    },

    smallButton: {
        width: "32px",
        height: "32px",
    },

    bodyContainer: {
        // padding: theme.spacing(4)
        width: "100%",
        height: "100%",
    },

    moreVertButton: {
        width: "48px",
        height: "32px",
        top: -9,
        right: -9,
    },

    smallOutlineIconButton: {
        color: theme.palette.secondary.main,
        // borderStyle: "solid",
        // borderColor: theme.palette.secondary.main,
        // borderWidth: "1px",
    },
}));

interface OwnProps {
    dashboardStore?: DashboardStore;
    content: (cardHeight?: number, controlsHeight?: number) => React.ReactNode;
    widgetDefinition?: BaseWidgetDefinition;
    disableActions?: boolean;
    styles?: React.CSSProperties;
}

type Props = OwnProps;

const WidgetCard: FunctionComponent<
    React.PropsWithChildren<React.PropsWithChildren<Props>>
> = observer((props) => {
    const classes = useStyles(props);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { elementHeight, elementWidth, theRef } =
        useResizeObserver<HTMLDivElement>({
            // name: "widgetCardMain",
            debounceWait: 10,
        });

    const {
        elementHeight: controlsHeight,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        elementWidth: controlsWidth,
        theRef: controlsRef,
    } = useResizeObserver<HTMLDivElement>({
        // name: "widgetCardControls",
        debounceWait: 10,
    });

    return (
        <>
            <Grid
                container
                ref={theRef}
                item
                xs
                className={classes.bodyContainer}
                style={{ height: "calc(100% - 0px)" }}
            >
                <Grid
                    container
                    item
                    xs
                    className={classes.cardBody}
                    style={{ height: "100%" }}
                >
                    {(!!props.widgetDefinition?.useCardTitleAndMenu ||
                        !props.widgetDefinition?.viewModelReady) && (
                        <Grid
                            ref={controlsRef}
                            item
                            xs={12}
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems={"flex-start"}
                            wrap={"nowrap"}
                        >
                            <Grid
                                item
                                xs
                                spacing={2}
                                container
                                alignItems={"baseline"}
                            >
                                <Grid item zeroMinWidth>
                                    <Typography
                                        className={classes.cardText}
                                        noWrap
                                        variant="h5"
                                    >
                                        {props.widgetDefinition?.title}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <BeatLoader
                                        size={6}
                                        color={theme.palette.primary.main}
                                        loading={
                                            props.widgetDefinition?.loading
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <WidgetMenu
                                dashboardStore={props.dashboardStore}
                                disableActions={props.disableActions}
                                widgetDefinition={props.widgetDefinition}
                            />
                        </Grid>
                    )}
                    <Grid
                        container
                        item
                        xs={12}
                        style={{
                            height: "calc(100% - 8px)",
                            paddingTop: "8px",
                        }}
                    >
                        <div
                            style={{
                                height: `${
                                    (elementHeight ?? 0) -
                                    ((controlsHeight ?? 0) + 24)
                                }px`,
                                width: `${(elementWidth ?? 0) - 16}px`,
                            }}
                        >
                            {props.content(elementHeight, controlsHeight)}
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
});

export default WidgetCard;
