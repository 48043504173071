import { FormHelperText } from "@mui/material";
import AcxSelectSingle from "components/UI/Select/BaseSelectComponents/AcxSelectSingle";
import { autorun } from "mobx";
import { Observer } from "mobx-react-lite";
import Organization from "models/Organization";
import React, { CSSProperties, useEffect } from "react";
import { OrgSelectorComponentStore } from "stores/ComponentStores/OrgSelectorComponentStore";
import LocalStorage from "stores/LocalStorage";
import RootStore from "stores/RootStore";
import { AuthStore } from "../../../stores/AuthStore";
import { useStore } from "../../../utils/useStore";

interface IOrgSelect {
    id: string;
    onLoading?: (isLoading: boolean) => void;
    showLoading?: boolean;
    onError?: (errorMessage?: string) => void;
    onSelect?: (org: Organization) => void;
    onDisplay?: (isVisible: boolean) => void;
    label?: string;
    fullWidth?: boolean;
    menuPortalTarget?: HTMLElement | null | undefined;
    disabled?: boolean;
    autoSelectUsersOrg?: boolean;
    defaultValue?: any;
    customStyle?: any;
    orgId?: string;
    customRootStyles?: CSSProperties;
    disableGlobalOrgChange?: boolean;
}

function OrgSelect(props: IOrgSelect) {
    const orgStore = useStore(AuthStore).orgStore;

    const local = RootStore().getStore(LocalStorage);

    useEffect(() => {
        orgStore.createViewModel(
            props.id,
            props.onSelect,
            props.onLoading,
            props.onError,
            props.onDisplay,
            true,
            props.disableGlobalOrgChange,
        );
    }, [
        props.id,
        orgStore,
        props.onSelect,
        props.onLoading,
        props.onError,
        props.onDisplay,
        props.disableGlobalOrgChange,
    ]);

    useEffect(() => {
        return autorun((r) => {
            const selectedOrg = orgStore.getViewModel(
                props.id,
            )?.selectedOrganization;
            const tempLocal = local.getLocalStore();
            tempLocal.setItem("selectedOrgID", selectedOrg?.id);
            orgStore.onActiveOrgIdChange(selectedOrg?.id);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.id]);

    useEffect(() => {
        if (props.orgId) {
            const org = orgStore
                .getViewModel(props.id)
                ?.organizations?.find((v) => v.id === props.orgId);
            orgStore.getViewModel(props.id)?.setSelectedOrganization(org);
        }
    }, [orgStore, props.orgId, props.id]);

    return (
        <Observer>
            {() => (
                <div
                    style={{
                        ...{
                            display:
                                orgStore.getViewModel(props.id)
                                    ?.canChangeOrganizations ?? false
                                    ? "block"
                                    : "none",
                        },
                        ...props.customRootStyles,
                    }}
                >
                    <AcxSelectSingle
                        inputLabel={props.label}
                        fullWidth={props.fullWidth}
                        isDisabled={props.disabled}
                        defaultValue={
                            props.defaultValue ||
                            orgStore.getViewModel(props.id)
                                ?.selectedOrganization
                        }
                        id={`org-selector-${props.id}`}
                        labelField="name"
                        valueField="id"
                        options={
                            orgStore.getViewModel(props.id)?.organizations ?? []
                        }
                        onChange={
                            orgStore.getViewModel(props.id)
                                ?.setSelectedOrganization
                        }
                        placeholder={
                            props.showLoading && orgStore.anyTaskLoading
                                ? "Loading.."
                                : "Select Organization"
                        }
                        menuPortalTarget={props.menuPortalTarget}
                        customStyle={props.customStyle}
                    />

                    {orgStore.getViewModel(props.id)?.errorMessage && (
                        <FormHelperText error>
                            {orgStore.getViewModel(props.id)?.errorMessage}
                        </FormHelperText>
                    )}
                </div>
            )}
        </Observer>
    );
}

interface WrapperProps {
    id: string;
    orgSelectStore: OrgSelectorComponentStore;
    inputLabel?: string;
    fullWidth?: boolean;
    disabled?: boolean;
    menuPortalTarget?: HTMLElement | null | undefined;
    styles?: CSSProperties;
    autoSelectUsersOrg?: boolean;
    disableGlobalOrgChange?: boolean;
}

export function OrgSelectWrapper(props: WrapperProps) {
    return (
        <OrgSelect
            id={props.id}
            fullWidth={props.fullWidth}
            disabled={props.disabled}
            onSelect={props.orgSelectStore.selectOrg}
            autoSelectUsersOrg={true}
            onError={props.orgSelectStore.setOrgError}
            onDisplay={props.orgSelectStore.setShowOrgSelect}
            onLoading={props.orgSelectStore.setIsLoading}
            label={props.inputLabel}
            menuPortalTarget={props.menuPortalTarget}
            disableGlobalOrgChange={props.disableGlobalOrgChange}
        />
    );
}

export default OrgSelect;
