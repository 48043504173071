import {
    Fade,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Paper,
    Theme,
    Typography,
    useTheme,
    Zoom,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import ReplayIcon from "@mui/icons-material/Replay";
import UndoIcon from "@mui/icons-material/Undo";
import clsx from "clsx";
import AcxButton from "components/UI/AcxButton";
import AcxExpansion from "components/UI/AcxExpansion";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import { Observer } from "mobx-react";
import SoundClip from "models/SoundClip";
import React, { useImperativeHandle, useRef } from "react";
import {
    ConnectDragPreview,
    ConnectDragSource,
    ConnectDropTarget,
    DragSource,
    DragSourceConnector,
    DragSourceMonitor,
    DropTarget,
    DropTargetConnector,
    DropTargetMonitor,
    XYCoord,
} from "react-dnd";
import hexToRGB from "utils/hexToRGB";
import {
    LoadRedactedClip,
    MontageSoundClipStore,
    UndoRedactedClip,
} from "./Stores/MontageSoundClipStore";
import ClipPlus from "./Views/ClipPlus";
import ToneIcon from "./Views/ToneIcon";

export const ItemTypes = { clip: "soundclip" };

const useStyles = makeStyles((theme: Theme) => ({
    icons: (props: IMontageClip) => ({
        margin: props.clip.transcriptionText ? "auto 0px" : "0px",
        color: hexToRGB(theme.palette.primary.main, 0.5),
    }),
    topIcon: {
        marginTop: "0px",
        cursor: "grab",
    },
    clipGrid: {
        marginTop: theme.spacing(2),
        marginBottom: "0px",
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    undoIcon: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
}));

interface IMontageClip {
    id: any;
    clip: SoundClip;
    renderAudioEditor: () => React.ReactNode;
    onDelete: (clipId: string) => void;
    index: number;
    store: MontageSoundClipStore;
    moveCard: (dragIndex: number, hoverIndex: number) => void;
    isDragging: boolean;
    connectDragSource: ConnectDragSource;
    connectDropTarget: ConnectDropTarget;
    connectDragPreview: ConnectDragPreview;
    hideControls?: boolean;
    showUndo?: boolean;
    showRedaction?: boolean;
}

const MontageClip = React.forwardRef<HTMLDivElement, IMontageClip>(
    (props: IMontageClip, ref) => {
        const theme = useTheme();
        const classes = useStyles(props);
        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(
            null,
        );
        const [show, setShow] = React.useState(true);
        const open = Boolean(anchorEl);
        const elementRef = useRef(null);
        const dragElementRef = useRef(null);

        props.connectDragSource(dragElementRef);
        props.connectDropTarget(elementRef);
        props.connectDragPreview(elementRef);

        const opacity = props.isDragging ? 0 : 1;

        useImperativeHandle<any, MontageClipInstance>(ref, () => ({
            getNode: () => elementRef.current,
        }));

        const onUndo = () => {
            props.store.popUndoStack(props.id, props.index);
        };

        const onDelete = () => {
            props.onDelete(props.clip.id);
        };

        const beginDelete = () => {
            setShow(false);
        };

        const addOptions = (event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const handleToneMenuClick = (type: "silent" | "tone") => {
            props.store.insertTone(props.id, type);
            handleClose();
        };
        const RedactClipSpacing = function (id) {
            //let returnText = "";
            let propsObject = props.store.toneForClip(id);
            if (propsObject) {
                return "2%";
            } else {
                return 0;
            }
        };

        const topMargin =
            props.store.toneForClip(props.id)?.length &&
            !props.clip.evaluationQbId
                ? theme.spacing(3)
                : theme.spacing(0.5);
        return (
            <Observer>
                {() => (
                    <Zoom
                        in={show}
                        timeout={theme.transitions.duration.standard}
                        onExited={onDelete}
                        style={{
                            transitionDelay: show
                                ? `${111 * props.index + 200}ms`
                                : "0ms",
                        }}
                    >
                        <div
                            ref={elementRef}
                            style={{ opacity: opacity, userSelect: "none" }}
                        >
                            <Grid
                                container
                                item
                                xs={12}
                                className={classes.clipGrid}
                            >
                                {!props.hideControls && (
                                    <Grid
                                        item
                                        xs={2}
                                        container
                                        direction="column"
                                        alignItems="flex-end"
                                    >
                                        <IconButton
                                            title="Drag and Drop"
                                            disableRipple={true}
                                            disableFocusRipple={true}
                                            ref={dragElementRef}
                                            className={clsx(
                                                classes.icons,
                                                classes.topIcon,
                                            )}
                                            size="large"
                                            sx={{
                                                margin: props.clip
                                                    .transcriptionText
                                                    ? "auto 0px"
                                                    : "0px",
                                                color: hexToRGB(
                                                    theme.palette.primary.main,
                                                    0.5,
                                                ),
                                            }}
                                        >
                                            <DragHandleIcon />
                                        </IconButton>
                                        <IconButton
                                            sx={{
                                                margin: props.clip
                                                    .transcriptionText
                                                    ? "auto 0px"
                                                    : "0px",
                                                color: hexToRGB(
                                                    theme.palette.primary.main,
                                                    0.5,
                                                ),
                                            }}
                                            title="Undo"
                                            onClick={onUndo}
                                            disabled={
                                                props.store.getAudioUndoStack(
                                                    props.id,
                                                ).length < 1
                                            }
                                            size="large"
                                        >
                                            <UndoIcon />
                                        </IconButton>

                                        <IconButton
                                            onClick={beginDelete}
                                            className={classes.icons}
                                            title="Delete"
                                            size="large"
                                            sx={{
                                                margin: props.clip
                                                    .transcriptionText
                                                    ? "auto 0px"
                                                    : "0px",
                                                color: hexToRGB(
                                                    theme.palette.primary.main,
                                                    0.5,
                                                ),
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>
                                )}

                                {props.showUndo && (
                                    <Grid
                                        item
                                        xs={1}
                                        container
                                        className={classes.undoIcon}
                                    >
                                        <IconButton
                                            className={classes.icons}
                                            title="Undo"
                                            onClick={onUndo}
                                            disabled={
                                                props.store.getAudioUndoStack(
                                                    props.id,
                                                ).length < 1
                                            }
                                            size="large"
                                        >
                                            <UndoIcon />
                                        </IconButton>
                                    </Grid>
                                )}

                                <Grid item xs={props.hideControls ? 10 : 8}>
                                    <Paper
                                        elevation={1}
                                        variant="outlined"
                                        style={{
                                            borderColor:
                                                theme.palette.primary.main,
                                            padding: theme.spacing(2),
                                        }}
                                    >
                                        {!props.store.getTaskLoading(
                                            LoadRedactedClip + props.clip.id,
                                        ) &&
                                        !props.store.getTaskLoading(
                                            UndoRedactedClip + props.clip.id,
                                        ) ? (
                                            <>
                                                {props.showRedaction && (
                                                    <Grid
                                                        item
                                                        key={"redact-options"}
                                                        style={{
                                                            paddingBottom:
                                                                RedactClipSpacing(
                                                                    props.id,
                                                                ),
                                                        }}
                                                    >
                                                        {props.store.clipsRedacted.find(
                                                            (value) => {
                                                                return (
                                                                    value ===
                                                                    props.clip
                                                                        .id
                                                                );
                                                            },
                                                        ) !== undefined && (
                                                            <AcxButton
                                                                key={
                                                                    "undo-redact"
                                                                }
                                                                buttonDisabled={
                                                                    !props.store
                                                                        .soundClipDgStore
                                                                        .SelectedRows
                                                                        .length ||
                                                                    props.store
                                                                        .anyClipsLoading
                                                                }
                                                                onClick={() => {
                                                                    props.store.setClipRedacted(
                                                                        props.id,
                                                                        false,
                                                                    );
                                                                }}
                                                                rootStyle={{
                                                                    position:
                                                                        "absolute",
                                                                    width: "1rem",
                                                                    height: "2rem",
                                                                    right: "7rem",
                                                                }}
                                                                leftRightSpacing={
                                                                    0
                                                                }
                                                                color="secondary"
                                                                tooltip="Undo Redaction"
                                                            >
                                                                <ReplayIcon />
                                                            </AcxButton>
                                                        )}
                                                        <AcxButton
                                                            key={"redact-clip"}
                                                            buttonDisabled={
                                                                !props.store
                                                                    .soundClipDgStore
                                                                    .SelectedRows
                                                                    .length ||
                                                                props.store
                                                                    .anyClipsLoading
                                                            }
                                                            onClick={() => {
                                                                props.store.setActiveSoundClip(
                                                                    props.clip,
                                                                    props.index,
                                                                );
                                                                props.store.setShowRedactDrawer(
                                                                    true,
                                                                );
                                                            }}
                                                            rootStyle={{
                                                                position:
                                                                    "absolute",
                                                                width: "6rem",
                                                                height: "2rem",
                                                                right: "8px",
                                                            }}
                                                            leftRightSpacing={0}
                                                            color="secondary"
                                                        >
                                                            Redact Clip
                                                        </AcxButton>
                                                    </Grid>
                                                )}

                                                <Typography>
                                                    {!!props.clip.evaluationQbId
                                                        ? `Evaluation Number: ${props.clip.evaluationQbId}`
                                                        : " "}
                                                </Typography>

                                                <Grid
                                                    container
                                                    style={{
                                                        marginBottom: "0.25rem",
                                                    }}
                                                    spacing={1}
                                                    xs={12}
                                                    item
                                                    justifyContent={
                                                        "space-between"
                                                    }
                                                >
                                                    <Grid item xs={6}>
                                                        {props.clip.segmentName}
                                                    </Grid>

                                                    {props.store.toneForClip(
                                                        props.id,
                                                    ) && (
                                                        <Grid
                                                            item
                                                            xs={6}
                                                            container
                                                            justifyContent={
                                                                "flex-end"
                                                            }
                                                            sx={{
                                                                marginTop:
                                                                    topMargin,
                                                            }}
                                                        >
                                                            {props.store
                                                                .toneForClip(
                                                                    props.id,
                                                                )
                                                                ?.map(
                                                                    (value) => (
                                                                        <ToneIcon
                                                                            key={
                                                                                value.id
                                                                            }
                                                                            clipId={
                                                                                props.id
                                                                            }
                                                                            id={
                                                                                value.id
                                                                            }
                                                                            type={
                                                                                value.type
                                                                            }
                                                                            store={
                                                                                props.store
                                                                            }
                                                                        />
                                                                    ),
                                                                )}
                                                        </Grid>
                                                    )}

                                                    <Grid item xs={12}>
                                                        {props.renderAudioEditor()}
                                                    </Grid>
                                                </Grid>

                                                {props.clip
                                                    .transcriptionText && (
                                                    <AcxExpansion
                                                        expanded={false}
                                                        header={"Transcription"}
                                                        body={
                                                            <Grid className="pendo-ignore">
                                                                <Typography
                                                                    className="pendo-ignore"
                                                                    component="span"
                                                                    style={{
                                                                        marginBottom:
                                                                            "0.25rem",
                                                                    }}
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: props
                                                                            .clip
                                                                            .transcriptionText,
                                                                    }}
                                                                />
                                                            </Grid>
                                                        }
                                                    />
                                                )}
                                            </>
                                        ) : (
                                            //loading circle for redacted clip
                                            <AcxLoadingIndicator
                                                alternate="PuffLoader"
                                                color="primary"
                                                size={135}
                                            />
                                        )}
                                    </Paper>
                                </Grid>
                                <Grid item xs={props.hideControls ? 1 : 2} />
                            </Grid>

                            {!props.hideControls && (
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    style={{
                                        marginLeft: theme.spacing(1),
                                        marginRight: theme.spacing(1),
                                    }}
                                >
                                    <Grid item xs={2}></Grid>

                                    <Grid
                                        item
                                        xs={8}
                                        container
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <ClipPlus />
                                    </Grid>

                                    <Grid item xs={1}>
                                        <IconButton
                                            onClick={addOptions}
                                            size="small"
                                            style={{
                                                color: theme.palette.secondary
                                                    .main,
                                            }}
                                            edge="start"
                                        >
                                            <AddCircleIcon
                                                style={{ fontSize: "20px" }}
                                            />
                                        </IconButton>
                                    </Grid>

                                    <Grid item xs={1}></Grid>
                                </Grid>
                            )}
                            <Menu
                                anchorEl={anchorEl}
                                keepMounted
                                open={open}
                                onClose={handleClose}
                                TransitionComponent={Fade}
                            >
                                <MenuItem
                                    onClick={() => handleToneMenuClick("tone")}
                                >
                                    Insert Tone
                                </MenuItem>
                                <MenuItem
                                    onClick={() =>
                                        handleToneMenuClick("silent")
                                    }
                                >
                                    Insert Silent Break
                                </MenuItem>
                            </Menu>
                        </div>
                    </Zoom>
                )}
            </Observer>
        );
    },
);

interface MontageClipInstance {
    getNode(): HTMLDivElement | null;
}

export default DropTarget(
    ItemTypes.clip,
    {
        drop: (props: IMontageClip, monitor, component) => {
            return {
                id: props.id,
                index: props.index,
            };
        },
        canDrop: (props, monitor) => {
            return true;
        },
        hover(
            props: IMontageClip,
            monitor: DropTargetMonitor,
            component: MontageClipInstance,
        ) {
            if (!component) {
                return null;
            }
            // node = HTML Div element from imperative API
            const node = component.getNode();
            if (!node) {
                return null;
            }

            const dragIndex = monitor.getItem().index;
            const hoverIndex = props.index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Determine rectangle on screen
            const hoverBoundingRect = node.getBoundingClientRect();

            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // Determine mouse position
            const clientOffset = monitor.getClientOffset();

            // Get pixels to the top
            const hoverClientY =
                (clientOffset as XYCoord).y - hoverBoundingRect.top;

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            // Time to actually perform the action
            props.moveCard(dragIndex, hoverIndex);

            monitor.getItem().index = hoverIndex;
        },
    },
    (connect: DropTargetConnector) => ({
        connectDropTarget: connect.dropTarget(),
    }),
)(
    DragSource(
        ItemTypes.clip,
        {
            beginDrag: (props: IMontageClip, monitor, component) => {
                return {
                    id: props.id,
                    index: props.index,
                };
            },
            canDrag: (props, monitor) => {
                return true;
            },
            isDragging: (props, monitor) => {
                return props.id === monitor.getItem().id;
            },
        },
        (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
            connectDragSource: connect.dragSource(),
            connectDragPreview: connect.dragPreview(),
            isDragging: monitor.isDragging(),
        }),
    )(MontageClip),
);
