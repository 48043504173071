import { Grid, Typography } from "@mui/material";
import theme from "Theme/AppTheme";
import AcxStatusCard from "components/UI/Cards/AcxStatusCard";
import { observer } from "mobx-react";
import React from "react";
import ModelDashboardStore, {
    LOAD_COST_METRICS,
} from "../Stores/ModelDashboardStore";

interface IModelDashboardStatsBarProps {
    store: ModelDashboardStore;
}

const statusCardHeight = "6rem";

const ModelDashboardStatsBar: React.FC<
    React.PropsWithChildren<
        React.PropsWithChildren<IModelDashboardStatsBarProps>
    >
> = observer(({ store }) => {
    return (
        <Grid container item xs={12} wrap="nowrap">
            <Grid item xs={6}>
                <Grid container item xs={12}>
                    <Grid item xs={4}>
                        <AcxStatusCard
                            isLoading={store.getTaskLoading(LOAD_COST_METRICS)}
                            containerStyles={{
                                margin: "0 0.25rem",
                                height: statusCardHeight,
                            }}
                            primaryContent={
                                <Typography variant="h2">
                                    {store.costMetrics
                                        ?.avgCallDurationEddyTrue !== undefined
                                        ? store.costMetrics?.avgCallDurationEddyTrue?.toFixed(
                                              2,
                                          ) + " mins"
                                        : "Unavailable"}
                                </Typography>
                            }
                            caption="Average Call Length Eddy True"
                            captionVariant="caption"
                            tooltipText="The average length of time in minutes of all calls returned from the filters applied where an Eddy was detected."
                            wrapTitle
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <AcxStatusCard
                            isLoading={store.getTaskLoading(LOAD_COST_METRICS)}
                            containerStyles={{
                                margin: "0 0.25rem",
                                height: statusCardHeight,
                            }}
                            primaryContent={
                                <Typography variant="h2">
                                    {store.costMetrics
                                        ?.avgCallDurationEddyFalse !== undefined
                                        ? store.costMetrics?.avgCallDurationEddyFalse?.toFixed(
                                              2,
                                          ) + " mins"
                                        : "Unavailable"}
                                </Typography>
                            }
                            caption="Average Call Length Eddy False"
                            captionVariant="caption"
                            tooltipText="The average length of time in minutes of all calls returned from the filters applied where an Eddy was not detected."
                            wrapTitle
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <AcxStatusCard
                            isLoading={store.getTaskLoading(LOAD_COST_METRICS)}
                            containerStyles={{
                                margin: "0 0.25rem",
                                height: statusCardHeight,
                            }}
                            primaryContent={
                                <Typography variant="h2">
                                    {
                                        // store.callMetrics?.result !== undefined
                                        //     ? store.callMetrics?.result?.toFixed(
                                        //           2,
                                        //       ) + " mins"
                                        //     : "Unavailable"
                                        store.costMetrics
                                            ?.avgCallDurationTotal !== undefined
                                            ? store.costMetrics?.avgCallDurationTotal?.toFixed(
                                                  2,
                                              ) + " mins"
                                            : "Unavailable"
                                    }
                                </Typography>
                            }
                            caption="Average Call Length Total"
                            captionVariant="caption"
                            tooltipText="The average length of time in minutes of all calls returned from the filters applied."
                            wrapTitle
                        />
                    </Grid>
                </Grid>
            </Grid>
            {/* <Grid item xs={2}>
                    <AcxStatusCard
                        isLoading={store.getTaskLoading(LOAD_SENTIMENT_METRICS)}
                        containerStyles={{
                            margin: "0 0.25rem",
                            height: statusCardHeight,
                        }}
                        primaryContent={
                            <Grid container alignItems="flex-end">
                                <Typography
                                    style={{
                                        color:
                                            (store.sentimentMetrics ?? 0) > 0.75
                                                ? theme.palette.green.main
                                                : (store.sentimentMetrics ??
                                                      0) > 0.5
                                                ? theme.palette.yellow.main
                                                : theme.palette.red.main,
                                        marginRight: "0.25rem",
                                    }}
                                    variant="h2"
                                >
                                    {(store.sentimentMetrics ?? 0) > 0.75
                                        ? "Positive"
                                        : (store.sentimentMetrics ?? 0) > 0.5
                                        ? "Neutral"
                                        : store.sentimentMetrics === undefined
                                        ? ""
                                        : "Negative"}
                                </Typography>

                                <Typography
                                    style={{
                                        color:
                                            store.sentimentMetrics !== undefined
                                                ? theme.palette.black.main
                                                : "",
                                    }}
                                    variant="h2"
                                >
                                    {store.sentimentMetrics !== undefined
                                        ? store.sentimentMetrics?.toFixed(2)
                                        : "Unavailable"}
                                </Typography>
                            </Grid>
                        }
                        caption="Average Call Sentiment"
                        captionVariant="caption"
                        tooltipText="The average sentiment of all calls returned from the filters applied, sentiment is scored between a -1 and +1."
                        wrapTitle
                    />
                </Grid> */}
            <Grid item xs={4}>
                <Grid container item xs={12}>
                    <Grid item xs={6}>
                        <AcxStatusCard
                            isLoading={store.getTaskLoading(LOAD_COST_METRICS)}
                            containerStyles={{
                                margin: "0 0.25rem",
                                height: statusCardHeight,
                            }}
                            primaryContent={
                                <Typography variant="h2">
                                    {store.costMetrics?.interactionsCount !==
                                    undefined
                                        ? store.costMetrics?.interactionsCount?.toLocaleString(
                                              "en-US",
                                          )
                                        : "Unavailable"}
                                </Typography>
                            }
                            caption="Number of Interactions Analyzed"
                            captionVariant="caption"
                            tooltipText="Count of audio and chat interactions analyzed. Only includes audio over 2 minutes."
                            wrapTitle
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <AcxStatusCard
                            isLoading={store.getTaskLoading(LOAD_COST_METRICS)}
                            containerStyles={{
                                margin: "0 0.25rem",
                                height: statusCardHeight,
                            }}
                            primaryContent={
                                <Typography variant="h2">
                                    {store.costMetrics?.eddyPercentage !==
                                    undefined
                                        ? store.costMetrics?.eddyPercentage?.toLocaleString(
                                              "en-US",
                                          ) + "%"
                                        : "Unavailable"}
                                </Typography>
                            }
                            caption="Percent of Calls with an Eddy"
                            captionVariant="caption"
                            wrapTitle
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={2}>
                <AcxStatusCard
                    isLoading={store.getTaskLoading(LOAD_COST_METRICS)}
                    containerStyles={{
                        margin: "0 0.25rem",
                        height: statusCardHeight,
                    }}
                    primaryContent={
                        <Typography
                            variant="h2"
                            style={{
                                color:
                                    store.costMetrics?.eddyCost === 0
                                        ? theme.palette.green.main
                                        : store.costMetrics?.eddyCost !==
                                          undefined
                                        ? theme.palette.red.main
                                        : "",
                            }}
                        >
                            {store.costMetrics?.eddyCost !== undefined
                                ? "$" +
                                  (
                                      store.costMetrics?.totalEddyHours *
                                      (store.getOrgEddyCost() ?? 23)
                                  ).toLocaleString("en-US", {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 0,
                                  })
                                : "Unavailable"}
                        </Typography>
                    }
                    caption="Estimated Eddy Talk Time Cost"
                    captionVariant="caption"
                    wrapTitle
                />
            </Grid>

            <Grid item xs={2}>
                <AcxStatusCard
                    isLoading={store.getTaskLoading(LOAD_COST_METRICS)}
                    containerStyles={{
                        margin: "0 0.25rem",
                        height: statusCardHeight,
                    }}
                    primaryContent={
                        <Typography variant="h2">
                            {store.costMetrics?.bottomOfFunnelCount !==
                            undefined
                                ? store.costMetrics?.bottomOfFunnelCount?.toLocaleString(
                                      "en-US",
                                  )
                                : "Unavailable"}
                        </Typography>
                    }
                    caption="Bottom of the funnel - Total"
                    captionVariant="caption"
                    wrapTitle
                />
            </Grid>
        </Grid>
    );
});

export default ModelDashboardStatsBar;
