import { ISourceFile } from "models/AudioMetadataModel";
import { BaseService } from "./BaseService";

export interface SourceFile {
    SourceFolder: string;
    FileName: string;
    Id: string;
    DirectoryId?: string;
}

export enum SamplingType {
    BottomOfTheFunnel = "BottomOfTheFunnel",
    MiddleOfTheFunnel = "MiddleOfTheFunnel",
}

export class AudioFilesService extends BaseService {
    protected urlBase: string = "api/AudioFiles";

    public async sampleAudioFileClip(amdId: string) {
        const path = "LoadFileFromSelectedAudio";
        const params = new URLSearchParams({ amdId });
        const response = await this.get(params, path);

        return response;
    }

    public async getAudioFileBuffer(metadataId: string): Promise<Uint8Array> {
        const arrayBuffer = await this.getArrayBuffer(
            new URLSearchParams(),
            `StreamAudio/${encodeURIComponent(metadataId)}`,
        );

        const bufferView = new Uint8Array(arrayBuffer);

        return bufferView;
    }

    public async getAudioFileBlob(metadataId: string): Promise<Blob> {
        const audioBlob = await this.getBlob(
            new URLSearchParams(),
            `StreamAudio/${encodeURIComponent(metadataId)}`,
        );

        return audioBlob;
    }

    public async getRedactedAudioFile(audioMetadataId: string) {
        const audioBuffer = await this.getArrayBuffer(
            new URLSearchParams(),
            `StreamRedactedAudio/${encodeURIComponent(audioMetadataId)}`,
        );

        return audioBuffer;
    }

    public async getAudioFileBlobForTranscription(
        transcriptionId: string,
    ): Promise<Blob> {
        const audioBlob = await this.getBlob(
            new URLSearchParams(),
            `StreamAudioForTranscription/${encodeURIComponent(
                transcriptionId,
            )}`,
        );

        return audioBlob;
    }

    public async selectAudioFilesSample(
        OrgId: string,
        orgStructMemberId: string | null | undefined,
        sourceFilesArray: Array<SourceFile>,
        lastBatch?: boolean,
        percentComplete?: number,
        sessionId?: string,
        appPath?: string,
        samplingType?: SamplingType,
        useBackgroundSampler?: boolean,
    ): Promise<string[]> {
        lastBatch = lastBatch ?? false;
        appPath = appPath ?? "";

        const urlSearchParams = new URLSearchParams({
            OrgId,
            lastBatch: JSON.stringify(lastBatch),
            appPath,
            samplingType: (
                samplingType || SamplingType.BottomOfTheFunnel
            ).toString(),
        });

        if (orgStructMemberId) {
            urlSearchParams.append("orgStructMemberId", orgStructMemberId);
        }
        
        if(useBackgroundSampler) {
            urlSearchParams.append("useBackgroundSampler", useBackgroundSampler.toString());
        }

        if (percentComplete !== undefined) {
            urlSearchParams.set(
                "percentComplete",
                percentComplete.toString(10),
            );
        }

        if (sessionId) {
            urlSearchParams.set("sessionId", sessionId);
        }

        const rv = await this.post(
            JSON.stringify(sourceFilesArray),
            urlSearchParams,
            "SelectAudioFilesSample",
        );

        return rv;
    }

    public async selectOnDemandSample(
        orgId: string,
        orgStructMemberId?: string,
        sourceFiles?: ISourceFile[],
        onDemandSampleType?: SamplingType,
        shouldOverrideExistingOrgStructureMemberId?: boolean,
    ) {
        const params = new URLSearchParams({
            orgId,
        });

        if (orgStructMemberId) {
            params.set("orgStructMemberId", orgStructMemberId);
        }

        if (onDemandSampleType) {
            params.set("onDemandSampleType", onDemandSampleType.toString());
        }

        if (shouldOverrideExistingOrgStructureMemberId) {
            params.set("shouldOverrideExistingOrgStructureMemberId", "true");
        }

        const response = await this.post(
            JSON.stringify(sourceFiles),
            params,
            "SelectOnDemandSample",
            { returnJson: true },
        );

        return response;
    }
}
