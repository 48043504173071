import { Grid, Paper, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import clsx from "clsx";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import AcxButton from "components/UI/AcxButton";
import AcxInputLabel from "components/UI/AcxInputLabel";
import AcxMainTextField from "components/UI/AcxMainTextField";
import { observer } from "mobx-react";
import React from "react";
import { useNavigate } from "react-router";
import { useForm } from "shared/hooks/useForm";
import { AcxResponse } from "shared/types/AcxResponse.type";
import { AuthStore } from "stores/AuthStore";
import useStyles from "Styles/Styles";
import { useStore } from "utils/useStore";
import { OrganizationAgentStore } from "../../../OrganizationAgents/stores/OrganizationAgentStore";

type PasswordFields = {
    newPassword: string;
    confirmPassword: string;
};

const defaultValues: PasswordFields = {
    newPassword: "",
    confirmPassword: "",
};

const validation = {
    newPassword: {
        required: {
            message: "Password is required.",
        },
        minLength: {
            value: 8,
            message: "Must be at least 8 characters",
        },
        pattern: [
            {
                value: /(?=.*[a-z])/,
                message: "Must have at least one lowercase character",
            },
            {
                value: /(?=.*[A-Z])/,
                message: "Must have at least one uppercase character",
            },
            {
                value: /(?=.*[0-9])/,
                message: "Must have at least one digit",
            },
            {
                value: /(?=.*\W)/,
                message: "Must have at least one special character",
            },
        ],
    },
    confirmPassword: {
        required: {
            message: "Please confirm the password.",
        },
        function: {
            value: (values: PasswordFields) => {
                const { newPassword, confirmPassword } = values;
                if (newPassword !== confirmPassword) {
                    return "Passwords must match.";
                }
                return false;
            },
        },
    },
};

const styles = (theme: Theme) => {
    return createStyles({
        formContainer: {
            marginTop: theme.spacing(2),
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
            width: "100%",
            overflowY: "auto",
        },
        error: {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.error.main,
            fontSize: "12px",
            lineHeight: "16px",
        },
    });
};

type Props = {
    isLoading: boolean;
    changePassword: (newPassword: string) => Promise<AcxResponse | undefined>;
    orgId?: string;
};

const ChangePasswordForm: React.FC<
    React.PropsWithChildren<React.PropsWithChildren<Props>>
> = observer(({ isLoading, changePassword, orgId }) => {
    const authStore = useStore(AuthStore);
    const classes = useStyles(styles);
    const navigate = useNavigate();
    const agentStore = useStore(OrganizationAgentStore);
    const {
        value: passwordValue,
        registerSubmit,
        errors,
        onChange,
        isSubmitting,
    } = useForm<PasswordFields>(defaultValues, validation);
    const messageStore = useStore(MessageStore);

    const registerOnChange =
        (prop: keyof PasswordFields) =>
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onChange(prop, e.target.value);
        };

    const submit = (values: PasswordFields) => {
        return changePassword(values.newPassword);
    };

    const onSuccess = () => {
        messageStore.logMessage(`Successfully changed password`, "success");
        if (agentStore.creatAgentUserDetails) {
            agentStore.creatAgentUserDetails = null;
            navigate(`/admin/organizations/${orgId}/agents`);
        }
    };

    return (
        <Paper elevation={1} className={classes.formContainer}>
            <Typography variant="h5">Change Password</Typography>
            <Typography variant="caption">
                Change password for this user.
            </Typography>
            <Grid container spacing={1} style={{ paddingTop: "12px" }}>
                <Grid container item spacing={1} wrap="wrap">
                    <Grid item xs={6}>
                        <AcxMainTextField
                            showAllErrors={true}
                            skeleton={isLoading}
                            onChange={registerOnChange("newPassword")}
                            id="newPassword"
                            labelText="Password"
                            value={passwordValue.newPassword}
                            error={!!errors?.fieldErrors?.newPassword}
                            helperText={errors?.fieldErrors?.newPassword}
                            required={true}
                            type="password"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <AcxMainTextField
                            showAllErrors={true}
                            skeleton={isLoading}
                            onChange={registerOnChange("confirmPassword")}
                            id="confirmPassword"
                            labelText="Confirm Password"
                            value={passwordValue.confirmPassword}
                            error={!!errors?.fieldErrors?.confirmPassword}
                            helperText={errors?.fieldErrors?.confirmPassword}
                            required={true}
                            type="password"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {errors?.form?.map((err, i) => (
                            <AcxInputLabel
                                key={i}
                                className={clsx(classes.error)}
                            >
                                {err}
                            </AcxInputLabel>
                        ))}
                    </Grid>
                    <Grid item>
                        <AcxButton
                            onClick={registerSubmit(submit, {
                                onSuccess,
                            })}
                            color="secondary"
                            leftRightSpacing={0}
                            loading={isLoading || isSubmitting}
                            buttonDisabled={
                                isSubmitting ||
                                !authStore.canUserEdit("Reset Passwords")
                            }
                        >
                            Change Password
                        </AcxButton>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
});

export default ChangePasswordForm;
