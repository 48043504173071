import { observer } from "mobx-react";
import { IApplicationFilterComponent } from "../Filters/IApplicationFilterComponent";
import React from "react";
import AcxToggleButton from "components/UI/AcxToggleButton";

export const EddyEffectFilter = observer(function (
    props: IApplicationFilterComponent,
) {
    return (
        <AcxToggleButton
            id="filter-eddy-toggle-button"
            exclusive
            items={[
                { element: "Is Present", value: true },
                { element: "Is Not Present", value: false },
            ]}
            onChange={(value?: boolean) => {
                props.store.setEddyEffectStatus(value);
            }}
            defaultValue={props.store.eddyEffectStatus}
            justifyContent="center"
            groupFlexGrow={1}
        />
    );
});
