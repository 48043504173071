import { styled, useTheme } from "@mui/styles";
import ContentLoading from "animations/ContentLoading";
import React, { useState } from "react";
import Dropzone from "react-dropzone";

type Prop = { uploadHandler: (file: File) => Promise<void>; onError: (errorTitle: string, errorMessage: string) => void };

const FILE_TYPE_ERROR = "file-invalid-type";
const FILE_TYPE_ERROR_TITLE = "Invalid file format"
const FILE_TYPE_ERROR_MESSAGE = "Cannot upload this file format. Supported file formats are png, or jpeg."
const FILE_TOO_LARGE_ERROR = "file-too-large";
const FILE_TOO_LARGE_ERROR_TITLE = "File size limit"
const FILE_TOO_LARGE_ERROR_MESSAGE = "The file is too large to be uploaded. Files larger than 10MB are not currently supported.";
const GENERIC_ERROR_TITLE = "Issue uploading file"
const GENERIC_ERROR_MESSAGE = "There was a problem uploading the file. Please try again."

function AcxImageFileDrop(props: Prop) {
    const theme = useTheme();
    const [isUploading, setIsUploading] = useState(false);

    const Container = styled("div")({
        backgroundColor: theme.palette.gray[50],
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px", // Add padding for spacing
        textAlign: "center", // Center text horizontally
        wordWrap: "break-word", // Break long words
        whiteSpace: "normal", // Allow text to wrap onto the next line
        overflow: "auto",
        borderRadius: "4px",
        border: `2px dashed ${theme.palette.gray[300]}`,
    });

    const PrimaryText = styled("p")({
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: "600",
        lineHeight: "16px",
        color: theme.palette.gray[400],
        marginBottom: "4px",
    });

    const SecondaryText = styled("p")({
        color: theme.palette.gray[400],
        textAlign: "center",
        fontFamily: "Inter",
        fontSize: "10px",
        fontWeight: "400",
        lineHeight: "16px",
        margin: 0,
    });

    async function uploadImage(files: File[]) {
        setIsUploading(true);
        await props.uploadHandler(files[0]);
        // setIsUploading(false);
    }

    return (
        <Dropzone
            accept={{ "image/jpeg": [".jpg", ".jpeg"], "image/png": [".png"] }}
            maxFiles={1}
            maxSize={10485760}
            onDrop={(acceptedFiles, rejectedFiles) => {
                if (rejectedFiles.length > 0 && rejectedFiles[0].errors.length > 0) {
                    const errorType = rejectedFiles[0].errors[0].code;
                    if (errorType === FILE_TYPE_ERROR) {
                        props.onError(FILE_TYPE_ERROR_TITLE, FILE_TYPE_ERROR_MESSAGE);
                    } else if (errorType === FILE_TOO_LARGE_ERROR) {
                        props.onError(FILE_TOO_LARGE_ERROR_TITLE, FILE_TOO_LARGE_ERROR_MESSAGE);
                    } else {
                        props.onError(GENERIC_ERROR_TITLE, GENERIC_ERROR_MESSAGE)
                    }
                    return;
                }

                uploadImage(acceptedFiles);
            }}
        >
            {({ getRootProps, getInputProps }) => (
                <section>
                    {isUploading ? (
                        <Container>
                            <ContentLoading />
                        </Container>
                    ) : (
                        <Container
                            {...getRootProps()}
                            style={{ cursor: "pointer" }}
                        >
                            <input {...getInputProps()} />
                            <PrimaryText>
                                Drag and drop your image here or browse files.
                            </PrimaryText>
                            <SecondaryText>
                                Jpeg or png files up to 10MB
                            </SecondaryText>
                            <SecondaryText>
                                Suggested dimensions: 300 x 300px
                            </SecondaryText>
                        </Container>
                    )}
                </section>
            )}
        </Dropzone>
    );
}

export default AcxImageFileDrop;
