import {
    IExtractedCSV,
    IExtractedRow,
    ICSVFormat,
    extractCSVFiles,
} from "./CSVExtractor";

import Immutable from "immutable";

export type CallDirection = "inbound" | "outbound" | "";

export interface IMetaData {
    id: string | null;
    timestamp: string | null;
    timezone: string | null;
    email: string | null;
    phone: string | null;
    name: string | null;
    agentName: string | null;
    callDirection: CallDirection | null;
}

export interface IUploadRecord {
    fileName: string;
    contents: ArrayBuffer;
    metaData: IMetaData;
    ignoreMetadata: boolean;
}

export interface IMatchedRow extends IExtractedRow {
    matchedFile?: IReadFileResult;
}

export interface IMatchedCSV extends IExtractedCSV {
    rows?: Immutable.List<IMatchedRow>;
}

export type IMatchResult = {
    matchedCSV: IMatchedCSV;
    unmatchedAudioFiles: Immutable.Set<string>;
};

export type IDataMatchResults = {
    matchedMetaDataFiles: Immutable.List<IMatchedCSV>;
    unmatchedAudioFiles: Immutable.List<IReadFileResult>;
    fileSystemErrors: Immutable.List<string>;
};

export interface IReadFileResult {
    status: string;
    fileName: string;
    contents?: String | ArrayBuffer | null;
    error?: any;
}

export function readBinaryFileAsync(file: File): Promise<ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();

        reader.onload = () => {
            resolve(
                reader.result === null ? null : (reader.result as ArrayBuffer),
            );
        };

        reader.onerror = reject;

        reader.readAsArrayBuffer(file);
    });
}

export function readTextFileAsync(file: File): Promise<String | null> {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();

        reader.onload = () => {
            resolve(reader.result === null ? null : (reader.result as String));
        };

        reader.onerror = reject;

        reader.readAsText(file);
    });
}

function _readFilesAsync(
    files: File[],
    readerFunction: (file: File) => Promise<ArrayBuffer | String | null>,
): Promise<IReadFileResult>[] {
    const promises = files.map(async (file) => {
        try {
            const contents = await readerFunction(file);
            return {
                status: "ok",
                fileName: file.name,
                contents,
            };
        } catch (err) {
            return {
                status: "error",
                fileName: file.name,
                error: err,
            };
        }
    });
    return promises;
}

export function readTextFilesAsync(files: File[]): Promise<IReadFileResult>[] {
    return _readFilesAsync(files, readTextFileAsync);
}

export function readBinaryFilesAsync(
    files: File[],
): Promise<IReadFileResult>[] {
    return _readFilesAsync(files, readBinaryFileAsync);
}

export function matchMetadata(
    audioFileReadResults: IReadFileResult[],
    extractedCSV: IExtractedCSV,
): IMatchResult {
    function stripExtension(fileName: string) {
        return fileName.replace(/\.[^/.]+$/, "");
    }

    type AudioFile = {
        originalName: string;
        strippedName: string;
        hasMatch: boolean;
        readResult: IReadFileResult;
    };

    let matchedCSV: IMatchedCSV = {
        ...extractedCSV,
    };

    if (matchedCSV.rows && matchedCSV.rows.size > 0) {
        // If there are rows to match, match them against the audio files
        let audioFilesByStrippedName = Immutable.Map<string, AudioFile>();

        for (const readResult of audioFileReadResults) {
            const originalName = readResult.fileName;
            const strippedName = stripExtension(originalName);
            const file = {
                originalName,
                strippedName,
                readResult,
                hasMatch: false,
            };
            audioFilesByStrippedName = audioFilesByStrippedName.set(
                strippedName,
                file,
            );
        }

        for (const row of matchedCSV.rows) {
            if (!row.fileIdentifier) continue;

            let foundFileName: string = "";
            if (audioFilesByStrippedName.has(row.fileIdentifier)) {
                foundFileName = row.fileIdentifier;
            } else if (
                audioFilesByStrippedName.has(stripExtension(row.fileIdentifier))
            ) {
                foundFileName = stripExtension(row.fileIdentifier);
            }

            if (foundFileName !== "") {
                const audioFile = audioFilesByStrippedName.get(foundFileName)!; //cannot be null because of the "has" checks above
                row.matchedFile = audioFile.readResult;
                audioFile!.hasMatch = true;
                audioFilesByStrippedName.set(foundFileName, audioFile);
            }
        }

        return {
            matchedCSV,
            unmatchedAudioFiles: Immutable.Set(
                audioFilesByStrippedName
                    .filter((af) => !af.hasMatch)
                    .map((af) => af.originalName)
                    .values(),
            ),
        };
    } else {
        return {
            matchedCSV,
            unmatchedAudioFiles: Immutable.Set(
                audioFileReadResults.map((x) => x.fileName),
            ),
        };
    }
}

/**
 * Reads all files into memory and matches extracted metadata with corresponding files
 *
 */
export async function readFilesAndMatchMetadata(
    audioFiles: File[],
    metaFiles: File[],
    csvFormat: ICSVFormat,
): Promise<IDataMatchResults> {
    const audioPromises = readBinaryFilesAsync(audioFiles);
    const metaPromises = readTextFilesAsync(metaFiles);

    let audioObjects = await Promise.all(audioPromises);
    let metaObjects = await Promise.all(metaPromises);

    let unmatchedAudioFileNames = Immutable.Set(
        audioObjects.map((o) => o.fileName),
    );
    let matchedMetaDataFiles = Immutable.List<IMatchedCSV>();

    const fileSystemErrors = Immutable.List(
        audioObjects
            .concat(metaObjects)
            .filter((obj) => obj.status === "error")
            .map((obj) => obj.fileName),
    );

    audioObjects = audioObjects.filter((obj) => obj.status === "ok");
    metaObjects = metaObjects.filter((obj) => obj.status === "ok");

    // TODO: this is only valid for csv formats
    const extractedFiles = extractCSVFiles(metaObjects, csvFormat);

    for (const extractedFile of extractedFiles) {
        const result = matchMetadata(audioObjects, extractedFile);
        matchedMetaDataFiles = matchedMetaDataFiles.push(result.matchedCSV);
        unmatchedAudioFileNames = unmatchedAudioFileNames.intersect(
            result.unmatchedAudioFiles,
        );
    }

    const unmatchedAudioFiles = Immutable.List(
        audioObjects.filter((o) => unmatchedAudioFileNames.has(o.fileName)),
    );

    return { matchedMetaDataFiles, unmatchedAudioFiles, fileSystemErrors };
}
