import { Box, Grid } from "@mui/material";
import SuccessDialog from "components/UI/AcxSuccessDialog";
import { observer } from "mobx-react";
import React, { useRef } from "react";
import theme from "Theme/AppTheme";
import { useStore } from "utils/useStore";
import AcxSelectSingle from "../../../../UI/Select/BaseSelectComponents/AcxSelectSingle";
import ConversationsStore, {
    SortOption,
} from "../../../Stores/ConversationsStore";
import EvaluateConversationDialog from "../EvaluateConversationDialog";
import UnfocusedConversation from "./UnfocusedConversation";
import { ApplicationFiltersStore } from "stores/ApplicationFilters/ApplicationFiltersStore";

const UnfocusedConversationList: React.FC = observer(() => {
    const store = useStore(ConversationsStore);
    const applicationFiltersStore = useStore(ApplicationFiltersStore);

    const containerRef = useRef(null);

    const sortOptions: SortOption[] = [
        {
            label: (
                <div>
                    <b>Date:</b> Newest to Oldest
                </div>
            ),
            value: "New to Old",
            sortField:
                applicationFiltersStore.dateReferenceOption === "ArrivalDate"
                    ? "ArrivedOn"
                    : "Timestamp",
            sortDirection: "desc",
        },
        {
            label: (
                <div>
                    <b>Date:</b> Oldest to Newest
                </div>
            ),
            value: "Old to New",
            sortField:
                applicationFiltersStore.dateReferenceOption === "ArrivalDate"
                    ? "ArrivedOn"
                    : "Timestamp",
            sortDirection: "asc",
        },
    ];

    const handleSortSelection = (sortOption: SortOption) => {
        store.setSelectedSortOption(sortOption);
        store.setPageNumberAndGetConversations(1);
    };

    const sortSelector = () => {
        return (
            <>
                <AcxSelectSingle
                    id="conversations-sort"
                    options={sortOptions ?? []}
                    valueField={"value"}
                    labelField={"label"}
                    defaultValue={store.selectedSortOption ?? undefined}
                    menuPortalTarget={containerRef.current}
                    customStyle={{
                        container: (provided, state) => ({
                            ...provided,
                            minWidth: "130px",
                            width: !store.selectedSortOption
                                ? "130px"
                                : "230px",
                            padding: "8px 16px",
                            borderRadius: "4px",
                            border:
                                "1px solid" +
                                theme.palette.lightgrayBorder.main,
                        }),
                        option: (provided, state) => ({
                            ...provided,
                            color: state.isSelected
                                ? theme.palette.primary[500]
                                : theme.palette.text.primary,
                            backgroundColor: state.isSelected
                                ? theme.palette.secondary[50]
                                : "#FFF",
                            width: "100%",
                            textWrap: "nowrap",
                            "&:hover": {
                                backgroundColor: theme.palette.secondary[50],
                                color:
                                    theme.palette.neutral[600] + "!important",
                            },
                        }),
                        menu: (provided, state) => ({
                            ...provided,
                            width: store.selectedSortOption ? "227px" : "185px",
                            right: 0,
                            zIndex: 10,
                            padding: "8px",
                        }),
                    }}
                    menuPlacement="bottom"
                    onChange={handleSortSelection}
                    placeholder={" Sort by"}
                />
            </>
        );
    };

    return (
        <>
            <Grid
                container
                alignContent="center"
                justifyContent="space-between"
                style={{ paddingBottom: theme.spacing(2) }}
            >
                <Box
                    fontWeight="bold"
                    fontSize="24px"
                    alignItems={"center"}
                    display={"flex"}
                >
                    {store.isInitialLoad ? "Latest Conversations" : "Results"} (
                    {store.estimatedConversationCount?.toLocaleString()})
                </Box>
                <Grid
                    container
                    justifyContent="flex-end"
                    item
                    ref={containerRef}
                    style={{
                        width: "230px",
                    }}
                >
                    {sortSelector()}
                </Grid>
            </Grid>

            {store?.conversationsList?.map((conversation) => {
                return (
                    <UnfocusedConversation
                        conversation={conversation}
                        key={conversation.conversationId}
                    />
                );
            })}

            {/* Evaluate Conversation Confirmation Dialog */}
            {store.evaluateConversationDialogOpen && (
                <EvaluateConversationDialog
                    onConfirmCallback={() => {
                        store.toggleEvaluationSuccessDialogOpen();
                    }}
                />
            )}

            {/* Success Dialog  */}
            {store.evaluateSuccessDialogOpen && (
                <SuccessDialog
                    isOpen={store.evaluateSuccessDialogOpen}
                    onClose={store.toggleEvaluationSuccessDialogOpen}
                    confirmButtonText="Back to Conversations"
                    onConfirm={store.toggleEvaluationSuccessDialogOpen}
                    title="Conversations Sampling"
                    subTitle="You’ll be notified when sampling is complete. Reporting data for these evaluations will be available tomorrow."
                />
            )}
        </>
    );
});

export default UnfocusedConversationList;
