import { ButtonBase, Grid, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import BarChartIcon from "@mui/icons-material/BarChart";
import { observer } from "mobx-react";
import React from "react";
import { useStore } from "utils/useStore";
import { useResizeObserver } from "../../utils/useResizeObserver";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import clsx from "clsx";
import ReportCardFilters from "./Reports/ReportCards/ReportCardFilters";
import AcxMenu, { AcxMenuItemProps } from "components/UI/Menu/AcxMenu";
import { autorun } from "mobx";
import { useLocation, useNavigate } from "react-router-dom";
import { buildReportVizType } from "./Reports/Common/ReportVisualizationBuilder";
import ReportHeader from "./Reports/ReportHeader";
import SignalsStore from "./Store/SignalsStore";

type StyleProps = {
    height?: number;
    width?: number;
    hideTitle?: boolean;
    top?: number;
    right?: number;
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: "100%",
        height: "100%",
    },
    scrollable: {
        height: "calc(100% - 88px)",
    },
    chartBody: (props: StyleProps) => ({
        height: `${props.height ?? 0}px`,
        width: "100%",
        paddingTop: "8px",
    }),
    emptyChartBody: (props: StyleProps) => ({
        height: `500px`,
        width: "100%",
        paddingTop: theme.spacing(3),
    }),
    moreVertButton: (props: StyleProps) => ({
        width: "48px",
        height: "32px",
        top: props.top !== undefined ? props.top : -9,
        right: props.right !== undefined ? props.right : -9,
    }),
    cardBody: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.white.main,
        boxShadow:
            "0 0 2px 1px rgba(0, 0, 0, 0.03), 0 6px 10px 2px rgba(0, 0, 0, 0.08)",
        borderRadius: 5,
    },
    smallButton: {
        width: "32px",
        height: "32px",
    },
    bodyContainer: {
        padding: theme.spacing(2),
    },
    bodyFullHeight: {
        height: "100%",
    },
    bodyHeight: {
        height: "calc(100% - 95px)",
    },
    smallOutlineIconButton: {
        color: theme.palette.secondary.main,
        borderStyle: "solid",
        borderColor: theme.palette.secondary.main,
        borderWidth: "1px",
    },
    assessmentIcon: {
        height: "10rem !important",
        width: "10rem !important",
        fill: "rgba(0, 0, 0, 0.38)",
    },
}));

const SignalsCustomReportBody = observer((props: StyleProps) => {
    const classes = useStyles({});
    const reportsStore = useStore(SignalsStore).reportsStore;

    const navigate = useNavigate();

    const location = useLocation();

    React.useEffect(() => {
        const getOrgIdLocation = () => {
            const pathArray = location.pathname.split("/");
            const groupIndex =
                pathArray.findIndex((value) => value === "reports") + 1;
            return pathArray[groupIndex];
        };
        const getReportId = () => {
            const pathArray = location.pathname.split("/");
            const groupIndex =
                pathArray.findIndex((value) => value === "reports") + 2;
            return pathArray[groupIndex];
        };

        const orgId = getOrgIdLocation();
        const reportId = getReportId();
        const curRpt = reportsStore.reports.find(
            (r) => r?.organizationId === orgId && r.id === reportId,
        );

        if (curRpt === undefined) {
            reportsStore.currentReport = null;
            reportsStore.clearDrillDownState();
            reportsStore.prepareReportSwitch();
        }

        if (
            curRpt !== undefined &&
            curRpt.id !== reportsStore.currentReport?.id
        ) {
            reportsStore.clearDrillDownState();
            reportsStore.prepareReportSwitch();

            reportsStore.currentReport = curRpt;
        }

        reportsStore.currentGroup = orgId;
    }, [
        location.pathname,
        location,
        reportsStore.reports.length,
        reportsStore.navStore.currentNavItem,
        reportsStore.currentReport,
        reportsStore,
        reportsStore.orgStore.selectedOrganization,
    ]);

    const [menuItems, setMenuItems] = React.useState<
        AcxMenuItemProps[] | undefined
    >();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { elementHeight, elementWidth, theRef } =
        useResizeObserver<HTMLDivElement>({
            debounceWait: 33,
        });

    const {
        elementHeight: controlsHeight,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        elementWidth: controlsWidth,
        theRef: controlsRef,
    } = useResizeObserver<HTMLDivElement>({
        debounceWait: 33,
    });

    const onCreateNewReport = () => {
        if (!reportsStore.canEditReports) {
            return;
        }

        reportsStore.initializeReportEditorForNew();

        if (reportsStore.orgSelectorStore.orgId) {
            navigate(
                `/focus/signals/reporteditor/${reportsStore.orgSelectorStore.orgId}/`,
            );
        }
    };

    function handleMoreMenuClick(event: React.MouseEvent<HTMLButtonElement>) {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
        event.preventDefault();
    }

    function handleMoreMenuClose() {
        setAnchorEl(null);
    }

    React.useEffect(() => {
        const disposer = autorun((r) => {
            const acxMenuItems = reportsStore?.menuItemsBuilder?.(() => {
                setAnchorEl(null);
            });

            setMenuItems(acxMenuItems);
        });

        return () => disposer();
    }, [reportsStore, reportsStore?.menuItemsBuilder]);

    return reportsStore.currentReport ? (
        <Grid item xs className={classes.root}>
            {/* {!reportsStore.drawerClosed && ( */}
            <ReportHeader
                reportName={reportsStore.currentReport?.name}
                editedAt={reportsStore.currentReport?.modifiedAt ?? ""}
                editedBy={reportsStore.currentReport?.modifiedBy ?? ""}
                editedOn={reportsStore.currentReport?.modifiedOn ?? ""}
            />
            {/* )} */}
            <Grid
                item
                xs
                className={clsx(classes.bodyContainer, classes.bodyHeight)}
                // style={{ height: "calc(100% - 95px)" }}
            >
                <Grid
                    container
                    ref={theRef}
                    item
                    xs
                    className={classes.cardBody}
                    style={{ height: "100%" }}
                >
                    <Grid
                        ref={controlsRef}
                        item
                        xs={12}
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems={"flex-start"}
                        wrap={"nowrap"}
                    >
                        {(!props.hideTitle ||
                            Boolean(
                                reportsStore.reportDataViewModel
                                    .drillDownForCurrentReport,
                            )) && (
                            <Grid item xs={1} container alignItems={"baseline"}>
                                {!props.hideTitle && (
                                    <Grid item>
                                        <Typography variant="h5">
                                            {reportsStore.currentReport?.name}
                                        </Typography>
                                    </Grid>
                                )}

                                {Boolean(
                                    reportsStore.reportDataViewModel
                                        .drillDownForCurrentReport,
                                ) && (
                                    <Grid item>
                                        <ButtonBase
                                            onClick={
                                                reportsStore.clearDrillDownState
                                            }
                                            className={clsx(
                                                classes.smallButton,
                                                classes.smallOutlineIconButton,
                                            )}
                                        >
                                            <KeyboardReturnIcon />
                                        </ButtonBase>
                                    </Grid>
                                )}
                            </Grid>
                        )}
                        {(!props.hideTitle ||
                            Boolean(
                                reportsStore.reportDataViewModel
                                    .drillDownForCurrentReport,
                            )) && (
                            <Grid
                                item
                                xs={11}
                                container
                                style={{ marginLeft: "-5%" }}
                            >
                                <ReportCardFilters />
                            </Grid>
                        )}
                        {props.hideTitle &&
                            !Boolean(
                                reportsStore.reportDataViewModel
                                    .drillDownForCurrentReport,
                            ) && <ReportCardFilters />}

                        {menuItems &&
                            !reportsStore.reportDataViewModel.isDrillDown && (
                                <Grid item style={{ position: "relative" }}>
                                    <ButtonBase
                                        onClick={handleMoreMenuClick}
                                        className={clsx(classes.moreVertButton)}
                                        disabled={
                                            reportsStore.reportDataViewModel
                                                .anyTaskLoading
                                        }
                                    >
                                        <MoreVertIcon />
                                    </ButtonBase>
                                </Grid>
                            )}

                        {menuItems &&
                            !reportsStore.reportDataViewModel.isDrillDown && (
                                <AcxMenu
                                    menuItems={menuItems}
                                    anchorElement={anchorEl}
                                    onMenuClose={handleMoreMenuClose}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                    }}
                                />
                            )}
                    </Grid>

                    <Grid
                        container
                        item
                        xs={12}
                        style={{
                            height: "calc(100% - 48px)",
                        }}
                    >
                        <div
                            style={{
                                height: `${
                                    (elementHeight ?? 0) -
                                    ((controlsHeight ?? 0) + 15)
                                }px`,
                                width: `${elementWidth ?? 0}px`,
                            }}
                        >
                            {buildReportVizType(
                                reportsStore.reportDataViewModel.currentReport
                                    ?.vizType,
                                reportsStore.reportDataViewModel.emptyDataSet,
                                reportsStore.reportDataViewModel
                                    .googleFormattedData,
                                reportsStore.reportDataViewModel.isDrillDown
                                    ? reportsStore.reportDataViewModel
                                          .drillReportData
                                    : reportsStore.reportDataViewModel
                                          .tableReportData,
                                reportsStore.reportDataViewModel.currentReport
                                    ?.xAxisLabel,
                                reportsStore.reportDataViewModel.currentReport
                                    ?.yAxisLabel,
                                reportsStore.reportDataViewModel.isDrillDown,
                                reportsStore.reportDataViewModel.onVizClick,
                                elementHeight,
                                controlsHeight,
                                false,
                                { ...reportsStore.currentReport?.vizOptions },
                                reportsStore.captureChartRef,
                            )}
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    ) : (
        <Grid
            container
            item
            xs={12}
            justifyContent="center"
            alignContent="center"
            onClick={onCreateNewReport}
            style={{ cursor: "pointer" }}
        >
            <Grid
                container
                item
                justifyContent="center"
                direction="column"
                alignItems="center"
            >
                <Typography variant="subtitle1" color="primary">
                    {!reportsStore.canEditReports
                        ? "No Report Selected"
                        : "Create New Report"}
                </Typography>

                <BarChartIcon className={classes.assessmentIcon} />
            </Grid>
        </Grid>
    );
});

export default SignalsCustomReportBody;
