import BaseEntity from "models/BaseEntity";
import { ApplicationUser } from "models/Permission/ApplicationUser";
import Question from "models/Question";
import { Tag } from "models/Tag";

export enum WorkflowTemplateDeliveryCadence {
    "Immediately" = 0,
    "Daily" = 1,
    "Weekly" = 2,
    "Monthly" = 3,
}

//This is only used for Hierarchy Types
export enum ContactType {
    "Escalation" = 0,
    "Administrative" = 1,
    "Manager" = 2,
}

export enum CustomContactType {
    "AgentManager" = 0,
}

export enum WorkflowConditionType {
    "APT" = 0,
    "ScoreEscalation" = 1,
    "EvaluationDispute" = 2,
}

export enum DueDateType {
    "BusinessDays" = 0,
    "CalendarDays" = 1,
}

export interface WorkflowTemplate extends BaseEntity {
    templateName: string;
    subject: string;
    body: string;
    bodyText: string;
}

export interface WorkflowDefinition {
    name?: string;
    workflowUri?: string;
    id?: string;
}

export interface UserContact {
    id: string;
    organizationId: string;
    userName: string;
    domain: string;
}

export interface WorkflowRecipient {
    userId?: string;
    organizationId: string;
    domain?: string;
    contactType?: ContactType;
    customContactType?: CustomContactType;
    user?: ApplicationUser;
}

export interface WorkflowMessagingMetadata {
    workflowRecipients?: WorkflowRecipient[];
    licensedModuleId?: string;
    workflowEmailTemplateId?: string;
    emailCadences?: WorkflowTemplateDeliveryCadence[];
    level: number;
    name?: string;
    overdueReminders?: boolean;
    dueDateDays?: number;
    dueDateType?: string;
}

export default interface WorkflowCondition {
    implementationType?: WorkflowConditionType;
    organizationId: string;
    questionId?: string;
    question?: Question;
    tagId?: string;
    tag?: Tag;
    name?: string;
    workflowIdentifier?: string;
    workflowMessagingMetadata: WorkflowMessagingMetadata[];
    createdBy?: string;
    modifiedBy?: string;
    createdOn?: string;
    modifiedOn?: string;
    isActive?: boolean;
    highPriority: boolean;
    id?: string;
    workflowDefinitionId?: string;
    workflowDefinition?: WorkflowDefinition;
    licensedModuleId?: string;
    scoreThreshold?: number;
}
