import UserRole from "models/Permission/UserRole";
import BaseService from "services/BaseService";
import { AcxError, AcxResponse } from "shared/types/AcxResponse.type";
import { LoginDate } from "../../types/LoginDate.type";
// import { Role } from "../../types/Role.type";
import { User } from "../../types/User.type";
import { IHierarchyAssignmentTier } from "./OrganizationUserDetails/Components/Types";
import { ApplicationUser } from "models/Permission/ApplicationUser";

type HierarchyItem = {
    organizationStructureMemberId: string;
    permission: string[];
    type: string[];
};

type HierarchyItems = {
    userId: string;
    items: HierarchyItem[];
};

class FakeServiceError extends Error {
    statusCode: number;
    errorJson: { [key: string]: string[] };

    constructor(statusCode: number, errors: AcxError[], form = false) {
        super(JSON.stringify(errors));

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, FakeServiceError);
        }

        this.name = "FakeServiceError";
        this.statusCode = statusCode;
        this.errorJson = { form: errors.map((err) => err.description) };
    }
}

class UserService extends BaseService {
    protected urlBase: string = "api/User";

    public async getUserLoginHistory(userId: string): Promise<LoginDate[]> {
        const res = await this.post(
            JSON.stringify({ userId }),
            new URLSearchParams({}),
            "UserLoginHistory",
        );

        return res;
    }

    public async getUsers(organizationId: string): Promise<User[]> {
        return this.post(
            JSON.stringify({ organizationId }),
            new URLSearchParams({}),
            "GetUsers",
        );
    }

    public async getAllUsers(): Promise<User[]> {
        return this.get(new URLSearchParams(), "GetAllUsers");
    }

    public async getUserNames(
        organizationId: string,
    ): Promise<ApplicationUser[]> {
        const res = await this.post<ApplicationUser[]>(
            JSON.stringify({ organizationId }),
            new URLSearchParams({}),
            "UserNames",
        );
        return res;
    }

    public async getRoles(): Promise<UserRole[]> {
        return this.get(new URLSearchParams({}), "GetRoles");
    }

    public async getUser(userId: string): Promise<User> {
        return this.post(
            JSON.stringify({ userId }),
            new URLSearchParams({}),
            "GetUserById",
        );
    }

    public async createUser(user: User) {
        const params = new URLSearchParams({});
        return this.post(JSON.stringify(user), params, "CreateUser", {
            returnJson: true,
        });
    }

    public async updateUser(user: User) {
        const params = new URLSearchParams({});
        return this.post(JSON.stringify(user), params, "UpdateUser", {
            returnJson: true,
        });
    }

    public async saveUser(user: User) {
        return user.id ? this.updateUser(user) : this.createUser(user);
    }

    public async changePassword(
        id: string,
        newPassword: string,
    ): Promise<AcxResponse> {
        const params = new URLSearchParams({});
        const res = await this.put(
            params,
            "AdminResetPassword",
            JSON.stringify({
                id,
                newPassword,
            }),
        );

        if (res.succeeded === false) {
            throw new FakeServiceError(400, res.errors, true);
        }
        return res;
    }

    public async getUserHierarchy(
        organizationId: string,
        userId: string,
    ): Promise<HierarchyItems> {
        return this.post(
            JSON.stringify({ organizationId, userId }),
            new URLSearchParams({}),
            "GetUserHierarchyForUser",
        );
    }

    public async saveUserHierarchy(
        orgId: string,
        userId: string,
        items: any[],
    ): Promise<any> {
        const params = new URLSearchParams({});
        return this.post(
            JSON.stringify({ organizationId: orgId, userId, items }),
            params,
            "AssignUserHierarchy",
            { returnJson: true },
        );
    }

    public async getTopLevelOsmAssignmentTiers(
        userId: string,
        organizationId: string,
    ) {
        const body = JSON.stringify({ userId, organizationId });
        console.log(body);
        const response = await this.post(
            body,
            new URLSearchParams({}),
            "GetTopLevelHierarchyAssignments",
        );

        return response;
    }

    public async getTopLevelHierarchiesProper(
        organizationId: string,
        userId: string,
        includeInactive: boolean,
    ) {
        const body = JSON.stringify({
            userId,
            organizationId,
            includeInactive,
        });

        return this.post(
            body,
            new URLSearchParams({}),
            "GetTopLevelHierarchiesProper",
        );
    }

    public async getOsmAssignmentChildrenTiers(
        userId: string,
        organizationId: string,
        osmId: string,
        showInactive: boolean,
    ) {
        let body = {
            userId,
            organizationId,
            osmId,
        };

        if (showInactive) {
            body["showInactive"] = showInactive;
        }
        const response = await this.post(
            JSON.stringify(body),
            new URLSearchParams({}),
            "GetHierarchyAssignmentsTier",
        );

        return response;
    }

    public async editHierarchyAssignmentsTier(
        orgId,
        userId,
        tier: IHierarchyAssignmentTier,
    ) {
        const params = new URLSearchParams({});
        const body = {
            orgId,
            userId,
            tier,
        };

        const response = await this.post(
            JSON.stringify(body),
            params,
            "EditHierarchyAssignmentsTier",
        );

        return response;
    }

    public async updateAllTiers(
        orgId,
        userId,
        hierarchyIds: string[],
        permissions?: number[],
        types?: number[],
    ) {
        const params = new URLSearchParams({});
        const body = {
            orgId,
            userId,
            hierarchyIds,
            permissions,
            types,
        };

        const response = await this.post(
            JSON.stringify(body),
            params,
            "UpdateAllTiers",
        );

        return response;
    }

    public async updateAllTiersOfParent(
        orgId,
        userId,
        tier: IHierarchyAssignmentTier,
    ) {
        const params = new URLSearchParams({});
        const body = {
            orgId,
            userId,
            tier,
        };

        const response = await this.post(
            JSON.stringify(body),
            params,
            "UpdateAllTiersOfParent",
        );

        return response;
    }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new UserService();
