import { Theme, alpha } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Grid, Typography } from "@mui/material";
import theme from "Theme/AppTheme";
import AcxCard from "components/UI/Cards/AcxCard";
import AcxSelectSingle from "components/UI/Select/BaseSelectComponents/AcxSelectSingle";
import { observer } from "mobx-react";
import React from "react";
import { useStore } from "utils/useStore";
import {
    EEOverTimeID,
    EddyPresence,
    SignalsDomain,
} from "../Models/SignalsModels";
import SignalsReportStore, {
    AverageCallDurationCardLoading,
    TotalConversationsCardLoading,
} from "../Store/SignalsReportStore";

const useStyles = makeStyles((theme: Theme) => ({
    cardTitleContainer: {
        paddingTop: theme.spacing(2),
    },
    title: {
        minHeight: "20px",
    },
    tooltip: {
        height: "20px",
    },
    cardsContainer: {
        display: "grid",
        paddingInline: theme.spacing(2),
        columnGap: theme.spacing(2.75),
        rowGap: theme.spacing(2),
        [theme.breakpoints.up("md")]: {
            gridTemplateRows: "1fr",
            gridTemplateColumns: "1fr 1fr 1fr",
        },
        [theme.breakpoints.down("lg")]: {
            gridTemplateRows: "1fr",
            gridTemplateColumns: "1fr 1fr 1fr",
        },
        zIndex: 1000, // set this to avoid card loading indicators appearing on top of header
    },
}));

const cardRootStyle: React.CSSProperties = {
    maxWidth: "none",
    borderRadius: theme.spacing(1),
    minHeight: theme.spacing(13), // 13 * 8 px
    paddingInline: theme.spacing(1),
    boxShadow: `0px 1px 8px 0px ${alpha(theme.palette.black.main, 0.05)}`,
};

const avgCallDurationOptions = [
    {
        label: "with an Eddy",
        value: EddyPresence.WithEddy,
    },
    {
        label: "without an Eddy",
        value: EddyPresence.WithoutEddy,
    },
    {
        label: "All Conversations",
        value: EddyPresence.AllConversations,
    },
];

type Props = { domainName: SignalsDomain };

const SignalsCards = observer((props: Props) => {
    const store = useStore(SignalsReportStore);
    const classes = useStyles();

    const isTotalConversationsLoading = store.getTaskLoading(
        TotalConversationsCardLoading,
    );

    const isAvgCallDurationLoading = store.getTaskLoading(
        AverageCallDurationCardLoading,
    );
    // Convos w/ Eddy calculated from this
    const isEEOverTimeLoading = store.getTaskLoading(EEOverTimeID);

    return (
        <div className={classes.cardsContainer}>
            <AcxCard
                loading={isTotalConversationsLoading}
                loadingSize={24}
                title={
                    <Typography
                        variant="h2"
                        fontWeight="normal"
                        color={theme.palette.gray[300]}
                        className={classes.cardTitleContainer}
                    >
                        Total Conversations
                    </Typography>
                }
                rootStyle={cardRootStyle}
                mainContentStyle={{ justifyContent: "start" }}
                mainContent={
                    <Typography variant="h1" fontWeight="normal">
                        {store.getTotalConversationsFormatted()}
                    </Typography>
                }
            />
            <AcxCard
                loading={isAvgCallDurationLoading}
                loadingSize={24}
                title={
                    <Grid
                        container
                        columnSpacing={0.25}
                        alignItems="center"
                        className={classes.cardTitleContainer}
                    >
                        <Grid item>
                            <Typography
                                variant="h2"
                                fontWeight="normal"
                                color={theme.palette.gray[300]}
                            >
                                Avg Call Duration
                            </Typography>
                        </Grid>
                        <Grid item style={{ position: "relative" }}>
                            <AcxSelectSingle
                                id="signals-average-call-duration-select"
                                isDisabled={isAvgCallDurationLoading}
                                dropdownIndicator={
                                    <ExpandMore
                                        htmlColor={theme.palette.gray[300]}
                                    />
                                }
                                customStyle={{
                                    valueContainer: (provided) => ({
                                        ...provided,
                                        height: "20px",
                                        paddingInline: "0px",
                                        display: "flex",
                                        alignItems: "start",
                                    }),
                                    indicatorsContainer: (provided) => ({
                                        ...provided,
                                        height: "16px",
                                        width: "16px",
                                        justifyContent: "center",
                                    }),
                                    container: (provided) => ({
                                        ...provided,
                                        border: "none",
                                        borderBottom: "2px solid",
                                        borderRadius: "0px",
                                        margin: "0px",
                                        padding: "0px",
                                        width: "164px",
                                        height: "20px",
                                        color: theme.palette.gray[300],
                                    }),
                                    singleValue: (provided) => ({
                                        ...provided,
                                        // react-select positions the single value element absolutely.
                                        // It uses a combination of transform with translateY(50%) and top (50%) css properties
                                        // to get it into the right place. For this implementation of the select it was
                                        // becoming unpredictable where the value would be positioned. Therefore I opted to make
                                        // the value container a flex container and align items start then reset the transorm (Y pos)
                                        // and top to 0% here. I have found this to be more reliable but can break during hot reload sometimes.
                                        transform: "translateY(0%)",
                                        top: "0%",
                                        fontSize: theme.typography.h5.fontSize,
                                        fontWeight:
                                            theme.typography.fontWeightBold,
                                        color: theme.palette.gray[300],
                                        margin: "0px",
                                    }),
                                }}
                                options={avgCallDurationOptions}
                                valueField="value"
                                labelField="label"
                                defaultValue={avgCallDurationOptions.find(
                                    (option) =>
                                        option.value ===
                                        store.averageCallDurationEddyFilter,
                                )}
                                onChange={(filter) => {
                                    if (
                                        filter.value ===
                                        store.averageCallDurationEddyFilter
                                    )
                                        return;

                                    store.setAvgCallDurationFilter(
                                        filter.value,
                                    );
                                    store.getAvgCallDuration(filter.value);
                                }}
                            />
                        </Grid>
                    </Grid>
                }
                rootStyle={cardRootStyle}
                mainContentStyle={{ justifyContent: "start" }}
                mainContent={
                    <Typography variant="h1" fontWeight="normal">
                        {store.getAvgCallDurationFormatted()}
                    </Typography>
                }
            />
            {props.domainName === "eddy" && (
                <AcxCard
                    loading={isEEOverTimeLoading}
                    loadingSize={24}
                    title={
                        <Typography
                            variant="h2"
                            fontWeight="normal"
                            color={theme.palette.gray[300]}
                            className={classes.cardTitleContainer}
                        >
                            Conversations with an Eddy
                        </Typography>
                    }
                    rootStyle={cardRootStyle}
                    mainContentStyle={{ justifyContent: "start" }}
                    mainContent={
                        <Typography variant="h1" fontWeight="normal">
                            {store.getEddyPercentFormatted()}
                        </Typography>
                    }
                />
            )}
        </div>
    );
});

export default SignalsCards;
