import { Box, Divider, Grid, IconButton, Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import theme from "Theme/AppTheme";
import AcxSelect from "components/UI/Select/BaseSelectComponents/AcxSelect";
import AcxSelectMulti from "components/UI/Select/BaseSelectComponents/AcxSelectMulti";
import { observer } from "mobx-react";
import React from "react";
import { Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import RBCFilterStore, { IRBCFilter } from "./RBCFilterStore";
import Classifier from "models/ClassifierModel";

interface Props {
    item: IRBCFilter;
    index: number;
    store: RBCFilterStore;
}

const useRBCItemStyles = makeStyles((theme: Theme) => ({
    itemContainer: {
        padding: "8px",
        backgroundColor: theme.palette.lightgray.main,
        borderRadius: "4px",
        width: "100%",
    },
    andOrContainer: {
        marginTop: "12px",
        marginBottom: "12px",
        height: "28px",
        position: "relative",
    },
    andOrInner: {
        color: "#1E40AF",
        backgroundColor: theme.palette.lightBlue.background,
        borderRadius: "4px",
        padding: "5px 10px 5px 10px",
        position: "absolute",
        cursor: "pointer",
    },
    orDivider: {
        width: "100%",
        backgroundColor: theme.palette.lightBlue.background,
        height: "2px",
    },
    closeContainer: {
        "&:hover $closeButton": {
            visibility: "visible",
        },
    },
    closeButton: {
        visibility: "hidden",
    },
}));

const RBCFilterItem: React.FunctionComponent<Props> = observer(
    ({ item, index, store }) => {
        const classes = useRBCItemStyles();

        const selectedItems = store.classifiersList.filter((option) =>
            item.classifierIds.includes(option.id),
        );

        const nextItem =
            index !== store.RBCFilterItems.length - 1
                ? store.RBCFilterItems[index + 1]
                : undefined;

        const showAndOrChip =
            store.RBCFilterItems.length !== 1 &&
            index !== store.RBCFilterItems.length - 1;

        const isAnd = item.filterGroupId === nextItem?.filterGroupId;

        const selectOptions = [
            { label: "Contains", value: true },
            {
                label: "Does not contain",
                value: false,
            },
        ];

        const handleClassifierIdChange = (arg?: Classifier[]) => {
            item = {
                ...item,
                classifierIds: arg?.map((classifier) => classifier.id) ?? [],
            };
            store.setRBCFilterItem(index, item);
        };

        const renderAndOr = (
            <Grid
                container
                className={classes.andOrContainer}
                justifyContent={isAnd ? "flex-start" : "center"}
                alignItems="center"
            >
                {!isAnd && <Divider className={classes.orDivider} />}
                <Stack
                    direction="row"
                    className={classes.andOrInner}
                    alignItems={"center"}
                    onClick={() => {
                        store.changeRBCCombinator(index, !isAnd ? 0 : 1);
                    }}
                >
                    <Box fontWeight={"bold"}>{isAnd ? "AND" : "OR"}</Box>
                </Stack>
            </Grid>
        );

        return (
            <>
                <Grid className={classes.itemContainer}>
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        className={classes.closeContainer}
                    >
                        <AcxSelect
                            id={"RBC-Filter-item-select-" + index}
                            options={selectOptions}
                            defaultValue={{
                                label: item.value
                                    ? "Contains"
                                    : "Does not contain",
                                value: item.value,
                            }}
                            customStyle={{
                                container: (provided, state) => ({
                                    ...provided,
                                    backgroundColor:
                                        theme.palette.lightgray.main,
                                    // cant find a better way to adjust this width
                                    width: item.value ? "100px" : "155px",
                                }),
                            }}
                            onChange={(item) => {
                                store.updateRBCItemContains(index, item.value);
                            }}
                        />
                        {index !== 0 && (
                            <IconButton
                                onClick={() => store.removeRBCFilterItem(index)}
                                size="small"
                                className={classes.closeButton}
                            >
                                <CloseIcon />
                            </IconButton>
                        )}
                    </Grid>
                    <AcxSelectMulti
                        fullWidth
                        options={store.classifiersList}
                        onChange={handleClassifierIdChange}
                        id={"RBC-item-user-classifiers-" + index}
                        valueField="id"
                        labelField="name"
                        defaultValue={selectedItems}
                        containerHeight="auto"
                        maxContainerHeight="96px"
                        alignControls="flex-start"
                        isClearable={true}
                    />
                </Grid>
                {showAndOrChip && renderAndOr}
            </>
        );
    },
);

export default RBCFilterItem;
