import createStyles from '@mui/styles/createStyles';
import AcxButton from "components/UI/AcxButton";
import AcxControlledTable from "components/UI/AcxTable/AcxControlledTable";
import Gutters from "Layouts/Responsive/Gutters";
import { observer } from "mobx-react";
import AzureTranscription from "models/AzureTranscription";
import React from "react";
import useStyles from "Styles/Styles";
import { useStore } from "utils/useStore";
import TranscriptionStatusStore from "./TranscriptionStatusStore";

const styles = () => createStyles({});

type TranscriptionRow = {
    name: string;
    description: string;
    createdDateTime: string;
    id: string;
    status: string;
    statusMessage: string;
};

function convertTranscription(tx: AzureTranscription): TranscriptionRow {
    const segs = tx.self.split("/");
    const id = segs[segs.length - 1];

    return {
        name: tx.displayName,
        description: tx.description,
        createdDateTime: tx.createdDateTime,
        id: id,
        status: tx.status,
        statusMessage: tx.properties?.error?.message,
    };
}

const TranscriptionStatusTable = observer(() => {
    const classes = useStyles(styles);
    const store = useStore(TranscriptionStatusStore);

    function formatStatus(status: string) {
        let className = "";

        if (status === "Succeeded") className = classes.fontColorSuccess;
        else if (status === "Failed") className = classes.fontColorError;
        else if (status === "NotStarted") className = classes.fontColorWarning;
        else if (status === "Running") className = classes.fontColorSecondary;

        return <span className={className}>{status}</span>;
    }

    function formatMessage(message: string) {
        if (message === "None.") message = "";
        return message;
    }

    const columns = [
        {
            headerLabel: "Name",
            dataKey: "name",
        },
        {
            headerLabel: "Description",
            dataKey: "description",
        },
        {
            headerLabel: "Created",
            dataKey: "createdDateTime",
        },
        {
            headerLabel: "Azure Transcription ID",
            dataKey: "id",
        },
        {
            headerLabel: "Status",
            dataKey: "status",
            formatter: formatStatus,
        },
        {
            headerLabel: "Message",
            dataKey: "statusMessage",
            formatter: formatMessage,
        },
    ];

    const controls = [
        <AcxButton onClick={store.clearCompleted}>Clear Completed</AcxButton>,
    ];

    return (
        <Gutters>
            <AcxControlledTable
                controls={controls}
                isLoading={store.loading}
                rows={store.transcriptions.map(convertTranscription)}
                columns={columns}
            />
        </Gutters>
    );
});

export default TranscriptionStatusTable;
