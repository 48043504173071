import { Grid, styled, Theme } from "@mui/material";
import AcxChip from "components/UI/AcxChip";
import IColDef from "components/UI/AcxDataGrid/IColDef";
import moment from "moment";
import React from "react";
import { ReclassifyJobStatus } from "services/ClassificationService";
import ReclassifyHistoryStore from "./ReclassifyHistoryStore";
import { Refresh } from "@mui/icons-material";
import AcxButton from "components/UI/AcxButton";
import { customSecondaryButtonStyle } from "utils/Style";

const TooltipList = styled("ul")(({ theme }) => ({
    paddingBlock: 0,
    paddingInline: theme.spacing(2),
}));

function getStatusCellBackgroundStyles(
    status: ReclassifyJobStatus,
    theme: Theme,
): React.CSSProperties {
    let backgroundColor: string | undefined = theme.palette.white.main;
    let color: string | undefined = theme.palette.black.main;

    switch (status) {
        case "Running":
            backgroundColor = theme.palette.primary[100];
            color = theme.palette.primary.main;
            break;
        case "Failed":
            backgroundColor = theme.palette.error[100];
            color = theme.palette.error[700];
            break;
        case "Completed":
            backgroundColor = "#DAF2EE";
            color = "#239C98";
            break;
        case "Queued":
            backgroundColor = theme.palette.gray[200];
            color = theme.palette.gray[600];
            break;
        case "Timeout":
            backgroundColor = "#FEEADC";
            color = "#F97316";
            break;
    }

    return {
        backgroundColor,
        color,
        paddingInline: theme.spacing(1),
        overflow: "hidden",
        textOverflow: "ellipsis",
    };
}

export const reclassifyHistoryColumns: IColDef[] = [
    {
        field: "id",
        headerName: "Job ID",
        flex: 0.25,
    },
    {
        field: "classifiers",
        headerName: "Classifiers",
        flex: 1,
        renderCell(params) {
            const classifiers = params.value as string[];
            const truncateClassifiers = classifiers.length > 2;

            return (
                <Grid container flexWrap="nowrap" columnGap={1}>
                    {truncateClassifiers && (
                        <Grid item>
                            <AcxChip
                                size="small"
                                color="lightBlue"
                                label={`${classifiers.length} Classifiers`}
                                showToolTip
                                customTooltip={
                                    <TooltipList>
                                        {classifiers.map((classifier) => (
                                            <li>{classifier}</li>
                                        ))}
                                    </TooltipList>
                                }
                            />
                        </Grid>
                    )}
                    {!truncateClassifiers &&
                        classifiers.map((classifier) => (
                            <Grid item>
                                <AcxChip
                                    size="small"
                                    color="lightBlue"
                                    label={classifier}
                                />
                            </Grid>
                        ))}
                </Grid>
            );
        },
    },
    {
        field: "totalRecords",
        headerName: "Total Records",
        flex: 0.5,
    },
    {
        field: "userName",
        headerName: "User",
        flex: 1,
    },
    {
        field: "startDate",
        headerName: "Date Range Start",
        flex: 0.5,
        valueGetter(params) {
            if (!params.value || typeof params.value !== "string") return;
            const utcMoment = moment.utc(params.value);
            return utcMoment.local().format("YYYY-MM-DD");
        },
    },
    {
        field: "endDate",
        headerName: "Date Range End",
        flex: 0.5,
        valueGetter(params) {
            if (!params.value || typeof params.value !== "string") return;
            const utcMoment = moment.utc(params.value);
            return utcMoment.local().format("YYYY-MM-DD");
        },
    },
    {
        field: "dateReference",
        headerName: "Date Range Reference",
        flex: 0.5,
    },
    {
        field: "executionStartDateTimeUtc",
        headerName: "Reclassify Start",
        flex: 1,
        valueGetter(params) {
            if (!params.value || typeof params.value !== "string") return;
            const utcMoment = moment.utc(params.value);
            return utcMoment.local().format("YYYY-MM-DD HH:mm:ss");
        },
    },
    {
        field: "executionEndDateTimeUtc",
        headerName: "Reclassify Complete",
        flex: 1,
        valueGetter(params) {
            if (!params.value || typeof params.value !== "string") return;
            const utcMoment = moment.utc(params.value);
            return utcMoment.local().format("YYYY-MM-DD HH:mm:ss");
        },
    },
    {
        field: "hierarchies",
        headerName: "Hierarchy",
        flex: 1,
        renderCell(params) {
            const hierarchies = params.value as string[];
            if (!hierarchies || !hierarchies.length) return null;
            const truncateHierarchies = hierarchies.length > 1;

            return (
                <Grid container flexWrap="nowrap" columnGap={1}>
                    {truncateHierarchies && (
                        <Grid item>
                            <AcxChip
                                size="small"
                                color="lightBlue"
                                label={`${hierarchies.length} Hierarchies`}
                                showToolTip
                                customTooltip={
                                    <TooltipList>
                                        {hierarchies.map((hierarchy) => (
                                            <li>{hierarchy}</li>
                                        ))}
                                    </TooltipList>
                                }
                            />
                        </Grid>
                    )}
                    {!truncateHierarchies &&
                        hierarchies.map((hierarchy) => (
                            <Grid item>
                                <AcxChip
                                    size="small"
                                    color="lightBlue"
                                    label={hierarchy}
                                />
                            </Grid>
                        ))}
                </Grid>
            );
        },
    },
    {
        field: "status",
        headerName: "Status",
        flex: 0.5,
        renderCell(params) {
            if (!params.value) return;

            return (
                <Grid
                    container
                    item
                    sx={(theme) =>
                        getStatusCellBackgroundStyles(
                            params.value!.toString() as ReclassifyJobStatus,
                            theme,
                        )
                    }
                >
                    {params.value.toString()}
                </Grid>
            );
        },
    },
    {
        field: "failureReason",
        headerName: "Failure Reason",
        flex: 1,
    },
];

export function getReclassifyHistoryControls(
    store: ReclassifyHistoryStore,
): React.ReactElement[] {
    return [
        <AcxButton
            fullWidth={false}
            leftRightSpacing={0}
            onClick={() => store.loadReclassifyResults()}
            sx={() => ({ margin: 0 })}
            customRootStyles={customSecondaryButtonStyle}
            loading={store.getTaskLoading(
                ReclassifyHistoryStore.Tasks.LOAD_RECLASSIFY_RESULTS,
            )}
        >
            <Refresh />
        </AcxButton>,
    ];
}
