import { SignalReportDatum } from "models/Reporting/ReportDatum";
import moment from "moment";
import { Moment } from "moment";
import { SignalsTemporalLabels } from "../Models/SignalsModels";

function groupBy<T, K extends keyof any>(
    array: T[],
    selector: (item: T) => K,
): Record<K, T[]> {
    return array.reduce((result, item) => {
        const key = selector(item);
        if (!result[key]) {
            result[key] = [];
        }
        result[key].push(item);
        return result;
    }, {} as Record<K, T[]>);
}

function formatData(
    dataGroups: Record<string, SignalReportDatum[]>,
    dateRangeType: "Week" | "Month" | "Quarter",
    startDate?: Moment,
) {
    const dataGroupKeys = Object.keys(dataGroups);

    return dataGroupKeys.flatMap((groupName) => {
        const groupData = dataGroups[groupName];
        const groupDataByTimePeriod = groupBy(groupData, (x) =>
            getLabelString(x, dateRangeType, startDate),
        );
        const allTimePeriods = Object.keys(groupDataByTimePeriod);

        return allTimePeriods.map((timePeriod) => {
            const timePeriodData = groupDataByTimePeriod[timePeriod];
            const y = timePeriodData.reduce((acc, curr) => acc + curr.y, 0);
            const totalCount = timePeriodData.reduce(
                (acc, curr) => acc + (curr.y_totalcount ?? 0),
                0,
            );
            const yPercent = y / totalCount;

            const newDatum: SignalReportDatum = {
                x: timePeriod,
                y: y,
                y_percent: yPercent,
                y_totalcount: totalCount,
                grouping: groupName,
            };
            if (groupName === "null") {
                // Need to do this for Eddy Over Time / charts without any groupings
                delete newDatum.grouping;
            }

            return newDatum;
        });
    });
}

function getLabelString(
    x: SignalReportDatum,
    dateRangeType: "Week" | "Month" | "Quarter",
    startDate?: Moment,
) {
    let momentDate = moment(
        `${x.metadata?.find((m) => m.name === "Month")?.value}/${
            x.metadata?.find((m) => m.name === "Day")?.value
        }/${x.metadata?.find((m) => m.name === "Year")?.value}`,
    );
    switch (dateRangeType) {
        case "Week":
            const dateToUse =
                momentDate.startOf("week") < startDate!
                    ? startDate!
                    : momentDate.startOf("week");

            return dateToUse.format("MMM D, YYYY");
        case "Month":
            momentDate = momentDate.startOf("month");
            return momentDate.format("MMM YYYY");
        case "Quarter":
            momentDate = momentDate.startOf("quarter");
            return "Q" + momentDate.format("Q YYYY");
        default:
            return "N/A";
    }
}

export const THE_MICHAEL_GRAIN = 12;

// obsolete, data aggregation handled on backend now
export function aggregateData(
    startDate: Moment,
    endDate: Moment,
    data: SignalReportDatum[],
) {
    const daysInRange = endDate.diff(startDate, "days");

    if (daysInRange <= THE_MICHAEL_GRAIN) {
        const ret = data;
        ret.forEach((i) => {
            i.x = moment(i.x).format("MMM D, YYYY");
        });
        return ret;
    }

    const dataGroups = groupBy(data, (x) => x.grouping!);

    const weeksInRange = endDate.diff(startDate, "weeks");

    if (weeksInRange <= THE_MICHAEL_GRAIN) {
        return formatData(dataGroups, "Week", startDate);
    }

    const monthsInRange = endDate.diff(startDate, "months");

    if (monthsInRange <= THE_MICHAEL_GRAIN) {
        return formatData(dataGroups, "Month");
    }

    //else, we do by quarter

    return formatData(dataGroups, "Quarter");
}

export function temporalGrain(
    startDate: Moment,
    endDate: Moment,
): SignalsTemporalLabels {
    const daysInRange = endDate.diff(startDate, "days");

    if (daysInRange <= THE_MICHAEL_GRAIN) {
        return "Daily";
    }
    const weeksInRange = endDate.diff(startDate, "weeks");

    if (weeksInRange <= THE_MICHAEL_GRAIN) {
        return "Weekly";
    }

    // use start of month because that matches backend calculation in GroupByAuthenticxTemporalGrain function in SignalsReportQuery.cs
    const monthsInRange = moment(endDate)
        .startOf("M")
        .diff(moment(startDate).startOf("M"), "months");

    if (monthsInRange <= THE_MICHAEL_GRAIN) {
        return "Monthly";
    }

    return "Quarterly";
}
