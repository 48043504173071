import { Box, useMediaQuery } from "@material-ui/core";
import theme from "Theme/AppTheme";
import { PlayerOptions } from "components/SoundClipEditor/SoundClipEditor";
import SoundClipEditorV2 from "components/SoundClipEditor/SoundClipEditorV2";
import SoundClipEditorStore from "components/SoundClipEditor/Stores/SoundClipEditorStore";
import { AcxTranscription } from "components/UI/AcxTranscription";
import AcxChatViewer, { IChatRecord } from "components/UI/Chat/AcxChatViewer";
import AcxDrawer from "components/UI/Drawer/AcxDrawer";
import { observer } from "mobx-react";
import { EddyEffectP2Results } from "models/EddyEffectP2Results";
import { InteractionType } from "models/InteractionType";
import React, { useState } from "react";
import { ITranscriptionSegment } from "services/TranscriptionService";
import { useStore } from "utils/useStore";
import { WorkflowReviewStore } from "../Stores/WorkflowReviewStore";

interface WFRSoundClipEditorDockProps {
    soundClipEditorStore?: SoundClipEditorStore;
    open: boolean;
    onToggleOpen: () => void;
    mediaType?: InteractionType;
    transcriptWords?: ITranscriptionSegment[];
    eddyEffectP2Data?: EddyEffectP2Results[];
    hasEvaluation?: boolean;
    records?: IChatRecord[];
}

const WFRSoundClipEditorDock: React.FC<WFRSoundClipEditorDockProps> = observer(
    ({
        soundClipEditorStore,
        open,
        onToggleOpen,
        mediaType,
        transcriptWords,
        eddyEffectP2Data,
        hasEvaluation,
        records,
    }) => {
        const workflowReviewStore = useStore(WorkflowReviewStore);
        const [playerOptions, setPlayerOptions] = useState<PlayerOptions>({
            id: "0",
            isPlaying: false,
            startTime: 0,
            endTime: 0,
        });

        const onSelectedClip = (options: PlayerOptions) => {
            options &&
                setPlayerOptions((prev) => ({
                    ...prev,
                    id: options.id,
                    isPlaying: options.isPlaying,
                    startTime: options.startTime,
                    endTime: options.endTime,
                }));
        };

        const shouldTruncateButtonText = useMediaQuery(
            theme.breakpoints.down("lg"),
        );
        return (!!records || !!transcriptWords) && mediaType !== undefined ? (
            <AcxDrawer
                anchor="bottom"
                open={open}
                collapsible="mini"
                content={
                    <>
                        {soundClipEditorStore &&
                            mediaType === InteractionType.Audio && (
                                <>
                                    <SoundClipEditorV2
                                        id="workflow-review-sound-clip-editor"
                                        soundClipEditorStore={
                                            soundClipEditorStore
                                        }
                                        playerOptions={playerOptions}
                                        fullControls
                                        enableZoom
                                        noSegmentRemoval
                                        textButton={
                                            open
                                                ? `Hide${
                                                      shouldTruncateButtonText
                                                          ? ""
                                                          : " Transcript"
                                                  }`
                                                : `View${
                                                      shouldTruncateButtonText
                                                          ? ""
                                                          : " Transcript"
                                                  }`
                                        }
                                        textButtonProps={{
                                            onClick: onToggleOpen,
                                        }}
                                        segmentList={
                                            workflowReviewStore.clipsToSegmentList
                                        }
                                    />
                                    {open && (
                                        <Box height="64%" padding={"8px"}>
                                            <AcxTranscription
                                                onSelectedClip={onSelectedClip}
                                                eddyEffectP2Data={
                                                    eddyEffectP2Data ?? []
                                                }
                                                transcriptWords={
                                                    transcriptWords ?? []
                                                }
                                                hasEvaluation={!!hasEvaluation}
                                                minimal
                                                disableClip
                                            />
                                        </Box>
                                    )}
                                </>
                            )}
                        {(mediaType === InteractionType.Chat ||
                            mediaType === InteractionType.ProcessedChat) && (
                            <AcxChatViewer
                                records={records}
                                eddyEffectP2Data={eddyEffectP2Data ?? []}
                                showHeader
                                secondaryButton
                                secondaryButtonLabel={
                                    open ? "Minimize Chat" : "Expand Chat"
                                }
                                secondaryButtonOnClick={onToggleOpen}
                                disableClip
                            />
                        )}
                    </>
                }
                maxSizePx={550}
                miniSizePx={
                    mediaType === InteractionType.Chat ||
                    mediaType === InteractionType.ProcessedChat
                        ? 300
                        : 195
                }
                variant="permanent"
            />
        ) : null;
    },
);

export default WFRSoundClipEditorDock;
