import { Grid, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import EddyLabel from "components/UI/Eddy/EddyLabel";
import ChromeTabs, { ChromeTab } from "components/UI/SubTabs/ChromeTabs";
import { observer } from "mobx-react";
import { AppDomains } from "models/Permission/AppDomains";
import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router";
import { AuthStore } from "stores/AuthStore";
import { useStore } from "utils/useStore";
import ClassifierReport from "./ClassifierReport/ClassifierReport";
import ModelDashboard from "./ModelDashboard/ModelDashboard";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: "100%",
        height: "calc(100% - 120px)",
        // overflow: "hidden",
    },
}));

const NlpLayout = observer(() => {
    const classes = useStyles();
    const navigate = useNavigate();
    const authStore = useStore(AuthStore);
    const location = useLocation();
    const [activeTab, setActiveTab] = useState<number>();
    const [initialLoadComplete, setInitialLoadComplete] = useState(false);
    const paths = location.pathname.split("/");

    //Function on tab change
    const updateTab = (index: number) => {
        setActiveTab(index);

        // prevents redirection from initial url
        if (
            !initialLoadComplete &&
            paths.some((path) => path === "classifier_builder_v2") &&
            paths.some((path) => path === "create" || path === "edit")
        ) {
            navigate(location.pathname);
        } else {
            navigate(tabs[index].route!);
        }
        if (!initialLoadComplete) {
            setInitialLoadComplete(true);
        }
    };

    //Navgate to current route's tab, and if current route's tab can't be found, navigate to the first tab.
    useEffect(() => {
        if (tabs.length === 0) {
            navigate("/app");
        } else if (
            activeTab === undefined ||
            tabs.findIndex((tab) => location.pathname.includes(tab.route!)) ===
                -1
        ) {
            updateTab(
                Math.max(
                    tabs.findIndex((tab) =>
                        location.pathname.includes(tab.route!),
                    ),
                    0,
                ),
            );
        }
    });

    //Legacy V1 view nlp permission
    const oldUserViewPermission = authStore.permStore.canPerformOp(
        AppDomains.Nlp,
        "View",
    );

    const tabs: Array<ChromeTab> = [
        {
            label: "Report",
            route: "/app/nlp/classifierreport",
            newPermissionCheck: authStore.canUserView("Report"),
            oldPermissionCheck: oldUserViewPermission,
        },
        {
            label: <EddyLabel />,
            route: "/app/nlp/modeldashboard",
            newPermissionCheck: authStore.canUserView("Eddy Dashboard"),
            oldPermissionCheck: oldUserViewPermission,
        },
    ].filter((tab) => tab.newPermissionCheck);

    return (
        <>
            <Grid container>
                <Grid item container>
                    <ChromeTabs
                        tabs={tabs}
                        activeTabIndex={activeTab}
                        onChange={updateTab}
                    />
                </Grid>
            </Grid>
            <Routes>
                {authStore.canUserView("Report") && (
                    <Route
                        path="classifierreport"
                        element={
                            <>
                                <div className={classes.root}>
                                    <ClassifierReport />
                                </div>
                            </>
                        }
                    />
                )}
                {authStore.canUserView("Eddy Dashboard") && (
                    <Route
                        path="modeldashboard"
                        element={
                            <>
                                <div className={classes.root}>
                                    <ModelDashboard />
                                </div>
                            </>
                        }
                    />
                )}
            </Routes>
        </>
    );
});

export default NlpLayout;
