import { Grid,Tooltip,Typography } from "@mui/material";
import { ContactRequestType } from "components/Admin/Organizations/types/Tier.type";
import AcxButton from "components/UI/AcxButton";
import AcxMainTextField from "components/UI/AcxMainTextField";
import AcxSelectMulti from "components/UI/Select/BaseSelectComponents/AcxSelectMulti";
import { observer } from "mobx-react";
import { AppDomains } from "models/Permission/AppDomains";
import { ApplicationUser } from "models/Permission/ApplicationUser";
import React from "react";
import { AuthStore } from "stores/AuthStore";
import { OrganizationServiceHierarchyStore } from "../stores/OrganizationServiceHierarchyStore";
import AcxChipListInput from "components/UI/AcxChipListInput";

interface OsmPropertiesLayoutProps {
    osmStore: OrganizationServiceHierarchyStore;
    authStore: AuthStore;
}

// tooltip values to match labels for org details
const tierPropertiesTooltips = {
    category: "A primary bucket used to describe the hierarchy (ex: patient services, financial, etc.). This value appears as a reportable field to give additional flexibility to filtering and reporting abilities.",
    subcategory: "A sub-bucket used to describe the hierarchy (ex : medication delivery, after-hours questions, billing, copay, etc.). This value appears as a reportable field to give additional flexibility to filtering and reporting abilities.",
	code: "An alphanumeric ID that can be given to a hierarchy. This value appears as a reportable field to give additional flexibility to filtering and reporting abilities.",
	adminContacts: "Users can be set as an Administrative Contact to configure notifications delivery for triggered workflows. This value appears as a reportable field.",
	escalationContacts: "Users can be set as an Escalation Contact to configure notifications delivery for triggered workflows. This value appears as a reportable field.",
    managerContacts: "Users can be set as an Manager Contact to configure notifications delivery for triggered workflows. This value appears as a reportable field.",
    hierarchyCode: "A value within the interaction metadata which identifies the hierarchy for that interaction",
};

const OsmPropertiesLayout: React.FC<React.PropsWithChildren<React.PropsWithChildren<OsmPropertiesLayoutProps>>> = observer(
    ({ osmStore, authStore }) => {
        let adminContacts: ContactRequestType[] = [];
        let escalationContacts: ContactRequestType[] = [];
        let managerContacts: ContactRequestType[] = [];

        const hasEditPerms =
            authStore.permStore.canPerformOp(
                AppDomains.Administrative,
                "Edit Service Hierarchy",
            ) || authStore.canUserEdit("Service Hierarchy");

        const handleOnAdminListChange = (users?: ApplicationUser[]) => {
            if (users) {
                adminContacts = users.map(
                    (user) =>
                        ({
                            userName: user.userName,
                            userId: user.id,
                        } as ContactRequestType),
                );
            } else adminContacts = [];

            osmStore.setActiveTierAttribute(
                "administrativeContacts",
                adminContacts,
            );
        };

        const handleOnEscalationListChange = (users?: ApplicationUser[]) => {
            if (users) {
                escalationContacts = users.map(
                    (user) =>
                        ({
                            userName: user.userName,
                            userId: user.id,
                        } as ContactRequestType),
                );
            } else escalationContacts = [];

            osmStore.setActiveTierAttribute(
                "escalationContacts",
                escalationContacts,
            );
        };

        const handleOnManagerListChange = (users?: ApplicationUser[]) => {
            if (users) {
                managerContacts = users.map(
                    (user) =>
                        ({
                            userName: user.userName,
                            userId: user.id,
                        } as ContactRequestType),
                );
            } else managerContacts = [];

            osmStore.setActiveTierAttribute("managerContacts", managerContacts);
        };

        const handleOnSubmit = () => {
            if (osmStore.activeTier && osmStore.activeTier.id) {
                // Our React Hook will trigger off of this and fire off an update to the active tier.
                osmStore.setEndpointFired(true, "Update");
            }
        };

        const handleOnAdd = () => {
            if (osmStore.activeTier && osmStore.activeTier.id) {
                osmStore.setHierarchyCode();
            }
        };

        return (
            <Grid
                container
                item
                xs={12}
                spacing={1}
                style={{ padding: "1rem 0" }}
            >
                <Grid container item>
                    <Typography color="secondary" variant="subtitle2">
                        {osmStore.activeTier?.name ?? ""}
                    </Typography>
                </Grid>

                <Tooltip title={tierPropertiesTooltips.category}>
                    <Grid item>
                        <AcxMainTextField
                            isDisabled={!hasEditPerms}
                            key="category"
                            containerStyle={{ marginBottom: "0.5rem" }}
                            id="category"
                            labelText="Category"
                            placeholderText='e.g. "Clinic"'
                            value={osmStore.activeTier?.category ?? ""}
                            onChange={(e) =>
                                osmStore.setActiveTierAttribute("category", e.target.value)
                            }
                            disableTooltip
                        />
                    </Grid>
                </Tooltip>
                <Tooltip title={tierPropertiesTooltips.subcategory}>
                    <Grid item>
                        <AcxMainTextField
                            isDisabled={!hasEditPerms}
                            key="subCategory"
                            disableTooltip
                            containerStyle={{ marginBottom: "0.5rem" }}
                            id="subCategory"
                            labelText="Sub Category"
                            placeholderText='e.g. "Patient"'
                            value={osmStore.activeTier?.subCategory ?? ""}
                            onChange={(e) =>
                                osmStore.setActiveTierAttribute(
                                    "subCategory",
                                    e.target.value,
                                )
                            }
                        />
                    </Grid>
                </Tooltip>
                <Tooltip title={tierPropertiesTooltips.code}>
                    <Grid item>
                        <AcxMainTextField
                            isDisabled={!hasEditPerms}
                            focus
                            disableTooltip
                            key="code"
                            containerStyle={{ marginBottom: "0.5rem" }}
                            id="code"
                            labelText="Code"
                            placeholderText='e.g. "0000"'
                            value={osmStore.activeTier?.code ?? ""}
                            onChange={(e) =>
                                osmStore.setActiveTierAttribute("code", e.target.value)
                            }
                        />
                    </Grid>
                </Tooltip>
                <Tooltip title={tierPropertiesTooltips.adminContacts}>
                    <Grid item xs={12} key="adminContacts1">
                        <AcxSelectMulti
                            isDisabled={!hasEditPerms}
                            key="adminContacts"
                            options={osmStore.orgUsers ?? []}
                            defaultValue={osmStore.adminUsers ?? []}
                            inputLabel={"Administrative Contacts"}
                            id="select-admin-contacts"
                            valueField={"id"}
                            labelField={"userName"}
                            containerHeight="auto"
                            fullWidth
                            isClearable
                            onChange={handleOnAdminListChange}
                        />
                    </Grid>
                </Tooltip>
                <Tooltip title={tierPropertiesTooltips.escalationContacts}>
                    <Grid
                        item
                        xs={12}
                        key="escalationContacts1"
                        style={{ marginTop: "0.5rem" }}
                    >
                        <AcxSelectMulti
                            isDisabled={!hasEditPerms}
                            key="escalationContacts"
                            options={osmStore.orgUsers ?? []}
                            defaultValue={osmStore.escalationUsers ?? []}
                            inputLabel={"Escalation Contacts"}
                            id="select-escalation-contacts"
                            valueField={"id"}
                            labelField={"userName"}
                            containerHeight="auto"
                            fullWidth
                            isClearable
                            onChange={handleOnEscalationListChange}
                        />
                    </Grid>
                </Tooltip>
                <Tooltip title={tierPropertiesTooltips.managerContacts}>
                    <Grid
                        item
                        xs={12}
                        key="managerContacts1"
                        style={{ marginTop: "0.5rem" }}
                    >
                        <AcxSelectMulti
                            isDisabled={!hasEditPerms}
                            key="managerContacts"
                            options={osmStore.orgUsers ?? []}
                            defaultValue={osmStore.managerUsers ?? []}
                            inputLabel={"Manager Contacts"}
                            id="select-manager-contacts"
                            valueField={"id"}
                            labelField={"userName"}
                            containerHeight="auto"
                            fullWidth
                            isClearable
                            onChange={handleOnManagerListChange}
                        />
                    </Grid>
                </Tooltip>

                <AcxChipListInput
                     labelText="Hierarchy Codes"
                     placeholderText="Add a new hierarchy code"
                     tooltip={tierPropertiesTooltips.hierarchyCode}
                     size="small"
                     color="blue"
                     loading={osmStore.isLoading}
                     value={osmStore.hierarchyCodeValue ?? ""}
                     values={osmStore.activeTier?.hierarchyCodes}
                     isDisabled={!hasEditPerms} // for the input field
                     btnDisabled={!osmStore.hierarchyCodeValue} // for the add btn
                     disableTooltip // disables default tooltip for acxMainTextField, allows for custom tooltip
                     onChange={(e) =>
                        osmStore.setHierarchyCodeValue(e.target.value ?? "")
                     }
                     onClick={handleOnAdd}
                     onDelete={ (value) => osmStore.deleteHierarchyCode(value)} 
                />

                <Grid container item>
                    <AcxButton
                        fullWidth={false}
                        buttonDisabled={osmStore.isLoading}
                        onClick={() => osmStore.setShowEditTierDrawer(false)}
                        leftRightSpacing={0}
                        color="default"
                    >
                        Cancel
                    </AcxButton>

                    <AcxButton
                        buttonDisabled={!hasEditPerms}
                        loading={osmStore.isLoading}
                        customRootStyles={{ marginRight: "0.5rem" }}
                        fullWidth={false}
                        onClick={handleOnSubmit}
                        leftRightSpacing={1}
                        color="secondary"
                    >
                        Set Properties
                    </AcxButton>
                </Grid>
            </Grid>
        );
    },
);

export default OsmPropertiesLayout;
