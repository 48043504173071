import { Button, Grid, Theme, Typography } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import AddSharpIcon from "@mui/icons-material/AddSharp";
import { Question } from "components/Admin/Organizations/types/Module.type";
import AcxErrorMessage from "components/UI/AcxErrorMessage";
import {
    QuestionDependencyCondition,
    QuestionDependencyQuestionAnswerCondition,
    QuestionDependencyQuestionAnswerOperator,
    QuestionDependencyType,
} from "models/Question";
import React from "react";
import { FormFieldErrors } from "shared/types/FormErrors.type";
import useStyles from "Styles/Styles";
import OrganizationModuleQuestionDependency from "./OrganizationModuleQuestionDependency";

export const emptyGUID = "00000000-0000-0000-0000-000000000000";

type Props = {
    value: Question;
    onChange: (key: keyof Question, val: any) => void;
    index: number;
    placement: number[];
    dependencyQuestions: Question[];
    errors?: FormFieldErrors;
    errorPath: string;
};

const styles = (theme: Theme) => {
    return createStyles({
        trashIcon: {
            cursor: "pointer",
            color: theme.palette.blue.main,
            "&:hover": {
                transform: "scale(1.03)",
            },
        },
        addButton: {
            textTransform: "none",
            fontWeight: "bold",
            marginTop: "15px",
        },
        addContainer: { display: "flex", justifyContent: "flex-end" },
        paddingTop: {
            paddingTop: theme.spacing(2),
        },
    });
};

const OrganizationModuleQuestionDependencies: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
    value,
    onChange,
    index,
    dependencyQuestions,
    errors,
    errorPath,
}) => {
    const classes = useStyles(styles);

    const addTopLevelDependency = () => {
        const newQuestionAnswerDependency = {
            dependsOnQuestionId: emptyGUID,
            op: QuestionDependencyQuestionAnswerOperator.Contains,
            value: "",
            type: QuestionDependencyType.QuestionAnswer,
        } as QuestionDependencyQuestionAnswerCondition;

        return value.dependencies
            ? {
                  conditions: [
                      ...value.dependencies.conditions,
                      newQuestionAnswerDependency,
                  ],
              }
            : {
                  conditions: [newQuestionAnswerDependency],
              };
    };

    return (
        <Grid container className={classes.paddingTop}>
            <Typography variant="h2">Question Dependencies</Typography>
            {!!errors?.[`${errorPath}.Dependencies`]?.length && (
                <Grid item xs={12}>
                    <AcxErrorMessage
                        errors={errors?.[`${errorPath}.Dependencies`]}
                    />
                </Grid>
            )}
            {value.dependencies &&
                value?.dependencies?.conditions.map(
                    (
                        condition: QuestionDependencyCondition,
                        conditionIndex: number,
                    ) => {
                        return (
                            <OrganizationModuleQuestionDependency
                                key={(value.id ?? "") + conditionIndex}
                                conditionIndex={conditionIndex}
                                onChange={onChange}
                                index={index}
                                value={value}
                                condition={condition}
                                dependencyQuestions={dependencyQuestions}
                            />
                        );
                    },
                )}
            <Grid item xs={12} className={classes.addContainer}>
                <Button
                    onClick={() => {
                        onChange("dependencies", addTopLevelDependency());
                    }}
                    color="secondary"
                    startIcon={<AddSharpIcon />}
                    className={classes.addButton}
                >
                    Add Dependency
                </Button>
            </Grid>
        </Grid>
    );
};

export default OrganizationModuleQuestionDependencies;
