import AddSharp from "@mui/icons-material/AddSharp";
import { Grid, Theme, Typography, useTheme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import AcxButton from "components/UI/AcxButton";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LocalStorage from "stores/LocalStorage";
import useStyles from "Styles/Styles";
import { useStore } from "utils/useStore";
import { AuthStore } from "../../../../../stores/AuthStore";
import useOrganizationRoles from "../../hooks/useOrganizationRoles";
import useOrganizationUsers from "../../hooks/useOrganizationUsers";
import OrganizationRolesDetail from "./OrganizationRolesDetail";
import OrganizationRolesListCards from "./OrganizationRolesListCards";
import OrganizationRolesListSearch from "./OrganizationRolesListSearch";
import OrganizationRolesListTable from "./OrganizationRolesListTable";
import OrganizationRolesListViewSelect from "./OrganizationRolesListViewSelect";

const lsViewTypeKey = "RolesListViewType";

export enum ViewType {
    cardView = "cardView",
    listView = "listView",
}

const styles = (theme: Theme) =>
    createStyles({
        container: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "0px",
            position: "relative",
            width: 424,
            height: 400,
            filter: "drop-shadow(0px 25px 50px rgba(0, 0, 0, 0.25))",
            borderRadius: "8px",
        },
        titleContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "24px",
            position: "static",
            width: "424px",
            height: "136px",
            left: "0px",
            top: "0px",
            background: "#FFFFFF",
            flex: "none",
            order: 0,
            flexGrow: 0,
            margin: "0px 0px",
        },
        actions: {
            display: "flex",
            alignItems: "flex-end",
            padding: "24px",
            width: "100%",
        },
        title: {
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "20px",
            lineHeight: "32px",
            color: theme.palette.blackFont.main,
        },
        subtitle: {
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "14px",
            lineHeight: "20px",
            color: "#71717A",
        },
        closeButton: {
            position: "absolute",
            top: 16,
            color: "#A1A1AA",
            right: 8,
        },
        closeIcon: {
            height: 20,
            width: 20,
        },
        error: {
            fontSize: "13px",
            color: theme.palette.error.main,
            textAlign: "center",
            padding: theme.spacing(1),
        },
        actionButton: {
            width: "inherit",
        },
        cancel: {
            color: theme.palette.blackFont.main,
            height: "30px",
            overflow: "hidden",
            fontSize: "13px",
            textAlign: "center",
            fontFamily: '"Inter", sans-serif',
            fontWeight: "bold",
            lineHeight: "20px",
            marginLeft: "0",
            whiteSpace: "nowrap",
            marginRight: "0",
            textOverflow: "ellipsis",
            letterSpacing: "0",
            textTransform: "none",
            background: "#FFFFFF",
            border: "1px solid #E4E4E7",
            "&:hover": {
                backgroundColor: theme.palette.white.main,
            },
        },
        fullWidth: {
            width: "100%",
        },
        required: {
            "&:after": {
                color: theme.palette.red.main,
                content: '" *" !important',
            },
        },
        controlSection: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            alignItems: "center",
        },
    });

type Props = {
    orgId: string;
};

const OrganizationRolesList: React.FC<Props> = observer(({ orgId }) => {
    const classes = useStyles(styles);
    const theme = useTheme();
    // const { search } = useLocation();
    const location = useLocation();
    const { getLocalStore } = useStore(LocalStorage);
    const ls = getLocalStore("AcxDefault");
    const authStore = useStore(AuthStore);
    const { users, isLoading: isLoadingUsers } = useOrganizationUsers(orgId);
    // roles is un-transformed result from API
    const { roles, isLoading, refetch } = useOrganizationRoles(orgId);
    const [filter, setFilter] = useState<string>("");
    const navigate = useNavigate();
    const [filteredRoles, setFilteredRoles] = useState<any[]>([]);
    const [detailData, setDetailData] = useState<any>({
        name: "",
    });
    const [view, setView] = useState<ViewType>();
    // const { id } = qs.parse(search);
    const [isOrgAdmin, setIsOrgAdmin] = useState<boolean>(false);
    const params = new URLSearchParams(location.search);
    const id = params.get("id");

    useEffect(() => {
        let destroyed = false;
        const getLsData = async () => {
            const viewType: ViewType | null = await ls.getItem(lsViewTypeKey);

            if (!destroyed) {
                setView(viewType || ViewType.cardView);
            }
        };
        getLsData();
        return () => {
            destroyed = true;
        };
    }, [ls]);

    useEffect(() => {
        if (!isLoadingUsers) {
            const role = roles?.find((role) => role.id === id);
            setIsOrgAdmin(role?.isOrgAdministrator ?? false);
            setDetailData(role || { name: "", permissions: {} });
        }
    }, [id, roles, isLoadingUsers, users]);

    useEffect(() => {
        const filtered = roles?.filter((group) =>
            group.$search?.includes(filter),
        );
        setFilteredRoles(filtered ? [...filtered] : []);
    }, [roles, filter]);

    const changeView = async (view: ViewType) => {
        setView(view);
        await ls.setItem(lsViewTypeKey, view);
    };

    const onCloseDetail = () => {
        navigate(`/admin/organizations/${orgId}/roles`);
    };

    return (
        <>
            {view ? (
                <div className={classes.appContent}>
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems={"flex-end"}
                        sx={{
                            marginTop: theme.spacing(2),
                            marginBottom: theme.spacing(2),
                            alignItems: "center",
                        }}
                    >
                        <Grid item xs={2}>
                            <Typography variant="subtitle1">
                                {!!filteredRoles.length && (
                                    <>{filteredRoles.length} </>
                                )}
                                Roles
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={9}
                            container
                            spacing={1}
                            justifyContent="flex-end"
                            alignItems={"flex-end"}
                            alignContent="flex-end"
                            style={{ margin: 0 }}
                        >
                            <div style={{ display: "flex" }}>
                                <OrganizationRolesListSearch
                                    value={filter}
                                    setFilter={setFilter}
                                />
                                <OrganizationRolesListViewSelect
                                    view={view}
                                    changeView={changeView}
                                />

                                {authStore.canUserEditRoles() && (
                                    <AcxButton
                                        color="secondary"
                                        leftRightSpacing={0}
                                        startIcon={<AddSharp />}
                                        onClick={() => {
                                            navigate(
                                                `/admin/organizations/${orgId}/roles?id=add`,
                                            );
                                        }}
                                    >
                                        New role
                                    </AcxButton>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                    {view === ViewType.cardView ? (
                        <OrganizationRolesListCards
                            orgId={orgId}
                            data={filteredRoles}
                            isLoading={isLoading}
                        />
                    ) : (
                        <OrganizationRolesListTable
                            orgId={orgId}
                            data={filteredRoles}
                            isLoading={isLoading}
                        />
                    )}
                </div>
            ) : (
                <></>
            )}

            {((id && detailData) || (id && id === "add")) && !isLoadingUsers && (
                <div style={{ zIndex: 1201 }}>
                    <OrganizationRolesDetail
                        orgId={orgId}
                        id={id}
                        isOrgAdministrator={isOrgAdmin}
                        close={onCloseDetail}
                        refetch={refetch}
                        data={detailData}
                        isLoadingUsers={isLoadingUsers}
                    />
                </div>
            )}
        </>
    );
});

export default OrganizationRolesList;
