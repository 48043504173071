import { Box, Grid, Theme, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { durationDisplay } from "components/SoundClipEditor/Controls/Player";
import { observer } from "mobx-react";
import moment from "moment";
import React from "react";
import {
    ConversationClassifier,
    Highlight,
} from "services/ConversationService";
import clsx from "clsx";
import ConversationClassifierChipList from "../../Drawer/components/ConversationClassifierChipList";
import ConversationHighlights from "../Highlights/ConversationHighlights";
import { AcxExpandableText } from "components/UI/AcxExpandableText";
import { interactionDateHelper } from "components/UI/AcxDataGrid/ColumnTypes/DateColTypes";

const useStyles = makeStyles((theme: Theme) => ({
    main: {
        color: theme.palette.black.main,
        padding: theme.spacing(1),
    },
    marginBottom: {
        marginBottom: theme.spacing(1),
    },
    separator: {
        paddingLeft: "4px",
        paddingRight: "4px",
    },
    colorGray: {
        color: "gray",
    },
    colorNavy: {
        color: "#2C3F92",
        paddingRight: "10px",
    },
    itemPadding: {
        padding: theme.spacing(1),
    },
    translatedTag: {
        color: "#9CA3AF",
        fontStyle: "italic",
        fontWeight: "bold",
        fontSize: "13px",
    },
}));

type Props = {
    summary: string;
    callDurationMillis: number;
    timestamp: string;
    agentFullName: string;
    conversationClassifiers: ConversationClassifier[];
    highlights: Highlight;
    isChat: boolean;
    selectedClassifierIds?: string[];
    hasTranslation?: boolean;
    chatMessageCount?: number;
};

const ConversationSummary: React.FunctionComponent<Props> = observer(
    (props) => {
        const classes = useStyles();
        const theme = useTheme();

        const rbcClassifierIds =
            props.highlights?.rbcHighlightResults.map(
                (rbc) => rbc.classifierId,
            ) ?? [];

        const filteredConversationClassifiers =
            props.conversationClassifiers.filter(
                (rbc) => !rbcClassifierIds.includes(rbc.classifierId),
            );

        const isTranslated = props.hasTranslation ?? false;
        return (
            <Grid className={classes.main}>
                <Grid
                    item
                    className={clsx(classes.colorGray, classes.itemPadding)}
                >
                    <Box
                        component="span"
                        fontWeight="bold"
                        className={classes.colorNavy}
                    >
                        {props.agentFullName}
                    </Box>
                    {props.isChat && props.chatMessageCount
                        ? `${props.chatMessageCount} ${
                              props.chatMessageCount === 1
                                  ? "Message"
                                  : "Messages"
                          }`
                        : durationDisplay(props.callDurationMillis / 1000)}
                    <span className={classes.separator}>|</span>
                    {moment(interactionDateHelper(props.timestamp)).format(
                        "MM/DD/YYYY",
                    )}
                    {isTranslated && (
                        <>
                            <span className={classes.separator}>|</span>
                            <span className={classes.translatedTag}>
                                Translated
                            </span>
                        </>
                    )}
                </Grid>

                <Grid
                    item
                    className={clsx("pendo-ignore", classes.itemPadding)}
                >
                    <AcxExpandableText
                        pendoIgnore
                        maxTruncatedLines={4}
                        containerClassName={classes.marginBottom}
                    >
                        <Box fontWeight="bold">Conversation Summary:</Box>{" "}
                        {!props.summary ? "N/A" : props.summary}
                    </AcxExpandableText>
                    {props.highlights && (
                        <ConversationHighlights
                            highlights={props.highlights}
                            conversationClassifiers={
                                props.conversationClassifiers
                            }
                            isChat={props.isChat}
                        />
                    )}
                </Grid>
                <Grid item padding={theme.spacing(1)}>
                    <ConversationClassifierChipList
                        conversationClassifiers={
                            filteredConversationClassifiers
                        }
                        selectedClassifierIds={props.selectedClassifierIds}
                    />
                </Grid>
            </Grid>
        );
    },
);

export default ConversationSummary;
