import { action, makeObservable, observable } from "mobx";
import { uuidv4 } from "utils/helpers";

export interface IThreadMessage {
    readonly id: string;
    content: string;
}

export class ThreadMessage implements IThreadMessage {
    readonly id: string;
    @observable content: string;

    constructor(content: string) {
        makeObservable(this);

        this.id = uuidv4();
        this.content = content;
    }

    @action public setContent(content: string) {
        this.content = content;
    }

    @action public appendContent(content: string) {
        this.content += content;
    }
}
