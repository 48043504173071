import { Grid, Switch, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { GridColDef } from "@mui/x-data-grid-pro";
import AcxButton from "components/UI/AcxButton";
import { observer } from "mobx-react-lite";
import React from "react";
import AcxDataGridStore from "../AcxDataGridStore";

const useStyles = makeStyles((theme: Theme) => ({
    rootContainer: {
        overflowX: "hidden",
    },
    bodyWrapper: {
        display: "flex",
        flexDirection: "column",
    },
    footer: {
        display: "flex",
        justifyContent: "space-between",
        padding: "1.25rem",
    },
    toggleContainer: (props: IHideColumnPanel) => ({
        maxHeight: props.maxHeight - 150,
        overflowY: "scroll",
    }),
}));

interface IHideColumnPanel {
    store: AcxDataGridStore;
    maxHeight: number;
}

const HideColumnPanel = observer((props: IHideColumnPanel) => {
    const classes = useStyles(props);
    return (
        <Grid
            container
            spacing={1}
            key="grid-hide-column"
            className={classes.rootContainer}
            style={{ maxHeight: props.maxHeight }}
            sx={{ overflowX: "hidden" }}
        >
            <Grid container sx={{ display: "flex", flexDirection: "column" }}>
                <Grid
                    item
                    sx={{
                        maxHeight: props.maxHeight - 150,
                        overflowY: "scroll",
                    }}
                >
                    {props.store.columns.map((col: GridColDef, i) => (
                        <div key={i}>
                            <Switch
                                sx={{ width: 40 }}
                                onChange={() => {
                                    const cols = props.store.columns.map(
                                        (storeCol) => {
                                            if (storeCol.field === col.field) {
                                                storeCol.hide = !storeCol.hide;
                                            }
                                            return storeCol;
                                        },
                                    );
                                    props.store.onColResizeReorderOrVisChange(
                                        cols,
                                    );
                                }}
                                checked={!col.hide}
                            />
                            {col.headerName}
                        </div>
                    ))}
                </Grid>

                <Grid container className={classes.footer}>
                    <Grid item>
                        <AcxButton
                            onClick={() => {
                                const cols = props.store.columns.map(
                                    (storeCol) => {
                                        storeCol.hide = true;
                                        return storeCol;
                                    },
                                );
                                props.store.onColResizeReorderOrVisChange(cols);
                            }}
                            color="secondary"
                            leftRightSpacing={1}
                        >
                            Hide All
                        </AcxButton>
                    </Grid>

                    <Grid item>
                        <AcxButton
                            onClick={() => {
                                const cols = props.store.columns.map(
                                    (storeCol) => {
                                        storeCol.hide = false;
                                        return storeCol;
                                    },
                                );
                                props.store.onColResizeReorderOrVisChange(cols);
                            }}
                            color="secondary"
                            leftRightSpacing={0}
                        >
                            Show All
                        </AcxButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
});

export default HideColumnPanel;
