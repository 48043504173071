import { Grid, Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import AcxSelectSingle from "components/UI/Select/BaseSelectComponents/AcxSelectSingle";
import { observer } from "mobx-react";
import React from "react";
import ModelDashboardStore from "../Stores/ModelDashboardStore";
import AcxHierarchySelector from "components/UI/AcxHierarchySelector/AcxHierarchySelector";

const useStyles = makeStyles((theme: Theme) => ({
    filterItemSelect: {
        marginLeft: "0.5rem",
        maxWidth: "350px",
    },
    selectorContainer: {
        paddingTop: theme.spacing(1),
    },
}));

const selectCustomStyle = {
    menuPortal: (provided, state) => ({
        ...provided,
        zIndex: 10000,
    }),
};

const callDirectionOptions = [
    { label: "Inbound", value: "inbound" },
    { label: "Outbound", value: "outbound" },
    { label: "Transfer", value: "transfer" },
];

interface IModelDashboardFilterProps {
    store: ModelDashboardStore;
}

const ModelDashboardFilters: React.FC<IModelDashboardFilterProps> = observer(
    ({ store }) => {
        const classes = useStyles();

        return (
            <Grid container>
                <Grid item xs={12} className={classes.filterItemSelect}>
                    <AcxSelectSingle
                        inputLabel="Call Direction"
                        options={callDirectionOptions}
                        id="callDirection"
                        valueField="value"
                        labelField="label"
                        customStyle={selectCustomStyle}
                        defaultValue={store.callDirection}
                        onChange={store.setCallDirection}
                        isClearable={true}
                        fullWidth
                    />
                </Grid>

                <Grid className={classes.selectorContainer}>
                    <AcxHierarchySelector
                        orgId={store.organizationId}
                        displayType="baseOnly"
                        userId={store.authStore._user.profile.sub}
                        setHierarchyIds={store.setHierarchyIds}
                        onSaveUpdateWithHierarchies={store.loadReports}
                        fullHeight
                        initSelectedHierarchyIds={store.hierarchyIds}
                    />
                </Grid>
            </Grid>
        );
    },
);

export default ModelDashboardFilters;
