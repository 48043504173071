import { Grid } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import AcxButton from "components/UI/AcxButton";
import AcxDateRangeInput from "components/UI/Calendar/DateRange/AcxDateRangeInput";
import VerticalDrawerContentTemplate from "components/UI/Drawer/VerticalDrawerContentTemplate";
import { Observer, observer } from "mobx-react";
import { Moment } from "moment";
import React from "react";
import { DateReferenceOption } from "stores/ComponentStores/DatePickerComponentStore";
import { LayoutDrawerStore } from "stores/Layout/LayoutDrawerStore";
import { useStore } from "utils/useStore";
import ModelDashboardStore from "../Stores/ModelDashboardStore";
import ModelDashboardFilters from "./ModelDashboardFilters";

const referenceOptions = [
    DateReferenceOption.InteractionDate,
    DateReferenceOption.ArrivalDate,
];

type IModelDashboardBodyControlsProps = {
    store: ModelDashboardStore;
};

const ModelDashboardBodyControls: React.FC<IModelDashboardBodyControlsProps> =
    observer(({ store }) => {
        const drawerStore = useStore(LayoutDrawerStore);

        const closeDrawer = () => {
            drawerStore.closeAndResetDrawer(true);
            drawerStore.restoreDefaults();
        };

        const openDrawer = () => {
            drawerStore.setOpen(true);
            drawerStore.contentInjector = () => (
                <VerticalDrawerContentTemplate
                    width="400px"
                    title="Optional Filters"
                    content={
                        <div
                            style={{
                                height: "calc(100% - 2rem)",
                            }}
                        >
                            <ModelDashboardFilters store={store} />
                        </div>
                    }
                    onClose={closeDrawer}
                    keepContent={true}
                />
            );
        };

        return (
            <Grid
                container
                item
                xs={10}
                alignItems="flex-end"
                justifyContent="flex-end"
                spacing={1}
            >
                <Observer>
                    {() => (
                        <AcxDateRangeInput
                            defaultStartDate={store.datePickerStore.beginDate}
                            defaultEndDate={store.datePickerStore.endDate}
                            onSelect={(start: Moment, end: Moment) => {
                                store.datePickerStore.setBeginDate(start);
                                store.datePickerStore.setEndDate(end);
                            }}
                            datePickerStore={store.datePickerStore}
                            dateReferenceOptions={referenceOptions}
                            displayDateType
                        />
                    )}
                </Observer>

                <AcxButton
                    leftRightSpacing={1}
                    fullWidth={false}
                    color="secondary"
                    tooltip="Load Reports"
                    onClick={store.loadReports}
                    customRootStyles={{ height: "32px" }}
                >
                    <RefreshIcon color="inherit" />
                </AcxButton>

                <AcxButton
                    leftRightSpacing={0}
                    fullWidth={false}
                    color="secondary"
                    tooltip="Optional Filters"
                    onClick={openDrawer}
                    customRootStyles={{ height: "32px" }}
                >
                    Filters
                </AcxButton>
            </Grid>
        );
    });

export default ModelDashboardBodyControls;
