import { AppBar, Grid, Toolbar, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useRef } from "react";
import {
    WorkflowLoading,
    WorkflowReviewStore,
} from "../Stores/WorkflowReviewStore";
import { useStore } from "utils/useStore";
import theme from "Theme/AppTheme";
import { observer } from "mobx-react";
import { useNavigate } from "react-router";
import NotificationMenuIcon from "components/Notifications/NotificationMenuIcon";
import ProfileMenuIcon from "components/Navigation/ProfileMenuIcon";
import AcxButton from "components/UI/AcxButton";
import { customSecondaryButtonStyle } from "utils/Style";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import { IconButton } from "@material-ui/core";
import ChevronLeftSharpIcon from "@mui/icons-material/ChevronLeftSharp";

const useStyles = makeStyles(() => ({
    appBar: {
        backgroundColor: "white",
        width: "100%",
        position: "relative",
        zIndex: theme.zIndex.drawer + 1,
    },
}));

const WFRAppBar = observer(() => {
    const appBarRef = useRef(null);
    const classes = useStyles();
    const navigate = useNavigate();

    const store = useStore(WorkflowReviewStore);

    const pageLoading = store.getTaskLoadingV2(WorkflowLoading);

    return (
        <AppBar
            ref={appBarRef}
            className={classes.appBar}
            elevation={1}
            position="sticky"
        >
            <Toolbar>
                <Grid container direction="row" justifyContent="space-between">
                    <Grid
                        container
                        item
                        justifyContent="flex-start"
                        alignItems="center"
                        xs={6}
                    >
                        <Grid item style={{ marginRight: theme.spacing(0.5) }}>
                            <IconButton
                                onClick={() => {
                                    navigate(-1);
                                }}
                            >
                                <ChevronLeftSharpIcon />
                            </IconButton>
                        </Grid>
                        <Grid item style={{ marginRight: theme.spacing(0.5) }}>
                            <Typography fontWeight={700}>
                                Workflow for Evaluation
                            </Typography>
                        </Grid>
                        <Grid item>
                            {pageLoading &&
                            !store.workflowReviewerInfo?.workflowInfo
                                .evaluationQbId ? (
                                <AcxLoadingIndicator
                                    color={"secondary"}
                                    alternate={"PuffLoader"}
                                    size={25}
                                />
                            ) : (
                                <Typography fontWeight={700}>
                                    {`#${
                                        store.workflowReviewerInfo?.workflowInfo
                                            .evaluationQbId ?? ""
                                    }`}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                    <Grid container item justifyContent="flex-end" xs={6}>
                        <Grid item>
                            <AcxButton
                                rootStyle={customSecondaryButtonStyle}
                                onClick={() => {
                                    navigate(
                                        `/evaluation/${store.orgId}/${store.evalId}`,
                                    );
                                }}
                            >
                                View Evaluation
                            </AcxButton>
                        </Grid>
                        <Grid item>
                            <NotificationMenuIcon
                            // drawerOffsetPx={appBarHeight}
                            />
                        </Grid>

                        <Grid item>
                            <ProfileMenuIcon
                                currLocation={"mainApp"}
                                organizationName={
                                    store.authStore.orgStore
                                        .selectedOrganization?.name ?? ""
                                }
                                customRootStyles={{
                                    marginLeft: "0.5rem",
                                    marginRight: "0.25rem",
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
});

export default WFRAppBar;
