import { Grid, Theme, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React, { CSSProperties, FunctionComponent } from "react";
import SmartPredictSvg from "SvgIcons/SmartPredictSvg";

export interface AcxStatusStickerProps {
    title?: string;
    customRootStyles?: CSSProperties;
}

type Props = AcxStatusStickerProps;

const useStyles = makeStyles((theme: Theme) => ({
    wrapper: (props: AcxStatusStickerProps) => ({
        textAlign: "center",
        padding: "0.25rem",
        borderRadius: "2px",
    }),
    bodyText: {
        color: theme.palette.info.dark,
        fontWeight: "bold",
        margin: "0 0.5rem",
    },
}));

const AcxStatusSticker: FunctionComponent<Props> = ({
    title,
    customRootStyles,
}) => {
    const classes = useStyles({ title });

    return (
        <Grid
            container
            alignContent="center"
            alignItems="center"
            className={classes.wrapper}
            style={customRootStyles}
        >
            <SmartPredictSvg />

            <Typography variant="caption" className={classes.bodyText}>
                {title ?? "Smart Predict"}
            </Typography>
        </Grid>
    );
};

export default AcxStatusSticker;
