import React, { CSSProperties, useState } from "react";
import { Button, Theme } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

export interface AcxTruncateProps {
    lineClamp: "none" | number;
    content: any;
    showMoreTextInactive?: string;
    showMoreTextActive?: string;
    showMoreRootStyles?: CSSProperties;
    contentContainerRootStyles?: CSSProperties; // Set a line clamp of 1 and a defined width to implement single line ellipsis (if a line clamp of 1 does not do what you need)
}

const useStyles = makeStyles((theme: Theme) => ({
    hidden: {
        display: "-webkit-box",
        WebkitLineClamp: (props: AcxTruncateProps) => props.lineClamp,
        overflow: "hidden",
        WebkitBoxOrient: "vertical",
    },
    showMoreButton: {
        textTransform: "none",
        color: theme.palette.blue.main,
        marginTop: "0.25rem",
        padding: "0px",
        minWidth: "max-content",
    },
}));

const AcxTruncate = (props: AcxTruncateProps) => {
    const classes = useStyles(props);
    const [isHidden, setIsHidden] = useState(true);

    return (
        <>
            <div
                style={props?.contentContainerRootStyles}
                className={isHidden ? classes.hidden : ""}
            >
                {props.content}
            </div>
            <Button
                className={classes.showMoreButton}
                size="small"
                onClick={() => setIsHidden(!isHidden)}
                style={props?.showMoreRootStyles}
            >
                {isHidden
                    ? props.showMoreTextInactive ?? "More..."
                    : props.showMoreTextActive ?? "...Less"}
            </Button>
        </>
    );
};

export default AcxTruncate;
