import {
    action,
    observable,
    runInAction,
    computed,
    makeObservable,
} from "mobx";
import Classifier, { ClassifierUtils } from "models/ClassifierModel";
import { createContext } from "react";
import ClassifierService from "services/ClassifierService";
import { ClassifierDisplayType, ClassifierKeywordType } from "./types";
import { ITranscriptionSegment } from "services/TranscriptionService";

class TranscriptionVisualStore {
    @observable currentSeekPosition = 0;

    @observable points: number[] = [];

    @observable classifiers: Classifier[] = [];

    @observable classifiersLoading = false;

    @observable expandedClassifier: number | false = false;

    @observable transcriptionLoading = false;

    @observable transcriptionSegments: ITranscriptionSegment[] = [];

    constructor() {
        makeObservable(this);
    }

    @computed
    get selectedKeywords(): string[] {
        if (this.expandedClassifier === false) return [];

        return ClassifierUtils.searchStringToTerms(
            this.classifiers[this.expandedClassifier]
                ?.classifierImplementations?.[0].queryString,
        );
    }

    @computed
    get displayClassifiers(): ClassifierDisplayType[] {
        let result: ClassifierDisplayType[] = [];

        const txText = this.transcriptionSegments.map((x) => x.text).join(" ");
        for (const classifier of this.classifiers) {
            let classifierHits = 0;
            let keywords: ClassifierKeywordType[] = [];
            const impl = classifier.classifierImplementations?.[0];
            if (impl) {
                keywords = ClassifierUtils.searchStringToTerms(
                    impl.queryString,
                ).map((kw) => {
                    const search = `${kw}`;
                    const kwHits =
                        txText.match(new RegExp(search, "gi"))?.length || 0;
                    classifierHits += kwHits;
                    return {
                        name: kw,
                        hits: kwHits,
                    };
                });
            }

            result.push({
                name: classifier.name,
                hits: classifierHits,
                keywords: keywords,
            });
        }
        return result;
    }

    @action
    handleExpansionChange =
        (index: number) =>
        (_event: React.ChangeEvent<{}>, isExpanded: boolean) => {
            this.expandedClassifier = isExpanded ? index : false;
        };

    @action
    loadClassifiers = async () => {
        this.classifiersLoading = true;
        const classifiers =
            await new ClassifierService().loadClassifiersForCurrentUser();
        runInAction(() => {
            this.classifiers = classifiers;
            this.classifiersLoading = false;
        });
    };
}
export default createContext(new TranscriptionVisualStore());
