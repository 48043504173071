import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { DialogActions, Grid, IconButton, Typography } from "@mui/material";
import { createStyles } from "@mui/styles";
import { SamplingTargetTimePeriod } from "components/Admin/Organizations/types/SamplingTargetTimePeriod";
import { SamplingTargetUnitOfMeasure } from "components/Admin/Organizations/types/SamplingTargetUnitOfMeasure";
import AcxButton from "components/UI/AcxButton";
import AcxMainTextField from "components/UI/AcxMainTextField";
import AcxTable from "components/UI/AcxTable/AcxTable";
import AcxSelectSingle from "components/UI/Select/BaseSelectComponents/AcxSelectSingle";
import { observer } from "mobx-react";
import { ApplicationUser } from "models/Permission/ApplicationUser";
import React from "react";
import { AuthStore } from "stores/AuthStore";
import useStyles from "Styles/Styles";
import {
    GET_SAMPLING_HISTORY,
    OrganizationServiceHierarchyStore,
} from "../stores/OrganizationServiceHierarchyStore";
import {
    tableColumns,
    timePeriodOptions,
    unitOptions,
} from "./OsmSamplingTargetUtils";

const styles = () =>
    createStyles({
        actions: {
            display: "flex",
            justifyContent: "flex-start",
        },
        titleContainer: {
            padding: "1rem 0",
        },
        submitContainer: {
            marginTop: "1rem",
        },
        tableContainer: {
            minWidth: "450px",
        },
    });

interface OsmSamplingTargetLayoutProps {
    osmStore: OrganizationServiceHierarchyStore;
    authStore: AuthStore;
}

const OsmSamplingTargetLayout: React.FC<
    React.PropsWithChildren<
        React.PropsWithChildren<OsmSamplingTargetLayoutProps>
    >
> = observer(({ osmStore, authStore }) => {
    const classes = useStyles(styles);
    const samplingTarget = osmStore.activeTier?.samplingTarget;

    const hasEditPerms = authStore.canUserEdit("Service Hierarchy");

    const formDataIsValid =
        osmStore.activeTier && samplingTarget
            ? (samplingTarget.value ?? 0) < 1
                ? false
                : !samplingTarget.unitOfMeasure
                ? false
                : !samplingTarget.timePeriod
                ? false
                : !samplingTarget.monitoredByUserId
                ? false
                : true
            : false;

    const handleOnSubmit = () => {
        if (osmStore.activeTier && osmStore.activeTier.id) {
            // Our React Hook will trigger off of this and fire off an update to the active tier.
            osmStore.setEndpointFired(true, "Update");
        }
    };

    return (
        <Grid container>
            <Grid item xs={12} className={classes.titleContainer}>
                <Typography component="span" variant="h1">
                    Sampling Target: {osmStore.activeTier?.name ?? ""}
                </Typography>
            </Grid>

            <Grid container spacing={1} item xs={12}>
                <Grid item>
                    <AcxMainTextField
                        isDisabled={!hasEditPerms}
                        autofocus
                        showAllErrors
                        onChange={(e) =>
                            osmStore.setActiveTierAttribute(
                                "samplingTarget.value",
                                parseInt(e.target.value ?? ""),
                            )
                        }
                        id="value"
                        labelText="Value"
                        placeholderText="Value"
                        value={samplingTarget?.value}
                        required
                        containerStyle={{
                            width: "60px",
                        }}
                        textareaRootStyles={{
                            marginRight: 0,
                            height: "32px",
                        }}
                        type="number"
                    />
                </Grid>

                <Grid item>
                    <AcxSelectSingle
                        isDisabled={!hasEditPerms}
                        onChange={(e) =>
                            osmStore.setActiveTierAttribute(
                                "samplingTarget.unitOfMeasure",
                                parseInt(e.id ?? ""),
                            )
                        }
                        id="unit"
                        labelField="value"
                        placeholder="Unit"
                        valueField={"id"}
                        defaultValue={{
                            id: samplingTarget?.unitOfMeasure?.toString(),
                            value: samplingTarget?.unitOfMeasure
                                ? SamplingTargetUnitOfMeasure[
                                      samplingTarget.unitOfMeasure
                                  ]
                                : "",
                        }}
                        inputLabel="Unit"
                        required
                        options={unitOptions}
                    />
                </Grid>

                <Grid item>
                    <AcxSelectSingle
                        isDisabled={!hasEditPerms}
                        onChange={(e) => {
                            osmStore.setActiveTierAttribute(
                                "samplingTarget.timePeriod",
                                parseInt(e.id ?? ""),
                            );
                        }}
                        id="period"
                        labelField="value"
                        placeholder="Period"
                        valueField={"id"}
                        defaultValue={{
                            id: samplingTarget?.timePeriod?.toString(),
                            value: samplingTarget?.timePeriod
                                ? SamplingTargetTimePeriod[
                                      samplingTarget.timePeriod
                                  ]
                                : "",
                        }}
                        inputLabel="Period"
                        required
                        options={timePeriodOptions}
                    />
                </Grid>

                <Grid item xs={4}>
                    <AcxSelectSingle
                        isDisabled={!hasEditPerms}
                        onChange={(e) => {
                            osmStore.setActiveTierAttribute(
                                "samplingTarget.monitoredByUserId",
                                e.id ?? "",
                            );
                        }}
                        required
                        id="monitor"
                        labelField="userName"
                        placeholder="Monitor"
                        valueField="id"
                        defaultValue={
                            {
                                id: samplingTarget?.monitoredByUserId,
                                userName: osmStore.orgUsers.find(
                                    (user) =>
                                        user.id ===
                                        samplingTarget?.monitoredByUserId,
                                )?.userName,
                            } as ApplicationUser
                        }
                        inputLabel="Monitored By"
                        options={osmStore.orgUsers}
                    />
                </Grid>
            </Grid>

            <Grid item xs={12} className={classes.submitContainer}>
                <AcxButton
                    loading={osmStore.isLoading}
                    leftRightSpacing={0}
                    onClick={handleOnSubmit}
                    fullWidth={false}
                    buttonDisabled={
                        !samplingTarget?.value ||
                        !formDataIsValid ||
                        osmStore.isLoading ||
                        !hasEditPerms
                    }
                    containerStyle={{ width: "min-content" }}
                    color="secondary"
                >
                    Submit
                </AcxButton>
            </Grid>

            <Grid item xs={12} className={classes.titleContainer}>
                <Typography
                    component="span"
                    variant="h1"
                    className={classes.title}
                >
                    History
                </Typography>
            </Grid>

            <Grid item xs={12} className={classes.tableContainer}>
                <AcxTable
                    cellDense
                    rows={osmStore.samplingHistoryRows}
                    columns={tableColumns}
                    isLoading={osmStore.getTaskLoading(GET_SAMPLING_HISTORY)}
                    skeletonRows={5}
                />

                <DialogActions className={classes.actions}>
                    <IconButton
                        onClick={() => {
                            osmStore.setSamplingHistoryPageNumber(
                                osmStore.samplingHistoryPageNumber > 1
                                    ? osmStore.samplingHistoryPageNumber - 1
                                    : 1,
                            );
                        }}
                        disabled={osmStore.samplingHistoryPageNumber === 1}
                        color="secondary"
                        size="large"
                    >
                        <ArrowLeftIcon />
                    </IconButton>

                    <Grid>Page: {osmStore.samplingHistoryPageNumber}</Grid>

                    <IconButton
                        onClick={() => {
                            osmStore.setSamplingHistoryPageNumber(
                                osmStore.samplingHistoryPageNumber + 1,
                            );
                        }}
                        disabled={osmStore.samplingHistoryRows.length < 10}
                        color="secondary"
                        size="large"
                    >
                        <ArrowRightIcon />
                    </IconButton>
                </DialogActions>
            </Grid>
        </Grid>
    );
});

export default OsmSamplingTargetLayout;
