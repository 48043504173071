import { Card } from "@mui/material";
import InlineAudioPlayer from "components/TestComponents/NewAudioEditor/InlineAudioPlayer";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import AcxToast from "components/UI/AcxToast";
import React from "react";

interface OwnProps {
    audioLocation: string;
    audioBlob?: Blob;
    points?: number[];
    isLoading: boolean;
    errorMessage?: string;
    onUpdateCurrentPlayingTime: (time: number) => void;
}

export default function TranscriptionVisualAudioView(props: OwnProps) {
    function renderContent() {
        if (props.isLoading) {
            return (
                <AcxLoadingIndicator
                    color="secondary"
                    alternate="PuffLoader"
                    size={50}
                />
            );
        }

        if (props.errorMessage || !props.audioLocation || !props.audioBlob) {
            const message = props.errorMessage ?? "Audio was invalid";
            return (
                <AcxToast
                    severity="error"
                    title="Error loading audio text"
                    message={message}
                />
            );
        }

        return (
            <InlineAudioPlayer
                audioType="audio/mp3"
                audioBlob={props.audioBlob}
                audioLocation={props.audioLocation}
                onUpdateCurrentPlayingTime={props.onUpdateCurrentPlayingTime}
                points={props.points}
            />
        );
    }

    return <Card>{renderContent()}</Card>;
}
