import { Button, Grid, Theme, Typography } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import AddSharpIcon from "@mui/icons-material/AddSharp";
import clsx from "clsx";
import { Tag } from "components/Admin/Organizations/types/Module.type";
import AcxInputLabel from "components/UI/AcxInputLabel";
import React, { useEffect, useState } from "react";
import { FormFieldErrors } from "shared/types/FormErrors.type";
import useStyles from "Styles/Styles";
import OrganizationModuleDetailsSelection from "./OrganizationModuleDetailsSelection";

const styles = (theme: Theme) => {
    return createStyles({
        addButton: {
            textTransform: "none",
            fontWeight: "bold",
            marginTop: "15px",
        },
        helperText: {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            fontSize: "12px",
            lineHeight: "16px",
        },
        helperTextError: {
            color: `${theme.palette.error.main} !important`,
        },
        table: {
            marginLeft: "-0.5rem",
        },
        wrapper: {
            marginTop: "1.75rem",
        },
    });
};

type Props = {
    value: any[];
    onChange: (val: Tag[]) => void;
    tagsLabel: string;
    error?: boolean;
    helperText?: string;
    errors?: FormFieldErrors;
    questionIndex: number;
    addTagsToDelete: (index: string) => void;
    errorPath: string;
    showInactive: boolean;
    isNew?: boolean;
    isScored?: boolean;
};

const OrganizationModuleDetailsSelections: React.FC<Props> = ({
    value,
    onChange,
    tagsLabel,
    error,
    helperText,
    errors,
    questionIndex,
    addTagsToDelete,
    errorPath = "",
    showInactive,
    isNew,
    isScored,
}) => {
    const [tags, setTags] = useState(value);

    useEffect(() => {
        onChange(tags);
    }, [onChange, tags]);

    const classes = useStyles(styles);

    const onChangeRow = (index: number, prop: string, val: string | number) => {
        setTags((prev) => {
            const newArr = [...prev];
            newArr[index] = {
                ...newArr[index],
                [prop]: val,
            };
            return newArr;
        });
    };

    const addRow = () => {
        setTags((prev) => {
            const newArr = [...prev];
            newArr.push({
                value: "",
                scoredValue: null,
                isActive: true,
                isNew: true,
            });
            return newArr;
        });
    };

    const removeRow = (removeIndex: number, id: string) => {
        setTags((prev) => {
            const newArr = tags.filter((row, i) => i !== removeIndex);
            return newArr;
        });

        if (id) {
            addTagsToDelete(id);
        }
    };

    return (
        <Grid item xs={12} key={questionIndex} className={classes.wrapper}>
            <Typography variant="h2" style={{ paddingBottom: "8px" }}>
                Tags
            </Typography>
            <Grid container spacing={1}>
                <Grid container justifyContent="center">
                    {!!value?.length &&
                        (showInactive
                            ? true
                            : value.some((item) => item.isActive)) && (
                            <table className={classes.table}>
                                <thead>
                                    <tr>
                                        <th>
                                            <Typography
                                                variant="h6"
                                                align="center"
                                            >
                                                {isNew === true
                                                    ? "Remove"
                                                    : "Active"}
                                            </Typography>
                                        </th>
                                        <th>
                                            <Typography
                                                variant="h6"
                                                align="center"
                                            >
                                                {tagsLabel}
                                            </Typography>
                                        </th>

                                        {isScored && (
                                            <th>
                                                <Typography
                                                    variant="h6"
                                                    align="center"
                                                >
                                                    Score
                                                </Typography>
                                            </th>
                                        )}

                                        <th>
                                            <Typography
                                                variant="h6"
                                                align="center"
                                            >
                                                Order
                                            </Typography>
                                        </th>
                                        <th>
                                            <Typography
                                                variant="h6"
                                                align="center"
                                            >
                                                Sentiment
                                            </Typography>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!!value?.length &&
                                        value.map((row, i) =>
                                            showInactive ? (
                                                <tr key={i}>
                                                    <OrganizationModuleDetailsSelection
                                                        value={row}
                                                        onChange={onChangeRow.bind(
                                                            null,
                                                            i,
                                                        )}
                                                        addRow={
                                                            value.length ===
                                                            i + 1
                                                                ? addRow
                                                                : undefined
                                                        }
                                                        removeRow={removeRow.bind(
                                                            null,
                                                            i,
                                                            row.id,
                                                        )}
                                                        errors={errors}
                                                        questionIndex={
                                                            questionIndex
                                                        }
                                                        tagIndex={i}
                                                        errorPath={errorPath}
                                                        showInactive={
                                                            showInactive
                                                        }
                                                        isNew={isNew}
                                                        isScored={isScored}
                                                    />
                                                </tr>
                                            ) : (
                                                row.isActive && (
                                                    <tr key={i}>
                                                        <OrganizationModuleDetailsSelection
                                                            value={row}
                                                            onChange={onChangeRow.bind(
                                                                null,
                                                                i,
                                                            )}
                                                            addRow={
                                                                value.length ===
                                                                i + 1
                                                                    ? addRow
                                                                    : undefined
                                                            }
                                                            removeRow={removeRow.bind(
                                                                null,
                                                                i,
                                                                row.id,
                                                            )}
                                                            errors={errors}
                                                            questionIndex={
                                                                questionIndex
                                                            }
                                                            tagIndex={i}
                                                            errorPath={
                                                                errorPath
                                                            }
                                                            showInactive={
                                                                showInactive
                                                            }
                                                            isNew={isNew}
                                                            isScored={isScored}
                                                        />
                                                    </tr>
                                                )
                                            ),
                                        )}
                                </tbody>
                            </table>
                        )}
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={9}></Grid>
                    <Grid
                        item
                        xs={3}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                        }}
                    >
                        <Button
                            color="secondary"
                            startIcon={<AddSharpIcon />}
                            className={classes.addButton}
                            onClick={addRow}
                        >
                            Add Tag
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            {helperText !== undefined && (
                <AcxInputLabel
                    id={"AcxText-BottomLabel"}
                    className={clsx({
                        [classes.helperText]: true,
                        [classes.helperTextError]: error,
                    })}
                    showAllErrors={true}
                >
                    {helperText}
                </AcxInputLabel>
            )}
        </Grid>
    );
};

export default OrganizationModuleDetailsSelections;
