import { Grid, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { observer } from "mobx-react";
import React from "react";
import { IAcxHierarchyComponentProps } from "./AcxHierarchySelector";
import AcxButton from "../AcxButton";

const useStyles = makeStyles((theme: Theme) => ({
    bottomContentContainer: {
        padding: "24px",
        minHeight: "78px",
    },
    clearBtnContainer: {
        paddingLeft: "25%",
    },
    clearBtn: {
        borderRadius: "4px",
        border: "1px solid #E4E4E7",
        backgroundColor: "#FFF",
        color: "#3F3F46!important",
        width: "36px",
        margin: "0px",
        "&:hover": {
            backgroundColor: "#F4F4F5!important",
        },
    },
    applyBtn: {
        borderRadius: "4px",
        backgroundColor: "#3564D5",
        color: "#FFFFFF",
        width: "153px",
        margin: "0px 0px 0px 0px!important",
        "&:hover": {
            backgroundColor: "rgba(53, 100, 213, 0.5)!important",
        },
    },
}));

const HierarchySelectorFooter: React.FC<IAcxHierarchyComponentProps> = observer(
    (props: IAcxHierarchyComponentProps) => {
        const classes = useStyles();

        return (
            <Grid
                container
                justifyContent="flex-end"
                className={classes.bottomContentContainer}
            >
                <Grid
                    item
                    container
                    xs={12}
                    wrap="nowrap"
                    justifyContent="flex-end"
                    className={classes.clearBtnContainer}
                >
                    <AcxButton
                        tooltip="Reset selection"
                        buttonClass={classes.clearBtn}
                        onClick={() => {
                            props.treeStore.clearSelectedBranchIds();
                        }}
                        styles={{
                            borderRadius: "4px",
                            border: "1px solid #E4E4E7",
                            backgroundColor: "#FFF",
                            color: "#3F3F46!important",
                            width: "36px",
                            margin: "0px",
                        }}
                    >
                        Clear
                    </AcxButton>
                    <AcxButton
                        tooltip={"Save to apply"}
                        styles={{
                            borderRadius: "4px",
                            backgroundColor: "#3564D5",
                            color: "#FFFFFF",
                            width: "153px",
                            margin: "0px 0px 0px 0px!important",
                        }}
                        color="secondary"
                        onClick={props.onApplyClick}
                    >
                        {props?.applyBtnText ?? "Apply Hierarchies"}
                    </AcxButton>
                </Grid>
            </Grid>
        );
    },
);

export default HierarchySelectorFooter;
