import {
    action,
    computed,
    makeObservable,
    observable,
    reaction,
    runInAction,
} from "mobx";
import { computedFn, queueProcessor } from "mobx-utils";
import { NGramPayload, ScoredNGram } from "models/NGram";
import moment, { Moment } from "moment";
import { Word } from "react-wordcloud";
import {
    ClassifierLineChartModel,
    ClassifierReportService,
} from "services/ClassifierReportService";
import type { OverviewStats } from "services/ClassifierReportService";
import {
    DatePickerComponentStore,
    DateReferenceOption,
} from "stores/ComponentStores/DatePickerComponentStore";
import { OrgSelectorComponentStore } from "stores/ComponentStores/OrgSelectorComponentStore";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";
import TaskQueue from "utils/TaskQueue";
import { ClassifierType } from "../../../models/ClassifierModel";
import type Classifier from "../../../models/ClassifierModel";
import ClassifierService from "../../../services/ClassifierService";
import { OrganizationService } from "../../../services/OrganizationService";
import { AsyncTaskStatus, BaseStore } from "../../../stores/BaseStore";
import { LuceneIndexSearchStore } from "../LuceneIndexSearch/LuceneIndexSearchStore";

export const LoadPublishedClassifiersTask = "Load Published Classifiers";
export const loadOverviewStatsTask = "Load Overview Stats";

export type DataSelection = "query" | "normalized";

export enum CardStatus {
    Waiting,
    Loading,
    Complete,
}

const reportService = new ClassifierReportService();
const classifierService = new ClassifierService();

export function getClassifierStatsTaskName(classifierId: string) {
    return `Load Classifier Stats: ${classifierId}`;
}

export function getClassifierNgramsTaskName(classifierId: string) {
    return `Load Classifier NGrams: ${classifierId}`;
}

export function getClassifierQueryTaskName(classifierId: string) {
    return `Load Classifier Query: ${classifierId}`;
}

export function getClassifierBubbledNGramsTaskName(classifierId: string) {
    return `Load Classifier Bubbled NGrams: ${classifierId}`;
}

@AcxStore
export default class ClassifierReportStore extends BaseStore {
    private orgService: OrganizationService = new OrganizationService();
    datePickerStore = new DatePickerComponentStore(
        undefined,
        undefined,
        DateReferenceOption.InteractionDate,
    );

    @observable
    orgSelectStore = new OrgSelectorComponentStore();

    searchStore: LuceneIndexSearchStore;

    @observable dataSelection: Map<string, DataSelection> = new Map<
        string,
        DataSelection
    >();

    @observable classifierStats: Map<string, ClassifierLineChartModel> =
        new Map<string, ClassifierLineChartModel>();
    @observable classifierNGrams: Map<string, NGramPayload> = new Map<
        string,
        NGramPayload
    >();
    @observable classifierBubbledNGrams: Map<string, ScoredNGram[]> = new Map<
        string,
        ScoredNGram[]
    >();
    @observable classifierBubbledNGramsLoading: Map<string, boolean> = new Map<
        string,
        boolean
    >();
    @observable classifierQueries: Map<string, string> = new Map<
        string,
        string
    >();
    @observable classifierStatuses: Map<string, CardStatus> = new Map<
        string,
        CardStatus
    >();
    @observable.shallow publishedClassifiers: Array<Classifier> = [];
    @observable overviewStats: OverviewStats;
    @observable selectedClassifiers: Array<Classifier> = observable.array();
    @observable.shallow queuedStatsTasks: Array<Classifier> = [];
    @observable taskQueue: TaskQueue;

    @observable.shallow hierarchyLevels?: Array<{ label: string; id: string }>;
    @observable selectedHierarchy: { label: string; id: string } | undefined;

    @observable searchResultsDialogOpen = false;

    constructor(private rootStore: IRootStore) {
        super("ClassifierReportStore");
        makeObservable(this);
        this.taskQueue = new TaskQueue(1);
        this.searchStore = new LuceneIndexSearchStore(rootStore);

        reaction(
            (r) => ({
                orgId: this.orgSelectStore.orgId,
            }),
            (args) => {
                if (!args.orgId) {
                    return;
                }

                this.selectedHierarchy = undefined;
                this.hierarchyLevels = undefined;
                this.publishedClassifiers = [];
                this.selectedClassifiers.splice(
                    0,
                    this.selectedClassifiers.length,
                );

                this.classifierStats.clear();
                this.classifierNGrams.clear();
                this.classifierQueries.clear();
                this.classifierBubbledNGrams.clear();

                this.setupAsyncTask("Load Hierarchy Levels", () =>
                    this.loadHierarchyLevels(args.orgId as string),
                );

                this.setupAsyncTask(LoadPublishedClassifiersTask, () =>
                    this.loadPublishedClassifiers(args.orgId as string),
                );
            },
        );

        reaction(
            () => {
                return {
                    orgid: this.orgSelectStore.orgId,
                    arg1: this.datePickerStore.beginDate,
                    arg2: this.datePickerStore.endDate,
                    arg3: this.datePickerStore.referenceOption,
                    hierarchy: this.selectedHierarchy,
                };
            },
            (args) => {
                if (!this.orgSelectStore.orgId) {
                    return;
                }

                if (
                    this.datePickerStore.beginDate.isAfter(
                        this.datePickerStore.endDate,
                    )
                ) {
                    return;
                }
                if (
                    this.datePickerStore.endDate.isBefore(
                        this.datePickerStore.beginDate,
                    )
                ) {
                    return;
                }

                this.setupAsyncTask(loadOverviewStatsTask, () =>
                    this.getOverviewStats(
                        this.orgSelectStore.orgId!,
                        this.datePickerStore.beginDate,
                        this.datePickerStore.endDate,
                        this.datePickerStore.referenceOption,
                        args.hierarchy?.id,
                    ),
                );

                this.selectedClassifiers.forEach((value) => {
                    this.addClassifierToReportList(value);
                });
            },
        );

        queueProcessor(this.queuedStatsTasks, (item) => {
            const serviceHierarchyId = this.selectedHierarchy?.id;

            if (
                this.selectedClassifiers.findIndex(
                    (value) => value.id === item.id,
                ) < 0
            ) {
                this.selectedClassifiers.push(item);
            }

            this.clearError(item.id);
            this.clearError(getClassifierStatsTaskName(item.id));
            this.clearError(getClassifierNgramsTaskName(item.id));
            this.clearError(getClassifierQueryTaskName(item.id));
            this.clearError(getClassifierBubbledNGramsTaskName(item.id));

            this.classifierStatuses.set(item.id, CardStatus.Waiting);

            this.setupAsyncTask(item.id, async () => {
                const unifiedTask = async () => {
                    this.classifierStatuses.set(item.id, CardStatus.Loading);
                    const statsPromise = this.setupAsyncTask(
                        getClassifierStatsTaskName(item.id),
                        async () =>
                            await this.loadClassifierStats(
                                this.orgSelectStore.orgId!,
                                item.id,
                                this.datePickerStore.beginDate,
                                this.datePickerStore.endDate,
                                this.datePickerStore.referenceOption,
                                serviceHierarchyId,
                            ),
                    );

                    // Temporarily commented out
                    // const ngramsPromise = this.setupAsyncTask(
                    //     getClassifierNgramsTaskName(item.id),
                    //     async () =>
                    //         await this.loadClassifierNGrams(
                    //             item.classifierTypeName,
                    //             item.id,
                    //             this.orgSelectStore.orgId!,
                    //             this.datePickerStore.beginDate,
                    //             this.datePickerStore.endDate,
                    //             this.datePickerStore.referenceOption,
                    //             serviceHierarchyId,
                    //         ),
                    // );

                    // const queryPromise = this.setupAsyncTask(
                    //     getClassifierQueryTaskName(item.id),
                    //     async () => await this.loadClassifierQuery(item.id),
                    // );

                    // const [query, ngrams, stats] = await Promise.allSettled([
                    //     queryPromise,
                    //     ngramsPromise,
                    //     statsPromise,
                    // ]);

                    // runInAction(() => {
                    //     try {
                    //         if (
                    //             query.status === "fulfilled" &&
                    //             query.value &&
                    //             query.value !== AsyncTaskStatus.Error
                    //         ) {
                    //             this.classifierQueries.set(
                    //                 item.id,
                    //                 query.value as string,
                    //             );
                    //         }
                    //         if (
                    //             ngrams.status === "fulfilled" &&
                    //             ngrams.value &&
                    //             ngrams.value !== AsyncTaskStatus.Error
                    //         ) {
                    //             this.classifierNGrams.set(
                    //                 item.id,
                    //                 ngrams.value as NGramPayload,
                    //             );
                    //         }
                    //         if (
                    //             stats.status === "fulfilled" &&
                    //             stats.value &&
                    //             stats.value !== AsyncTaskStatus.Error
                    //         ) {
                    //             this.classifierStats.set(
                    //                 item.id,
                    //                 stats.value as ClassifierLineChartModel,
                    //             );
                    //         }
                    //         this.dataSelection.set(item.id, "query");
                    //     } finally {
                    //         this.classifierStatuses.set(
                    //             item.id,
                    //             CardStatus.Complete,
                    //         );
                    //     }
                    // });

                    const [stats] = await Promise.allSettled([statsPromise]);

                    runInAction(() => {
                        try {
                            if (
                                stats.status === "fulfilled" &&
                                stats.value &&
                                stats.value !== AsyncTaskStatus.Error
                            ) {
                                this.classifierStats.set(
                                    item.id,
                                    stats.value as ClassifierLineChartModel,
                                );
                            }
                            this.dataSelection.set(item.id, "query");
                        } finally {
                            this.classifierStatuses.set(
                                item.id,
                                CardStatus.Complete,
                            );
                        }
                    });
                };
                this.taskQueue.enqueue(unifiedTask);
            });

            // this.loadClassifierBubbledNGrams(item.id, this.orgSelectStore.orgId!, this.datePickerStore.beginDate,
            //     this.datePickerStore.endDate,
            //     this.datePickerStore.referenceOption);
        });
    }

    @action
    runSearch = (word: Word) => {
        if (
            this.datePickerStore.referenceOption ===
            DateReferenceOption.ArrivalDate
        ) {
            this.searchStore.doSearch(
                this.orgSelectStore.orgId,
                word.text,
                this.datePickerStore.beginDate.toISOString(),
                this.datePickerStore.endDate.toISOString(),
            );
        } else {
            this.searchStore.doSearch(this.orgSelectStore.orgId, word.text);
        }

        this.openSearchResultsDialog();
    };

    @computed get overviewHours() {
        return this.overviewStats?.hours;
    }

    @computed get overviewCount() {
        return this.overviewStats?.count;
    }

    @computed get overviewHoursPerDay() {
        const numDays =
            this.datePickerStore.endDate.diff(
                this.datePickerStore.beginDate,
                "days",
            ) + 1; // add 1 to include
        // start date as well
        return Math.round(this.overviewCount / numDays);
    }

    @computed get overviewMinutesPerCall() {
        return Math.round((this.overviewHours / this.overviewCount) * 60 || 0);
    }

    getCardStatus(classifierId: string) {
        return this.classifierStatuses.get(classifierId);
    }

    getSelectedWordCloudData(classifierId: string) {
        if (this.dataSelection.get(classifierId) === "query") {
            return this.nGramDataForClassifier(classifierId)?.queryNGrams || [];
        } else {
            return this.classifierBubbledNGrams.get(classifierId) || [];
        }
    }

    @action.bound
    openSearchResultsDialog() {
        this.searchResultsDialogOpen = true;
    }

    @action.bound
    closeSearchResultsDialog() {
        this.searchResultsDialogOpen = false;
    }

    @action
    getOverviewStats = async (
        orgId: string,
        beginDate: moment.Moment,
        endDate: moment.Moment,
        dateReference: DateReferenceOption,
        serviceHierarchyId?: string,
    ) => {
        this.overviewStats = await reportService.GetOverviewStats(
            orgId,
            beginDate,
            endDate,
            dateReference,
            serviceHierarchyId,
        );
    };

    @action
    removeClassifierReportCard = (classifierId: string) => {
        const indx = this.selectedClassifiers.findIndex(
            (value) => value.id === classifierId,
        );
        if (indx >= 0) {
            this.selectedClassifiers.splice(indx, 1);
            this.classifierStats.delete(classifierId);
            this.classifierNGrams.delete(classifierId);
        }
    };

    reportCardTitle = computedFn(function (
        this: ClassifierReportStore,
        classifierId: string,
    ) {
        const indx = this.publishedClassifiers.findIndex(
            (value) => value.id === classifierId,
        );
        if (indx >= 0) {
            return this.publishedClassifiers[indx].name;
        }
        return null;
    });

    @action
    addClassifierToReportList(classifier: Classifier) {
        this.queuedStatsTasks.push(classifier);
    }

    @action
    setDataSelection = (classifierId: string, newValue: DataSelection) => {
        this.dataSelection.set(classifierId, newValue);
    };

    statsForClassifier = computedFn(function (
        this: ClassifierReportStore,
        classifierId: string,
    ) {
        return this.classifierStats.get(classifierId)?.graphData;
    });
    totalAppearancesForClassifier = computedFn(function (
        this: ClassifierReportStore,
        classifierId: string,
    ) {
        return this.classifierStats.get(classifierId)?.totalAppearances ?? 0;
    });
    percentInteractionsForClassifier = computedFn(function (
        this: ClassifierReportStore,
        classifierId: string,
    ) {
        return (
            (this.classifierStats.get(classifierId)?.percentInteractions ?? 0) *
            100
        );
    });

    nGramDataForClassifier = computedFn(function (
        this: ClassifierReportStore,
        classifierId: string,
    ) {
        return this.classifierNGrams.get(classifierId);
    });

    queryForClassifier = computedFn(function (
        this: ClassifierReportStore,
        classifierId,
    ) {
        return this.classifierQueries.get(classifierId);
    });

    loadPublishedClassifiers = async function (
        this: ClassifierReportStore,
        orgId: string,
    ) {
        if (!orgId) {
            return;
        }
        const classifiersPromise = await classifierService
            .getPublishedClassifiers(orgId)
            .then((value) =>
                value.filter(
                    (value1) =>
                        value1.classifierTypeName === ClassifierType.Lucene ||
                        value1.classifierTypeName === ClassifierType.Tensorflow,
                ),
            );

        runInAction(() => {
            this.publishedClassifiers = classifiersPromise;
        });
    };

    loadClassifierStats = async function (
        this: ClassifierReportStore,
        organizationId: string,
        classifierId: string,
        startDate: Moment,
        endDate: Moment,
        dateReference: DateReferenceOption,
        serviceHierarchy?: string,
    ) {
        if (!organizationId || !classifierId || !startDate || !endDate) {
            return;
        }

        const statsPromise = reportService.GetClassificationStats(
            organizationId,
            classifierId,
            startDate.toISOString(),
            endDate.toISOString(),
            dateReference,
            serviceHierarchy,
        );

        return statsPromise;
    };

    loadClassifierNGrams = async function (
        this: ClassifierReportStore,
        classifierTypeName: string,
        classifierId: string,
        orgId: string,
        startDate: Moment,
        endDate: Moment,
        dateReference: DateReferenceOption,
        serviceHierarchy?: string,
    ) {
        if (
            !classifierId ||
            !startDate ||
            !endDate ||
            classifierTypeName !== ClassifierType.Lucene
        ) {
            return;
        }

        const nGramsPromise = reportService.GetClassifierNGrams(
            classifierId,
            orgId,
            startDate.toISOString(),
            endDate.toISOString(),
            dateReference,
            serviceHierarchy,
        );

        return nGramsPromise;
    };

    @action
    loadClassifierBubbledNGrams = async (
        classifierTypeName: string,
        classifierId: string,
        orgId: string,
        startDate: Moment,
        endDate: Moment,
        dateReference: DateReferenceOption,
        serviceHierarchy?: string,
    ) => {
        if (!classifierId || !startDate || !endDate) {
            return;
        }
        this.classifierBubbledNGramsLoading.set(classifierId, true);
        const newData = await reportService.GetClassifierBubbledNGrams(
            classifierId,
            orgId,
            startDate.toISOString(),
            endDate.toISOString(),
            dateReference,
            serviceHierarchy,
        );
        runInAction(() => {
            this.classifierBubbledNGrams.set(classifierId, newData);
            this.classifierBubbledNGramsLoading.set(classifierId, false);
        });
    };

    private async loadHierarchyLevels(orgId: string) {
        const res = await this.orgService.getFlattenedServiceHierarchy(orgId);
        runInAction(() => {
            this.hierarchyLevels = res;
        });
    }

    setHierarchyLevel = (hierarchy?: { label: string; id: string }) => {
        this.selectedHierarchy = hierarchy;
    };

    loadClassifierQuery = async function (
        this: ClassifierReportStore,
        classifierId: string,
    ) {
        if (!classifierId) {
            return;
        }

        const queryPromise = await reportService.GetClassifierQuery(
            classifierId,
        );

        return queryPromise;
    };
}
