import {
    Box,
    Checkbox,
    FormControlLabel,
    Grid,
    Paper,
    Theme,
    Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import useOrganizationUser from "components/Admin/Organizations/hooks/useOrganizationUser";
import useRoles from "components/Admin/Organizations/hooks/useRoles";
import { Role } from "components/Admin/Organizations/types/Role.type";
import { User } from "components/Admin/Organizations/types/User.type";
import { AppUser } from "components/Admin/UserManager/UserStore";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import AcxButton from "components/UI/AcxButton";
import AcxConfirmationDialog from "components/UI/AcxConfirmationDialog";
import AcxInputLabel from "components/UI/AcxInputLabel";
import AcxMainTextField from "components/UI/AcxMainTextField";
import AcxHierarchyDetailsFab from "components/UI/HierarchyDetailsFab/AcxHierarchyDetailsFab";
import AcxSelect from "components/UI/Select/BaseSelectComponents/AcxSelect";
import AcxSelectMulti from "components/UI/Select/BaseSelectComponents/AcxSelectMulti";
import AcxSelectSingleAsync from "components/UI/Select/BaseSelectComponents/AcxSelectSingleAsync";
import { runInAction } from "mobx";
import { observer } from "mobx-react";
import Agentv2 from "models/Agentv2";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";
import AgentService from "services/AgentService";
import { useForm } from "shared/hooks/useForm";
import { AuthStore } from "stores/AuthStore";
import theme from "Theme/AppTheme";
import { useStore } from "utils/useStore";
import { OrganizationService } from "../../../../../../services/OrganizationService";
import OrgSelect from "../../../../AdminUIComponents/OrgSelect";
import { Organization } from "../../../types/Organization.type";
import { OrganizationAgentStore } from "../../OrganizationAgents/stores/OrganizationAgentStore";
import { OrganizationUserStore } from "../stores/OrganizationUserStore";
import ChangePasswordForm from "./Components/ChangePasswordForm";
import HierarchyAssignments from "./HierarchyAssignments";
import UserTenantAuthorizations from "./UserTenantAuthorizations/UserTenantAuthorizations";

const orgService = new OrganizationService();
const agentService = new AgentService();

const defaultValues: User = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    userName: "",
    roleName: "",
    roleId: "",
};

const statusValues = [
    { key: false, value: "Active" },
    { key: true, value: "Disabled" },
];

const useStyles = makeStyles((theme: Theme) => ({
    pageContainer: {
        width: "80%",
        marginLeft: "auto",
        marginRight: "auto",
        paddingTop: 20,
    },
    formContainer: {
        marginTop: theme.spacing(2),
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        width: "100%",
        overflowY: "auto",
    },
    error: {
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.error.main,
        fontSize: "12px",
        lineHeight: "16px",
    },
    fieldContainer: {
        width: "95%",
    },
    fieldSpacing: {
        marginTop: "0.25rem",
    },
    hierarchyAssignmentsContainer: {
        marginBottom: "1.25rem",
    },
}));

type Props = {
    orgId: string;
};

const OrganizationUserDetails: React.FC<Props> = observer(({ orgId }) => {
    const authStore = useStore(AuthStore);
    const orgUserStore = useStore(OrganizationUserStore);
    const agentStore = useStore(OrganizationAgentStore);
    agentStore.orgId = orgId;

    const messageStore = useStore(MessageStore);

    const classes = useStyles();
    const navigate = useNavigate();

    const [validation] = React.useState({
        lastName: {
            required: {
                message: "Please enter a Last Name",
            },
            pattern: [
                {
                    value: /^[a-zA-Z0-9-._@+' ]+$/,
                    message: `Last Name may only contain letters, numbers, and the following: - . _ @ + '`,
                },
            ],
        },
        firstName: {
            required: {
                message: "Please enter a First Name",
            },
            pattern: [
                {
                    value: /^[a-zA-Z0-9-._@+' ]+$/,
                    message: `First Name may only contain letters, numbers, and the following: - . _ @ + '`,
                },
            ],
        },
        userName: {
            required: {
                message: "Please enter a username",
            },
        },
        roleId: {
            required: {
                message: "Please select a role",
            },
        },
        email: {
            required: {
                message: "Please enter an email",
            },
            pattern: [
                {
                    value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                    message: `Please enter valid email address`,
                },
            ],
        },
    });

    const [currentRole, setCurrentRole] = React.useState<Role>();

    const [hierarchyOverrideOrg, setHierarchyOverrideOrg] = React.useState<
        Organization | undefined
    >(undefined);

    const { organizationId, userId } = useParams();

    const { roles, isLoading: isLoadingRoles } = useRoles({
        orgId: organizationId!,
    });
    const [organization, setOrganization] = useState<Organization>();
    const [orgIsLoading, setOrgIsLoading] = useState<boolean>(true);
    const [agentsManaged, setAgentsManaged] = useState<Agentv2[]>([]);
    const [agentsToRemove, setAgentsToRemove] = useState<Agentv2[]>([]);
    const [agents, setAgents] = useState<Agentv2[]>([]);
    const [agentAlreadyManagedDialogOpen, setAgentAlreadyManagedDialogOpen] =
        useState(false);
    const [reassignAgentsDialogOpen, setReassignAgentsDialogOpen] =
        useState(false);
    const [reassignToManagerId, setReassignToManagerId] = useState("");

    const { value, registerSubmit, errors, onChange, isSubmitting, setValue } =
        useForm<User>(defaultValues, validation);

    const {
        user,
        isLoading: isOrganizationUserLoading,
        changePassword,
        saveUser,
    } = useOrganizationUser({
        id: userId,
        organizationId: organizationId!,
    });

    const isLoading =
        orgIsLoading || isLoadingRoles || isOrganizationUserLoading;

    const handleRoleChange = (e: any) => {
        onChange("roleId", e.id);
    };

    const handleStatusChange = (e: any) => {
        if (e.key && agentsManaged.length > 0) {
            setReassignAgentsDialogOpen(true);
        }
        onChange("disabled", e.key);
    };

    const handleEmailConfirmation = (e: any) => {
        onChange("emailConfirmed", e.target.checked);
    };

    const submit = (values: User) => {
        return saveUser(values);
    };
    const saveButtonLabel = () => {
        return agentStore.creatAgentUserDetails
            ? "Save Agent User"
            : "Save Changes";
    };

    const updateAgentManager = (userId: string) => {
        agentsManaged.forEach((agent) => {
            agentStore.updateAgentManager(agent.id, userId);
        });

        agentsToRemove.forEach((agent) => {
            agentStore.updateAgentManager(agent.id);
        });
    };

    const onSuccess = async (res: User) => {
        orgUserStore.dgStore.reset();

        if (agentStore.creatAgentUserDetails) {
            if ((!value?.id || value.id === "add") && res?.id) {
                await agentStore.updateAgentUser(res.id);
                runInAction(() => {
                    agentStore.dgStore.setLoading(false);
                    // navigate(`/admin/organizations/${orgId}/agents`);
                });
            }
        } else {
            orgUserStore.loadUsers(organizationId!);
            messageStore.logMessage(`Successfully saved user`, "success");
            if (res.id) {
                updateAgentManager(res.id);
            }
            if ((!value?.id || value.id === "add") && res?.id) {
                navigate(`/admin/organizations/${orgId}/users/${res.id}`);
            }
        }
    };

    const registerOnChange =
        (prop: keyof User) => (e: React.ChangeEvent<HTMLInputElement>) => {
            onChange(prop, e.target.value);
        };

    const displayTenant = React.useMemo(() => {
        return (
            authStore.canUserViewAdminApp() &&
            authStore.orgStore?.organizations?.find((org) => org.id === orgId)
                ?.blobContainer === "authcx"
        );
    }, [authStore, orgId]);

    React.useEffect(() => {
        if (user) {
            setValue(user);
        }
    }, [user, setValue]);
    React.useEffect(() => {
        if (agentStore.creatAgentUserDetails) {
            value.firstName = agentStore.creatAgentUserDetails.firstName;
            value.lastName = agentStore.creatAgentUserDetails.lastName;
            value.email = agentStore.creatAgentUserDetails.agentEmail;
        }

        orgService.getOrganization(organizationId!).then((o) => {
            setOrgIsLoading(false);
            setOrganization(o);
        });

        return () => {
            agentStore.creatAgentUserDetails = null;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    React.useEffect(() => {
        if (user?.roleId && roles?.length) {
            const selectedRole = roles.find((role) => role.id === user.roleId);
            if (selectedRole) {
                setCurrentRole(selectedRole);
            }
        }
    }, [roles, user]);

    React.useEffect(() => {
        setHierarchyOverrideOrg(undefined);
    }, [userId]);

    React.useEffect(() => {
        agentService.getAgents(orgId).then((agents) => {
            setAgents(agents);
        });

        if (userId !== "add") {
            agentService.getAgentsByManagerId(orgId, userId).then((agents) => {
                setAgentsManaged(agents);
            });
        }
        // eslint-disable-next-line
    }, []);

    const isSamlEnabledOrganization =
        !isLoading && organization && !!organization.saml2AuthenticxDomain;

    const selectAgentsToManage = (agents) => {
        const agentsToRemoveManager: Agentv2[] = [];
        const agentIdsSelected = agents.map((agent) => agent.id);
        const originalRemovals = agentsToRemove;

        // If the original removals are not included in the agents selected array, keep them removed
        originalRemovals.forEach((agent) => {
            if (!agentIdsSelected.includes(agent.id)) {
                agentsToRemoveManager.push(agent);
            }
        });

        // If an agent originally was to be managed by this user, but is no longer selected to
        // be managed remove the agent's manager
        agentsManaged.forEach((agent) => {
            if (!agentIdsSelected.includes(agent.id)) {
                agentsToRemoveManager.push(agent);
            }
        });

        setAgentsManaged(agents);
        setAgentsToRemove(agentsToRemoveManager);
    };

    return (
        <div className={classes.pageContainer}>
            <AcxConfirmationDialog
                isOpen={agentAlreadyManagedDialogOpen}
                onClose={() => setAgentAlreadyManagedDialogOpen(false)}
                title={"Are you sure you want to reassign these agents?"}
                content={
                    <Box id="convert-to-human-eval-dialog-content">
                        <ul>
                            {agentsManaged
                                .filter(
                                    (agent) =>
                                        !!agent.managerId &&
                                        agent.managerId !== userId,
                                )
                                .map((agent) => {
                                    return <li>{agent.fullName}</li>;
                                })}
                        </ul>
                    </Box>
                }
                onConfirm={() => {
                    registerSubmit(submit, {
                        onSuccess,
                    })();
                    setAgentAlreadyManagedDialogOpen(false);
                }}
                isLoading={false}
            />

            <AcxConfirmationDialog
                isOpen={reassignAgentsDialogOpen}
                onClose={() => {
                    // Set disabled status back to false if users are still being managed
                    if (agentsManaged.length !== 0) {
                        onChange("disabled", false);
                    }
                    setReassignAgentsDialogOpen(false);
                }}
                title={"Reassign Agent(s)"}
                subTitle={
                    "This user is currently assigned as an Agent Manager, reassign this users’ agent(s) below"
                }
                content={
                    <Box id="reassign-agents-dialog-content">
                        <AcxSelectSingleAsync
                            key="userId"
                            inputLabel="Agent Manager"
                            fullWidth
                            id="agent-user-option-selector-for-reassign"
                            labelField="email"
                            valueField="id"
                            loadOptions={agentStore.searchUsers}
                            cacheOptions
                            isClearable
                            isLoading={false}
                            onChange={(user: AppUser) => {
                                setReassignToManagerId(user?.id ?? "");
                            }}
                            noOptionMessage="Please enter First Name, Last Name, Email, or Username"
                        />
                    </Box>
                }
                confirmButtonDisabled={!reassignToManagerId}
                onConfirm={() => {
                    // Set new manager for agents managed list
                    updateAgentManager(reassignToManagerId);

                    // Reset current managed agent lists
                    setAgentsManaged([]);
                    setAgentsToRemove([]);

                    setReassignAgentsDialogOpen(false);
                }}
                onCancel={() => {
                    onChange("disabled", false);
                }}
                isLoading={false}
            />
            <Grid
                style={{
                    marginTop: theme.spacing(1),
                    marginBottom: theme.spacing(1),
                }}
                container
                alignItems="center"
                justifyContent="space-between"
            >
                <Grid item>
                    <Typography variant="subtitle1">User Details</Typography>
                </Grid>

                <Grid item>
                    <AcxButton
                        onClick={() => {
                            if (
                                agentsManaged.filter(
                                    (agent) =>
                                        !!agent.managerId &&
                                        agent.managerId !== userId,
                                ).length > 0
                            ) {
                                setAgentAlreadyManagedDialogOpen(true);
                            } else {
                                registerSubmit(submit, {
                                    onSuccess,
                                })();
                            }
                        }}
                        color="secondary"
                        leftRightSpacing={0}
                        loading={isLoading || isSubmitting}
                        buttonDisabled={
                            isLoading ||
                            isSubmitting ||
                            !authStore.canUserEdit("Users")
                        }
                    >
                        {saveButtonLabel()}
                    </AcxButton>
                </Grid>
            </Grid>

            <Paper elevation={1} className={classes.formContainer}>
                <Typography variant="h5">User Details</Typography>

                <Typography variant="caption">
                    Basic details about the user.
                </Typography>

                <Grid container spacing={1} style={{ marginTop: "1rem" }}>
                    <Grid container item xs={12} wrap="nowrap">
                        <AcxMainTextField
                            containerStyle={{ width: "100%" }}
                            showAllErrors={true}
                            skeleton={isLoading}
                            labelText="First Name"
                            id="firstName"
                            value={value.firstName}
                            onChange={registerOnChange("firstName")}
                            error={!!errors?.fieldErrors?.firstName}
                            helperText={errors?.fieldErrors?.firstName}
                            required={true}
                        />

                        <AcxMainTextField
                            containerStyle={{ width: "100%" }}
                            showAllErrors={true}
                            skeleton={isLoading}
                            labelText="Last Name"
                            id="lastName"
                            value={value.lastName}
                            onChange={registerOnChange("lastName")}
                            error={!!errors?.fieldErrors?.lastName}
                            helperText={errors?.fieldErrors?.lastName}
                            required={true}
                        />
                    </Grid>

                    <Grid
                        container
                        item
                        xs={12}
                        wrap="nowrap"
                        className={classes.fieldSpacing}
                    >
                        <AcxMainTextField
                            containerStyle={{ width: "100%" }}
                            showAllErrors={true}
                            skeleton={isLoading}
                            onChange={registerOnChange("userName")}
                            id="userName"
                            labelText="User Name"
                            value={value.userName}
                            error={!!errors?.fieldErrors?.userName}
                            helperText={errors?.fieldErrors?.userName}
                            required={true}
                        />

                        {isSamlEnabledOrganization && (
                            <AcxMainTextField
                                containerStyle={{ width: "100%" }}
                                showAllErrors={true}
                                skeleton={isLoading}
                                onChange={registerOnChange(
                                    "samlProviderUserCode",
                                )}
                                id="samlProviderUserCode"
                                labelText="SAML Provider User Code (optional)"
                                value={value.samlProviderUserCode}
                                error={
                                    !!errors?.fieldErrors?.samlProviderUserCode
                                }
                                helperText={
                                    errors?.fieldErrors?.samlProviderUserCode
                                }
                                required={false}
                            />
                        )}
                    </Grid>

                    <Grid
                        container
                        item
                        xs={12}
                        wrap="nowrap"
                        className={classes.fieldSpacing}
                    >
                        <AcxMainTextField
                            containerStyle={{
                                width: "calc(50% + 0.25rem)",
                            }}
                            showAllErrors={true}
                            skeleton={isLoading}
                            onChange={registerOnChange("phoneNumber")}
                            id="phoneNumber"
                            labelText="Phone Number"
                            value={value.phoneNumber}
                            error={!!errors?.fieldErrors?.phoneNumber}
                            helperText={errors?.fieldErrors?.phoneNumber}
                        />

                        <AcxMainTextField
                            containerStyle={{
                                width: "calc(50% + 0.25rem)",
                            }}
                            showAllErrors={true}
                            skeleton={isLoading}
                            onChange={registerOnChange("email")}
                            id="email"
                            labelText="Email"
                            value={value.email}
                            error={!!errors?.fieldErrors?.email}
                            helperText={errors?.fieldErrors?.email}
                            required={true}
                        />
                    </Grid>

                    <Grid container item xs={12} wrap="nowrap">
                        <div style={{ width: "25%", marginRight: "8px" }}>
                            <AcxSelect
                                fullWidth={true}
                                containerOffset={{ right: "0.5rem" }}
                                showAllErrors={true}
                                options={roles || []}
                                inputLabel="Role"
                                labelField="name"
                                valueField="id"
                                defaultValue={currentRole}
                                id="role-selector"
                                onChange={handleRoleChange}
                                isLoading={isLoadingRoles}
                                required={true}
                                error={!!errors?.fieldErrors?.roleId}
                                helperText={errors?.fieldErrors?.roleId}
                            />
                        </div>
                        <div style={{ width: "25%" }}>
                            {value.id && (
                                <AcxSelect
                                    fullWidth={true}
                                    showAllErrors={true}
                                    skeleton={isLoading}
                                    options={statusValues}
                                    inputLabel="Status"
                                    labelField="value"
                                    valueField="key"
                                    defaultValue={statusValues.find(
                                        (s) => s.key === value.disabled,
                                    )}
                                    id="status"
                                    onChange={handleStatusChange}
                                    required={true}
                                />
                            )}
                        </div>
                    </Grid>

                    {!value?.disabled &&
                        !orgUserStore.AgentUsers &&
                        !agentStore.creatAgentUserDetails && (
                            <Grid
                                container
                                item
                                xs={12}
                                wrap="nowrap"
                                className={classes.fieldSpacing}
                            >
                                <div
                                    style={{
                                        marginRight: "0.5rem",
                                        width: "100%",
                                    }}
                                >
                                    <AcxSelectMulti
                                        key="new-user-assign-agents"
                                        options={agents}
                                        defaultValue={agentsManaged}
                                        inputLabel={"Assign Agents"}
                                        id="select-assign-agents"
                                        valueField={"fullName"}
                                        labelField={"fullName"}
                                        fullWidth
                                        containerHeight="auto"
                                        onChange={selectAgentsToManage}
                                    />
                                </div>
                            </Grid>
                        )}

                    {value.id && (
                        <Grid
                            container
                            item
                            xs={12}
                            wrap="nowrap"
                            className={classes.fieldSpacing}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={(e) =>
                                            handleEmailConfirmation(e)
                                        }
                                        id="isEmailConfirmed"
                                        name="emailConfirmed"
                                        checked={value?.emailConfirmed ?? false}
                                    />
                                }
                                label="Email Confirmed"
                            />
                        </Grid>
                    )}

                    <Grid container item xs={12}>
                        {errors?.form?.map((err, i) => (
                            <AcxInputLabel
                                key={i}
                                className={clsx(classes.error)}
                            >
                                {err}
                            </AcxInputLabel>
                        ))}
                    </Grid>
                </Grid>
            </Paper>

            {value.id && (
                <ChangePasswordForm
                    isLoading={isLoading}
                    changePassword={changePassword}
                    orgId={orgId}
                />
            )}

            <Grid
                container
                item
                xs={12}
                className={
                    displayTenant ? "" : classes.hierarchyAssignmentsContainer
                }
            >
                <Grid container alignItems="center" spacing={6}>
                    <Grid item>
                        <OrgSelect
                            disableGlobalOrgChange
                            id="hierarchy-assignment-org-selector-override-user_details"
                            orgId={organizationId}
                            autoSelectUsersOrg={false}
                            onSelect={setHierarchyOverrideOrg}
                            customStyle={{
                                container: (provided, state) => ({
                                    ...provided,
                                    borderRadius: 6,
                                    marginTop: "1rem",
                                    minWidth: "200px",
                                }),
                                control: (provided, state) => ({
                                    ...provided,
                                    padding: 6,
                                }),
                            }}
                        />
                    </Grid>

                    <Grid item>
                        <AcxHierarchyDetailsFab
                            store={orgUserStore.fabStore}
                            orgId={hierarchyOverrideOrg?.id ?? orgId}
                            userId={userId}
                        />
                    </Grid>
                </Grid>

                <HierarchyAssignments
                    orgId={hierarchyOverrideOrg?.id ?? orgId}
                    userId={userId!}
                />
            </Grid>

            {displayTenant && (
                <Grid container item xs={12} style={{ marginBottom: "1rem" }}>
                    <UserTenantAuthorizations userId={userId} />
                </Grid>
            )}
        </div>
    );
});

export default OrganizationUserDetails;
