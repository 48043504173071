// import csvParse from "csv-parse/lib/sync";
// import { parse } from "csv-parse/sync";
import Immutable from "immutable";
import moment from "moment-timezone";
import papa from "papaparse";
import { CallDirection, IMetaData, IReadFileResult } from "./UploaderUtils";

interface ICSVField {
    index: number;
    extractor: (value: string) => string;
}

export interface IExtractedCell {
    original: string;
    extracted: string;
    metadataField?: keyof IMetaData;
    error?: string;
}

export type IExtractedRow = {
    fileIdentifier: string;
    fields: Immutable.List<IExtractedCell>;
    error?: string;
};

export interface IExtractedCSV {
    fileName: string;
    error?: string;
    rows?: Immutable.List<IExtractedRow>;
}

// column indices for relevant information in the CSV
export type ICSVFormat = {
    [fieldName in keyof IMetaData]: ICSVField;
};

/**
 * Converts an alphabetic column index to zero-based numeric index
 * Thanks https://stackoverflow.com/a/9906193
 *
 * @param letter The excel-style letters for a column (A, G, AZ, ZZX, etc)
 */
export function excelColumnLetterToIndex(letter: string): number {
    letter = letter.toLocaleUpperCase();
    const base = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let result = 0;

    for (let i = 0, j = letter.length - 1; i < letter.length; i += 1, j -= 1) {
        if (base.indexOf(letter[i]) < 0) {
            return -1;
        }
        result += Math.pow(base.length, j) * (base.indexOf(letter[i]) + 1);
    }

    return result - 1;
}

export function getFormatHeader(format: ICSVFormat): {
    columnLabels: Immutable.List<string>;
    columnUses: Immutable.List<string>;
} {
    let maxCol = 0;
    let columns = Immutable.Map<number, string>();
    for (const [identifier, data] of Object.entries(format)) {
        const id: number = data.index;
        maxCol = Math.max(maxCol, id);
        columns = columns.set(id, identifier);
    }

    function getLabel(index: number) {
        let num = index + 1;
        // Thanks blogger https://cwestblog.com/2013/09/05/javascript-snippet-convert-number-to-column-name/
        for (var ret = "", a = 1, b = 26; (num -= a) >= 0; a = b, b *= 26) {
            ret = String.fromCharCode((num % b) / a + 65) + ret;
        }
        return ret;
    }

    function getUse(c: number) {
        return columns.has(c) ? columns.get(c)! : "";
    }

    const colArray = Immutable.List([...Array(maxCol + 1).keys()]);

    return {
        columnLabels: colArray.map(getLabel),
        columnUses: colArray.map(getUse),
    };
}

/**
 * A convenience function for creating CSVField objects
 *
 * @param index 0-based integer or alphabetic column index
 * @param type type of data to extract for this field
 */
export function CSVField(
    index: number | string,
    extractor: (arg0: string) => string,
): ICSVField {
    if (typeof index === typeof "A") {
        index = excelColumnLetterToIndex(index as string);
    } else {
        index = index as number;
    }
    return {
        index: index,
        extractor: extractor,
    };
}

export function dateExtractorFactory(
    format: string,
    timezone: string,
): (arg0: string) => string {
    return (value: string) => {
        if (!value) {
            return ""; // Don't report an error if we didn't get anything in the first place
        }

        const parsed = moment.tz(value, format, timezone);
        if (!parsed.isValid()) {
            throw new Error(`Couldn't parse time: ${value}`);
        }

        return parsed.format("x"); // unicode millisecond time stamp
    };
}

function normalizeCell(contents: string) {
    return contents.trim().replace(/\s+/g, " ");
}

export function extractCSVFile(
    inputRows: string[][],
    csvFormat: ICSVFormat,
): Immutable.List<IExtractedRow> {
    // CR: I know this could be done in linear instead of N*M and I don't care
    let extractedRows = Immutable.List<IExtractedRow>();
    for (const inputRow of inputRows) {
        let fileIdentifier = "";
        let fields = Immutable.List<IExtractedCell>();
        let rowError;
        for (
            let columnIndex = 0;
            columnIndex < inputRow.length;
            columnIndex++
        ) {
            const originalValue = inputRow[columnIndex];
            let extractedValue = normalizeCell(originalValue);
            let metadataField;
            let fieldError;
            for (const [fieldName, csvField] of Object.entries(csvFormat)) {
                if (csvField.index === columnIndex) {
                    try {
                        const extractor = csvField.extractor;
                        extractedValue = extractor(extractedValue);
                        metadataField = fieldName;
                        if (fieldName === "id") {
                            fileIdentifier = extractedValue;
                        }
                    } catch (ex) {
                        //@ts-ignore
                        fieldError = ex.message as string;
                    }
                }
            }
            fields = fields.push({
                original: originalValue,
                extracted: extractedValue,
                metadataField,
                error: fieldError,
            });
        }
        const outputRow: IExtractedRow = {
            fileIdentifier,
            error: rowError,
            fields,
        };
        extractedRows = extractedRows.push(outputRow);
    }
    return extractedRows;
}

export function extractCSVFiles(
    inputFiles: IReadFileResult[],
    csvFormat: ICSVFormat,
): Immutable.List<IExtractedCSV> {
    let extractedFiles = Immutable.List<IExtractedCSV>();

    for (const inputFile of inputFiles) {
        let extractedFile: IExtractedCSV = {
            fileName: inputFile.fileName,
        };

        if (
            inputFile.contents === null ||
            inputFile.contents === undefined ||
            inputFile.contents === ""
        ) {
            extractedFile.error = "File had no contents";
        } else {
            try {
                const csvData = inputFile.contents.toString().trim();
                // const inputRows = csvParse(csvData) as string[][];
                // const inputRows = parse(csvData, {
                //     columns: true,
                // }) as string[][];
                const parse = papa.parse(csvData, { header: false });
                const inputRows = parse.data as string[][];
                const extractedRows = extractCSVFile(inputRows, csvFormat);
                extractedFile.rows = extractedRows;
            } catch (ex) {
                //@ts-ignore
                extractedFile.error = ex.message;
            }
        }

        extractedFiles = extractedFiles.push(extractedFile);
    }
    return extractedFiles;
}
const fanimationDateFormat = "YYYY-MM-DD HH:mm:ss";
const fanimationDateExtractor = dateExtractorFactory(
    fanimationDateFormat,
    "America/New_York",
);

export const NOT_USED = CSVField(-1, (x) => "");

export const fanimationCSVFormat: ICSVFormat = {
    id: CSVField("A", (x) => x),
    timestamp: CSVField("B", fanimationDateExtractor),
    phone: CSVField("K", (x) => x),
    name: CSVField("L", (x) => x),
    agentName: CSVField("N", (x) => x),
    timezone: NOT_USED,
    email: NOT_USED,
    callDirection: NOT_USED,
};

const abbvieDateFormat = "M/D/YYYY H:mm:ss A";
const abbvieDateExtractor = dateExtractorFactory(
    abbvieDateFormat,
    "America/New_York",
);

function abbvieNameExtractor(name: string): string {
    const [last, first] = name.replace(" ", "").split(",");
    return first + " " + last;
}

function directionExtractor(direction: string): CallDirection {
    let transformed = direction.toLocaleLowerCase().trim();
    if (transformed === "outbound") return "outbound";
    if (transformed === "inbound") return "inbound";
    if (transformed === "" || transformed === null || transformed === undefined)
        return "";
    throw new Error("Invalid call direction: " + direction);
}

export const abbvieCSVFormat: ICSVFormat = {
    id: CSVField("A", (x) => x),
    timestamp: CSVField("B", abbvieDateExtractor),
    agentName: CSVField("E", abbvieNameExtractor),
    callDirection: CSVField("D", directionExtractor),
    timezone: NOT_USED,
    email: NOT_USED,
    name: NOT_USED,
    phone: NOT_USED,
};

export const emptyCSVFormat: ICSVFormat = {
    id: NOT_USED,
    timestamp: NOT_USED,
    agentName: NOT_USED,
    callDirection: NOT_USED,
    timezone: NOT_USED,
    email: NOT_USED,
    name: NOT_USED,
    phone: NOT_USED,
};
