import { Grid, IconButton } from "@mui/material";
import FlagIcon from "@mui/icons-material/Flag";
import React, { FunctionComponent } from "react";
import EditSvg from "SvgIcons/EditSvg";
import QuoteSvg from "SvgIcons/QuoteSvg";
import { makeStyles } from "@mui/styles";

type Props = {
    toggleNote: () => void;
    showNote: boolean;
    toggleQuote: () => void;
    showQuote: boolean;
    toggleAnswerAlert: () => void;
    flagActive: boolean;
    interactionType?: number;
    restrictMedia?: boolean;
    isDisabled?: boolean;
};

const useQuoteNoteStyles = makeStyles(() => ({
    buttonContainer: {
        textAlign: "center",
    },
    iconButton: {
        marginBottom: "0.5rem",
        padding: "8px",
    },
    active: {
        backgroundColor: "#3564D5",
    },
    inactive: {
        backgroundColor: "#e6e6e6",
    },
    activeIcon: {
        color: "white",
    },
    inactiveIcon: {
        color: "#1F1F1F",
        opacity: 0.25,
    },
}));

const QuoteNoteButtonContainer: FunctionComponent<Props> = ({
    toggleNote,
    showNote,
    toggleQuote,
    showQuote,
    toggleAnswerAlert,
    flagActive,
    interactionType,
    restrictMedia,
}) => {
    const classes = useQuoteNoteStyles();

    const iconSize = { width: "20px", height: "20px" };

    const renderIconButton = (
        onClick: () => void,
        isActive: boolean,
        IconComponent: React.ElementType,
        iconProps: object = {},
    ) => (
        <Grid item className={classes.buttonContainer}>
            <IconButton
                onClick={onClick}
                className={`${classes.iconButton} ${
                    isActive ? classes.active : classes.inactive
                }`}
                size="large">
                <IconComponent
                    {...iconSize}
                    className={
                        isActive ? classes.activeIcon : classes.inactiveIcon
                    }
                    {...iconProps}
                />
            </IconButton>
        </Grid>
    );
    return (
        <>
            {renderIconButton(toggleNote, showNote, EditSvg, {
                width: "20px",
                height: "20px",
            })}

            {interactionType !== 4 &&
                !restrictMedia &&
                renderIconButton(toggleQuote, showQuote, QuoteSvg, {
                    width: "20px",
                    height: "20px",
                })}

            {renderIconButton(toggleAnswerAlert, flagActive, FlagIcon, {
                fontSize: "small",
            })}
        </>
    );
};

export default QuoteNoteButtonContainer;
