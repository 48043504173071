import { Breadcrumbs, Tooltip, Typography } from "@mui/material";
import {
    GridCellValue,
    GridColTypeDef,
    GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import AcxChipList from "components/UI/AcxChipList";
import { isObject } from "lodash";
import React from "react";
import { ArrayToCommaSepString } from "utils/StringUtils";
import { isArrayType } from "utils/TypeGuards";
import { ArrayJoinComparator } from "../Formatters/ArrayJoinFormatter";
import { StringFormatter } from "../Formatters/DefaultFormatters";
import { ChipComparator } from "../Formatters/TagFormatter";

// export const priceColumnType: GridColTypeDef = {
//     extendType: "number",
//     filterOperators: getGridNumericColumnOperators()
//         .filter((operator) => operator.value === ">" || operator.value === "<")
//         .map((operator) => {
//             return {
//                 ...operator,
//                 InputComponentProps: {
//                     InputProps: {
//                         startAdornment: (
//                             <InputAdornment position="start">$</InputAdornment>
//                         ),
//                     },
//                 },
//             };
//         }),
// };

export const arrayJoinColumnType: GridColTypeDef = {
    extendType: "string",
    valueFormatter(params: GridValueFormatterParams) {
        const value = params.getValue(params.id, params.field)?.valueOf();
        if (isArrayType(value)) {
            const joinedArray = ArrayToCommaSepString(value);
            return StringFormatter({
                ...params,
                getValue: (field) => joinedArray,
            });
        }
        return "";
    },
    sortComparator(v1: GridCellValue, v2: GridCellValue) {
        const x1 = ArrayToCommaSepString(v1 as any[]);
        const x2 = ArrayToCommaSepString(v2 as any[]);

        return x1 > x2 ? 1 : -1;
    },
};

export const arrayTagColumnType: GridColTypeDef = {
    valueFormatter: (params: GridValueFormatterParams | string) => {
        let tags: string[];

        if (isObject(params)) {
            tags = params.getValue(params.id, params.field) as any;
            if (Array.isArray(tags)) {
                const res = (
                    <AcxChipList
                        size={"small"}
                        color={"blue"}
                        values={tags}
                        height="100%"
                        toolTip={tags.join(", ")}
                    />
                );
                return res;
            }
        }

        return null;
    },
};

export const breadCrumbColumnType: GridColTypeDef = {
    renderCell: (params: GridValueFormatterParams | string) => {
        function formatter(args?: string[]) {
            let toolTip = "";
            if (Array.isArray(args) && args.length > 0) {
                toolTip = args.join(" > ");
            }

            return (
                <Tooltip title={toolTip}>
                    <Breadcrumbs
                        style={{ whiteSpace: "nowrap" }}
                        separator="›"
                        aria-label="breadcrumb"
                    >
                        {args?.map((member) => {
                            return (
                                <Typography key={`breadcrumb_${member}`}>
                                    {member}
                                </Typography>
                            );
                        })}
                    </Breadcrumbs>
                </Tooltip>
            );
        }

        let values: string[];

        if (isObject(params)) {
            values = params.getValue(params.id, params.field) as any;
            if (Array.isArray(values)) {
                return formatter(values.filter((value, index) => !!value));
            }
        }

        return null;
    },
    sortComparator: ArrayJoinComparator,
};

export const hierarchyColumnType: GridColTypeDef = {
    renderCell: (params: GridValueFormatterParams | string) => {
        let initialHierarchiesFormat: string[][] = [];
        let hierarchies: string[];

        if (isObject(params)) {
            const value: { id: string; levels: string[] }[] = params.getValue(
                params.id,
                params.field,
            ) as any;

            for (let index = 0; index < value.length; index++) {
                if (value[index].levels) {
                    initialHierarchiesFormat.push(
                        value[index].levels.filter((level) => !!level),
                    );
                }
            }

            if (initialHierarchiesFormat.length === 0) {
                initialHierarchiesFormat = [value] as any;
            }

            hierarchies = initialHierarchiesFormat.flatMap((hierarchyGroup) =>
                hierarchyGroup.join(" > "),
            );
        } else {
            hierarchies = params as any;
        }

        if (hierarchies.length === 1 && hierarchies[0] === "") {
            return [];
        }

        const res = (
            <AcxChipList
                toolTip={
                    <>
                        {hierarchies.map((value) => (
                            <div key={value}>{value}</div>
                        ))}
                    </>
                }
                size={"small"}
                color={"blue"}
                values={hierarchies}
                height="100%"
            />
        );

        return res;
    },
    sortComparator: ChipComparator,
};

export const numberWithoutCommasColumnType: GridColTypeDef = {
    valueFormatter: (params: GridValueFormatterParams) =>
        params.value?.toString(),
};
