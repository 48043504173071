import { observer } from "mobx-react";
import { SignalsDomain } from "../Models/SignalsModels";
import { Grid, Typography } from "@mui/material";
import { Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import theme from "Theme/AppTheme";
import AcxCard from "components/UI/Cards/AcxCard";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
    cardHeader: {
        textTransform: "uppercase",
        letterSpacing: "2px",
    },
}));

type Props = { domainName: SignalsDomain };

const SignalsPageDescription: React.FC<Props> = observer((props: Props) => {
    const classes = useStyles();

    const rootStyle = {
        maxWidth: "98%",
        height: "fit-content",
        backgroundColor:
            props.domainName === "eddy"
                ? theme.palette.lightBlue.background
                : theme.palette.green.background,
        padding: theme.spacing(2),
        margin: theme.spacing(2),
        boxShadow: "none",
        marginTop: 0,
    };

    const variantCopy: {
        [K in SignalsDomain]: {
            title: string;
            subTitle1: string;
            subTitle2: string;
        } | null;
    } = {
        eddy: {
            title: "The Eddy Effect",
            subTitle1:
                "On this page, explore the question: in what situations do my customers experience friction or feel stuck?",
            subTitle2: `An "Eddy" is when the customer's desired or expected experience is disrupted by an obstacle or barrier that causes the customer to feel “stuck” in a problem.`,
        },
        trends: {
            title: "Trends Highlights",
            subTitle1:
                "Trends allow you to see key insights over time, including indications of how volume or trends differ from previous time periods.",
            subTitle2: `Find insights across quality and compliance, too, to see how your team is performing.`,
        },
        topics: {
            title: "Topics Summary",
            subTitle1:
                "Topics give you visibility into what topics are naturally arising across your customer conversations.",
            subTitle2: `See what topics are coming up most often, how they're trending over time, and how they relate to Eddy Effect prevalence.`,
        },
        contacts: {
            title: "Contact Types",
            subTitle1:
                "Our Contact Type AI Model automatically identifies which personas are present across your interactions.",
            subTitle2: `Use this page to explore what types of contacts are chatting or calling in most often, and how Eddies are impacting them.`,
        },
        dashboard: null,
    };

    return (
        variantCopy[props.domainName] && (
            <AcxCard
                rootStyle={rootStyle}
                title={
                    <Typography
                        className={classes.cardHeader}
                        color={
                            props.domainName === "eddy"
                                ? theme.palette.lightBlue.main
                                : theme.palette.green.main
                        }
                        fontWeight={700}
                    >
                        {variantCopy[props.domainName]?.title}
                    </Typography>
                }
                mainContent={
                    <Grid container direction="column" gap={1}>
                        <Typography variant="body1">
                            {variantCopy[props.domainName]?.subTitle1}
                        </Typography>
                        <Typography variant="body1">
                            {variantCopy[props.domainName]?.subTitle2}
                        </Typography>
                    </Grid>
                }
            />
        )
    );
});

export default SignalsPageDescription;
