import {
    Grid,
    IconButton,
    IconButtonProps,
    Tooltip,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { Theme, useTheme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import {
    Forward30,
    Replay30,
    SkipNext,
    SkipPrevious,
} from "@mui/icons-material";
import AcxButton, { IAcxButtonProps } from "components/UI/AcxButton";
import { observer } from "mobx-react";
import moment from "moment";
import React, { useEffect } from "react";
import { customSecondaryButtonStyle } from "utils/Style";
import { isUndefinedType } from "../../../utils/TypeGuards";
import SoundClipEditorStore from "../Stores/SoundClipEditorStore";
import PlayControl, { PlayStatus } from "./PlayControl";
import PlaybackRate from "./PlaybackRate";
import VolumeSlider from "./VolumeSlider";
import Zoom from "./Zoom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Link } from "react-router-dom";
import { useStore } from "utils/useStore";
import { ConversationService } from "services/ConversationService";
import { EvalStore } from "components/Evaluation/Stores/EvalStore";

interface IPlayerV2Props {
    enablePlaybackRate?: boolean;
    viewModel: SoundClipEditorStore;
    enableZoom?: boolean;
    iconButton?: React.ReactNode;
    iconButtonProps?: Partial<IconButtonProps>;
    textButton?: string | null;
    textButtonProps?: Partial<IAcxButtonProps>;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            padding: theme.spacing(1),
        },
        title: {
            opacity: "0.5",
            color: "#1F1F1F",
            fontSize: "16px",
            letterSpacing: "0",
            lineHeight: "24px",
            textAlign: "center",
        },
        duration: {
            color: theme.palette.blue.main,
            fontSize: "14px",
            fontWeight: 400,
            paddingTop: "2px",
        },
        playbackRateContainer: {
            margin: "0 0.5rem",
            padding: "0 0.25rem",
            borderRadius: "0.25rem",
        },
        playbackRateText: {
            color: theme.palette.white.main,
            cursor: "pointer",
        },
        controls: {
            padding: "12px 22px",
            height: "100%",
            backgroundColor: "#FAFCFF",
        },
        volumeUpContainer: {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
        },
        volumeDownContainer: {
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
        },
        popperPaper: {
            backgroundColor: theme.palette.lightBlue.background,
        },
        iconColor: {
            color: theme.palette.blue.main,
        },
        volumeContainer: {
            height: "48px",
            width: "300px",
        },
    }),
);

const Player: React.FC<IPlayerV2Props> = observer(
    ({
        enablePlaybackRate,
        viewModel,
        enableZoom,
        iconButton,
        iconButtonProps = {},
        textButton,
        textButtonProps = {},
    }) => {
        const theme = useTheme();
        const classes = useStyles(theme);
        const [playStatus, setPlayStatus] = React.useState<PlayStatus>(
            PlayStatus.Stopped,
        );

        const [hasVideo, setHasVideo] = React.useState(false);

        const conversationService = new ConversationService();

        const evalStore = useStore(EvalStore);

        const playAudio = () => {
            setPlayStatus(PlayStatus.Play);
            viewModel.onPlayClick();
        };

        const pauseAudio = () => {
            setPlayStatus(PlayStatus.Paused);
            viewModel.onPlayClick();
        };

        const getDurationDisplay = () => {
            const audioDuration = viewModel.clipAudioBuff?.duration;
            if (audioDuration === undefined) {
                return "";
            }

            const minutes = Math.floor(audioDuration / 60);
            const seconds = Math.floor(audioDuration % 60);

            return ` (${minutes}:${seconds})`;
        };

        useEffect(() => {
            const status = viewModel.isPlaying
                ? PlayStatus.Play
                : PlayStatus.Paused;
            setPlayStatus(status);
        }, [viewModel.isPlaying]);

        const amdId = evalStore.currentEval?.interaction?.audioMetadataId;

        useEffect(() => {
            if (amdId && evalStore.authStore.canUserView("Screen Recording")) {
                try {
                    conversationService
                        .getScreenRecordings(amdId)
                        .then((res) => {
                            if (res && Object.keys(res).length) {
                                setHasVideo(true);
                            } else {
                                setHasVideo(false);
                            }
                        });
                } catch (e) {
                    // Don't log message to user
                    setHasVideo(false);
                }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [amdId]);

        return (
            <>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-around"
                    alignItems="center"
                    id="main-grid"
                    sx={{
                        padding: "12px 22px",
                        height: "100%",
                        backgroundColor: "#FAFCFF",
                    }}
                >
                    <Grid
                        container
                        item
                        xs={4}
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        {evalStore.authStore.canUserView("Screen Recording") &&
                            hasVideo && (
                                <Grid item>
                                    <Link
                                        to={"/focus/video/" + amdId}
                                        target="_blank"
                                        style={{ textDecoration: "none" }}
                                    >
                                        <AcxButton
                                            leftRightSpacing={0}
                                            customRootStyles={
                                                customSecondaryButtonStyle
                                            }
                                            startIcon={<OpenInNewIcon />}
                                        >
                                            View Video
                                        </AcxButton>
                                    </Link>
                                </Grid>
                            )}
                        {iconButton && (
                            <IconButton
                                className={classes.iconColor}
                                {...iconButtonProps}
                                size="large"
                            >
                                {iconButton}
                            </IconButton>
                        )}
                        <VolumeSlider
                            onVolumeChange={viewModel.changeVolume}
                            onChannel1Change={viewModel.changeChannel1Volume}
                            onChannel2Change={viewModel.changeChannel2Volume}
                            multiChannel={viewModel.isMultiChannel}
                        />
                    </Grid>
                    <Grid
                        container
                        item
                        xs={4}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item>
                            <Tooltip title="Go to beginning">
                                <IconButton
                                    className={classes.button}
                                    onClick={viewModel.goToBegin}
                                    size={"medium"}
                                >
                                    <SkipPrevious
                                        className={classes.iconColor}
                                        fontSize={
                                            useMediaQuery(
                                                theme.breakpoints.down("lg"),
                                            )
                                                ? "small"
                                                : "large"
                                        }
                                        color={"inherit"}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title="Go back 30 seconds">
                                <IconButton
                                    className={classes.button}
                                    onClick={viewModel.goBack30}
                                    size={"medium"}
                                >
                                    <Replay30
                                        className={classes.iconColor}
                                        fontSize={
                                            useMediaQuery(
                                                theme.breakpoints.down("lg"),
                                            )
                                                ? "small"
                                                : "large"
                                        }
                                        color={"inherit"}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <PlayControl
                                style={{ color: "#3564D5" }}
                                play={playAudio}
                                pause={pauseAudio}
                                status={playStatus}
                                color={"inherit"}
                            />
                        </Grid>
                        <Grid item>
                            <Tooltip title="Go forward 30 seconds">
                                <IconButton
                                    className={classes.button}
                                    onClick={viewModel.goForward30}
                                    size={"medium"}
                                >
                                    <Forward30
                                        color={"inherit"}
                                        className={classes.iconColor}
                                        fontSize={
                                            useMediaQuery(
                                                theme.breakpoints.down("lg"),
                                            )
                                                ? "small"
                                                : "large"
                                        }
                                    />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title={"Go to end" + getDurationDisplay()}>
                                <IconButton
                                    className={classes.button}
                                    onClick={viewModel.goToEnd}
                                    size={"medium"}
                                >
                                    <SkipNext
                                        color={"inherit"}
                                        style={{ color: "#3564D5" }}
                                        fontSize={
                                            useMediaQuery(
                                                theme.breakpoints.down("lg"),
                                            )
                                                ? "small"
                                                : "large"
                                        }
                                    />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={4}
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        {enableZoom && <Zoom viewModel={viewModel} />}
                        {durationTitleDisplay(
                            viewModel.playHeadTime,
                            viewModel.clipAudioBuff?.duration,
                        ) &&
                            enablePlaybackRate && (
                                <Grid
                                    item
                                    sx={{
                                        margin: "0 0.5rem",
                                        padding: "0 0.25rem",
                                        borderRadius: "0.25rem",
                                    }}
                                >
                                    <PlaybackRate viewModel={viewModel} />
                                </Grid>
                            )}
                        <Grid item>
                            <Typography
                                sx={{
                                    color: theme.palette.blue.main,
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    paddingTop: "2px",
                                }}
                            >
                                {durationTitleDisplay(
                                    viewModel.playHeadTime,
                                    viewModel.clipAudioBuff?.duration,
                                )}
                            </Typography>
                        </Grid>
                        {textButton && (
                            <Grid item>
                                <AcxButton
                                    customRootStyles={{
                                        ...customSecondaryButtonStyle,
                                        marginRight: 0,
                                        marginLeft: "16px",
                                    }}
                                    {...textButtonProps}
                                >
                                    {textButton}
                                </AcxButton>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </>
        );
    },
);

export function durationDisplay(val: number) {
    const dur = moment.duration(val, "seconds");

    const minutesAndSeconds = `${`${dur.minutes()}`.padStart(
        2,
        "0",
    )}:${`${dur.seconds()}`.padStart(2, "0")}`;

    if (dur.hours()) {
        return `${dur.hours()}:` + minutesAndSeconds;
    } else {
        return minutesAndSeconds;
    }
}

export function durationTitleDisplay(
    currentTime: number | undefined,
    duration: number | undefined,
) {
    if (!isUndefinedType(duration) && !isUndefinedType(currentTime)) {
        return `${durationDisplay(currentTime)} / ${durationDisplay(duration)}`;
    } else {
        return undefined;
    }
}

export default Player;
