import { Box, CircularProgress, Modal, TextField, Typography } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import { ArrowBack, Close } from "@mui/icons-material";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import AcxButton from "components/UI/AcxButton";
import AcxDataGrid from "components/UI/AcxDataGrid/AcxDataGrid";
import AcxTruncate from "components/UI/AcxTruncate";
import { observer } from "mobx-react";
import moment from "moment";
import clsx from "clsx";
import React, { useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import useStyles from "Styles/Styles";
import { useStore } from "utils/useStore";
import { TopicStore } from "../stores/TopicStore";

const styles = () => {
    return createStyles({
        PageWrapper: {
            width: "80%",
            marginLeft: "auto",
            marginRight: "auto",
            paddingTop: "20px",
            paddingBottom: "20px",
        },
        PageHeader: {
            marginTop: "8px",
            marginBottom: "8px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        },
        BackIcon: {
            fontSize: "20px",
            cursor: "pointer",
        },
        HeaderTitle: {
            marginLeft: "20px",
        },
        HeaderButtons: {
            marginLeft: "auto",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        },
        PublishButton: {
            backgroundColor: "#3664D5",
            color: "#FFFFFF",
            fontFamily: '"Inter", sans-serif',
            fontWeight: "bold",
            textTransform: "none",
        },
        DiscardButton: {
            backgroundColor: "#FFFFFF",
            color: "#555555",
            marginRight: "20px",
            fontFamily: '"Inter", sans-serif',
            fontWeight: "bold",
            textTransform: "none",
            boxShadow: "0px 0px 1px 1px rgb(228,228,231)",
        },
        Main: {
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "100%",
            marginTop: "25px",
        },
        LeftContent: {
            display: "flex",
            width: "70%",
            flexDirection: "column",
            height: "100%",
            marginRight: "12px",
        },
        DateAdded: {
            height: "40px",
            backgroundColor: "#E4E4E7",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingLeft: "20px",
            paddingRight: "20px",
            borderRadius: "5px",
            marginBottom: "24px",
        },
        DateAddedDismiss: {
            marginLeft: "auto",
            cursor: "pointer",
        },
        Chart: {
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#FFFFFF",
            paddingLeft: "20px",
            paddingRight: "20px",
            listStyle: "none",
            paddingTop: "20px",
            paddingBottom: "12px",
            borderRadius: "10px",
            marginTop: "0px",
        },
        Datapoint: {
            display: "flex",
            flexDirection: "row",
            marginBottom: "12px",
        },
        BarTitle: {
            width: "40%",
        },
        GraphSpace: {
            width: "60%",
        },
        Bar: {
            backgroundColor: "#3664D5",
            height: "100%",
        },
        root: {
            marginTop: "12px",
            marginBottom: "12px",
            borderRadius: "10px",
            minHeight: "320px",

            "& .MuiDataGrid-root": {
                marginTop: "12px",
                marginBottom: "12px",
                borderRadius: "10px",
                minHeight: "500px",
            },
            "& .MuiDataGrid-columnsContainer": {
                backgroundColor: "#3664D5",
                color: "#FFFFFF",
                borderRadius: "10px 10px 0px 0px",
            },
            "& .MuiDataGrid-footer": {
                backgroundColor: "#FFFFFF",
                borderRadius: "0px 0px 10px 10px",
            },
            "& .MuiDataGrid-window": {
                backgroundColor: "#FFFFFF",
            },
        },
        interaction: {
            color: "#3664D5",
        },
        RightContent: {
            display: "flex",
            width: "30%",
            flexDirection: "column",
            height: "100%",
            marginLeft: "12px",
        },
        TopicDetails: {
            backgroundColor: "#FFFFFF",
            paddingLeft: "24px",
            paddingRight: "24px",
            paddingTop: "24px",
            paddingBottom: "24px",
            height: "155px",
            display: "flex",
            flexDirection: "column",
        },
        TopicNameTitle: {
            marginTop: "24px",
            "&::after": {
                content: "' *'",
                color: "#FF0000",
            },
        },
        TopicNameTextfield: {
            height: "0px",
            marginTop: "12px",
        },
        TranscriptionModal: {
            display: "flex",
            flexDirection: "column",
            background: "#FFFFFF",
            width: "70%",
            height: "70%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "10px",
            overflow: "hidden",
        },
        ModalHeader: {
            width: "100%",
            background: "#3664D5",
            display: "flex",
            flexDirection: "row",
            paddingLeft: "16px",
            paddingTop: "16px",
            paddingBottom: "16px",
            color: "#FFFFFF",
        },
        ModalTitle: {
            color: "#FFFFFF",
        },
        ModalContent: {
            margin: "20px",
            overflow: "scroll",
        },
        Spinner: {
            margin: "auto",
        },
    });
};

type Props = {
    orgId: string;
};

const TopicDetail: React.FC<Props> = observer(({ orgId }) => {
    const { topicId } = useParams<{ topicId: string }>();
    const classes = useStyles(styles);

    const topicStore = useStore(TopicStore);
    const messageStore = useStore(MessageStore);

    const [showCreatedDate, setShowCreatedDate] = useState(true);
    const [publishEnabled, setPublishEnabled] = useState(false);
    const [topicName, setTopicName] = useState(
        topicStore.selectedTopic?.userAssignedLabel ?? "",
    );

    const TopicNameChange = (event) => {
        setPublishEnabled(Boolean(String(event.target.value).trim()));
        setTopicName(event.target.value);
    };

    const SaveTopicName = async () => {
        const code = await topicStore.topicService.updateTopicName(
            topicStore.selectedTopic.id,
            topicName,
        );
        //Check if update successful
        if (code === 1) {
            messageStore.logMessage(`Successfully saved topic`, "success");
            topicStore.selectedTopic.userAssignedLabel = topicName;
        } else {
            messageStore.logMessage(`Failed to save topic`, "error");
        }
    };

    let topicLoading = true;
    let interactionsLoading = true;
    let Datapoints;

    if (topicStore.selectedTopic) {
        topicLoading = false;
        interactionsLoading = topicStore.getTaskLoading(
            "getRelevantInteractions",
        );

        //Scale for the graph normalizes based on the max and min, found that basing it solely on the max produces too little variance for graphs
        const scale =
            Math.max.apply(null, topicStore.selectedTopic.scores) +
            Math.min.apply(null, topicStore.selectedTopic.scores);

        Datapoints = topicStore.selectedTopic.words.map((word, index) => (
            <li key={index}>
                <div className={classes.Datapoint}>
                    <Typography className={classes.BarTitle} variant="body2">
                        {word}
                    </Typography>
                    <div className={classes.GraphSpace}>
                        <div
                            className={classes.Bar}
                            style={{
                                width: `${
                                    (topicStore.selectedTopic.scores[index] /
                                        scale) *
                                    100
                                }%`,
                            }}
                        />
                    </div>
                </div>
            </li>
        ));
    } else {
        const getTopic = async () => {
            if (topicId) {
                topicStore.setSelectedTopic(
                    await topicStore.topicService.getById(topicId),
                );
            }
        };
        topicStore.orgId = orgId;
        getTopic();
    }

    const navigate = useNavigate();

    const NavigateBack = () => {
        if (topicStore.selectedTopic.userAssignedLabel) {
            let path = generatePath("/admin/organizations/:orgId/topics/", {
                orgId: orgId,
            });
            navigate(path);
        } else {
            let path = generatePath("/admin/organizations/:orgId/uidtopics/", {
                orgId: orgId,
            });
            navigate(path);
        }
    };

    const formatTranscriptForHighlight = (
        transcript?: string,
        wordsArray?: string[],
    ) => {
        let newTranscript = transcript || "";

        if (wordsArray && transcript) {
            for (const word of wordsArray) {
                const wordRegex = new RegExp("\\b" + word + "\\b", "g");
                newTranscript = newTranscript?.replaceAll(
                    wordRegex,
                    `<div class="highlighted">${word}</div>`,
                );
            }
        }

        return newTranscript;
    };

    return (
        <>
            {!topicLoading && (
                <div className={classes.PageWrapper}>
                    <div className={classes.PageHeader}>
                        <ArrowBack
                            onClick={NavigateBack}
                            className={classes.BackIcon}
                        />
                        <Typography
                            className={classes.HeaderTitle}
                            variant="subtitle1"
                        >
                            {topicStore.selectedTopic.userAssignedLabel ??
                                `Topic ${topicStore.selectedTopic.topicNumber}`}
                        </Typography>
                        <div className={classes.HeaderButtons}>
                            {/* Discard Button UI, currently no need / function without scope creep, would need separate ticket
                         <Button variant="contained" disableElevation className={classes.DiscardButton}>Discard Topic</Button> 
                         */}
                            <AcxButton
                                onClick={SaveTopicName}
                                color="secondary"
                                leftRightSpacing={0}
                                buttonDisabled={!publishEnabled}
                            >
                                Save Topic
                            </AcxButton>
                        </div>
                    </div>
                    <div className={classes.Main}>
                        <div className={classes.LeftContent}>
                            {showCreatedDate && (
                                <>
                                    <div className={classes.DateAdded}>
                                        <Typography
                                            component="div"
                                            variant="body2"
                                        >
                                            This topic was first added on{" "}
                                            <Box
                                                fontWeight="fontWeightBold"
                                                display="inline"
                                            >
                                                {moment(
                                                    topicStore.selectedTopic
                                                        .createdOn,
                                                ).format("MMM DD, YYYY.")}
                                            </Box>
                                        </Typography>
                                        <Close
                                            onClick={() =>
                                                setShowCreatedDate(false)
                                            }
                                            className={classes.DateAddedDismiss}
                                        />
                                    </div>
                                </>
                            )}
                            <ul className={classes.Chart}>{Datapoints}</ul>
                            {!interactionsLoading && (
                                <>
                                    {topicStore.relevantInteractions.length >
                                    0 ? (
                                        <div style={{ height: "500px" }}>
                                            <Typography variant="subtitle1">
                                                Relevant Interactions
                                            </Typography>
                                            <AcxDataGrid
                                                dataGridStore={
                                                    topicStore.dgStore
                                                }
                                            />
                                        </div>
                                    ) : (
                                        <Typography variant="subtitle2">
                                            No relevant interactions found for
                                            this topic.
                                        </Typography>
                                    )}
                                </>
                            )}
                        </div>
                        <div className={classes.RightContent}>
                            <div className={classes.TopicDetails}>
                                <Typography variant="h5">
                                    Topic Details
                                </Typography>
                                <Typography
                                    className={classes.TopicNameTitle}
                                    variant="body2"
                                >
                                    Topic Name
                                </Typography>
                                <TextField
                                    value={topicName}
                                    onChange={TopicNameChange}
                                    size="small"
                                    className={classes.TopicNameTextfield}
                                    id="outlined-basic"
                                    variant="outlined"
                                    placeholder={
                                        topicStore.selectedTopic
                                            .userAssignedLabel ??
                                        `Topic ${topicStore.selectedTopic.topicNumber}`
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <Modal
                        open={topicStore.showTranscription}
                        onClose={() => topicStore.handleHideTranscription()}
                    >
                        <div className={classes.TranscriptionModal}>
                            <div className={classes.ModalHeader}>
                                <Typography
                                    className={classes.ModalTitle}
                                    variant="subtitle2"
                                >
                                    {topicStore.selectedInteraction?.fileName}
                                </Typography>
                            </div>

                            {topicStore.transcription ? (
                                <div
                                    className={clsx(
                                        classes.ModalContent,
                                        "pendo-ignore",
                                    )}
                                >
                                    <AcxTruncate
                                        lineClamp={2}
                                        content={
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: formatTranscriptForHighlight(
                                                        topicStore.transcription
                                                            ?.text,
                                                        topicStore.selectedTopic
                                                            ?.words,
                                                    ),
                                                }}
                                            />
                                        }
                                    />
                                </div>
                            ) : (
                                <CircularProgress className={classes.Spinner} />
                            )}
                        </div>
                    </Modal>
                </div>
            )}
        </>
    );
});
export default TopicDetail;
