import {
    Card,
    CardActions,
    CardActionsProps,
    CardContent,
    CardContentProps,
    CardHeader,
    CardHeaderPropsWithComponent,
    CardProps,
} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { Theme } from "@mui/material/styles";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { CSSProperties, FunctionComponent } from "react";
import AcxLoadingIndicator from "../AcxLoadingIndicator";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            maxWidth: "400px",
            width: "100%",
        },
        media: {
            height: 0,
            paddingTop: "56.25%", // 16:9
        },
    }),
);

const StyledCard = withStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "stretch",
        flexDirection: "column",
        justifyContent: "flex-start",
    },
}))((props: CardProps) => <Card {...props} />);

const StyledCardHeader = withStyles((theme) => ({
    root: {
        flex: "0 1 auto",
        justifyContent: "center",
        display: "flex",
        alignItems: "flex-start",
        paddingTop: "8px",
        paddingBottom: "4px",
        paddingLeft: "16px",
        paddingRight: "16px",
    },
    /* Styles applied to the avatar element. */
    avatar: {
        flex: "0 0 auto",
        marginRight: 16,
    },
    /* Styles applied to the action element. */
    action: {
        flex: "0 0 auto",
        alignSelf: "flex-start",
        marginTop: -8,
        marginRight: -8,
    },
    /* Styles applied to the content wrapper element. */
    content: {
        flex: "1 1 auto",
        justifyContent: "flex-start",
    },
    /* Styles applied to the title Typography element. */
    title: {},
    /* Styles applied to the subheader Typography element. */
    subheader: {},
}))((props: CardHeaderPropsWithComponent) => <CardHeader {...props} />);

const StyledCardContent = withStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        flex: "2 0 auto",
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 8,
        paddingTop: 4,
        "&:last-child": {
            paddingBottom: 24,
        },
    },
}))((props: CardContentProps) => <CardContent {...props} />);

const StyledCardActions = withStyles((theme) => ({
    root: {
        flex: "1 1 0",
        display: "flex",

        alignItems: "flex-end",
        flexDirection: "row",
        flexWrap: "wrap",
        alignContent: "space-evenly",
        justifyContent: "flex-end",
        padding: 8,
    },
    /* Styles applied to the root element if `disableSpacing={false}`. */
    spacing: {
        "& > :not(:first-child)": {
            marginLeft: 8,
        },
    },
}))((props: CardActionsProps) => <CardActions {...props} />);

interface OwnProps {
    headerAction?: React.ReactNode;
    headerAvatar?: React.ReactNode;

    title?: React.ReactNode;
    subTitle?: React.ReactNode;

    mainContent?: React.ReactNode;
    mainContentStyle?: CSSProperties;

    mediaImageUrl?: string;
    mediaTitle?: string;

    actions?: React.ReactNode[];

    rootStyle?: CSSProperties;

    loading?: boolean;
    loadingSize?: number;
}

type Props = OwnProps;

const AcxCard: FunctionComponent<React.PropsWithChildren<Props>> = (props) => {
    const classes = useStyles(props);

    return (
        <StyledCard style={props.rootStyle} className={classes.root}>
            <StyledCardHeader
                avatar={props.headerAvatar}
                title={props.title as any}
                action={props.headerAction}
                subheader={props.subTitle}
            />

            <StyledCardContent style={props.mainContentStyle}>
                {props.loading ? (
                    <AcxLoadingIndicator
                        color="secondary"
                        alternate="PuffLoader"
                        size={props.loadingSize ?? 125}
                    />
                ) : (
                    props.mainContent
                )}
            </StyledCardContent>

            {Boolean(props.actions) && (
                <StyledCardActions disableSpacing>
                    {props.actions}
                </StyledCardActions>
            )}
        </StyledCard>
    );
};

export default AcxCard;
