import { chunk } from "lodash";
import {
    action,
    computed,
    makeObservable,
    observable,
    reaction,
    runInAction,
} from "mobx";
import type {
    AgentCalls,
    CallDurationStats,
    InteractionDateStats,
    RecommendedSample,
} from "models/RecommendedSample";
import React from "react";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";
import { v4 as uuid } from "uuid";
import AudioMetadataModel, {
    AudioMetadataSamplingStatus,
    AudioMetadataStatus,
    UISamplingStatus,
} from "../../../../../models/AudioMetadataModel";
import CallBatch from "../../../../../models/CallBatch";
import { OrganizationStructureMember } from "../../../../../models/OrganizationModels/OrganizationStructureMember";
import {
    AudioFilesService,
    SamplingType,
    SourceFile,
} from "../../../../../services/AudioFilesService";
import { ServiceError } from "../../../../../services/BaseService";
import {
    CallbatchService,
    ICallbatchService,
} from "../../../../../services/CallbatchService";
import { AuthStore } from "../../../../../stores/AuthStore";
import { BaseStore } from "../../../../../stores/BaseStore";
import { OrgSelectorComponentStore } from "../../../../../stores/ComponentStores/OrgSelectorComponentStore";
import { TableComponentStore } from "../../../../../stores/ComponentStores/TableComponentStore";
import { LayoutDrawerStore } from "../../../../../stores/Layout/LayoutDrawerStore";
import { OrganizationStore } from "../../../../../stores/OrganizationStore";
import { isStringType, isType } from "../../../../../utils/TypeGuards";
import {
    GenerateSampleSuggestionOp,
    UseSampleOp,
} from "../../AudioFileSampler/Stores/AudioFileSamplerStore";
import RecommendationStats from "../Views/RecommendationStep/RecommendationStats";
import StartSamplingDrawerContent from "../Views/RecommendationStep/StartSamplingDrawerContent";
import { ConfigurationStepStore } from "./ConfigurationStepStore";
import { SourceFilesStepStore } from "./SourceFilesStepStore";
import { StepperStore } from "./StepperStore";

export const LoadInterationAssignmentsTask = "Load Interaction Assignments";

@AcxStore
export class RecommendationStepStore extends BaseStore {
    private readonly audioFileService: AudioFilesService =
        new AudioFilesService();
    private readonly callbatchService: ICallbatchService =
        new CallbatchService();

    private readonly stepperStore: StepperStore;
    readonly orgSelectorStore: OrgSelectorComponentStore;
    readonly orgStore: OrganizationStore;
    readonly authStore: AuthStore;
    readonly sourceFilesStepStore: SourceFilesStepStore;
    readonly tableStore: TableComponentStore<AudioMetadataModel> =
        new TableComponentStore<AudioMetadataModel>(undefined, false);

    @observable interactionAssignmentDistribution: Map<
        number,
        { id: string; count: string }
    > = new Map<number, { id: string; count: string }>([
        [
            0,
            {
                id: "",
                count: "0",
            },
        ],
    ]);
    @observable hierarchyAssignmentDistribution: Map<
        number,
        { selections: OrganizationStructureMember[]; count: string }
    > = new Map<
        number,
        { selections: OrganizationStructureMember[]; count: string }
    >([[0, { selections: [], count: "0" }]]);

    @observable hierarchySelections: OrganizationStructureMember[] = [];
    @observable showSamplingDialog: boolean = false;
    @observable emptyRecommendationDialogVisible = false;
    @observable callBatches?: CallBatch[] = [];
    @observable agentCallDistribution?: AgentCalls[];
    @observable callDurationStats?: CallDurationStats;
    @observable interactionDateStats?: InteractionDateStats;
    @observable callBatchId: string;
    @observable samplingProgress = 0;
    @observable samplingType = SamplingType.BottomOfTheFunnel;
    @observable useBackgroundSampler = false;
    @observable samplingErrorsOccurred = false;
    @observable sampleInBackground = true;
    @observable extendedMetaLabels?: string[] = [];
    private samplingOpStarted = false;
    private focusPageDrawerStore: LayoutDrawerStore;

    constructor(private rootStore: IRootStore) {
        super("RecommendationStep Store");

        makeObservable(this);

        this.stepperStore = rootStore.getStore(StepperStore);
        this.sourceFilesStepStore = rootStore.getStore(SourceFilesStepStore);
        this.orgSelectorStore = this.sourceFilesStepStore.orgSelectStore;
        this.orgStore = rootStore.getStore(AuthStore).orgStore;
        this.authStore = rootStore.getStore(AuthStore);
        this.focusPageDrawerStore = this.rootStore.getStore(LayoutDrawerStore);

        this.stepperStore.addResetCallback(this.reset, 2);

        reaction(
            (r) => this.orgSelectorStore.orgId,
            (arg) => {
                this.reset();
                this.focusPageDrawerStore.reset();

                if (arg && !this.sourceFilesStepStore.isFromOutsideSampler) {
                    this.setupAsyncTask(
                        LoadInterationAssignmentsTask,
                        async () => {
                            const res =
                                await this.callbatchService.listCallbatches(
                                    arg,
                                );
                            runInAction(() => {
                                this.callBatches = res;
                            });
                        },
                    );
                }
            },
            { fireImmediately: true },
        );

        reaction(
            () =>
                this.rootStore.getStore(ConfigurationStepStore)
                    .hierarchySelections,
            (arg) => {
                this.hierarchySelections = arg;
            },
            { fireImmediately: true },
        );

        reaction(
            (r) => this.stepperStore.stepIndex,
            (whichStep) => {
                if (whichStep !== 2) {
                    this.focusPageDrawerStore.closeDrawer();
                }
            },
            { fireImmediately: true },
        );

        reaction(
            (r) => this.stepperStore.stepIndex,
            (whichStep) => {
                if (whichStep === 2) {
                    this.initializeDrawerWithRecommendationStats();
                }
            },
            { fireImmediately: true },
        );

        reaction(
            (r) => this.recommendationStatsData,
            (arg) => {
                this.focusPageDrawerStore.setContentFactory(() => (
                    <RecommendationStats
                        onClose={this.closeStatsDrawer}
                        dateStats={arg.dateStats}
                        agentCalls={arg.agentCalls}
                        durationStats={arg.durationStats}
                    />
                ));
            },
            { fireImmediately: true },
        );

        reaction(
            (r) => this.allItemsSampledSuccessfully,
            (arg) => {
                if (arg) {
                    this.stepperStore.nextStep();
                }
            },
            {},
        );

        reaction(
            (r) => ({
                isSampling: this.getTaskLoading(UseSampleOp),
                errorCount: this.errorItemsCount,
            }),
            (args) => {
                if (args.isSampling) {
                    this.samplingOpStarted = true;
                    this.stepperStore.disableSteps();
                } else {
                    this.stepperStore.enableSteps();
                    this.samplingErrorsOccurred =
                        this.samplingOpStarted &&
                        !args.isSampling &&
                        args.errorCount > 0;
                }
            },
            { fireImmediately: true },
        );
    }

    @action
    private initializeDrawerWithRecommendationStats() {
        this.focusPageDrawerStore.reset();
        this.focusPageDrawerStore.setAnchor("right");
        this.focusPageDrawerStore.setOffsetPixels(64);
        this.focusPageDrawerStore.setOffsetPosition("Top");

        if (
            this.interactionDateStats &&
            this.agentCallDistribution &&
            this.callDurationStats
        ) {
            this.focusPageDrawerStore.setContentFactory(() => (
                <RecommendationStats
                    onClose={this.closeStatsDrawer}
                    dateStats={this.interactionDateStats}
                    agentCalls={this.agentCallDistribution}
                    durationStats={this.callDurationStats}
                />
            ));
        }
    }

    @action
    private reset = () => {
        this.tableStore.reset();
        this.samplingErrorsOccurred = false;
        this.useBackgroundSampler = false;
        this.samplingOpStarted = false;
        this.agentCallDistribution = undefined;
        this.callDurationStats = undefined;
        this.interactionDateStats = undefined;
        this.callBatchId = "";
        this.interactionAssignmentDistribution.clear();
        this.interactionAssignmentDistribution.set(0, { id: "", count: "0" });
        this.hierarchyAssignmentDistribution.clear();
        this.hierarchyAssignmentDistribution.set(0, {
            selections: [],
            count: "0",
        });
        this.samplingProgress = 0;
        this.rootStore.getStore(LayoutDrawerStore).reset();
        this.samplingType = SamplingType.BottomOfTheFunnel;
    };

    @computed
    get allItemsSampledSuccessfully() {
        const totalAvailItems = this.tableStore.items.length;
        const completeItems = this.tableStore.items.filter(
            (value) => value.status === AudioMetadataStatus.Sampled,
        ).length;
        return totalAvailItems - completeItems === 0;
    }

    @computed
    get samplingProgressParams() {
        const isSampling = this.getTaskLoading(UseSampleOp);
        const params = this.samplingStatusCounts;

        if (isSampling) {
            return params;
        } else {
            return undefined;
        }
    }

    @computed
    get samplingStatusCounts() {
        const totalSelectedItems = this.tableStore.selectedItems.length;
        const totalItems = this.tableStore.items.length;
        const completeItems = this.sampledItemsCount;
        // [in-sample count, successfully completed count, out of sample count]
        return [
            totalSelectedItems,
            completeItems,
            totalItems - totalSelectedItems,
        ] as const;
    }

    @computed
    get errorItemsCount() {
        const res = this.tableStore.items.filter(
            (value) =>
                (value.status as UISamplingStatus)?.status ===
                AudioMetadataSamplingStatus.SamplingError,
        ).length;

        return res;
    }

    @computed
    get sampledItemsCount() {
        const completeItems = this.tableStore.items.filter(
            (value) => value.status === AudioMetadataStatus.Sampled,
        ).length;
        return completeItems;
    }

    @computed
    get canOpenStatsDrawer() {
        return (
            this.interactionDateStats &&
            this.agentCallDistribution &&
            this.callDurationStats
        );
    }

    @action
    openStatsDrawer = () => {
        this.initializeDrawerWithRecommendationStats();
        this.rootStore.getStore(LayoutDrawerStore).openDrawer();
    };

    @action
    closeStatsDrawer = () => {
        this.rootStore.getStore(LayoutDrawerStore).closeDrawer();
    };

    @computed
    get members() {
        return this.rootStore.getStore(ConfigurationStepStore).members;
    }

    @computed
    get levels() {
        return this.rootStore.getStore(ConfigurationStepStore).levels;
    }

    @action
    setHierarchySelection = (
        index: number,
        newValue: OrganizationStructureMember,
    ) => {
        this.rootStore
            .getStore(ConfigurationStepStore)
            .setHierarchySelection(index, newValue);
    };

    @action
    closeSamplingDialog = () => {
        this.showSamplingDialog = false;
        this.clearLastTaskError();
    };
    @action
    showStartSamplingDialog = () => {
        this.showSamplingDialog = true;
    };

    @action
    showStartSamplingDrawer = () => {
        this.focusPageDrawerStore.reset();
        this.focusPageDrawerStore.setAnchor("right");
        this.focusPageDrawerStore.setOffsetPixels(64);
        this.focusPageDrawerStore.setOffsetPosition("Top");

        this.focusPageDrawerStore.setContentFactory(() => (
            <StartSamplingDrawerContent store={this} />
        ));
        this.focusPageDrawerStore.openDrawer();
    };

    @action
    showEmptyRecommendationDialog() {
        this.emptyRecommendationDialogVisible = true;
    }

    @action
    closeEmptyRecommendationDialog = () => {
        this.emptyRecommendationDialogVisible = false;
    };

    @action setBackgroundSampling = (value: boolean) => {
        this.useBackgroundSampler = value;
    };

    @action
    useSample = (
        ignoreBatching: boolean = false,
        outsideSamplerCallback?: () => void,
    ) => {
        let totalCount = 0;
        const samplingDistributionMap = this.hierarchyAssignmentDistribution;
        const entries = [...samplingDistributionMap.entries()].sort(
            (a, b) => a[0] - b[0],
        );

        let allotmentTotal = 0;
        for (let entry of entries) {
            const allotment = parseInt(entry[1].count, 10);

            if (Number.isNaN(allotment)) {
                throw new Error(
                    `You must enter a valid number for all Allotment counts`,
                );
            }

            allotmentTotal += allotment;
        }

        const selectedItems = this.tableStore.selectedItems;
        const totalSelectedItems = selectedItems.length;
        if (allotmentTotal !== totalSelectedItems) {
            throw new Error(
                `You must ensure that Allotments sum (${allotmentTotal}) to total selected files: ${totalSelectedItems}`,
            );
        }

        // background sampling properties

        const samplingSessionId = uuid();

        this.setupAsyncTask(UseSampleOp, async () => {
            for (let keyValue of entries) {
                const value = keyValue[1];

                let legacyCallBatchId: string | null = null;
                let hierarchyId: string | null = null;

                const osmArray = (value as any)
                    .selections as OrganizationStructureMember[];
                if ((osmArray?.length ?? 0) > 0) {
                    hierarchyId = osmArray[osmArray.length - 1].id;
                }

                await this.useSampleInternal(
                    selectedItems.slice(
                        totalCount,
                        totalCount + parseInt(value.count, 10),
                    ),
                    legacyCallBatchId,
                    totalSelectedItems -
                        (totalCount + parseInt(value.count, 10)),
                    hierarchyId,
                    samplingSessionId,
                    ignoreBatching,
                );

                totalCount += parseInt(value.count, 10);
            }
            runInAction(() => {
                outsideSamplerCallback?.();
                this.showSamplingDialog = false;
                this.useBackgroundSampler = false;
                this.closeSamplingDialog();
                this.interactionAssignmentDistribution.clear();
                this.interactionAssignmentDistribution.set(0, {
                    id: "",
                    count: "0",
                });
                this.hierarchyAssignmentDistribution.clear();
                this.hierarchyAssignmentDistribution.set(0, {
                    selections: [],
                    count: "0",
                });

                const remainingSelected = this.tableStore.items
                    .filter(
                        (value) =>
                            (value.status as UISamplingStatus)?.status ===
                            AudioMetadataSamplingStatus.SamplingError,
                    )
                    .filter((failedItems) =>
                        selectedItems.some((x) => x.id === failedItems.id),
                    );

                this.tableStore.clearSelectedItems();

                const remainingItems = this.tableStore.items.filter(
                    (value) => value.status !== AudioMetadataStatus.Sampled,
                );

                this.tableStore.setItems(remainingItems);

                this.tableStore.selectedItems = remainingSelected;
            });
        });

        runInAction(() => {
            this.showSamplingDialog = !this.sampleInBackground;
            this.focusPageDrawerStore.closeAndResetDrawer();
        });
    };

    @action
    useSampleInternal = async (
        items: AudioMetadataModel[],
        batchId: string | null,
        remainingAfterBatch: number,
        hierarchyId: string | null,
        samplingSessionId: string,
        ignoreBatching: boolean = false,
    ) => {
        const recommendationList = items;

        const sourceFileArray = recommendationList?.map(
            (value) =>
                ({
                    // SourceFolder: this.samplingDirectory,
                    FileName: value.fileName,
                    Id: value.id,
                    DirectoryId: value?.origDirectoryId,
                } as SourceFile),
        );

        const orgId = this.orgSelectorStore.orgId;

        let orgStructMemberId: string | null = hierarchyId ?? null;

        if (!orgId) {
            throw new Error(`Invalid Organization Id`);
        }

        if (!sourceFileArray || !sourceFileArray.length) {
            console.warn(
                "Attempting to upload sample with empty/undefined recommendation list",
            );
            // this.finishSampling();
            return;
        }

        const chunkSize = ignoreBatching ? sourceFileArray.length : 5;
        const chunks = chunk(sourceFileArray, chunkSize);

        let isAnyErrors: boolean[] = [];
        let statusIndx = 0;

        const updateRecommendListStatus = (value: string, indx: number) => {
            runInAction(() => {
                if (value) {
                    const res = this.tableStore.items.find(
                        (value1) => value1.id === recommendationList[indx].id,
                    );
                    if (res) {
                        res.status = {
                            status: AudioMetadataSamplingStatus.SamplingError,
                            message: value,
                        } as UISamplingStatus;
                    }
                } else {
                    const res = this.tableStore.items.find(
                        (value1) => value1.id === recommendationList[indx].id,
                    );
                    if (res) {
                        res.status = AudioMetadataStatus.Sampled;
                    }
                }
            });
        };

        let remainingInBatch = sourceFileArray.length;
        for (const subArray of chunks) {
            if (subArray) {
                let resultsArray: string[] = [];

                try {
                    const isLastBatch =
                        remainingAfterBatch === 0 &&
                        remainingInBatch <= chunkSize;
                    const rv: string[] =
                        await this.audioFileService.selectAudioFilesSample(
                            orgId,
                            orgStructMemberId,
                            subArray,
                            isLastBatch,
                            this.samplingProgress,
                            samplingSessionId,
                            "/focus/audiofilesamplerv2",
                            this.samplingType,
                            this.useBackgroundSampler,
                        );

                    resultsArray = rv;
                } catch (err) {
                    if (err instanceof ServiceError) {
                        const errMsg = err.message;
                        resultsArray = subArray.map((value) => errMsg);
                    } else {
                        resultsArray = subArray.map(
                            (value) => "Network Failure",
                        );
                    }
                } finally {
                    this.samplingProgress +=
                        (subArray.length /
                            this.tableStore.selectedItems.length) *
                        100;
                    remainingInBatch -= chunkSize;
                }
                isAnyErrors.push(resultsArray.some((value) => Boolean(value)));

                for (const value of resultsArray) {
                    if (recommendationList[statusIndx]) {
                        updateRecommendListStatus(value, statusIndx);
                    }
                    statusIndx += 1;
                }
            }
        }

        if (!isAnyErrors.some((value) => value)) {
            //no errors happened; all uploaded successfully and finished sampling
        }
    };

    @action
    onCallBatchSelect = (callBatchId: unknown) => {
        if (isStringType(callBatchId)) {
            this.callBatchId = callBatchId;
        } else if (isType<CallBatch>(callBatchId, "batchId")) {
            if (callBatchId.batchId) {
                this.callBatchId = callBatchId.batchId;
            }
        }
    };

    @computed
    get recommendationStatsData() {
        return {
            agentCalls: this.agentCallDistribution,
            dateStats: this.interactionDateStats,
            durationStats: this.callDurationStats,
        };
    }

    @computed
    get isRecommendedSetLoading() {
        return this.rootStore
            .getStore(ConfigurationStepStore)
            .getTaskLoading(GenerateSampleSuggestionOp);
    }

    @action
    setRecommendedSample = (recommendation: RecommendedSample) => {
        this.extendedMetaLabels = [];
        this.tableStore.setItems(
            recommendation.extendedMetadataResults.length > 0
                ? recommendation.extendedMetadataResults.map((item) => {
                      item.item1.extendedMetadata = item.item2;
                      const tempLabels = Object.keys(item.item2);
                      if (tempLabels.length) {
                          tempLabels.forEach((labelString) => {
                              if (
                                  !this.extendedMetaLabels?.includes(
                                      labelString,
                                  )
                              ) {
                                  this.extendedMetaLabels?.push(labelString);
                              }
                          });
                      }
                      return item.item1;
                  })
                : recommendation.results,
        );
        this.tableStore.setSelectedItems(
            recommendation.extendedMetadataResults.map((item) => item.item1.id),
        );
        this.agentCallDistribution = recommendation.agentCallDistribution;
        this.callDurationStats = recommendation.callDurationStats;
        this.interactionDateStats = recommendation.interactionDateStats;
    };

    @action
    setSamplingType(samplingType: SamplingType) {
        this.samplingType = samplingType;
    }
}
