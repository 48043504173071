import React from "react";
// import { Route, Switch, useRouteMatch } from "react-router";
import useStyles from "Styles/Styles";
import OrganizationWorkflowsWrapper from "./OrganizationWorkflowsWrapper";

type Props = {
    id: string;
};

const OrganizationWorkflows: React.FC<Props> = ({ id }) => {
    // const { path } = useRouteMatch();
    const classes = useStyles(() => {
        return {};
    });

    return (
        // <Switch>
        // <Route path={path} exact>
        // </Route>
        // </Switch>
        <div className={classes.appContent}>
            <OrganizationWorkflowsWrapper orgId={id} />
        </div>
    );
};

export default OrganizationWorkflows;
