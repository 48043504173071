import { Drawer, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useResizeObserver } from "../../../utils/useResizeObserver";

function isVerticalDrawer(props: Props) {
    return props.anchor === "left" || props.anchor === "right";
}

const useStyles = makeStyles((theme: Theme) => ({
    drawer: (props: Props) => ({
        flexShrink: 0,
        whiteSpace: "nowrap",
        position: "absolute",
        overflowY: isVerticalDrawer(props) ? "auto" : "auto",
        overflowX: isVerticalDrawer(props) ? "hidden" : "auto",
        top: `calc(100% - ${
            (props.open ? props.maxSizePx : props.miniSizePx) ?? 0
        }px)`,
        left:
            props.alternateWidth !== undefined
                ? `${props.alternateLeftPosition}px`
                : undefined,
    }),

    drawerPaper: (props: Props) => ({
        left:
            props.alternateWidth !== undefined
                ? `${props.alternateLeftPosition}px`
                : undefined,

        boxShadow: theme.shadows[5],
        [`padding${props.offsetPosition ?? ""}`]: `${props.offsetPx ?? 0}px`,
    }),

    drawerOpen: (props: Props) => ({
        width: isVerticalDrawer(props)
            ? "auto"
            : props.alternateWidth !== undefined
            ? `${props.alternateWidth}px`
            : "100%",
        height: isVerticalDrawer(props)
            ? "100%"
            : props.maxSizePx !== undefined
            ? `${props.maxSizePx}px`
            : `auto`,

        overflowY: isVerticalDrawer(props) ? "auto" : "auto",
        overflowX: isVerticalDrawer(props) ? "hidden" : "auto",
    }),
    drawerCloseMini: (props: Props) => ({
        width: isVerticalDrawer(props)
            ? `${props.miniSizePx ?? 0}px`
            : props.alternateWidth !== undefined
            ? `${props.alternateWidth}px`
            : "100%",
        height: isVerticalDrawer(props) ? "100%" : `${props.miniSizePx ?? 0}px`,

        overflowY: isVerticalDrawer(props) ? "auto" : "auto",
        overflowX: isVerticalDrawer(props) ? "hidden" : "auto",
    }),

    drawerClose: (props: Props) => ({
        width: isVerticalDrawer(props) ? 0 : "100%",
        height: isVerticalDrawer(props) ? "100%" : 0,
    }),
}));

export interface DrawerStore {
    setOpen(isOpen: boolean);
    reset();
}

interface OwnProps {
    anchor: "left" | "top" | "right" | "bottom";
    offsetPosition?: "Left" | "Top" | "Right" | "Bottom";
    offsetPx?: number;
    miniSizePx?: number;
    maxSizePx?: number;
    open: boolean;
    content?: React.ReactNode;
    alternateWidth?: number;
    alternateLeftPosition?: number;
    variant?: "permanent" | "persistent" | "temporary";
    onDrawerClose?: () => void;
    onDrawerOpen?: () => void;
    collapsible: "mini" | "full";
    hidden?: boolean;
    hideBackdrop?: boolean;
    disableBackdropClick?: boolean;
    disableBackdrop?: boolean;
    transitionDuration?: React.ComponentProps<
        typeof Drawer
    >["transitionDuration"];

    // drawer stays closed while drawer-content is loading
    loading?: boolean;
    drawerStore?: DrawerStore;
    customRootStyles?: React.CSSProperties;
    customPaperClasses?: string;
    onDrawerResize?: (width: number, height: number) => void;
    disableEnforceFocus?: boolean;
}

type Props = OwnProps;

const AcxDrawer: React.FC<Props> = (props) => {
    const classes = useStyles(props);
    const location = useLocation();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const resizeObj = useResizeObserver<HTMLDivElement>({
        name: "AcxDrawer",
        pixelDelta: 1,
        onResize: props.onDrawerResize,
    });

    useEffect(() => {
        props.drawerStore?.reset();
    }, [location.pathname, props.drawerStore]);

    const drawerOpen = props.open;
    return (
        <>
            {!props.hidden && (
                <Drawer
                    ref={resizeObj.theRef}
                    style={{
                        ...props.customRootStyles,
                        ...{ visibility: props.loading ? "hidden" : "visible" },
                    }}
                    variant={props.variant}
                    elevation={3}
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: drawerOpen,
                        [classes.drawerCloseMini]:
                            !drawerOpen && props.collapsible === "mini",
                        [classes.drawerClose]:
                            !drawerOpen && props.collapsible === "full",
                    })}
                    classes={{
                        paper: clsx(
                            {
                                [classes.drawerPaper]: true,
                                [classes.drawerOpen]: drawerOpen,
                                [classes.drawerCloseMini]:
                                    !drawerOpen && props.collapsible === "mini",
                                [classes.drawerClose]:
                                    !drawerOpen && props.collapsible === "full",
                            },
                            props.customPaperClasses,
                        ),
                    }}
                    ModalProps={{
                        keepMounted: true,
                        hideBackdrop: props.disableBackdrop ?? true,
                        onClose: (event, reason) => {
                            if (reason === "backdropClick") {
                                // do nothing, this replaces deprecated disableBackdropClick
                            }
                        },
                        disableEnforceFocus: props.disableEnforceFocus,
                    }}
                    BackdropProps={{ invisible: props.disableBackdrop ?? true }}
                    SlideProps={{ mountOnEnter: true }}
                    anchor={props.anchor}
                    open={drawerOpen}
                    onClose={props.onDrawerClose}
                    transitionDuration={props.transitionDuration}
                >
                    {props.content}
                </Drawer>
            )}
        </>
    );
};

export default AcxDrawer;
