import { Box, Collapse, Grid, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import theme from "Theme/AppTheme";
import ConversationsStore from "components/Conversations/Stores/ConversationsStore";
import { Routes } from "components/Navigation/Routes";
import AcxButton from "components/UI/AcxButton";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import { observer } from "mobx-react";
import React from "react";
import { useNavigate } from "react-router";
import { customSecondaryButtonStyle } from "utils/Style";
import { useStore } from "utils/useStore";

const useConversationEvalCardStyles = makeStyles((theme: Theme) => ({
    paddingTop: {
        paddingTop: theme.spacing(1),
    },
    title: {
        fontSize: "14px",
        fontWeight: 400,
    },
    score: {
        fontSize: "20px",
        fontWeight: 700,
    },
    moduleScoreContainer: {
        paddingTop: theme.spacing(1),
    },
    module: {
        padding: theme.spacing(0.5, 0),
    },
    padding: {
        padding: theme.spacing(2),
    },
    loading: {
        paddingRight: theme.spacing(5),
        paddingTop: theme.spacing(1),
    },
}));

type Props = {
    evaluationId: string;
    evaluationNumber: number;
};

const ConversationEvalCard: React.FunctionComponent<Props> = observer(
    (props) => {
        const navigate = useNavigate();
        const store = useStore(ConversationsStore);

        const classes = useConversationEvalCardStyles();

        const collapseIn =
            !!store.selectedConversation?.evaluationId &&
            !!store.evaluationScores.length;
        return (
            <Grid>
                <Grid item container xs={12} className={classes.padding}>
                    <Grid item container xs={12}>
                        <Box fontWeight="bold">
                            Evaluation #{props.evaluationNumber}
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Collapse in={store.evalScoresLoading}>
                            <Box className={classes.loading}>
                                <AcxLoadingIndicator
                                    size={40}
                                    alternate="PuffLoader"
                                />
                            </Box>
                        </Collapse>
                    </Grid>
                    <Grid item xs={12} container>
                        <Collapse in={collapseIn}>
                            <Box className={classes.moduleScoreContainer}>
                                {store.evaluationScores.map((module, idx) => (
                                    <Grid
                                        item
                                        xs={12}
                                        key={`${idx} ${module.name}`}
                                        className={classes.module}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: "14px",
                                                fontWeight: 400,
                                            }}
                                        >
                                            {module.name}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: "20px",
                                                fontWeight: 700,
                                            }}
                                        >
                                            {Number(
                                                module.moduleScore.toFixed(2),
                                            )}
                                        </Typography>
                                    </Grid>
                                ))}
                            </Box>
                        </Collapse>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={12}
                        className={classes.paddingTop}
                        justifyContent={"center"}
                    >
                        <AcxButton
                            id="conversation-eval-card-view-eval-button"
                            color="default"
                            customRootStyles={{
                                ...customSecondaryButtonStyle,

                                width: theme.spacing(18),
                            }}
                            leftRightSpacing={0}
                            onClick={() => {
                                if (
                                    store.authStore.orgStore
                                        .selectedOrganization?.id &&
                                    props.evaluationId
                                ) {
                                    navigate(
                                        Routes.makeEvaluationRoute(
                                            store.authStore.orgStore
                                                .selectedOrganization?.id,
                                            props.evaluationId,
                                        ),
                                    );
                                }
                            }}
                        >
                            View Evaluation
                        </AcxButton>
                    </Grid>
                </Grid>
            </Grid>
        );
    },
);

export default ConversationEvalCard;
