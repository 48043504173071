import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    FormControlLabel,
    Grid,
    Theme,
    Typography,
} from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import AcxButton from "components/UI/AcxButton";
import AcxMainTextField from "components/UI/AcxMainTextField";
import AcxTable from "components/UI/AcxTable/AcxTable";
import AcxDialog from "components/UI/Dialog/AcxDialog";
import AcxSelectSingle from "components/UI/Select/BaseSelectComponents/AcxSelectSingle";
import React, { useEffect, useState } from "react";
import { useForm } from "shared/hooks/useForm";
import useStyles from "Styles/Styles";
import theme from "Theme/AppTheme";
import { useStore } from "utils/useStore";
import OrganizationService from "../../Organization.service";
import {
    Saml2BindingTypeHash,
    saml2BindingTypes,
    SAMLDataRequest,
} from "../../types/Organization.type";

const styles = (theme: Theme) => {
    return createStyles({
        container: {
            marginTop: 16,
        },
        formContainer: {
            paddingTop: 0,
            paddingBottom: theme.spacing(4),
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
            width: "100%",
            overflowY: "auto",
        },
        heading: {
            fontSize: theme.typography.pxToRem(16),
            flexBasis: "60%",
            flexShrink: 0,
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
        },
        secondaryHeading: {
            fontSize: theme.typography.pxToRem(12),
            color: theme.palette.text.secondary,
            width: "100%",
        },
        lineLeft: {
            display: "flex",
            alignItems: "center",
            flexBasis: "60%",
            flexShrink: 0,
        },
        lineMiddle: {
            display: "flex",
            flexBasis: "20%",
            flexShrink: 0,
        },
        lineRight: {
            textAlign: "right",
            flexBasis: "20%",
            flexShrink: 0,
            display: "flex",
            alignItems: "center",
        },
        actions: {
            display: "flex",
            alignItems: "flex-end",
            padding: "24px",
            width: "100%",
        },
    });
};

const validation = {
    authenticxDomain: {
        function: {
            value: (values: SAMLDataRequest) => {
                const { enableSamlSso, authenticxDomain } = values;
                if (enableSamlSso && !authenticxDomain) {
                    return "Authenticx domain is required.";
                }
                return false;
            },
        },
    },
    bindingType: {
        function: {
            value: (values: SAMLDataRequest) => {
                const { enableSamlSso, bindingType } = values;
                if (enableSamlSso && !bindingType) {
                    return "Binding type is required.";
                }
                return false;
            },
        },
    },
    certificateInPemFormat: {
        function: {
            value: (values: SAMLDataRequest) => {
                const { enableSamlSso, certificateInPemFormat } = values;
                if (enableSamlSso && !certificateInPemFormat) {
                    return "Certificate in PEM format is required.";
                }
                return false;
            },
        },
    },
    entityId: {
        function: {
            value: (values: SAMLDataRequest) => {
                const { enableSamlSso, entityId } = values;
                if (enableSamlSso && !entityId) {
                    return "Entity ID is required.";
                }
                return false;
            },
        },
    },
    loginButtonText: {
        function: {
            value: (values: SAMLDataRequest) => {
                const { enableSamlSso, loginButtonText } = values;
                if (enableSamlSso && !loginButtonText) {
                    return "Login button text is required.";
                }
                return false;
            },
        },
    },
    samlResponseEmailPropertyName: {
        function: {
            value: (values: SAMLDataRequest) => {
                const { enableSamlSso, samlResponseEmailPropertyName } = values;
                if (enableSamlSso && !samlResponseEmailPropertyName) {
                    return "Response email property name is required.";
                }
                return false;
            },
        },
    },
    responseFirstNamePropertyName: {
        function: {
            value: (values: SAMLDataRequest) => {
                const { enableSamlSso, samlResponseFirstNamePropertyName } =
                    values;
                if (enableSamlSso && !samlResponseFirstNamePropertyName) {
                    return "Response first name property name is required.";
                }
                return false;
            },
        },
    },
    responseLastNamePropertyName: {
        function: {
            value: (values: SAMLDataRequest) => {
                const { enableSamlSso, samlResponseLastNamePropertyName } =
                    values;
                if (enableSamlSso && !samlResponseLastNamePropertyName) {
                    return "Response last name property name is required.";
                }
                return false;
            },
        },
    },
    singleSignOnServiceUrl: {
        function: {
            value: (values: SAMLDataRequest) => {
                const { enableSamlSso, singleSignOnServiceUrl } = values;
                if (enableSamlSso && !singleSignOnServiceUrl) {
                    return "Response sign on URL is required.";
                }
                return false;
            },
        },
    },
    // singleSignOutServiceUrl:
    //   organization?.saml2SingleSignOutServiceUrl,
};

const logTypes = ["", "Info", "Warning", "Error"];

const logColumns = [
    {
        headerLabel: "Date",
        dataKey: "createdOn",
        formatter: (arg) => {
            const dateString = new Date(arg)?.toLocaleString();
            return dateString !== "Invalid Date" ? dateString : "";
        },
    },
    {
        headerLabel: "Type",
        dataKey: "type",
        formatter: (arg) => logTypes[arg],
    },
    {
        headerLabel: "Message",
        dataKey: "message",
    },
];

type Props = {
    isLoading: boolean;
    defaultValues: SAMLDataRequest;
    organizationId?: string;
};

const SAMLSettings: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
    isLoading,
    defaultValues,
    organizationId,
}) => {
    const classes = useStyles(styles);
    const [isOpen, setIsOpen] = useState(false);
    const [isLogsOpen, setIsLogsOpen] = useState(false);
    const [SAMLPageNumber, setSAMLPageNumber] = useState(1);
    const [logRows, setLogRows] = useState<any[]>(["loading"]);
    const { value, registerSubmit, errors, onChange, isSubmitting, setValue } =
        useForm<SAMLDataRequest>(defaultValues, validation);
    const messageStore = useStore(MessageStore);

    const toggleAccordion = () => {
        setIsOpen((prev) => !prev);
    };

    const submit = () => {
        return OrganizationService.saveSAMLOptions({
            ...value,
            organizationId,
        });
    };

    const onSuccess = () => {
        messageStore.logMessage(`Successfully saved SAML options.`, "success");
    };

    const onFail = () => {
        messageStore.logMessage(`Failed to save SAML options.`, "error");
    };

    const logItemCount = 10;

    useEffect(() => {
        if (!isLogsOpen) return;
        setLogRows(["loading"]);
        const offset = (SAMLPageNumber - 1) * logItemCount;
        OrganizationService.getLogsForOrganization(
            organizationId!,
            offset,
            logItemCount,
        )
            .then((data) => setLogRows(data))
            .catch(() => setLogRows([]));
    }, [SAMLPageNumber, isLogsOpen, organizationId]);

    return (
        <Accordion
            square
            expanded={isOpen}
            onChange={toggleAccordion}
            className={classes.container}
        >
            <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
            >
                <Typography className={classes.heading}>
                    {isOpen ? (
                        <KeyboardArrowDownIcon />
                    ) : (
                        <KeyboardArrowRightIcon />
                    )}{" "}
                    SAML SSO Options
                </Typography>
                <div className={classes.lineRight}>
                    <Typography className={classes.secondaryHeading}>
                        {value?.enableSamlSso ? "Enabled" : "Disabled"}
                    </Typography>
                </div>
            </AccordionSummary>
            <AccordionDetails className={classes.formContainer}>
                <Grid
                    container
                    spacing={1}
                    style={{ paddingBottom: theme.spacing(1) }}
                >
                    <Grid item xs={12}>
                        <AcxDialog
                            isOpen={isLogsOpen}
                            maxWidth="lg"
                            onClose={() => setIsLogsOpen(false)}
                            title="SAML Logs"
                            text=""
                            dialogContentChildren={
                                <AcxTable
                                    rows={logRows}
                                    columns={logColumns}
                                    isLoading={logRows[0] === "loading"}
                                />
                            }
                            children={
                                <>
                                    <AcxButton
                                        onClick={() => {
                                            setSAMLPageNumber((prev) =>
                                                prev > 1 ? prev - 1 : 1,
                                            );
                                        }}
                                        buttonDisabled={
                                            SAMLPageNumber === 1 ||
                                            logRows[0] === "loading"
                                        }
                                    >
                                        Previous Page
                                    </AcxButton>
                                    <div style={{ width: "10rem" }}>
                                        Page: {SAMLPageNumber}
                                    </div>
                                    <AcxButton
                                        onClick={() => {
                                            setSAMLPageNumber(
                                                (prev) => prev + 1,
                                            );
                                        }}
                                        buttonDisabled={
                                            logRows[0] === "loading" ||
                                            logRows.length < logItemCount
                                        }
                                    >
                                        Next Page
                                    </AcxButton>
                                </>
                            }
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={(e) =>
                                        e.target.checked
                                            ? onChange(
                                                  "enableSamlSso",
                                                  e.target.checked,
                                              )
                                            : setValue({ enableSamlSso: false })
                                    }
                                    id="enableSamlSso"
                                    name="enableSamlSso"
                                    checked={value?.enableSamlSso || false}
                                    disabled={isLoading}
                                />
                            }
                            label="Enable SAML SSO"
                        />
                    </Grid>
                    {value?.enableSamlSso && (
                        <>
                            <Grid item xs={6}>
                                <AcxMainTextField
                                    skeleton={isLoading}
                                    onChange={(e) =>
                                        onChange(
                                            "authenticxDomain",
                                            e.target.value,
                                        )
                                    }
                                    id="authenticxDomain"
                                    labelText="Authenticx Domain"
                                    value={value?.authenticxDomain}
                                    error={
                                        !!errors?.fieldErrors?.authenticxDomain
                                    }
                                    helperText={
                                        errors?.fieldErrors?.authenticxDomain
                                    }
                                    showAllErrors={true}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <AcxSelectSingle
                                    inputLabel="Binding Type"
                                    id="bindingType"
                                    valueField="value"
                                    labelField="label"
                                    options={saml2BindingTypes}
                                    defaultValue={
                                        value?.bindingType
                                            ? Saml2BindingTypeHash[
                                                  value?.bindingType
                                              ]
                                            : undefined
                                    }
                                    onChange={(val) =>
                                        onChange("bindingType", val.value)
                                    }
                                    error={!!errors?.fieldErrors?.bindingType}
                                    helperText={
                                        errors?.fieldErrors?.bindingType
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <AcxMainTextField
                                    skeleton={isLoading}
                                    onChange={(e) =>
                                        onChange("entityId", e.target.value)
                                    }
                                    id="entityId"
                                    labelText="Entity ID"
                                    value={value?.entityId}
                                    error={!!errors?.fieldErrors?.entityId}
                                    helperText={errors?.fieldErrors?.entityId}
                                    showAllErrors={true}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <AcxMainTextField
                                    skeleton={isLoading}
                                    onChange={(e) =>
                                        onChange(
                                            "loginButtonText",
                                            e.target.value,
                                        )
                                    }
                                    id="loginButtonText"
                                    labelText="Login button text"
                                    value={value?.loginButtonText}
                                    error={
                                        !!errors?.fieldErrors?.loginButtonText
                                    }
                                    helperText={
                                        errors?.fieldErrors?.loginButtonText
                                    }
                                    showAllErrors={true}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AcxMainTextField
                                    skeleton={isLoading}
                                    onChange={(e) =>
                                        onChange(
                                            "samlResponseEmailPropertyName",
                                            e.target.value,
                                        )
                                    }
                                    id="samlResponseEmailPropertyName"
                                    labelText="Response email property name"
                                    value={value?.samlResponseEmailPropertyName}
                                    error={
                                        !!errors?.fieldErrors
                                            ?.samlResponseEmailPropertyName
                                    }
                                    helperText={
                                        errors?.fieldErrors
                                            ?.samlResponseEmailPropertyName
                                    }
                                    showAllErrors={true}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <AcxMainTextField
                                    skeleton={isLoading}
                                    onChange={(e) =>
                                        onChange(
                                            "samlResponseFirstNamePropertyName",
                                            e.target.value,
                                        )
                                    }
                                    id="responseFirstNamePropertyName"
                                    labelText="Response first name property name"
                                    value={
                                        value?.samlResponseFirstNamePropertyName
                                    }
                                    error={
                                        !!errors?.fieldErrors
                                            ?.responseFirstNamePropertyName
                                    }
                                    helperText={
                                        errors?.fieldErrors
                                            ?.responseFirstNamePropertyName
                                    }
                                    showAllErrors={true}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <AcxMainTextField
                                    skeleton={isLoading}
                                    onChange={(e) =>
                                        onChange(
                                            "samlResponseLastNamePropertyName",
                                            e.target.value,
                                        )
                                    }
                                    id="responseLastNamePropertyName"
                                    labelText="Response last name property name"
                                    value={
                                        value?.samlResponseLastNamePropertyName
                                    }
                                    error={
                                        !!errors?.fieldErrors
                                            ?.responseLastNamePropertyName
                                    }
                                    helperText={
                                        errors?.fieldErrors
                                            ?.responseLastNamePropertyName
                                    }
                                    showAllErrors={true}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <AcxMainTextField
                                    skeleton={isLoading}
                                    onChange={(e) =>
                                        onChange(
                                            "singleSignOnServiceUrl",
                                            e.target.value,
                                        )
                                    }
                                    id="singleSignOnServiceUrl"
                                    labelText="Single sign on service URL"
                                    value={value?.singleSignOnServiceUrl}
                                    error={
                                        !!errors?.fieldErrors
                                            ?.singleSignOnServiceUrl
                                    }
                                    helperText={
                                        errors?.fieldErrors
                                            ?.singleSignOnServiceUrl
                                    }
                                    showAllErrors={true}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <AcxMainTextField
                                    skeleton={isLoading}
                                    onChange={(e) =>
                                        onChange(
                                            "singleSignOutServiceUrl",
                                            e.target.value,
                                        )
                                    }
                                    id="singleSignOutServiceUrl"
                                    labelText="Single sign out service URL"
                                    value={value?.singleSignOutServiceUrl}
                                    error={
                                        !!errors?.fieldErrors
                                            ?.singleSignOutServiceUrl
                                    }
                                    helperText={
                                        errors?.fieldErrors
                                            ?.singleSignOutServiceUrl
                                    }
                                    showAllErrors={true}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AcxMainTextField
                                    skeleton={isLoading}
                                    onChange={(e) =>
                                        onChange(
                                            "certificateInPemFormat",
                                            e.target.value,
                                        )
                                    }
                                    id="certificateInPemFormat"
                                    labelText="Certificate in PEM format"
                                    value={value?.certificateInPemFormat}
                                    error={
                                        !!errors?.fieldErrors
                                            ?.certificateInPemFormat
                                    }
                                    helperText={
                                        errors?.fieldErrors
                                            ?.certificateInPemFormat
                                    }
                                    showAllErrors={true}
                                    multiline={true}
                                    rows={10}
                                />
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12} className={classes.actions}>
                        <div style={{ marginLeft: "auto", display: "flex" }}>
                            <AcxButton
                                onClick={() => {
                                    OrganizationService.getLogsForOrganization(
                                        organizationId!,
                                        0,
                                        logItemCount,
                                    )
                                        .then((data) => setLogRows(data))
                                        .catch(() => setLogRows([]));
                                    setIsLogsOpen(true);
                                }}
                                fullWidth={false}
                            >
                                SAML Logs
                            </AcxButton>
                            <AcxButton
                                onClick={registerSubmit(submit, {
                                    onSuccess,
                                    onFail,
                                })}
                                color="secondary"
                                leftRightSpacing={0}
                                loading={isSubmitting}
                                buttonDisabled={isSubmitting}
                            >
                                Save
                            </AcxButton>
                        </div>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

export default SAMLSettings;
