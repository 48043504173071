import { Close } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import AcxChip from "components/UI/AcxChip";
import { observer } from "mobx-react";
import React from "react";

const useStyles = makeStyles((theme) => ({
    chipContents: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "40ch",
    },
}));

interface AppliedFilterChipProps {
    isApplicable: boolean;
    onRemove?(): void;
}

const AppliedFilterChipBase = observer(function (
    props: React.PropsWithChildren<AppliedFilterChipProps>,
) {
    const classes = useStyles();

    return (
        <Grid item>
            <AcxChip
                size="small"
                color={props.isApplicable ? "lightBlue" : "gray"}
                label={
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        spacing={0.5}
                        flexWrap="nowrap"
                    >
                        <Grid item className={classes.chipContents}>
                            <span>{props.children}</span>
                        </Grid>
                        {props.onRemove && (
                            <Grid item>
                                <IconButton
                                    size="small"
                                    color={
                                        props.isApplicable
                                            ? "primary"
                                            : "default"
                                    }
                                    onClick={() => props.onRemove?.()}
                                >
                                    <Close fontSize="small" />
                                </IconButton>
                            </Grid>
                        )}
                    </Grid>
                }
                showToolTip={!props.isApplicable}
                customTooltip={
                    !props.isApplicable ? "Filter not applied." : undefined
                }
            />
        </Grid>
    );
});

export default AppliedFilterChipBase;
