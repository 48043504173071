import { Box, Grid, Theme, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";
import { ConversationClassifier } from "services/ConversationService";
import AcxChip, { AcxChipPropsIcon } from "components/UI/AcxChip";
import AddIcon from "@mui/icons-material/Add";
import { useStore } from "utils/useStore";
import { AuthStore } from "stores/AuthStore";
import { ApplicationFilterValuesStore } from "stores/ApplicationFilters/ApplicationFilterValuesStore";

const useConversationClassifierChipListStyles = makeStyles((theme: Theme) => ({
    chipListWrapper: () => ({
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        maxWidth: "none",
    }),
    chipContainer: {
        flexShrink: 1,
        overflow: "hidden",
    },
}));

type Props = {
    conversationClassifiers: ConversationClassifier[];
    showAllChips?: boolean;
    selectedClassifierIds?: string[];
    showSelectedOnly?: boolean;
};

const ConversationClassifierChipList: React.FunctionComponent<Props> = observer(
    ({
        conversationClassifiers,
        showAllChips,
        selectedClassifierIds,
        showSelectedOnly,
    }) => {
        const authStore = useStore(AuthStore);
        const applicationFilterValuesStore = useStore(
            ApplicationFilterValuesStore,
        );
        const classes = useConversationClassifierChipListStyles();

        const [sortedTags, setSortedTags] = useState<ConversationClassifier[]>(
            [],
        );
        const [tooltipTags, setTooltipTags] = useState<
            ConversationClassifier[]
        >([]);

        const chipMaxWidth = "200px";

        const hideSafetyEvents = !authStore.canUserView("Safety Events Model");

        const hideHipaaCompliance = !authStore.canUserView(
            "HIPAA Compliance Model",
        );

        const hideTopicCluster = !authStore.canUserView("Topics");

        const classifierTagPredicate = useCallback(
            (item: ConversationClassifier) => {
                const safetyEventTags = [
                    "ML-AdverseEvents-P2",
                    "ML-AdverseEvents",
                    "ML-SE-Identification",
                    "ML-SE-Acknowledgement",
                    "ML-EddyEffect-P2",
                    "ML-Sentiment",
                ];

                const hiddenTags = [
                    "ML-EddyEffect-P2",
                    "ML-Sentiment",
                    "ML-AdverseEvents-P2",
                    "ML-Topic-LLM",
                ];

                const shouldHideSafetyEvents =
                    hideSafetyEvents && safetyEventTags.includes(item.name);

                const shouldHideHipaaCompliance =
                    hideHipaaCompliance && item.name === "ML-Hipaa";

                return (
                    !shouldHideSafetyEvents &&
                    !shouldHideHipaaCompliance &&
                    (item.result !== "false" ||
                        (item.result === "false" &&
                            item.name === "ML-SE-Acknowledgement")) &&
                    !hiddenTags.includes(item.name)
                );
            },
            [hideSafetyEvents, hideHipaaCompliance],
        );

        useEffect(() => {
            if (!conversationClassifiers) return;
            const tags = conversationClassifiers.filter(classifierTagPredicate);
            tags.sort((a, b) => {
                if (a.score < b.score) {
                    return 1;
                } else {
                    return -1;
                }
            });
            if (selectedClassifierIds?.length) {
                tags.sort((a, b) => {
                    if (selectedClassifierIds?.includes(a.classifierId)) {
                        return -1;
                    } else {
                        return 1;
                    }
                });
            }
            tags.sort((a, b) => {
                if (
                    a.classifierType === "Tensorflow" &&
                    a.name !== "ML-Sentiment"
                ) {
                    return -1;
                } else {
                    return 1;
                }
            });
            if (showAllChips) {
                setSortedTags(tags);
                return;
            }
            let sliceCount = 9;
            let sliceAdjustment = 0;
            if (
                !!tags.filter((item) => item.name === "ML-SentimentV2").length
            ) {
                // Change slice count when sentimentV2 is present because it creates 2 chips with very long labels
                sliceAdjustment += 3;
            }

            if (!hideTopicCluster) {
                sliceAdjustment += 1;
            }

            sliceCount = sliceCount - sliceAdjustment;
            if (showSelectedOnly && selectedClassifierIds) {
                tags.sort((a, b) => {
                    if (selectedClassifierIds.includes(a.classifierId)) {
                        return -1;
                    } else {
                        return 1;
                    }
                });
                sliceCount = selectedClassifierIds.length;
            }
            if (tags.length > sliceCount) {
                const showTags = tags.slice(0, sliceCount);
                const tooltipTags = tags.slice(sliceCount);
                setSortedTags(showTags);
                setTooltipTags(tooltipTags);
            } else {
                setSortedTags(tags);
                setTooltipTags([]);
            }
        }, [
            classifierTagPredicate,
            conversationClassifiers,
            hideTopicCluster,
            selectedClassifierIds,
            showAllChips,
            showSelectedOnly,
        ]);

        return (
            <Grid
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    maxWidth: "none",
                }}
            >
                {sortedTags.map((item, index) => {
                    if (item.name === "ML-SentimentV2") {
                        return getChipsForEddySentiment(item, index, classes);
                    } else if (item.name === "ML-Topic-Cluster") {
                        return getChipsForTopicClusters(item, index, classes);
                    }
                    const [label, color, icon] = getChipForClassifier(
                        item,
                        applicationFilterValuesStore,
                    );
                    return (
                        <Box
                            sx={{
                                flexShrink: 1,
                                overflow: "hidden",
                                paddingRight: 0.5,
                            }}
                            key={"sorted" + index}
                        >
                            <AcxChip
                                label={label}
                                size="small"
                                color={color as any}
                                style={{ maxWidth: chipMaxWidth }}
                                icon={icon}
                            />
                        </Box>
                    );
                })}
                {!!tooltipTags.length && (
                    <Tooltip
                        title={tooltipTags.map((tag, index) => (
                            <div key={"tooltip" + index}>{tag.name}</div>
                        ))}
                    >
                        <Box
                            sx={{
                                flexShrink: 1,
                                overflow: "hidden",
                                paddingRight: 0.5,
                            }}
                        >
                            <AcxChip
                                label={
                                    <Grid
                                        container
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <AddIcon
                                            style={{
                                                height: "14px",
                                                width: "14px",
                                            }}
                                        />
                                        <Grid
                                            item
                                            style={{
                                                height: "16px",
                                            }}
                                        >
                                            {tooltipTags.length}
                                        </Grid>
                                    </Grid>
                                }
                                size="small"
                                color={"lightGray"}
                            />
                        </Box>
                    </Tooltip>
                )}
            </Grid>
        );
    },
);

function getChipForClassifier(
    classifier: ConversationClassifier,
    store: ApplicationFilterValuesStore,
): [React.ReactNode, string, AcxChipPropsIcon | undefined] {
    let color = "lightGray";
    let label: string | React.ReactNode = classifier.name;
    let icon: AcxChipPropsIcon | undefined;
    // parse only if valid JSON
    const parseResult = (result) => {
        if (typeof result === "string") {
            if (checkForJson(result)) {
                try {
                    return JSON.parse(result);
                } catch (parseError) {
                    console.error("Error parsing JSON:", parseError);
                    return null;
                }
            }
            return { Label: result };
        }
        return result;
    };

    const checkForJson = (str) => {
        return (
            (str.startsWith("{") && str.endsWith("}")) ||
            (str.startsWith("[") && str.endsWith("]"))
        );
    };

    const result = parseResult(classifier.result);

    if (!result) {
        console.log("No valid result found.");
        return [label, color, icon];
    }

    if (classifier.classifierType === "Tensorflow") {
        switch (classifier.name) {
            case "ML-EddyEffect":
                label = "Eddy Effect Signal";
                color = "eddy";
                icon = "eddy";
                break;
            case "ML-AdverseEvents":
                label = "Adverse Event";
                color = "adverse";
                break;
            case "ML-AdverseEvents-P2":
                label = "Adverse Event";
                color = "adverse";
                break;
            case "ML-SE-Acknowledgement":
                if (
                    result.Label === "SE Acknowledgement" ||
                    result.Label === "true"
                ) {
                    label = "Safety Event Acknowledged";
                    color = "green";
                } else if (
                    result.Label === "No SE Acknowledgement" ||
                    result.Label === "false"
                ) {
                    label = "Safety Event Not Acknowledged";
                    color = "yellow";
                }
                break;
            case "ML-SE-Identification":
                label = "Safety Event Identified";
                color = "yellow";
                break;
            case "ML-Hipaa":
                if (result.Label === "Compliant") {
                    label = "HIPAA Compliant";
                    color = "darkGreen";
                } else if (result.Label === "Not Compliant") {
                    label = "Not HIPAA Compliant";
                    color = "red";
                } else {
                    label = "HIPAA N/A";
                    color = "gray";
                }
                break;
            case "ML-ContactType":
                label = store.getContactTypeLabel(result.Label);
                color = "contact";
                break;
            default:
                color = "lightGray";
        }
    }
    return [label, color, icon];
}

function getChipsForEddySentiment(item, index, classes): React.ReactNode {
    const result = JSON.parse(item.result ?? "{}");
    let labelStart = `Sentiment Start: ${result.starting}`;
    let colorStart =
        result.starting === "Positive"
            ? "green"
            : result.starting === "Neutral"
            ? "yellow"
            : "red";
    let labelEnd = `Sentiment End: ${result.ending}`;
    let colorEnd =
        result.ending === "Positive"
            ? "green"
            : result.ending === "Neutral"
            ? "yellow"
            : "red";
    return (
        <Box
            sx={{ flexShrink: 1, overflow: "hidden", paddingRight: 0.5 }}
            key={"sorted" + index}
        >
            <AcxChip
                label={labelStart}
                size="small"
                color={colorStart as any}
                style={{ marginRight: 4 }}
            />
            <AcxChip label={labelEnd} size="small" color={colorEnd as any} />
        </Box>
    );
}

function getChipsForTopicClusters(
    classifierResult,
    index,
    classes,
): React.ReactNode {
    const result = JSON.parse(classifierResult.result ?? "{}");

    const color = "darkGray";

    return (
        <Box
            sx={{ flexShrink: 1, overflow: "hidden", paddingRight: 0.5 }}
            key={"sorted" + index}
        >
            {result.TopicCluster1 && (
                <AcxChip
                    label={result.TopicCluster1}
                    size="small"
                    color={color}
                />
            )}
            {/* We dont want to show these right now but may want to in the future */}
            {/* {result.TopicCluster2 && (
                <AcxChip
                    label={result.TopicCluster2}
                    size="small"
                    color={color}
                />
            )}
            {result.TopicCluster3 && (
                <AcxChip
                    label={result.TopicCluster3}
                    size="small"
                    color={color}
                />
            )} */}
        </Box>
    );
}

export default ConversationClassifierChipList;
