import EditIcon from "@mui/icons-material/Edit";
import { Theme, useTheme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import * as _ from "lodash";
import React, { CSSProperties, useState } from "react";
import { ActionMeta, OptionTypeBase, components } from "react-select";
import CreatableSelect from "react-select/creatable";
import { isArrayType } from "utils/TypeGuards";
import { default as hexToRGB } from "utils/hexToRGB";
import useStyles from "../../../../Styles/Styles";
import theme from "../../../../Theme/AppTheme";
import AcxInputLabel from "../../AcxInputLabel";
import DownChevronSvg from "./DownChevronSvg";

const styles = (theme: Theme) =>
    createStyles({
        field: {
            width: "100%",
            backgroundColor: theme.palette.white.main,
        },
        editOver: {
            cursor: "pointer",
            "&:hover": {
                color: hexToRGB(theme.palette.black.main, 0.6),
            },
        },
        textLabel: {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            fontSize: "12px",
            lineHeight: "16px",
        },
    });
export interface IAcxSelectCreatable {
    options: any[];
    id: string;
    onChange?: (arg0: any | undefined) => void;
    onMultiChange?: (arg0: any[] | undefined) => void;
    placeholder?: string;
    isMulti?: boolean;
    menuPortalTarget?: HTMLElement | null | undefined;
    defaultValue?: any;
    valueField: string;
    labelField: string;
    colorField?: string;
    inputLabel?: string;
    enableNoSelection?: boolean;
    editIcon?: boolean;
    onEditClick?: () => void;
    isClearable: boolean;
    isLoading: boolean;
    shrink?: boolean;
    infoText?: string;
    fullWidth?: boolean;
    width?: string;
    containerHeight?: string | "auto";
    isDisabled?: boolean;
    menuPlacement?: "top" | "bottom" | "auto";
    customStyle?: Record<string, (provided, state) => CSSProperties>;
    maxMenuHeightPx?: number;
    required?: boolean;
    onCreate?: (arg0: any) => void;
}

export interface OptionType extends OptionTypeBase {}
function AcxSelectCreatable(props: IAcxSelectCreatable) {
    const classes = useStyles(styles);
    const appTheme = useTheme();
    const [labelFocus, setLabelFocus] = useState<boolean | undefined>();
    const [currentValue, setCurrentValue] = useState<any>(props.defaultValue);
    const [options, setOptions] = useState<any[]>(props.options);

    const onEditClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();

        if (props.onEditClick) {
            props.onEditClick();
        }
    };

    const IndicatorSeparator = ({ innerProps }) => {
        if (props.editIcon) {
            return (
                <div
                    onMouseDown={onEditClick}
                    className={classes.editOver}
                    style={{ padding: "6px" }}
                >
                    <EditIcon viewBox={"0 0 20 20"} style={{ fontSize: 14 }} />
                </div>
            );
        } else {
            return <div></div>;
        }
    };

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected
                ? theme.palette.primary[500]
                : theme.palette.text.primary,
            padding: appTheme.spacing(1),
            borderRadius: "2px",
            backgroundColor: state.isSelected
                ? theme.palette.secondary[50]
                : theme.palette.white.main,
            "&:hover": {
                backgroundColor: theme.palette.secondary[50],
                color: theme.palette.neutral[600],
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            zIndex: 10,
            padding: "8px",
        }),
        menuPortal: (provided) => ({
            ...provided,
            zIndex: 9999,
        }),
        control: () => ({
            display: "flex",
            cursor: "pointer",
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = "opacity 300ms";

            return { ...provided, opacity, transition };
        },
        dropdownIndicator: (provided, state) => ({
            ...provided,
            color: appTheme.palette.text.primary,
        }),
        indicatorContainer: (provided, state) => ({
            ...provided,
            padding: "6px",
        }),
        multiValue: (styles, { data }) => ({
            ...styles,
            backgroundColor: hexToRGB(
                data[props.colorField ?? ""] ?? appTheme.palette.info.main,
                0.18,
            ),
            // border: `2px dotted ${appTheme.palette.secondary.main}`,
            fontSize: "13px",
            color: data[props.colorField ?? ""] ?? appTheme.palette.info.main,
        }),
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: data[props.colorField ?? ""] ?? appTheme.palette.info.main,
            fontWeight: "bold",
        }),
        container: (provided, state) => ({
            ...provided,
            fontSize: "13px",
            minWidth: "100px",
            width: props.width ?? "100%",
            maxWidth: props.fullWidth ? "100%" : "336px",
            border: props.isDisabled ? "none" : "1px solid",
            borderColor: appTheme.palette.black.light,
            borderRadius: appTheme.shape.borderRadius,

            minHeight: props.containerHeight ?? "32px",
            height: props.containerHeight ?? "32px",
            backgroundColor: props.isDisabled
                ? hexToRGB(appTheme.palette.black.main, 0.1)
                : appTheme.palette.white.main,
        }),
    };
    _.assign(customStyles, props.customStyle ?? {});

    const onChange = (value: any, actionMeta: ActionMeta<any>) => {
        if (
            value?.[props.valueField] === "-1" ||
            actionMeta.action === "clear"
        ) {
            setCurrentValue(null);
            value = undefined;
        } else {
            setCurrentValue(value);
        }
        if (value) {
            if (isArrayType(value)) {
                if (props.onMultiChange) {
                    const val = props.options.filter((v, index) => {
                        return value.value.includes(v[props.valueField]);
                    });
                    props.onMultiChange(val);
                }
            } else {
                if (props.onChange) {
                    const val = props.options.find(
                        (opt) => opt[props.valueField] === value.value,
                    );
                    props.onChange(val);
                }
            }
        } else {
            if (props.onChange) {
                props.onChange(undefined);
            }
            if (props.onMultiChange) {
                props.onMultiChange(undefined);
            }
        }
    };

    React.useEffect(() => {
        if (props.enableNoSelection) {
            if (props.options.length > 0) {
                const curops = [
                    {
                        [props.valueField]: "-1",
                        [props.labelField]: "No Selection",
                    },
                ];
                setOptions(curops.concat(props.options));
            } else {
                const curops = [
                    {
                        [props.valueField]: "-1",
                        [props.labelField]: "No Selection",
                    },
                ];
                setOptions(curops);
            }
        } else {
            const opt = props.options.map((value, indx) => {
                return {
                    label: value[props.labelField ?? "label"],
                    value: value[props.valueField ?? "value"],
                };
            });
            setOptions(opt);
        }
    }, [
        props.options,
        props.enableNoSelection,
        props.valueField,
        props.labelField,
    ]);

    React.useEffect(() => {
        if (props.defaultValue === undefined || props.defaultValue === null) {
            setCurrentValue(null);
        } else {
            if (isArrayType(props.defaultValue)) {
                const opt = props.defaultValue.map((value: any, indx) => {
                    return {
                        label: value[props.labelField ?? "label"],
                        value: value[props.valueField ?? "value"],
                    };
                });
                setCurrentValue(opt);
            } else {
                const opt = {
                    label: props.defaultValue[props.labelField ?? "label"],
                    value: props.defaultValue[props.valueField ?? "value"],
                };
                setCurrentValue(opt);
            }
        }
    }, [props.defaultValue, props.labelField, props.options, props.valueField]);

    const DropDownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <DownChevronSvg />
            </components.DropdownIndicator>
        );
    };

    function getCustomComponents() {
        // if (props.editIcon) {
        return {
            IndicatorSeparator: IndicatorSeparator,
            DropdownIndicator: DropDownIndicator,
        };
        // }else{
        //     return undefined;
        // }
    }

    return (
        <>
            {props.inputLabel && (
                <AcxInputLabel
                    shrink={props.shrink}
                    focused={labelFocus}
                    id={props.id + "-label"}
                    htmlFor={props.id}
                    className={classes.textLabel}
                    infoText={props.infoText}
                >
                    {props.inputLabel}
                </AcxInputLabel>
            )}
            <CreatableSelect
                allowCreateWhileLoading={true}
                onCreateOption={props.onCreate}
                isLoading={props.isLoading}
                required={props.required}
                closeMenuOnSelect={!props.isMulti}
                isClearable={props.isClearable}
                maxMenuHeight={props.maxMenuHeightPx}
                width={"100%"}
                aria-labelledby={props.id + "-label"}
                onChange={onChange}
                onBlur={() => setLabelFocus(false)}
                onFocus={() => setLabelFocus(true)}
                placeholder={props.placeholder}
                defaultValue={currentValue}
                value={currentValue}
                styles={customStyles}
                options={options}
                menuShouldScrollIntoView
                isMulti={props.isMulti}
                menuPortalTarget={
                    props.menuPortalTarget === undefined
                        ? document.body
                        : props.menuPortalTarget
                } // null is significant
                menuPlacement={props.menuPlacement ?? "auto"}
                inputId={props.id}
                className={classes.field}
                components={getCustomComponents()}
                isDisabled={props.isDisabled}
                // theme={(theme) => ({
                //     ...theme,
                //     borderRadius: appTheme.shape.borderRadius,
                //     spacing: {
                //         baseUnit: 3,
                //         controlHeight: 32,
                //         menuGutter: appTheme.spacing(1),
                //     },
                //     colors: {
                //         ...theme.colors,
                //         primary25: hextToRGB(
                //             appTheme.palette.secondary.main,
                //             0.25,
                //         ),
                //         primary: appTheme.palette.secondary.main,
                //     },
                // })}
            />
        </>
    );
}

AcxSelectCreatable.defaultProps = {
    isMulti: false,
    valueField: "value",
    labelField: "label",
    enableNoSelection: false,
    editIcon: false,
    isClearable: false,
    isLoading: false,
    useBodyAsPortal: true,
    shrink: false,
};

export default React.memo(AcxSelectCreatable);
