import * as _ from "lodash";
import {
    action,
    makeObservable,
    observable,
    reaction,
    runInAction,
} from "mobx";
import { ReportGroup } from "models/Reporting/ReportGroup";
import moment, { Moment } from "moment";
import { ReportDatum } from "../../../models/Reporting/ReportDatum";
import { ReportFilter } from "../../../models/Reporting/ReportFilter";
import { ReportModel, VizType } from "../../../models/Reporting/ReportModel";
import { ReportsService } from "../../../services/ReportsService";
import { BaseStore } from "../../../stores/BaseStore";
import { DateReferenceOption } from "../../../stores/ComponentStores/DatePickerComponentStore";
import { reportDataToGoogleFormatV2 } from "../../../utils/ReportDataToGoogleFormat";
import AcxDataGridStore from "../../UI/AcxDataGrid/AcxDataGridStore";
import type { IReport } from "../Stores/ReportsStore";
import { reportToIReport } from "./IReportTransformer";

type DrillDownClosure = (
    reportId: string | undefined,
    orgId: string | undefined,
    beginDate: Moment | undefined,
    endDate: Moment | undefined,
    dateReferenceOption: DateReferenceOption,
    quickFilters: Array<ReportFilter>,
    hierarchyIds: string[] | undefined,
) => Promise<void>;

export class ReportDataViewModel extends BaseStore {
    private readonly reportServiceV2: ReportsService = new ReportsService(
        "2.0",
    );

    @observable currentReport?: IReport | null;
    @observable.ref drillDownForCurrentReport: IReport | null;
    @observable.ref reportData: ReportDatum[] = [];
    @observable.ref tableReportData: AcxDataGridStore = new AcxDataGridStore();
    @observable.ref drillReportData: AcxDataGridStore = new AcxDataGridStore();

    @observable.ref googleFormattedData?: any[][];
    @observable isDrillDown: boolean = false;
    @observable.ref drillDownClosure?: DrillDownClosure;
    @observable emptyDataSet: boolean = false;

    quickFilters: Array<ReportFilter> = [];
    tableReportDataAbortController: AbortController;
    reportDataAbortController: AbortController;

    constructor(
        private reportId?: string,
        private orgId?: string,
        private startDate?: Moment,
        private endDate?: Moment,
        private referenceOption?: DateReferenceOption,
        dynamicFilters?: Array<ReportFilter>,
        private onViewModelReady?: (report: IReport) => void,
        private isWidget?: boolean,
        private hierarchyIds?: string[],
        private agentId?: string,
    ) {
        super("ReportDataViewModel");

        makeObservable(this);

        this.tableReportData.disableLocalConfig = false;
        this.drillReportData.disableLocalConfig = false;

        reaction(
            (r) => this.tableReportData.rows.length > 0,
            (hasRows) => {
                this.tableReportData.vertIconMenuItemsBuilder = (
                    onClose,
                    history,
                ) => {
                    return [
                        this.tableReportData.buildVertMenuOptionForCsvExport(
                            hasRows,
                        ),
                    ];
                };
            },
        );

        reaction(
            (r) => this.drillReportData.rows.length > 0,
            (hasRows) => {
                this.drillReportData.vertIconMenuItemsBuilder = (
                    onClose,
                    history,
                ) => {
                    return [
                        this.drillReportData.buildVertMenuOptionForCsvExport(
                            hasRows,
                        ),
                    ];
                };
            },
        );

        this.tableReportData.checkboxSelection = false;
        this.drillReportData.checkboxSelection = false;
        this.quickFilters = dynamicFilters ?? [];

        if (this.reportId && this.startDate && this.endDate) {
            this.setupAsyncTask(`Loading Report`, () =>
                this.loadReportData(
                    this.reportId,
                    this.orgId,
                    this.startDate,
                    this.endDate,
                    this.referenceOption,
                    this.hierarchyIds,
                    this.agentId,
                ),
            );
        }
    }

    @action
    setOrgId(orgId?: string) {
        this.orgId = orgId;
    }

    @action
    setQuickFilters(quickFilters: Array<ReportFilter>) {
        this.quickFilters = quickFilters;
        if (quickFilters && quickFilters.length) {
            this.setupAsyncTask(`Loading Report`, () =>
                this.loadReportData(
                    this.currentReport?.id,
                    this.orgId,
                    this.startDate,
                    this.endDate,
                    this.referenceOption,
                    this.hierarchyIds,
                    this.agentId,
                ),
            );
        }
    }

    @action
    setReport(
        report: IReport,
        startDate: moment.Moment,
        endDate: moment.Moment,
        referenceOption: DateReferenceOption,
        hierarchyIds?: string[],
        agentId?: string,
    ) {
        this.currentReport = report;
        this.startDate = startDate;
        this.endDate = endDate;
        this.hierarchyIds = hierarchyIds;
        this.referenceOption = referenceOption;
        this.tableReportData.gridId = report.id + "tablereport";
        this.drillReportData.gridId = report.id + "drillthrough";
        this.agentId = agentId;

        this.setupAsyncTask(`Loading Report`, () =>
            this.loadReportData(
                this.currentReport?.id,
                this.orgId,
                this.startDate,
                this.endDate,
                this.referenceOption,
                this.hierarchyIds,
                this.agentId,
            ),
        );
    }

    @action
    clearDrillDownState = () => {
        this.isDrillDown = false;
        this.drillDownForCurrentReport = null;
        this.drillDownClosure = undefined;

        this.drillReportData.rows = [];
    };

    @action
    private async loadReportModel(reportId: string) {
        if (this.currentReport?.report) {
            return this.currentReport.report;
        }

        return this.reportServiceV2.getReport(
            reportId,
            `ReportDataViewModel/ReportModel`,
        );
    }

    abortReportDataLoad = () => {
        if (this.tableReportDataAbortController) {
            this.tableReportDataAbortController.abort();
        }
        if (this.reportDataAbortController) {
            this.reportDataAbortController.abort();
        }
        this.attemptToCancelTask("Loading Report");
    };

    @action
    async loadReportData(
        reportId: string | undefined,
        orgId: string | undefined,
        startDate?: moment.Moment,
        endDate?: moment.Moment,
        referenceOption?: DateReferenceOption,
        hierarchyIds?: string[],
        agentId?: string,
    ) {
        if (!reportId || !startDate || !endDate || !referenceOption) {
            return;
        }
        this.startDate = startDate;
        this.endDate = endDate;
        this.hierarchyIds = hierarchyIds;
        this.agentId = agentId;
        this.reportId = reportId;
        this.referenceOption =
            referenceOption ?? DateReferenceOption.InteractionDate;
        this.setOrgId(orgId);

        const reportModel = await this.loadReportModel(reportId);

        if (this.currentReport) {
            this.currentReport.report = reportModel;
        }

        let data: any = undefined;
        let googleFormattedData: any = undefined;

        this.setupAsyncTask(
            `Load Drill Down`,
            async () =>
                await this.drillDownClosure?.(
                    reportModel?.id,
                    this.orgId,
                    startDate,
                    endDate,
                    referenceOption,
                    this.quickFilters,
                    this.hierarchyIds,
                ),
        );

        if (reportModel.vizType === VizType.Table) {
            this.tableReportDataAbortController = new AbortController();
            data = await this.reportServiceV2.getTableReportDataByReportId(
                orgId,
                reportId,
                this.startDate,
                this.endDate,
                this.referenceOption,
                this.quickFilters,
                `ReportDataViewModel/Data`,
                this.hierarchyIds,
                this.agentId,
                this.tableReportDataAbortController.signal,
            );
        } else {
            this.reportDataAbortController = new AbortController();
            data = await this.reportServiceV2.getReportDataByReportId(
                orgId,
                reportId,
                this.startDate,
                this.endDate,
                this.referenceOption,
                this.quickFilters,
                `ReportDataViewModel/Data`,
                this.hierarchyIds,
                this.agentId,
                this.reportDataAbortController.signal,
            );
            if (reportModel?.vizOptions?.enablePercentFormat) {
                data.map((obj) => (obj.y = Math.round(obj.y * 10000) / 100));
            } else {
                data.map((obj) => Math.floor(obj.y));
            }

            googleFormattedData = reportDataToGoogleFormatV2(
                data,
                reportModel,
                this.isWidget,
            );
        }

        const currReport = reportToIReport(reportModel);

        runInAction(() => {
            if (!this.currentReport) {
                this.onViewModelReady?.(currReport);
            }

            this.currentReport = currReport;

            if (reportModel.vizType === VizType.Table) {
                this.tableReportData.setInitialTableReportColumns(data.columns);
                this.tableReportData.rows = data.rows;
                this.emptyDataSet = data.rowCount === 0;
            } else {
                this.reportData = data;
                this.emptyDataSet = (this.reportData?.length ?? 0) < 1;
                this.googleFormattedData = googleFormattedData;
            }
        });
    }
    @action
    public loadPreviewReportData = async (
        orgId: string | undefined,
        reportDetails: ReportModel | undefined,
        vizTypeTable: boolean,
    ) => {
        if (this.currentReport === undefined) {
            const report: IReport = {
                id: "",
                group: "",
                name: "",
                createdBy: "previewReport",
                modifiedBy: "previewReport",
                modifiedOn: "",
                modifiedOnDate: "",
                modifiedAt: "",
                vizOptions: undefined,
            };
            this.currentReport = report;
        }
        if (this.currentReport) {
            this.referenceOption = DateReferenceOption.InteractionDate;
            this.setOrgId(orgId);
            const reportModel = this.currentReport?.report;
            let data: any = undefined;
            let googleFormattedData: any = undefined;
            if (reportDetails && reportDetails?.reportFields.length > 0) {
                this.setupAsyncTask("Load Report Preview Data", async () => {
                    data = await this.reportServiceV2.getPreviewReportData(
                        orgId,
                        reportDetails,
                    );
                    if (!vizTypeTable) {
                        if (reportModel?.vizOptions?.enablePercentFormat) {
                            data.data.map(
                                (obj) =>
                                    (obj.y = Math.round(obj.y * 10000) / 100),
                            );
                        } else {
                            data.data.map((obj) => Math.floor(obj.y));
                        }

                        googleFormattedData = reportDataToGoogleFormatV2(
                            data.data,
                            reportDetails,
                            this.isWidget,
                        );
                    }
                    if (reportDetails) {
                        reportDetails.group = new ReportGroup();
                        reportDetails.group.groupName = "previewGroup";
                        const currReport = reportToIReport(reportDetails);

                        runInAction(() => {
                            if (!this.currentReport) {
                                this.onViewModelReady?.(currReport);
                            }

                            this.currentReport = currReport;

                            if (reportDetails.vizType === VizType.Table) {
                                this.tableReportData.setInitialTableReportColumns(
                                    data.columns,
                                );
                                this.tableReportData.rows = data.rows;
                                this.emptyDataSet = data.rowCount === 0;
                            } else {
                                this.reportData = data.data;
                                this.emptyDataSet =
                                    (this.reportData?.length ?? 0) < 1;
                                this.googleFormattedData = googleFormattedData;
                            }
                        });
                    }
                });
            } else {
                this.reportData = [];
                this.emptyDataSet = true;
                this.googleFormattedData = [];
            }
        }
    };
    @action
    public onVizClick = async (
        series: string | null,
        x: string | number | null,
        y: string | number | null | any,
    ) => {
        if (this.currentReport?.id && this.startDate && this.endDate) {
            this.drillDownClosure = async (
                reportId: string | undefined,
                orgId: string | undefined,
                beginDate: Moment | undefined,
                endDate: Moment | undefined,
                dateReferenceOption: DateReferenceOption,
                quickFilters: Array<ReportFilter>,
            ) => {
                if (!reportId || !beginDate || !endDate) {
                    return;
                }

                const activeFilters = quickFilters.filter(
                    (f) => !!f.value && f.value !== JSON.stringify([]),
                );

                const res = await this.reportServiceV2.drillIntoReport(
                    reportId,
                    orgId,
                    x as any,
                    series as any,
                    beginDate,
                    endDate,
                    dateReferenceOption,
                    activeFilters,
                    `ReportDataViewModel/DrillDown`,
                );

                if (_.isEmpty(res)) {
                    this.clearDrillDownState();
                    return;
                }

                runInAction(() => {
                    this.drillReportData.setColumns(res.columns);
                    this.drillReportData.rows = res.rows;

                    this.drillDownForCurrentReport = {
                        vizType: VizType.Table,
                        modifiedOn: moment().local().format("MMM D"),
                        modifiedAt: moment().local().format("h:mm a"),
                        modifiedBy: this.currentReport?.createdBy,
                        createdBy: this.currentReport?.createdBy,
                        name: `${this.currentReport?.name} Details`,
                        drillThru: true,
                    } as IReport;

                    this.isDrillDown = true;
                });
            };

            this.setupAsyncTask(
                `Load Drill Down`,
                async () =>
                    await this.drillDownClosure?.(
                        this.currentReport?.id,
                        this.orgId,
                        this.startDate,
                        this.endDate,
                        this.referenceOption ??
                            DateReferenceOption.InteractionDate,
                        this.quickFilters,
                        this.hierarchyIds,
                    ),
            );
        }
    };
}
