import moment from "moment";
import { serializeToUtc } from "utils/DateTimeUtils";
import { isUndefinedType } from "utils/TypeGuards";
import BaseService from "./BaseService";

export class ModelDashboardService extends BaseService {
    protected urlBase: string = "api/ModelDashboard";

    private injectGetEndpointWithFilters = async (
        orgId: string,
        classifierId: string,
        endpoint: string,
        startDate?: moment.Moment,
        endDate?: moment.Moment,
        callDirection?: string,
        dateReference?: string,
        hierarchies?: string[],
    ) => {
        const params = new URLSearchParams({});
        return await this.post(
            JSON.stringify({
                startDate,
                endDate,
                dateReference,
                callDirection,
                hierarchies,
                classifierId,
                orgId,
            }),
            params,
            endpoint,
        );
    };

    public async getDataForVolumeByHierarchy(
        modelId: string,
        startDate?: moment.Moment,
        endDate?: moment.Moment,
    ) {
        const params = new URLSearchParams({ modelId });

        if (!isUndefinedType(startDate) && !isUndefinedType(endDate)) {
            params.set("startDate", serializeToUtc(startDate)!);
            params.set("endDate", serializeToUtc(endDate)!);
        }

        const response = await this.get(params, "GetDataForVolumeByHierarchy");

        return response;
    }

    public async getAmdStatus(
        orgId: string,
        classifierId: string,
        callDirection?: string,
        startDate?: moment.Moment,
        endDate?: moment.Moment,
        dateReference?: string,
        hierarchies?: string[],
    ) {
        const response = await this.injectGetEndpointWithFilters(
            orgId,
            classifierId,
            "GetAmdStats",
            startDate,
            endDate,
            callDirection,
            dateReference,
            hierarchies,
        );

        return response;
    }

    public async getEstimatedCostMetricForEddy(
        orgId: string,
        classifierId: string,
        callDirection?: string,
        startDate?: moment.Moment,
        endDate?: moment.Moment,
        dateReference?: string,
        hierarchies?: string[],
    ) {
        const response = await this.injectGetEndpointWithFilters(
            orgId,
            classifierId,
            "GetEstimatedCostForEddy",
            startDate,
            endDate,
            callDirection,
            dateReference,
            hierarchies,
        );

        return response;
    }

    public async getDataForTrendingEddyEffect(
        orgId: string,
        classifierId: string,
        callDirection?: string,
        startDate?: moment.Moment,
        endDate?: moment.Moment,
        dateReference?: string,
        hierarchies?: string[],
    ) {
        const response = await this.injectGetEndpointWithFilters(
            orgId,
            classifierId,
            "GetDataForTrendingEddyEffect",
            startDate,
            endDate,
            callDirection,
            dateReference,
            hierarchies,
        );

        return response;
    }

    public async getDataForEddyByRbc(
        orgId: string,
        classifierId: string,
        callDirection?: string,
        startDate?: moment.Moment,
        endDate?: moment.Moment,
        dateReference?: string,
        hierarchies?: string[],
    ) {
        const response = await this.injectGetEndpointWithFilters(
            orgId,
            classifierId,
            "GetDataForEddyByRbc",
            startDate,
            endDate,
            callDirection,
            dateReference,
            hierarchies,
        );

        return response;
    }

    public async getDataForEddyBySentiment(
        orgId: string,
        classifierId: string,
        callDirection?: string,
        startDate?: moment.Moment,
        endDate?: moment.Moment,
        dateReference?: string,
        hierarchies?: string[],
    ) {
        const response = await this.injectGetEndpointWithFilters(
            orgId,
            classifierId,
            "GetDataForEddyBySentiment",
            startDate,
            endDate,
            callDirection,
            dateReference,
            hierarchies,
        );

        return response;
    }

    public async getTopicsForEddy(
        orgId: string,
        classifierId: string,
        callDirection?: string,
        startDate?: moment.Moment,
        endDate?: moment.Moment,
        dateReference?: string,
        hierarchies?: string[],
    ) {
        const response = await this.injectGetEndpointWithFilters(
            orgId,
            classifierId,
            "GetTopicsForEddy",
            startDate,
            endDate,
            callDirection,
            dateReference,
            hierarchies,
        );

        return response;
    }

    public drillEddy = async (
        orgId: string,
        callDirection?: string,
        startDate?: moment.Moment,
        endDate?: moment.Moment,
        dateReference?: string,
        hierarchies?: string[],
        eddyChartType?: string,
        chartMetadata?: { name: string; value: any }[] | null,
    ) => {
        const params = new URLSearchParams({});
        const res = await this.post(
            JSON.stringify({
                startDate,
                endDate,
                dateReference,
                callDirection,
                hierarchies,
                eddyChartType,
                chartMetadata,
                orgId,
            }),
            params,
            "DrillEddy",
        );
        return res;
    };
}
