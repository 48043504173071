import { Add, CheckCircleOutline, Remove } from "@mui/icons-material";
import {
    Card,
    CardContent,
    Grid,
    Icon,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import theme from "Theme/AppTheme";
import AcxButton from "components/UI/AcxButton";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import AcxCard from "components/UI/Cards/AcxCard";
import AcxDialog from "components/UI/Dialog/AcxDialog";
import { observer } from "mobx-react";
import {
    AddClassifierGroupResult,
    ClassifierForLibrary,
} from "models/ClassifierModel";
import React, { CSSProperties, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AsyncTaskStatus } from "stores/BaseStore";
import { useStore } from "utils/useStore";
import ClassifierLibraryStore from "../Stores/ClassifierLibraryStore";

interface ClassifierCardProps {
    classifier: ClassifierForLibrary;
    titleStyle?: CSSProperties;
}

const useClassifierCardStyles = makeStyles(() => {
    return {
        card: {
            height: "172px",
            width: "100%",
        },
        body: {
            // lineClamp: "4 block-ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 4,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
    };
});

function ClassifierCard({
    classifier,
    titleStyle = {},
    ...props
}: React.PropsWithChildren<
    ClassifierCardProps & React.ComponentPropsWithoutRef<typeof Card>
>) {
    const classes = useClassifierCardStyles();

    return (
        <Card
            {...props}
            id={classifier.id}
            variant="outlined"
            className={classes.card}
            style={props.style}
        >
            <CardContent style={{ padding: "0px" }}>
                <Typography
                    padding={2}
                    style={{
                        backgroundColor: "#EFF6FF",
                        fontSize: "14px",
                        fontWeight: 600,
                        ...titleStyle,
                    }}
                    variant="h3"
                >
                    <Stack direction="row" justifyContent="space-between">
                        {classifier.name}
                        {classifier.existsInTenant && (
                            <Tooltip title="This classifier exists in your classifiers">
                                <Icon>
                                    <CheckCircleOutline />
                                </Icon>
                            </Tooltip>
                        )}
                    </Stack>
                </Typography>
                <Typography
                    // Using margin here allows line clamp to work properly.
                    margin={3}
                    variant="body1"
                    className={classes.body}
                >
                    {classifier.description !== ""
                        ? classifier.description
                        : "No description."}
                </Typography>
            </CardContent>
        </Card>
    );
}

type ColoredGroup = {
    backgroundColor: CSSProperties["backgroundColor"];
    color: CSSProperties["color"];
};

const CLASSIFIER_GROUP_COLORS: ColoredGroup[] = [
    {
        backgroundColor: theme.palette.lightBlue.background,
        color: theme.palette.black.main,
    },
    {
        backgroundColor: theme.palette.gray.light,
        color: theme.palette.black.main,
    },
    {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.white.main,
    },
];

const ClassifierLibrary = observer(() => {
    const classifierLibraryStore = useStore(ClassifierLibraryStore);
    useEffect(() => {
        // initial load
        if (!classifierLibraryStore.library)
            classifierLibraryStore.loadLibrary();
    }, [classifierLibraryStore]);

    const isLibraryLoading = classifierLibraryStore.getTaskLoading(
        ClassifierLibraryStore.Tasks.LoadClassifierLibrary,
    );

    const [addClassifierGroupResult, setAddClassifierGroupResult] = useState<
        AddClassifierGroupResult | undefined
    >(undefined);

    async function onAddGroupClick(groupId: string, groupName: string) {
        const result = await classifierLibraryStore.addGroupToCurrentTenant(
            groupId,
        );

        if (result === AsyncTaskStatus.Error)
            return classifierLibraryStore.messageStore.logError(
                "Error adding classifier to your library. Please try again later.",
            );

        if (result.failedClassifierNames.length > 0)
            setAddClassifierGroupResult(result);
        else
            classifierLibraryStore.messageStore.logInfo(
                `Classifiers from '${groupName}' added successfully`,
            );
    }

    return (
        <Grid container direction="column" wrap="nowrap" padding={6} gap={4}>
            <AcxCard
                rootStyle={{
                    maxWidth: "none",
                    height: "fit-content",
                    backgroundColor: theme.palette.white.main,
                    paddingInline: "32px",
                    paddingBlock: "20px",
                    boxShadow: "none",
                }}
                title={
                    <Typography
                        style={{
                            textTransform: "uppercase",
                            letterSpacing: "2px",
                            color: theme.palette.secondary.dark,
                            fontWeight: 700,
                        }}
                    >
                        Explore Authenticx's Classifier Library
                    </Typography>
                }
                mainContent={
                    <Typography variant="body1">
                        Classifiers are sets of rules that can be used to
                        identify whether certain criteria was found in a
                        conversation. Our team has helped clients build
                        thousands of classifiers over the years, and this
                        library contains our "greatest hits". Rather than
                        starting from scratch, browse the classifiers below and
                        consider adding them to your own organization's
                        classifiers.
                    </Typography>
                }
            />
            <Grid container item gap={4}>
                {(isLibraryLoading || !classifierLibraryStore.library) && (
                    <AcxLoadingIndicator
                        color="secondary"
                        alternate="PuffLoader"
                        size={64}
                    />
                )}
                {!isLibraryLoading &&
                    classifierLibraryStore.library &&
                    Object.entries(classifierLibraryStore.library).map(
                        ([group, classifiers], groupIndex) => {
                            const groupId = classifiers[0].groupId;
                            const isAddAllDisabled = classifiers.every(
                                (classifier) => classifier.existsInTenant,
                            );

                            return (
                                <Grid container item gap={2} key={group}>
                                    <Stack
                                        width={"100%"}
                                        direction="row"
                                        justifyContent={"space-between"}
                                    >
                                        <Typography variant="h1">
                                            {group}
                                        </Typography>
                                        <AcxButton
                                            variant="contained"
                                            fullWidth={false}
                                            leftRightSpacing={0}
                                            loading={classifierLibraryStore.getTaskLoading(
                                                ClassifierLibraryStore.Tasks.AddGroupToTenant(
                                                    groupId,
                                                ),
                                            )}
                                            customRootStyles={{
                                                borderRadius: "4px",
                                                border: "1px solid #E4E4E7",
                                                backgroundColor: theme.palette.white.main,
                                                color: `#3F3F46 !important`,
                                            }}
                                            onClick={() =>
                                                onAddGroupClick(groupId, group)
                                            }
                                            buttonDisabled={isAddAllDisabled}
                                        >
                                            Add All To Your Classifiers
                                        </AcxButton>
                                    </Stack>
                                    <Grid container item spacing={2}>
                                        {classifiers.map((classifier) => {
                                            const { backgroundColor, color } =
                                                CLASSIFIER_GROUP_COLORS[
                                                    groupIndex %
                                                        CLASSIFIER_GROUP_COLORS.length
                                                ];

                                            return (
                                                <Grid
                                                    item
                                                    lg={3}
                                                    md={4}
                                                    xs={6}
                                                    key={classifier.id}
                                                >
                                                    <Link
                                                        style={{
                                                            textDecoration:
                                                                "none",
                                                        }}
                                                        to={`./${classifier.id}`}
                                                    >
                                                        <ClassifierCard
                                                            classifier={
                                                                classifier
                                                            }
                                                            titleStyle={{
                                                                backgroundColor,
                                                                color,
                                                            }}
                                                        />
                                                    </Link>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Grid>
                            );
                        },
                    )}
            </Grid>
            <AcxDialog
                isOpen={addClassifierGroupResult !== undefined}
                onClose={() => setAddClassifierGroupResult(undefined)}
                title="Encountered errors adding some classifiers"
                maxWidth="lg"
                contentWidth="40rem"
                // subTitle="There were naming conflicts resulting in some classifiers not being added to your classifiers"
                dialogContentChildren={
                    addClassifierGroupResult && (
                        <Grid container direction="column" gap={2}>
                            <Grid container item direction="column" gap={1}>
                                <Typography variant="h5">
                                    Classifiers added to your classifiers
                                </Typography>
                                {addClassifierGroupResult.successfulClassifierNames.map(
                                    (name) => (
                                        <Stack
                                            color={theme.palette.green.main}
                                            direction="row"
                                            alignItems="center"
                                        >
                                            <Icon>
                                                <Add />
                                            </Icon>
                                            <Typography
                                                variant="body1"
                                                color={theme.palette.green.main}
                                            >
                                                {name}
                                            </Typography>
                                        </Stack>
                                    ),
                                )}
                                {addClassifierGroupResult
                                    .successfulClassifierNames.length === 0 && (
                                    <Typography variant="body1">
                                        None
                                    </Typography>
                                )}
                            </Grid>
                            <Grid container item direction="column" gap={1}>
                                <Typography variant="h5">
                                    Classifiers not added to your classifiers
                                </Typography>
                                {addClassifierGroupResult.failedClassifierNames.map(
                                    (name) => (
                                        <Stack
                                            color={theme.palette.red.main}
                                            direction="row"
                                            alignItems="center"
                                        >
                                            <Icon>
                                                <Remove />
                                            </Icon>
                                            <Typography
                                                variant="body1"
                                                color={theme.palette.red.main}
                                            >
                                                {name}
                                            </Typography>
                                        </Stack>
                                    ),
                                )}
                            </Grid>
                        </Grid>
                    )
                }
            />
        </Grid>
    );
});

export default ClassifierLibrary;
