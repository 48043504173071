import { Grid } from "@mui/material";
import { GridCellParams } from "@mui/x-data-grid-pro";
import type { Tier } from "components/Admin/Organizations/types/Tier.type";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import AcxDataGrid from "components/UI/AcxDataGrid/AcxDataGrid";
import AcxDataGridStore from "components/UI/AcxDataGrid/AcxDataGridStore";
import IColDef from "components/UI/AcxDataGrid/IColDef";
import { BranchDataProps } from "components/UI/AcxRecursiveTree/AcxRecursiveTreeBranch";
import RecursiveTreeStore from "components/UI/AcxRecursiveTree/Stores/AcxRecursiveTreeStore";
import VerticalDrawerContentTemplate from "components/UI/Drawer/VerticalDrawerContentTemplate";
import _ from "lodash";
import { action, makeObservable, observable, reaction } from "mobx";
import { ApplicationUser } from "models/Permission/ApplicationUser";
import React from "react";
import { OrganizationService } from "services/OrganizationService";
import { UserService } from "services/UserService";
import { BaseStore } from "stores/BaseStore";
import { DialogComponentStore } from "stores/ComponentStores/DialogComponentStore";
import { LayoutDrawerStore } from "stores/Layout/LayoutDrawerStore";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";

export const GET_SAMPLING_HISTORY = "Get Sampling History";
export const GET_ORG_DATA = "Get Organization Data";

@AcxStore
export class OrganizationServiceHierarchyStore extends BaseStore {
    private readonly userService: UserService = new UserService();
    private readonly orgService: OrganizationService =
        new OrganizationService();

    readonly reassignDialogStore: DialogComponentStore<OrganizationServiceHierarchyStore>;
    readonly deleteDialogStore: DialogComponentStore<OrganizationServiceHierarchyStore>;
    readonly addTierDialog: DialogComponentStore<OrganizationServiceHierarchyStore>;
    readonly messageStore: MessageStore;
    readonly dgStore: AcxDataGridStore;
    readonly drawerStore: LayoutDrawerStore;

    @observable.ref
    orgUsers: ApplicationUser[] = [];

    @observable
    orgServiceSetup: string[];

    @observable
    organizationId: string = "";

    @observable
    adminUsers: ApplicationUser[] = [];

    @observable
    escalationUsers: ApplicationUser[] = [];

    @observable
    managerUsers: ApplicationUser[] = [];

    @observable
    showDeleteDialog: boolean = false;

    @observable
    showEditTierDrawer: boolean = false;

    @observable
    showServiceHierarchyUsersDrawer: boolean = false;

    @observable
    showAddTierDialog: boolean = false;

    @observable
    showAddTopLevelTierDialog: boolean = false;

    @observable
    showReassignTierDialog: boolean = false;

    @observable
    samplingHistoryRows: any[] = [];

    @observable
    samplingHistoryPageNumber: number = 1;

    @observable
    isLoading: boolean = false;

    @observable
    triggerReassign: boolean = false;

    @observable
    activeTier?: Tier;

    @observable
    endpointFired: {
        active: boolean;
        payload?: "Add" | "Remove" | "Update" | "Reassign";
    } = { active: false }; // Used to track when to rerender rows.

    @observable
    hierarchyCodeValue?: string;

    treeStore: RecursiveTreeStore;

    constructor(private rootStore: IRootStore) {
        super("OrganizationServiceHierarchyStore");
        makeObservable(this);

        this.reassignDialogStore = new DialogComponentStore(undefined, this);
        this.deleteDialogStore = new DialogComponentStore(undefined, this);
        this.addTierDialog = new DialogComponentStore(undefined, this);
        this.messageStore = rootStore.getStore(MessageStore);
        this.dgStore = new AcxDataGridStore(
            "HierarchyUsersDataStoreGrid",
            undefined,
        );
        this.drawerStore = this.rootStore.getStore(LayoutDrawerStore);

        this.dgStore.checkboxSelection = false;

        reaction(
            () => ({
                orgId: this.organizationId,
                activeLocation: this.rootStore.activeLocation,
            }),
            (args) => {
                if (
                    args.activeLocation &&
                    !(
                        args.activeLocation.location.includes(
                            "admin/organizations",
                        ) &&
                        args.activeLocation.location.includes(
                            "service-hierarchy",
                        )
                    )
                ) {
                    return;
                }

                if (args.orgId) {
                    this.setupAsyncTask(GET_ORG_DATA, async () => {
                        this.orgUsers = await this.userService.getUserNames(
                            args.orgId as string,
                        );

                        const orgResponse =
                            await this.orgService.getOrganization(
                                args.orgId ?? "",
                            );

                        this.orgServiceSetup = orgResponse.serviceSetup ?? [];
                    });
                }
            },
            { fireImmediately: true },
        );
    }

    @action
    setActiveTierAttribute = (pathToKey: string, value) => {
        if (!this.activeTier) return;
        const tier = this.activeTier;
        _.set(tier, pathToKey, value);

        this.activeTier = tier;
    };

    @action
    setActiveTier = (tier: Tier) => {
        this.activeTier = tier;
    };

    @action
    setHierarchyCodeValue = (value: string) => {
        this.hierarchyCodeValue = value;
    };

    @action // set active tier hierarchy codes
    setHierarchyCode = () => {
        if (!this.activeTier) return;
        if (this.activeTier?.hierarchyCodes === null) {
            this.activeTier.hierarchyCodes = [];
        }
        const currentHierarchyCodes = this.activeTier.hierarchyCodes;

        const foundCodeValue = currentHierarchyCodes?.includes(
            this.hierarchyCodeValue as string,
        );

        const isEmptyString = this.hierarchyCodeValue?.trim() === "";

        if (isEmptyString) {
            this.messageStore.logError(
                "The hierarchy code cannot be an empty string.",
            );
            return;
        }

        if (foundCodeValue) {
            this.messageStore.logError("That hierarchy code already exists.");
            return;
        }

        currentHierarchyCodes &&
            this.setActiveTierAttribute("hierarchyCodes", [
                this.hierarchyCodeValue?.trim(),
                ...(currentHierarchyCodes as string[]),
            ]);

        this.setEndpointFired(true, "Update");
        this.hierarchyCodeValue = "";
    };

    @action
    deleteHierarchyCode = (value: string) => {
        if (this.activeTier) {
            const updatedCodes = this.activeTier?.hierarchyCodes?.filter(
                (code) => code !== value,
            );
            this.setActiveTierAttribute("hierarchyCodes", updatedCodes);
            this.setEndpointFired(true, "Update");
        }
    };

    @action
    setEndpointFired = (
        active: boolean,
        payload?: "Add" | "Remove" | "Update",
    ) => {
        this.endpointFired = { active, payload };
    };

    @action
    setIsLoading = (isLoading: boolean) => {
        this.isLoading = isLoading;
    };

    @action
    setTriggerReassign = (isActive: boolean) => {
        this.triggerReassign = isActive;
    };

    @action
    setOrganizationId = (orgId: string) => {
        this.organizationId = orgId;
    };

    @action
    setShowReassignTierDialog = (isVisible: boolean) => {
        this.showReassignTierDialog = isVisible;
    };

    @action
    setShowDeleteDialog = (isVisible: boolean) => {
        this.showDeleteDialog = isVisible;
    };

    @action
    setShowAddTierDialog = (isVisible: boolean) => {
        this.showAddTierDialog = isVisible;
    };

    @action
    setShowAddTopLevelTierDialog = (isVisible: boolean) => {
        this.showAddTopLevelTierDialog = isVisible;
    };

    @action
    setShowEditTierDrawer = (isVisible: boolean) => {
        this.showEditTierDrawer = isVisible;
    };

    @action
    setShowServiceHierarchyUsersDrawer = (isVisible: boolean) => {
        this.showServiceHierarchyUsersDrawer = isVisible;
    };

    @action
    setSamplingHistoryRows = (input: any[]) => {
        this.samplingHistoryRows = input;
    };

    @action
    setSamplingHistoryPageNumber = (num: number) => {
        this.samplingHistoryPageNumber = num;
    };

    @action
    setAdminUsers = () => {
        this.adminUsers = [];

        if (
            this.activeTier &&
            (this.activeTier.administrativeContacts?.length ?? 0) > 0
        ) {
            let tmpUser = {} as ApplicationUser;

            this.adminUsers = this.activeTier.administrativeContacts.map(
                (contact) => {
                    this.orgUsers.forEach((user) => {
                        if (user.id === contact.userId) {
                            tmpUser = user;
                            return;
                        }
                    });

                    if (!tmpUser)
                        console.log(
                            `Could not find a admin contact for ${contact.userName}`,
                        );
                    return tmpUser;
                },
            );
        }
    };

    @action
    setEscalationUsers = () => {
        this.escalationUsers = [];

        if (
            this.activeTier &&
            (this.activeTier.escalationContacts?.length ?? 0) > 0
        ) {
            let tmpUser = {} as ApplicationUser;

            this.escalationUsers = this.activeTier.escalationContacts.map(
                (contact) => {
                    this.orgUsers.forEach((user) => {
                        if (user.id === contact.userId) {
                            tmpUser = user;
                            return;
                        }
                    });

                    if (!tmpUser)
                        console.log(
                            `Could not find a escalation contact for ${contact.userName}`,
                        );
                    return tmpUser;
                },
            );
        }
    };

    @action
    setManagerUsers = () => {
        this.managerUsers = [];

        if (
            this.activeTier &&
            (this.activeTier.managerContacts?.length ?? 0) > 0
        ) {
            let tmpUser = {} as ApplicationUser;

            this.managerUsers = this.activeTier.managerContacts.map(
                (contact) => {
                    this.orgUsers.forEach((user) => {
                        if (user.id === contact.userId) {
                            tmpUser = user;
                            return;
                        }
                    });

                    if (!tmpUser)
                        console.log(
                            `Could not find a manager contact for ${contact.userName}`,
                        );
                    return tmpUser;
                },
            );
        }
    };

    @action
    openEditTierDrawer = async () => {
        await this.setupAsyncTask("Get Sampling Target History", async () => {
            this.getSamplingTargetHistory(1);
        });

        this.samplingHistoryRows = [];
        this.hierarchyCodeValue = ""; // clear any hierarchy code value on close of drawer

        this.setAdminUsers();
        this.setEscalationUsers();
        this.setManagerUsers();

        this.setSamplingHistoryPageNumber(1);
        this.setShowEditTierDrawer(true);
    };

    @action
    openServiceHierarchyUsersDrawer = async () => {
        //Define Columns, necessary because role must have a roleName field to filter properly, if data coming down the pipe is changed this could be omitted in the future
        const columns: IColDef[] = [
            {
                headerName: "User Name",
                field: "userName",
                width: 250,
            },
            {
                headerName: "First Name",
                field: "firstName",
                width: 150,
            },
            {
                headerName: "Last Name",
                field: "lastName",
                width: 150,
            },
            {
                headerName: "Role",
                field: "roleName",
                width: 150,
            },
            {
                field: "disabled",
                headerName: "Disabled",
                renderCell: (params: GridCellParams) =>
                    params.row.disabled ? "True" : " ",
            },
        ];

        //Make API call
        const users = await this.userService.getUsersByHierarchy(
            this.activeTier?.id ?? "",
        );

        //Assign the roleName field, then populate data
        users.forEach((u) =>
            u.role ? (u.roleName = u.role.name) : (u.roleName = ""),
        );
        this.dgStore.setColumns(columns);
        this.dgStore.rows = users;

        //Prepare and populate the drawer wit appropriate content
        this.drawerStore.closeAndResetDrawer();
        this.drawerStore.restoreDefaults();
        this.drawerStore.setContentFactory(() => (
            <Grid
                container
                item
                xs={12}
                style={{ height: "100%", width: "900px" }}
            >
                <VerticalDrawerContentTemplate
                    title={"Assigned Hierarchy Users"}
                    subTitle={
                        this.activeTier?.name ?? "Hierarchy Name Unavailable"
                    }
                    width={"900px"}
                    content={
                        <div style={{ height: "calc(100% - 2rem)" }}>
                            <div style={{ height: "100%" }}>
                                <AcxDataGrid dataGridStore={this.dgStore} />
                            </div>
                        </div>
                    }
                    onClose={() => {}}
                />
            </Grid>
        ));

        //Signal that the drawer can be opened
        this.drawerStore.setOpen(true);
    };

    @action
    toggleLoginHistory = () => {};

    @action
    getSamplingTargetHistory = (page: number) => {
        const offset = (page - 1) * 10;

        this.setupAsyncTask(GET_SAMPLING_HISTORY, async () => {
            if (!this.activeTier) return;

            await this.orgService
                .getSamplingTargetForOrganizationStructureMember(
                    this.activeTier.id,
                    10,
                    offset,
                )
                .then((res) => {
                    res.history =
                        res.history?.map((histItem) => ({
                            ...histItem,
                            monitoredByUserFullName:
                                (histItem.monitoredByUserFirstName ?? "N/A") +
                                " " +
                                (histItem.monitoredByUserLastName ?? "N/A"),
                        })) ?? [];

                    this.setSamplingHistoryRows(res.history);
                });
        });
    };

    public createTopLevelTier = async (orgId: string, newBranch: Tier) => {
        try {
            const data = await this.orgService.createTier(
                orgId,
                newBranch,
                undefined,
            );

            this.treeStore.setTopLevelBranches([
                ...(this.treeStore.topLevelBranches ?? []),
                data.child,
            ]);

            this.messageStore.logMessage(
                `Successfully created ${newBranch.name}`,
                "success",
            );
        } catch (error) {
            console.error("CreateTopLevelTier", error);
            this.messageStore.logError(
                (error as Error).message ?? "Failed to create a new tier.",
            );
        }
    };

    public createOsmTier = async (
        orgId: string,
        parent: BranchDataProps,
        newBranch: BranchDataProps,
    ) => {
        try {
            const res = await this.orgService.createTier(
                orgId,
                newBranch as Tier,
                parent as Tier,
            );

            if (parent) {
                this.treeStore.openedBranchIds.push(parent.id);
            }

            this.setEndpointFired(false);

            this.messageStore.logMessage(
                "Successfully created tier.",
                "success",
            );

            return res;
        } catch (error) {
            console.error("CreateTierError", error);
            this.setEndpointFired(false);
            this.messageStore.logError(
                (error as Error).message ?? "Failed to create new tier.",
            );
        }
    };

    public getOsmTier = async (parentId: string) => {
        this.setIsLoading(true);

        const tier = await this.orgService.getServiceHierarchyLeaf(parentId);

        this.setIsLoading(false);

        return tier;
    };

    public getAvailableParents = async (
        orgId: string,
        parentId: string,
        branchDepth: number,
    ) => {
        this.setIsLoading(true);

        const parents = await this.orgService.getAvailableParents(
            orgId,
            parentId,
            branchDepth,
        );

        this.setIsLoading(false);

        return parents;
    };

    @action
    reassignOsmTier = async (
        orgId: string,
        parentId: string,
        childId: string,
    ) => {
        if (!parentId) {
            this.messageStore.logError("A parent tier must be selected.");
            return;
        }

        try {
            this.setIsLoading(true);

            const data = await this.orgService.reassignTier(
                orgId,
                parentId,
                childId,
            );

            this.setEndpointFired(false);
            this.setIsLoading(false);
            this.setTriggerReassign(true); // Fires useEffect() hook to rerender recursive tree

            return data;
        } catch (error) {
            console.error("ReassignTierError", error);
            this.setEndpointFired(false);
            this.setIsLoading(false);
            this.messageStore.logError(
                (error as Error).message ?? "Tier has failed to reassign.",
            );
        }
    };

    @action
    updateOsmTier = async (
        orgId: string,
        parentId: string,
        newBranch: BranchDataProps,
    ) => {
        if (newBranch.name.length === 0) {
            this.messageStore.logError("Name cannot be empty.");
            return;
        }

        try {
            this.setIsLoading(true);

            const updatedTier = await this.orgService.updateTier(
                orgId,
                parentId,
                newBranch as Tier,
            );

            if (updatedTier.hierarchyCodes === null) {
                updatedTier.hierarchyCodes = [];
            }

            if (this.endpointFired) {
                this.setEndpointFired(false);
            }
            this.setIsLoading(false);

            this.messageStore.logMessage(
                "Successfully updated tier.",
                "success",
            );

            return updatedTier;
        } catch (error) {
            this.setIsLoading(false);
            this.setEndpointFired(false);
            this.messageStore.logError(
                (error as Error).message ?? "Tier has failed to update.",
            );
        }
    };

    public deleteOsmTier = async (
        orgId: string,
        tierId: string,
        parentTier?: BranchDataProps,
    ) => {
        try {
            this.setIsLoading(true);

            const parent = await this.orgService.deleteTier(
                orgId,
                tierId,
                parentTier as Tier,
            );

            this.setEndpointFired(false);
            this.setIsLoading(false);

            this.messageStore.logMessage(
                "Successfully deleted tier.",
                "success",
            );

            return parent as unknown as BranchDataProps;
        } catch (error) {
            console.error("DeleteTierError", error);
            this.setIsLoading(false);
            this.setEndpointFired(false);
            this.messageStore.logError(
                (error as Error).message ?? "Failed to delete tier.",
            );
        }
    };

    @action
    confirmRemoveTier = () => {
        if (this.activeTier) {
            this.setShowDeleteDialog(false);
            this.setEndpointFired(true, "Remove"); // Our useEffect will fire off of this
        } else {
            this.messageStore.logInfo(
                "Something went wrong. Cancel and try again.",
            );
        }
    };

    public async getTopLevelServiceHierarchiesForOrganization() {
        const topLevelHierarchies =
            await this.orgService.getTopLevelServiceHierarchiesForOrganization();

        return topLevelHierarchies;
    }
}
