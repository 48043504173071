import { interactionDateHelper } from "components/UI/AcxDataGrid/ColumnTypes/DateColTypes";
import { evalNameFromBlobFileKey } from "components/UI/AcxDataGrid/Formatters/LinkFormatter";
import { action, computed, makeObservable, observable } from "mobx";
import moment, { Moment } from "moment";
import { capitalizeFirstChar } from "../utils/helpers";
import { isStringType } from "../utils/TypeGuards";
import Agentv2 from "./Agentv2";
import AudioMetadataModel from "./AudioMetadataModel";
import BaseEntity from "./BaseEntity";
import CallCenter from "./CallCenter";
import type CustomerType from "./CustomerType";
import Evaluation from "./Evaluation";
import { InteractionType } from "./InteractionType";
import Organization from "./Organization";
import type { OrganizationStructureMember } from "./OrganizationModels/OrganizationStructureMember";

export default class Interaction extends BaseEntity {
    @observable interactionDate?: Date | Moment | string;
    customerName?: string;
    customerEmail?: string;
    customerPhone?: string;

    contentLocation?: string;
    @observable vendorName?: string;
    @observable brandName?: string;
    @observable agentName?: string;

    interactionType?: InteractionType;
    interactionTypeId?: string;
    timeZoneId?: string;

    @observable agentId?: string;
    agent: Agentv2;
    @observable interactionDirection?: string;
    @observable callCenter?: string;
    @observable callSummary?: string;
    @observable repeatCaller: boolean;
    @observable eddyEffect: boolean;
    @observable holdCount?: number;
    @observable transferCount?: number;

    audioMetadataId?: string;
    @observable audioMetadata?: AudioMetadataModel;

    organizationId: string;
    organization: Organization;
    evaluations: Evaluation[];

    @observable customerTypeId?: string;
    @observable customerType?: CustomerType;

    @observable organizationStructureMemberId?: string | null;
    @observable
    organizationStructureMember?: OrganizationStructureMember;

    @action
    setInteractionDirection = (arg: string | undefined) => {
        this.interactionDirection = arg;
        if (this.audioMetadata) {
            this.audioMetadata.callDirection = arg;
        }
    };

    @action
    setTransferCount = (count?: number) => {
        this.transferCount = count;
    };

    @action
    setHoldCount = (count?: number) => {
        this.holdCount = count;
    };

    constructor(id?: string) {
        // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
        super(id);

        makeObservable(this);
    }

    @action
    setEddyEffect(isEddy: boolean) {
        this.eddyEffect = isEddy;
    }

    @action
    setRepeatCaller(repeatCaller: boolean) {
        this.repeatCaller = repeatCaller;
    }

    @action
    setAgent = (agent?: Agentv2) => {
        this.agentId = agent?.id;
        this.agentName = agent?.fullName;
    };

    @action
    setCallCenter = (callCenter: CallCenter) => {
        this.callCenter = callCenter.callCenterName;
    };

    @action
    setOrganizationStructureMember = (
        ogsm: OrganizationStructureMember | string,
    ) => {
        if (typeof ogsm === "string") {
            this.organizationStructureMemberId = ogsm;
        } else {
            this.organizationStructureMemberId = ogsm.id;
            this.organizationStructureMember = ogsm;
        }
    };

    @action
    setCallSummary(summary: string | undefined) {
        this.callSummary = summary;
    }

    @action
    setInteractionDate = (date: Moment | Date | string | undefined) => {
        if (moment.isMoment(date)) {
            date?.set("hour", 12).set("minute", 0);
            date = date.utc();
        }
        this.interactionDate = date;
        if (this.audioMetadata) {
            if (isStringType(date)) {
                this.audioMetadata.timestamp = date;
            } else {
                this.audioMetadata.timestamp = (date as Moment)?.toISOString();
            }
        }
    };

    @action
    setCustomerTypeId = (custTypeId?: string) => {
        this.customerTypeId = custTypeId;
    };

    @action
    setCustomerType = (custType?: CustomerType) => {
        this.customerType = custType;
        this.setCustomerTypeId(custType?.id);
    };

    @computed
    get InteractionDirectionDisplay() {
        return capitalizeFirstChar(this.interactionDirection);
    }

    @computed
    get formattedName() {
        return evalNameFromBlobFileKey(this.audioMetadata?.blobFileKey);
    }

    get InteractionDateAsMoment() {
        if (this.interactionDate) {
            return moment(interactionDateHelper(this.interactionDate));
        }
        return undefined;
    }

    static fromJson(interactionJson?: Interaction) {
        if (interactionJson) {
            return Object.assign(
                new Interaction(interactionJson.id),
                interactionJson,
            );
        }
        return interactionJson;
    }
}
