import {
    Button,
    Grid,
    IconButton,
    Theme,
    Typography,
    useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import { observer, Observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { InteractionType } from "../../../../models/InteractionType";
import SoundClip from "../../../../models/SoundClip";
import theme from "../../../../Theme/AppTheme";
import { useStore } from "../../../../utils/useStore";
import AcxMainExpansion, {
    ConditionalStyleOverride,
} from "../../../UI/AcxMainExpansion";
import AcxMainTextField from "../../../UI/AcxMainTextFieldGrid";
import AcxMicroPlayer from "../../../UI/Audio/AcxMicroPlayer";
import AcxSelectMulti from "../../../UI/Select/BaseSelectComponents/AcxSelectMulti";
import {
    createAudioClipTask,
    EvalStore,
    LoadAudioTranscriptionTask,
    UpdateAudioClip,
} from "../../Stores/EvalStore";
import { isTextMediaType } from "../EvalPage";
import {
    formatSecondsToHms,
    getColorFromClipIndex,
} from "components/SoundClipEditor/utils";
import { UpdateSoundClipRequest } from "services/SoundClipService";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import { AsyncTaskStatus } from "stores/BaseStore";
import clsx from "clsx";
import { Answer } from "models/Answer";
import AnswerType, { AnswerTypeNames } from "models/AnswerType";
import LicensedModule, { RenderPlacement } from "models/LicensedModule";
import Question from "models/Question";
import { Tag } from "models/Tag";

interface StylesProps {
    index: number;
}

const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        marginBottom: 0,
    },
    header: {
        userSelect: "none",
        minHeight: "56px",
        margin: 0,
    },
    titleContainer: {
        display: "flex",
        alignItems: "center",
        "& .hidden-edit-icon": {
            visibility: "hidden",
        },
        "&:hover .hidden-edit-icon": {
            visibility: "visible",
        },
    },
    subTitle: {
        color: theme.palette.text.secondary,
        fontWeight: "normal",
        border: "1px transparent solid",
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(0.5),
        "&:hover": {
            cursor: "text",
        },
    },
    deleteEditButtons: {
        color: theme.palette.primary.main,
        fontFamily: "Inter",
        fontSize: "13px",
        fontWeight: "bold",
        letterSpacing: 0,
        lineHeight: "20px",
        textTransform: "none",
    },
    buttonMarginRight: { marginRight: theme.spacing(1) },
    bottomSpacing: {
        marginBottom: theme.spacing(2),
    },
    controlButton: ({ index }) => ({
        color: getColorFromClipIndex(index),
    }),
}));

interface IClipViewProps {
    soundClip: SoundClip;
    isDisabled?: boolean;
    mediaUrl?: string;
    onDeleteButton: () => void;
    isOpen?: boolean;
    index: number;
}

interface UpdateAnswerResults {
    wasClipAdded: boolean;
    wasClipRemoved: boolean;
    wasTagAdded: boolean;
}

const headerOverride: ConditionalStyleOverride = {
    1 /*TRUE*/: {
        backgroundColor: theme.palette.primary[50],
        color: theme.palette.text.primary,
    },
    0 /*FALSE*/: {
        backgroundColor: theme.palette.white.main,
        color: theme.palette.white.contrastText,
    },
};

const bodyOverride: ConditionalStyleOverride = {
    1: {
        backgroundColor: theme.palette.primary[50],
        color: theme.palette.text.primary,
    },
    0: {
        backgroundColor: theme.palette.white.main,
        color: theme.palette.white.contrastText,
    },
};

const chevronOverride: ConditionalStyleOverride = {
    1: { color: theme.palette.text.primary },
    0: { color: theme.palette.text.primary },
};

const ClipView: React.FC<IClipViewProps> = observer(
    ({ soundClip, isDisabled, mediaUrl, onDeleteButton, isOpen, index }) => {
        const classes = useStyles({ index });
        const store = useStore(EvalStore);
        const messageStore = useStore(MessageStore);
        const theme = useTheme();

        const [title, setTitle] = useState(soundClip?.segmentName ?? "");
        const [transcriptionText, setTranscriptionText] = useState(
            soundClip?.transcriptionText ?? "",
        );
        const [note, setNote] = useState(soundClip?.note ?? "");
        const [editing, setEditing] = useState(false);

        useEffect(() => {
            if (soundClip?.segmentName) {
                setTitle(soundClip?.segmentName);
            }
        }, [soundClip?.segmentName]);

        useEffect(() => {
            if (soundClip?.transcriptionText) {
                setTranscriptionText(soundClip.transcriptionText);
            }
        }, [soundClip?.transcriptionText]);

        useEffect(() => {
            if (soundClip?.note) {
                setNote(soundClip.note);
            }
        }, [soundClip?.note]);

        const isLoading =
            store.getTaskLoading(`${createAudioClipTask}-${soundClip?.id}`) ||
            store.getTaskLoading(`${UpdateAudioClip}-${soundClip?.id}`) ||
            store.getTaskLoading(`UpdateClip-${soundClip?.id}`);

        const disabled = isDisabled || isLoading;

        const updateAnswerFromClipTagChange = (
            question: Question,
            isTagRemoved: boolean,
            updatedTag: Tag,
            answer: Answer | undefined,
            answerType: AnswerType,
            isTagResponse: boolean,
            newTagsHaveSameQuestion: boolean,
        ): UpdateAnswerResults => {
            let wasClipRemoved = false;
            let wasClipAdded = false;
            let wasTagAdded = false;
            if (answer) {
                // add new soundclip to the answer
                let existingSoundClips: SoundClip[] = answer?.soundClipAnswers
                    .filter((value) => {
                        return !!value.soundClip;
                    })
                    .map((value) => value.soundClip) as SoundClip[];

                if (isTagRemoved) {
                    if (!newTagsHaveSameQuestion) {
                        if (
                            existingSoundClips.find((value) => {
                                return value.id === soundClip?.id;
                            })
                        ) {
                            wasClipRemoved = true;
                        }
                        // this is the last tag attached to the clip for that question so remove the clip
                        existingSoundClips = existingSoundClips.filter(
                            (value) => {
                                return value.id !== soundClip?.id;
                            },
                        );
                    }
                    answer.setSoundClips([...existingSoundClips]);
                } else {
                    if (
                        !existingSoundClips
                            .map((sc) => sc.id)
                            .includes(soundClip.id)
                    ) {
                        wasClipAdded = true;
                    }
                    answer?.setSoundClips([...existingSoundClips, soundClip]);
                }
            } else {
                wasClipAdded = true;
                question.answerType = answerType;
                // if an eval question does not yet have an active selected answer tag (e.g. when adding a soundclip to a blank eval question)
                const newAnswer = new Answer(
                    store.currentEval?.id ?? "",
                    question.id,
                    question,
                    undefined,
                    store.currentEval?.createdBy,
                    store.currentEval?.modifiedBy,
                );
                newAnswer?.setSoundClips([soundClip]);
                store.currentEval?.updateAnswer(
                    newAnswer,
                    question.id,
                    undefined,
                    [updatedTag],
                    store.enableAutoBindClips,
                );
                newAnswer.setQuestion(question);
                answer = newAnswer;
            }

            // only update answer tags for tag answer types, only add tags / never remove tags
            if (isTagResponse) {
                const existingTags = answer?.activeAnswerTags.map(
                    (value) => value.tag,
                ) as Tag[];

                if (!isTagRemoved) {
                    if (
                        !existingTags.map((t) => t.id).includes(updatedTag.id)
                    ) {
                        wasTagAdded = true;
                    }
                    if (answerType.variation === "Single") {
                        answer?.setAnswerTags([updatedTag]);
                    } else {
                        answer?.setAnswerTags([...existingTags, updatedTag]);
                    }
                }
            }
            return { wasClipAdded, wasClipRemoved, wasTagAdded };
        };

        const navigateUserToUpdatedQuestion = (
            selectedModule: LicensedModule,
            selectedQuestion: Question,
            soundClip: SoundClip,
            updatedTag: Tag,
            result: UpdateAnswerResults,
        ) => {
            let clipMessage = "";

            if (result.wasClipAdded || result.wasClipRemoved) {
                clipMessage = `Clip "${soundClip.segmentName}" was ${
                    result.wasClipRemoved ? "removed from " : "added to "
                } "${selectedQuestion.shortQuestionText}".`;
            }

            let tagMessage = !!clipMessage
                ? ` Tag "${updatedTag?.value}" was also added.`
                : `Tag "${updatedTag?.value}" was added to "${selectedQuestion.shortQuestionText}".`;

            tagMessage = result.wasTagAdded ? tagMessage : "";

            if (!clipMessage && !tagMessage) return;

            const isSameModule = store.currentModule?.id === selectedModule.id;

            if (!isSameModule) {
                store.setCurrentModule(selectedModule);
            }
            setTimeout(
                () => {
                    document
                        .getElementById(`question-${selectedQuestion.id}`)
                        ?.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                        });
                },
                isSameModule ? 0 : 1000,
            );

            messageStore.logMessage(clipMessage + tagMessage, "success");
        };

        const onClipTagChange = (newTags: Tag[] | undefined) => {
            // handle remove all (newTags = undefined)
            if (!newTags) {
                if (store.enableAutoBindClips) {
                    soundClip?._tags?.forEach((t) => {
                        const licensedModuleForTag =
                            store.licensedModulesForEval?.find(
                                (value) =>
                                    value.id === t?.question?.licensedModuleId,
                            );
                        let answer = store.currentEval?.getAnswerForQuestion(
                            t.question!,
                            licensedModuleForTag?.evaluationModuleId,
                        );
                        const existingClips = answer?.activeSoundClips;
                        const updatedClips = existingClips?.filter(
                            (sc) => sc.id !== soundClip.id,
                        );
                        answer?.setSoundClips(updatedClips);
                    });
                    messageStore.logMessage(
                        `Clip "${soundClip.segmentName}" was removed from attached questions.`,
                        "success",
                    );
                }
                soundClip?.setClipTags([]);
                return;
            }

            let isTagRemoved: boolean =
                (soundClip?._tags?.length ?? 0) > newTags.length;

            let updatedTag: Tag | undefined = newTags[newTags.length - 1];

            if (isTagRemoved) {
                // get updated tag by comparing newTags to old tags and finding missing value
                updatedTag = soundClip?._tags?.find(
                    (t) => !newTags?.map((st) => st.id).includes(t.id),
                );
            }

            soundClip?.setClipTags([...newTags]);

            if (!store.enableAutoBindClips) return;

            if (!updatedTag || !updatedTag?.question) return;

            const licensedModuleForTag = store.licensedModulesForEval?.find(
                (value) => value.id === updatedTag?.question?.licensedModuleId,
            );
            if (!licensedModuleForTag) return;

            // if tag is contained in a module not visible for user, do not update that answer at all
            let isFromVisibleModule =
                licensedModuleForTag.renderPlacement ===
                    RenderPlacement.Middle &&
                !licensedModuleForTag.isWorkflowModule;

            if (!isFromVisibleModule) {
                if (!isTagRemoved) {
                    messageStore.logMessage(
                        "Tagged clips cannot be automatically associated to this module.",
                        "error",
                    );
                }
                return;
            }

            const answerType = licensedModuleForTag.questions.find(
                (ques) => ques.id === updatedTag?.question?.id,
            )?.answerType;
            if (!answerType) return;

            const isTagResponse =
                answerType?.answerTypeName === AnswerTypeNames.TagResponse ||
                answerType?.answerTypeName ===
                    AnswerTypeNames.ScoredTagResponse;

            const newTagsHaveSameQuestion = newTags
                .map((t) => t.questionId)
                .includes(updatedTag?.question.id);

            let answer = store.currentEval?.getAnswerForQuestion(
                updatedTag?.question,
                licensedModuleForTag.evaluationModuleId,
            );

            const result = updateAnswerFromClipTagChange(
                updatedTag?.question,
                isTagRemoved,
                updatedTag,
                answer,
                answerType,
                isTagResponse,
                newTagsHaveSameQuestion,
            );

            // removed a tag response answer with multiple tags, so we arent updating the answer at all
            if (newTagsHaveSameQuestion && isTagRemoved) return;

            navigateUserToUpdatedQuestion(
                licensedModuleForTag,
                updatedTag?.question,
                soundClip,
                updatedTag,
                result,
            );
        };

        const body = (
            <Observer>
                {() => (
                    <Grid
                        container
                        className={classes.root}
                        direction="column"
                        justifyContent="space-around"
                        alignItems="stretch"
                    >
                        <Grid item xs={12} className={classes.bottomSpacing}>
                            <Observer>
                                {() => (
                                    <AcxMainTextField
                                        id={"transcribed-text-field"}
                                        multiLine
                                        rows={4}
                                        rowsMax={8}
                                        helperText={"Transcription Failed"}
                                        error={
                                            Boolean(
                                                store.getTaskError(
                                                    LoadAudioTranscriptionTask,
                                                )?.message,
                                            ) &&
                                            !Boolean(
                                                soundClip?.transcriptionText,
                                            )
                                        }
                                        placeholderText={
                                            !Boolean(
                                                soundClip?.transcriptionText,
                                            ) &&
                                            store.getTaskLoading(
                                                LoadAudioTranscriptionTask,
                                            )
                                                ? "Loading Transcription..."
                                                : undefined
                                        }
                                        onChange={(evt) =>
                                            setTranscriptionText(
                                                evt.target.value,
                                            )
                                        }
                                        onBlur={async () => {
                                            if (
                                                soundClip?.transcriptionText ===
                                                transcriptionText
                                            )
                                                return;

                                            soundClip?.setTranscriptionText(
                                                transcriptionText,
                                            );

                                            const result =
                                                await store.updateTextClip(
                                                    soundClip as UpdateSoundClipRequest,
                                                );

                                            if (
                                                result === AsyncTaskStatus.Error
                                            )
                                                return messageStore.logError(
                                                    `Failed to save clip transcript. Please try again.`,
                                                );

                                            messageStore.logInfo(
                                                "Clip transcription saved successfully.",
                                            );
                                        }}
                                        labelText={"Transcribed Clip"}
                                        disabled={disabled}
                                        value={transcriptionText}
                                    />
                                )}
                            </Observer>
                        </Grid>

                        <Grid item xs={12} className={classes.bottomSpacing}>
                            <Observer>
                                {() => (
                                    <AcxMainTextField
                                        id={"transcribed-text-note-field"}
                                        labelText={"Add A Note"}
                                        multiLine
                                        rowsMax={4}
                                        value={note}
                                        onChange={(evt) =>
                                            setNote(evt.target.value)
                                        }
                                        onBlur={async () => {
                                            if (soundClip?.note === note)
                                                return;

                                            soundClip?.setNote(note);

                                            const result =
                                                await store.updateTextClip(
                                                    soundClip as UpdateSoundClipRequest,
                                                );

                                            if (
                                                result === AsyncTaskStatus.Error
                                            )
                                                return messageStore.logError(
                                                    `Failed to save clip note. Please try again.`,
                                                );

                                            messageStore.logInfo(
                                                "Clip note saved successfully.",
                                            );
                                        }}
                                        disabled={disabled}
                                    />
                                )}
                            </Observer>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            className={classes.bottomSpacing}
                            style={{ maxWidth: "100%" }}
                        >
                            <AcxSelectMulti
                                isClearable={true}
                                containerHeight={"auto"}
                                inputLabel={"Add Tag(s)"}
                                id={"clip-tag-selection-" + soundClip?.id}
                                fullWidth
                                options={store.groupClipTags as any[]}
                                colorField={"sentimentColor"}
                                customStyle={{
                                    menuPortal: (provided, state) => ({
                                        ...provided,
                                        zIndex: 9999,
                                    }),
                                }}
                                menuPlacement={"top"}
                                menuPortalTarget={document.body}
                                onChange={onClipTagChange}
                                defaultValue={soundClip?.clipTags ?? []}
                                valueField="id"
                                labelField="value"
                                groupCollapse={true}
                                isDisabled={isDisabled}
                            />
                        </Grid>

                        <Grid
                            item
                            container
                            direction={"row"}
                            justifyContent={"flex-end"}
                        >
                            {!store.evalChat && (
                                <Button
                                    className={clsx(
                                        classes.deleteEditButtons,
                                        classes.buttonMarginRight,
                                    )}
                                    onClick={() => {
                                        store.soundClipEditorStore.selectAndEditSoundClip(
                                            soundClip,
                                        );
                                    }}
                                >
                                    <EditIcon fontSize="small" />
                                    <span
                                        style={{ marginLeft: theme.spacing(1) }}
                                    >
                                        Edit
                                    </span>
                                </Button>
                            )}
                            <Button
                                className={classes.deleteEditButtons}
                                onClick={onDeleteButton}
                            >
                                <DeleteIcon fontSize="small" />
                                <span style={{ marginLeft: theme.spacing(1) }}>
                                    Delete
                                </span>
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </Observer>
        );

        const headerMenu = () => {
            const loadingContent = (
                <AcxLoadingIndicator
                    color="secondary"
                    alternate="PuffLoader"
                    size={50}
                />
            );

            if (isTextMediaType(store.mediaType)) {
                return (
                    <Observer>
                        {() => (
                            <>
                                {store.getTaskLoading(
                                    `${createAudioClipTask}-${soundClip?.segmentName}`,
                                ) ? (
                                    <>{loadingContent}</>
                                ) : (
                                    <IconButton
                                        onClick={(event) =>
                                            store.setActiveClipInverse(
                                                soundClip,
                                            )
                                        }
                                        size="large"
                                    >
                                        <SendIcon
                                            className={classes.controlButton}
                                        />
                                    </IconButton>
                                )}{" "}
                            </>
                        )}
                    </Observer>
                );
            } else if (store.mediaType === InteractionType.Audio) {
                return (
                    <Observer>
                        {() => (
                            <AcxMicroPlayer
                                style={{ width: 20, height: 20 }}
                                controlButtonClass={classes.controlButton}
                                isLoading={isLoading}
                                getFunction={() => {
                                    if (
                                        soundClip?.segmentChunks &&
                                        soundClip.segmentChunks.length > 0
                                    ) {
                                        return Promise.resolve(
                                            soundClip.segmentChunks[0],
                                        );
                                    }
                                    return store.downloadClipFromBlob(
                                        soundClip?.filePath,
                                    );
                                }}
                                mediaUrl={mediaUrl}
                            />
                        )}
                    </Observer>
                );
            } else {
                return null;
            }
        };

        return (
            <AcxMainExpansion
                header={
                    <>
                        <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            {editing ? (
                                <>
                                    <Grid item xs={7}>
                                        <AcxMainTextField
                                            id="sound-clip-title"
                                            value={title}
                                            onChange={(evt) =>
                                                setTitle(evt.target.value)
                                            }
                                            onBlur={async () => {
                                                setEditing(false);
                                                if (
                                                    soundClip?.segmentName ===
                                                    title
                                                )
                                                    return;

                                                soundClip?.setSegmentName(
                                                    title,
                                                );

                                                const result =
                                                    await store.updateTextClip(
                                                        soundClip as UpdateSoundClipRequest,
                                                    );

                                                if (
                                                    result ===
                                                    AsyncTaskStatus.Error
                                                )
                                                    return messageStore.logError(
                                                        `Failed to save clip name. Please try again.`,
                                                    );

                                                messageStore.logInfo(
                                                    "Clip name saved successfully.",
                                                );
                                            }}
                                            autoFocus
                                            disabled={disabled}
                                            inputProps={{
                                                autoComplete: "off",
                                            }}
                                            blurOnEnterPress
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography>
                                            {`${formatSecondsToHms(
                                                soundClip?.startTime,
                                            )} - ${formatSecondsToHms(
                                                soundClip?.endTime,
                                            )}`}
                                        </Typography>
                                    </Grid>
                                </>
                            ) : (
                                <Grid item>
                                    <div
                                        className={classes.titleContainer}
                                        onClick={() => {
                                            setEditing(true);
                                        }}
                                    >
                                        <Typography
                                            component="span"
                                            className={classes.subTitle}
                                            style={{
                                                color:
                                                    store.activeClip ===
                                                        soundClip && soundClip
                                                        ? theme.palette.info
                                                              .main
                                                        : theme.palette.text
                                                              .primary,
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {title}
                                        </Typography>
                                        <EditIcon
                                            className="hidden-edit-icon"
                                            fontSize="inherit"
                                            style={{
                                                alignmentBaseline: "middle",
                                            }}
                                        />
                                    </div>
                                </Grid>
                            )}
                        </Grid>
                    </>
                }
                headerClass={classes.header}
                disableTooltip
                headerStyleOverRide={headerOverride}
                bodyStyleOverRide={bodyOverride}
                chevronStyleOverRide={chevronOverride}
                alwaysShowHeaderMenu
                body={body}
                headerMenu={headerMenu}
                expanded={store.openClipId === soundClip?.id || isOpen}
            />
        );
    },
);

export default ClipView;
