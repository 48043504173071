import { Grid, Theme, Typography } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import ACXCard from "components/UI/ACXCard";
import AcxExpansionAccordion from "components/UI/AcxExpansionAccordion";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import React from "react";
import useStyles from "Styles/Styles";
import { ClassifierDisplayType } from "../../types";

const styles = (theme: Theme) =>
    createStyles({
        expandHeader: {
            color: theme.palette.secondary.contrastText,
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.h5.fontSize,
        },
    });

interface OwnProps {
    classifiers: ClassifierDisplayType[];
    currentExpanded: number | false;
    onChangeExpansion: (
        index: number,
    ) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
    isLoading: boolean;
}

export default function TranscriptionVisualClassifiersView(props: OwnProps) {
    const classes = useStyles(styles);

    function renderClassifierAccordion(classifier: ClassifierDisplayType) {
        return (
            <Grid container>
                {classifier.keywords.map((kw, idx) => (
                    <React.Fragment key={"keyword_" + idx}>
                        <Grid item xs={8}>
                            <Typography variant={"h6"}>{kw.name}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant={"h6"}>{kw.hits}</Typography>
                        </Grid>
                    </React.Fragment>
                ))}
            </Grid>
        );
    }

    let content: JSX.Element;

    if (props.isLoading) {
        content = (
            <AcxLoadingIndicator
                color="secondary"
                alternate="PuffLoader"
                size={50}
            />
        );
    } else {
        content = (
            <>
                {props.classifiers?.map((classifier, idx) => (
                    <AcxExpansionAccordion
                        header={
                            <div
                                className={classes.expandHeader}
                            >{`${classifier.name} [${classifier.hits}]`}</div>
                        }
                        index={idx}
                        expanded={props.currentExpanded}
                        onChange={props.onChangeExpansion}
                        content={renderClassifierAccordion(classifier)}
                        key={"classifier_" + idx}
                    />
                ))}
            </>
        );
    }

    return <ACXCard title="Classifiers" content={content} />;
}
