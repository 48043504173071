import { Switch, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import DeleteIcon from "@mui/icons-material/Delete";
import AcxMainTextField from "components/UI/AcxMainTextField";
import AcxSelectSingle from "components/UI/Select/BaseSelectComponents/AcxSelectSingle";
import _ from "lodash";
import React from "react";
import { FormFieldErrors } from "shared/types/FormErrors.type";
import useStyles from "Styles/Styles";
import { sentimentOptions } from "../modules-constants";

const styles = (theme: Theme) => {
    return createStyles({
        trashIcon: {
            position: "absolute",
            top: "15%",
            left: "15%",
            cursor: "pointer",
            color: theme.palette.blue.main,
            "&:hover": {
                transform: "scale(1.03)",
            },
        },
    });
};

type Props = {
    value: any;
    onChange: (prop: string, value: string | number) => void;
    addRow?: () => void;
    removeRow: () => void;
    errors?: FormFieldErrors;
    questionIndex: number;
    tagIndex: number;
    errorPath: string;
    showInactive: boolean;
    isNew: boolean | undefined;
    isScored?: boolean;
};

const OrganizationModuleDetailsSelection: React.FC<Props> = ({
    value,
    onChange,
    errors,
    tagIndex,
    errorPath = "",
    removeRow,
    isNew,
    isScored,
}) => {
    const onChangeField = (prop: string, val: any) => {
        onChange(prop, val);
    };

    const classes = useStyles(styles);

    return (
        <>
            <td style={{ position: "relative" }} key={`${value}_${tagIndex}_1`}>
                {isNew === true ? (
                    <DeleteIcon
                        onClick={() => removeRow()}
                        titleAccess="Remove Question"
                        className={classes.trashIcon}
                    />
                ) : (
                    <Switch
                        sx={{ minWidth: "40px" }}
                        onChange={() =>
                            onChangeField("isActive", !value.isActive)
                        }
                        checked={value.isActive}
                        id="isActive"
                    />
                )}
            </td>
            <td key={`${value}_${tagIndex}_2`}>
                <AcxMainTextField
                    value={value.value}
                    onBlur={onChangeField.bind(null, "value")}
                    id={`value`}
                    error={
                        !!errors?.[`${errorPath}.Tags[${tagIndex}].Value`]
                            ?.length
                    }
                    helperText={
                        errors?.[`${errorPath}.Tags[${tagIndex}].Value`]
                    }
                />
            </td>
            {isScored && (
                <td key={`${value}_${tagIndex}_3`}>
                    <AcxMainTextField
                        value={value.scoredValue}
                        onBlur={onChangeField.bind(null, "scoredValue")}
                        id={`scoredValue`}
                        error={
                            !!errors?.[
                                `${errorPath}.Tags[${tagIndex}].ScoredValue`
                            ]?.length
                        }
                        helperText={
                            errors?.[
                                `${errorPath}.Tags[${tagIndex}].ScoredValue`
                            ]
                        }
                    />
                </td>
            )}
            <td key={`${value}_${tagIndex}_4`}>
                <AcxMainTextField
                    type="number"
                    value={value.order}
                    onBlur={onChangeField.bind(null, "order")}
                    id={`order`}
                    error={
                        !!errors?.[`${errorPath}.Tags[${tagIndex}].Order`]
                            ?.length
                    }
                    helperText={
                        errors?.[`${errorPath}.Tags[${tagIndex}].Order`]
                    }
                />
            </td>
            <td key={`${value}_${tagIndex}_5`}>
                <AcxSelectSingle
                    placeholder="None"
                    id={`sentiment`}
                    valueField="value"
                    labelField="label"
                    options={Object.values(sentimentOptions)}
                    defaultValue={sentimentOptions[String(value.sentiment)]}
                    onChange={(val) => onChangeField("sentiment", val.value)}
                    error={
                        !!errors?.[`${errorPath}.Tags[${tagIndex}].Sentiment`]
                            ?.length
                    }
                    helperText={
                        errors?.[`${errorPath}.Tags[${tagIndex}].Sentiment`]
                    }
                />
            </td>
        </>
    );
};

export default React.memo(OrganizationModuleDetailsSelection, areEqual);

function areEqual(prevProps, nextProps) {
    return (
        // Other props
        _.isEqual(prevProps.errors, nextProps.errors) &&
        prevProps.questionIndex === nextProps.questionIndex &&
        prevProps.tagIndex === nextProps.tagIndex &&
        prevProps.errorPath === nextProps.errorPath &&
        prevProps.showInactive === nextProps.showInactive &&
        prevProps.isNew === nextProps.isNew &&
        // Tag props
        _.isEqual(
            prevProps.value.classifierResultAnswerTag,
            nextProps.value.classifierResultAnswerTag,
        ) &&
        prevProps.value.id === nextProps.value.id &&
        prevProps.value.isActive === nextProps.value.isActive &&
        prevProps.value.order === nextProps.value.order &&
        prevProps.value.scoredValue === nextProps.value.scoredValue &&
        prevProps.value.sentiment === nextProps.value.sentiment &&
        prevProps.value.value === nextProps.value.value
    );
}
