import EditIcon from "@mui/icons-material/Edit";
import { Divider, Theme, Tooltip, useTheme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import clsx from "clsx";
import * as _ from "lodash";
import React, { CSSProperties, ReactElement, ReactNode, useState } from "react";
import Select, { ActionMeta, OptionTypeBase, components } from "react-select";
import { isArrayType, isNullableType } from "utils/TypeGuards";
import { uuidv4 } from "utils/helpers";
import { default as hexToRGB } from "utils/hexToRGB";
import useStyles from "../../../../Styles/Styles";
import theme from "../../../../Theme/AppTheme";
import AcxInputLabel from "../../AcxInputLabel";
import DownChevronSvg from "./DownChevronSvg";

const styles = (theme: Theme) =>
    createStyles({
        field: {
            width: "100%",
            backgroundColor: theme.palette.white.main,
        },
        editOver: {
            cursor: "pointer",
            "&:hover": {
                color: hexToRGB(theme.palette.black.main, 0.6),
            },
        },
        textLabel: {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            fontSize: "12px",
            lineHeight: "16px",
        },
        groupStyles: {
            color: "white",
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(0.5),
            paddingLeft: theme.spacing(0.5),
        },
        collapsed: {
            display: "none",
        },
        helperText: {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            fontSize: "12px",
            lineHeight: "16px",
        },
        helperTextError: {
            color: `${theme.palette.error.main} !important`,
        },
        required: {
            "&:after": {
                color: theme.palette.red.main,
                content: '" *" !important',
            },
        },
        optionContainer: {
            width: "100%",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
        },
    });

export interface IAcxSelect {
    containerOffset?: { left?: string; right?: string };
    options: any[];
    id: string;
    onChange?: (arg0: any | undefined) => void;
    onMultiChange?: (arg0: any[] | undefined) => void;
    placeholder?: string;
    isMulti?: boolean;
    menuPortalTarget?: HTMLElement | null | undefined;
    defaultValue?: any;
    valueField: string;
    labelField: string;
    helpField?: string;
    colorField?: string;
    inputLabel?: string;
    noValue?: boolean;
    enableNoSelection?: boolean;
    editIcon?: boolean;
    onEditClick?: () => void;
    onBlur?: () => void;
    isClearable: boolean;
    isLoading: boolean;
    shrink?: boolean;
    infoText?: React.ReactNode;
    fullWidth?: boolean;
    minWidth?: string;
    width?: string;
    containerHeight?: string | "auto";
    maxContainerHeight?: string;
    alignControls?: CSSProperties["alignItems"];
    isDisabled?: boolean;
    menuPlacement?: "top" | "bottom" | "auto";
    customStyle?: Record<string, (provided, state) => CSSProperties>;
    extendedCustomStyle?: Record<string, (provided, state) => CSSProperties>;
    // customRootStyles?: React.CSSProperties;
    maxMenuHeightPx?: number;
    required?: boolean;
    customFilter?: (option, rawInput: string) => boolean;
    groupCollapse?: boolean;
    skeleton?: boolean;
    helperText?: React.ReactNode;
    showAllErrors?: boolean;
    error?: boolean;
    optionComponent?: (props) => ReactNode;
    tooltips?: { [key: string]: string };
    dropdownMenuEndChildren?: React.ReactNode;
    alternateItemColor?: string;
    dropdownIndicator?: React.ReactNode;
    isSearchable?: boolean;
}

export interface OptionType extends OptionTypeBase {}

function AcxSelect(props: IAcxSelect) {
    const classes = useStyles(styles);
    const appTheme = useTheme();
    const [labelFocus, setLabelFocus] = useState<boolean | undefined>();
    const [currentValue, setCurrentValue] = useState<any>(props.defaultValue);
    const [options, setOptions] = useState<any[]>(props.options);
    const [thisId, setThisId] = useState<string>();
    const [shouldCollapse, setShouldCollapse] = useState<boolean>(
        props.groupCollapse ?? false,
    );

    const onEditClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();

        if (props.onEditClick) {
            props.onEditClick();
        }
    };

    const IndicatorSeparator = ({ innerProps }) => {
        if (props.editIcon) {
            return (
                <div
                    onMouseDown={onEditClick}
                    className={classes.editOver}
                    style={{ padding: "6px" }}
                >
                    <EditIcon viewBox={"0 0 20 20"} style={{ fontSize: 14 }} />
                </div>
            );
        } else {
            return <div></div>;
        }
    };

    const onHeaderClick = (id) => {
        const node =
            document.getElementById(id)?.parentElement?.nextElementSibling;
        if (node) {
            for (let i = 0; i < node.children.length; i++) {
                const element = node.children[i];
                const cls = element.classList;
                if (cls.contains(classes.collapsed)) {
                    element.classList.remove(classes.collapsed);
                } else {
                    element.classList.add(classes.collapsed);
                }
            }
        }
    };

    const GroupHeading = (p) => {
        return (
            <div
                className={clsx([classes.groupStyles, `grp-${thisId}`])}
                onClick={() => onHeaderClick(p.id)}
            >
                <components.GroupHeading {...p} />
            </div>
        );
    };

    const onFilter = (option, rawInput: string) => {
        if (props.groupCollapse) {
            if (rawInput?.length > 0) {
                setShouldCollapse(false);
                const tgt = document.querySelector(`div.grp-${thisId}`);
                const node = tgt?.nextElementSibling;

                if (node) {
                    for (let i = 0; i < node.children.length; i++) {
                        const element = node.children[i];
                        const cls = element.classList;
                        if (cls.contains(classes.collapsed)) {
                            element.classList.remove(classes.collapsed);
                        }
                    }
                }
            } else {
                setShouldCollapse(true);
            }
        }

        if (rawInput === "") {
            return true;
        }

        let parent: string = option.data.parent ?? option.data;
        if (!parent) return false;

        const compare: string = rawInput.toLowerCase();

        if (parent && parent["value"]) {
            parent = parent["value"];
        }

        if (
            (parent?.toLowerCase && parent?.toLowerCase().includes(compare)) ||
            (option?.label?.toLowerCase &&
                option.label.toLowerCase().includes(compare)) ||
            (option?.data?.name?.toLowerCase &&
                option.data?.name?.toLowerCase().includes(compare))
        ) {
            return true;
        }

        return false;
    };

    const multiValueOption = (p) => {
        const tooltipTitle =
            p.selectProps.getOptionTooltip(p.data) ??
            p.selectProps.getOptionLabel(p.data) ??
            "";

        return (
            <Tooltip title={tooltipTitle}>
                <div className={classes.optionContainer}>
                    <components.MultiValueLabel {...p} />
                </div>
            </Tooltip>
        );
    };

    const singleValueOption = (p) => {
        const tooltipTitle =
            p.selectProps.getOptionTooltip(p.data) ??
            p.selectProps.getOptionLabel(p.data) ??
            "";

        return (
            <div>
                <Tooltip title={tooltipTitle}>
                    <div>
                        <components.SingleValue {...p} />
                    </div>
                </Tooltip>
            </div>
        );
    };

    const Option = (p) => {
        if (props.optionComponent) {
            return props.optionComponent(p) as ReactElement;
        }

        const { children } = p;

        return (
            <div className={shouldCollapse ? classes.collapsed : ""}>
                <Tooltip title="">
                    <div>
                        <components.Option {...p}>{children}</components.Option>
                    </div>
                </Tooltip>
            </div>
        );
    };

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected
                ? theme.palette.primary[500]
                : props.alternateItemColor ?? theme.palette.text.primary,
            backgroundColor:
                state.isSelected || state.isFocused
                    ? theme.palette.primary[50]
                    : theme.palette.white.main,
            "&:hover": !state.isDisabled
                ? {
                      backgroundColor: theme.palette.primary[50],
                      color: theme.palette.neutral[600],
                  }
                : {},
            opacity: state.isDisabled ? "50%" : "100%",
            padding: appTheme.spacing(1),
            borderRadius: "2px",
        }),
        groupHeading: (provided, state) => ({
            // ...provided,
            color: hexToRGB(appTheme.palette.black.main, 0.7),
            font: appTheme.typography.fontFamily,
            fontSize: "13px",
            fontWeight: appTheme.typography.fontWeightBold,
            cursor: "pointer",
        }),
        menu: (provided, state) => ({
            ...provided,
            // width: "300px",
            zIndex: 10,
            padding: "8px",
        }),
        menuPortal: (provided) => ({
            ...provided,
            zIndex: 9999,
        }),
        control: () => ({
            display: "flex",
            cursor: "pointer",
        }),
        singleValue: (provided, state) => {
            if (props.colorField) {
                const fontWeight = "bold";
                const fontSize = "12px";
                const lineHeight = "15px";
                const paddingLeft = "6px";
                const paddingRight = "6px";
                const paddingTop = "3px";
                const paddingBottom = "3px";
                const backgroundColor = hexToRGB(
                    state.data[props.colorField ?? ""] ??
                        appTheme.palette.info.main,
                    0.18,
                );
                const color =
                    state.data[props.colorField ?? ""] ??
                    appTheme.palette.info.main;
                return {
                    ...provided,
                    backgroundColor,
                    color,
                    fontWeight,
                    paddingTop,
                    paddingRight,
                    paddingBottom,
                    paddingLeft,
                    fontSize,
                    lineHeight,
                };
            }
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = "opacity 300ms";
            return { ...provided, opacity, transition };
        },
        dropdownIndicator: (provided, state) => ({
            ...provided,
            color: appTheme.palette.text.primary,
        }),
        indicatorContainer: (provided, state) => ({
            ...provided,
            padding: "6px",
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            alignItems: props.alignControls ?? "center",
            height: "fit-content",
            position: "sticky",
            top: 0,
        }),
        multiValue: (styles, { data }) => ({
            ...styles,
            backgroundColor: hexToRGB(
                data[props.colorField ?? ""] ?? appTheme.palette.info.main,
                0.18,
            ),
            // border: `2px dotted ${appTheme.palette.secondary.main}`,
            fontSize: "13px",
            color: data[props.colorField ?? ""] ?? appTheme.palette.info.main,
        }),
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: data[props.colorField ?? ""] ?? appTheme.palette.info.main,
            fontWeight: "bold",
        }),
        container: (provided, state) => ({
            ...provided,
            fontSize: "13px",
            minWidth: props.minWidth ?? "100px",
            width: props.width ?? "100%",
            maxWidth: props.fullWidth ? "100%" : "336px",
            border: props.isDisabled ? "none" : "1px solid",
            borderColor: state.isFocused
                ? hexToRGB(theme.palette.primary.main, 0.7)
                : appTheme.palette.lightgrayBorder.main,
            borderRadius: appTheme.shape.borderRadius,
            minHeight: props.containerHeight ?? "32px",
            height: props.containerHeight ?? "32px",
            maxHeight: props.maxContainerHeight,
            overflowY: !isNullableType(props.maxContainerHeight)
                ? "auto"
                : "none",
            backgroundColor: props.isDisabled
                ? hexToRGB(appTheme.palette.black.main, 0.1)
                : appTheme.palette.white.main,
        }),
    };
    _.assign(customStyles, props.customStyle ?? {});

    const onChange = (value: any, actionMeta: ActionMeta<any>) => {
        if (
            value?.[props.valueField] === "-1" ||
            actionMeta.action === "clear"
        ) {
            setCurrentValue(null);
            value = undefined;
        } else {
            setCurrentValue(value);
        }
        if (value) {
            if (isArrayType(value)) {
                if (props.onMultiChange) {
                    props.onMultiChange(value);
                }
            } else {
                if (props.onChange) {
                    props.onChange(value);
                }
            }
        } else {
            if (props.onChange) {
                props.onChange(undefined);
            }
            if (props.onMultiChange) {
                props.onMultiChange(undefined);
            }
        }
    };

    React.useEffect(() => {
        setThisId(uuidv4());
    }, []);

    React.useEffect(() => {
        if (props.enableNoSelection) {
            if (props.options.length > 0) {
                const curops = [
                    {
                        [props.valueField]: "-1",
                        [props.labelField]: "No Selection",
                    },
                ];
                setOptions(curops.concat(props.options));
            } else {
                const curops = [
                    {
                        [props.valueField]: "-1",
                        [props.labelField]: "No Selection",
                    },
                ];
                setOptions(curops);
            }
        } else {
            setOptions(props.options);
        }
    }, [
        props.options,
        props.enableNoSelection,
        props.valueField,
        props.labelField,
    ]);

    React.useEffect(() => {
        if (props.defaultValue === undefined || props.defaultValue === null) {
            setCurrentValue(null);
        } else {
            if (isArrayType(props.defaultValue)) {
                setCurrentValue(props.defaultValue);
            } else {
                setCurrentValue(props.defaultValue);
            }
        }
    }, [props.defaultValue, props.options, props.valueField]);

    const DropDownIndicator = (inheritedProps) => {
        return (
            <components.DropdownIndicator {...inheritedProps}>
                {props.dropdownIndicator ? (
                    props.dropdownIndicator
                ) : (
                    <DownChevronSvg />
                )}
            </components.DropdownIndicator>
        );
    };

    const Menu = (menuProps) => {
        return (
            <>
                <components.Menu {...menuProps}>
                    {menuProps.children}
                    {props.dropdownMenuEndChildren &&
                        React.Children.count(props.dropdownMenuEndChildren) >
                            0 && (
                            <>
                                <Divider />
                                {props.dropdownMenuEndChildren}
                            </>
                        )}
                </components.Menu>
            </>
        );
    };

    function getCustomComponents() {
        return {
            IndicatorSeparator: IndicatorSeparator,
            GroupHeading: GroupHeading,
            Option: Option,
            MultiValueLabel: multiValueOption,
            SingleValue: singleValueOption,
            DropdownIndicator: DropDownIndicator,
            Menu,
        };
    }

    return (
        <>
            {props.inputLabel && (
                <AcxInputLabel
                    shrink={props.shrink}
                    focused={labelFocus}
                    id={props.id + "-label"}
                    htmlFor={props.id}
                    className={clsx({
                        [classes.required]: props.required && !currentValue,
                        [classes.textLabel]: true,
                    })}
                    infoText={props.infoText}
                >
                    {props.inputLabel}
                </AcxInputLabel>
            )}

            <Select
                isLoading={props.isLoading}
                isClearable={props.isClearable}
                maxMenuHeight={props.maxMenuHeightPx}
                width={"100%"}
                aria-labelledby={props.id + "-label"}
                onChange={onChange}
                onBlur={() => {
                    setLabelFocus(false);
                    props.onBlur?.();
                }}
                isSearchable={props.isSearchable}
                onFocus={() => setLabelFocus(true)}
                placeholder={props.placeholder ?? "Search..."}
                defaultValue={props.noValue ? null : currentValue}
                value={props.noValue ? null : currentValue}
                styles={{ ...customStyles }}
                options={options}
                menuShouldScrollIntoView
                isMulti={props.isMulti}
                menuPortalTarget={
                    props.menuPortalTarget === undefined
                        ? document.body
                        : props.menuPortalTarget
                } // null is significant
                menuPlacement={props.menuPlacement ?? "auto"}
                inputId={props.id}
                className={classes.field}
                getOptionValue={(option) => option[props.valueField!]}
                getOptionLabel={(option) => option[props.labelField!]}
                // gets values for custom tooltips for individual inputs in the multi select input
                getOptionTooltip={(option) =>
                    props.tooltips?.[option[props.labelField!]]
                }
                components={getCustomComponents()}
                isDisabled={props.isDisabled}
                filterOption={props.customFilter ?? onFilter ?? undefined}
                // theme={(theme) => ({
                //     ...theme,
                //     borderRadius: appTheme.shape.borderRadius,
                //     spacing: {
                //         baseUnit: 3,
                //         controlHeight: 32,
                //         menuGutter: appTheme.spacing(1),
                //     },
                //     colors: {
                //         ...theme.colors,
                //         primary25: hextToRGB(
                //             appTheme.palette.secondary.main,
                //             0.25,
                //         ),
                //         primary: appTheme.palette.secondary.main,
                //     },
                // })}
            />

            {props.helperText !== undefined && (
                <AcxInputLabel
                    id={"AcxMainText-BottomLabel"}
                    className={clsx({
                        [classes.helperText]: true,
                        [classes.helperTextError]: props.error,
                    })}
                    showAllErrors={!!props.showAllErrors}
                >
                    {props.helperText}
                </AcxInputLabel>
            )}
        </>
    );
}

AcxSelect.defaultProps = {
    isMulti: false,
    valueField: "value",
    labelField: "label",
    enableNoSelection: false,
    editIcon: false,
    isClearable: false,
    isLoading: false,
    useBodyAsPortal: true,
    shrink: false,
    required: false,
};

export default React.memo(AcxSelect);
