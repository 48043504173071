import IMotfAutomationDefinition from "models/IMotfAutomationDefinition";
import BaseService from "./BaseService";

interface IntegerRange {
    minimum: number;
    maximum: number;
}

export type UpsertMotfDefinition = Pick<
    IMotfAutomationDefinition,
    "name" | "quotaType" | "mediaTypes" | "isActive"
> & {
    hierarchyIds: string[];
    quotaVolume: number;
    audioInteractionDurationMinutes: IntegerRange;
    chatMessageCountRange: IntegerRange;
};

export class MotfAutomationService extends BaseService {
    protected urlBase: string = "api/AutomaticEvaluationRules";

    public async getMotfAutomationDefinitions(
        includeInactive: boolean = false,
    ): Promise<IMotfAutomationDefinition[]> {
        const params = new URLSearchParams({
            includeInactive: includeInactive.toString(),
        });

        return this.get(params, "List");
    }

    public async getMotfAutomationDefinition(
        id: string,
        organizationId: string,
    ): Promise<UpsertMotfDefinition> {
        const params = new URLSearchParams({
            id,
            organizationId,
        });

        return this.get(params, "Get");
    }

    public async createMotfAutomationDefinition(
        organizationId: string,
        definition: UpsertMotfDefinition,
    ): Promise<{ id: string }> {
        const params = new URLSearchParams();

        const body = {
            ...definition,
            lastExecuted: null,
            organizationId,
        };

        return this.post(JSON.stringify(body), params, "Create");
    }

    public async updateMotfAutomationDefinition(
        id: string,
        organizationId: string,
        definition: UpsertMotfDefinition,
    ): Promise<void> {
        const params = new URLSearchParams({
            id,
        });

        const body = {
            ...definition,
            organizationId,
        };

        return this.patch(JSON.stringify(body), params, "Update");
    }

    public async deactivateMotfAutomationDefinition(
        id: string,
        organizationId: string,
    ) {
        const params = new URLSearchParams();

        const body = {
            ruleId: id,
            organizationId,
        };

        return this.patch(JSON.stringify(body), params, "Deactivate");
    }

    public async deleteMotfAutomationDefinition(
        id: string,
        organizationId: string,
    ) {
        const params = new URLSearchParams();

        const body = {
            ruleId: id,
            organizationId,
        };

        // This should be substituted with a proper hard delete operation
        // once its supported
        return this.patch(JSON.stringify(body), params, "Deactivate");
    }
}
