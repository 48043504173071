import { GridValueFormatterParams } from "@mui/x-data-grid-pro";
import React from "react";
import { ThemeColors } from "Theme/AppTheme";
import AcxProgress from "./AcxProgress";

export const ProgressFormatter = (obj: {
    numeratorField: string;
    denominatorField: string;
}) => {
    const fn = (params: GridValueFormatterParams) => {
        const fields = obj;
        const numerator = parseInt(params.row[fields.numeratorField]);
        const denominator = parseInt(params.row[fields.denominatorField]);

        const percentComplete =
            denominator !== 0 && !Number.isNaN(denominator)
                ? (numerator / denominator) * 100
                : 0;

        const colorMap = {
            51: ThemeColors.green,
            50: ThemeColors.yellow,
            25: ThemeColors.red,
        };

        let backgroundColor;
        if (percentComplete > 50) {
            backgroundColor = colorMap[51];
        } else if (percentComplete < 50 && percentComplete > 25) {
            backgroundColor = colorMap[50];
        } else {
            backgroundColor = colorMap[25];
        }

        const label = `${numerator} out of ${denominator}.`;

        const res = (
            <AcxProgress
                key={params.id}
                percentComplete={percentComplete.toString()}
                backgroundColor={backgroundColor}
                showInnerLabel={true}
                bottomLabel={label}
            />
        );

        return res;
    };

    return fn;
};
