import { Grid, Paper, Typography } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import { observer } from "mobx-react";
import React from "react";
import useStyles from "Styles/Styles";
import ModelDashboardStore, {
    LOAD_BY_RBC,
    LOAD_SENTIMENT,
    LOAD_TOPIC,
    LOAD_TRENDING,
} from "../Stores/ModelDashboardStore";

const styles = () =>
    createStyles({
        paper: {
            padding: "1rem",
            width: "100%",
            height: "100%",
        },
        chartTitle: {
            height: "2rem",
        },
        unavailable: {
            margin: "1rem 0",
        },
        chartWrapper: {
            height: "calc(100% - 2rem)",
        },
    });

type Props = {
    chart: React.ReactChild;
    data: any[];
    store: ModelDashboardStore;
    chartName?: string;
};

const BuildChartContainer: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = observer(
    ({ chart, data, store, chartName }) => {
        const classes = useStyles(styles);

        const validData = (d) => {
            return (d?.flatMap((datum) => datum).length ?? 0) > 1;
        };

        const isLoading = (chartName?: string) => {
            switch (chartName) {
                case "Eddy Effect by Sentiment":
                    return store.getTaskLoading(LOAD_SENTIMENT);
                case "Eddy Effect by RBC":
                    return store.getTaskLoading(LOAD_BY_RBC);
                case "Trending Eddy Effect":
                    return store.getTaskLoading(LOAD_TRENDING);
                case "Eddy Effect by Topic":
                    return store.getTaskLoading(LOAD_TOPIC);
                default:
                    return false;
            }
        };

        return (
            <Paper className={classes.paper}>
                <Typography variant="h2" className={classes.chartTitle}>
                    {chartName}
                </Typography>

                {validData(data) ? (
                    <Grid item className={classes.chartWrapper}>
                        {isLoading(chartName) ? (
                            <AcxLoadingIndicator
                                alternate="PuffLoader"
                                size={50}
                            />
                        ) : (
                            chart
                        )}
                    </Grid>
                ) : (
                    <Typography variant="h2" className={classes.unavailable}>
                        Unavailable
                    </Typography>
                )}
            </Paper>
        );
    },
);

export default BuildChartContainer;
