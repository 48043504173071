import { Grid, IconButton, Theme, Tooltip, Typography } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import AddBoxIcon from "@mui/icons-material/AddBox";
import _ from "lodash";
import { AgentModel } from "models/AgentModel";
import { FieldFormatter, MetadataSpec } from "models/MetadataSpec";
// import moment from "moment";
import React from "react";
import useStyles from "Styles/Styles";
import ParseFormat from "../../../../../utils/ParseFormat";
import { OptionType } from "../../../../UI/Select/BaseSelectComponents/AcxSelect";
import AcxSelectSingle from "../../../../UI/Select/BaseSelectComponents/AcxSelectSingle";
import { MetaFieldLabels } from "../Types/TemplateModel";
import {
    AgentFormatOption,
    AgentFormatterOption,
    DateFormatOption,
} from "./FormatOptions";

export const defaultAgentFormatter = (arg: AgentModel) =>
    `${arg.firstName} ${arg.lastName}`;

export function buildAgentFormatterFromType(
    agentFormat: AgentFormatterOption | undefined,
) {
    let formatFunc: ((agent: AgentModel) => string) | undefined;
    if (!agentFormat) {
        return defaultAgentFormatter;
    }
    switch (agentFormat.value) {
        case "LastName FirstInitial":
            formatFunc = (agent: AgentModel) => {
                return `${agent.lastName} ${agent.firstInitial}`;
            };
            break;
        case "LastName, FirstName":
            formatFunc = (agent: AgentModel) => {
                return `${agent.lastName}, ${agent.firstName}`;
            };
            break;
        case "LastName,FirstName":
            formatFunc = (agent: AgentModel) => {
                return `${agent.lastName},${agent.firstName}`;
            };
            break;
        case "FirstName LastName":
            formatFunc = (agent: AgentModel) => {
                return `${agent.firstName} ${agent.lastName}`;
            };

            break;
        case "AgentCode":
            formatFunc = (agent: AgentModel) => {
                return `${agent.agentCode}`;
            };
            break;
        case "AgentEmail":
            formatFunc = (agent: AgentModel) => {
                return `${agent.agentEmail}`;
            };
            break;
        default:
            formatFunc = defaultAgentFormatter;
    }
    return formatFunc;
}

const styles = (theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        select: {},
    });

export interface IColumnMap {
    columnOptions: OptionType[];
    defaultValue?: MetadataSpec.FieldType;
    onChange: (field: MetadataSpec.FieldType) => void;
    rowLabel: MetaFieldLabels;
    // columnType: "string" | "date" | "agent"
}

export default function ColumnMap(props: IColumnMap) {
    const classes = useStyles(styles);
    // const theme = useTheme();
    const [defValue, setDefValue] = React.useState<OptionType>();
    // const [output, setOutput] = React.useState<string>("");
    const [format, setFormat] = React.useState<string>(
        props.defaultValue?.format ?? "",
    );
    const [overrideFmt, setOverrideFmt] = React.useState(false);
    // const [columnLabel, setColumnLabel] = React.useState("");

    const handleDate = (col: OptionType | undefined, fmt: any = "") => {
        const label = col?.label;
        if (fmt === "") {
            fmt = ParseFormat(label);
        }
        // const m = moment(label, fmt);
        // setOutput(m.format("LL"));
        setFormat(fmt.toString());
        doChange(col, fmt.toString());
    };

    const handleAgent = (col: OptionType | undefined, fmt: any = "") => {
        const label = col?.label;

        if (fmt === "") {
            const comma = label.indexOf(",");
            if (comma > -1) {
                const arr: any[] = label.split(", ");
                if (arr.length === 1) {
                    fmt = "LastName,FirstName";
                } else {
                    fmt = "LastName, FirstName";
                }
            } else if (label.indexOf("@") > 0) {
                fmt = "AgentEmail";
            } else if (label.indexOf(" ") >= 0) {
                fmt = "FirstName LastName";
            } else {
                fmt = "AgentCode";
            }
        }
        // setOutput(label);
        setFormat(fmt);
        doChange(col, fmt);
    };

    React.useEffect(() => {
        // debugger;
        const ops = getOptions();
        const fld = ops.find(
            (i) => i.value === props.defaultValue?.columnLocation?.toString(),
        );
        setDefValue(fld);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.defaultValue, props.columnOptions]);

    const doChange = (
        col: OptionType | undefined,
        fmt: string | null = null,
        fmtFunc?: (arg: any) => string,
    ) => {
        const val = col?.value;
        if (!fmt) {
            fmt = format;
        }

        const res: MetadataSpec.FieldType & FieldFormatter<any> = {
            fieldName: props.rowLabel,
            columnLocation: parseInt(val),
            dataType: getDataType(props.rowLabel).typ,
            format: fmt ? fmt : "unknown",
            formatFunc: fmtFunc,
        };
        props.onChange(res);
    };

    const handleColumnChange = (newColumn: OptionType) => {
        setDefValue(newColumn);

        if (props.rowLabel === "Interaction Date") {
            handleDate(newColumn);
        } else if (props.rowLabel === "Agent Name") {
            handleAgent(newColumn);
        } else {
            doChange(newColumn);
        }
    };
    const getCurrentFormat = () => {
        if (props.rowLabel === "Agent Name" && _.isObject(format)) {
            return format?.["value"];
        } else if (_.isString(format) && format === "unknown") {
            return "";
        }
        return format;
    };

    const getOptions = () => {
        if (props.columnOptions) {
            return props.columnOptions;
        }
        const arr = new Array<OptionType>();
        arr.push({ value: "none", label: "None" });
        for (let i = 0; i < 10; i++) {
            arr.push({
                value: i.toString(),
                label: "Column " + i.toString(),
            } as OptionType);
        }
        return arr;
    };

    const getDataType = (
        value: string,
    ): { value: string; label: string; typ: string } => {
        const arr = new Array<{ value: string; label: string; typ: string }>();
        arr.push({ value: "agent", label: "Agent Name", typ: "string" });
        arr.push({
            value: "interactionDate",
            label: "Interaction Date",
            typ: "Date",
        });
        arr.push({
            value: "callDirection",
            label: "Call Direction",
            typ: "string",
        });
        arr.push({ value: "filename", label: "Filename", typ: "string" });
        arr.push({ value: "meta1", label: "Meta 1", typ: "string" });
        arr.push({ value: "meta2", label: "Meta 2", typ: "string" });
        arr.push({ value: "meta3", label: "Meta 3", typ: "string" });
        arr.push({ value: "meta4", label: "Meta 4", typ: "string" });
        arr.push({ value: "meta5", label: "Meta 5", typ: "string" });
        const res = arr.find((f) => f.label === value);
        if (!res) {
            return { value: "unknown", label: "unknown", typ: "unknown" };
        } else {
            return res;
        }
    };

    const handleFormatOverride = (
        item: string | AgentFormatterOption | undefined,
    ) => {
        if (props.rowLabel === "Interaction Date") {
            handleDate(defValue, item);
        } else if (props.rowLabel === "Agent Name") {
            handleAgent(defValue, item);
        } else {
            // setOutput(columnLabel);
        }
    };

    const renderFormatOptions = () => {
        if (props.rowLabel === "Call Direction") {
            return "";
        } else if (props.rowLabel === "Interaction Date") {
            return (
                <DateFormatOption
                    format={format}
                    onChange={handleFormatOverride}
                />
            );
        } else if (props.rowLabel === "Agent Name") {
            return (
                <AgentFormatOption
                    format={format}
                    onChange={handleFormatOverride}
                />
            );
            // return <AcxMainTextField value={format} id="column-agent-fmt" />;
        } else if (props.rowLabel === "Filename") {
            return "";
        } else {
            return "";
        }
    };

    //show format override component
    function onFmtOverrideClick() {
        setOverrideFmt(!overrideFmt);
    }
    return (
        <>
            <Grid container className={classes.select} spacing={1}>
                <Grid
                    xs={2}
                    item
                    container
                    zeroMinWidth
                    alignItems="flex-start"
                >
                    <Typography
                        variant="h3"
                        style={{ marginTop: "8px" }}
                        display="block"
                    >
                        {props.rowLabel}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <AcxSelectSingle
                        defaultValue={defValue}
                        valueField="value"
                        labelField="label"
                        options={getOptions()}
                        onChange={handleColumnChange}
                        id={"col-select-" + Math.random().toString(10)}
                    />
                </Grid>
                <Grid item xs={1} container alignItems="flex-start">
                    {(props.rowLabel === "Interaction Date" ||
                        props.rowLabel === "Agent Name") && (
                        <Tooltip title="Override Format">
                            <IconButton
                                size="small"
                                onClick={onFmtOverrideClick}
                            >
                                <AddBoxIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </Grid>
                <Grid item xs={2} container zeroMinWidth alignItems="center">
                    <Typography variant="h3">{getCurrentFormat()}</Typography>
                </Grid>
                <Grid item xs={4}>
                    {overrideFmt && renderFormatOptions()}
                </Grid>
            </Grid>
        </>
    );
}
