import { BaseService } from "./BaseService";
import { SearchResult } from "components/NLP/ClassifierBuilder/Search/ClassifierBuilderSearchController";

export class SearchService extends BaseService {
    protected urlBase = "api/Search";

    public searchByQuery(
        orgId: string,
        queryString: string,
        limit?: number,
        startDate?: string,
        endDate?: string,
    ) {
        const apiPath = "QueryString";

        const params = new URLSearchParams({
            orgId,
            queryString,
            limit: limit?.toString() || "",
        });

        if (startDate && endDate) {
            params.set("startDate", startDate);
            params.set("endDate", endDate);
        }

        return this.get<SearchResult[]>(params, apiPath);
    }
}

export default SearchService;
