import AcxDataGridStore from "components/UI/AcxDataGrid/AcxDataGridStore";
import { debounce } from "lodash";
import {
    action,
    computed,
    makeObservable,
    observable,
    reaction,
    runInAction,
} from "mobx";
import moment from "moment";
import { User } from "oidc-client";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";
import {
    AnalystEvalHistory,
    EvaluationLeaderStats,
} from "../../../../models/EvalMetricModels";
import Evaluation from "../../../../models/Evaluation";
import { IOrganization } from "../../../../models/Organization";
import { TimePeriod } from "../../../../models/TimePeriod";
import { EvaluationMetricsService } from "../../../../services/EvaluationMetricsService";
import { EvaluationService } from "../../../../services/EvaluationService";
import { OrganizationService } from "../../../../services/OrganizationService";
import {
    InteractionQuote,
    QuoteOfThePeriodService,
    QuotePeriod,
} from "../../../../services/QuoteOfThePeriodService";
import { AuthStore } from "../../../../stores/AuthStore";
import { BaseStore } from "../../../../stores/BaseStore";
import { DatePickerComponentStore } from "../../../../stores/ComponentStores/DatePickerComponentStore";
import { OrgSelectorComponentStore } from "../../../../stores/ComponentStores/OrgSelectorComponentStore";
import { TableComponentStore } from "../../../../stores/ComponentStores/TableComponentStore";
import { serializeToUtc } from "../../../../utils/DateTimeUtils";
import { delay } from "../../../../utils/helpers";
import { EvalStore } from "../../../Evaluation/Stores/EvalStore";

export const loadAnalystEvaluationsTask = "Load Analyst Evaluations";
export const loadLiveEvaluationHierarchy = "Load Live Evaluation Hierarchy";
export const FindNextEvalTask = "Finding Next Evaluation";
export const LoadAnalystHistoryTask = "Load Analyst History";
export const LoadQuoteTask = "Load Quote";
export const LoadAnalystMonthlyProgress = "Load Analyst Monthly Progress";
export const LoadAnalystMetrics = "Load Analyst Metrics";
export const AnalystLeadersTask = "Analyst Leaders";
export const REFRESH = "Reload Data";
export const LoadHiersExceptLive = "Load Hierarchies except live";
export interface EvaluationWithQueueName extends Evaluation {
    hierarchy: string[];
    agentName: string;
}
@AcxStore
export class AnalystDashboardStore extends BaseStore {
    private readonly evaluationMetricsService: EvaluationMetricsService =
        new EvaluationMetricsService();
    private readonly quoteOfThePeriodService: QuoteOfThePeriodService =
        new QuoteOfThePeriodService();
    private readonly evaluationService: EvaluationService =
        new EvaluationService();
    private readonly evalStore: EvalStore;

    readonly authStore: AuthStore;
    readonly orgSelectorStore: OrgSelectorComponentStore;
    readonly datePickerStore = new DatePickerComponentStore();
    readonly tableStore = new TableComponentStore<EvaluationWithQueueName>(
        (evalObject, searchString) => {
            if (!searchString) {
                return true;
            }

            searchString = searchString.toLowerCase();

            return !!(
                evalObject?.hierarchy
                    ?.join(",")
                    ?.toLowerCase()
                    .includes(searchString) ||
                evalObject.evaluationQbId
                    ?.toString()
                    .toLowerCase()
                    .includes(searchString) ||
                evalObject.interaction?.contentLocation
                    ?.toLowerCase()
                    .includes(searchString)
            );
        },
    );

    dgStore: AcxDataGridStore;

    @observable pendingEval?: Evaluation;
    @observable quoteAudioBuffer?: AudioBuffer;
    @observable quoteOfTheWeek?: InteractionQuote;
    @observable analystHistory?: AnalystEvalHistory;
    @observable analystMonthlyProgress?: AnalystEvalHistory;
    @observable evaluationLeaderStats?: EvaluationLeaderStats;
    @observable showNextEvalDialog: boolean = false;
    @observable evalProgress: number = 0;
    @observable noAdditionalEvalMsg: string | null = null;

    @observable private analystUser: User | null = null;
    @observable private startRefresh: number = 0;

    @observable liveEvalModalOpen: boolean = false;
    @observable hierarchyStartEvalOpen: boolean = false;

    @observable allUserHierarchies: { label: string; id: string }[] = [];

    constructor(public rootStore: IRootStore) {
        super("AnalystDashboardStore");

        makeObservable(this);

        this.orgSelectorStore = new OrgSelectorComponentStore(
            this.preOrgChangeCleanup,
        );

        this.dgStore = new AcxDataGridStore(
            "myEvaluationsTable",
            "MyEvaluations",
        );

        this.authStore = rootStore.getStore(AuthStore);
        this.evalStore = rootStore.getStore(EvalStore);

        this.authStore
            .getUserObject()
            .then((value) => {
                runInAction(() => {
                    this.analystUser = value;
                });
            })
            .catch((reason) => {
                console.error(`AuthStore::getUserObject failed: ${reason}`);
            });

        reaction(
            (r) => ({
                analystEvals: this.analystMonthlyProgress,
                leaderEvals: this.evaluationLeaderStats,
            }),
            async (arg) => {
                if (arg.analystEvals && arg.leaderEvals) {
                    const leaderProgress =
                        (arg.leaderEvals.completedEvaluations ?? 0) > 0
                            ? arg.leaderEvals.completedEvaluations
                            : 1;

                    const totalProgress =
                        (100 * (arg.analystEvals.completedEvaluations ?? 0)) /
                        (leaderProgress ?? 1);

                    for (let i = 0; i <= totalProgress; i += 5) {
                        runInAction(() => {
                            this.evalProgress = i;
                        });
                        await delay(48);
                    }
                }
            },
            { delay: 1000, fireImmediately: true },
        );

        reaction(
            (r) => ({
                selectedOrg: this.orgSelectorStore.orgId,
                analyst: this.analystUser,
                refresh: this.startRefresh,
            }),
            this.debounceEffect((arg) => {
                const organizationId = arg.selectedOrg;
                if (!organizationId || !arg.analyst) {
                    return;
                }

                this.setupAsyncTask(AnalystLeadersTask, () =>
                    this.getAnalystLeaderStats(),
                );

                this.setupAsyncTask(LoadAnalystMetrics, () =>
                    this.getAnalystMetrics(arg.analyst?.profile.sub),
                );
                this.setupAsyncTask(LoadHiersExceptLive, () =>
                    this.getUserHierarchiesExceptLive(
                        organizationId,
                        arg.analyst?.profile.sub,
                    ),
                );
            }, 500),
            { delay: 0, fireImmediately: true },
        );

        reaction(
            () => {
                return {
                    refresh: this.startRefresh,
                    selectedOrg: this.orgSelectorStore.orgId,
                    analyst: this.analystUser,
                    beginDate: this.datePickerStore.beginDate,
                    endDate: this.datePickerStore.endDate,
                };
            },
            this.debounceEffect(async (params) => {
                const organizationId = params.selectedOrg;
                if (organizationId && params.analyst) {
                    this.setupAsyncTask(LoadAnalystHistoryTask, () =>
                        this.getAnalystHistory(
                            params.analyst?.profile.sub,
                            TimePeriod.SpecificRange,
                            this.datePickerStore.beginDate,
                            this.datePickerStore.endDate,
                        ),
                    );

                    this.setupAsyncTask(loadAnalystEvaluationsTask, () =>
                        this.getAnalystEvaluations(
                            params.analyst?.profile.sub,
                            params.beginDate,
                            params.endDate,
                        ),
                    );
                }
            }, 1500),
            { delay: 0, fireImmediately: true },
        );

        reaction(
            () => this.getTaskLoading(loadAnalystEvaluationsTask),
            (loading) => {
                if (loading) {
                    runInAction(() => {
                        this.dgStore.setLoading(true);
                    });
                }
            },
        );
    }

    @action
    refreshTable = () => {
        this.setupAsyncTask(REFRESH, async () => {
            this.dgStore.setLoading(true);

            await this.getAnalystEvaluations(
                this.analystUser?.profile?.sub,
                this.datePickerStore.beginDate,
                this.datePickerStore.endDate,
            );

            this.dgStore.setLoading(false);
        });
    };

    getUserHierarchiesExceptLive = async (orgId, userId) => {
        if (!orgId) return;
        const os = new OrganizationService();

        const res = await os.getFlattenedServiceHierarchyForUserExceptLive(
            orgId,
            userId,
        );
        this.allUserHierarchies = res;
    };

    @action
    onHierarchyStartEval = () => {
        this.hierarchyStartEvalOpen = !this.hierarchyStartEvalOpen;
    };

    @action
    async startHierEval(orgMemberId: string) {
        return this.setupAsyncTask("Start Hier Eval", () =>
            this.startNextEval(orgMemberId),
        );
    }

    @action
    onLiveEvalClose = () => {
        this.liveEvalModalOpen = !this.liveEvalModalOpen;
    };

    @action
    async startLiveEval(orgMemberId: string) {
        return this.setupAsyncTask("Generate Live Eval", () =>
            this.generateLiveEvaluation(orgMemberId),
        );
    }

    @action
    generateLiveEvaluation = async (orgMemberId: string) => {
        const organizationId = this.orgSelectorStore.orgId;
        this.noAdditionalEvalMsg = null;

        if (!organizationId) {
            throw new Error(`Failed to load Organization Id for User`);
        }

        if (!this.analystUser?.profile.sub) {
            throw new Error(`Failed to load Analyst User profile`);
        }

        const analystId = this.analystUser.profile.sub;

        await delay(1500);

        const nextEval: { id: string | null } =
            await this.evaluationService.generateLiveEval(
                analystId,
                orgMemberId,
            );

        if (!nextEval.id) {
            this.noAdditionalEvalMsg =
                "No additional sampled interactions available for evaluation";
            return;
        }

        runInAction(() => {
            this.onLiveEvalClose();
        });

        return nextEval.id;
    };

    @action
    private preOrgChangeCleanup = (nextOrg?: IOrganization) => {
        this.quoteOfTheWeek = undefined;
        this.quoteAudioBuffer = undefined;

        this.pendingEval = undefined;
        this.evaluationLeaderStats = undefined;
        this.analystMonthlyProgress = undefined;
        this.analystHistory = undefined;

        this.evalProgress = 0;
        this.tableStore.setItems([]);
    };

    @computed get evaluations() {
        return this.tableStore.items;
    }

    @computed get filteredEvaluations() {
        return this.tableStore.filteredItems;
    }

    @computed get selectedEvaluations() {
        return this.tableStore.selectedItems;
    }

    @action
    public refresh = debounce(
        () => {
            this.startRefresh = (this.startRefresh + 1) % 1000;
        },
        6000,
        { leading: true },
    );

    @action
    public onQuoteAudioBufferReady = (arg: {
        id: string;
        index: number;
        audioBuffer: AudioBuffer;
    }) => {
        this.quoteAudioBuffer = arg.audioBuffer;
    };

    @action
    private async getAnalystLeaderStats() {
        const res = await this.evaluationMetricsService.getAnalystLeaderStats(
            TimePeriod.Month,
            "AnalystDashboard/LeaderStats",
        );

        runInAction(() => {
            const evaluationLeaderStat = res?.[0];
            this.evaluationLeaderStats = evaluationLeaderStat;
        });
    }

    @action
    private async getQuoteOfTheWeek(orgId: string) {
        try {
            const res = await this.quoteOfThePeriodService.getQuote(
                orgId,
                QuotePeriod.Week,
                "AnalystDashboard/QOTW",
            );
            runInAction(() => {
                this.quoteOfTheWeek = res;
            });
        } catch (e) {
            this.quoteOfTheWeek = undefined;
        }
    }

    @action
    private async getAnalystMetrics(analystId: string | undefined) {
        const task1 = this.setupAsyncTask(LoadAnalystHistoryTask, () =>
            this.getAnalystHistory(
                analystId,
                TimePeriod.SpecificRange,
                this.datePickerStore.beginDate,
                this.datePickerStore.endDate,
            ),
        );

        const task2 = this.setupAsyncTask(LoadAnalystMonthlyProgress, () =>
            this.getAnalystMonthlyProgress(analystId),
        );

        await Promise.all([task1, task2]);
    }

    @action
    private async getAnalystHistory(
        analystId: string | undefined,
        timePeriod: TimePeriod | undefined,
        beginDate: moment.Moment,
        endDate: moment.Moment,
    ) {
        if (!analystId) {
            throw new Error(`Failed to load Analyst User profile`);
        }

        const res = await this.evaluationMetricsService.getAnalystHistory(
            analystId,
            timePeriod,
            beginDate,
            endDate,
            "AnalystDashboard/AnalystHistory",
        );
        runInAction(() => {
            this.analystHistory = res;
        });
    }

    @action
    private async getAnalystMonthlyProgress(analystId: string | undefined) {
        if (!analystId) {
            throw new Error(`Failed to load Analyst User profile`);
        }

        const res = await this.evaluationMetricsService.getAnalystHistory(
            analystId,
            TimePeriod.Month,
            undefined,
            undefined,
            "AnalystDashboard/MonthlyProgress",
        );
        runInAction(() => {
            this.analystMonthlyProgress = res;
        });
    }

    @action
    closeNextEvalDialog = () => {
        this.showNextEvalDialog = false;
        this.clearLastTaskError();
    };

    @action
    private async startNextEvalInternal(hierarchyId: string) {
        const organizationId = this.orgSelectorStore.orgId;

        this.noAdditionalEvalMsg = null;
        if (!organizationId) {
            throw new Error(`Failed to load Organization Id for User`);
        }

        if (!this.analystUser?.profile.sub) {
            throw new Error(`Failed to load Analyst User profile`);
        }
        this.showNextEvalDialog = true;
        const analystId = this.analystUser.profile.sub;

        await delay(1500);

        const nextEvalId: { id: string | null } =
            await this.evaluationService.startNextEval(analystId, hierarchyId);
        if (!nextEvalId.id) {
            this.noAdditionalEvalMsg =
                "No additional sampled interactions available for evaluation";
            return;
        }

        runInAction(() => {
            this.showNextEvalDialog = false;
        });
        return nextEvalId.id;
    }

    @action
    async startNextEval(hierarchyId: string) {
        return this.setupAsyncTask(FindNextEvalTask, () =>
            this.startNextEvalInternal(hierarchyId),
        );
    }

    @action
    private getAnalystEvaluations = async (
        analystId: string | null | undefined,
        beginDate: moment.Moment,
        endDate: moment.Moment,
    ) => {
        if (!analystId) {
            throw new Error(`Failed to load Analyst User profile`);
        }

        const evals = await this.evaluationService.getEvalsForAnalyst(
            analystId,
            serializeToUtc(beginDate)!,
            serializeToUtc(endDate)!,
            false,
            "AnalystDashboard/AnalystEvaluations",
        );

        const evalsWithQueueName = evals.map(
            (value) =>
                ({
                    ...value.evaluation,
                    hierarchy: value.serviceHierarchy?.filter((v) =>
                        Boolean(v),
                    ),
                    agentName: value.agentName,
                } as EvaluationWithQueueName),
        );

        runInAction(() => {
            this.dgStore.reset();
            this.dgStore.rows = evalsWithQueueName;
            this.dgStore.checkboxSelection = false;
            this.dgStore.setLoading(false);
        });
    };
}
