import Agentv2 from "models/Agentv2";
import { AgentModel } from "../models/AgentModel";
import BaseService from "./BaseService";

interface AgentHierarchies {
    id: string;
    levels: string[];
}
export interface IAgentsWithHierarchies extends Agentv2 {
    hierarchies: AgentHierarchies[];
}
export default class AgentService extends BaseService {
    protected urlBase: string = "api/Agent";

    async searchAgents(organizationId, searchValue): Promise<Agentv2[]> {
        const agents = await this.post<Agentv2[]>(
            JSON.stringify({ organizationId }),
            new URLSearchParams({
                searchValue: searchValue,
            }),
            "SearchAgents",
        );

        return agents;
    }

    async getAgent(agentId: string): Promise<Agentv2> {
        const agent = await this.get<Agentv2>(
            new URLSearchParams(),
            `${agentId}`,
        );

        return agent;
    }

    async getAgents(orgId): Promise<Agentv2[]> {
        const agents = await this.post(
            JSON.stringify({ organizationId: orgId }),
            new URLSearchParams(),
            "ListAgents",
        );

        return agents;
    }

    async getAgentViewModels(organizationId) {
        const agents = await this.post<AgentModel[]>(
            JSON.stringify({ organizationId }),
            new URLSearchParams({}),
            "ListAgentViewModels",
        );
        return agents;
    }

    async createAgent(
        firstName,
        lastName,
        agentEmail,
        agentCode,
        organizationId,
        osms,
        startDate,
        managerId
    ) {
        const agent = new Agentv2();
        agent.firstName = firstName;
        agent.lastName = lastName;
        agent.agentEmail = agentEmail;
        agent.organizationId = organizationId;
        agent.agentCode = agentCode;
        agent.startDate = startDate;
        if (managerId) {
            agent.managerId = managerId;
        }

        const requestBody = { agent, osms };

        const newAgent = await this.post(
            JSON.stringify(requestBody),
            new URLSearchParams(),
            "PostAgent",
        );
        return newAgent;
    }

    async updateAgent(
        OrgId,
        Id,
        firstName,
        lastName,
        agentEmail,
        agentCode,
        isActive,
        osms,
        userId,
        startDate,
        managerId
    ) {
        let searchParams = {
            OrgId,
            Id,
            firstName,
            lastName,
            agentEmail,
            isActive,
            agentCode,
            userId,
        }

        if (startDate) {
            searchParams["startDate"] = startDate;
        }

        if (managerId) {
            searchParams["managerId"] = managerId;
        }

        const params = new URLSearchParams(searchParams);
        osms.forEach((osm) => {
            params.append("osms", osm);
        });
        const req = await this.put(params, "PutAgent", null);

        return req;
    }

    async getAgentsByManagerId(organizationId, managerId): Promise<Agentv2[]> {
        return this.post(JSON.stringify({organizationId}), new URLSearchParams({}), `AgentsByManagerId/${managerId}`);

    }

    updateAgentManager(organizationId, Id, managerId) {
        const params = new URLSearchParams({ Id, managerId});
        this.patch(JSON.stringify({ organizationId }), params, "UpdateAgentManager");
    }  

    async mergeAgent(targetAgent: Agentv2, sourceAgent: Agentv2) {
        const bodyObject = {
            sourceAgent: sourceAgent,
            targetAgent: targetAgent,
        };

        const req = await this.post(
            JSON.stringify(bodyObject),
            new URLSearchParams(),
            "MergeAgents",
        );

        return req;
    }

    async getAgentWithHierarchies(organizationId): Promise<IAgentsWithHierarchies[]> {
        const agentWithHierarchies = await this.post<IAgentsWithHierarchies[]>(
            JSON.stringify({ organizationId }),
            new URLSearchParams({}),
            "AgentWithHierarchies",
        );
        return agentWithHierarchies;
    }
    async getInActiveAgentWithHierarchies(
        organizationId,
    ): Promise<IAgentsWithHierarchies[]> {
        const inActiveAgentWithHierarchies = await this.post<
            IAgentsWithHierarchies[]
        >(
            JSON.stringify({ organizationId }),
            new URLSearchParams({}),
            "InActiveAgentWithHierarchies",
        );
        return inActiveAgentWithHierarchies;
    }
    async deleteAgents(agentIds) {
        const req = await this.delete(
            new URLSearchParams(),
            "DeleteAgents",
            JSON.stringify(agentIds),
        );
        return req;
    }
}
