import { AuthStore } from "stores/AuthStore";
import { useStore } from "utils/useStore";
import OpsStore from "./OpsStore";
import { Observer, observer } from "mobx-react";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import Gutters from "Layouts/Responsive/Gutters";
import AcxDataGrid from "components/UI/AcxDataGrid/AcxDataGrid";
import React, { useEffect } from "react";
import IColDef from "../../../../UI/AcxDataGrid/IColDef";
import { dateTimeColumnType } from "../../../../UI/AcxDataGrid/ColumnTypes/DateColTypes";
import AcxButton from "../../../../UI/AcxButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import CloudUpload from "@mui/icons-material/CloudUpload";
import AcxDateRangeInput from "../../../../UI/Calendar/DateRange/AcxDateRangeInput";
import { Moment } from "moment";
import moment from "moment/moment";

const Ops = observer(() => {
    const store = useStore(OpsStore);
    const authStore = useStore(AuthStore);
    const messageStore = useStore(MessageStore);

    const refreshData = async () => {
        try {
            await store.updateInteractions();
        } catch (e: unknown) {
            // quick fix to clean the error message since its locked behind ultra and platform tools
            let err = (e as Error).message;
            messageStore.logError(err.slice(81));
        }
    };

    const uploadInteraction = async () => {
        try {
            await store.uploadInteractions();
        } catch {
            messageStore.logError(
                "There was an error when uploading interaction(s)",
            );
        }
    };

    useEffect(() => {
        if (
            !store.interactions ||
            !store.interactions.find(
                (interaction) =>
                    interaction.orgId ===
                    authStore.orgStore.selectedOrganization?.id,
            )
        ) {
            store.updateInteractions();
        }
    }, [authStore.orgStore.selectedOrganization?.id, store]);

    const columns: IColDef[] = [
        {
            headerName: "File Name",
            field: "fileName",
            type: "string",
        },
        {
            headerName: "Media Type",
            field: "mediaType",
            type: "string",
        },
        {
            headerName: "Status",
            field: "status",
            type: "string",
        },
        {
            headerName: "Created On",
            field: "fileCreatedOn",
            ...dateTimeColumnType,
        },
        {
            headerName: "Modified On",
            field: "fileModifiedOn",
            ...dateTimeColumnType,
        },
        {
            headerName: "Amd ID",
            field: "amdId",
            type: "string",
        },
        {
            headerName: "Directory GUID",
            field: "directoryGuid",
            type: "string",
        },
    ];

    const controls = [
        <AcxButton
            leftRightSpacing={0}
            tooltip="Refresh Data"
            fullWidth={false}
            color="secondary"
            onClick={refreshData}
            customRootStyles={{ height: "32px" }}
            loading={store.opsDgStore.isLoading}
            buttonDisabled={store.opsDgStore.isLoading}
        >
            <RefreshIcon />
        </AcxButton>,
        <AcxButton
            leftRightSpacing={0}
            tooltip="Upload All Interaction Types"
            fullWidth={false}
            color="secondary"
            onClick={uploadInteraction}
            customRootStyles={{ height: "32px" }}
            loading={store.opsDgStore.isLoading}
            buttonDisabled={store.opsDgStore.isLoading}
        >
            <CloudUpload />
        </AcxButton>,
        <Observer>
            {() => (
                <AcxDateRangeInput
                    labelText={"Select Created On Date"}
                    defaultStartDate={moment().startOf("date")}
                    defaultEndDate={store.datePickerStore.endDate}
                    onSelect={(start: Moment, end: Moment) => {
                        store.datePickerStore.setBeginDate(start);
                        store.datePickerStore.setEndDate(end);
                        refreshData();
                    }}
                />
            )}
        </Observer>,
    ];

    useEffect(() => {
        store.opsDgStore.setColumns(columns);
        store.opsDgStore.controls = controls;
    });

    return (
        <Gutters>{<AcxDataGrid dataGridStore={store.opsDgStore} />}</Gutters>
    );
});

export default Ops;
