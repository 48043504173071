import {
    action,
    computed,
    makeObservable,
    observable,
    reaction,
    runInAction,
} from "mobx";
import { AcxAgentService } from "services/AcxAgentService";
import { BaseStore } from "stores/BaseStore";
import type { IRootStore } from "stores/RootStore";
import { AcxStore } from "stores/RootStore";
import { EvalStore } from "components/Evaluation/Stores/EvalStore";
import ConversationsStore from "components/Conversations/Stores/ConversationsStore";
import { AuthStore } from "stores/AuthStore";
import { initialSuggestionsSet, pickRandomSuggestions } from "./Suggestions";
import { Thread } from "./Models/Thread";
import type { ThreadSuggestion } from "./Models/ThreadSuggestion";
import LocalStorage from "stores/LocalStorage";
import { ResponseTime } from "./Models/ResponseTime";
import { ThreadMessage } from "./Models/ThreadMessage";
import { InteractionType } from "models/InteractionType";
import { GenerateCoachingNotesData } from "./Models/GenerateCoachingNotesData";
import Evaluation from "models/Evaluation";
import Conversation from "models/Conversation";
import { AgentState } from "./Models/AgentState";
import { MessageAuthor } from "./Models/MessageAuthor";
import { ThreadMessageGroup } from "./Models/ThreadMessageGroup";

interface StreamingSubmitInputOptions {
    manualInput?: string;
    suggestion?: boolean;
}

@AcxStore
export default class AgentChatStore extends BaseStore {
    readonly acxAgentService: AcxAgentService = new AcxAgentService();
    private abortController?: AbortController;

    @observable thread: Thread;

    @observable currentInput: string = "";

    // @observable waitingForAgentResponse: boolean = false;
    @observable state: AgentState = AgentState.Idle;

    // We use a interval here so that the observable changes each
    // second causing UI to re-render as needed for the different
    // message / loading states.
    @observable private responseTimeInSeconds?: number;
    private responseTimerHandle?: NodeJS.Timeout;

    readonly localStorage: LocalForage;

    constructor(public rootStore: IRootStore) {
        super("AgentChatStore");
        makeObservable(this);

        this.resetChat();

        this.localStorage = this.rootStore
            .getStore(LocalStorage)
            .getLocalStore();

        reaction(
            () => this.state,
            (state) => {
                if (state === AgentState.Waiting) {
                    if (this.responseTimerHandle) return;
                    this.responseTimeInSeconds = 0;
                    this.responseTimerHandle = setInterval(
                        () =>
                            runInAction(() => {
                                if (this.responseTimeInSeconds === undefined)
                                    this.responseTimeInSeconds = 0;
                                this.responseTimeInSeconds += 1;
                            }),
                        1000,
                    );
                } else {
                    clearInterval(this.responseTimerHandle);
                    this.responseTimerHandle = undefined;
                    this.responseTimeInSeconds = undefined;
                }
            },
        );

        const evalStore = this.rootStore.getStore(EvalStore);
        const convosStore = this.rootStore.getStore(ConversationsStore);
        const authStore = this.rootStore.getStore(AuthStore);

        const sessionSuggestions = pickRandomSuggestions(initialSuggestionsSet);

        reaction(
            () => this.rootStore.activeLocation,
            () => {
                const recentAgentMessageGroup =
                    this.thread.recentAgentMessageGroup!;
                if (recentAgentMessageGroup.transientMessage)
                    recentAgentMessageGroup.clearSuggestions();
                recentAgentMessageGroup.clearTransientMessage();

                if (!this.thread.hasUserMessaged)
                    recentAgentMessageGroup.setSuggestions(sessionSuggestions);
            },
            { fireImmediately: true },
        );

        reaction(
            () => ({
                evaluation: evalStore.currentEval,
                conversation: convosStore.selectedConversation,
            }),
            (args) => {
                if (!authStore.canUserEdit("Agent Coaching")) return;

                const recentAgentMessageGroup =
                    this.thread.recentAgentMessageGroup!;
                if (recentAgentMessageGroup.transientMessage)
                    recentAgentMessageGroup.clearSuggestions();
                recentAgentMessageGroup.clearTransientMessage();

                if (!args.evaluation && !args.conversation) return;

                let data: GenerateCoachingNotesData | undefined = undefined;

                if (!!args.evaluation) {
                    data = this.extractGenerateCoachingNotesDataFromEvaluation(
                        args.evaluation,
                    );
                } else if (!!args.conversation) {
                    data =
                        this.extractGenerateCoachingNotesDataFromConversation(
                            args.conversation,
                        );
                }

                if (!data) return;

                recentAgentMessageGroup.setTransientMessage(
                    "It looks like you're viewing a conversation, here are some things I can help with:",
                );
                recentAgentMessageGroup.setSuggestions([
                    {
                        content: "Coach the agent on this conversation",
                        action: async (store, suggestion) => {
                            const message = store.thread
                                .addUserMessageGroup()
                                .addMessage(suggestion.content);
                            await this.acxAgentService.persistMessage(
                                this.thread.id,
                                {
                                    author: MessageAuthor.User,
                                    content: message.content,
                                    additionalInformation: {
                                        EvaluationId: data!.evaluationId,
                                    },
                                },
                            );
                            this.generateCoachingNotesAction(data!);
                        },
                    },
                ]);
            },
            { fireImmediately: true },
        );
    }

    @computed get responseTime() {
        const timeInSeconds = this.responseTimeInSeconds;
        if (timeInSeconds === undefined) return undefined;

        if (timeInSeconds > 30) return ResponseTime.Long;
        else if (timeInSeconds > 15) return ResponseTime.Medium;
        else return ResponseTime.Short;
    }

    @action
    resetChat() {
        this.thread = new Thread();

        const group = this.thread.addAgentMessageGroup();
        group.addMessage(
            "Hello! I'm Ava, your Authenticx AI assistant. I'm here to help you discover meaningful insights. How can I assist you today?",
        );
        group.setSuggestions(pickRandomSuggestions(initialSuggestionsSet));
    }

    @action
    onChangeHandler(input: string) {
        this.currentInput = input;
    }

    @action public async handleSuggestion(suggestion: ThreadSuggestion) {
        this.abortController = new AbortController();
        if (!this.thread.id) {
            const threadResponse =
                await this.acxAgentService.streamingStartChatThread(
                    this.abortController.signal,
                );
            this.thread.id = threadResponse.id;
        }

        const message =
            this.thread.recentAgentMessageGroup!.commitTransientMessage();
        if (message) {
            await this.acxAgentService.persistMessage(this.thread.id, {
                author: MessageAuthor.Agent,
                content: message.content,
            });
        }
        suggestion.action(this, suggestion);
    }

    public abort() {
        if (!this.abortController) return;
        this.abortController.abort();
    }

    @action
    async submitInput(manualInput?: string) {
        const input = manualInput ?? this.currentInput;
        this.currentInput = "";
        if (!input) return;

        const recentAgentMessages = this.thread.recentAgentMessageGroup!;
        recentAgentMessages.commitTransientMessage();

        this.thread.addUserMessageGroup().addMessage(input);

        this.state = AgentState.Waiting;

        if (!this.thread.id) {
            const threadResponse =
                await this.acxAgentService.streamingStartChatThread();
            this.thread.id = threadResponse.id;
        }

        const response = await this.acxAgentService.submitMessage({
            threadId: this.thread.id,
            body: input,
        });

        this.state = AgentState.Idle;

        const messageGroup = this.thread.addAgentMessageGroup();
        messageGroup.addMessage(response.text);
        if (response.suggestions) {
            messageGroup.addServerSuggestions(response.suggestions);
        }
        // messageGroup.setActions(response.actions)
    }

    @action
    async streamingSubmitInput(options?: StreamingSubmitInputOptions) {
        const input = options?.manualInput ?? this.currentInput;
        this.currentInput = "";
        if (!input) return;

        const recentAgentMessages = this.thread.recentAgentMessageGroup!;
        recentAgentMessages.commitTransientMessage();

        this.thread.addUserMessageGroup().addMessage(input);

        this.state = AgentState.Waiting;
        this.abortController = new AbortController();

        let newMessageGroup: ThreadMessageGroup | undefined = undefined;
        try {
            if (!this.thread.id) {
                const threadResponse =
                    await this.acxAgentService.streamingStartChatThread(
                        this.abortController.signal,
                    );
                this.thread.id = threadResponse.id;
            }

            let additionalInformation: Record<string, unknown> | undefined =
                undefined;

            if (options?.suggestion) {
                additionalInformation ??= {};
                additionalInformation["Suggestion"] = true;
            }

            const stream = this.acxAgentService.streamingSubmitMessage(
                {
                    threadId: this.thread.id,
                    body: input,
                },
                additionalInformation,
                this.abortController.signal,
            );

            let isFirst = true;
            let message: ThreadMessage | undefined = undefined;
            for await (const chunk of stream) {
                if (isFirst) {
                    this.state = AgentState.Streaming;
                    newMessageGroup = this.thread.addAgentMessageGroup();
                    message = newMessageGroup.addMessage("");
                }

                if (!message) continue;
                isFirst = false;

                if (!chunk.actions && !chunk.suggestions) {
                    if (!chunk.text) continue;
                    message.appendContent(chunk.text);
                    continue;
                }

                // if (chunk.actions) {
                //     this.thread.recentAgentMessageGroup?.setActions(chunk.actions);
                // }

                if (chunk.suggestions) {
                    this.thread.recentAgentMessageGroup?.addServerSuggestions(
                        chunk.suggestions,
                    );
                }
            }
        } catch (error) {
            if (error instanceof DOMException && error.name === "AbortError") {
                // only show this message if we were still waiting when cancelled, otherwise
                // there will be some streamed content which will be cut off.
                if (this.state === AgentState.Waiting) {
                    this.thread
                        .addAgentMessageGroup()
                        .addMessage("The request was cancelled.");
                }
            } else {
                if (!newMessageGroup)
                    newMessageGroup = this.thread.addAgentMessageGroup();
                newMessageGroup.addMessage(
                    "I was unable to complete your request. Please try again.",
                );
            }
        } finally {
            this.state = AgentState.Idle;
        }
    }

    private async generateCoachingNotesAction(data: GenerateCoachingNotesData) {
        this.state = AgentState.Waiting;

        let message: ThreadMessage | undefined = undefined;
        let additionalInformation: Record<string, unknown> | undefined =
            undefined;

        try {
            const result = await this.acxAgentService.generateCoachingNotes(
                data.agentId,
                data.evaluationId,
                this.abortController?.signal,
            );

            if (result.successful) {
                const notes = {
                    doesWellNotes: result.doesWellNotes,
                    improvementNotes: result.improvementNotes,
                };
                await this.localStorage.setItem(
                    "generatedCoachingNotes",
                    notes,
                );

                window
                    .open(
                        `${window.location.origin}/app/agentcoaching/evaluationreview?agentId=${data.agentId}&agentName=${data.agentName}&evaluationId=${data.evaluationId}&evaluationNumber=${data.evaluationNumber}`,
                        "_blank",
                    )
                    ?.focus();

                message = this.thread
                    .addAgentMessageGroup()
                    .addMessage(
                        "I've opened a new tab with the generated coaching notes.",
                    );
                additionalInformation = { CoachingNotes: notes };
            } else {
                message = this.thread
                    .addAgentMessageGroup()
                    .addMessage(
                        result.message.replaceAll(
                            "helpdesk@authenticx.com",
                            "[helpdesk@authenticx.com](mailto:helpdesk@authenticx.com)",
                        ),
                    );
            }
        } catch (error: unknown) {
            message = this.thread
                .addAgentMessageGroup()
                .addMessage(
                    "An error occured while generating coaching notes. Please try again.",
                );
        } finally {
            if (message) {
                this.acxAgentService.persistMessage(this.thread.id, {
                    author: MessageAuthor.Agent,
                    content: message.content,
                    additionalInformation,
                });
            }
            this.state = AgentState.Idle;
        }
    }

    private extractGenerateCoachingNotesDataFromEvaluation(
        evaluation: Evaluation,
    ): GenerateCoachingNotesData | undefined {
        const interaction = evaluation.interaction;
        if (
            !interaction ||
            !interaction.agentId ||
            !interaction.agentName ||
            !evaluation.evaluationQbId ||
            !!evaluation.agentCoachingId ||
            interaction.interactionType === InteractionType.Live
        )
            return;
        return {
            agentId: interaction.agentId,
            agentName: interaction.agentName,
            evaluationId: evaluation.id,
            evaluationNumber: evaluation.evaluationQbId,
        };
    }

    private extractGenerateCoachingNotesDataFromConversation(
        conversation: Conversation,
    ) {
        if (!!conversation.evaluationAgentCoachingId) return;
        return {
            agentId: conversation.agentId,
            agentName: `${conversation.agentFirstName} ${conversation.agentLastName}`,
            evaluationId: conversation.evaluationId,
            evaluationNumber: conversation.evaluationNumber,
        };
    }
}
