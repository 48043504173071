import ReactDOMServer from "react-dom/server";
import { ReportFormatOptions } from "../components/ReportsRv2/Models/ReportFormatOptions";
import { ReportFieldDataTypes } from "../models/Reporting/ReportDataField";
import {
    ReportDatum,
    SignalReportDatum,
} from "../models/Reporting/ReportDatum";
import { DateAggregation, FieldUse } from "../models/Reporting/ReportField";
import { ReportModel, VizType } from "../models/Reporting/ReportModel";
import theme from "../Theme/AppTheme";
import { getCurrencyCharacter, getDelimiterCharacter } from "./helpers";
import { isNumeric } from "./StringUtils";
import moment from "moment";

const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

const defaultColorScheme = [
    theme.palette.green.main,
    theme.palette.blue.main,
    theme.palette.orange.main,
    theme.palette.red.main,
];
const singleSeriesMainBarColors = [
    theme.palette.secondary.main,
    theme.palette.primary.main,
];

const AnnotationRoleColumn = {
    type: "string",
    role: "annotation",
};

const StyleRoleAnnotation = { role: "style" };

// maps series name to y value
type DataPoint = Map<string, number | null>;

// Used for reports V1
export function reportDataToGoogleFormat2(
    data: ReportDatum[],
    report: ReportModel | undefined,
    isWidget?: boolean,
    singleColor?: boolean,
    swapColors?: boolean,
    notTransparent?: boolean,
    isClassifier?: boolean,
    showPercent?: boolean,
) {
    const seriesNamesSet = new Set<string>();
    const xValuesSet = new Set<string>();
    const dataPoints = new Map<string, DataPoint>();

    const formattingOptions = ReportFormatOptions.fromJson(report?.vizOptions);

    const xField = report?.reportFields.find(
        (value) => value.fieldUse === FieldUse.X,
    );
    // const yField = report?.reportFields.find(value => value.fieldUse === FieldUse.Y);
    const xFieldType = xField?.reportDataField?.datatype;
    const xDataAgg = xField?.dateAggregation;

    for (const datum of data) {
        var thisSeriesName = datum.grouping || "";
        var thisXValue = datum.x;
        seriesNamesSet.add(thisSeriesName);
        xValuesSet.add(thisXValue);
        const thisDataPoint =
            dataPoints.get(datum.x) ?? new Map<string, number>();
        thisDataPoint.set(thisSeriesName, datum.y);
        dataPoints.set(datum.x, thisDataPoint);
    }

    // We need to add null values to the result array so that they can be interpolated by the chart
    const seriesNames = Array.from(seriesNamesSet);
    const xValues = Array.from(xValuesSet);

    // header row looks like [Xlabel, S1Name, style, annotation, tooltip, S2Name, style, annotation, tooltip, S3Name, style, annotation, tooltip]

    const columnHeaders: any[] = [];
    for (let seriesName of seriesNames) {
        columnHeaders.push(seriesName);
        if (
            report?.vizType !== VizType.Histogram &&
            (seriesNames.length > 1 || report?.vizType !== VizType.Line)
        ) {
            columnHeaders.push(StyleRoleAnnotation);
            if (!isWidget) {
                columnHeaders.push(AnnotationRoleColumn);
            }
        }
    }

    const headerRow = ["x"].concat(columnHeaders) as any[];
    const result: (string | number | null | any)[][] = [];

    let colorScale: string[] =
        seriesNames.length > 1 ? defaultColorScheme : singleSeriesMainBarColors;

    if (swapColors) {
        colorScale = [theme.palette.blue.main, theme.palette.green.main];
    }
    if (isClassifier) {
        colorScale = [theme.palette.blue.main];
    }
    let colorIndex = 0;

    // const horizontalTicks:Array<{v:number|string,f:string}> = [];
    for (let xValue of xValues) {
        if (seriesNames.length > 1) {
            colorIndex = 0;
        }

        // result rows look like [XValue, S1Value, S2Value, S3Value]
        // const xVar = report?.vizType === VizType.Pie ? ({v: xValue?.toString(),
        //     f: xValue?.toString()}) : ({v:0, f: xValue});

        const resultRow: (string | number | null | any)[] = [
            report?.vizType === VizType.Pie ? xValue.toString() : xValue,
        ];

        // horizontalTicks.push({v:xVar.v, f: xVar.f})

        for (const seriesName of seriesNames) {
            const yValue = dataPoints.get(xValue)?.get(seriesName) ?? null;
            resultRow.push(yValue);
            if (
                report?.vizType !== VizType.Histogram &&
                (seriesNames.length > 1 || report?.vizType !== VizType.Line)
            ) {
                resultRow.push("__STYLE__");
                if (!isWidget) {
                    if (yValue && Math.abs(yValue) > 0.025) {
                        resultRow.push(
                            !Number.isNaN(yValue)
                                ? showPercent ||
                                  formattingOptions.enablePercentFormat
                                    ? `${yValue}%`
                                    : `${yValue}`
                                : Number.isInteger(yValue)
                                ? yValue
                                : yValue.toFixed(2),
                        );
                    } else {
                        resultRow.push("");
                    }
                }
            }
        }
        result.push(resultRow);
    }

    if (
        xDataAgg === DateAggregation.MonthYear &&
        xFieldType === ReportFieldDataTypes.Date
    ) {
        result.sort((a, b) => {
            let x = a[0],
                y = b[0];
            if (a[0].f !== undefined) {
                x = a[0].f;
                y = b[0].f;
            }

            if (typeof x === "string" && typeof y === "string") {
                let aDate = (x as string)?.replace(/\s\(.*/, "");
                let bDate = (y as string)?.replace(/\s\(.*/, "");
                const aMonth = aDate?.slice(0, aDate.length - 4).trim();
                const bMont = bDate?.slice(0, bDate.length - 4).trim();

                const aYear = parseInt(aDate?.slice(aDate.length - 4).trim());
                const bYear = parseInt(bDate?.slice(bDate.length - 4).trim());

                const aDt = new Date(
                    aYear,
                    months.findIndex((value) => value === aMonth),
                );

                const bDt = new Date(
                    bYear,
                    months.findIndex((value) => value === bMont),
                );

                return aDt > bDt ? 1 : -1;
            } else {
                return x > y ? 1 : -1;
            }
        });
    } else if (xFieldType === ReportFieldDataTypes.Date) {
        result.sort((a, b) => {
            let x = a[0],
                y = b[0];
            if (a[0].f !== undefined) {
                x = a[0].f;
                y = b[0].f;
            }
            if (typeof x === "number" && typeof y === "number") {
                return x - y;
            } else {
                return (x?.toString()?.toLowerCase() ?? "") >
                    (y?.toString()?.toLowerCase() ?? "")
                    ? 1
                    : -1;
            }
        });
    } else {
        result.sort((a, b) => {
            let x = a[1],
                y = b[1];
            if (a[1]?.f !== undefined) {
                x = a[1].f;
                y = b[1].f;
            }
            if (typeof x === "number" && typeof y === "number") {
                return -1 * (x - y);
            } else {
                return (x?.toString()?.toLowerCase() ?? "") >
                    (y?.toString()?.toLowerCase() ?? "")
                    ? 1
                    : -1;
            }
        });
    }

    const styleColumns = headerRow.findAllIndexes(
        (value, key) => value.role === "style",
    );
    for (let resultElement of result) {
        for (let styleColumn of styleColumns) {
            (resultElement as any[])[styleColumn] = `stroke-color: ${
                singleColor
                    ? theme.palette.secondary.main
                    : colorScale[colorIndex % colorScale.length]
            }; stroke-opacity: 1.0; stroke-width: 2; fill-color: ${
                singleColor
                    ? theme.palette.secondary.main
                    : colorScale[colorIndex % colorScale.length]
            }; fill-opacity: ${notTransparent ? "1" : "0.22"}`;
            if (seriesNames.length > 1) {
                colorIndex = (colorIndex + 1) % seriesNames.length;
            } else {
                colorIndex++;
            }
        }
    }

    result.insertAt(0, headerRow);
    // console.log(result);
    return result;
}

// Used for reports V2
export function reportDataToGoogleFormatV2(
    data: ReportDatum[],
    report: ReportModel | undefined,
    isWidget?: boolean,
    singleColor?: boolean,
    swapColors?: boolean,
    notTransparent?: boolean,
    isClassifier?: boolean,
    showPercent?: boolean,
) {
    const seriesNamesSet = new Set<string>();
    const xValuesSet = new Set<string>();
    const dataPoints = new Map<string, DataPoint>();

    const formattingOptions = ReportFormatOptions.fromJson(report?.vizOptions);

    const xField = report?.reportFields.find(
        (value) => value.fieldUse === FieldUse.X,
    );
    // const yField = report?.reportFields.find(value => value.fieldUse === FieldUse.Y);
    const xFieldType = xField?.reportDataField?.datatype;

    for (const datum of data) {
        var thisSeriesName = datum.grouping || "";
        var thisXValue = datum.x;
        seriesNamesSet.add(thisSeriesName);
        xValuesSet.add(thisXValue);

        const thisDataPoint =
            dataPoints.get(datum.x) ?? new Map<string, number>();
        thisDataPoint.set(thisSeriesName, datum.y);
        dataPoints.set(datum.x, thisDataPoint);
    }

    // We need to add null values to the result array so that they can be interpolated by the chart
    const seriesNames = Array.from(seriesNamesSet);
    const xValues = Array.from(xValuesSet);

    // header row looks like [Xlabel, S1Name, style, annotation, tooltip, S2Name, style, annotation, tooltip, S3Name, style, annotation, tooltip]

    const columnHeaders: any[] = [];
    const showDataLabels: boolean =
        !isWidget && !report?.formattingValues?.hideDataLabels;

    for (let seriesName of seriesNames) {
        columnHeaders.push(seriesName);
        if (
            report?.vizType !== VizType.Histogram &&
            (seriesNames.length > 1 || report?.vizType !== VizType.Line)
        ) {
            columnHeaders.push(StyleRoleAnnotation);
            if (showDataLabels) {
                columnHeaders.push(AnnotationRoleColumn);
            }
        }
    }

    const headerRow = ["x"].concat(columnHeaders) as any[];
    const result: (string | number | null | any)[][] = [];

    let colorScale: string[] =
        seriesNames.length > 1 ? defaultColorScheme : singleSeriesMainBarColors;

    if (report?.vizOptions?.colorScheme?.length) {
        colorScale = report?.vizOptions?.colorScheme;
    }

    if (swapColors) {
        colorScale = [theme.palette.blue.main, theme.palette.green.main];
    }
    if (isClassifier) {
        colorScale = [theme.palette.blue.main];
    }
    let colorIndex = 0;

    // const horizontalTicks:Array<{v:number|string,f:string}> = [];

    for (let xValue of xValues) {
        if (seriesNames.length > 1) {
            colorIndex = 0;
        }

        // result rows look like [XValue, S1Value, S2Value, S3Value]
        // const xVar = report?.vizType === VizType.Pie ? ({v: xValue?.toString(),
        //     f: xValue?.toString()}) : ({v:0, f: xValue});

        const xString = function (xValue: any) {
            let returnValue = xValue;
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            xValue !== null ? (returnValue = xValue.toString()) : "";
            return returnValue;
        };
        const resultRow: (string | number | null | any)[] = [
            report?.vizType === VizType.Pie
                ? xString(xValue)
                : isNumeric(xString(xValue))
                ? formatChartValue(Number(xValue), report)
                : xValue,
        ];

        for (const seriesName of seriesNames) {
            const yValue = dataPoints.get(xValue)?.get(seriesName) ?? null;
            resultRow.push(yValue);
            if (
                report?.vizType !== VizType.Histogram &&
                (seriesNames.length > 1 || report?.vizType !== VizType.Line)
            ) {
                resultRow.push("__STYLE__");
                if (showDataLabels) {
                    if (yValue && Math.abs(yValue) > 0.025) {
                        let formattedValue = formatChartValue(yValue, report);
                        // Handle percent
                        if (
                            showPercent ||
                            formattingOptions.enablePercentFormat
                        ) {
                            formattedValue = `${formattedValue}%`;
                        }
                        resultRow.push(formattedValue);
                    } else {
                        resultRow.push("");
                    }
                }
            }
        }
        result.push(resultRow);
    }

    if (xFieldType !== ReportFieldDataTypes.Date) {
        result.sort((a, b) => {
            let x = a[1],
                y = b[1];
            if (a[1]?.f !== undefined) {
                x = a[1].f;
                y = b[1].f;
            }
            if (typeof x === "number" && typeof y === "number") {
                return -1 * (x - y);
            } else {
                return (x?.toString()?.toLowerCase() ?? "") >
                    (y?.toString()?.toLowerCase() ?? "")
                    ? 1
                    : -1;
            }
        });
    }

    const styleColumns = headerRow.findAllIndexes(
        (value, key) => value.role === "style",
    );

    for (let resultElement of result) {
        for (let styleColumn of styleColumns) {
            (resultElement as any[])[styleColumn] = `stroke-color: ${
                singleColor
                    ? theme.palette.secondary.main
                    : colorScale[colorIndex] ??
                      colorScale[colorIndex % colorScale.length]
            }; stroke-opacity: 1.0; stroke-width: 2; fill-color: ${
                singleColor
                    ? theme.palette.secondary.main
                    : colorScale[colorIndex] ??
                      colorScale[colorIndex % colorScale.length]
            }; fill-opacity: ${notTransparent ? "1" : "0.22"}`;
            if (seriesNames.length > 1) {
                colorIndex = (colorIndex + 1) % seriesNames.length;
            } else {
                colorIndex++;
            }
        }
    }

    result.insertAt(0, headerRow);
    // console.log(result);
    return result;
}

function adjustDecimal(unformatedNumber, report?: ReportModel): string {
    let returnValue = unformatedNumber.toString();
    if (Math.floor(unformatedNumber) !== unformatedNumber) {
        if (report?.formattingValues?.decimal) {
            returnValue = unformatedNumber.toFixed(
                report.formattingValues.decimal,
            );
        } else {
            returnValue = unformatedNumber.toFixed(2);
        }
    }
    return returnValue;
}

export function formatChartValue(
    inputValue: number,
    report: ReportModel | undefined,
) {
    let formattedValue = `${inputValue}`;
    if (report && !report?.formattingValues) {
        if (!Number.isNaN(inputValue)) {
            // Handle decimals
            formattedValue = adjustDecimal(inputValue, report);
            return formattedValue;
        } else {
            return formattedValue;
        }
    }
    if (!Number.isNaN(inputValue)) {
        // Handle decimals
        formattedValue = adjustDecimal(inputValue, report);
        // Handle delimiter
        if (report && report.formattingValues?.delimiter !== null) {
            let [valueWithoutDecimals, decimalValues] =
                formattedValue.split(".");

            let tempArray = valueWithoutDecimals.split("");
            for (let i = tempArray.length - 4; i >= 0; i -= 3) {
                tempArray[i] =
                    tempArray[i] +
                    getDelimiterCharacter(report.formattingValues?.delimiter);
            }
            formattedValue =
                tempArray.join("") + (decimalValues ? "." + decimalValues : "");
        }
        // Handle currency
        if (report && report?.formattingValues?.currency !== null) {
            formattedValue =
                getCurrencyCharacter(report?.formattingValues?.currency) +
                formattedValue;
        }
    }
    return formattedValue;
}

export interface IGoogleFormatOptions {
    data: SignalReportDatum[];
    showPercent?: boolean;
    isComparePrev?: boolean;
    tooltipBuilder?: (
        x: any,
        y: any,
        seriesName: any,
    ) =>
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
        | string;
}

interface GoogleFormat {
    data: any[][];
    seriesNames: Set<string>;
}

// Used for signals
export function signalDataToGoogleFormat(
    args: IGoogleFormatOptions,
): GoogleFormat {
    const seriesNamesSet = new Set<string>();
    const xValuesSet = new Set<string>();
    const dataPoints = new Map<string, DataPoint>();

    for (const datum of args.data) {
        const thisSeriesName = datum.grouping || "";
        const thisXValue = datum.x;
        seriesNamesSet.add(thisSeriesName);
        xValuesSet.add(thisXValue);

        const thisDataPoint =
            dataPoints.get(datum.x) ?? new Map<string, number>();
        thisDataPoint.set(thisSeriesName, datum.y);
        dataPoints.set(datum.x, thisDataPoint);
    }

    // We need to add null values to the result array so that they can be interpolated by the chart
    const seriesNames = Array.from(seriesNamesSet);

    // Sort series names for compare prev groupings
    if (args.isComparePrev) {
        seriesNames.sort((a, b) => {
            if (moment(a.split("-")[0]) > moment(b.split("-")[0])) {
                return 1;
            } else {
                return -1;
            }
        });
    }

    const xValues = Array.from(xValuesSet);

    // header row looks like [Xlabel, S1Name, style, annotation , S2Name, style, annotation, S3Name, style, annotation]

    const columnHeaders: any[] = [];

    for (let seriesName of seriesNames) {
        columnHeaders.push(seriesName);
        if (args.tooltipBuilder)
            columnHeaders.push({
                type: "string",
                role: "tooltip",
                p: { html: true },
            });
    }

    const headerRow = ["x"].concat(columnHeaders) as any[];
    const result: (string | number | null | any)[][] = [];

    for (let xValue of xValues) {
        // result rows look like [XValue, S1Value, S2Value, S3Value]

        const xString = function (xValue: any) {
            let returnValue = xValue;
            returnValue = xValue !== null ? xValue.toString() : "";
            return returnValue;
        };
        const resultRow: (string | number | null | any)[] = [xString(xValue)];

        for (const seriesName of seriesNames) {
            const yValue = dataPoints.get(xValue)?.get(seriesName) ?? null;
            resultRow.push(yValue);
            if (args.tooltipBuilder) {
                // google charts only likes html strings. We can still allow the user
                // to return jsx elements/nodes but use ReactDOMServer to turn it into
                // static html
                let tooltip = args.tooltipBuilder(xValue, yValue, seriesName);
                if (typeof tooltip !== "string")
                    tooltip = ReactDOMServer.renderToStaticMarkup(tooltip);
                resultRow.push(tooltip);
            }
        }
        result.push(resultRow);
    }

    result.insertAt(0, headerRow);

    return { data: result, seriesNames: seriesNamesSet };
}
