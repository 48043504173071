import { Box, Button, Divider, Grid, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import { observer } from "mobx-react";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthStore } from "stores/AuthStore";
import useStyles from "Styles/Styles";
import { useStore } from "utils/useStore";
import ReportsStore from "./Reports/Stores/ReportsStore";
import AcxMainTextField from "components/UI/AcxMainTextField";
import SearchIcon from "@mui/icons-material/Search";
import SignalsStore from "./Store/SignalsStore";
import { ReportEditorStore } from "./Reports/Stores/ReportEditorStore";
import { FieldUse } from "models/Reporting/ReportField";
import theme from "Theme/AppTheme";
import SideNavigationLink from "Layouts/SideNavigationNew/SideNavigationLink";
import SignalsSvg from "./Icons/SignalsSvg";
import ReportsSvg from "./Icons/ReportsSvg";
import SideNavigation from "Layouts/SideNavigationNew/SideNavigation";
import AddIcon from "@mui/icons-material/Add";
import SignalsReportStore, { LoadDashboards } from "./Store/SignalsReportStore";

const styles = (theme: Theme) =>
    createStyles({
        drawerHeader: {
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
            fontWeight: "bold",
        },
        searchField: {
            marginTop: theme.spacing(1),
        },
        searchIconColor: {
            color: "#1F1F1F",
            opacity: 0.25,
            marginRight: theme.spacing(1),
        },
        editorButtons: {
            textTransform: "none",
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold as any,
        },
        mainNavPadding: {
            paddingRight: theme.spacing(2),
            paddingLeft: theme.spacing(2),
            paddingBottom: theme.spacing(5),
        },
    });

const SignalsDrawerContent = observer(() => {
    const signalsStore = useStore(SignalsStore);
    const signalsReportStore = useStore(SignalsReportStore);
    const reportsStore = useStore(ReportsStore);
    const reportEditorStore = useStore(ReportEditorStore);
    const { pathname, hash } = useLocation();

    const navigate = useNavigate();
    const classes = useStyles(styles);
    const orgStore = useStore(AuthStore).orgStore;

    React.useEffect(() => {
        if (orgStore && orgStore.selectedOrganization) {
            reportsStore.orgSelectorStore.selectOrg(
                orgStore.selectedOrganization,
            );
        }
    }, [orgStore, orgStore?.selectedOrganization, reportsStore]);

    function onCreateNewReport() {
        reportsStore.initializeReportEditorForNew();
        reportEditorStore?.currentReport?.reportFields
            .filter(
                (value) =>
                    value.fieldUse !== undefined &&
                    [FieldUse.X, FieldUse.Y].includes(value.fieldUse),
            )
            .forEach((value) => {
                reportEditorStore.currentReport?.removeReportField(value);
            });
        reportsStore.reportDataViewModel.googleFormattedData = [];
        if (orgStore.selectedOrganization?.id) {
            navigate(
                `/focus/signals/reporteditor/${orgStore.selectedOrganization.id}/`,
            );
        }
    }

    const getCurrentNavItem = () => {
        const pathNameArray = pathname.split("/");
        const currentRoute = pathNameArray[pathNameArray.length - 1];

        let currentNavItem = !signalsStore.showReports
            ? currentRoute !== "signals"
                ? currentRoute + decodeURIComponent(hash)
                : decodeURIComponent(hash)
            : `${signalsStore.reportsStore.signalRoutePrefix}/${signalsStore.authStore.orgStore.selectedOrganization?.id}/${signalsStore.reportsStore.currentReport?.id}`;

        if (pathNameArray.includes("dashboard")) {
            currentNavItem = "dashboard/" + currentNavItem;
        }

        return currentNavItem;
    };

    const reportsSearchControls = (
        <>
            <Grid item>
                <Typography variant="h1">All Reports</Typography>
            </Grid>
            <Grid item>
                <Button
                    className={classes.editorButtons}
                    variant={"contained"}
                    color={"primary"}
                    size={"small"}
                    onClick={onCreateNewReport}
                    disabled={!reportsStore.canEditReports}
                    disableElevation
                >
                    Create New
                </Button>
            </Grid>
            <Grid item xs={12} className={classes.searchField}>
                <Typography>
                    Last Updated: {reportsStore.lastRefreshTime}
                </Typography>
            </Grid>
            <Grid item xs={12} className={classes.searchField}>
                <AcxMainTextField
                    placeholderText="Search"
                    onChange={(evt) => {
                        reportsStore.reportsSearchTerm = evt.target.value;
                    }}
                    containerClass={classes.drawerHeader}
                    id="report-list-search-box"
                    startAdornment={
                        <SearchIcon className={classes.searchIconColor} />
                    }
                    value={reportsStore.reportsSearchTerm}
                />
            </Grid>
        </>
    );

    const mainNavItems = (
        <Grid
            container
            justifyContent={"space-between"}
            alignItems={"baseline"}
            className={classes.drawerHeader}
        >
            <SideNavigationLink
                val={{
                    title: (
                        <Box id="signals-discover-signals-link" fontSize="14px">
                            Discover Signals
                        </Box>
                    ),
                    icon: <SignalsSvg />,
                    link: "",
                    signalsVariant: true,
                }}
                currentNavItem={
                    pathname.split("signals")[1] !== "/reports" ? "" : undefined
                }
                replaceLinks={{}}
            />

            <SideNavigationLink
                val={{
                    title: (
                        <Box
                            id="signals-new-dash-link"
                            fontSize="14px"
                            onClick={() => {
                                signalsReportStore.showCreateDashboard = true;
                                signalsReportStore.newDashboardTitle = "";
                                signalsReportStore.newDashboardVis = "User";
                            }}
                        >
                            New Dashboard
                        </Box>
                    ),
                    icon: <AddIcon />,
                    link: getCurrentNavItem(),
                    signalsVariant: true,
                }}
                replaceLinks={{}}
            />

            <SideNavigationLink
                val={{
                    title: (
                        <Box id="signals-reporting-link" fontSize="14px">
                            Reporting
                        </Box>
                    ),
                    icon: <ReportsSvg />,
                    link: "reports",
                    signalsVariant: true,
                }}
                currentNavItem={pathname.split("signals/")[1]?.slice(0, 7)}
                replaceLinks={{}}
            />
            <Grid
                item
                xs={12}
                style={{
                    paddingTop: theme.spacing(1),
                    paddingBottom: theme.spacing(1),
                }}
            >
                <Divider />
            </Grid>
            {signalsStore.showReports && reportsSearchControls}
        </Grid>
    );

    const userDashboardNavItems = (
        <Grid
            container
            justifyContent={"space-between"}
            alignItems={"baseline"}
            className={classes.drawerHeader}
        >
            <Grid
                item
                xs={12}
                style={{
                    paddingTop: theme.spacing(1),
                    paddingBottom: theme.spacing(1),
                }}
            >
                <Divider />
            </Grid>

            <Grid
                item
                style={{
                    paddingTop: theme.spacing(1),
                    paddingBottom: theme.spacing(1),
                    color: theme.palette.gray.main,
                    fontSize: "12px",
                }}
            >
                MY DASHBOARDS
            </Grid>
        </Grid>
    );

    const orgDashboardNavItems = (
        <Grid
            container
            justifyContent={"space-between"}
            alignItems={"baseline"}
            className={classes.drawerHeader}
        >
            <Grid
                item
                xs={12}
                style={{
                    paddingTop: theme.spacing(1),
                    paddingBottom: theme.spacing(1),
                }}
            >
                <Divider />
            </Grid>

            <Grid
                item
                style={{
                    paddingTop: theme.spacing(1),
                    paddingBottom: theme.spacing(1),
                    color: theme.palette.gray.main,
                    fontSize: "12px",
                }}
            >
                SHARED DASHBOARDS
            </Grid>
        </Grid>
    );

    return (
        <Grid className={classes.mainNavPadding}>
            {mainNavItems}
            <SideNavigation
                items={signalsStore.navStore.itemGroups ?? []}
                currentNavItem={getCurrentNavItem()}
                organizationId=""
                isLoading={
                    signalsStore.reportsStore.getTaskLoadingV2(
                        "Load Reports",
                    ) ?? false
                }
                signalsVariant
            />
            {!signalsStore.showReports && (
                <>
                    {userDashboardNavItems}
                    <SideNavigation
                        items={
                            signalsStore.userDashboardNavStore.itemGroups ?? []
                        }
                        currentNavItem={getCurrentNavItem()}
                        organizationId=""
                        signalsVariant
                        isLoading={signalsReportStore.getTaskLoading(
                            LoadDashboards,
                        )}
                    />
                    {!!signalsStore.orgDashboardNavStore?.itemGroups
                        ?.length && (
                        <>
                            {orgDashboardNavItems}
                            <SideNavigation
                                items={
                                    signalsStore.orgDashboardNavStore
                                        .itemGroups ?? []
                                }
                                currentNavItem={getCurrentNavItem()}
                                organizationId=""
                                signalsVariant
                                isLoading={signalsReportStore.getTaskLoading(
                                    LoadDashboards,
                                )}
                            />
                        </>
                    )}
                </>
            )}
        </Grid>
    );
});

export default SignalsDrawerContent;
