import { Grid, Typography } from "@mui/material";
import AcxToggleButton from "components/UI/AcxToggleButton";
import React, { useEffect } from "react";
import { ApplicationFilterDomain } from "services/ApplicationFiltersService";

interface VisibilityToggleProps {
    defaultValue?: ApplicationFilterDomain;
    onChange?(visibility: ApplicationFilterDomain): void;
}

const userEquivalentVisibilities: ApplicationFilterDomain[] = [
    ApplicationFilterDomain.Conversations,
    ApplicationFilterDomain.Signals,
];

export function VisibilityToggle(
    props: VisibilityToggleProps &
        Partial<
            Omit<
                React.ComponentPropsWithoutRef<typeof AcxToggleButton>,
                "defaultValue" | "onChange"
            >
        >,
) {
    useEffect(() => {
        if (
            props.defaultValue &&
            userEquivalentVisibilities.includes(props.defaultValue)
        )
            props.onChange?.(ApplicationFilterDomain.User);
    }, [props]);

    return (
        <Grid container direction="column" rowSpacing={1}>
            <Grid item>
                <Typography fontWeight="bold">Visibility</Typography>
            </Grid>
            <Grid item>
                <AcxToggleButton
                    {...props}
                    id="conversations-filter-eddy-toggle-button"
                    exclusive
                    items={[
                        {
                            element: "Only Me",
                            value: ApplicationFilterDomain.User,
                        },
                        {
                            element: "My Organization",
                            value: ApplicationFilterDomain.Organization,
                        },
                    ]}
                    onChange={(value?: ApplicationFilterDomain) => {
                        if (!value) value = ApplicationFilterDomain.User;
                        props.onChange?.(value);
                    }}
                    defaultValue={
                        props.defaultValue ?? ApplicationFilterDomain.User
                    }
                    justifyContent="center"
                    groupFlexGrow={1}
                />
            </Grid>
        </Grid>
    );
}
