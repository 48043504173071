import { observer } from "mobx-react";
import { IApplicationFilterComponent } from "../Filters/IApplicationFilterComponent";
import React from "react";
import AcxToggleButton from "components/UI/AcxToggleButton";
import { useStore } from "utils/useStore";
import { AuthStore } from "stores/AuthStore";

export const AdverseEventFilter = observer(function (
    props: IApplicationFilterComponent,
) {
    const authStore = useStore(AuthStore);

    if (!authStore.canUserView("Safety Events Model")) return null;

    return (
        <AcxToggleButton
            id="conversations-filter-adverse-event-toggle-button"
            exclusive
            items={[
                {
                    element: "Is Present",
                    value: true,
                },
                {
                    element: "Is Not Present",
                    value: false,
                },
            ]}
            onChange={(value) => {
                props.store.setAdverseEvent(value);
            }}
            defaultValue={props.store.adverseEvent}
            justifyContent="center"
            groupFlexGrow={1}
        />
    );
});
