import { NGramPayload, ScoredNGram } from "models/NGram";
import moment from "moment";
import BaseService from "./BaseService";
import { DateReferenceOption } from "stores/ComponentStores/DatePickerComponentStore";

export interface XYData<T> {
    x: T;
    y: number;
}

export interface XYSeriesData<T> extends XYData<T> {
    series: string;
}

export interface ClassifierLineChartModel {
    graphData: Array<XYData<string>>;
    totalAppearances: number;
    percentInteractions: number;
}

export interface OverviewStats {
    count: number;
    hours: number;
}

interface QueryResult {
    query: string;
}

interface bubbledNGramsPayload {
    tfIdfFreqs: ScoredNGram[];
}

interface ClassifierSummaryPayload {
    data: Array<XYSeriesData<string>>;
}

export class ClassifierReportService extends BaseService {
    protected urlBase = "api/ClassifierReport";

    public async GetOverviewStats(
        orgId: string,
        beginDate: moment.Moment,
        endDate: moment.Moment,
        dateReference: DateReferenceOption,
        serviceHierarchyId?: string,
    ) {
        const params = new URLSearchParams({
            orgId: orgId || "",
            beginDate: beginDate?.toISOString() || "",
            endDate: endDate?.toISOString() || "",
            dateReference: dateReference.valueOf(),
        });
        if (serviceHierarchyId) {
            params.set("serviceHierarchy", serviceHierarchyId);
        }

        const rv = await this.get<OverviewStats>(
            params,
            "OverviewStats",
            undefined,
            "NLP/OverviewStats",
        );
        return rv;
    }

    public async GetClassificationStats(
        organizationId:string,
        classifierId: string,
        startDate: string,
        endDate: string,
        dateReference: DateReferenceOption,
        serviceHierarchyId?: string,
    ) {
        const params = new URLSearchParams({
            classifierId,
            startDate,
            endDate,
            dateReference,
        });
        if (serviceHierarchyId) {
            params.set("serviceHierarchy", serviceHierarchyId);
        }
        const rv = await this.post<ClassifierLineChartModel>(
            JSON.stringify({ organizationId }),
            params,
            "ClassificationStats",
        );

        return rv;
    }

    public async GetClassificationSummary(
        organizationId: string,
        startDate: moment.Moment,
        endDate: moment.Moment,
        dateReference: DateReferenceOption,
        serviceHierarchyId?: string,
    ) {
        const path = "ClassificationSummary";
        const params = new URLSearchParams({
            startDate: startDate?.toISOString() || "",
            endDate: endDate?.toISOString() || "",
            dateReference: dateReference.valueOf(),
        });
        if (serviceHierarchyId) {
            params.set("serviceHierarchy", serviceHierarchyId);
        }
        const rv = await this.post<ClassifierSummaryPayload>(
            JSON.stringify({ organizationId }),
            params,
            path,
            undefined,
            "NLP/ClassifierSummary",
        );
        return rv.data;
    }

    public async GetClassifierBubbledNGrams(
        classifierId: string,
        orgId: string,
        startDate: string,
        endDate: string,
        dateReference: DateReferenceOption,
        serviceHierarchyId?: string,
    ) {
        const params = new URLSearchParams({
            classifierId,
            orgId,
            startDate,
            endDate,
            dateReference,
        });
        if (serviceHierarchyId) {
            params.set("serviceHierarchy", serviceHierarchyId);
        }
        const rv = await this.get<bubbledNGramsPayload>(
            params,
            "ClassifierBubbledNGrams",
        );

        return rv.tfIdfFreqs;
    }

    public async GetClassifierNGrams(
        classifierId: string,
        orgId: string,
        startDate: string,
        endDate: string,
        dateReference: DateReferenceOption,
        serviceHierarchyId?: string,
    ) {
        const params = new URLSearchParams({
            classifierId,
            orgId,
            startDate,
            endDate,
            dateReference,
        });
        if (serviceHierarchyId) {
            params.set("serviceHierarchy", serviceHierarchyId);
        }
        const rv = await this.get<NGramPayload>(params, "ClassifierNGrams");

        return rv;
    }

    public async GetClassifierQuery(classifierId: string) {
        const params = new URLSearchParams({ classifierId });
        const rv = await this.get<QueryResult>(params, "ClassifierQuery");

        return rv.query;
    }
}
