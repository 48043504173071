import {
    AppBar,
    CssBaseline,
    Grid,
    Theme,
    Toolbar,
    Typography,
    useTheme,
} from "@mui/material";
import { CSSProperties } from "@mui/styles/withStyles";
import createStyles from "@mui/styles/createStyles";
import SoundClipTranscription from "components/AcxInternal/SoundClipTranscription/SoundClipTranscription";
import SamplerButton from "components/Navigation/SamplerButton";
import NavMenu, { ITab } from "components/Navigation/NavMenuNew";
import Page from "components/Navigation/Page";
import ProfileMenuIcon from "components/Navigation/ProfileMenuIcon";
import NlpLayout from "components/NLP/NlpLayout";
import { observer } from "mobx-react";
import { AppDomains } from "models/Permission/AppDomains";
import React from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { AuthStore } from "stores/AuthStore";
import useStyles from "Styles/Styles";
import hexToRGB from "utils/hexToRGB";
import { useStore } from "utils/useStore";
import logo from "../../../authenticx-green.png";
import { LayoutDrawerStore } from "../../../stores/Layout/LayoutDrawerStore";
import { useResizeObserver } from "../../../utils/useResizeObserver";
import FileProcessDashboard from "../../Instrumentation/FileProcessDashboard";
import MessageStore from "../../ManagerInteractions/Stores/MessageStore";
import NotificationMenuIcon from "../../Notifications/NotificationMenuIcon";
import AcxSnackBar from "../../UI/AcxSnackBar";
import AcxDrawer from "../../UI/Drawer/AcxDrawer";
import PipelineToolsNav from "../AdminUIComponents/PipelineTools/PipelineToolsNav";
import OrganizationsContainer from "../Organizations/OrganizationsContainer";
import Config from "../OrgConfig/Config";
import { AdminRoutes } from "./AdminRoutes";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.background.default,
            position: "relative",
        },
        appBar: {
            // backgroundColor: theme.palette.white.main,
            zIndex: theme.zIndex.drawer + 1,
        },
        logo: {
            height: 30,
            cursor: "pointer",
            marginLeft: "auto",
            marginRight: "auto",
        },
        logoContainer: {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
        },
        rightToolbar: {
            marginLeft: "auto",
        },
        tenantIcon: {
            height: 30,
            width: 30,
            borderRadius: "100%",
            backgroundColor: hexToRGB(theme.palette.secondary.main, 0.1),
            color: theme.palette.blue.main,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "bold",
        },
        toolbar: theme.mixins.toolbar as CSSProperties, //fix incompatibility with React.CSSProperties
    });

const App = observer(() => {
    const authStore = useStore(AuthStore);
    const drawerStore = useStore(LayoutDrawerStore);
    const messageStore = useStore(MessageStore);

    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const classes = useStyles(styles);

    const {
        elementHeight: appBarHeight,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        elementWidth,
        theRef,
    } = useResizeObserver<HTMLElement>({
        name: "AdminAppNavToolbar",
        pixelDelta: 1,
        debounceWait: 50,
    });

    const [currentTab, setCurrentTab] = React.useState(0);
    const [tabs, setTabs] = React.useState<Array<ITab>>();

    const handleTabChange = (_event: React.ChangeEvent<{}>, newTab: number) => {
        setCurrentTab(newTab);
        var tab = tabs![newTab];
        navigate(tab.routePrefix + tab.route);
    };

    const onLogoClick = () => {
        navigate("/");
    };

    React.useEffect(() => {
        async function authSetup() {
            if (authStore.permStore && authStore._isAuthenticated) {
                const userTabs = authStore.filterTabs(AdminRoutes.tabsConfig);

                const containsTab = (tabLabel: string) => {
                    return userTabs.find((tab) => tab.tabLabel === tabLabel);
                };

                if (
                    (authStore.canUserView("File Process Tracking") ||
                        authStore.permStore.isElevated) &&
                    !containsTab("File Dashboard")
                ) {
                    userTabs.push({
                        tabLabel: "File Dashboard",
                        routePrefix: "/admin",
                        route: "/fileprocessdashboard",
                        // roles: ["Superusers", "Ultrausers", "Administrators"],
                        domain: AppDomains.Administrative,
                    });
                }

                if (userTabs.length === 0) {
                    navigate("/app");
                    return;
                }

                setTabs(userTabs);

                let currentRoute = location.pathname.replace(
                    AdminRoutes.routePrefix,
                    "",
                );

                currentRoute = "/" + currentRoute.split("/")[1];

                const curTab = userTabs.findIndex((o) =>
                    o.route.startsWith(currentRoute),
                );

                if (curTab !== currentTab) {
                    if (curTab > -1) {
                        setCurrentTab(curTab);
                    } else {
                        setCurrentTab(0);

                        if (
                            authStore.permStore.userTenantAuthorizations
                                .length === 0 &&
                            userTabs[0].tabLabel === "Settings"
                        ) {
                            if (authStore.activeOrgId) {
                                navigate(
                                    "/admin/organizations/" +
                                        authStore.activeOrgId,
                                );
                            } else {
                                navigate(
                                    "/admin/organizations/" +
                                        authStore._user.profile.OrgId,
                                );
                            }
                            return;
                        }

                        navigate(userTabs[0].routePrefix + userTabs[0].route);
                    }
                }
            } else {
                navigate(authStore.getLoginUrl());
            }
        }

        authSetup();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        location.pathname,
        authStore.permStore,
        authStore.permStore?.appPermissions,
    ]);

    React.useEffect(() => {
        const settingsTab = tabs?.find((i) => i.tabLabel === "Settings");

        // Old settings route was /organizations, to implement routing to currently selected org, we need to add the selected orgs id to the route
        if (settingsTab) {
            if (authStore.orgStore.selectedOrganization?.id) {
                settingsTab.route =
                    "/organizations/" +
                    authStore.orgStore.selectedOrganization?.id;
            } else {
                settingsTab.route =
                    "/organizations/" + authStore._user.profile.OrgId;
            }
        }
    }, [
        authStore._user.profile.OrgId,
        authStore.orgStore.selectedOrganization?.id,
        tabs,
    ]);

    return (
        <>
            <CssBaseline />
            <AcxSnackBar
                open={messageStore.showMessage}
                onClose={messageStore.showNextMessage}
                message={messageStore.currentMessage?.text || ""}
                severity={messageStore.currentMessage?.severity}
            />
            <AppBar
                ref={theRef}
                className={classes.appBar}
                style={{
                    boxShadow:
                        "0px 4px 1px -1px rgba(0,0,0,0.02), 0px 1px 1px 0px rgba(0,0,0,0.02), 0px 2px 6px 0px rgba(0,0,0,0.02)",
                }}
            >
                <Toolbar sx={{ backgroundColor: theme.palette.white.main }}>
                    <Grid container wrap={"nowrap"} alignItems={"center"}>
                        <Grid item>
                            <div className={classes.logoContainer}>
                                <Grid container>
                                    <Grid
                                        item
                                        container
                                        xs={12}
                                        alignContent="center"
                                    >
                                        <img
                                            className={classes.logo}
                                            alt=""
                                            src={logo}
                                            onClick={onLogoClick}
                                        ></img>
                                    </Grid>

                                    <Grid
                                        item
                                        container
                                        xs={12}
                                        justifyContent="center"
                                        alignContent="center"
                                        alignItems="center"
                                    >
                                        <Typography
                                            display="block"
                                            align="center"
                                            style={{
                                                fontSize: "12px",
                                                color: theme.palette.text
                                                    .primary,
                                            }}
                                        >
                                            Admin
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>

                        <Grid item xs={9}>
                            <NavMenu
                                onChange={handleTabChange}
                                selected={currentTab}
                                tabs={tabs!}
                            />
                        </Grid>

                        <Grid
                            container
                            item
                            xs={3}
                            justifyContent={"flex-end"}
                            wrap={"nowrap"}
                        >
                            <Grid item style={{ alignSelf: "center" }}>
                                <NotificationMenuIcon
                                    drawerOffsetPx={appBarHeight}
                                />
                            </Grid>

                            <Grid item style={{ alignSelf: "center" }}>
                                <SamplerButton showIcon={true} />
                            </Grid>

                            <Grid item style={{ alignSelf: "center" }}>
                                <ProfileMenuIcon
                                    currLocation={"adminApp"}
                                    organizationName={
                                        authStore.orgStore.selectedOrganization
                                            ?.name ?? ""
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>

            <div className={classes.toolbar} />

            <div>
                <AcxDrawer
                    customRootStyles={drawerStore.customRootStyles}
                    anchor={drawerStore.anchor}
                    open={drawerStore.isOpen}
                    collapsible={drawerStore.collapseSize}
                    variant={drawerStore.variant}
                    offsetPx={drawerStore.offsetPx}
                    offsetPosition={drawerStore.offsetPosition}
                    onDrawerClose={() => drawerStore.setOpen(false)}
                    drawerStore={drawerStore}
                    onDrawerOpen={() => drawerStore.setOpen(true)}
                    content={drawerStore.contentInjector?.()}
                />
            </div>

            <Routes>
                <Route path="config" element={<Config />} />

                <Route
                    path="fileprocessdashboard"
                    element={<FileProcessDashboard />}
                />

                <Route
                    path="soundcliptranscribe"
                    element={<SoundClipTranscription />}
                />

                <Route path="nlp/*" element={<NlpLayout />} />

                <Route path="/pipeline/*" element={<PipelineToolsNav />} />

                <Route
                    path="organizations/*"
                    element={<OrganizationsContainer />}
                ></Route>

                <Route
                    path="/*"
                    element={
                        <Page title="Administration | Authenticx">
                            <OrganizationsContainer />
                        </Page>
                    }
                />
            </Routes>
        </>
    );
});

export default App;
