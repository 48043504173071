import { IconButton, Theme, Typography, alpha, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Grid } from "@mui/material";
import {
    FileCopy,
    Cached,
    VisibilityOff,
    Visibility,
    ThumbUp,
    ThumbDown,
} from "@mui/icons-material";
import AcxButton from "components/UI/AcxButton";
import AcxChip from "components/UI/AcxChip";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useStore } from "utils/useStore";
import AISummariesStore, { FeedbackType } from "../Store/AISummariesStore";
import logo from "../../../authenticx-green.png";
import SignalsReportStore from "../Store/SignalsReportStore";
import { AuthStore } from "stores/AuthStore";
import AcxToast from "components/UI/AcxToast";
import cx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
    spotlightContainer: {
        padding: theme.spacing(2),
        backgroundColor: "#F3F4F6",
        borderRadius: theme.spacing(0.5),
    },
    spotlightHeader: {
        textTransform: "uppercase",
        letterSpacing: "2px",
    },
    spotlightButton: {
        backgroundColor: "transparent",
        // color: theme.palette.gray.main,
        color: "#9CA3AF",
        "&:hover": {
            backgroundColor: alpha(theme.palette.gray.main, 0.1),
        },
        "&:disabled": {
            color: theme.palette.gray.light,
            backgroundColor: "transparent",
        },
    },
    spotlightFeedbackButton: {
        backgroundColor: "white",
        color: "#9CA3AF",
    },
    spotlightFeedbackChosen: {
        color: theme.palette.black.main,
    },
    spotlightFeedbackNotChosen: {
        opacity: "50%",
    },
    itemDisabled: {
        cursor: "not-allowed",
    },
    spotlightContent: {
        maxHeight: "200px",
        overflow: "auto",
    },
}));

const SignalsSpotlight = observer((props: { chartId: string }) => {
    const classes = useStyles();
    const theme = useTheme();
    const store = useStore(SignalsReportStore);
    const authStore = useStore(AuthStore);

    const aiSummaryStore = useStore(AISummariesStore);

    const [isSpotlightExpanded, setIsSpotlightExpanded] = useState(true);
    const [feedbackResponseMessage, setFeedbackResponseMessage] = useState<
        string | undefined
    >();

    if (!authStore.canUserView("AI Summaries")) return null;

    async function handleFeedback(feedbackType: FeedbackType) {
        const result = await aiSummaryStore.sendSummaryFeedback(
            props.chartId,
            feedbackType,
        );

        if (!result) {
            setFeedbackResponseMessage(
                "Sorry, we weren't able to process your feedback. Please try again.",
            );
        } else {
            setFeedbackResponseMessage(
                "Thanks for your feedback! Your input makes Spotlight better in future iterations.",
            );
        }
    }

    const state = aiSummaryStore.getSummary(props.chartId);

    const isSpotlightLoading = aiSummaryStore.isSummaryLoading(props.chartId);
    const isChartLoading = store.getTaskLoading(props.chartId);
    const isFeedbackLoading = store.getTaskLoading(`Feedback-${props.chartId}`);

    return (
        (aiSummaryStore.isInitialSummaryFetched(props.chartId) ||
            isSpotlightLoading ||
            aiSummaryStore.aiSummariesByChartId[props.chartId]) && (
            <Grid
                container
                direction="row"
                alignItems="start"
                wrap={"nowrap"}
                gap={2}
                className={classes.spotlightContainer}
            >
                <Grid item>
                    <img src={logo} alt="" style={{ height: "20px" }} />
                </Grid>
                <Grid container item direction="column" gap={1}>
                    <Grid
                        container
                        item
                        direction="row"
                        gap={2}
                        alignItems="center"
                    >
                        <Typography
                            className={classes.spotlightHeader}
                            sx={{ color: theme.palette.secondary.dark }}
                        >
                            Spotlight
                        </Typography>
                        {state.outdated && (
                            <AcxChip
                                size="small"
                                color="gray"
                                label="Current filters not reflected"
                            />
                        )}
                    </Grid>
                    {isSpotlightLoading && (
                        <Grid
                            container
                            item
                            direction="column"
                            alignItems="center"
                            gap={1}
                            // center it
                            style={{ marginLeft: "-32px" }}
                        >
                            <Grid item>
                                <AcxLoadingIndicator
                                    alternate="PuffLoader"
                                    size={32}
                                    style={{ zIndex: 1098 }}
                                />
                            </Grid>
                            <Grid item>
                                <Typography>
                                    Generating spotlight based on current
                                    insights
                                </Typography>
                            </Grid>
                        </Grid>
                    )}

                    {!isSpotlightLoading && (
                        <React.Fragment>
                            <Grid item>
                                {isSpotlightExpanded && (
                                    <Typography
                                        variant="body1"
                                        // Insights come back with new line characters in them, using the pre
                                        // tag/component will format them correctly
                                        component="pre"
                                        noWrap={false}
                                        className={classes.spotlightContent}
                                        style={{
                                            // This is needed so that text wraps properly within the pre element
                                            // otherwise it will just overflow
                                            whiteSpace: "pre-wrap",
                                        }}
                                    >
                                        {state.summary ??
                                            "No insights generated"}
                                    </Typography>
                                )}
                            </Grid>

                            {!store.isPresentationMode && (
                                <Grid
                                    container
                                    item
                                    direction="row"
                                    gap={1}
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Grid
                                        container
                                        item
                                        direction="row"
                                        gap={1}
                                        width="fit-content"
                                    >
                                        {!aiSummaryStore.didSummaryTimeout(
                                            props.chartId,
                                        ) && (
                                            <React.Fragment>
                                                <Grid item>
                                                    <IconButton
                                                        id={`signals-spotlight-thumbsup-${props.chartId}`}
                                                        className={cx(
                                                            classes.spotlightFeedbackButton,
                                                            state.feedback ===
                                                                FeedbackType.Like &&
                                                                classes.spotlightFeedbackChosen,
                                                            state.feedback &&
                                                                state.feedback !==
                                                                    FeedbackType.Like &&
                                                                classes.spotlightFeedbackNotChosen,
                                                            isFeedbackLoading &&
                                                                classes.itemDisabled,
                                                        )}
                                                        onClick={() =>
                                                            handleFeedback(
                                                                FeedbackType.Like,
                                                            )
                                                        }
                                                        disabled={
                                                            isFeedbackLoading
                                                        }
                                                        size="large"
                                                    >
                                                        <ThumbUp fontSize="small" />
                                                    </IconButton>
                                                </Grid>
                                                <Grid item>
                                                    <IconButton
                                                        id={`signals-spotlight-thumbsdown-${props.chartId}`}
                                                        className={cx(
                                                            classes.spotlightFeedbackButton,
                                                            state.feedback ===
                                                                FeedbackType.Dislike &&
                                                                classes.spotlightFeedbackChosen,
                                                            state.feedback &&
                                                                state.feedback !==
                                                                    FeedbackType.Dislike &&
                                                                classes.spotlightFeedbackNotChosen,
                                                            isFeedbackLoading &&
                                                                classes.itemDisabled,
                                                        )}
                                                        onClick={() =>
                                                            handleFeedback(
                                                                FeedbackType.Dislike,
                                                            )
                                                        }
                                                        disabled={
                                                            isFeedbackLoading
                                                        }
                                                        size="large"
                                                    >
                                                        <ThumbDown fontSize="small" />
                                                    </IconButton>
                                                </Grid>
                                            </React.Fragment>
                                        )}
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        direction="row"
                                        gap={1}
                                        width="fit-content"
                                    >
                                        <Grid item>
                                            <AcxButton
                                                id={`signals-spotlight-copy-${props.chartId}`}
                                                variant="text"
                                                startIcon={<FileCopy />}
                                                leftRightSpacing={0}
                                                customRootStyles={{
                                                    backgroundColor:
                                                        "transparent",
                                                    color: theme.palette
                                                        .gray[400],
                                                }}
                                                onClick={() => {
                                                    aiSummaryStore.copySummaryToClipboard(
                                                        props.chartId,
                                                    );
                                                }}
                                            >
                                                Copy
                                            </AcxButton>
                                        </Grid>
                                        <Grid item>
                                            <AcxButton
                                                id={`signals-spotlight-refresh-${props.chartId}`}
                                                variant="text"
                                                startIcon={<Cached />}
                                                leftRightSpacing={0}
                                                customRootStyles={{
                                                    backgroundColor:
                                                        "transparent",
                                                    color: theme.palette
                                                        .gray[400],
                                                }}
                                                buttonDisabled={
                                                    !state.refreshable ||
                                                    isChartLoading
                                                }
                                                onClick={() => {
                                                    store.refreshAISummary(
                                                        props.chartId,
                                                    );
                                                    setFeedbackResponseMessage(
                                                        undefined,
                                                    );
                                                    setIsSpotlightExpanded(
                                                        true,
                                                    );
                                                }}
                                            >
                                                Refresh
                                            </AcxButton>
                                        </Grid>
                                        <Grid item>
                                            <AcxButton
                                                id={`signals-show-hide-insights-${props.chartId}`}
                                                variant="text"
                                                startIcon={
                                                    isSpotlightExpanded ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )
                                                }
                                                leftRightSpacing={0}
                                                customRootStyles={{
                                                    backgroundColor:
                                                        "transparent",
                                                    color: theme.palette
                                                        .gray[400],
                                                }}
                                                onClick={() =>
                                                    setIsSpotlightExpanded(
                                                        (expanded) => !expanded,
                                                    )
                                                }
                                            >
                                                {isSpotlightExpanded &&
                                                    "Hide insights"}
                                                {!isSpotlightExpanded &&
                                                    "Show insights"}
                                            </AcxButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}

                            <Grid item style={{ position: "relative" }}>
                                {!isFeedbackLoading &&
                                    feedbackResponseMessage && (
                                        <AcxToast
                                            fullWidth
                                            fullWidthMessage
                                            title=""
                                            message={feedbackResponseMessage}
                                            severity={
                                                state.feedback
                                                    ? "infoV2"
                                                    : "error"
                                            }
                                            onClose={() =>
                                                setFeedbackResponseMessage(
                                                    undefined,
                                                )
                                            }
                                        />
                                    )}
                            </Grid>
                        </React.Fragment>
                    )}
                </Grid>
            </Grid>
        )
    );
});

export default SignalsSpotlight;
