import React, { useState, useContext } from "react";
import TranscriptionVisualTranscriptionView from "./TranscriptionVisualTranscriptionView";
import { TranscriptionService } from "services/TranscriptionService";
import useServiceData from "utils/useServiceData";
import TranscriptionVisualStore from "../../TranscriptionVisualStore";
import { observer } from "mobx-react-lite";

const txServ = new TranscriptionService();

interface OwnProps {
    transcriptionId: string;
}

const TranscriptionVisualTranscriptionController = observer(
    (props: OwnProps) => {
        const tvStore = useContext(TranscriptionVisualStore);
        const [showAll, setShowAll] = useState(false);
        const [payload, isLoading] = useServiceData(() =>
            txServ.getTranscriptionText(props.transcriptionId),
        );
        const segments = payload?.phrases || [];
        tvStore.transcriptionSegments = segments;
        tvStore.points = segments?.map((item) => item.startTime) || [];

        return (
            <TranscriptionVisualTranscriptionView
                segments={segments}
                currentlyPlayingSegmentIdx={getCurrentlyPlayingSegmentIdx()}
                showAll={showAll}
                toggleShowAll={() => setShowAll(!showAll)}
                isLoading={isLoading}
                onClassifyClick={tvStore.loadClassifiers}
                highlightKeywords={tvStore.selectedKeywords}
                {...props}
            />
        );

        function getCurrentlyPlayingSegmentIdx(): number | null {
            if (!segments || tvStore.currentSeekPosition === 0) return null;
            const numSegments = segments.length;
            for (let x = numSegments - 1; x >= 0; x--) {
                const segment = segments[x];
                if (segment.startTime <= tvStore.currentSeekPosition) {
                    return x;
                }
            }
            return null;
        }
    },
);

export default TranscriptionVisualTranscriptionController;
