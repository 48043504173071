import { Grid, Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import AcxButton from "components/UI/AcxButton";
import AcxChip from "components/UI/AcxChip";
import AcxSelectSingle from "components/UI/Select/BaseSelectComponents/AcxSelectSingle";
import Classifier from "models/ClassifierModel";
import React from "react";
import useStyles from "Styles/Styles";

const styles = (theme: Theme) =>
    createStyles({
        controlRowWrapper: {
            marginBottom: "0.8rem",
            marginTop: "0px",
            justifyContent: "space-between",
        },
    });
interface ClassifierOption extends Classifier {
    labelDisplay?: React.ReactElement;
}
interface OwnProps {
    onOpenLoadDialog: () => void;
    onOpenDeleteDialog: () => void;
    onOpenPublishDialog: () => void;
    onSetSelectedClassifier: (classifier: Classifier) => void;
    handleLoad: () => void;
    storeTemporaryValue: (value: Classifier) => void;
    selectedClassifier?: Classifier;
    classifiers: Classifier[];
    classifiersLoading: boolean;
    controlsDisabled: boolean;
}

function ClassifierBuilderClassifierManagementView(props: OwnProps) {
    const classes = useStyles(styles);
    const selectedIsPublished = props.selectedClassifier?.isPublished;

    const classifierOptions: ClassifierOption[] = React.useMemo(() => {
        let options: ClassifierOption[] = [];

        for (const classifier of props.classifiers) {
            const option: ClassifierOption = { ...classifier };

            const labelDisplay = (
                <label>
                    {classifier.name}
                    {classifier.createdBy && (
                        <AcxChip
                            color="green"
                            size="small"
                            label={classifier.createdBy}
                        />
                    )}
                    {classifier.isPublished ? (
                        <AcxChip color="gray" size="small" label="Published" />
                    ) : null}
                </label>
            );

            option.labelDisplay = labelDisplay;
            options.push(option);
        }

        options = options.sort((a, b) => {
            // For unpublished classifiers, sort by name
            if (!a.isPublished && !b.isPublished) {
                if (a.name < b.name) return -1;
                if (b.name < a.name) return 1;
                return 0;
            }

            // Sort unpublished classifiers first
            if (!a.isPublished && b.isPublished) return -1;
            if (!b.isPublished && a.isPublished) return 1;

            // For published classifiers, sort by owner
            if (!a.createdBy || !b.createdBy) return 0;
            if (a.createdBy < b.createdBy) return -1;
            if (b.createdBy < a.createdBy) return 1;

            // If all else is equal, sort by name
            if (a.name < b.name) return -1;
            if (b.name < a.name) return 1;
            return 0;
        });

        return options;
    }, [props.classifiers]);

    const handleClassifierSelection = (event: Classifier) => {
        const searchTerms = (
            document.getElementById("search-term-entry") as HTMLInputElement
        )?.value;

        if (!searchTerms) {
            props.onSetSelectedClassifier(event as Classifier);
            props.handleLoad();
        } else {
            props.storeTemporaryValue(event as Classifier);
            props.onOpenLoadDialog();
        }
    };

    return (
        <>
            <Grid container className={classes.controlRowWrapper}>
                <Grid container item xs={6}>
                    <AcxSelectSingle
                        id="index-select"
                        onChange={handleClassifierSelection}
                        valueField="id"
                        labelField="labelDisplay"
                        options={classifierOptions}
                        placeholder={
                            props.classifiersLoading
                                ? "Loading..."
                                : "Select Classifier"
                        }
                        defaultValue={classifierOptions.find(
                            (option) =>
                                option.id === props.selectedClassifier?.id,
                        )}
                    />
                </Grid>

                <Grid container item xs={6} justifyContent="flex-end">
                    <Grid container item xs={2}>
                        <AcxButton
                            leftRightSpacing={0}
                            color="secondary"
                            buttonDisabled={props.controlsDisabled}
                            onClick={props.onOpenPublishDialog}
                            tooltip={
                                selectedIsPublished
                                    ? "Unpublish classifier"
                                    : "Publish classifier"
                            }
                            fullWidth={true}
                        >
                            {selectedIsPublished ? `Unpublish` : `Publish`}
                        </AcxButton>
                    </Grid>

                    <Grid container item xs={2} justifyContent="flex-end">
                        {/* <AcxButton
                            leftRightSpacing={0}
                            color="secondary"
                            buttonDisabled={props.controlsDisabled}
                            onClick={props.onOpenDeleteDialog}
                            tooltip="Delete classifier"
                            fullWidth={false}
                        >
                            <DeleteIcon />
                        </AcxButton> */}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default ClassifierBuilderClassifierManagementView;
