import { ButtonBase, Grid, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import { CSSProperties } from "@mui/styles";
import cx from "clsx";
import React, { useEffect, useState } from "react";
import useStyles from "Styles/Styles";
import BaseEntity from "../../models/BaseEntity";
import { ThemeColors } from "../../Theme/AppTheme";
import hexToRGB from "../../utils/hexToRGB";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            // flexGrow: 1,
            boxSizing: "border-box",
            width: "100%",
            height: "100%",
        },
        answerButton: {
            backgroundColor: "inherit",
            userSelect: "none",
            height: "100%",
            outlineColor: "inherit",
            fontFamily: "inherit",
            letterSpacing: "inherit",
            lineHeight: "inherit",
            fontSize: "inherit",
            outlineStyle: "inherit",
            outlineWidth: "inherit",
            textAlign: "center",
            fontWeight: "bold",
            width: "100%",
            textTransform: "none",
            paddingTop: "2px",
            paddingBottom: "2px",
            paddingLeft: "3px",
            paddingRight: "3px",
            borderRadius: "5px",
        },
        item: {
            backgroundColor: theme.palette.white.main,
            fontFamily: "Inter",
            fontSize: "14px",
            letterSpacing: 0,
            // lineHeight: "16px",
            cursor: "pointer",
            userSelect: "none",
            borderStyle: "solid",
            borderWidth: "1px",
            textAlign: "center",
            fontWeight: "bolder",
        },
        unSelected: {
            color: theme.palette.text.disabled,
            borderColor: hexToRGB(theme.palette.gray.main, 0.3),
            cursor: "pointer",
            fontWeight: "normal",
            "&:hover": {
                color: theme.palette.blue.main,
                outlineColor: theme.palette.blue.main,
                backgroundColor: theme.palette.blue.background,
            },
        },
        green: {
            color: theme.palette.black.main,
            borderColor: theme.palette.green.main,
            backgroundColor: theme.palette.green.background,
        },
        yellow: {
            color: theme.palette.black.main,
            borderColor: theme.palette.yellow.main,
            backgroundColor: theme.palette.yellow.background,
        },
        red: {
            color: theme.palette.black.main,
            borderColor: theme.palette.red.main,
            backgroundColor: theme.palette.red.background,
        },
        blue: {
            color: theme.palette.black.main,
            borderColor: theme.palette.info.main,
            backgroundColor: hexToRGB(theme.palette.info.main, 0.05),
        },
        darkblue: {
            color: theme.palette.black.main,
            borderColor: theme.palette.black.main,
            backgroundColor: ThemeColors.lightBlue,
        },
    });

export enum AnswerColor {
    RED = "red",
    YELLOW = "yellow",
    GREEN = "green",
    GRAY = "gray",
    BLUE = "blue",
    DARKBLUE = "darkblue",
}

export interface AnswerSelection {
    text: string;
    color: AnswerColor;
}

interface Props<T> {
    options: Array<{ data: T; option: AnswerSelection }>;
    onSelect: (arg0: T) => void;
    defaultValue?: T;
    buttonRootStyles?: CSSProperties;
    isDisabled?: boolean;
}

function AcxAnswerSelector<T extends BaseEntity>(props: Props<T>) {
    const classes = useStyles(styles);
    const [selected, setSelected] = useState<number>();

    useEffect(() => {
        if (props.defaultValue !== undefined) {
            const defaultValueIndex = props.options.findIndex(
                (value) => value.data.id === props.defaultValue?.id,
            );
            setSelected(defaultValueIndex);
        } else {
            setSelected(-1);
        }
    }, [props.defaultValue, props.options]);

    function clickHandler(optionIndex: number) {
        if (!props.isDisabled) {
            if (optionIndex === selected) {
                setSelected(-1);
                // @ts-ignore
                props.onSelect(undefined);
            } else {
                setSelected(optionIndex);
                const option = props.options[optionIndex];
                props.onSelect(option.data);
            }
        }
    }

    function renderOption(option: AnswerSelection, optionIndex: number) {
        let classNames = [classes.item];
        if (optionIndex === selected) {
            classNames.push(classes[option.color.valueOf()]);
        } else {
            classNames.push(classes.unSelected);
        }
        return (
            <Grid
                item
                xs={12}
                lg
                className={cx(classNames)}
                style={{
                    minHeight: "28px",
                    marginRight: "8px",
                    borderRadius: "5px",
                    marginBottom: "0.5rem",
                    ...props.buttonRootStyles,
                }}
                key={`option_${optionIndex}`}
            >
                <ButtonBase
                    className={classes.answerButton}
                    onClick={() => clickHandler(optionIndex)}
                >
                    {option.text}
                </ButtonBase>
            </Grid>
        );
    }

    return (
        <Grid container className={classes.root}>
            {props.options.map((value) => value.option).map(renderOption)}
        </Grid>
    );
}

export default AcxAnswerSelector;
