import { Theme, Tooltip } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import AddCircleIcon from "@mui/icons-material/AddCircle";
import React from "react";
import useStyles from "Styles/Styles";
import { EMockOrgSetup, IFakeRecursiveTier } from "../RecursiveTreeTestData";
import { RecursiveTreeTestStore } from "../RecursiveTreeTestStore";

export interface IRecursiveTreeTestAddRowIconProps {
    branch: IFakeRecursiveTier;
    store: RecursiveTreeTestStore;
}

const styles = (theme: Theme) => {
    return createStyles({
        icon: {
            display: "flex",
            alignItems: "center",
            marginRight: theme.spacing(1),
            marginLeft: theme.spacing(1),
            cursor: "pointer",
        },
    });
};

const RecursiveTreeTestAddRowIcon: React.FC<React.PropsWithChildren<React.PropsWithChildren<IRecursiveTreeTestAddRowIconProps>>> =
    ({ branch, store }) => {
        const classes = useStyles(styles);

        const enableAddition = (
            branch: IFakeRecursiveTier,
        ): "Maxed" | "Open" | boolean => {
            if (branch.branchDepth === Object.keys(EMockOrgSetup).length - 1) {
                return "Maxed";
            }

            if (
                !store.treeStore.openedBranchIds.includes(branch.id) &&
                (branch.dummyTiers?.length ?? 0) > 0
            ) {
                return "Open";
            }

            return true;
        };

        const handleOnClick = () => {
            store.setActiveTier(branch);
            store.setShowAddDialog(true);
        };

        return enableAddition(branch) === true ? (
            <Tooltip title={"Add Branch"}>
                <AddCircleIcon
                    color="secondary"
                    onClick={handleOnClick}
                    cursor="pointer"
                    className={classes.icon}
                />
            </Tooltip>
        ) : (
            <Tooltip
                title={
                    enableAddition(branch) === "Open"
                        ? "Please open the branch before adding children."
                        : "No more branches are available within the organization setup."
                }
            >
                <AddCircleIcon htmlColor="#d5dff6" className={classes.icon} />
            </Tooltip>
        );
    };

export default RecursiveTreeTestAddRowIcon;
