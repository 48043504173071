import { Typography } from "@mui/material";
import { observer } from "mobx-react";
import React, { useMemo } from "react";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import { useStore } from "utils/useStore";
import theme from "Theme/AppTheme";
import AcxVerticalBarGraph from "components/UI/Visualization/AcxVerticalBarGraph";
import AcxLineGraph from "components/UI/Visualization/AcxLineGraph";
import { EEOverTimeID, ISignalsVizDefinition } from "../Models/SignalsModels";
import { signalsChartColorScheme } from "components/UI/Visualization/GraphColorSchemes";
import { ChartPlaceholderContainer } from "./ChartPlaceholderContainer";
import NoData from "./NoData";
import SignalsReportStore from "../Store/SignalsReportStore";
import InfoSvg from "SvgIcons/InfoSvg";
import AcxHorizontalBarGraph from "components/UI/Visualization/AcxHorizontalBarGraph";
import SignalsDrilldownStore from "../Store/SignalsDrilldownStore";

const chartTimeoutMessage =
    "Report has timed out. Try a shorter date/period range, adjust your filters, or click refresh to try again.";

type Props = ISignalsVizDefinition;

const SignalsChart: React.FC<Props> = observer(
    ({
        id,
        chartType,
        yTitle,
        xTitle,
        data,
        seriesNames,
        visibleSeries,
        isStackedBar,
        dataEndpoint,
        vizOptions,
        selectionType,
    }) => {
        const store = useStore(SignalsReportStore);
        const signalsDrilldownStore = useStore(SignalsDrilldownStore);

        const isComparePrev = store.selectedComparePrevBools[id];

        let series: Record<string, unknown>[] | undefined;
        if (visibleSeries && (seriesNames?.length ?? 0) > 0) {
            series = [];
            for (const seriesName of seriesNames ?? []) {
                if (visibleSeries.includes(seriesName)) series.push({});
                else series.push({ lineWidth: 0, visibleInLegend: false });
            }
        }

        const validData = (d) => {
            return (d?.flatMap((datum) => datum).length ?? 0) > 1;
        };

        const isValidData = useMemo(() => validData(data), [data]);

        const chartOnSelect = (
            series: string | null,
            x: string | number | null,
            y: any,
            chartId?: string,
            metadata?: any[],
        ) => signalsDrilldownStore.onSelect(series, x, y, chartId, metadata);

        const vAxisDefaultTextStyle = {
            bold: false,
            italic: false,
            fontSize: "12",
            color: theme.palette.grey[600],
        };

        const vAxistDefaultTitleStyle = {
            italic: false,
            bold: false,
            fontSize: "12",
            color: "#374151", // Please update once palette is updated | Old Color: theme.palette.grey[800],
        };

        const parsedVizOptions = JSON.parse(vizOptions ?? "{}");

        const renderChart = () => {
            switch (chartType) {
                case "VerticalBar":
                    return (
                        <AcxVerticalBarGraph
                            chartAreaHeight={75}
                            chartAreaWidth={"100%"}
                            showGrid
                            legendPosition="bottom"
                            data={data}
                            yTitle={yTitle}
                            xTitle={xTitle}
                            vAxisFormat="short"
                            fontSize="1.25rem"
                            vAxisFontSize="12"
                            hAxisFontSize="12"
                            fontBold={false}
                            legendAlignment="center"
                            dataOpacity={1}
                            chartHeight="300px"
                            usesHtmlTooltips
                            slantedText={false}
                            colorScheme={
                                isComparePrev
                                    ? [
                                          theme.palette.secondary.main,
                                          theme.palette.primary[800],
                                      ]
                                    : signalsChartColorScheme
                            }
                            vAxisTitleStyle={vAxistDefaultTitleStyle}
                            vAxisTextStyle={vAxisDefaultTextStyle}
                            onChartReady={(chartWrapper) =>
                                store.captureChartRef(chartWrapper, id)
                            }
                            stacked={isStackedBar}
                            onSelect={chartOnSelect}
                            chartId={id}
                            {...parsedVizOptions}
                        />
                    );

                case "Line":
                    return (
                        <AcxLineGraph
                            showGrid
                            chartAreaHeight={75}
                            chartAreaWidth={"100%"}
                            legendPosition={
                                dataEndpoint !== EEOverTimeID
                                    ? "bottom"
                                    : "none"
                            }
                            legendAlignment="center"
                            data={data}
                            yTitle={yTitle}
                            xTitle={xTitle}
                            vAxisFormat="short"
                            vAxisFontSize="12"
                            hAxisFontSize="12"
                            dataOpacity={1}
                            chartHeight="300px"
                            hideDataLabels
                            hideDataMarkers
                            usesHtmlTooltips
                            colorScheme={signalsChartColorScheme}
                            vAxisTitleStyle={vAxistDefaultTitleStyle}
                            vAxisTextStyle={vAxisDefaultTextStyle}
                            onChartReady={(chartWrapper) =>
                                store.captureChartRef(chartWrapper, id)
                            }
                            onSelect={chartOnSelect}
                            chartId={id}
                            series={series}
                            {...parsedVizOptions}
                        />
                    );

                case "HorizontalBar":
                    return (
                        <AcxHorizontalBarGraph
                            chartAreaHeight={"80%"}
                            chartAreaWidth={"85%"}
                            chartAreaLeftOffset={"15%"}
                            showGrid
                            legendPosition="bottom"
                            data={data}
                            dataOpacity={1}
                            chartHeight="300px"
                            usesHtmlTooltips
                            colorScheme={signalsChartColorScheme}
                            vAxisTextStyle={vAxisDefaultTextStyle}
                            textPosition="out"
                            onChartReady={(chartWrapper) =>
                                store.captureChartRef(chartWrapper, id)
                            }
                            onSelect={chartOnSelect}
                            chartId={id}
                            hAxisFormat="0"
                            {...parsedVizOptions}
                        />
                    );
            }
        };

        const currentlyLoadingChart = store.getTaskLoading(id);
        const chartErrorPresent = store.getTaskError(id);

        // redraw charts when presentation mode is toggled to adjust chart width for new screen size
        React.useEffect(() => {
            setTimeout(() => {
                store.chartWrappersByChartId[id]?.draw();
            }, 100); // timeout is to ensure the page has rendered before re-drawing
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [store.isPresentationMode]);

        return (
            <>
                {currentlyLoadingChart && (
                    <ChartPlaceholderContainer>
                        <AcxLoadingIndicator
                            alternate="PuffLoader"
                            size={50}
                            style={{ zIndex: 1 }}
                        />
                    </ChartPlaceholderContainer>
                )}

                {chartErrorPresent && (
                    <ChartPlaceholderContainer direction={"column"}>
                        <InfoSvg width="50px" height="50px" />
                        <Typography variant="h6">
                            {chartTimeoutMessage}
                        </Typography>
                    </ChartPlaceholderContainer>
                )}

                {isValidData &&
                    !currentlyLoadingChart &&
                    !chartErrorPresent &&
                    renderChart()}

                {!currentlyLoadingChart &&
                    !chartErrorPresent &&
                    !isValidData && (
                        <NoData
                            chartId={id}
                            selectionType={selectionType}
                            headerText="No data available"
                        />
                    )}
            </>
        );
    },
);

export default SignalsChart;
