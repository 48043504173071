import { observer } from "mobx-react";
import { IApplicationFilterComponent } from "../Filters/IApplicationFilterComponent";
import React, { useEffect, useState } from "react";
import { useStore } from "utils/useStore";
import { ApplicationFilterValuesStore } from "stores/ApplicationFilters/ApplicationFilterValuesStore";
import AcxSelectSingleAsync from "components/UI/Select/BaseSelectComponents/AcxSelectSingleAsync";
import Agentv2 from "models/Agentv2";
import _ from "lodash";

function getAgentOptionLabel(data) {
    let lbl = "";
    if (_.has(data, "fullName")) {
        if (data?.fullName !== " ") {
            lbl = data.fullName;
        } else {
            lbl = data.agentEmail;
        }
        if (data.isActive) {
            lbl = lbl + " - Active";
        } else {
            lbl = lbl + " - Inactive";
        }
    } else {
        lbl = data.label;
    }
    return lbl;
}

export const AgentFilter = observer(function (
    props: IApplicationFilterComponent,
) {
    const valuesStore = useStore(ApplicationFilterValuesStore);

    const [agent, setAgent] = useState<Agentv2 | undefined>();

    useEffect(() => {
        // Initial agent value load if agentId is present
        if (!!props.store.agentId && !agent)
            valuesStore
                .getAgent(props.store.agentId)
                .then((agent) => setAgent(agent));
        else if (!props.store.agentId && !!agent) setAgent(undefined);
        else if (
            !!props.store.agentId &&
            !!agent &&
            props.store.agentId !== agent.id
        )
            valuesStore
                .getAgent(props.store.agentId)
                .then((agent) => setAgent(agent));
    }, [agent, valuesStore, props.store.agentId]);

    const isLoading =
        valuesStore.getTaskLoading("Get Agent") ||
        valuesStore.getTaskLoading("Search Agents");

    return (
        <AcxSelectSingleAsync
            fullWidth
            defaultValue={agent ?? ""}
            isLoading={isLoading}
            loadOptions={(input) => valuesStore.searchAgent(input)}
            cacheOptions
            isClearable
            onChange={(agent?: Agentv2) => {
                setAgent(agent);
                props.store.setAgentId(agent?.id);
            }}
            enableNoSelection
            valueField="id"
            labelField={getAgentOptionLabel}
            id="conversations-filter-agent-async-select"
            noOptionMessage="Please enter First, Last, Agent Code, or Email"
        />
    );
});
