import { LuceneIndexSearchStore } from "components/NLP/LuceneIndexSearch/LuceneIndexSearchStore";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";
import { ClassifierBuilderStore } from "../ClassifierBuilderStore";

@AcxStore
export class ClassifierBuilderSearchStore {
    builderStore: ClassifierBuilderStore;
    searchStore: LuceneIndexSearchStore;

    public constructor(rootStore: IRootStore) {
        this.builderStore = rootStore.getStore(ClassifierBuilderStore);
        this.searchStore = new LuceneIndexSearchStore(rootStore);
    }
}
