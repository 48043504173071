import { GridValueFormatterParams } from "@mui/x-data-grid-pro";
import AcxChipList from "components/UI/AcxChipList";
import { isObject } from "lodash";
import React from "react";

export const HierarchyFormatter = (
    params: GridValueFormatterParams | string,
) => {
    let initialHierarchiesFormat: string[][] = [];
    let hierarchies: string[];

    if (isObject(params)) {
        const value: { id: string; levels: string[] }[] = params.getValue(
            params.id,
            params.field,
        ) as any;

        for (let index = 0; index < value.length; index++) {
            if (value[index].levels) {
                initialHierarchiesFormat.push(
                    value[index].levels.filter((level) => !!level),
                );
            }
        }

        if (initialHierarchiesFormat.length === 0) {
            initialHierarchiesFormat = [value] as any;
        }

        hierarchies = initialHierarchiesFormat.flatMap((hierarchyGroup) =>
            hierarchyGroup.join(" > "),
        );
    } else {
        hierarchies = params as any;
    }

    if (hierarchies.length === 1 && hierarchies[0] === "") {
        return [];
    }

    const res = (
        <AcxChipList
            toolTip={
                <>
                    {hierarchies.map((value) => (
                        <div key={value}>{value}</div>
                    ))}
                </>
            }
            size={"small"}
            color={"blue"}
            values={hierarchies}
            height="100%"
        />
    );

    return res;
};
