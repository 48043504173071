import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import React, { useEffect, useRef } from "react";
import Chart from "react-google-charts";
import { GoogleChartWrapper, GoogleViz } from "react-google-charts/dist/types";
import { pieGraphDefaultColorScheme } from "./GraphColorSchemes";

const chartName = "AcxPieGraph";

type GoogleDataFormat = any[][];

interface OwnProps {
    data?: GoogleDataFormat;
    previousData?: GoogleDataFormat;
    colorScheme?: string[];

    legendPosition: "none" | "bottom" | "labeled" | "left" | "right" | "top";

    onSelect?: (
        series: string | null,
        x: string | number | null,
        y: string | number | null | any,
    ) => void;

    innerRadius?: number; // 0 <= innerRadius <= 1
    sliceText?: "percentage" | "value" | "label" | "none";

    chartAreaLeftOffset?: number | string;
    chartAreaTopOffset?: number | string;
    chartAreaHeight?: number | string;
    chartAreaWidth?: number | string;

    showDemoData?: boolean;
    asWidget?: boolean;

    containerHeight?: string;

    useAlternateRowLabel?: boolean; // used in AWOverviewChart due to data being out of date on chart type switch

    onChartReady?: (
        chartWrapper: GoogleChartWrapper,
        google: GoogleViz,
    ) => void;
}

type Props = OwnProps;

const AcxPieGraph: React.FC<Props> = (props) => {
    const wrapper = useRef<null | any>();
    useEffect(() => {
        return () => {
            wrapper.current?.getChart()?.clearChart();
        };
    }, []);

    const data = Boolean(props.showDemoData) ? fake_data : props.data;

    const showLegend = true;

    return (
        <>
            {Boolean(data) && (
                <Chart
                    width={`100%`}
                    height={props.containerHeight ?? `95%`}
                    chartType="PieChart"
                    loader={
                        <AcxLoadingIndicator
                            color="secondary"
                            alternate="PuffLoader"
                            size={96}
                        />
                    }
                    data={Boolean(props.previousData) ? undefined : data}
                    diffdata={
                        Boolean(props.previousData)
                            ? {
                                  old: props.previousData,
                                  new: props.data,
                              }
                            : undefined
                    }
                    options={{
                        pieSliceText: props.sliceText ?? "percentage",
                        pieHole: Boolean(props.previousData)
                            ? undefined
                            : props.innerRadius ?? 0.56,
                        fontName: "Inter",
                        dataOpacity: 0.65,
                        animation: {
                            startup: true,
                            duration: 500,
                            easing: "out",
                        },
                        tooltip: {
                            textStyle: { fontName: "Inter" },
                            showColorCode: true,
                        },

                        colors:
                            props.colorScheme && props.colorScheme.length
                                ? props.colorScheme
                                : pieGraphDefaultColorScheme,
                        pieSliceTextStyle: {
                            fontName: "Inter",
                        },
                        legend: {
                            position: showLegend
                                ? props.legendPosition
                                : "none",
                            alignment: "center",
                            textStyle: {
                                fontName: "Inter",
                            },
                        },
                        chart: {},
                        chartArea: {
                            left: props.chartAreaLeftOffset,
                            top: props.chartAreaTopOffset,
                            width: props.chartAreaWidth ?? "100%",
                            height: props.chartAreaHeight ?? "90%",
                        },
                    }}
                    getChartWrapper={(w, g) => {
                        wrapper.current = w;
                        props.onChartReady?.(w, g);
                    }}
                    chartEvents={[
                        {
                            eventName: "select",
                            callback: ({ chartWrapper }) => {
                                const chart = chartWrapper.getChart();
                                const selection = chart.getSelection();
                                if (selection.length === 1) {
                                    const [selectedItem] = selection;
                                    const dataTable =
                                        chartWrapper.getDataTable();
                                    const { row, column } = selectedItem;

                                    if (row !== null && column !== null) {
                                        const rowLabel = data?.[row + 1]?.[0];
                                        console.log(
                                            `${chartName} selection : ${JSON.stringify(
                                                {
                                                    row,
                                                    column,
                                                    series: dataTable?.getColumnLabel(
                                                        column,
                                                    ),
                                                    X: rowLabel,
                                                    Y: dataTable?.getValue(
                                                        row,
                                                        column,
                                                    ),
                                                    rowProps:
                                                        dataTable?.getRowProperties(
                                                            row,
                                                        ),
                                                    columnProps:
                                                        dataTable?.getColumnProperties(
                                                            column,
                                                        ),
                                                },
                                            )}`,
                                        );

                                        props.onSelect?.(
                                            dataTable?.getColumnLabel(column) ??
                                                null,
                                            rowLabel,
                                            dataTable?.getValue(row, column) ??
                                                null,
                                        );
                                    } else if (row !== null) {
                                        const rowLabel = data?.[row + 1]?.[0];

                                        const alternateRowLabel = (
                                            dataTable as any
                                        )?.Wf[row]["c"][0]["v"];

                                        console.log(
                                            `${chartName} selection : ${JSON.stringify(
                                                {
                                                    row,
                                                    column,
                                                    // X: rowLabel,
                                                    X: props.useAlternateRowLabel
                                                        ? alternateRowLabel
                                                        : rowLabel,
                                                    rowProps:
                                                        dataTable?.getRowProperties(
                                                            row,
                                                        ),
                                                },
                                            )}`,
                                        );

                                        props.onSelect?.(
                                            null,
                                            props.useAlternateRowLabel
                                                ? alternateRowLabel
                                                : rowLabel,
                                            null,
                                        );
                                    } else if (column !== null) {
                                        console.log(
                                            `${chartName} selection : ${JSON.stringify(
                                                {
                                                    row,
                                                    column,
                                                    series: dataTable?.getColumnLabel(
                                                        column,
                                                    ),
                                                    columnProps:
                                                        dataTable?.getColumnProperties(
                                                            column,
                                                        ),
                                                },
                                            )}`,
                                        );

                                        props.onSelect?.(
                                            dataTable?.getColumnLabel(column) ??
                                                null,
                                            null,
                                            null,
                                        );
                                    }
                                }
                            },
                        },
                    ]}
                />
            )}
        </>
    );
};

const fake_data = [
    ["Tags", "Count"],
    ["Brand Value", 358293],
    ["Program Value", 101265],
    ["Help With Bill", 172780],
    ["Brand Detractor", 129634],
    ["Program Detractor", 97216],
];

export default AcxPieGraph;
