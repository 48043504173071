import { IconButton, Tooltip, useMediaQuery } from "@mui/material";
import { Theme, useTheme } from "@mui/material/styles";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { PauseCircleFilled, PlayCircleFilledWhite } from "@mui/icons-material";
import * as React from "react";
import { CSSProperties } from "react";

interface IPlayerProps {
    //   parameters: any;
    play: () => void;
    pause: () => void;
    color: "default" | "inherit" | "primary" | "secondary";
    svgSize?: "small" | "medium";
    status: PlayStatus;
    hoverMessage?: HoverMessages;
    style?: CSSProperties;
    buttonStyle?: CSSProperties;
}

export class HoverMessages {
    playMessage: string = "Play";
    pauseMessage: string = "Pause";
}

export enum PlayStatus {
    Play,
    Paused,
    Stopped,
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: theme.spacing(0),
            padding: theme.spacing(1),
        },
    }),
);
const PlayControl = ({
    play,
    pause,
    status,
    color,
    hoverMessage,
    style,
    svgSize,
    buttonStyle,
}: IPlayerProps) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    if (hoverMessage === undefined) {
        hoverMessage = new HoverMessages();
    }
    const iconSize = useMediaQuery(theme.breakpoints.down('lg'))
        ? "small"
        : "large";
    switch (status) {
        case PlayStatus.Play:
            return (
                <Tooltip title={hoverMessage.pauseMessage} arrow>
                    <IconButton
                        disableRipple
                        size={svgSize}
                        onClick={pause}
                        color={color}
                        className={classes.button}
                        style={buttonStyle}
                    >
                        <PauseCircleFilled
                            style={style}
                            fontSize={svgSize ? svgSize : iconSize}
                        />
                    </IconButton>
                </Tooltip>
            );
        default:
            return (
                <Tooltip title={hoverMessage.playMessage} arrow>
                    <IconButton
                        disableRipple
                        size={svgSize}
                        onClick={play}
                        color={color}
                        className={classes.button}
                        style={buttonStyle}
                    >
                        <PlayCircleFilledWhite
                            style={style}
                            fontSize={svgSize ? svgSize : iconSize}
                        />
                    </IconButton>
                </Tooltip>
            );
    }
};

export default PlayControl;
