import { ClickAwayListener, Paper, Popper } from "@mui/material";
import { useTheme } from "@mui/styles";
import { GridApiRef } from "@mui/x-data-grid-pro";
import { observer } from "mobx-react";
import React from "react";
import { uuidv4 } from "utils/helpers";
import AcxDataGridStore from "../AcxDataGridStore";
import HideColumnPanel from "./HideColumnPanel";

interface IHideColumn {
    api: GridApiRef;
    store: AcxDataGridStore;
    id?: string;
}

const HideColumn = observer((props: IHideColumn) => {
    const theme = useTheme();
    const [isOpen, setIsOpen] = React.useState<boolean>(
        props.store.hideColumnsIsOpen ?? false,
    );

    // ToDo: the dependencies decreased here, I'm not so sure about that.
    const handleClickAway = React.useCallback(() => {
        props.store.hideColumnsIsOpen = false;
    }, [props.store]);

    let anchorEl;
    if (
        props.api.current &&
        props.api.current.columnHeadersContainerElementRef?.current
    ) {
        anchorEl = props.api.current.columnHeadersContainerElementRef!.current;
    }

    const calcMaxHeight = () => {
        const maxHeightFromContainer =
            (props.api.current.rootElementRef?.current?.offsetHeight ?? 0) -
            (props.api.current.columnHeadersContainerElementRef?.current
                ?.offsetHeight ?? 0) -
            35;
        return maxHeightFromContainer > 0 ? maxHeightFromContainer : 0;
    };

    React.useEffect(() => {
        setIsOpen(props.store.hideColumnsIsOpen ?? false);
    }, [props.store.hideColumnsIsOpen]);

    return (
        <Popper
            id={`data-grid-hide-column-menu-${props.id ?? uuidv4()}`}
            placement="bottom-start"
            open={isOpen}
            anchorEl={anchorEl}
            modifiers={[
                {
                    name: "flip",
                    enabled: false,
                },
                {
                    name: "preventOverflow",
                    enabled: false,
                    options: {
                        boundariesElement: "scrollParent",
                    },
                },
                {
                    name: "hide",
                    enabled: false,
                },
            ]}
            style={{ zIndex: 9999 }}
        >
            <ClickAwayListener onClickAway={handleClickAway}>
                <Paper
                    sx={{
                        backgroundColor: theme.palette.background.paper,
                        padding: "8px",
                        borderRadius: "8px",
                    }}
                    elevation={8}
                >
                    <HideColumnPanel
                        store={props.store}
                        maxHeight={calcMaxHeight() ?? 300}
                    />
                </Paper>
            </ClickAwayListener>
        </Popper>
    );
});

export default HideColumn;
