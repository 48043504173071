import { observer } from "mobx-react";
import { IAppliedFilterChipComponent } from "../Chips/IAppliedFilterChipComponent";
import AppliedFilterChipBase from "../Chips/AppliedFilterChipBase";
import { useStore } from "utils/useStore";
import { ApplicationFiltersStore } from "stores/ApplicationFilters/ApplicationFiltersStore";
import React from "react";
import { MediaType } from "stores/ApplicationFilters/Filters/MediaType";

export const MediaTypesChips = observer(function (
    props: IAppliedFilterChipComponent,
) {
    const applicationFiltersStore = useStore(ApplicationFiltersStore);

    return applicationFiltersStore.mediaTypes.map((mediaType) => (
        <AppliedFilterChipBase
            isApplicable={props.isApplicable}
            onRemove={() => applicationFiltersStore.toggleMediaType(mediaType)}
        >
            {MediaType.toString(mediaType)}
        </AppliedFilterChipBase>
    ));
});
