import { useContext } from "react";
import App from "../app";
import { Constructor, IRootStore } from "../stores/RootStore";

export function useRootStore(): IRootStore {
    return useContext(App.RootStoreContext());
}

export function useStore<TStore>(whichStore: Constructor<TStore>): TStore {
    return useContext(App.RootStoreContext()).getStore<TStore>(whichStore);
}
