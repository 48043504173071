import { ConfigurationStepStore } from "components/Admin/AdminUIComponents/AudioFileSamplerV2/Stores/ConfigurationStepStore";
import { SourceFilesStepStore } from "components/Admin/AdminUIComponents/AudioFileSamplerV2/Stores/SourceFilesStepStore";
import { StepperStore } from "components/Admin/AdminUIComponents/AudioFileSamplerV2/Stores/StepperStore";
import {
    action,
    computed,
    makeObservable,
    observable,
    reaction,
    runInAction,
} from "mobx";
import AudioContextProvider from "providers/AudioContextProvider";
import { AudioFilesService } from "services/AudioFilesService";
import SearchService from "services/SearchService";
import { AuthStore } from "stores/AuthStore";
import { DatePickerComponentStore } from "stores/ComponentStores/DatePickerComponentStore";
import { DialogComponentStore } from "stores/ComponentStores/DialogComponentStore";
import { v4 as uuid } from "uuid";
import { AudioMetadataStatus } from "../../../models/AudioMetadataModel";
import { ClipUIModel } from "../../../models/SoundClip";
import { StorageAccountUseOptions } from "../../../models/StorageAccount";
import { TranscriptionService } from "../../../services/TranscriptionService";
import { BaseStore } from "../../../stores/BaseStore";
import { IRootStore } from "../../../stores/RootStore";
import MessageStore from "../../ManagerInteractions/Stores/MessageStore";
import { SearchResult } from "../ClassifierBuilder/Search/ClassifierBuilderSearchController";

export const LOAD_AUDIO_METADATA = "Load Audio Metadata";
export const SAMPLE_AUDIO = "Sample Classifier Builder Audio File";

const searchService = new SearchService();
export class LuceneIndexSearchStore extends BaseStore {
    private readonly audioProvider = new AudioContextProvider();

    private readonly audioFilesService: AudioFilesService =
        new AudioFilesService();

    private readonly authStore: AuthStore;
    private readonly configurationStepStore: ConfigurationStepStore;
    private readonly sourceFilesStepStore: SourceFilesStepStore;
    private readonly stepperStore: StepperStore;

    private orgId: string;

    readonly transcriptionService: TranscriptionService =
        new TranscriptionService();

    @observable
    luceneAudioDialogStore: DialogComponentStore<LuceneIndexSearchStore>;

    @observable
    datePickerStore: DatePickerComponentStore;

    @observable
    query: string;

    @observable
    searchResults: SearchResult[];

    @observable
    builderPage: boolean;

    @observable
    startDate?: string;

    @observable
    endDate?: string;

    @observable.ref
    currentSoundClip?: ClipUIModel = undefined;

    @observable.ref
    currentBlob?: Blob;

    @observable.ref
    currentBuffer?: AudioBuffer;

    constructor(private rootStore: IRootStore) {
        super("LuceneIndexSearch");

        makeObservable(this);

        this.luceneAudioDialogStore = new DialogComponentStore(undefined, this);
        this.datePickerStore = new DatePickerComponentStore();

        this.authStore = rootStore.getStore(AuthStore);
        this.sourceFilesStepStore = rootStore.getStore(SourceFilesStepStore);
        this.stepperStore = rootStore.getStore(StepperStore);
        this.configurationStepStore = rootStore.getStore(
            ConfigurationStepStore,
        );

        reaction(
            (r) => ({
                storeError: this.nextTaskError,
            }),
            (arg) => {
                if (arg && arg.storeError?.message) {
                    const msg = arg.storeError?.message;
                    this.rootStore.getStore(MessageStore).logError(msg);
                    this.clearLastTaskError();
                }
            },
            { fireImmediately: true, delay: 0 },
        );
    }

    @computed
    get searchResultsLoading() {
        return this.getTaskLoading("Search Azure Index");
    }

    @action
    setCurrentBuffer = (arg: {
        id: string;
        index: number;
        audioBuffer: AudioBuffer;
        blob: Blob;
    }) => {
        this.currentBuffer = arg.audioBuffer;
        this.currentBlob = arg.blob;
    };

    @action.bind(this)
    async doSearch(
        orgId: string | undefined,
        query: string,
        startDate?: string,
        endDate?: string,
    ) {
        if (!query || !orgId) {
            return;
        }

        this.orgId = orgId;
        this.query = query;
        this.startDate = startDate;
        this.endDate = endDate;

        if (this.builderPage) {
            startDate = this.datePickerStore.beginDate.toISOString();
            endDate = this.datePickerStore.endDate.toISOString();
        }
        this.setupAsyncTask("Search Azure Index", async () => {
            const results = await searchService.searchByQuery(
                orgId,
                query,
                100,
                startDate,
                endDate,
            );
            runInAction(() => {
                this.searchResults = results;
            });
        });
    }

    @action
    loadAudioMetadataForTranscription(
        transcriptionId: string,
        transcriptionText: string,
    ) {
        this.setupAsyncTask(LOAD_AUDIO_METADATA, async () => {
            this.currentSoundClip = undefined;

            const audioMetadata =
                await this.transcriptionService.getMetadataForTranscription(
                    transcriptionId,
                );

            const sc = new ClipUIModel(uuid());

            const url = `https://${audioMetadata?.directory?.account}.blob.core.windows.net/${audioMetadata?.blobFileKey}`;

            sc.id = audioMetadata.id;
            sc.url = url;
            sc.segmentName = audioMetadata?.blobFileKey;
            sc.filePath = url;
            sc.startTime = 0;
            sc.transcriptionText = transcriptionText;
            sc.organizationId = this.orgId;
            sc.storageAccount = audioMetadata?.directory?.account!;
            sc.storageAccountUse =
                audioMetadata.status === AudioMetadataStatus.Sampled
                    ? StorageAccountUseOptions.Main
                    : StorageAccountUseOptions.Holding;
            sc.fileName = audioMetadata?.fileName;
            sc.directoryId = audioMetadata?.directory?.id;

            runInAction(() => {
                this.currentSoundClip = sc;
            });

            this.luceneAudioDialogStore.open();
        });
    }

    @action
    closeLuceneDialog = () => {
        this.currentSoundClip = undefined;
        this.luceneAudioDialogStore.close();
    };

    @action
    download = async () => {
        if (this.currentBlob && this.currentSoundClip) {
            this.audioProvider.download(
                this.currentBlob,
                (this.currentSoundClip.segmentName ?? "LuceneAudioFile") +
                    ".mp3",
                false,
            );

            this.closeLuceneDialog();
        }
    };

    @action
    sampleAudio = (navigate) => {
        this.setupAsyncTask(SAMPLE_AUDIO, async () => {
            if (this.currentSoundClip) {
                const audioFile =
                    await this.audioFilesService.sampleAudioFileClip(
                        this.currentSoundClip.id,
                    );

                if (audioFile) {
                    this.sourceFilesStepStore.reset();
                    this.configurationStepStore.postSamplingReset();

                    this.configurationStepStore.setRulesetHidden(true);

                    this.sourceFilesStepStore.orgSelectStore.selectOrg(
                        this.authStore.orgStore.selectedOrganization,
                    );

                    this.configurationStepStore.setFiles([audioFile]);
                    this.stepperStore.stepIndex = 1;

                    this.closeLuceneDialog();
                    navigate("/focus/audiofilesamplerv2");
                }
            } else {
                this.closeLuceneDialog();
            }
        });
    };
}
