import { Grid, Theme, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { observer } from "mobx-react";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import AcxButton from "components/UI/AcxButton";
import ClassifierBuilderTips from "../ClassifierBuilderTips";
import StandardClassifierBuilderStore from "./StandardClassifierBuilderStore";
import StandardClassifierBuilderGroup from "./StandardClassifierBuilderGroup";

const useBuilderStyles = makeStyles((theme: Theme) => ({
    addButton: {
        color: theme.palette.secondary.main,
        "&:hover": {
            color: "white",
        },
    },
}));

interface Props {
    store: StandardClassifierBuilderStore;
}

const StandardClassifierBuilder: React.FunctionComponent<Props> = observer(
    ({ store }) => {
        const classes = useBuilderStyles();
        const theme = useTheme();
        return (
            <>
                <Grid container item xs={12}>
                    {!!store.classifierGroups.length ? (
                        store.classifierGroups.map((group, index) => (
                            <StandardClassifierBuilderGroup
                                group={group}
                                groupIndex={index}
                                store={store}
                                key={"group-" + index}
                            />
                        ))
                    ) : (
                        <ClassifierBuilderTips />
                    )}
                </Grid>
                <AcxButton
                    variant="outlined"
                    fullWidth={false}
                    color="secondary"
                    buttonClass={classes.addButton}
                    onClick={() => {
                        store.addClassifierBuilderGroup(0);
                    }}
                    customRootStyles={{
                        color: theme.palette.primary.main,
                    }}
                >
                    <AddIcon /> New Group
                </AcxButton>
            </>
        );
    },
);

export default StandardClassifierBuilder;
