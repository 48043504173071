import { useEffect, useState } from "react";
import Peaks from "@beacx/peacx";
import theme from "Theme/AppTheme";

export type ElementRef = Element | null;

export type PeaksOptions = {
    waveformColor: string;
    editable: boolean;
    segmentColor: string;
    showPlayheadTime: boolean;
    height: number;
    pointMarkerColor?: string;
    axisGridlineColor?: string;
    axisLabelColor?: string;
};

function buildSegment(time1: number, time2: number, labelText?: string) {
    let startTime: number;
    let endTime: number;

    if (time1 < time2) {
        startTime = time1;
        endTime = time2;
    } else if (time2 < time1) {
        startTime = time2;
        endTime = time1;
    } else {
        throw new Error(
            "Can't create a segment with identical start and end times",
        );
    }

    return {
        startTime: startTime,
        endTime: endTime,
        editable: true,
        labelText: labelText,
    };
}

function usePeaks(
    overView: ElementRef,
    zoomView: ElementRef,
    mediaElement: ElementRef,
    config: PeaksOptions,
    points: number[] = [],
) {
    const [peaksInstance, setPeaksInstance] = useState<Peaks.PeaksInstance>();
    useEffect(() => {
        let mouseDownTime: number | null = null;
        let creatingNewSegment: boolean = false;
        if (overView == null || zoomView == null || mediaElement == null) {
            return;
        }

        const options = {
            containers: {
                overview: overView as HTMLElement,
            },
            mediaElement: mediaElement as HTMLElement,
            webAudio: {
                audioContext: new AudioContext(),
            },
            zoomLevels: [56, 128, 256, 512, 1024, 2048, 4096],
            randomizeSegmentColor: false,
            zoomWaveformColor: theme.palette.blue.main.toString(),
            playheadColor: theme.palette.black.main.toString(),
            playheadTextColor: "black",
            overviewWaveformColor: config.waveformColor,
            editableOverview: config.editable,
            segmentColor: config.segmentColor,
            showPlayheadTime: config.showPlayheadTime,
            height: config.height,
            pointMarkerColor:
                config.pointMarkerColor || theme.palette.grey.toString(),
            axisGridlineColor: config.axisGridlineColor || "#CCC",
            axisLabelColor: config.axisLabelColor || "#AAA",
            emitCueEvents: true,
            points: [],
        };

        const peaksInstance = Peaks.init(options);

        for (const time of points) {
            peaksInstance.points.add({ time });
        }

        if (options.editableOverview) {
            peaksInstance.on("overview.dragstart", (time, modifiers) => {
                if (modifiers.includes("Shift")) {
                    creatingNewSegment = true;
                } else {
                    peaksInstance.segments.removeAll();
                }
                mouseDownTime = time;
            });

            peaksInstance.on("overview.dragmove", (time) => {
                if (mouseDownTime == null) {
                    throw new Error(
                        "Tried to create a segment with no initial time",
                    );
                }

                const segment = buildSegment(mouseDownTime, time);

                if (creatingNewSegment) {
                    peaksInstance.segments.add(segment);
                    creatingNewSegment = false;
                } else {
                    const segments = peaksInstance.segments.getSegments();
                    if (segments.length > 0) {
                        const targetId = segments[segments.length - 1].id;
                        if (targetId != null) {
                            peaksInstance.segments.removeById(targetId);
                        }
                    }
                    peaksInstance.segments.add(segment);
                }
            });
        }
        setPeaksInstance(peaksInstance);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        config.axisGridlineColor,
        config.axisLabelColor,
        config.editable,
        config.height,
        config.pointMarkerColor,
        config.segmentColor,
        config.showPlayheadTime,
        config.waveformColor,
        mediaElement,
        overView,
        zoomView,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        points.join("|"),
    ]);

    return peaksInstance;
}

export default usePeaks;
