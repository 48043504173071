import { makeStyles, CreateCSSProperties, PropsFunc } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import _ from "lodash";
import globalStyles from "./GlobalStyles";

export type StyleRulesCallback<
    ThemeType extends Theme = Theme,
    Props extends object = {},
    ClassKey extends string = string,
> = (
    theme: ThemeType,
) => Record<
    ClassKey,
    | React.CSSProperties
    | CreateCSSProperties<Props>
    | PropsFunc<Props, CreateCSSProperties<Props>>
>;

const useStyles = <
    ThemeType extends Theme = Theme,
    ClassKey extends string = string,
>(
    themer: StyleRulesCallback<ThemeType, {}, ClassKey>,
) => {
    return makeStyles((theme: ThemeType) => {
        const mergedRules = globalStyles(theme) as Record<string, any>;
        const newRules = themer(theme) as Record<string, any>;
        return _.merge(mergedRules, newRules);
    })();
};

export default useStyles;
