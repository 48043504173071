import { Dialog, Slide, SlideProps, Typography } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import Theme from "Theme/AppTheme";
import { useStore } from "utils/useStore";
import LuceneIndexSearchResults from "../LuceneIndexSearch/LuceneIndexSearchResults";
import ClassifierReportStore from "./ClassifierReportStore";

const Transition = React.forwardRef(function Transition(
    props: SlideProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const ClassifierReportSearchResultsDialog = observer(() => {
    const reportStore = useStore(ClassifierReportStore);

    return (
        <Dialog
            fullWidth={true}
            maxWidth="lg"
            open={reportStore.searchResultsDialogOpen}
            onClose={reportStore.closeSearchResultsDialog}
            TransitionComponent={Transition}
        >
            <div
                style={{
                    padding: Theme.spacing(2),
                    width: "100%",
                    height: "80vh",
                }}
            >
                <div>
                    <Typography component="span">
                        Showing results for{" "}
                    </Typography>
                    <Typography color="primary" component="span">
                        {reportStore.searchStore.query}{" "}
                    </Typography>
                </div>

                <LuceneIndexSearchResults
                    store={reportStore.searchStore}
                    isLoading={reportStore.searchStore.searchResultsLoading}
                    searchResults={reportStore.searchStore.searchResults}
                />
            </div>
        </Dialog>
    );
});

export default ClassifierReportSearchResultsDialog;
