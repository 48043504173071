import { Fab, Grid, Tooltip, Typography } from "@mui/material";
import ServiceHierarchySvg from "SvgIcons/ServiceHierarchySvg";
import theme from "Theme/AppTheme";
import { Observer, observer } from "mobx-react";
import React from "react";
import AcxChip from "../AcxChip";
import { LOAD_HIERARCHIES } from "./AcxHierarchyDetailsFabStore";

export interface IHierarchyDetailsFabStore {
    setParams: (orgId: string, userId: string) => void;
    getTaskLoading: (arg: string) => boolean;
    displayView: (content: React.ReactNode, mainContentClass?: string) => void;
    hierarchies?: { label: string; id: string }[];
}

interface IHierarchyDetailsFabProps {
    store?: IHierarchyDetailsFabStore;
    orgId?: string;
    userId?: string;
    fabLabel?: string;
    handleOnClick?: () => void;
}

// IMPORTANT! To use the AcxHierarchyDetailsFab, you must provide either a Store or use a callback in the handleOnChange to populate any actions.
// In the event in which a Store is used, reference AcxHandleDetailsFabStore. It is suggested to new up an instance of AcxHandleDetailsFabStore in the constructor of your primary store to pass in as the prop argument, or have your store implement IHierarchyDetailsFabStore interface.
const AcxHierarchyDetailsFab: React.FC<
    React.PropsWithChildren<React.PropsWithChildren<IHierarchyDetailsFabProps>>
> = observer(({ store, orgId, userId, fabLabel, handleOnClick }) => {
    const fabStore: IHierarchyDetailsFabStore | undefined = store;

    const isLoading = fabStore?.getTaskLoading(LOAD_HIERARCHIES) ?? false;

    const handleOnViewQueues = () => {
        if (fabStore) {
            fabStore.displayView(
                <Observer>
                    {() => (
                        <div>
                            {fabStore.hierarchies?.map((hierarchy) => {
                                return (
                                    <Grid container key={hierarchy.id}>
                                        <Tooltip title={hierarchy.label}>
                                            <span style={{ width: "100%" }}>
                                                <AcxChip
                                                    style={{
                                                        maxWidth: "100%",
                                                    }}
                                                    size="small"
                                                    color="blue"
                                                    label={hierarchy.label}
                                                />
                                            </span>
                                        </Tooltip>
                                    </Grid>
                                );
                            })}
                        </div>
                    )}
                </Observer>,
                undefined,
            );
        }
    };

    React.useEffect(() => {
        if (fabStore) {
            if (orgId && userId) {
                fabStore.setParams(orgId, userId);
            }
        }
    }, [orgId, fabStore, userId]);

    return (
        <Tooltip
            title={
                isLoading ? "Hierarchies are still loading, please wait." : ""
            }
        >
            <span>
                <Fab
                    disabled={isLoading}
                    variant="extended"
                    style={{ marginTop: "0.5rem" }}
                    size="medium"
                    color="primary"
                    aria-label="view queues"
                    onClick={handleOnClick ?? handleOnViewQueues}
                >
                    <ServiceHierarchySvg
                        opacity={1}
                        color={theme.palette.white.main}
                    />

                    <Typography
                        component="p"
                        sx={{
                            marginLeft: "0.5rem",
                            color: "inherit",
                            fontSize: "inherit",
                            fontWeight: 600,
                            textTransform: "none",
                        }}
                    >
                        {fabLabel ?? "Hierarchies"}
                    </Typography>
                </Fab>
            </span>
        </Tooltip>
    );
});

export default AcxHierarchyDetailsFab;
