import makeStyles from '@mui/styles/makeStyles';
import { Card, CardContent, Typography } from "@mui/material";
import React from "react";
import WarningIcon from "@mui/icons-material/Warning";

const useDisputeStyles = makeStyles({
    card: {
        marginBottom: 12,
        border: "1px solid #E5E7EB",
        borderRadius: "4px",
        margin: "0 8px 0 8px",
    },
    titleContainer: {
        display: "flex",
        alignItems: "center",
        marginBottom: "4px",
    },
    title: {
        fontSize: "13px",
        fontWeight: "bold",
    },
    text: {
        marginLeft: "25px",
        fontSize: "13px",
    },
    warningIcon: {
        marginRight: 8,
        color: "red",
        fontSize: "17px",
    },
});

export function DisputeNote({ disputeNote }: { disputeNote?: string }) {
    const classes = useDisputeStyles();

    return (
        <Card className={classes.card}>
            <CardContent>
                <div className={classes.titleContainer}>
                    <WarningIcon className={classes.warningIcon} />
                    <Typography className={classes.title}>
                        Dispute Notes
                    </Typography>
                </div>
                <Typography className={classes.text}>
                    {disputeNote ? disputeNote : "None provided"}
                </Typography>
            </CardContent>
        </Card>
    );
}
