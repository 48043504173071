import { Grid } from "@mui/material";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import AcxRecursiveTree from "components/UI/AcxRecursiveTree/AcxRecursiveTree";
import { Observer, observer } from "mobx-react";
import React from "react";
import { IAcxHierarchyComponentProps } from "./AcxHierarchySelector";

// const useStyles = makeStyles((theme: Theme) => ({
//     loadingContainer: {
//         padding: theme.spacing(2),
//         height: "100%",
//     },
//     treeContainer: (props: IAcxHierarchyComponentProps) => ({
//         height: props.fullHeight ? "50vh" : "40vh",
//         overflowY: "scroll",
//         "@media (max-height: 600px)": {
//             height: "100px",
//         },
//     }),

//     padBottom: {
//         paddingBottom: "1rem",
//     },
// }));

const HierarchySelectorTree: React.FC<IAcxHierarchyComponentProps> = observer(
    (props: IAcxHierarchyComponentProps) => {
        return (
            <Observer>
                {() => (
                    <Grid
                        item
                        sx={{
                            height: props.fullHeight ? "50vh" : "40vh",
                            overflowY: "scroll",
                            "@media (max-height: 600px)": {
                                height: "100px",
                            },
                        }}
                    >
                        {props.treeStore.isTreeLoading ? (
                            <Grid
                                item
                                container
                                justifyContent="center"
                                alignItems="center"
                                sx={(theme) => ({
                                    padding: theme.spacing(2),
                                    height: "100%",
                                })}
                            >
                                <AcxLoadingIndicator
                                    size={66}
                                    alternate="PuffLoader"
                                />
                            </Grid>
                        ) : (
                            <AcxRecursiveTree store={props.treeStore} />
                        )}
                    </Grid>
                )}
            </Observer>
        );
    },
);

export default HierarchySelectorTree;
