import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import { action, makeObservable, observable, runInAction } from "mobx";
import { ServiceError } from "services/BaseService";
import ClassifierService from "services/ClassifierService";
import { AuthStore } from "stores/AuthStore";
import { BaseStore } from "stores/BaseStore";
import { DialogComponentStore } from "stores/ComponentStores/DialogComponentStore";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";
import { ClassifierBuilderStore } from "../ClassifierBuilderStore";

class EditorDialog extends DialogComponentStore {
    @observable classifierName: string;

    resetDialog = () => {
        this.classifierName = "";
    };

    @action setClassifierName = (newName: string) => {
        this.classifierName = newName;
    };

    constructor() {
        // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
        super();

        makeObservable(this);
    }
}

async function tryParseJSON(
    response: Response,
): Promise<{ data?: any; error?: string }> {
    const rawResponse = await response.text();

    if (rawResponse.startsWith("{") && rawResponse.endsWith("}")) {
        try {
            return { data: JSON.parse(rawResponse) };
        } catch (jsonParseError) {
            console.error("Failed to parse JSON:", jsonParseError);
            return { error: "Parsing error" };
        }
    } else {
        console.error("Response is not valid JSON:", rawResponse);
        return { error: rawResponse };
    }
}

const classifierService = new ClassifierService();

@AcxStore
export class ClassifierBuilderEditorStore extends BaseStore {
    messageStore: MessageStore;
    cbStore: ClassifierBuilderStore;
    dialog = new EditorDialog();
    updateDialog = new EditorDialog();

    @observable editorQuery: string = "";
    @observable newClassifierName = "";
    @observable errorMessage: string = "";

    readonly authStore: AuthStore;

    constructor(public rootStore: IRootStore) {
        super("ClassifierBuilderStore");

        makeObservable(this);

        this.authStore = rootStore.getStore(AuthStore);
        this.messageStore = rootStore.getStore(MessageStore);
        this.cbStore = rootStore.getStore(ClassifierBuilderStore);
    }

    @action setNewClassifierName = (newName: string) => {
        this.newClassifierName = newName;
    };

    @action setEditorQuery = (newQuery: string) => {
        this.editorQuery = newQuery;
    };

    @action createClassifier = async () => {
        this.errorMessage = "";
        this.dialog.setLoading();
        const orgId = this.cbStore.orgSelectStore.orgId;
        try {
            const newClassifier = await classifierService.createClassifier(
                this.dialog.classifierName,
                this.editorQuery,
                orgId,
            );

            await this.cbStore.loadClassifiers();

            runInAction(async () => {
                this.dialog.setNotLoading();
                this.dialog.close();
                this.cbStore.setSelectedClassifier(newClassifier.classifier);
            });
        } catch (error) {
            const serviceError = error as ServiceError;
            const resp = await tryParseJSON(serviceError.response);

            runInAction(() => {
                this.dialog.setNotLoading();

                if (!resp.error) {
                    this.errorMessage = resp.data.message || "Unknown error";
                } else {
                    if (resp.error.includes("Lexical error")) {
                        this.messageStore.logMessage(
                            " Failed to save due to invalid syntax.",
                            "error",
                        );
                    } else {
                        this.messageStore.logMessage(
                            `${
                                this.cbStore?.selectedClassifier?.name ??
                                "Classifier"
                            } ${resp.error}.`,
                            "error",
                        );
                    }
                }
            });
        }
    };

    @action updateClassifier = async () => {
        this.errorMessage = "";
        this.updateDialog.setLoading();

        if (!this.cbStore.selectedClassifier) {
            throw new Error("Please select a classifier to update.");
        }

        try {
            const updatedClassifier = await classifierService.updateClassifier(
                this.newClassifierName !== ""
                    ? this.newClassifierName
                    : this.cbStore.selectedClassifier?.name,
                this.authStore._user.profile.email ?? "",
                this.editorQuery,
                this.cbStore.selectedClassifier.id,
            );

            runInAction(() => {
                this.updateDialog.setNotLoading();
                this.updateDialog.close();
                this.cbStore.loadClassifiers();
                this.cbStore.setSelectedClassifier(updatedClassifier);
            });

            this.messageStore.logMessage(
                `${
                    this.cbStore.selectedClassifier.name ?? "Classifier"
                } successfully updated.`,
                "success",
            );

            return updatedClassifier;
        } catch (error) {
            const serviceError = error as ServiceError;
            const resp = await tryParseJSON(serviceError.response);

            runInAction(() => {
                this.updateDialog.setNotLoading();

                if (!resp.error) {
                    this.errorMessage = resp.data.message || "Unknown error";
                } else {
                    if (resp.error.includes("Lexical error")) {
                        this.messageStore.logMessage(
                            `${
                                this.cbStore?.selectedClassifier?.name ??
                                "Classifier"
                            } Failed to update due to invalid syntax.`,
                            "error",
                        );
                    } else {
                        this.messageStore.logMessage(
                            `${
                                this.cbStore?.selectedClassifier?.name ??
                                "Classifier"
                            } ${resp.error}.`,
                            "error",
                        );
                    }
                }
            });
        }
    };
}
