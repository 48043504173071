import { Divider, Grid, styled } from "@mui/material";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import { useStore } from "../../../../utils/useStore";
import AcxMainTextField from "../../../UI/AcxMainTextFieldGrid";
import AcxSelectMulti from "../../../UI/Select/BaseSelectComponents/AcxSelectMulti";
import { EvalReviewStore } from "../Stores/EvalReviewStore";
import AcxButton from "components/UI/AcxButton";
import SparklingSvg from "SvgIcons/SparklingSvg";
import { AuthStore } from "stores/AuthStore";

interface IReviewDetailsProps {
    isCoached: boolean;
}

const GenerateNotesButton = styled(AcxButton)(({ theme }) => ({
    background:
        "linear-gradient(131deg, #32D1B7 3.43%, #349AC7 70.89%, #3664D6 100.5%)",
    paddingInline: theme.spacing(2),
    paddingBlock: theme.spacing(1),
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 600,
}));

const ReviewDetails: FunctionComponent<IReviewDetailsProps> = observer(
    (props) => {
        const store = useStore(EvalReviewStore);
        const authStore = useStore(AuthStore);

        function onImproveNotesChange(
            evt: React.ChangeEvent<HTMLInputElement>,
        ) {
            const val = evt.target.value;
            store.agentCoaching?.setImprovementNotes(val);
        }

        function onDoWellChange(evt: React.ChangeEvent<HTMLInputElement>) {
            const val = evt.target.value;
            store.agentCoaching?.setDoWellNotes(val);
        }

        function onResponseNotesChange(
            evt: React.ChangeEvent<HTMLInputElement>,
        ) {
            const val = evt.target.value;
            store.agentCoaching?.setResponseNotes(val);
        }

        return (
            <Grid container item direction="column" flexWrap="nowrap">
                <Grid item padding={2}>
                    <AcxMainTextField
                        id={"eval-coaching-do-well-notes"}
                        multiLine
                        rows={1}
                        rowsMax={6}
                        labelText={"What did the agent do well?"}
                        placeholderText="Add feedback..."
                        disabled={props.isCoached}
                        onChange={onDoWellChange}
                        value={store.agentCoaching?.agentDoWellNotes ?? ""}
                        sx={(theme) => ({
                            padding: theme.spacing(2),
                        })}
                        labelSx={(theme) => ({
                            color: theme.palette.gray[600],
                            fontSize: "14px",
                            fontWeight: 600,
                            lineHeight: "20px",
                        })}
                        textContainerSx={(theme) => ({
                            borderColor: theme.palette.gray[200],
                            ":focus-within": {
                                borderColor: theme.palette.primary.main,
                            },
                        })}
                    />
                </Grid>
                <Divider />
                <Grid item padding={2}>
                    <AcxMainTextField
                        id={"eval-coaching-improvement-notes"}
                        multiLine
                        rows={1}
                        rowsMax={6}
                        labelText={"What areas of opportunity exist?"}
                        placeholderText="Add feedback..."
                        disabled={props.isCoached}
                        onChange={onImproveNotesChange}
                        value={store.agentCoaching?.improvementNotes ?? ""}
                        sx={(theme) => ({
                            padding: theme.spacing(2),
                        })}
                        labelSx={(theme) => ({
                            color: theme.palette.gray[600],
                            fontSize: "14px",
                            fontWeight: 600,
                            lineHeight: "20px",
                        })}
                        textContainerSx={(theme) => ({
                            borderColor: theme.palette.gray[200],
                            ":focus-within": {
                                borderColor: theme.palette.primary.main,
                            },
                        })}
                    />
                </Grid>
                <Divider />
                <Grid item padding={2}>
                    <AcxSelectMulti
                        options={store.focusAreaQuestions ?? []}
                        id={"eval-coaching-focus-areas-selector"}
                        containerHeight={"auto"}
                        valueField={"value"}
                        fullWidth
                        isDisabled={props.isCoached}
                        onChange={store.agentCoaching?.setFocusAreas}
                        defaultValue={store.agentCoaching?.focusAreaQuestions}
                        labelField={"value"}
                        inputLabel={"Focus Areas"}
                    />
                </Grid>
                {props.isCoached && (
                    <React.Fragment>
                        <Divider />
                        <Grid item padding={2}>
                            <AcxMainTextField
                                id={"agent-coaching-response-notes"}
                                multiLine
                                rows={1}
                                rowsMax={6}
                                labelText={"Agent Response"}
                                onChange={onResponseNotesChange}
                                placeholderText={"Comment NA for no response"}
                                value={store.agentCoaching?.responseNotes ?? ""}
                                sx={(theme) => ({
                                    padding: theme.spacing(2),
                                })}
                                labelSx={(theme) => ({
                                    color: theme.palette.gray[600],
                                    fontSize: "14px",
                                    fontWeight: 600,
                                    lineHeight: "20px",
                                })}
                                textContainerSx={(theme) => ({
                                    borderColor: theme.palette.gray[200],
                                    ":focus-within": {
                                        borderColor: theme.palette.primary.main,
                                    },
                                })}
                            />
                        </Grid>
                    </React.Fragment>
                )}
                {authStore.isUserUltra() && !props.isCoached && (
                    <Grid container item padding={2} justifyContent="flex-end">
                        <Grid item>
                            <GenerateNotesButton
                                leftRightSpacing={0}
                                fullWidth={false}
                                startIcon={
                                    <SparklingSvg
                                        width={20}
                                        height={20}
                                        color="#FFFFFF"
                                        gradient={false}
                                    />
                                }
                                buttonDisabled={
                                    store.hasGeneratedCoachingNotes ||
                                    store.targetedEvaluations?.length !== 1 ||
                                    store.getTaskLoading(
                                        "Load Single Evaluation Review",
                                    )
                                }
                                loading={store.getTaskLoading(
                                    "Generate Coaching Notes",
                                )}
                                onClick={() => store.generateCoachingNotes()}
                            >
                                Generate Notes
                            </GenerateNotesButton>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        );
    },
);

export default ReviewDetails;
