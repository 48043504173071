import { Grid, Typography } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import Classifier, { ClassifierType } from "../../../../models/ClassifierModel";
import useStyles from "../../../../Styles/Styles";
import AcxMultiColumnCard from "../../../UI/Cards/AcxMultiColumnCard";
import { DataSelection } from "../ClassifierReportStore";

const styles = () => {
    return createStyles({
        root: {
            height: "100%",
            width: "100%",
        },
        cardHeader: {
            height: "10%",
            width: "100%",
        },
        exportButton: {
            paddingBottom: "6px",
        },
        mainContent: {
            height: "90%",
            width: "100%",
        },
    });
};

interface IReportCardProps {
    classifier: Classifier;
    reportTitle: string | null;
    isLoading?: boolean;
    statusIndicator?: React.ReactNode;
    graphComponent: React.ReactNode;
    graphComponentError?: React.ReactNode;
    wordCloudComponent: React.ReactElement;
    wordCloudComponentError?: React.ReactElement;
    expansionInfoComponent: React.ReactNode;
    expansionInfoComponentError?: React.ReactNode;
    topNcomponent: React.ReactNode;
    wordCloudSelection: DataSelection;
    setWordCloudSelection: (arg0: DataSelection) => void;
    onExport?: () => void;
}

const ReportCard: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<IReportCardProps>>> = observer((props) => {
    const classes = useStyles(styles);

    return (
        <>
            <Grid item xs={12} className={classes.root}>
                <Grid
                    item
                    xs={12}
                    container
                    className={classes.cardHeader}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="baseline"
                >
                    <Grid item>
                        <Typography variant={"h1"}>
                            {props.reportTitle}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item className={classes.mainContent}>
                    <AcxMultiColumnCard
                        leftChild={
                            props.graphComponentError ?? props.graphComponent
                        }
                        rightChild={
                            props.classifier.classifierTypeName ===
                            ClassifierType.Tensorflow
                                ? undefined
                                : props.wordCloudComponentError ??
                                  props.wordCloudComponent
                        }
                        anotherRightChild={
                            props.classifier.classifierTypeName ===
                            ClassifierType.Tensorflow
                                ? undefined
                                : props.topNcomponent
                        }
                        bottomChild={
                            props.expansionInfoComponentError ??
                            props.expansionInfoComponent
                        }
                        statusIndicator={props.statusIndicator}
                        isLoading={props.isLoading}
                        height={"auto"}
                    />
                </Grid>
            </Grid>
        </>
    );
});

export default ReportCard;
