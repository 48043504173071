import { Breadcrumbs, Tooltip, Typography } from "@mui/material";
import { GridValueFormatterParams } from "@mui/x-data-grid-pro";
import { isObject } from "lodash";
import React from "react";

export const BreadCrumbFormatter = (
    params: GridValueFormatterParams | string,
) => {
    function formatter(args?: string[]) {
        let toolTip = "";
        if (Array.isArray(args) && args.length > 0) {
            toolTip = args.join(" > ");
        }

        return (
            <Tooltip title={toolTip}>
                <Breadcrumbs
                    style={{ whiteSpace: "nowrap" }}
                    separator="›"
                    aria-label="breadcrumb"
                >
                    {args?.map((member) => {
                        return (
                            <Typography key={`breadcrumb_${member}`}>
                                {member}
                            </Typography>
                        );
                    })}
                </Breadcrumbs>
            </Tooltip>
        );
    }

    let values: string[];

    if (isObject(params)) {
        values = params.getValue(params.id, params.field) as any;
        if (Array.isArray(values)) {
            return formatter(values.filter((value, index) => !!value));
        }
    }

    return null;
};
