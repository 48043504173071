import Page from "components/Navigation/Page";
import React, { useEffect, useState } from "react";
import {
    WorkflowLoading,
    WorkflowReviewStore,
} from "./Stores/WorkflowReviewStore";
import { useStore } from "utils/useStore";
import { observer } from "mobx-react";
import { useLocation } from "react-router";
import Organization from "models/Organization";
import WFRAppBar from "./Components/WFRAppBar";
import WFRStepper from "./Components/WFRStepper";
import WFRCapaModuleCard from "./Components/WFRCapaModuleCard";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import WFRTriggerCard from "./Components/WFRTriggerCard";
import AcxSnackBar from "components/UI/AcxSnackBar";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import { Box } from "@material-ui/core";
import Scrollbars from "react-custom-scrollbars-2";
import { InteractionType } from "models/InteractionType";
import WFRSoundClipEditorDock from "./Components/WFRSoundClipEditorDock";

const WFR: React.FC = observer(() => {
    const store = useStore(WorkflowReviewStore);
    const messageStore = useStore(MessageStore);
    const location = useLocation();

    const [open, setOpen] = useState(false);
    const onToggleOpen = () => {
        setOpen(!open);
    };

    useEffect(() => {
        const segments = location.pathname.split("/");
        const orgId = segments[2];
        const workflowId = segments[3];

        if (orgId && workflowId) {
            store.initWorkflowReviewStore(workflowId, orgId);
            store.authStore.orgStore.onActiveOrgIdChange(orgId);
            store.authStore.orgStore?.setGlobalOrganization(
                store.authStore.orgStore.organizations?.find(
                    (org) => org.id === orgId,
                )! as Organization,
            );
        }
    }, [
        store.authStore._isAuthenticated,
        store.authStore.activeOrgId,
        store.authStore.orgStore,
        location.pathname,
        location.search,
        store,
    ]);

    useEffect(() => {
        return () => {
            store.resetWorkflowStore();
        };
    }, [store]);

    const pageLoading = store.getTaskLoadingV2(WorkflowLoading);

    const boxHeight = open
        ? "calc(100vh - 750px)"
        : `calc(100vh - ${
              store.clipAudioMetadata?.mediaType === InteractionType.Chat ||
              store.clipAudioMetadata?.mediaType ===
                  InteractionType.ProcessedChat
                  ? 476
                  : store.clipAudioMetadata?.mediaType === InteractionType.Audio
                  ? 378
                  : 220
          }px)`;

    return (
        <Page title="Workflow Review">
            <AcxSnackBar
                open={messageStore.showMessage}
                onClose={messageStore.showNextMessage}
                message={messageStore.currentMessage?.text || ""}
                severity={messageStore.currentMessage?.severity}
                options={messageStore.currentMessage?.options}
            />
            <WFRAppBar />
            <WFRStepper />
            {pageLoading ? (
                <AcxLoadingIndicator
                    color={"secondary"}
                    alternate={"PuffLoader"}
                    size={175}
                />
            ) : (
                <Box
                    sx={{
                        height: boxHeight,
                    }}
                >
                    <Scrollbars>
                        <WFRTriggerCard />
                        <WFRCapaModuleCard navigateBack={() => {}} />
                    </Scrollbars>
                </Box>
            )}
            <WFRSoundClipEditorDock
                soundClipEditorStore={store.soundClipEditorStore}
                open={open}
                onToggleOpen={onToggleOpen}
                mediaType={store.clipAudioMetadata?.mediaType}
                transcriptWords={store.transcriptionData?.words}
                eddyEffectP2Data={store.eddyEffectP2Data}
                hasEvaluation
                records={store.chatTranscription}
            />
        </Page>
    );
});

export default WFR;
