import {
    GridAddIcon,
    GridFilterItem,
    GridLinkOperator,
    GridPanelContent,
    GridPanelFooter,
    GridPanelWrapper,
    useGridSlotComponentProps,
} from "@mui/x-data-grid-pro";
import AcxButton from "components/UI/AcxButton";
import * as React from "react";
import AcxDataGridStore from "../AcxDataGridStore";
import { GridFilterForm } from "./FilterForm";

interface IGridFilterPanel {
    store: AcxDataGridStore;
}

export default function GridFilterPanel(props: IGridFilterPanel) {
    const { apiRef } = useGridSlotComponentProps();
    const rootProps = useGridSlotComponentProps();
    const gridState = rootProps.state;

    const hasMultipleFilters = React.useMemo(
        () => gridState.filter.items.length > 1,
        [gridState.filter.items.length],
    );

    const applyFilter = React.useCallback(
        (item: GridFilterItem) => {
            apiRef!.current.upsertFilter?.(item);
        },
        [apiRef],
    );

    const applyFilterLinkOperator = React.useCallback(
        (operator: GridLinkOperator) => {
            apiRef!.current.applyFilterLinkOperator?.(operator);
        },
        [apiRef],
    );
    const addNewFilter = React.useCallback(() => {
        if (!props.store.filtered) {
            props.store.updateFiltered(true);
        }
        const columns = (apiRef!.current?.state?.options as any)?.columns;
        if (columns && columns[0].type === "number") {
            columns[0].filterOperators = columns[0].filterOperators.sort(
                (filterOp) => {
                    return filterOp.label === "!=" ? -1 : 1;
                },
            );
        }
        apiRef!.current.upsertFilter?.({});
    }, [apiRef, props.store]);

    const deleteFilter = React.useCallback(
        (item: GridFilterItem) => {
            apiRef!.current.deleteFilter?.(item);
        },
        [apiRef],
    );
    return (
        <GridPanelWrapper>
            <GridPanelContent>
                {gridState.filter.items.map((item, index) => (
                    <GridFilterForm
                        key={item.id == null ? index : item.id}
                        item={item}
                        applyFilterChanges={applyFilter}
                        deleteFilter={deleteFilter}
                        hasMultipleFilters={hasMultipleFilters}
                        showMultiFilterOperators={index > 0}
                        multiFilterOperator={gridState.filter.linkOperator}
                        disableMultiFilterOperator={index !== 1}
                        applyMultiFilterOperatorChanges={
                            applyFilterLinkOperator
                        }
                        store={props.store}
                    />
                ))}
            </GridPanelContent>
            {!rootProps.options.disableMultipleColumnsFiltering && (
                <GridPanelFooter
                    style={
                        gridState.filter.items.length
                            ? { justifyContent: "flex-end" }
                            : { justifyContent: "center" }
                    }
                >
                    <AcxButton
                        onClick={addNewFilter}
                        startIcon={<GridAddIcon />}
                        color="primary"
                        fullWidth={false}
                    >
                        {apiRef
                            ? apiRef.current?.getLocaleText?.(
                                  "filterPanelAddFilter",
                              )
                            : "Add Filter"}
                    </AcxButton>
                </GridPanelFooter>
            )}
        </GridPanelWrapper>
    );
}
