import { Grid, Theme, Typography } from "@mui/material";
import { chunk, maxBy } from "lodash";
import {
    action,
    computed,
    flow,
    makeObservable,
    observable,
    reaction,
    runInAction,
    toJS,
} from "mobx";
import { chunkProcessor, computedFn } from "mobx-utils";
import moment from "moment";
import Papa from "papaparse";
import React from "react";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";
import { AgentModel } from "../../../../../models/AgentModel";
import AudioMetadataModel from "../../../../../models/AudioMetadataModel";
import AudioMetadataUiModel from "../../../../../models/AudioMetadataUiModel";
import CallBatch from "../../../../../models/CallBatch";
import type { MetadataSpec } from "../../../../../models/MetadataSpec";
import Organization from "../../../../../models/Organization";
import { OrganizationStructureLevel } from "../../../../../models/OrganizationModels/OrganizationStructureLevel";
import { OrganizationStructureMember } from "../../../../../models/OrganizationModels/OrganizationStructureMember";
import RuleSet from "../../../../../models/RuleSet";
import {
    AudioFilesService,
    SourceFile,
} from "../../../../../services/AudioFilesService";
import { AudioSamplingService } from "../../../../../services/AudioSamplingService";
import { ServiceError } from "../../../../../services/BaseService";
import {
    CallbatchService,
    ICallbatchService,
} from "../../../../../services/CallbatchService";
import { MetadataService } from "../../../../../services/MetadataService";
import { OrganizationService } from "../../../../../services/OrganizationService";
import { RuleSetService } from "../../../../../services/RuleSetService";
import { BaseStore } from "../../../../../stores/BaseStore";
import { BlobDirectoryStore } from "../../../../../stores/BlobDirectoryStore";
import { delay } from "../../../../../utils/helpers";
import hexToRGB from "../../../../../utils/hexToRGB";
import { isStringType, isUndefinedType } from "../../../../../utils/TypeGuards";
import {
    IReadFileResult,
    readTextFilesAsync,
} from "../../../AudioUploader/UploaderUtils";
import { AgentNameFormatterType as AgentFormatOptions } from "../../MetadataTemplate/MetaInput/FormatOptions";
import {
    ExtHandle,
    MetaFieldLabels,
    MetaSource,
} from "../../MetadataTemplate/Types/TemplateModel";

export const UploadSpecToServerOp = "Upload Spec To Server";
export const LoadRuleSetsOp = "Load RuleSets";
export const LoadCallBatchesOp = "Load Call Batches";
export const LoadSpecModelsOp = "Load Spec Models";
export const ReadMetaFilesOp = "Read Meta Files";
export const GenerateSampleSuggestionOp = "Generating Sample Suggestion";
export const UseSampleOp = "Uploading Sample Set";
export const MetaFromCsvForTargetFilesItem =
    "Extract MetaData From Csv For Target Files Item";
export const MetaFromCsvForTargetFilesReaction =
    "Extract MetaData From Csv For Target Files Reaction";
export const MetaFromFileNamesForTargetFilesReactionOp =
    "Extract MetaData From FileNames For Target Files Reaction";
export const GetAgentsListOp = "Load QB Agents List";
export const DeleteSpecModelTask = "Delete Spec Model";
export const LoadOragnizationHierarchiesTask = "Load Oragnization Hierarchies";

const defaultAgentFormatter = (arg: AgentModel) =>
    `${arg.firstName} ${arg.lastName}`;

const defaultSampleSize = 100;

export interface ParsedSpecModel extends MetadataSpec.IMetadataSpecModel {
    spec: MetadataSpec.ISpecType;
}

@AcxStore
export class AudioFileSamplerStore extends BaseStore {
    @observable showBuildRecommendationDialog: boolean = false;
    @observable newRecommendationReady: boolean = false;
    @observable newSpecFromBuilder: boolean = false;
    @observable uploadReady = false;
    @observable MetaFiles: File[] = [];
    @observable.shallow metaFilesContent: string[] = [];
    @observable callBatches?: CallBatch[] = [];
    @observable activeSpecModel?: ParsedSpecModel;
    @observable metadataSpecModelList: Map<string, Array<ParsedSpecModel>> =
        observable.map();
    @observable fromFileNameToMetaSpec: Map<
        string,
        Array<{ field: string; value: string }>
    > = observable.map();
    @observable fromCSVToMetaSpec: Map<
        string,
        Array<{ field: string; value: string }>
    > = observable.map();

    @observable metaViewDirectory?: string;
    @observable itemsMovedToTarget: Array<AudioMetadataModel> =
        observable.array();

    @observable confirmationOpen: boolean = false;

    @observable deleteSpecOpen: boolean = false;
    @observable _isDirListExpanded: Map<string, boolean> = observable.map();
    @observable callBatchId?: string;
    @observable orgId?: string;

    @observable.shallow ruleSets?: RuleSet[];
    @observable activeRuleSet?: RuleSet;
    @observable.shallow agentsList?: AgentModel[];
    @observable formattedAgentsMap?: Map<string, AgentModel> = observable.map();

    @observable.shallow levels: OrganizationStructureLevel[] = [];
    @observable.shallow members: OrganizationStructureMember[] = [];
    @observable hierarchySelections: OrganizationStructureMember[] = [];

    @observable sampleSize: string | number = defaultSampleSize;
    @observable onNavigatingFromRuleBuilder = false;
    @observable org?: Organization;
    @observable showSamplingDialog: boolean = false;

    samplingDirectory?: string;

    isFromOutsideSampler = false;

    private fromCSVToMetaSpecTemp: Map<
        string,
        Array<{ field: string; value: string }>
    > = new Map<string, Array<{ field: string; value: string }>>();
    private fromFileNameToMetaSpecTemp: Map<
        string,
        Array<{ field: string; value: string }>
    > = new Map<string, Array<{ field: string; value: string }>>();
    private lastCsvMetaSpec?: string;
    private lastFileNameMetaSpec?: string;
    private activeRuleSetIdFromBuilder?: string;

    private readonly audioFileService = new AudioFilesService();
    private readonly ruleSetService = new RuleSetService();
    private readonly callbatchService: ICallbatchService =
        new CallbatchService();
    private readonly metadataService: MetadataService = new MetadataService();
    private readonly audioSamplingService: AudioSamplingService =
        new AudioSamplingService();
    private readonly orgService: OrganizationService =
        new OrganizationService();

    constructor(public rootStore: IRootStore) {
        super("AudioFileSamplerStore");

        makeObservable(this);

        this.setLongRunningTasks(UseSampleOp, GenerateSampleSuggestionOp);

        rootStore.getStore(BlobDirectoryStore).setTargetChangeListeners(
            (...val) => {
                this.itemsMovedToTarget.push(...val);
            },
            (...val) => {
                val.forEach((m) => {
                    this.itemsMovedToTarget.splice(
                        0,
                        this.itemsMovedToTarget.length,
                        ...this.itemsMovedToTarget.filter(
                            (item) => item.fileName === m.fileName,
                        ),
                    );
                });
            },
        );
        reaction(
            (r) => this.orgId,
            (orgId) => {
                if (orgId && !this.isFromOutsideSampler) {
                    this.setupAsyncTask(LoadRuleSetsOp, () =>
                        this.loadRuleSets(this.activeRuleSetIdFromBuilder),
                    );
                    this.setupAsyncTask(LoadCallBatchesOp, () =>
                        this.loadBatches(orgId),
                    );
                    this.setupAsyncTask(LoadSpecModelsOp, () =>
                        this.loadMetaSpecsForOrg(orgId),
                    );
                    this.setupAsyncTask(LoadOragnizationHierarchiesTask, () =>
                        this.retrieveHierarchy(),
                    );
                }
            },
            { delay: 50 },
        );

        reaction(
            (r) => this.org,
            (organization) => {
                if (organization) {
                    this.setupAsyncTask(GetAgentsListOp, () =>
                        this.loadAgentsList(organization),
                    );
                }
            },
            { delay: 50 },
        );

        reaction(
            (r) => ({
                agents: this.agentsList,
                specModel: this.activeSpecModel,
            }),
            (obj) => {
                const formatFunc =
                    obj.specModel?.spec.fields?.find(
                        (value) =>
                            (value.fieldName as MetaFieldLabels) ===
                            "Agent Name",
                    )?.formatFunc ?? defaultAgentFormatter;

                const result = obj.agents?.reduce((map, obj) => {
                    map[formatFunc(obj).replace(/\s+/g, "").toLowerCase()] =
                        obj;
                    return map;
                }, observable.map() as Map<string, AgentModel>);

                this.formattedAgentsMap = result ?? observable.map();
            },
            { delay: 100 },
        );

        reaction(
            (r) => ({
                fromBuilder: this.onNavigatingFromRuleBuilder,
                orgId: this.orgId,
            }),
            (arg) => {
                if (arg.fromBuilder && !!arg.orgId) {
                    this.setupAsyncTask(LoadRuleSetsOp, () =>
                        this.loadRuleSets(this.activeRuleSetIdFromBuilder),
                    );
                }
            },
            { delay: 10 },
        );

        reaction(
            (r) => this.activeSpecModel,
            async (activeSpec) => {
                if (activeSpec?.spec.parse.type === "filename") {
                    // if (this.lastFileNameMetaSpec !== activeSpec.specName) {
                    //     this.fromFileNameToMetaSpec.clear();
                    //     this.populateMetaRecordsFromTargetFiles(activeSpec.spec);
                    // }
                    this.fromFileNameToMetaSpecTemp.clear();
                    // this.fromFileNameToMetaSpec.clear();
                    this.populateMetaRecordsFromTargetFiles(activeSpec.spec);
                    this.lastFileNameMetaSpec = activeSpec.specName;
                }
            },
            { delay: 100 },
        );

        reaction(
            (r) => ({
                fileRows: this.metaFilesContent,
                activeSpec: this.activeSpecModel,
            }),
            ({ fileRows, activeSpec }) => {
                if (
                    fileRows &&
                    fileRows.length > 0 &&
                    activeSpec?.spec.parse.type === "file"
                ) {
                    this.fromCSVToMetaSpecTemp.clear();

                    this.ExtractMetaFromCsvForTargetFiles(fileRows, activeSpec);

                    this.lastCsvMetaSpec = activeSpec.specName;
                }
            },
            { delay: 100 },
        );

        chunkProcessor(
            this.itemsMovedToTarget,
            (items) => {
                this.buildMetaRecordForItemAddedToTarget(...items);
            },
            100,
            25,
        );
    }

    @action
    setDeleteSpecOpen = () => {
        this.deleteSpecOpen = Boolean(this.activeSpecModel);
    };
    @action
    setDeleteSpecClose = () => {
        this.deleteSpecOpen = false;
    };

    @action
    setMetadataViewerDirectory(directory?: string) {
        this.metaViewDirectory = directory;
    }

    @action
    setHierarchySelection = (
        index: number,
        newValue: OrganizationStructureMember,
    ) => {
        this.hierarchySelections = this.hierarchySelections
            .slice(0, index)
            .concat([newValue])
            .filter((value) => value.name !== "No Selection");
    };

    @action
    private async retrieveHierarchy() {
        const payload = await this.orgService.getInternalStructureHierarchy();
        runInAction(() => {
            this.levels = payload.levels;
            this.members = payload.members;
        });
    }

    @action
    deleteMetadataSpec = () => {
        this.setupAsyncTask(DeleteSpecModelTask, async () => {
            if (this.activeSpecModel?.id && this.orgId) {
                const specId = this.activeSpecModel.id;
                const orgId = this.orgId;

                await this.metadataService.deleteMetadataSpec(specId);
                runInAction(() => {
                    this.setDeleteSpecClose();
                });

                await delay(50);

                runInAction(() => {
                    this.activeSpecModel = undefined;
                    this.fromFileNameToMetaSpec.clear();
                    this.fromCSVToMetaSpec.clear();
                });

                await delay(50);

                this.setupAsyncTask(LoadSpecModelsOp, () =>
                    this.loadMetaSpecsForOrg(orgId),
                );
            }
        });
    };

    @computed
    get sampleSizeAsString() {
        if (Number.isNaN(+this.sampleSize)) {
            return defaultSampleSize.toString(10);
        } else {
            return this.sampleSize.toString(10);
        }
    }

    @action
    setSampleSize(sampleSize: string) {
        sampleSize = (sampleSize ?? "").trim();
        if (sampleSize === "") {
            this.sampleSize = sampleSize;
            return;
        }

        try {
            let size = parseInt(sampleSize, 10);
            if (Number.isNaN(size) || size < 0) {
                size = 0;
            }

            this.sampleSize = size;
        } catch (err) {
            this.sampleSize = defaultSampleSize;
            console.error("sample size conversion to int failed: " + err);
        }
    }

    @computed
    get formattedArrivedOnDateRange() {
        const blobDirectoryStore = this.rootStore.getStore(BlobDirectoryStore);
        const start = blobDirectoryStore.beginDate.format("MM/DD/YYYY");
        const end = blobDirectoryStore.endDate.format("MM/DD/YYYY");
        return `${start} - ${end}`;
    }

    @action
    setOnNavFromBuilder = (rulesetId?: string) => {
        this.activeRuleSetIdFromBuilder = rulesetId;
        this.onNavigatingFromRuleBuilder = true;
    };

    ExtractMetaFromCsvForTargetFiles = (
        fileRows: string[],
        activeSpec?: ParsedSpecModel,
        targetArray?: Array<AudioMetadataModel>,
    ) => {
        let Rows = fileRows;
        for (let item of targetArray ??
            this.rootStore.getStore(BlobDirectoryStore).TargetList.children) {
            for (let fileRow of Rows) {
                if (
                    this.buildMetaRecordFromFileRow(
                        fileRow,
                        activeSpec?.spec,
                        item.fileName,
                    )
                ) {
                    Rows = Rows.filter((value) => value !== fileRow);
                    break;
                }
            }
        }
        this.fromCSVToMetaSpec = this.fromCSVToMetaSpecTemp;
    };

    async buildMetaRecordForItemAddedToTarget(...items: AudioMetadataModel[]) {
        if (isUndefinedType(this.activeSpecModel)) {
            return;
        }

        if (this.activeSpecModel.spec.parse?.type === "filename") {
            for (let item of items) {
                this.buildMetaRecordFromFileName(
                    item,
                    this.activeSpecModel.spec,
                );
            }

            this.fromFileNameToMetaSpec = this.fromFileNameToMetaSpecTemp;
        } else if (this.activeSpecModel.spec.parse?.type === "file") {
            if (this.metaFilesContent) {
                for (let item of items) {
                    const transformedFileName = this.transformFilenameToSpec(
                        item.fileName,
                    );

                    this.ExtractMetaFromCsvForTargetFiles(
                        this.metaFilesContent ?? [],
                        this.activeSpecModel,
                        [
                            {
                                fileName: transformedFileName,
                            } as AudioMetadataModel,
                        ],
                    );
                }
            }
        }
    }

    @action
    prepareStoreForOrgSwitch() {
        this.cancelAllTasks();

        this.itemsMovedToTarget.splice(0, this.itemsMovedToTarget.length);
        this.fromFileNameToMetaSpec.clear();
        this.fromCSVToMetaSpec.clear();
        this.callBatchId = undefined;
        this.activeRuleSet = undefined;
        this.activeSpecModel = undefined;
        this.samplingDirectory = undefined;
    }

    @action
    private analyzeMetaSpec(metaSpec: MetadataSpec.ISpecType) {
        if ((metaSpec.parse?.type as MetaSource) === "filename") {
            this.populateMetaRecordsFromTargetFiles(metaSpec);
        } else if ((metaSpec.parse?.type as MetaSource) === "file") {
            this.populateMetaRecordsFromCSV(metaSpec);
        }
    }

    @action
    private async populateMetaRecordsFromTargetFiles(
        metaSpec: MetadataSpec.ISpecType,
    ) {
        let t0 = performance.now();
        for (let value of this.rootStore.getStore(BlobDirectoryStore).TargetList
            .children) {
            this.buildMetaRecordFromFileName(value, metaSpec);
            const t1 = performance.now();
            if (t1 - t0 > 30) {
                t0 = t1;
                await delay(30);
            }
        }
        this.fromFileNameToMetaSpec = this.fromFileNameToMetaSpecTemp;
    }

    @action
    populateMetaRecordsFromCSV(metaSpec: MetadataSpec.ISpecType) {
        this.metaFilesContent?.forEach((value, index) => {
            this.buildMetaRecordFromFileRow(value, metaSpec);
        });
    }

    @action
    private buildMetaRecordFromFileRow(
        fileRow: string,
        metaSpec?: MetadataSpec.ISpecType,
        fileNameToMatch?: string,
    ) {
        if (!metaSpec) {
            return;
        }
        const parse = Papa.parse(fileRow, {
            quoteChar: '"',
            delimiter: metaSpec.parse?.delimiter ?? ",",
        }).data?.[0];

        if (!parse) {
            return;
        }
        const parsed = parse as [];

        if ((parsed.length ?? 0) === 0) {
            return;
        }

        if (
            !metaSpec.fields?.length ||
            metaSpec.fields.length > parsed.length
        ) {
            return;
        }

        let fileName = "";
        const fields: any[] = [];
        for (let fieldSpec of metaSpec.fields) {
            if ((fieldSpec.fieldName as MetaFieldLabels) === "Filename") {
                fileName = parsed[fieldSpec.columnLocation];

                if (
                    fileNameToMatch &&
                    fileName !==
                        this.transformFilenameToSpec(fileNameToMatch) &&
                    fileName !== fileNameToMatch
                ) {
                    return;
                }
            }

            fields.push({
                field: fieldSpec.fieldName,
                value: parsed[fieldSpec.columnLocation],
            });
        }

        if (
            !fileName ||
            fields.filter((f) => !f.value).length >= metaSpec.fields.length - 1
        ) {
            return;
        }

        this.fromCSVToMetaSpecTemp.set(fileName, fields);

        return fileName;
    }

    @action
    private buildMetaRecordFromFileName(
        value: AudioMetadataModel,
        metaSpec: MetadataSpec.ISpecType,
    ) {
        const transformedFilename = this.transformFilenameToSpec(
            value.fileName,
        );

        const parse = Papa.parse(transformedFilename, {
            quoteChar: '"',
            delimiter: metaSpec.parse?.delimiter,
        }).data?.[0];
        if (!parse) {
            return;
        }
        const parsed = parse as [];

        if ((parsed.length ?? 0) === 0) {
            return;
        }

        if (
            !metaSpec.fields?.length ||
            metaSpec.fields.length > parsed.length
        ) {
            return;
        }

        const fields = metaSpec.fields.map((fieldSpec) => {
            return {
                field: fieldSpec.fieldName,
                value: parsed[fieldSpec.columnLocation],
            };
        });

        this.fromFileNameToMetaSpecTemp.set(value.fileName, fields);
        return value.fileName;
    }

    private extractFieldsFromMetaRecord(
        metaRecord?: Array<{ field: string; value: string }>,
    ) {
        return [
            metaRecord?.find(
                (value1) => (value1.field as MetaFieldLabels) === "Filename",
            ),
            metaRecord?.find(
                (value1) =>
                    (value1.field as MetaFieldLabels) === "Interaction Date",
            ),
            metaRecord?.find(
                (value1) => (value1.field as MetaFieldLabels) === "Agent Name",
            ),
            metaRecord?.find(
                (value1) =>
                    (value1.field as MetaFieldLabels) === "Call Direction",
            ),
        ];
    }

    getMetaRecordForFileNameV2 = computedFn(
        function (
            this: AudioFileSamplerStore,
            audioMetadataModel: AudioMetadataUiModel,
        ) {
            const origFileName = audioMetadataModel.fileName;
            const transformedFileName =
                this.transformFilenameToSpec(origFileName);

            if (
                (this.activeSpecModel?.spec.parse.type as MetaSource) === "file"
            ) {
                let [fileName, metaRecord] = [
                    transformedFileName,
                    this.fromCSVToMetaSpec.get(transformedFileName),
                ] as const;

                if (!metaRecord) {
                    [fileName, metaRecord] = [
                        origFileName,
                        this.fromCSVToMetaSpec.get(origFileName),
                    ] as const;
                }

                return { fileName, metaRecord, audioMetadataModel };
            } else {
                let [fileName, metaRecord] = [
                    transformedFileName,
                    this.fromFileNameToMetaSpec.get(transformedFileName),
                ] as const;

                if (!metaRecord) {
                    [fileName, metaRecord] = [
                        origFileName,
                        this.fromFileNameToMetaSpec.get(origFileName),
                    ] as const;
                }

                return { fileName, metaRecord, audioMetadataModel };
            }
        },
        { name: "GetMetaRecordsForFileName" },
    );

    getFormattedMetaContentForRecordV2 = computedFn(
        function (
            this: AudioFileSamplerStore,
            {
                fileName,
                metaRecord,
                audioMetadataModel,
            }: {
                fileName: string;
                metaRecord?: Array<{ field: string; value: string }>;
                audioMetadataModel: AudioMetadataUiModel;
            },
            theme: Theme,
        ) {
            const [fileField, interactionDate, agentName, callDirection] =
                this.extractFieldsFromMetaRecord(metaRecord);

            let displayDate = interactionDate?.value;
            if (interactionDate) {
                const metaSpecDateTime = this.metadataSpec?.fields.find(
                    (field) =>
                        (field.fieldName as MetaFieldLabels) ===
                        "Interaction Date",
                );

                const dateTimeFormat = metaSpecDateTime?.format;

                const parsedDt = moment(interactionDate?.value, dateTimeFormat);

                displayDate = parsedDt.format(moment.HTML5_FMT.DATE);
                // interactionDate.value = parsedDt.format(moment.HTML5_FMT.DATETIME_LOCAL);
                //
                // if (metaSpecDateTime) {
                //     metaSpecDateTime.format = moment.HTML5_FMT.DATETIME_LOCAL;
                // }
            }

            return (
                <Grid
                    container
                    wrap={"wrap"}
                    direction="row"
                    alignContent={"flex-start"}
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <Grid item lg={3} md={6} xs={6}>
                        <span style={{ fontWeight: "bold" }}>File</span>
                        <Typography
                            style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {fileField?.value || fileName}
                        </Typography>
                    </Grid>

                    {(interactionDate?.field ||
                        audioMetadataModel.timestamp) && (
                        <Grid item lg md={6} xs={6}>
                            <span style={{ fontWeight: "bold" }}>Date</span>
                            <Typography
                                style={{
                                    color: theme.palette.info.main,
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                }}
                            >
                                {" "}
                                {displayDate ||
                                    moment(audioMetadataModel.timestamp).format(
                                        moment.HTML5_FMT.DATE,
                                    )}
                            </Typography>
                        </Grid>
                    )}

                    {(agentName?.field || audioMetadataModel.agentName) && (
                        <Grid item lg md xs={6}>
                            <span style={{ fontWeight: "bold" }}>Agent</span>
                            <Typography
                                style={{
                                    color: this.formattedAgentsMap?.[
                                        (
                                            agentName?.value ||
                                            audioMetadataModel.agentName
                                        )
                                            ?.replace(/\s+/g, "")
                                            ?.toLowerCase() ?? "no-agent"
                                    ]
                                        ? theme.palette.info.main
                                        : hexToRGB(
                                              theme.palette.red.main,
                                              0.54,
                                          ),
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                }}
                            >
                                {" "}
                                {agentName?.value ||
                                    audioMetadataModel.agentName}
                            </Typography>
                        </Grid>
                    )}

                    {(callDirection?.field ||
                        audioMetadataModel.callDirection) && (
                        <Grid item lg md xs={6}>
                            <span style={{ fontWeight: "bold" }}>Call</span>
                            <Typography
                                style={{
                                    color: theme.palette.info.main,
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                }}
                            >
                                {callDirection?.value ||
                                    audioMetadataModel.callDirection}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            );
        },
        { name: "getFormattedMetaContentForRecord" },
    );

    loadAgentsList = flow(function* (
        this: AudioFileSamplerStore,
        org: Organization,
    ) {
        yield delay(80);
        const agentsList = yield this.metadataService.agentsList(org);
        yield delay(15);
        this.agentsList = observable.array(agentsList);
    });

    @action
    loadRuleSets = flow(function* (
        this: AudioFileSamplerStore,
        ruleSetId?: string,
    ) {
        const rulesets = yield this.ruleSetService.getRuleSets();
        yield delay(8);
        this.ruleSets = rulesets;
        if (ruleSetId) {
            const selectedRS = this.ruleSets?.find((r) => r.id === ruleSetId);
            this.setActiveRuleSet(selectedRS);
        }
    });

    @action
    loadBatches = flow(function* (this: AudioFileSamplerStore, orgId: string) {
        yield delay(1500);
        const batches = yield this.callbatchService.listCallbatches(orgId);
        yield delay(0);
        this.callBatches = batches;
    });

    @action
    startBuildingRecommendation(directory: string) {
        if (
            !this.orgId ||
            !this.activeSpecModel?.specName ||
            !this.activeRuleSet ||
            !this.org
        ) {
            throw new Error(
                "OrgId, RuleSetId, and SpecName required for sampling",
            );
        }

        this.setupAsyncTask(GetAgentsListOp, () =>
            this.loadAgentsList(this.org!),
        );
        this.samplingDirectory = directory;
        this.showBuildRecommendationDialog = true;
    }

    @action
    closeBuildRecommendDialog() {
        this.showBuildRecommendationDialog = false;
    }

    @action
    finishSampling = () => {
        this.newRecommendationReady = false;
        this.samplingDirectory = undefined;
        const blobDirectoryStore = this.rootStore.getStore(BlobDirectoryStore);
        blobDirectoryStore.clearRecommendList();

        delay(300)
            .then((value) => blobDirectoryStore.reset())
            .then((value) => blobDirectoryStore.getDirs(this.orgId!))
            .catch((reason) => console.error(reason));
    };

    @action
    closeSamplingDialog = () => {
        this.showSamplingDialog = false;
        this.clearLastTaskError();
    };
    @action
    showStartSamplingDialog = () => {
        this.showSamplingDialog = true;
    };

    useSample = () => {
        this.setupAsyncTask(UseSampleOp, () => this.useSampleInternal());
    };

    useSampleInternal = async () => {
        const blobDirectoryStore = this.rootStore.getStore(BlobDirectoryStore);

        const recommendationList =
            blobDirectoryStore.RecommendedList?.children?.filter(
                (audiometa) => audiometa.includedInSample && audiometa.fileName,
            ) ?? [];

        const sourceFileArray = recommendationList?.map(
            (value) =>
                ({
                    SourceFolder: this.samplingDirectory,
                    FileName: value.fileName,
                    Id: value.id,
                    DirectoryId: value?.origDirectoryId,
                } as SourceFile),
        );

        const orgId = this.orgId;

        let orgStructMemberId: string | undefined = undefined;
        if (this.hierarchySelections && this.hierarchySelections.length > 0) {
            orgStructMemberId =
                this.hierarchySelections[this.hierarchySelections.length - 1]
                    ?.id;
        }

        if (!orgId) {
            throw new Error(`Invalid Organization Id`);
        }

        if (!sourceFileArray || !sourceFileArray.length) {
            this.finishSampling();
            return;
        }

        const chunkSize = 25;
        const chunks = chunk(sourceFileArray, chunkSize);

        let isAnyErrors: boolean[] = [];
        let statusIndx = 0;

        function updateRecommendListStatus(value: string, indx: number) {
            runInAction(() => {
                if (value) {
                    recommendationList[indx].currentStatus = value;
                } else {
                    recommendationList[indx].currentStatus = "Complete";
                }
            });
        }

        for (const subArray of chunks) {
            if (subArray) {
                let resultsArray: string[] = [];

                try {
                    const rv: string[] =
                        await this.audioFileService.selectAudioFilesSample(
                            orgId,
                            orgStructMemberId,
                            subArray,
                        );
                    resultsArray = rv;
                } catch (err) {
                    if (err instanceof ServiceError) {
                        const errMsg = err.message;
                        resultsArray = subArray.map((value) => errMsg);
                    } else {
                        resultsArray = subArray.map(
                            (value) => "Network Failure",
                        );
                    }
                }
                isAnyErrors.push(resultsArray.some((value) => Boolean(value)));

                for (const value of resultsArray) {
                    if (recommendationList[statusIndx]) {
                        updateRecommendListStatus(value, statusIndx);
                    }
                    statusIndx += 1;
                }
            }
        }

        if (!isAnyErrors.some((value) => value)) {
            this.finishSampling();
        }

        runInAction(() => {
            this.showSamplingDialog = false;
        });
    };

    startBuildingSampleSuggestion = () => {
        this.setupAsyncTask(GenerateSampleSuggestionOp, () =>
            this.startBuildingSampleSuggestionInternal(),
        );
    };

    startBuildingSampleSuggestionInternal = flow(function* (
        this: AudioFileSamplerStore,
    ) {
        if (!this.orgId) {
            throw new Error("OrgId required");
        }

        if (!this.activeRuleSet?.id) {
            throw new Error("Active RuleSet Id required");
        }

        if (!this.activeSpecModel?.specName) {
            throw new Error("Active Spec Name required");
        }
        if (!this.samplingDirectory) {
            throw new Error("Sampling Directory required");
        }

        if (this.sampleSize === "") {
            return;
        }

        let orgStructMemberId: null | string = null;
        if (this.hierarchySelections && this.hierarchySelections.length > 0) {
            orgStructMemberId =
                this.hierarchySelections[this.hierarchySelections.length - 1]
                    ?.id;
        }

        const orgId = this.orgId;
        const rulesetId = this.activeRuleSet.id;
        const specName = this.activeSpecModel.specName;
        const samplingDirectory = this.samplingDirectory;

        const blobDirectoryStory = this.rootStore.getStore(BlobDirectoryStore);

        yield delay(100);

        yield blobDirectoryStory.clearTarget();

        yield delay(0);

        let csvContents;
        if ((this.activeSpecModel.spec.parse.type as MetaSource) === "file") {
            csvContents = this.metaFilesContent?.join("\n") ?? "";
        }

        const samplingSuggestion =
            yield this.audioSamplingService.generateSamplingSuggestion(
                orgId,
                rulesetId,
                specName,
                orgStructMemberId,
                samplingDirectory,
                +this.sampleSize,
                blobDirectoryStory.beginDate.startOf("date").toISOString(),
                blobDirectoryStory.endDate.endOf("date").toISOString(),
                csvContents,
            );

        yield delay(0);

        yield blobDirectoryStory.setRecommendedList(samplingSuggestion);

        yield delay(0);

        this.newRecommendationReady = true;
    });

    loadMetaSpecsForOrg = flow(function* (
        this: AudioFileSamplerStore,
        orgId: string,
    ) {
        const metaSpeclist = yield this.metadataService.getMetadataSpecList(
            orgId,
        );

        const parsedSpecModels = observable.array(
            metaSpeclist
                ?.filter((value) => value.spec)
                .map((value) => this.specToObject(value)),
        );

        yield delay(0);

        if (parsedSpecModels.length > 0) {
            this.metadataSpecModelList.set(orgId, parsedSpecModels);

            const latestSpec = parsedSpecModels[0];

            this.setMetaSpecFromServer(latestSpec);
        } else {
            this.metadataSpecModelList.set(orgId, observable.array());
            this.activeSpecModel = undefined;
        }
    });

    @action
    cancelSpecSaveDialog = () => {
        this.activeSpecModel = undefined;
        this.newSpecFromBuilder = false;

        this.fromFileNameToMetaSpec.clear();
        this.fromCSVToMetaSpec.clear();
    };

    @action
    fromSpecConfirmDialog = (
        specName: string,
        levels: OrganizationStructureMember[],
    ) => {
        if (isUndefinedType(this.activeSpecModel)) {
            throw new Error("Metadata Spec model undefined");
        }

        let osm: OrganizationStructureMember | undefined = undefined;
        if (levels.length > 0) {
            osm = levels?.[levels.length - 1];
        }

        this.activeSpecModel.organizationStructureMemberId = osm?.id;
        this.activeSpecModel.specName = specName;
        this.newSpecFromBuilder = false;

        this.setupAsyncTask(UploadSpecToServerOp, () => this.uploadSpec()).then(
            (value) => {
                this.setActiveSpec(
                    value as MetadataSpec.IMetadataSpecModel & {
                        Symbol?: boolean;
                    },
                );
                if (this.activeSpecModel) {
                    let currentSpecList = this.metadataSpecModelList.get(
                        this.orgId!,
                    );
                    if (isUndefinedType(currentSpecList)) {
                        currentSpecList = observable.array([
                            this.activeSpecModel,
                        ]) as ParsedSpecModel[];
                    } else {
                        currentSpecList.push(this.activeSpecModel);
                    }
                    this.metadataSpecModelList.set(
                        this.orgId!,
                        currentSpecList,
                    );
                }
            },
        );
    };

    uploadSpec = flow(function* (this: AudioFileSamplerStore) {
        const theActiveSpec = toJS(this.activeSpecModel);
        theActiveSpec?.spec.fields.forEach((value) => {
            delete value.formatFunc;
        });

        const newlyCreatedSpec = yield this.metadataService.createMetadataSpec(
            this.orgId!,
            theActiveSpec?.specName!,
            theActiveSpec?.spec!,
            theActiveSpec?.organizationStructureMemberId!,
        );

        return newlyCreatedSpec;
    });

    private transformFilenameToSpec = (origFileName: string) => {
        let transformed = origFileName;
        if (!this.metadataSpec) {
            return transformed;
        }

        if (
            (this.metadataSpec?.fileHandle.action as ExtHandle) ===
                "removestring" &&
            this.metadataSpec?.fileHandle.value
        ) {
            transformed = origFileName.slice(
                0,
                origFileName.lastIndexOf(this.metadataSpec.fileHandle.value),
            );
        } else if (
            (this.metadataSpec?.fileHandle.action as ExtHandle) === "removedot"
        ) {
            transformed = origFileName.slice(0, origFileName.lastIndexOf("."));
        }

        return transformed;
    };

    private specToObject(
        specModel: MetadataSpec.IMetadataSpecModel,
    ): ParsedSpecModel {
        let parsedSpecModel: ParsedSpecModel = specModel as ParsedSpecModel;
        if (isStringType(specModel.spec)) {
            parsedSpecModel = {
                ...specModel,
                spec: JSON.parse(specModel.spec),
            };
        }

        let formatFunc: ((agent: AgentModel) => string) | undefined = undefined;

        const agentField = parsedSpecModel.spec.fields.find(
            (fld) => (fld.fieldName as MetaFieldLabels) === "Agent Name",
        );
        if (!isUndefinedType(agentField)) {
            const agentFormat: AgentFormatOptions = (agentField?.format ??
                "unknown") as AgentFormatOptions;
            switch (agentFormat) {
                case "LastName FirstInitial":
                    formatFunc = (agent: AgentModel) => {
                        return `${agent.lastName} ${agent.firstInitial}`;
                    };
                    break;
                case "LastName, FirstName":
                    formatFunc = (agent: AgentModel) => {
                        return `${agent.lastName}, ${agent.firstName}`;
                    };
                    break;
                case "FirstName LastName":
                    formatFunc = (agent: AgentModel) => {
                        return `${agent.firstName} ${agent.lastName}`;
                    };

                    break;
                case "AgentCode":
                    formatFunc = (agent: AgentModel) => {
                        return `${agent.agentCode}`;
                    };
                    break;
            }
            agentField.formatFunc = formatFunc;
        }

        return parsedSpecModel as ParsedSpecModel;
    }

    @action
    setActiveSpec = (
        specModel: MetadataSpec.IMetadataSpecModel & { Symbol?: boolean },
    ) => {
        if (
            specModel?.specName !== this.activeSpecModel?.specName ||
            specModel.id !== this.activeSpecModel?.id
        ) {
            if (specModel[Symbol.for("UnSelect")]) {
                delay(0)
                    .then((value) => {
                        runInAction(() => {
                            this.activeSpecModel = undefined;
                        });
                    })
                    .then((value) => delay(16))
                    .then((value) => {
                        runInAction(() => {
                            this.fromFileNameToMetaSpec.clear();
                            this.fromCSVToMetaSpec.clear();
                        });
                    });
            } else {
                delay(0).then((value) => {
                    runInAction(() => {
                        this.activeSpecModel = this.specToObject(specModel);
                    });
                });
            }
        }
    };

    @action
    setActiveRuleSet = (ruleSet?: RuleSet) => {
        this.activeRuleSet = ruleSet;

        this.activeRuleSetIdFromBuilder = undefined;
        this.onNavigatingFromRuleBuilder = false;
    };

    @action
    reset() {
        this.callBatches = undefined;
        this.callBatchId = undefined;
        this.confirmationOpen = false;
        this.taskErrors.clear();
        this.orgId = undefined;
        this.taskLoadingMap.clear();
    }

    @action
    setMetaSpecFromServer(value: ParsedSpecModel) {}

    get metadataSpec(): MetadataSpec.ISpecType | undefined {
        return this.activeSpecModel?.spec;
    }

    @action
    setMetadataSpecFromBuilder = (
        value: MetadataSpec.ISpecType | undefined,
    ) => {
        if (!isUndefinedType(value)) {
            const targetFiles =
                this.rootStore.getStore(BlobDirectoryStore).TargetList.children;
            const { containerName } = targetFiles[0];
            let newSpec = {
                spec: value,
                organizationId: this.orgId,
                specName: `${containerName}-latest`,
                callCenter: "",
                brand: "",
                vendor: "",
            } as ParsedSpecModel;

            if ((newSpec.spec.fileHandle.action as ExtHandle) === "removedot") {
                newSpec.spec.fileHandle.value = ".";
            }

            if ((newSpec.spec.fileHandle.action as ExtHandle) === "") {
                newSpec.spec.fileHandle.value = "";
            }

            this.setActiveSpec(newSpec);

            this.uploadReady = true;
            this.newSpecFromBuilder = true;
        } else {
            this.uploadReady = false;
            this.newSpecFromBuilder = false;
        }
    };

    readMetaFiles = flow(function* (this: AudioFileSamplerStore) {
        const metaPromises = readTextFilesAsync(this.MetaFiles);

        let metaObjects: IReadFileResult[] = yield Promise.all(metaPromises);

        metaObjects = metaObjects
            .filter((obj) => obj.status === "ok")
            .filter((value) => !!value.contents)
            .sort(
                (a, b) =>
                    ((b.contents as string)?.length ?? 0) -
                    ((a.contents as string)?.length ?? 0),
            );

        if (!metaObjects || metaObjects.length === 0) {
            this.setMetaFileContents(undefined);
            return;
        }

        yield delay(0);
        const macOsSep = /\r[^\n]/g;
        const windowsSep = /\r\n/g;
        const unixSep = /[^\r]\n/g;

        const separatorResults = [
            {
                count: (
                    ((metaObjects[0].contents ?? "") as string).match(
                        macOsSep,
                    ) || []
                ).length,
                sep: macOsSep,
            },
            {
                count: (
                    ((metaObjects[0].contents ?? "") as string).match(
                        windowsSep,
                    ) || []
                ).length,
                sep: windowsSep,
            },
            {
                count: (
                    ((metaObjects[0].contents ?? "") as string).match(
                        unixSep,
                    ) || []
                ).length,
                sep: unixSep,
            },
        ];

        yield delay(0);
        const lineSep =
            maxBy(separatorResults, (obj) => obj.count)?.sep ?? windowsSep;

        const metaLines = ((metaObjects[0].contents ?? "") as string).split(
            lineSep,
        );
        this.setMetaFileContents(metaLines);
    });

    @action
    setMetaFiles(value: File[]) {
        this.fromCSVToMetaSpec.clear();
        this.MetaFiles.splice(0, this.MetaFiles.length, ...value);
        this.setupAsyncTask(ReadMetaFilesOp, () => this.readMetaFiles());
    }

    isDirLevelExpanded = computedFn(function (
        this: AudioFileSamplerStore,
        dirName: string,
    ) {
        return this._isDirListExpanded.get(dirName);
    });

    @action
    setMetaFileContents(value: string[] | undefined) {
        this.metaFilesContent = value ?? [];
    }

    @action
    setDirListExpandedExpanded(diraName: string, expanded: boolean) {
        this._isDirListExpanded.set(diraName, expanded);
    }

    @action
    switchOrgs(orgId: string, org?: Organization) {
        this.orgId = orgId;
        this.org = org;
    }

    @computed
    get anyLongRunningLoading() {
        let filter = [...this.taskLoadingMap.entries()]
            .filter((value) => value[0] === GenerateSampleSuggestionOp)
            .filter((value) => value[1])
            .map((value) => value[0]);
        return filter?.[0];
    }
}

export function getMinutes(val: number | undefined) {
    if (val && val > 0) {
        const dur = moment.duration(val, "ms");
        return `${`${dur.hours()}`.padStart(
            2,
            "0",
        )}:${`${dur.minutes()}`.padStart(2, "0")}:${`${dur.seconds()}`.padStart(
            2,
            "0",
        )}`;
        // const dur = moment.utc(val);
        // return dur.format("hh:mm:ss");
    } else {
        return undefined;
    }
}

export function itemLevels(name: string): number {
    return name.split("/").length - 1;
}
