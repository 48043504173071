import { Grid, Paper, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Observer } from "mobx-react";
import React from "react";
import { useStore } from "../../../../utils/useStore";
import { EvalLicensedModule, EvalStore } from "../../Stores/EvalStore";
import ModuleCardFooter from "./ModuleCardFooter";
import ModuleCardHeader from "./ModuleCardHeader";
import ModuleDisputedMessage from "./ModuleDisputedMessage";
import QuestionAnswerList from "./QuestionAnswerList";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        paddingBottom: "16px",
        width: "100%",
        height: "auto",
        borderRadius: "8px",
        marginTop: "2.0rem",
        marginBottom: "2.0rem",
    },

    sectionTitle: {
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(1),
        marginBottom: "16px",
    },

    cardContainer: {
        paddingLeft: "16px",
        paddingRight: "16px",
    },
}));

interface OwnProps {
    module?: EvalLicensedModule;
}

const ModuleCard = (props: OwnProps) => {
    const classes = useStyles();
    const store = useStore(EvalStore);
    return (
        <Observer>
            {() => {
                return (
                    <Paper className={classes.root} elevation={4}>
                        <Grid
                            container
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="stretch"
                            className={classes.cardContainer}
                        >
                            <Grid item xs={12} className={classes.sectionTitle}>
                                <Typography variant={"h1"}>
                                    {props.module?.name}
                                </Typography>
                            </Grid>
                            <ModuleCardHeader module={props.module} />
                            <ModuleDisputedMessage
                                hasDisputedAnswers={
                                    props.module?.hasDisputedAnswers
                                }
                                evaluationStatus={
                                    store.currentEval?.evaluationStatus
                                }
                                canChangeDisputedAnswers={
                                    store.currentEval?.canChangeDisputedAnswers
                                }
                                isEvalEditable={store.currentEval?.isEditable}
                            />
                            <QuestionAnswerList
                                store={store}
                                module={props.module}
                            />
                            <ModuleCardFooter module={props.module} />
                        </Grid>
                    </Paper>
                );
            }}
        </Observer>
    );
};

export default ModuleCard;
