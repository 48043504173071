import { Theme, Typography } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import moment from "moment";
import React from "react";
import useStyles from "Styles/Styles";
import hexToRGB from "utils/hexToRGB";

const allmonths = moment.months();
const styles = (theme: Theme) => {
    return createStyles({
        calendarTable: {
            width: "100%",
            margin: 0,
            padding: 0,
            borderSpacing: "1px",
            borderCollapse: "separate",
        },
        monthCell: {
            cursor: "pointer",
            color: theme.palette.secondary.main,
            "&:hover": {
                backgroundColor: hexToRGB(theme.palette.secondary.main, 0.4),
            },
        },
    });
};
export interface IMonthBody {
    onMonthSelect: (mnth) => void;
}
export default function MonthBody(props: IMonthBody) {
    const classes = useStyles(styles);
    const onMonthSelect = (val) => {
        // debugger;
        props.onMonthSelect?.(val);
    };
    const monthCells = () => {
        const m: JSX.Element[] = [];
        allmonths.forEach((value) => {
            m.push(
                <td
                    onClick={() => onMonthSelect(value)}
                    className={classes.monthCell}
                    key={`month-selector-${value}`}
                >
                    <span style={{ display: "block", textAlign: "center" }}>
                        {value}
                    </span>
                </td>,
            );
        });
        return m;
    };
    const monthRows = () => {
        let cells: JSX.Element[] = [];
        const rows: JSX.Element[][] = [];
        const months = monthCells();
        months.forEach((r, i) => {
            if (i % 3 !== 0 || i === 0) {
                cells.push(r);
            } else {
                rows.push(cells);
                cells = [];
                cells.push(r);
            }
        });
        rows.push(cells);
        return rows;
    };
    return (
        <table className={classes.calendarTable}>
            <thead>
                <tr>
                    <td colSpan={3}>
                        <Typography align="center" variant="h1" display="block">
                            Select a Month
                        </Typography>
                    </td>
                </tr>
            </thead>
            <tbody>
                {monthRows().map((d, i) => (
                    <tr key={i}>{d}</tr>
                ))}
            </tbody>
        </table>
    );
}
