import { observer } from "mobx-react";
import { useStore } from "utils/useStore";
import { OrganizationCustomerTypeStore } from "../stores/OrganizationCustomerTypeStore";
import { customerTypesTypographyStyles } from "../OrganizationCustomerTypes";
import { Grid, Paper, Typography } from "@mui/material";
import RightArrowSvg from "SvgIcons/ConfigIcons/RightArrowSvg";
import theme from "Theme/AppTheme";
import AcxSelectSingle from "components/UI/Select/BaseSelectComponents/AcxSelectSingle";
import React from "react";
import AcxButton from "components/UI/AcxButton";
import makeStyles from '@mui/styles/makeStyles';

interface OrganizationCustomerTypeMappingProps {
    contactType: string;
}

const useStyles = makeStyles((theme) => ({
    button: {
        borderRadius: "0px",
        border: "none",
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.white.main,
        textAlign: "start",
        justifyContent: "start",
        "&:hover": {
            border: "none",
            color: theme.palette.secondary.main,
            backgroundColor: theme.palette.lightBlue.background,
        },
    },
}));

const OrganizationCustomerTypeMapping = observer(
    (props: OrganizationCustomerTypeMappingProps) => {
        const store = useStore(OrganizationCustomerTypeStore);
        const classes = useStyles();

        const currentlyMappedCustomerType = store.mappedCustomerTypes.find(
            (customerType) => customerType.sourceName === props.contactType,
        );

        return (
            <Paper
                elevation={0}
                sx={{
                    paddingBlock: "0.5rem",
                    paddingInline: "1rem",
                    borderRadius: "0.5rem",
                }}
            >
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    flexWrap="nowrap"
                >
                    <Grid item xs={2}>
                        <Typography style={customerTypesTypographyStyles.text}>
                            {props.contactType}
                        </Typography>
                    </Grid>
                    <Grid container item alignItems="center" xs={1}>
                        <RightArrowSvg />
                    </Grid>
                    <Grid item xs={9}>
                        <AcxSelectSingle
                            id={`Select-${props.contactType}`}
                            options={store.unMappedCustomerTypes}
                            valueField="id"
                            labelField="name"
                            defaultValue={currentlyMappedCustomerType}
                            isClearable
                            fullWidth
                            customStyle={{
                                container: () => ({
                                    border: `1px solid ${theme.palette.lightgrayBorder.main}`,
                                    borderRadius: "0.25rem",
                                }),
                            }}
                            onChange={(customerType) => {
                                if (!!currentlyMappedCustomerType)
                                    store.updateCustomerTypeSourceName(
                                        currentlyMappedCustomerType,
                                        undefined,
                                    );

                                if (!!customerType)
                                    store.updateCustomerTypeSourceName(
                                        customerType,
                                        props.contactType,
                                    );
                            }}
                            dropdownMenuEndChildren={
                                store.optimisticCustomerTypes.length === 0 && (
                                    <AcxButton
                                        fullWidth
                                        leftRightSpacing={0}
                                        variant="outlined"
                                        buttonClass={classes.button}
                                        onClick={() => store.openCreateDialog()}
                                    >
                                        + Contact Type
                                    </AcxButton>
                                )
                            }
                        />
                    </Grid>
                </Grid>
            </Paper>
        );
    },
);

export default OrganizationCustomerTypeMapping;
