import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import RuleSet from "../../../../../models/RuleSet";
import AcxSelect from "components/UI/Select/BaseSelectComponents/AcxSelect";

interface OwnProps {
    onSelect?: (ruleSet?: RuleSet) => void;
    label?: string;
    showSpinner?: boolean;
    orgId?: string;
    defaultValue?: RuleSet;
    error?: string;
    showEdit?: boolean;
    noSelect?: boolean;
    ruleSets?: RuleSet[];
    onNavigateRuleBuilder?: () => void;
    loading?: boolean;
    isNextStep?: boolean;
    customStyle?: any;
}

type Props = OwnProps;

const RuleSetSelect: FunctionComponent<Props> = observer((props) => {
    return (
        <AcxSelect
            inputLabel={props.label}
            defaultValue={props.defaultValue}
            id="ruleset-selector"
            labelField="name"
            valueField="id"
            editIcon={props.showEdit}
            customStyle={props.customStyle}
            enableNoSelection={props.noSelect}
            onEditClick={props.onNavigateRuleBuilder}
            options={props.ruleSets ?? []}
            onChange={props.onSelect}
            fullWidth={true}
            placeholder={
                props.loading
                    ? "Loading..."
                    : props.error
                    ? ""
                    : "Select RuleSet..."
            }
            isClearable
        />
    );
});

export default RuleSetSelect;
