import { AppUser } from "components/Admin/UserManager/UserStore";
import AcxDataGridStore from "components/UI/AcxDataGrid/AcxDataGridStore";
import {
    action,
    computed,
    makeObservable,
    observable,
    reaction,
    runInAction,
} from "mobx";
import { AgentStructureMember } from "models/AgentModels/AgentStructureMember";
import Agentv2 from "models/Agentv2";
import moment from "moment";
import { Moment } from "moment";
import AgentService, { IAgentsWithHierarchies } from "services/AgentService";
import { UserService } from "services/UserService";
import { BaseStore } from "stores/BaseStore";
import { DialogComponentStore } from "stores/ComponentStores/DialogComponentStore";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";

@AcxStore
export class OrganizationAgentStore extends BaseStore {
    private readonly agentService: AgentService = new AgentService();
    private readonly userService = new UserService();

    readonly agentCreateDialogStore: DialogComponentStore<OrganizationAgentStore>;
    readonly mergeAgentsDialogStore: DialogComponentStore<OrganizationAgentStore>;
    readonly updateAgentsDialogStore: DialogComponentStore<OrganizationAgentStore>;

    readonly dgStore: AcxDataGridStore;
    @observable orgId?: string;
    @observable createMergeData?: Agentv2;
    @observable createUpdateData?: Agentv2;
    @observable selectedAgentHierarchies?: any[];
    @observable inActiveStatus?: any;
    @observable ActiveAgentsLabel?: string;
    @observable creatAgentUserDetails?: any;
    @observable users: AppUser[];

    constructor(private rootStore: IRootStore) {
        super("OrganizationAgentStore");
        makeObservable(this);
        this.dgStore = new AcxDataGridStore(
            "OrganizationAgentDataStoreGrid",
            "OrgAgents",
        );
        this.agentCreateDialogStore = new DialogComponentStore(undefined, this);
        this.mergeAgentsDialogStore = new DialogComponentStore(undefined, this);
        this.updateAgentsDialogStore = new DialogComponentStore(
            undefined,
            this,
        );

        reaction(
            (r) => ({
                orgId: this.orgId,
                activeLocation: this.rootStore.activeLocation,
            }),
            (args) => {
                if (
                    args.activeLocation &&
                    !(
                        args.activeLocation.location.includes(
                            "admin/organizations",
                        ) && args.activeLocation.location.includes("/agents")
                    )
                ) {
                    return;
                }

                if (!args.orgId) return;

                this.setupAsyncTask("Load Agents", () =>
                    this.loadAgents(args.orgId as string),
                );
            },
            { fireImmediately: true },
        );

        reaction(
            (r) => this.anyTaskLoading,
            (task) => {
                this.dgStore.setLoading(task);
            },
            { fireImmediately: true },
        );

        reaction(
            (r) => this.dgStore.rows.length > 0,
            (hasRows) => {
                this.dgStore.vertIconMenuItemsBuilder = () => {
                    return [
                        this.dgStore.buildVertMenuOptionForCsvExport(hasRows),
                    ];
                };
            },
        );
    }

    searchUsers = async (inputValue: string): Promise<AppUser[]> => {
        if (this.orgId) {
            const u = await this.userService.searchUsers(
                this.orgId,
                inputValue,
            );
            return u;
        }
        return [];
    };

    private loadAgents = async (orgId: string) => {
        const agents = await this.agentService.getAgentWithHierarchies(orgId);

        runInAction(() => {
            this.dgStore.rows = agents as any;
        });
    };

    private loadInactiveAgents = async (orgId: string) => {
        const agents = await this.agentService.getInActiveAgentWithHierarchies(
            orgId,
        );
        runInAction(() => {
            this.dgStore.rows = agents as any;
        });
    };
    @action
    setSelectedAgentHierarchies = (val: string[]) => {
        this.selectedAgentHierarchies = val;
    };

    @action
    openCreateAgent = () => {
        this.selectedAgentHierarchies = [];
        this.agentCreateDialogStore.open();
    };

    @action
    openUpdateAgent = () => {
        const source =
            this.dgStore.getSelectedRows()[0] as IAgentsWithHierarchies;
        this.createUpdateData = { ...source } as Agentv2;
        this.selectedAgentHierarchies = source?.hierarchies.map(
            (hier) => hier.id,
        );
        this.updateAgentsDialogStore.open();
    };

    @action
    updateAgentUser = async (userId: any) => {
        this.setupAsyncTask("Update Agent User", async () => {
            await this.agentService.updateAgent(
                this.orgId,
                this.creatAgentUserDetails.id,
                this.creatAgentUserDetails.firstName,
                this.creatAgentUserDetails.lastName,
                this.creatAgentUserDetails.agentEmail,
                this.creatAgentUserDetails.agentCode,
                this.creatAgentUserDetails.isActive,
                this.creatAgentUserDetails.hierarchies,
                userId,
                this.creatAgentUserDetails.startDate,
                this.creatAgentUserDetails.managerId,
            );

            if (this.orgId) {
                this.dgStore.clearSelected();
                this.inActiveStatus = true;
                this.ActiveAgentsLabel = "Show Inactive Agents";
                await this.loadAgents(this.orgId);
            }
        }).then(() => {
            return true;
        });
    };
    @action
    createAgentUser = () => {
        this.creatAgentUserDetails = this.dgStore.getSelectedRows()[0];
        this.creatAgentUserDetails.hierarchies =
            this.creatAgentUserDetails?.hierarchies.map((hier) => hier.id);
    };

    @action
    openMergeAgents = () => {
        // Initializes createMergeData with the TARGET Agent
        const target = this.dgStore.getSelectedRows()[1] as Agentv2;
        this.createMergeData = { ...target } as Agentv2;

        // Create Agent Structure Members
        const asms = target.serviceHierarchies?.map((val) => {
            const asm = {} as AgentStructureMember;
            asm.agentId = this.createMergeData?.id;
            asm.orgStructureMemberId = val.id;

            return asm;
        });

        this.createMergeData.agentStructureMembers = asms;
        this.mergeAgentsDialogStore.open();
    };

    @action
    onAgentCreateConfirm = (
        firstName: string,
        lastName: string,
        agentEmail: string,
        agentCode: string,
        hierarchies: string[],
        startDate?: Moment,
        managerId?: string,
    ) => {
        this.setupAsyncTask("Create Agent", async () => {

            await this.agentService.createAgent(
                firstName,
                lastName,
                agentEmail,
                agentCode,
                this.orgId,
                hierarchies,
                !!startDate ? moment(startDate).toISOString() : undefined,
                managerId,
            );
            this.agentCreateDialogStore.close();
        }).then(() => {
            if (this.orgId) return this.loadAgents(this.orgId);
        });
    };

    @action
    onMergeAgentUpdate = <T extends keyof Agentv2>(arg: T, value: any) => {
        this.createMergeData![arg] = value;
    };

    @action
    onMergeAgentUpdateServiceHierarchies = (
        value?: { label: string; id: string }[],
    ) => {
        // Overwrite scenario.
        const asms = value?.map((val) => {
            const asm = {} as AgentStructureMember;
            asm.agentId = this.createMergeData?.id;
            asm.orgStructureMemberId = val.id;

            return asm;
        });

        this.createMergeData!.agentStructureMembers = asms ?? [];
    };

    // Agent manager is undefined when we want to remove the manager id from the agent
    @action
    updateAgentManager = (agentId: string, managerId?: string) => {
        this.setupAsyncTask("Update Agent", async () => {
            this.agentService.updateAgentManager(
                this.orgId,
                agentId,
                managerId ?? "",
            );
        });
    };

    @action
    onAgentUpdateConfirm = (
        firstName: string,
        lastName: string,
        agentEmail: string,
        agentCode?: string,
        isActive?: boolean,
        hierarchies?: string[],
        userId?: string,
        startDate?: Moment,
        managerId?: string,
    ) => {
        this.setupAsyncTask("Update Agent", async () => {
            const source = this.dgStore.selectedRows[0] as Agentv2;
            await this.agentService.updateAgent(
                this.orgId,
                source.id,
                firstName,
                lastName,
                agentEmail,
                agentCode,
                isActive,
                hierarchies,
                userId,
                !!startDate ? moment(startDate).toISOString() : undefined,
                managerId,
            );

            this.updateAgentsDialogStore.close();
        }).then(async () => {
            if (this.orgId) {
                this.dgStore.clearSelected();
                this.inActiveStatus = true;
                this.ActiveAgentsLabel = "Show Inactive Agents";
                await this.loadAgents(this.orgId);
            }
        });
    };

    @action
    onAgentsMergeConfirm = () => {
        this.setupAsyncTask("Merge Agents", async () => {
            await this.agentService.mergeAgent(
                this.createMergeData!,
                this.dgStore.getSelectedRows()[0] as Agentv2,
            );

            this.mergeAgentsDialogStore.close();
        }).then(async () => {
            if (this.orgId) {
                this.dgStore.clearSelected();
                await this.loadAgents(this.orgId);
            }
        });
    };

    @action
    loadInActiveAgents = async () => {
        if (this.orgId) {
            await this.loadInactiveAgents(this.orgId);
        }
    };
    @action
    loadActiveAgents = async () => {
        if (this.orgId) {
            await this.loadAgents(this.orgId);
        }
    };
    @action
    deleteAgents = () => {
        this.setupAsyncTask("Delete Agents", async () => {
            const agentIds =
                this.dgStore.getSelectedRows().map((agent) => agent.id) ??
                ([] as Agentv2[]);
            await this.agentService.deleteAgents(agentIds);
        }).then(async () => {
            if (this.orgId) {
                this.dgStore.clearSelected();
                await this.loadAgents(this.orgId);
            }
        });
    };

    @computed
    get deleteButtonEnabled() {
        return this.dgStore.selectedRows.length > 0;
    }

    @computed
    get updateButtonEnabled() {
        return this.dgStore.selectedRows.length === 1;
    }

    @computed
    get mergeButtonEnabled() {
        return this.dgStore.selectedRows.length === 2;
    }
}
