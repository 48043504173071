import { Container, Grid, IconButton, Link, Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { ArrowBack } from "@mui/icons-material";
import { Stack } from "@mui/material";
import OrgSelect from "components/Admin/AdminUIComponents/OrgSelect";
import AcxDataGrid from "components/UI/AcxDataGrid/AcxDataGrid";
import AcxDateRangeInput from "components/UI/Calendar/DateRange/AcxDateRangeInput";
import { AmdTimelineWithGraph } from "components/UI/Timeline/AmdTimelineWithGraph";
import { observer } from "mobx-react";
import { Moment } from "moment";
import React, {useEffect, useState} from 'react';
import theme from "Theme/AppTheme";
import { useStore } from "utils/useStore";
import { FileProcessStore, FileProcessView } from "./FileProcessStore";
import ChromeTabs, { ChromeTab } from 'components/UI/SubTabs/ChromeTabs';
import FileProcessFailures from './FileProcessFailures';
import { AuthStore } from 'stores/AuthStore';



const useStyles = makeStyles((theme: Theme) => ({
    root: {
        position: 'relative',
        width: '100%',
        height: 'calc(100% - 100px)',
        overflow: 'auto',
    },
    container: {
        height: '100%',
    },
    chartsGrid: {
        height: '100%',
        padding: '2rem',
    },
    linkStyle: {
        textAlign: 'left',
        color: theme.palette.blue.main,
        fontWeight: 'bolder',
        cursor: 'pointer',
    },
    gridItemStyle: {
        paddingLeft: '8px',
    },
    backButton: {
        textAlign: 'left',
        color: theme.palette.blue.main,
        fontWeight: 'bolder',
        cursor: 'pointer',
    },
}));

const DateRangePicker = observer(({ onSelect }: { onSelect: (start: Moment, end: Moment) => void }) => {
    const store = useStore(FileProcessStore);
    const classes = useStyles();
    return (
        <Grid item container className={classes.gridItemStyle}>
            <AcxDateRangeInput
                labelText="Select Date"
                defaultStartDate={store.datePickerStore.beginDate}
                defaultEndDate={store.datePickerStore.endDate}
                onSelect={onSelect}
            />
        </Grid>
    );
});

const DashboardContent = observer(({ activeTab }: { activeTab: number }) => {
    const classes = useStyles();
    const store = useStore(FileProcessStore);

    return (
        <Container maxWidth='xl' className={classes.container}>
            <Grid container justifyContent='flex-start' spacing={2}>
                <Stack
                    direction="row"
                    alignSelf={"start"}
                    alignItems="center"
                    style={{
                        opacity:
                            store.view !== FileProcessView.Dashboard
                                ? "100"
                                : "0",
                    }}
                    onClick={() => store.goBackView()}
                >
                    <Link
                        variant="body1"
                        style={{
                            textAlign: "left",
                            color: theme.palette.blue.main,
                            fontWeight: "bolder",
                            cursor: "pointer",
                        }}
                        color="textPrimary"
                    >
                        <IconButton size="medium">
                            <ArrowBack />
                        </IconButton>
                        Go Back
                    </Link>
                </Stack>
            </Grid>
            <Grid container item spacing={3} direction='column' alignItems='center' className={classes.chartsGrid} wrap='nowrap'>
                {store.view !== FileProcessView.AmdTimeline && (
                    <AcxDataGrid dataGridStore={store.dataGridStore} />
                )}
                {store.view === FileProcessView.AmdTimeline && store.selectedAmd && (
                    <AmdTimelineWithGraph amdId={store.selectedAmd.id} />
                )}
            </Grid>
        </Container>
    );
});

const FileProcessDashboard = observer(() => {
    const classes = useStyles();
    const store = useStore(FileProcessStore);
    const authStore = useStore(AuthStore);
    const [activeTab, setActiveTab] = useState<number>(0);

    useEffect(() => {
        store.dataGridStore.controls= [
            <div style={{width: "200px" }}>
                <OrgSelect
                    id={"FileProcessDashboardOrgSelector"}
                    label="Select Organization"
                    onSelect={(organization) => store.setOrganization(organization)}
                />
            </div>,
            <DateRangePicker onSelect={(start: Moment, end: Moment) => {
                store.datePickerStore.setBeginDate(start);
                store.datePickerStore.setEndDate(end);
            }} />,
        ];
        if (authStore.orgStore.selectedOrganization) {
            store.setOrganization(authStore.orgStore.selectedOrganization);
        }
    }, [authStore.orgStore.selectedOrganization, store]);

    const tabs: Array<ChromeTab> = [
        { label: "Dashboard", route: "/admin/fileprocessdashboard" },
        { label: "Processing Failures", route: "/admin/fileprocessdashboard/fileprocessfailures" },
    ];
    const handleTabChange = (index: number) => setActiveTab(index);

    return (
        <>
            <ChromeTabs tabs={tabs} activeTabIndex={activeTab} onChange={handleTabChange} />
            <div className={classes.root}>
                {activeTab === 0 ? <DashboardContent activeTab={activeTab} /> : <FileProcessFailures />}
            </div>
        </>
    );
});

export default FileProcessDashboard;