import { Grid, Theme, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import AcxConfirmationDialog from "components/UI/AcxConfirmationDialog";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import AcxDateRangeInputSelect, {
    CustomDateInputStyles,
} from "components/UI/Calendar/DateRange/AcxDateRangeInputSelect";
import { observer } from "mobx-react";
import React from "react";
import { DateReferenceOption } from "stores/ComponentStores/DatePickerComponentStore";
import { useStore } from "utils/useStore";
import ClassifierBuilderReclassifyDialogStore from "./ClassifierBuilderReclassifyDialogStore";
import AcxSelectMulti from "components/UI/Select/BaseSelectComponents/AcxSelectMulti";
import AcxHierarchySelector from "components/UI/AcxHierarchySelector/AcxHierarchySelector";
import Classifier from "models/ClassifierModel";

const useStyles = makeStyles((theme: Theme) => ({
    toggleBtnContainer: {
        paddingRight: theme.spacing(2),
        alignSelf: "flex-end",
    },
    hierToggleButton: {
        padding: "1rem",
        minWidth: "max-content",
    },
    toggleInputContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        minHeight: "52px",
    },
    selectorContentContainer: {
        maxHeight: "600px",
    },
    selectorContentContainerFH: {
        height: "100%",
    },
    hierButton: {
        padding: "1rem",
        minWidth: "max-content",
    },
    selectorContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        alignItems: "flex-end",
        minHeight: "52px",
    },
    buttonContainer: {
        paddingRight: theme.spacing(2),
        alignSelf: "flex-end",
    },
    dropdownContainer: {
        maxHeight: "600px",
        overflowY: "auto",
    },
    dropdownContainerFH: {
        maxHeight: "100%",
        overflowY: "auto",
    },
    topContentContainer: {
        padding: "16px",
    },
    treeContainer: {
        maxHeight: "458px",
        overflowY: "scroll",
    },
    treeContainerFH: {
        maxHeight: "60vh",
        overflowY: "scroll",
    },

    bottomContentContainer: {
        padding: "24px",
    },
    clearBtn: {
        borderRadius: "4px",
        border: "1px solid #E4E4E7",
        backgroundColor: "#FFF",
        color: "#3F3F46!important",
        width: "36px",
        margin: "0px",
        "&:hover": {
            backgroundColor: "#F4F4F5!important",
        },
    },
    applyBtn: {
        borderRadius: "4px",
        backgroundColor: "#3564D5",
        color: "#FFFFFF",
        width: "153px",
        margin: "0px 0px 0px 0px!important",
        "&:hover": {
            backgroundColor: "rgba(53, 100, 213, 0.5)!important",
        },
    },
    selectAllText: {
        padding: "8px 0px",
        fontWeight: "bold",
        textAlign: "end",
        color: "#3564D5",
    },
    totalText: {
        display: "flex",
        flexWrap: "nowrap",
        alignItems: "center",
    },
    padBottom: {
        paddingBottom: "1rem",
    },
    popper: {
        zIndex: 99999,
        borderRadius: "8px",
    },
    popperFloat: {
        zIndex: 99999,
        borderRadius: "8px",
        top: "-25px!important",
    },
    inputField: {
        width: "100%",
        minWidth: "350px",
    },
    dialogInputField: {
        minWidth: "100%",
    },
    branchContent: {
        alignSelf: "center",
        paddingTop: "1px",
    },
    actionBtns: {
        marginTop: "5px",
    },
    indicatorContainer: {
        width: "100%",
    },
}));

const dateRangeStyles: CustomDateInputStyles = {
    containerWrapper: {
        flexDirection: "column",
        justifyContent: "flex-start",
        width: "100%",
    },
    dateInputWrapper: { paddingBottom: "1rem", minWidth: "100%" },
    dateInnerStyles: {
        flexDirection: "row-reverse",
        paddingRight: "1rem",
    },
    dateRefWrapper: { minWidth: "100%" },
};

interface Props {
    defaultValue?: Classifier[];
}

const ClassifierBuilderReclassifyDialog = observer(
    ({ defaultValue }: Props) => {
        const classes = useStyles();
        const store = useStore(ClassifierBuilderReclassifyDialogStore);

        function reclassify() {
            store.reclassify();
        }

        function closeDialog() {
            store.dialog.close();
        }

        function closeConfirmDialog() {
            store.confirmDialog.close();
        }

        function clearProgress() {
            store.completedCount = 0;
            store.totalCount = 0;
        }

        const indicator = store.loadingAffected ? (
            <AcxLoadingIndicator
                color="secondary"
                alternate="PuffLoader"
                size={32}
            />
        ) : store.succeeded >= 0 ? (
            "Reclassification process started."
        ) : (
            <Grid
                container
                item
                justifyContent="flex-start"
                alignItems="center"
                direction="row"
                wrap="nowrap"
            >
                <Typography style={{ fontWeight: "bold" }}>
                    {store.numAffectedInteractions !== undefined &&
                        store.numAffectedInteractions !== null &&
                        `Total Interactions to be reclassified:`}
                </Typography>
                <Typography style={{ marginLeft: ".5rem" }}>
                    {store.numAffectedInteractions !== undefined &&
                        store.numAffectedInteractions !== null &&
                        `  ${store.numAffectedInteractions}`}
                </Typography>
            </Grid>
        );

        const referenceOptions = [
            DateReferenceOption.ArrivalDate,
            DateReferenceOption.InteractionDate,
        ];

        const reclassifyDialogContent = (
            <Grid
                container
                item
                spacing={3}
                alignItems="center"
                direction="column"
                justifyContent="center"
            >
                <Grid item xs={12} container spacing={1} alignItems="center">
                    <AcxDateRangeInputSelect
                        label="Select Date Range"
                        datePickerStore={store.datePickerStore}
                        dateRangeStyles={dateRangeStyles}
                        dateReferenceOptions={referenceOptions}
                        fullWidth
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    container
                    alignItems="center"
                    style={{ paddingBottom: "8px", maxWidth: "480px" }}
                >
                    <AcxSelectMulti
                        inputLabel="Select Classifier(s)"
                        fullWidth
                        options={store.classifierOptions}
                        onChange={store.setSelectedClassifiers}
                        id="user-classifiers"
                        valueField="id"
                        labelField="name"
                        placeholder="Select classifier(s)"
                        defaultValue={
                            defaultValue && defaultValue.length > 0
                                ? defaultValue
                                : store.selectedClassifiers
                        }
                        containerHeight="auto"
                        maxContainerHeight="96px"
                        alignControls="flex-start"
                        isClearable={true}
                    />
                </Grid>
                <Grid item xs={12} container alignItems="center">
                    <AcxHierarchySelector
                        displayType="inputDialog"
                        orgId={store.orgSelectStore.orgId ?? ""}
                        userId={store.authStore._user.profile.sub ?? ""}
                        fullHeight
                        setHierarchyIds={store.setHierarchyIds}
                        onSaveUpdateWithHierarchies={() => {}}
                    />
                </Grid>
                <Grid item xs={12} className={classes.indicatorContainer}>
                    {indicator}
                </Grid>
            </Grid>
        );

        const confirmReclassifyContent = (
            <Grid
                container
                item
                alignItems="center"
                direction="column"
                justifyContent="center"
            >
                <Grid
                    item
                    xs={12}
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={{
                        maxWidth: "95%",
                        paddingBottom: "1rem",
                    }}
                >
                    <Typography>
                        Are you sure you want to reclassify this selection?
                        Reclassify will process all selected classifiers in your
                        specified date range and hierarchy. You will receive a
                        notification once complete.
                    </Typography>
                </Grid>
            </Grid>
        );

        return (
            <>
                <AcxConfirmationDialog
                    buttonsDisabled={store.reclassifySessionInProgress}
                    dialogContentStyles={{ overflow: "hidden" }}
                    isOpen={store.dialog.isOpen}
                    onClose={closeDialog}
                    onConfirm={() => {
                        store.confirmDialog.open();
                        closeDialog();
                    }}
                    content={reclassifyDialogContent}
                    confirmButtonText="Reclassify These Interactions"
                    isLoading={store.dialog.isLoading}
                    errorMessage={store.dialog.errorMessage}
                    title="Reclassify"
                    subTitle="Run classifier(s) against selected hierarchy"
                    maxWidth="xl"
                    controlStyles={{ backgroundColor: "#fff" }}
                />
                <AcxConfirmationDialog
                    isOpen={store.confirmDialog.isOpen}
                    dialogContentStyles={{ textAlign: "center" }}
                    buttonsDisabled={store.reclassifySessionInProgress}
                    title="Confirm Reclassify"
                    content={confirmReclassifyContent}
                    onClose={() => {
                        closeConfirmDialog();
                        store.dialog.open();
                    }}
                    onConfirm={() => {
                        clearProgress();
                        reclassify();
                        closeConfirmDialog();
                        closeDialog();
                    }}
                    isLoading={store.confirmDialog.isLoading}
                    alternateSpinnerType="PuffLoader"
                    errorMessage={store.confirmDialog.errorMessage}
                    controlStyles={{ backgroundColor: "#fff" }}
                    centerTitle
                />
            </>
        );
    },
);

export default ClassifierBuilderReclassifyDialog;
