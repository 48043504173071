import { Tooltip } from "@mui/material";
import { Grid } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import { useStore } from "utils/useStore";
import theme from "Theme/AppTheme";
import AcxSelect from "components/UI/Select/BaseSelectComponents/AcxSelect";
import { ISignalsVizDefinition } from "../Models/SignalsModels";
import AcxCompactSelectMulti from "components/UI/Select/AcxCompactSelectMulti";
import { Question } from "components/Admin/Organizations/types/Module.type";
import SignalsReportStore from "../Store/SignalsReportStore";
import InfoIcon from "@mui/icons-material/Info";

type Props = ISignalsVizDefinition;

const SignalsChartControls: React.FC<Props> = observer((props: Props) => {
    const { id, showDataGrid, selectionType } = props;

    const store = useStore(SignalsReportStore);

    const showClassifierControls = selectionType === "Classifiers";
    const showModuleControls =
        selectionType === "Question" ||
        selectionType === "Questions" ||
        selectionType === "Tags";

    const showTopicControls = selectionType === "Topics";

    const renderModuleControl = () => {
        const isSingleQuestionSelect =
            selectionType === "Question" || selectionType === "Tags";

        const selectedQuestion: Question | undefined =
            store.selectedQuestionByChartId[id];

        const modulesToUse =
            selectionType === "Tags"
                ? store.orgModules
                : store.orgScoredModules;

        return (
            <Grid item container xs={8} spacing={2} alignItems={"flex-end"}>
                <Grid item xs={3}>
                    <AcxSelect
                        fullWidth
                        defaultValue={
                            store.selectedModuleIdByChartId
                                ? modulesToUse?.find(
                                      (item) =>
                                          item.id ===
                                          store.selectedModuleIdByChartId[id],
                                  )
                                : undefined
                        }
                        options={modulesToUse}
                        onChange={(item) => {
                            store.handleSelectedModuleChange(id, item.id);
                        }}
                        placeholder="Select Module"
                        id={"signals-chart-select-module" + id}
                        labelField="displayName"
                        valueField="id"
                        alternateItemColor={theme.palette.gray.main}
                    />
                </Grid>
                {isSingleQuestionSelect ? (
                    <>
                        <Grid item xs={3}>
                            <AcxSelect
                                fullWidth
                                defaultValue={selectedQuestion}
                                options={
                                    modulesToUse.find(
                                        (m) =>
                                            m.id ===
                                            store.selectedModuleIdByChartId[id],
                                    )?.questions ?? []
                                }
                                onChange={(item) => {
                                    if (item) {
                                        store.handleQuestionChange(id, [
                                            item.id,
                                        ]);
                                    }
                                }}
                                placeholder="Select Question"
                                id={
                                    "signals-chart-filter-question-single-select" +
                                    id
                                }
                                labelField="displayName"
                                valueField="id"
                                alternateItemColor={theme.palette.gray.main}
                            />
                        </Grid>
                        {selectionType === "Tags" && !showDataGrid && (
                            <Grid item xs={3}>
                                <AcxCompactSelectMulti
                                    options={selectedQuestion?.tags ?? []}
                                    labelField="value"
                                    valueField="id"
                                    id={"signals-tags-multi-select-" + id}
                                    maxSelectionCount={store.maxSelectionCount}
                                    onApply={(ids) => {
                                        store.handleTagChange(id, ids);
                                    }}
                                    defaultSelectedIds={store.getSelectedRowIds(
                                        id,
                                    )}
                                    itemTitle="Response"
                                />
                            </Grid>
                        )}
                    </>
                ) : (
                    !showDataGrid && (
                        <Grid item xs={3}>
                            <AcxCompactSelectMulti
                                options={
                                    modulesToUse.find(
                                        (m) =>
                                            m.id ===
                                            store.selectedModuleIdByChartId[id],
                                    )?.questions ?? []
                                }
                                id={"question-select-" + id}
                                onApply={(ids) => {
                                    store.handleQuestionChange(id, ids);
                                }}
                                itemTitle="Question"
                                defaultSelectedIds={modulesToUse
                                    .find(
                                        (m) =>
                                            m.id ===
                                            store.selectedModuleIdByChartId[id],
                                    )
                                    ?.questions.filter((q) =>
                                        store
                                            .getSelectedRowIds(id)
                                            .includes(q.id ?? ""),
                                    )
                                    .map((q) => q.id ?? "")}
                                labelField="displayName"
                                valueField="id"
                                maxSelectionCount={store.maxSelectionCount}
                            />
                        </Grid>
                    )
                )}
            </Grid>
        );
    };

    const renderClassifierControl = () => {
        const showSelectMulti = showDataGrid === false;

        return (
            <Grid item container xs={8} spacing={2} alignItems={"flex-end"}>
                <Grid item xs={3}>
                    <AcxSelect
                        fullWidth
                        defaultValue={
                            store.selectedClassifierCategoryByChartId
                                ? store.selectedClassifierCategoryByChartId[id]
                                : undefined
                        }
                        options={store.userClassifierCategories ?? []}
                        onChange={(item) => {
                            store.handleSelectedClassifierCategoryChange(
                                id,
                                item?.id ?? undefined,
                            );
                        }}
                        placeholder="All Classifiers"
                        id={"signals-chart-select-classifier-category" + id}
                        labelField="name"
                        valueField="id"
                        alternateItemColor={theme.palette.gray.main}
                        isClearable
                    />
                </Grid>
                {showSelectMulti && (
                    <Grid item xs={3} container alignContent={"flex-end"}>
                        <AcxCompactSelectMulti
                            options={store.userClassifiers.filter((c) => {
                                const selectedId =
                                    store.selectedClassifierCategoryByChartId[
                                        id
                                    ]?.id;
                                if (
                                    selectedId &&
                                    selectedId !== "all-classifiers"
                                ) {
                                    return (
                                        c.classifierCategory?.id === selectedId
                                    );
                                } else {
                                    return true;
                                }
                            })}
                            id={"classifier-select-" + id}
                            onApply={(ids) => {
                                store.updateSelectedClassifiers(id, ids);
                            }}
                            itemTitle="Classifier"
                            defaultSelectedIds={store.userClassifiers
                                .filter((item) =>
                                    store
                                        .getSelectedRowIds(id)
                                        .includes(item.id),
                                )
                                .map((c) => c.id)}
                            labelField="name"
                            valueField="id"
                            maxSelectionCount={store.maxSelectionCount}
                        />
                    </Grid>
                )}
            </Grid>
        );
    };

    const renderTopicControl = () => {
        if (showDataGrid) return;
        return (
            <Grid item container xs={8} spacing={2} alignItems={"flex-end"}>
                <Grid item xs={3}>
                    <AcxCompactSelectMulti
                        options={store.topics}
                        id={"topic-select-" + id}
                        onApply={(ids) => {
                            store.handleTopicChange(id, ids);
                        }}
                        itemTitle="Topic"
                        defaultSelectedIds={store.topics
                            .filter((item) =>
                                store.getSelectedRowIds(id).includes(item.id),
                            )
                            .map((c) => c.id)}
                        labelField="topicLabel"
                        valueField="id"
                        maxSelectionCount={store.maxSelectionCount}
                    />
                </Grid>
                <Grid item>
                    <Tooltip title="Topics are sorted in order of most prevalent by interaction date.">
                        <InfoIcon color="secondary" />
                    </Tooltip>
                </Grid>
            </Grid>
        );
    };

    return (
        <>
            {showClassifierControls && renderClassifierControl()}
            {showModuleControls && renderModuleControl()}
            {showTopicControls && renderTopicControl()}
        </>
    );
});

export default SignalsChartControls;
