import { Grid } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import { observer } from "mobx-react";
import React from "react";
import useStyles from "Styles/Styles";
import { useStore } from "utils/useStore";
import ModelDashboardBodyContent from "./BodyContent/ModelDashboardBodyContent";
import ModelDashboardStore from "./Stores/ModelDashboardStore";

const styles = () =>
    createStyles({
        modelDashboardWrapper: {
            margin: "0.5rem 0.75rem",
            padding: "0.5rem 0.75rem",
            height: "calc(100% - 1.75rem)",
            width: "calc(100% - 1.5rem)",
        },
        sidebarWrapper: {
            height: "100%",
            overflow: "scroll",
            maxWidth: "250px",
        },
        bodyContentWrapper: {
            paddingLeft: "0.5rem",
            height: "100%",
        },
    });

// We are currently hiding the sidebar, which will populate its options based upon the availableModels observable in the ModelDashboardStore.
const ModelDashboard = observer(() => {
    const classes = useStyles(styles);
    const modelDashboardStore = useStore(ModelDashboardStore);

    return (
        <Grid container className={classes.modelDashboardWrapper}>
            {/* <Grid item xs={3} className={classes.sidebarWrapper}>
                <ModelDashboardSidebar store={modelDashboardStore} />
            </Grid> */}

            <Grid item xs={12} className={classes.bodyContentWrapper}>
                <ModelDashboardBodyContent store={modelDashboardStore} />
            </Grid>
        </Grid>
    );
});

export default ModelDashboard;
