import { Accordion, AccordionDetails, AccordionSummary, Grid, Theme, Typography } from "@mui/material";

import createStyles from '@mui/styles/createStyles';

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import AcxButton from "components/UI/AcxButton";

import { observer } from "mobx-react";
import { default as IntegrationConfiguration } from "models/OrganizationModels/OrganizationIntegrationConfiguration";

import React from "react";
import useStyles from "Styles/Styles";
import { useStore } from "utils/useStore";
import {
    IntegrationStore,
    LOAD_INTEGRATION,
    SET_INTEGRATION,
} from "./IntegrationStore";
import IntegrationSettingItem from "./IntegrationSettingItem";

const styles = (theme: Theme) => {
    return createStyles({
        container: {
            marginTop: 16,
        },
        heading: {
            fontSize: theme.typography.pxToRem(16),
            flexBasis: "60%",
            flexShrink: 0,
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
        },
        fieldSpacing: {
            margin: "0.25rem 0",
        },
        itemSpacing: { margin: "1rem", padding: "1rem" },
        inputSpacing: { marginRight: "0.25rem" },
    });
};

type Props = {
    orgId?: string;
};

const IntegrationSettings: React.FC<Props> = observer(({ orgId }) => {
    const classes = useStyles(styles);
    const [isOpen, setIsOpen] = React.useState(false);

    const integrationStore = useStore(IntegrationStore);

    const toggleAccordion = () => {
        setIsOpen((prev) => !prev);
    };

    const isLoading =
        integrationStore.getTaskLoading(LOAD_INTEGRATION) ||
        integrationStore.getTaskLoading(SET_INTEGRATION);

    React.useEffect(() => {
        if (!orgId) return;
        integrationStore.setOrgId(orgId);
    }, [integrationStore, orgId]);

    return (
        <Accordion
            square
            expanded={isOpen}
            onChange={toggleAccordion}
            className={classes.container}
        >
            <AccordionSummary>
                <Typography className={classes.heading}>
                    {isOpen ? (
                        <KeyboardArrowDownIcon />
                    ) : (
                        <KeyboardArrowRightIcon />
                    )}{" "}
                    Telephony Settings
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid item xs={12}>
                    <Grid
                        item
                        container
                        xs={12}
                        style={{ justifyContent: "space-between" }}
                    >
                        <AcxButton
                            onClick={integrationStore.addItem}
                            fullWidth={false}
                            color="primary"
                            leftRightSpacing={1}
                            buttonDisabled={
                                integrationStore.integrationConfiguration
                                    .length >= 1
                            }
                            tooltip={
                                integrationStore.integrationConfiguration
                                    .length >= 1
                                    ? "Only 1 URL allowed"
                                    : ""
                            }
                        >
                            Add URL
                        </AcxButton>
                        <AcxButton
                            onClick={
                                integrationStore.saveTelephonyConfiguration
                            }
                            fullWidth={false}
                            color="secondary"
                            leftRightSpacing={1}
                            loading={isLoading}
                            buttonDisabled={integrationStore.hasError}
                        >
                            Save
                        </AcxButton>
                    </Grid>
                    {integrationStore.integrationConfiguration.map(
                        (item: IntegrationConfiguration, index: number) => (
                            <IntegrationSettingItem item={item} index={index} />
                        ),
                    )}
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
});

export default IntegrationSettings;
