import { observer } from "mobx-react";
import React from "react";
import { Route, Routes } from "react-router-dom";
import FocusedConversation from "./FocusedConversation/FocusedConversation";
import UnfocusedConversationWrapper from "./UnfocusedConversation/UnfocusedConversationWrapper";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import { useStore } from "utils/useStore";
import ConversationsStore, {
    loadConversations,
} from "components/Conversations/Stores/ConversationsStore";

const ConversationRouting: React.FunctionComponent = observer(() => {
    const store = useStore(ConversationsStore);
    return (
        <Routes>
            <Route path={":id"} element={<FocusedConversation />} />
            <Route
                path={``}
                element={
                    store.getTaskLoading(loadConversations) ? (
                        <AcxLoadingIndicator size={75} alternate="PuffLoader" />
                    ) : (
                        <UnfocusedConversationWrapper />
                    )
                }
            />
        </Routes>
    );
});

export default ConversationRouting;
