import { Radio, styled } from "@mui/material";
import React from "react";
import AcxSelectMulti, {
    IAcxSelectMulti,
} from "./BaseSelectComponents/AcxSelectMulti";

const ControlContainer = styled("div")(() => ({
    display: "grid",
    gridTemplateColumns: "1fr 3fr 3fr",
    alignItems: "center",
    margin: "0 0 0.5rem 0",
    padding: "0px",
    gap: "0.5rem",
}));

const Label = styled("label")(({ theme }) => ({
    userSelect: "none",
    fontSize: "12px",
    lineHeight: "16px",
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold as any,
}));

const ItemContainer = styled("section")(({ theme }) => ({
    display: "flex",
    gap: "0.25rem",
    alignItems: "center",
}));

const Border = styled("div")(({ theme }) => ({
    border: `1px solid ${theme.palette.lightgrayBorder.main}`,
    borderRadius: "3px",
    width: "100%",
}));

export interface IAcxLabeledSelectToggle
    extends Omit<
        IAcxSelectMulti<IAcxLabeledSelectToggleOption>,
        "options" | "isClearable" | "isLoading"
    > {
    readonly fieldIdentifierLabel?: string;
    setRequired?: boolean;
    readonly fieldName: string;
    firstDropdownOptions: IAcxLabeledSelectToggleOption[];
    secondDropdownOptions: IAcxLabeledSelectToggleOption[];
}
export interface IAcxLabeledSelectToggleOption {
    id: string;
    label: string;
}

const AcxLabeledSelectToggle = (props: IAcxLabeledSelectToggle) => {
    const [checked, setChecked] = React.useState("");
    const [valueOne, setValueOne] = React.useState(props.firstDropdownOptions);
    const [valueTwo, setValueTwo] = React.useState(props.secondDropdownOptions);

    React.useEffect(() => {
        if (checked === `firstRadioField_${props.fieldName}`) {
            props.onChange?.(valueOne);
        } else if (checked === `secondRadioField_${props.fieldName}`) {
            props.onChange?.(valueTwo);
        }
    }, [props, checked, valueOne, valueTwo]);

    React.useEffect(() => {
        if (props.firstDropdownOptions.length !== 0) {
            setChecked(`firstRadioField_${props.fieldName}`);
        } else if (
            props.firstDropdownOptions.length === 0 &&
            props.secondDropdownOptions.length !== 0
        ) {
            setChecked(`secondRadioField_${props.fieldName}`);
        } else {
            setChecked(`firstRadioField_${props.fieldName}`);
        }
    }, [props, props.fieldName]);

    const handleOneInputUpdate = (
        arg: IAcxLabeledSelectToggleOption[] | undefined,
    ) => {
        setValueOne(arg as IAcxLabeledSelectToggleOption[]);
    };

    const handleTwoInputUpdate = (
        arg: IAcxLabeledSelectToggleOption[] | undefined,
    ) => {
        setValueTwo(arg as IAcxLabeledSelectToggleOption[]);
    };

    const handleRadioUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.id);
    };

    return (
        <ControlContainer>
            <Label title={props.fieldIdentifierLabel}>
                {props.fieldIdentifierLabel}
            </Label>

            <ItemContainer>
                <Radio
                    key={`firstRadioField_${props.fieldName}`}
                    id={`firstRadioField_${props.fieldName}`}
                    checked={
                        checked === `firstRadioField_${props.fieldName}`
                            ? true
                            : false
                    }
                    onChange={handleRadioUpdate}
                    name={props.fieldName}
                    value={valueOne ? JSON.stringify(valueOne) : ""}
                />

                <Border>
                    <AcxSelectMulti
                        key={`firstTextField_${props.fieldName}`}
                        id={`firstTextField_${props.fieldName}`}
                        containerHeight="auto"
                        width="200px"
                        options={props.firstDropdownOptions || []}
                        defaultValue={props.firstDropdownOptions || []}
                        valueField={props.valueField}
                        labelField={props.labelField}
                        required={props.setRequired ? props.setRequired : false}
                        onChange={handleOneInputUpdate}
                        customStyle={{
                            dropdownIndicator: () => ({
                                opacity: "0%",
                            }),
                        }}
                        isDisabled
                        placeholder=""
                    />
                </Border>
            </ItemContainer>

            <ItemContainer>
                <Radio
                    key={`secondRadioField_${props.fieldName}`}
                    id={`secondRadioField_${props.fieldName}`}
                    checked={
                        checked === `secondRadioField_${props.fieldName}`
                            ? true
                            : false
                    }
                    onChange={handleRadioUpdate}
                    name={props.fieldName}
                    value={valueTwo ? JSON.stringify(valueTwo) : ""}
                />

                <Border>
                    <AcxSelectMulti
                        key={`secondTextField_${props.fieldName}`}
                        id={`secondTextField_${props.fieldName}`}
                        containerHeight="auto"
                        width="200px"
                        options={props.secondDropdownOptions || []}
                        defaultValue={props.secondDropdownOptions || []}
                        valueField={props.valueField}
                        labelField={props.labelField}
                        required={props.setRequired ? props.setRequired : false}
                        onChange={handleTwoInputUpdate}
                        customStyle={{
                            dropdownIndicator: () => ({
                                opacity: "0%",
                            }),
                        }}
                        isDisabled
                        placeholder=""
                    />
                </Border>
            </ItemContainer>
        </ControlContainer>
    );
};

export default AcxLabeledSelectToggle;
