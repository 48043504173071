import {
    Breadcrumbs,
    Button,
    ButtonBase,
    Grid,
    IconButton,
    Typography,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { AddSharp } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import FolderIcon from "@mui/icons-material/Folder";
import AcxDateRangeInput from "components/UI/Calendar/DateRange/AcxDateRangeInput";
import { Observer, observer } from "mobx-react";
import { Moment } from "moment";
import React, { FunctionComponent, useRef, useState } from "react";
import DirectoryInfoModel from "../../../../../../models/DirectoryInfoModel";
import { useStore } from "../../../../../../utils/useStore";
import AcxButton from "../../../../../UI/AcxButton";
import AcxControlledTable, {
    CustomControlItem,
} from "../../../../../UI/AcxTable/AcxControlledTable";
import {
    IAcxTableColumn,
    IAcxTableRow,
} from "../../../../../UI/AcxTable/AcxTable";
import {
    LoadSourceDirectoriesTask,
    SourceFilesStepStore,
} from "../../Stores/SourceFilesStepStore";
import { StepperStore } from "../../Stores/StepperStore";
import DirectoryServiceHierarchyDialog from "./DirectoryServiceHierarchyDialog";

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        color: "#1F1F1F",
        fontSize: "16px",
        letterSpacing: "0",
        lineHeight: "24px",
    },

    breadcrumbs: {
        color: "inherit",
        fontSize: "16px",
        fontWeight: "inherit",
        fontFamily: "Inter",
    },
    selectedItemText: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        userSelect: "none",
        fontFamily: theme.typography.fontFamily,
    },
    selectedItemIcon: {
        color: theme.palette.action.disabled,
    },

    selectedItemRow: {
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        minHeight: "45px",
    },

    contextButton: {
        textTransform: "none",
        fontFamily: theme.typography.fontFamily,
    },
    selectedItemLabel: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        userSelect: "none",
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold as any,
    },

    viewFilesButton: {
        textTransform: "none",
        fontFamily: theme.typography.fontFamily,
    },
    hierarchyBreadcrumb: {
        cursor: "pointer",
    },

    folderIcon: {
        color: theme.palette.info.light,
    },
    folderNameButton: (props: Props) => ({
        fontSize: "12px",
        fontWeight: "bold",

        overflow: "hidden",
        textOverflow: "ellipsis",
        color: theme.palette.blue.main,
        fontFamily: theme.typography.fontFamily,
        textTransform: "none",
        letterSpacing: 0,
        textAlign: "center",
        lineHeight: "20px",
        "&:disabled": {
            color: theme.palette.text.primary,
        },
    }),
}));

interface OwnProps {}

type Props = OwnProps;

const DirectoryTable: FunctionComponent<Props> = observer((props) => {
    const classes = useStyles(props);
    const store = useStore(SourceFilesStepStore);
    const stepperStore = useStore(StepperStore);
    const [localDirs, setDirectories] = useState<
        DirectoryInfoModel[] | undefined
    >();
    const serviceHierachySelectorRef = useRef(null);

    function openDirectoryServiceHierarchyDialog(
        directoryInfoModels: DirectoryInfoModel[],
    ) {
        setDirectories(directoryInfoModels);
        store.openDirectoryHierarchyDialog();
    }

    function nameFormatter(
        directoryName: string,
        directoryInfoModel: DirectoryInfoModel,
    ) {
        return (
            <Grid
                container
                justifyContent={"flex-start"}
                alignItems={"baseline"}
            >
                <Grid item>
                    <IconButton
                        onClick={() =>
                            store.appendDirectoryPath(directoryInfoModel)
                        }
                        disabled={
                            !Boolean(directoryInfoModel.subDirectories?.length)
                        }
                        size="large"
                    >
                        <FolderIcon className={classes.folderIcon} />
                    </IconButton>
                </Grid>
                <Grid item>
                    <Button
                        className={classes.folderNameButton}
                        disabled={
                            !Boolean(directoryInfoModel.subDirectories?.length)
                        }
                        onClick={() =>
                            store.appendDirectoryPath(directoryInfoModel)
                        }
                    >
                        {directoryName}
                    </Button>
                </Grid>
            </Grid>
        );
    }

    function viewFilesFormatter(directoryId: string) {
        return (
            <Button
                className={classes.viewFilesButton}
                variant={"contained"}
                color={"primary"}
                size={"small"}
                onClick={() => store.loadFiles(directoryId)}
                disableElevation
            >
                View Files
            </Button>
        );
    }

    function renderSelectedRows(row: IAcxTableRow) {
        const directory = row as DirectoryInfoModel;
        return (
            <div key={row.id}>
                <Grid
                    container
                    direction="row"
                    className={classes.selectedItemRow}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                >
                    <Grid item xs>
                        <Typography className={classes.selectedItemText}>
                            {directory.currDirectory}
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography className={classes.selectedItemText}>
                            <span className={classes.selectedItemLabel}>
                                unSampled
                            </span>
                            : {directory.numUnsampledFiles}
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        xs
                        container
                        alignItems={"center"}
                        spacing={1}
                        wrap={"nowrap"}
                    >
                        {/* <Grid item>
                                    <Typography
                                        className={classes.selectedItemText}
                                    >
                                        <span
                                            className={
                                                classes.selectedItemLabel
                                            }
                                        >
                                            Hierarchy
                                        </span>
                                        :
                                    </Typography>
                                </Grid> */}
                        {/* <Grid item>
                                    <Breadcrumbs
                                        separator="›"
                                        aria-label="breadcrumb"
                                        className={classes.hierarchyBreadcrumb}
                                        onClick={() =>
                                            openDirectoryServiceHierarchyDialog(
                                                directory,
                                            )
                                        }
                                    >
                                        {store.hierarchyMap
                                            .get(
                                                directory.organizationStructureMemberId ??
                                                    "",
                                            )
                                            ?.map((member) => {
                                                return (
                                                    <span
                                                        key={`breadcrumb_${member.name}`}
                                                    >
                                                        {member.name}
                                                    </span>
                                                );
                                            }) ?? (
                                            <Button
                                                className={
                                                    classes.contextButton
                                                }
                                                onClick={() =>
                                                    openDirectoryServiceHierarchyDialog(
                                                        directory,
                                                    )
                                                }
                                                variant={"outlined"}
                                                color={"secondary"}
                                                size={"small"}
                                            >
                                                Add Hiearchy
                                            </Button>
                                        )}
                                    </Breadcrumbs>
                                </Grid> */}
                    </Grid>
                    <Grid item xs={1} container justifyContent={"flex-end"}>
                        <IconButton
                            size="small"
                            className={classes.selectedItemIcon}
                            onClick={() => store.unselectDirectory(directory)}
                        >
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    </Grid>
                </Grid>
            </div>
        );
    }

    const columnDefs = [
        ["Directory", "currDirectorySegment", nameFormatter],
        // ["Sampled Files", "numSampledFiles", countFormatter],
        ["Unsampled Files", "numUnsampledFiles", countFormatter],
        ["SubDirectories", "subDirectories", subDirectoriesFormatter],
        ["Unsampled In SubDirectories", "numUnsampledFilesSubDirs"],
        ["", "id", viewFilesFormatter],
    ];

    const columns = columnDefs.map((c) => {
        return {
            headerLabel: c[0],
            dataKey: c[1],
            formatter: c[2],
        } as IAcxTableColumn;
    });

    const navPath = (
        <Breadcrumbs maxItems={5} key={"breadCrumbControl"} title={"Navigator"}>
            {store.currentDirectoryPath.map((value) => {
                return (
                    <ButtonBase
                        className={classes.breadcrumbs}
                        key={value.id}
                        onClick={(event) => store.updateDirectoryPath(value)}
                    >
                        {value.currDirectorySegment?.substr(
                            0,
                            value.currDirectorySegment?.length - 1,
                        )}
                    </ButtonBase>
                );
            })}
        </Breadcrumbs>
    );

    const directoryTableControls: CustomControlItem[] = [
        {
            controlElement: (
                <Grid item container justifyContent={"flex-end"}>
                    <Observer>
                        {() => (
                            <AcxDateRangeInput
                                labelText={"Select Arrived On Date"}
                                defaultStartDate={
                                    store.datePickerStore.beginDate
                                }
                                defaultEndDate={store.datePickerStore.endDate}
                                onSelect={(start: Moment, end: Moment) => {
                                    store.datePickerStore.setBeginDate(start);
                                    store.datePickerStore.setEndDate(end);
                                }}
                            />
                        )}
                    </Observer>
                </Grid>
            ),
            xs: 9,
            sm: 8,
            md: 7,
            lg: 5,
            xl: 2,
        },
        {
            controlElement: (
                <Grid item container justifyContent={"flex-end"}>
                    <AcxButton
                        buttonDisabled={
                            store.tableStore.selectedItems.length === 0
                        }
                        color="secondary"
                        leftRightSpacing={0}
                        rootStyle={{
                            padding: "1rem",
                            minWidth: "max-content",
                        }}
                        startIcon={<AddSharp />}
                        onClick={() =>
                            openDirectoryServiceHierarchyDialog(
                                store.selectedDirectories,
                            )
                        }
                    >
                        Add Hierarch
                        {store.selectedDirectories?.length > 1 ? "ies" : "y"}
                    </AcxButton>
                </Grid>
            ),
            xs: 9,
            sm: 8,
            md: 7,
            lg: 5,
            xl: 2,
        },
    ];

    const selectedItems = store.tableStore.selectedItemIds;

    return (
        <div style={{ maxHeight: "100%" }}>
            {localDirs && (
                <DirectoryServiceHierarchyDialog
                    store={store}
                    directories={localDirs}
                    existingHierarchy={store.hierarchyMap.get(
                        localDirs?.[0]?.organizationStructureMemberId ?? "",
                    )}
                    containerRef={serviceHierachySelectorRef.current}
                />
            )}
            <AcxControlledTable
                cellDense
                enableHover
                rows={store.tableStore.filteredItems}
                enableSort
                enableCheck
                controls={directoryTableControls}
                selectionContextOptions={{
                    itemName: "Directory",
                    itemNamePluralized: "Directories",
                    action: (
                        <AcxButton
                            onClick={stepperStore.nextStep}
                            tooltip={
                                store.tableStore.selectedItems.length === 0
                                    ? "Select directories to begin configuration"
                                    : "Go to configuration"
                            }
                            buttonDisabled={
                                store.tableStore.selectedItems.length === 0
                            }
                            color={"primary"}
                        >
                            Configure Sample
                        </AcxButton>
                    ),
                    selectedRows: store.tableStore.selectedItems,
                    renderSelectedRow: renderSelectedRows,
                }}
                columns={columns}
                rowTerm={navPath}
                isLoading={store.getTaskLoading(LoadSourceDirectoriesTask)}
                selectedItems={selectedItems}
                onSelecteditems={store.tableStore.setSelectedItems}
                keyField="id"
            />
        </div>
    );
});

function subDirectoriesFormatter(subDirs: DirectoryInfoModel[] | undefined) {
    return subDirs?.length ?? 0;
}

function countFormatter(count: number) {
    return count;
}

export default DirectoryTable;
