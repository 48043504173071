import AcxConfirmationDialog from "components/UI/AcxConfirmationDialog";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { AuthStore } from "stores/AuthStore";
import { useStore } from "../../../../utils/useStore";
import { ClassifierBuilderStore } from "../ClassifierBuilderStore";
import { ClassifierBuilderEditorStore } from "../Editor/ClassifierBuilderEditorStore";
import ClassifierBuilderClassifierManagementView from "./ClassifierBuilderClassifierManagementView";

type Props = {};

const ClassifierBuilderClassifierManagement = observer((props: Props) => {
    const editorStore = useStore(ClassifierBuilderEditorStore);
    const cbStore = useStore(ClassifierBuilderStore);
    const authStore = useStore(AuthStore);

    async function handleDelete() {
        try {
            cbStore.deleteClassifier();
        } catch (error) {
            //@ts-ignore
            editorStore.messageStore.logError(error.message);
        }
    }

    async function handlePublishClassifier() {
        cbStore.toggleClassifierPublished();
    }

    function handleLoadClassifier() {
        if (cbStore.temporaryClassifier) {
            cbStore.setSelectedClassifier(cbStore.temporaryClassifier);
            cbStore.removeTemporaryValue();
        }

        cbStore.closeLoadDialog();
        const implementations =
            cbStore.selectedClassifier?.classifierImplementations || [];

        if (implementations.length < 1) {
            alert("No classifier implementations");
            return;
        }

        const implementation = implementations[0];
        if (implementation === undefined) {
            alert("Bad classifier implementation!");
            return;
        }
        const newQueryString = implementation.queryString;
        if (newQueryString === undefined) {
            alert("Classifier has no valid query string");
            return;
        }

        editorStore.setEditorQuery(newQueryString);
    }

    const selectedIsPublished = cbStore.selectedClassifier?.isPublished;
    return (
        <>
            <AcxConfirmationDialog
                isOpen={cbStore.isLoadDialogOpen}
                title="Load existing Classifier?"
                content="Loading this classifier will overwrite your current work."
                onClose={cbStore.closeLoadDialog}
                onConfirm={handleLoadClassifier}
                buttonsDisabled={cbStore.isDialogLoading}
                isLoading={cbStore.isDialogLoading}
                alternateSpinnerType="PuffLoader"
                errorMessage={cbStore.errorMessage}
            />

            <AcxConfirmationDialog
                isOpen={cbStore.isDeleteDialogOpen}
                title="Are you sure you would like to delete?"
                content={
                    <>
                        Deleting{" "}
                        <strong>{cbStore.selectedClassifier?.name}</strong>{" "}
                        cannot be reversed.
                    </>
                }
                onClose={cbStore.closeDeleteDialog}
                onConfirm={handleDelete}
                buttonsDisabled={cbStore.isDialogLoading}
                isLoading={cbStore.isDialogLoading}
                alternateSpinnerType="PuffLoader"
                errorMessage={cbStore.errorMessage}
            />

            <AcxConfirmationDialog
                isOpen={cbStore.isPublishDialogOpen}
                title={
                    selectedIsPublished
                        ? "Unpublish this classifier?"
                        : "Publish this classifier?"
                }
                content={
                    selectedIsPublished
                        ? `This will deactivate the classifier for all future interactions uploaded by your organization.`
                        : `This will activate the classifier for all future interactions uploaded by your organization.`
                }
                onClose={cbStore.closePublishDialog}
                onConfirm={handlePublishClassifier}
                buttonsDisabled={cbStore.isDialogLoading}
                isLoading={cbStore.isDialogLoading}
                alternateSpinnerType="PuffLoader"
                errorMessage={cbStore.errorMessage}
            />

            <ClassifierBuilderClassifierManagementView
                onOpenDeleteDialog={cbStore.openDeleteDialog}
                onOpenLoadDialog={cbStore.openLoadDialog}
                onOpenPublishDialog={cbStore.openPublishDialog}
                onSetSelectedClassifier={cbStore.setSelectedClassifier}
                handleLoad={handleLoadClassifier}
                selectedClassifier={cbStore.selectedClassifier}
                classifiers={toJS(cbStore.classifiers)}
                classifiersLoading={cbStore.isClassifierListLoading}
                controlsDisabled={
                    cbStore.controlsDisabled ||
                    !authStore.canUserEdit("Builder")
                }
                storeTemporaryValue={cbStore.storeTemporaryValue}
            />
        </>
    );
});

export default ClassifierBuilderClassifierManagement;
