import { Tier } from "components/Admin/Organizations/types/Tier.type";
import { DataProcessingOption } from "models/DataProcesses";
import { InteractionType } from "models/InteractionType";
import { OrganizationMetadataConfiguration } from "models/OrganizationModels/OrganizationMetadataConfiguration";
import { OrganizationStructureLevel } from "models/OrganizationModels/OrganizationStructureLevel";
import { OrganizationStructureMember } from "models/OrganizationModels/OrganizationStructureMember";
import OrganizationStorageAccount from "models/OrganizationStorageAccount";
import { ApplicationUser } from "models/Permission/ApplicationUser";
import StorageAccount from "models/StorageAccount";
import { InteractionHierarchyLevels } from "../models/InteractionHierarchyLevels";
import Organization, { IOrganization } from "../models/Organization";
import BaseService from "./BaseService";

export class HierarchyPayload {
    members: OrganizationStructureMember[];
    levels: OrganizationStructureLevel[];
}

export class OrganizationService extends BaseService {
    protected urlBase: string = "api/Organizations";

    public async getOrganizationMetadataConfiguration(): Promise<
        OrganizationMetadataConfiguration[]
    > {
        return await this.get(
            new URLSearchParams({}),
            "GetOrganizationMetadataConfiguration",
        );
    }

    public async setOrganizationMetadataConfiguration(
        orgId: string,
        config: OrganizationMetadataConfiguration[],
    ) {
        const params = new URLSearchParams({
            orgId: orgId,
        });

        const stringifiedConfig = JSON.stringify(config);

        const res = await this.post(
            stringifiedConfig,
            params,
            "SetOrganizationMetadataConfiguration",
        );

        return res;
    }

    public async listStorageAccounts(): Promise<StorageAccount[]> {
        const res = await this.get(
            new URLSearchParams({ page: "0" }),
            "GetStorageAccountList",
        );
        return res as StorageAccount[];
    }

    public async getStorageAccountUse(): Promise<string[]> {
        const res = await this.get(
            new URLSearchParams({ page: "0" }),
            "GetStorageAccountUseList",
        );
        return res as string[];
    }

    public async getDataProcessOptions(): Promise<string[]> {
        const res = await this.get(
            new URLSearchParams({ page: "0" }),
            "GetDataProcessingOptions",
        );
        return res as string[];
    }

    public async getDataProcessingList(): Promise<DataProcessingOption[]> {
        const res = await this.get(
            new URLSearchParams({ page: "0" }),
            "GetDataProcessing",
        );
        return res as DataProcessingOption[];
    }

    public async createStorageAccount(
        name: string,
        keyVaultSecretName: string,
        useName: string,
    ): Promise<string> {
        const params = new URLSearchParams({
            Name: name,
            KeyVaultSecretName: keyVaultSecretName,
            UseName: useName,
        });
        const res = await this.post(null, params, "CreateStorageAccount");
        return res;
    }

    public async getAllOrganizations<T extends boolean>(
        mapToOrgClass: T,
        page: number = 0,
        rowsPerPage: number = 100,
    ): Promise<T extends true ? Organization[] : IOrganization[]> {
        const res = await this.get(
            new URLSearchParams({
                page: page.toString(10),
                rowsPerPage: rowsPerPage.toString(10),
            }),
            "GetPagedList",
        );
        if (mapToOrgClass) {
            return res.map((value) => Organization.fromServer(value));
        }
        return res;
    }

    public async getUsersOrganization(): Promise<IOrganization[]> {
        return await this.get(new URLSearchParams({}), "");
    }

    public async createOrganization(organization: IOrganization) {
        // const params = OrganizationService.makeUrlParams(organization);
        const params = new URLSearchParams({});
        await this.post(JSON.stringify(organization), params, "");
    }

    public async updateOrganization(organization: IOrganization) {
        // const params = OrganizationService.makeUrlParams(organization);
        const params = new URLSearchParams({});
        await this.put(params, "", JSON.stringify(organization));
    }

    public async deleteOrganization(organization: IOrganization) {
        if (!organization.id) {
            throw new Error(
                `Can't delete Organization ${organization.name} without valid ID`,
            );
        }
        return await this.delete(
            new URLSearchParams({ id: organization.id }),
            "DeleteOrganization",
        );
    }

    public async deleteOrgStorage(orgStorage: OrganizationStorageAccount) {
        return await this.delete(
            new URLSearchParams({
                organizationId: orgStorage.organizationId,
                storageAccountId: orgStorage.storageAccountId,
            }),
            "DeleteOrganizationStorage",
        );
    }

    public async getInternalStructureHierarchy() {
        const apiPath = "GetInternalStructureHierarchy";
        const params = new URLSearchParams({});
        return await this.get<HierarchyPayload>(params, apiPath);
    }

    public async getInteractionHierarchyLevels(orgStructureMemberId: string) {
        const apiPath = "HierarchyLevels";
        const params = new URLSearchParams({ orgStructureMemberId });
        const payload = await this.get<InteractionHierarchyLevels[]>(
            params,
            apiPath,
        );
        return payload;
    }

    public async getAnalystGroups(analystId: string) {
        const apiPath = "AnalystGroups";
        const params = new URLSearchParams({ analystId });
        const payload = await this.get<OrganizationStructureMember[]>(
            params,
            apiPath,
        );
        return payload;
    }
    public async getTeamAnalysts(orgId: string, analystId: string) {
        const apiPath = "TeamAnalysts";
        const params = new URLSearchParams({ orgId, analystId });
        const payload = await this.get<ApplicationUser[]>(params, apiPath);
        return payload;
    }

    public async getGroupAssignments(groupId: string) {
        const apiPath = "GroupAssignments";
        const params = new URLSearchParams({ groupId });
        const payload = await this.get<{
            assignments: OrganizationStructureMember[][];
        }>(params, apiPath);
        return payload.assignments;
    }

    public async getUserAssignments(userId: string) {
        const apiPath = "UserAssignments";
        const payload = await this.post<{
            assignments: OrganizationStructureMember[][];
        }>(JSON.stringify({ userId }), new URLSearchParams({}), apiPath);
        return payload.assignments;
    }

    public async getFlattenedServiceHierarchy(orgId: string) {
        const apiPath = "FlattenedHierarchyLevels";
        const params = new URLSearchParams({ orgId });
        return await this.get<{ label: string; id: string }[]>(params, apiPath);
    }

    public async getFlattenedServiceHierarchyForUser(
        orgId: string,
        userId: string,
    ) {
        const apiPath = "FlattenedHierarchyLevelsForUser";
        const params = new URLSearchParams({ orgId, userId });
        return await this.get<{ label: string; id: string }[]>(params, apiPath);
    }

    public async getFlattenedServiceHierarchyForUserByMedia(
        mediaType: InteractionType,
    ) {
        const apiPath = "FlattenedHierarchyLevelsForUserByMedia";
        const params = new URLSearchParams({
            mediaType: mediaType.toString(),
        });
        return await this.get<{ label: string; id: string }[]>(params, apiPath);
    }

    public async getFlattenedServiceHierarchyForUserExceptLive(
        orgId: string,
        userId: string,
    ) {
        const apiPath = "FlattenedHierarchyLevelsForUserExceptLive";
        const params = new URLSearchParams({
            orgId,
            userId,
        });
        return await this.get<{ label: string; id: string }[]>(params, apiPath);
    }

    public async getOrganization(
        organizationId: string,
    ): Promise<Organization> {
        const res: Organization = await this.post(
            JSON.stringify({ organizationId }),
            new URLSearchParams({}),
            "GetOrganizationById",
        );

        res.EnableSamlSso = !!res.saml2EntityId;
        return res;
    }

    public async getSamplingTargetForOrganizationStructureMember(
        id: string,
        historyLimit: number = 10,
        historyOffset: number = 0,
    ) {
        const res = await this.get(
            new URLSearchParams({
                OrganizationStructureMemberId: id,
                HistoryLimit: JSON.stringify(historyLimit),
                HistoryOffset: JSON.stringify(historyOffset),
            }),
            "GetSamplingTargetForOrganizationStructureMember",
        );

        return res;
    }

    public async updateSamplingTarget(
        organizationStructureMemberId: string,
        samplingTargetTimePeriod: string,
        samplingTargetUnitOfMeasure: string,
        value: number,
        monitoredByUserId: string,
        hierarchyCodes?: string[],
    ) {
        const res = await this.post(
            JSON.stringify({
                OrganizationStructureMemberId: organizationStructureMemberId,
                TimePeriod: samplingTargetTimePeriod,
                UnitOfMeasure: samplingTargetUnitOfMeasure,
                Value: value,
                MonitoredByUserId: monitoredByUserId,
                HierarchyCodes: hierarchyCodes,
            }),
            new URLSearchParams({}),
            "UpdateSamplingTarget",
        );

        return res;
    }

    public async getTopLevelServiceHierarchiesForOrganization(): Promise<{
        organizationId: string;
        tiers: Tier[];
    }> {
        const params = new URLSearchParams({});
        const res = await this.get(
            params,
            "GetTopLevelServiceHierarchiesForOrganization",
        );

        return res;
    }

    public async getAvailableParents(
        organizationId: string,
        parentId: string,
        branchDepth: number,
    ): Promise<Tier[]> {
        const body = JSON.stringify({
            organizationId,
            parentId,
            branchDepth,
        });

        const res = await this.post(
            body,
            new URLSearchParams({}),
            "GetAvailableParents",
        );

        return res;
    }

    public async getServiceHierarchyLeaf(osmId: string): Promise<Tier[]> {
        const params = new URLSearchParams({ osmId });
        const res = await this.get(params, "GetServiceHierarchyLeaf");

        return res;
    }

    public async reassignTier(
        orgId: string,
        parentId: string,
        childId: string,
    ) {
        const params = new URLSearchParams({});
        const body = { orgId, parentId, childId };

        const res = await this.put(
            params,
            "ReassignTier",
            JSON.stringify(body),
        );

        return res;
    }

    public async createTier(orgId: string, newBranch: Tier, parent?: Tier) {
        const params = new URLSearchParams({});
        const body = {
            orgId,
            parent,
            newBranch,
        };

        const res = await this.post(JSON.stringify(body), params, "CreateTier");

        return res;
    }

    public async updateTier(orgId: string, branchId: string, branch: Tier) {
        const params = new URLSearchParams({});
        const body = {
            orgId,
            branchId,
            branch,
        };

        const res = await this.put(params, "UpdateTier", JSON.stringify(body));

        return res;
    }

    public async deleteTier(
        orgId: string,
        branchId: string,
        parentBranch?: Tier,
    ) {
        const params = new URLSearchParams({});
        const body = {
            orgId,
            branchId,
            parentBranch,
        };

        const res = await this.delete(
            params,
            "DeleteTier",
            JSON.stringify(body),
        );

        return res;
    }

    public getPipelineProcessingOption = async (orgId: string) => {
        const params = new URLSearchParams({ orgId });
        const response = await this.get(params, "PipelineProcessingOption");

        return response; // For all customers other than Anthem, response will be null. If not null, and property is OnDemand, show On-Demand Sample.
    };

    public getPipelineProcessingOptions = async () => {
        const params = new URLSearchParams({});
        const response = await this.get(params, "GetPipelineProcessingOptions");

        return response; // Enum for our processing options
    };
}
