import Evaluation from "models/Evaluation";
import { WorkflowConditionType } from "../components/Admin/Organizations/OrganizationDetail/OrganizationWorkflows/Types/WorkflowModels";
import { WorkflowMessagingMetadata } from "../models/Workflows/WorkflowMessagingMetadata";
import BaseService from "./BaseService";
import { SoundClipMedia } from "./SoundClipService";
import { HighlightsForTagResults } from "models/HighlightsForTagResults";

export interface EvaluationServiceHierarchy {
    evaluation: Evaluation;
    serviceHierarchy?: string[];
    agentName: string;
}

export class EvaluationUpdateResponse {
    evaluation: Evaluation;
    workflowResponse: {
        approveSignal: string;
        rejectSignal: string;
        workflowInstanceId: string;
    };
    moduleScoresMap: Map<string, number>;
    moduleScores: string;
}

export interface WorkflowMetadata extends Partial<WorkflowMessagingMetadata> {
    level: number;
    id: string;
    licensedModuleId: string;
    type: WorkflowConditionType;
    name: string;
}

export interface EvaluationAnswerHistory {
    answerHistoryObjectsList: AnswerHistoryObject[];
    moduleId: string;
}

export interface AnswerHistoryObject {
    answerHistoriesList: AnswerHistoryItem[];
    questionId: string;
    questionText: string;
}

export interface AnswerHistoryItem {
    previousValues: string[];
    currentValues: string[];
    changedBy: string;
    changedOn: string;
}

export class EvaluationRequestModel {
    evaluation: Evaluation;
    workflowMetadata: Record<string, WorkflowMetadata[]>;
    permissions?: IEvalPermissionsModel;
    errorCode?: string;
    mediaUrl: string;
    isRedacted?: boolean;
    soundClipMedia: SoundClipMedia[];
}

export interface IEvalPermissionsModel {
    canChangeDisputedAnswers: boolean;
    canConvertToAnalystEvaluation: boolean;
    canDispute: boolean;
    canEdit: boolean;
}

export class EvaluationService extends BaseService {
    protected urlBase: string = "api/Evaluations";

    public async getEvalGraph(organizationId: string, evaluationId: string) {
        const res = await this.post<EvaluationRequestModel>(
            JSON.stringify({ organizationId, evaluationId }),
            new URLSearchParams({}),
            "GetEval",
        );

        return res;
    }

    public async getHighlightsForTag(tagId: string, amdId: string) {
        const res = await this.get<HighlightsForTagResults>(
            new URLSearchParams({
                tagId,
                amdId,
            }),
            "GetHighlightsForTag",
        );

        return res;
    }

    public async startNextEval(analystId: string, hierarchyId: string) {
        const res = await this.get<{ id: string | null }>(
            new URLSearchParams({
                analystId: analystId,
                hierarchyId: hierarchyId,
            }),
            "Start",
        );

        return res;
    }

    public async generateLiveEval(analystId: string, orgMemberId: string) {
        const res = await this.get<{ id: string | null }>(
            new URLSearchParams({
                analystId: analystId,
                orgStructureMemberId: orgMemberId,
            }),
            "StartLiveEval",
        );

        return res;
    }

    public async getEvalByEvaluationNumber(evalNumber: number, orgId: string) {
        const resp = await this.get<string>(
            new URLSearchParams({
                evaluationNumber: evalNumber.toString(),
                orgId: orgId,
            }),
            "GetEvalByEvalNumber",
        );

        return resp;
    }

    public async getEvalsForAnalyst(
        analystId: string,
        startDate: string,
        endDate: string,
        onlyComplete: boolean,
        debounceKey?: string,
    ) {
        const res = await this.get<EvaluationServiceHierarchy[]>(
            new URLSearchParams({
                analystId: analystId,
                startDate: startDate,
                endDate: endDate,
                onlyComplete: JSON.stringify(onlyComplete),
            }),
            "AnalystEvaluations",
            undefined,
            debounceKey,
        );

        return res;
    }

    public async updateEvalGraph(evaluation: EvaluationUpdateResponse) {
        const body = JSON.stringify(evaluation);
        const urlSearchParams: URLSearchParams = new URLSearchParams();
        const res = await this.put(urlSearchParams, "UpdateEvalNew", body);
        return res;
    }

    public async updateEvalHierarchy(
        evaluationId: string,
        hierarchyId: string,
    ) {
        const urlSearchParams: URLSearchParams = new URLSearchParams({
            evaluationId,
            hierarchyId,
        });
        const res = await this.put(urlSearchParams, "UpdateEvalHierarchy");
        return res;
    }

    public async getAnswerHistoryForEval(evalId: string) {
        const res = await this.get<EvaluationAnswerHistory[]>(
            new URLSearchParams({
                evalId,
            }),
            "GetAnswerHistoryForEval",
        );

        return res;
    }

    public async convertToAnalystEvaluation(
        evaluationId: string,
        orgId: string,
        analystId: string,
        isMinimal: boolean,
    ) {
        await this.patch<EvaluationRequestModel>(
            null,
            new URLSearchParams({
                evaluationId,
                orgId,
                analystId,
                isMinimal: isMinimal.toString(),
            }),
            "ConvertToAnalystEvaluation",
        );
    }

    public async acknowledgeEvaluation(evalId: string) {
        await this.get(
            new URLSearchParams({
                evalId,
            }),
            "Acknowledge",
        );
    }

    public async addAnalystTimeSpent(
        evaluationId: string,
        timeInSeconds: number,
    ) {
        return this.patch(
            null,
            new URLSearchParams({
                evaluationId,
                timeInSeconds: timeInSeconds.toString(),
            }),
            "AddAnalystTimeSpent",
            undefined,
            undefined,
            undefined,
            {
                // ensures request isnt cancelled when tab is closed
                keepalive: true,
            },
        );
    }
}
