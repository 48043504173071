import { Theme } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ScoredNGram } from "models/NGram";
import React, { useMemo } from "react";
import WordCloud, {
    Callbacks,
    MinMaxPair,
    Optional,
    Options,
    WordEventCallback,
} from "react-wordcloud";
import "tippy.js/animations/scale.css";
import "tippy.js/dist/tippy.css";

interface Props {
    mainColor: string;
    data?: ScoredNGram[];
    onWordClick?: WordEventCallback;
    alternateTooltip?: boolean;
    maxWords?: number;
}

const colors = ["secondary", "primary", "secondary", "orange"];

function getCallback(callback: string, mainColor: string, theme: Theme) {
    return function (word, event) {
        const isActive = callback !== "onWordMouseOut";
        const element = event.target as HTMLElement;
        element.setAttribute("background", "white");

        let hoverColor;
        if (isActive) {
            const currColor = mainColor;
            hoverColor = colors
                .map((value) => theme.palette[value].main)
                .filter((value) => value !== currColor)
                .shift();
        } else {
            hoverColor = mainColor;
        }

        element.setAttribute("fill", hoverColor);
        element.setAttribute(
            "text-decoration",
            isActive ? "underline" : "none",
        );

        const multiplier = isActive ? 1.42 : 1 / 1.42;
        const elemA = (element as any).transform.baseVal[0].matrix.a;
        const elemd = (element as any).transform.baseVal[0].matrix.d;

        if (
            (element as any)?.transform?.baseVal?.[0]?.matrix?.a !== undefined
        ) {
            (element as any).transform.baseVal[0].matrix.a = multiplier * elemA;
        }

        if (
            (element as any)?.transform?.baseVal?.[0]?.matrix?.d !== undefined
        ) {
            (element as any).transform.baseVal[0].matrix.d = multiplier * elemd;
        }
    };
}

function AcxWordCloud(props: Props) {
    const theme = useTheme();
    const words = useMemo(
        () =>
            props.data?.map((d) => {
                return { text: d.nGram, value: d.score };
            }),
        [props.data],
    );

    const options: Optional<Options> = useMemo(
        () => ({
            colors: [props.mainColor],
            rotations: 0,
            rotationAngles: [0, 0] as MinMaxPair,
            fontFamily: "Inter",
            fontSizes: [12, 36],
            deterministic: true,
            enableOptimizations: true,
        }),
        [props.mainColor],
    );

    const callbacks: Callbacks = useMemo(
        () => ({
            getWordTooltip: props.alternateTooltip
                ? (w) => `${w.text}`
                : (w) => `${w.value} appearances`,
            onWordClick: props.onWordClick,
            onWordMouseOut: getCallback(
                "onWordMouseOut",
                props.mainColor,
                theme,
            ),
            onWordMouseOver: getCallback(
                "onWordMouseOver",
                props.mainColor,
                theme,
            ),
        }),
        [props.alternateTooltip, props.mainColor, props.onWordClick, theme],
    );

    if (!words) {
        return <></>;
    }

    return (
        <WordCloud
            words={words}
            options={options}
            callbacks={callbacks}
            maxWords={props?.maxWords}
        />
    );
}

export default AcxWordCloud;
