import { observer } from "mobx-react";
import React from "react";
import { IApplicationFilterComponent } from "../Filters/IApplicationFilterComponent";
import { basicDateReferenceOptions } from "stores/ComponentStores/DatePickerComponentStore";
import AcxDateRangeInput from "components/UI/Calendar/DateRange/AcxDateRangeInput";
import { Moment } from "moment";

export const DateRangeFilter = observer(function ({
    store,
    onSelect,
    ...props
}: IApplicationFilterComponent &
    Omit<
        React.ComponentProps<typeof AcxDateRangeInput>,
        "datePickerStore" | "defaultStartDate" | "defaultEndDate"
    >) {
    return (
        <AcxDateRangeInput
            hideLabel
            sideBar
            doubleCalendar
            dateReferenceOptions={basicDateReferenceOptions}
            maxWidth="none"
            {...props}
            datePickerStore={store.datePickerStore}
            defaultStartDate={store.datePickerStore.beginDate.startOf("day")}
            defaultEndDate={store.datePickerStore.endDate}
            onSelect={(start: Moment, end: Moment) => {
                store.setStartDate(start);
                store.setEndDate(end);
                onSelect?.(start, end);
            }}
        />
    );
});
