export function symmetricDifference<T>(
    existingItems: Set<T>,
    newItems: Set<T>,
) {
    const newlyAdded = new Set(
        [...newItems].filter((x) => !existingItems.has(x)),
    );
    const removed = new Set([...existingItems].filter((x) => !newItems.has(x)));

    const intersection = new Set(
        [...existingItems].filter((x) => newItems.has(x)),
    );

    return [newlyAdded, intersection, removed] as const;
}

export function symmetricDifferenceBy<T, U, K>(
    existingItems: T[],
    newItems: U[],
    existingKeySelector: (arg: T) => K,
    newKeySelector: (arg: U) => K,
) {
    const existingItemsSet = new Set(existingItems.map(existingKeySelector));
    const newItemsSet = new Set(newItems.map(newKeySelector));

    return symmetricDifference(existingItemsSet, newItemsSet);
}
