import { IChatRecord } from "components/UI/Chat/AcxChatViewer";
import BaseEntity from "./BaseEntity";
import { ConversationClassifier } from "services/ConversationService";
import type { Highlight } from "services/ConversationService";
import { EvalType } from "./Evaluation";
import { InteractionType } from "./InteractionType";
import {
    IMultiLanguageTranscriptionPayload,
    ITranscriptionPayload,
    ITranscriptionSegment,
} from "services/TranscriptionService";
import { computed, makeObservable, observable } from "mobx";
import { AdverseEventP2Results } from "./AdverseEventP2Results";
import { EddyEffectP2Results } from "./EddyEffectP2Results";
import { emptyGUID } from "components/Admin/Organizations/OrganizationDetail/OrganizationModules/OrganizationModuleDetails/OrganizationModuleQuestionDependencies";
import { getTranscriptByLangCode } from "utils/Utils";

export interface ConversationParameters {
    callDurationMillis: number;
    chatTranscription: IChatRecord[];
    conversationClassifiers: ConversationClassifier[];
    conversationId: string;
    evalType: EvalType | null;
    mediaType: InteractionType;
    mediaUrl: string;
    summary: string;
    timestamp: string;
    transcriptions: ITranscriptionPayload[];
    agentFirstName: string;
    agentLastName: string;
    evaluationId: string;
    hasTranslation: boolean;
    isRedacted: boolean;
    highlights: Highlight;
    evaluationNumber: number;
    evaluationAgentCoachingId?: string;
    agentId: string;
    hierarchyId: string;
}

export default class Conversation extends BaseEntity {
    callDurationMillis: number;
    chatTranscription: IChatRecord[];
    conversationClassifiers: ConversationClassifier[];
    conversationId: string;
    evalType: EvalType | null;
    mediaType: InteractionType;
    mediaUrl: string;
    summary: string;
    timestamp: string;
    transcriptions: ITranscriptionPayload[];
    agentFirstName: string;
    agentLastName: string;
    evaluationId: string;
    hasTranslation: boolean;
    isRedacted: boolean;
    highlights: Highlight;
    evaluationNumber: number;
    evaluationAgentCoachingId?: string;
    agentId: string;
    hierarchyId: string;

    @observable multiLangTranscriptions?: IMultiLanguageTranscriptionPayload[];
    @observable
    languageCode: IMultiLanguageTranscriptionPayload["languageCode"] = "en";

    constructor(conversation: ConversationParameters) {
        super();
        makeObservable(this);

        this.agentFirstName = conversation.agentFirstName;
        this.agentLastName = conversation.agentLastName;
        this.callDurationMillis = conversation.callDurationMillis;
        this.chatTranscription = conversation.chatTranscription;
        this.conversationClassifiers = conversation.conversationClassifiers;
        this.conversationId = conversation.conversationId;
        this.evalType = conversation.evalType;
        this.evaluationId = conversation.evaluationId;
        this.evaluationNumber = conversation.evaluationNumber;
        this.evaluationAgentCoachingId = conversation.evaluationAgentCoachingId;
        this.agentId = conversation.agentId;
        this.hasTranslation = conversation.hasTranslation;
        this.hierarchyId = conversation.hierarchyId;
        this.highlights = conversation.highlights;
        this.isRedacted = conversation.isRedacted;
        this.mediaType = conversation.mediaType;
        this.mediaUrl = conversation.mediaUrl;
        this.summary = conversation.summary;
        this.timestamp = conversation.timestamp;
        this.transcriptions = conversation.transcriptions;
    }

    @computed
    get hasEval() {
        return this?.evaluationId !== emptyGUID || this.evalType !== null;
    }

    @computed
    get isChat(): boolean {
        return !!this.chatTranscription;
    }

    @computed
    get eddyData(): EddyEffectP2Results[] {
        const eddyData = this.conversationClassifiers.find(
            (item) => item.name === "ML-EddyEffect-P2",
        );

        if (eddyData) {
            const parsedResult: number[][] | null = JSON.parse(eddyData.result);
            if (!parsedResult) {
                return [];
            } else {
                // translate to structure of other endpoint getEddyEffectP2Results
                return parsedResult.map((item) => ({
                    startWordIndex: item[0],
                    endWordIndex: item[1],
                }));
            }
        }
        return [];
    }

    @computed
    get adverseEventData(): AdverseEventP2Results[] {
        const adverseEventData = this.conversationClassifiers.find(
            (item) => item.name === "ML-AdverseEvents-P2",
        );
        if (adverseEventData) {
            const parsedResult: number[][] | null = JSON.parse(
                adverseEventData.result,
            );

            if (!parsedResult) {
                return [];
            } else {
                // translate to structure of other endpoint getEddyEffectP2Results
                return parsedResult.map((item) => ({
                    startWordIndex: item[0],
                    endWordIndex: item[1],
                }));
            }
        }
        return [];
    }

    @computed
    get hasAdverseEvent(): { result: boolean; containsExamples: boolean } {
        const adverseEventData = this.conversationClassifiers.find(
            (item) => item.name === "ML-AdverseEvents",
        );
        return {
            result: adverseEventData?.result === "true",
            containsExamples: this.adverseEventData.length > 0,
        };
    }

    @computed
    get hasEddyEffect(): { result: boolean; containsExamples: boolean } {
        const eddyData = this.conversationClassifiers.find(
            (item) => item.name === "ML-EddyEffect",
        );
        return {
            result: eddyData?.result === "true",
            containsExamples: this.eddyData.length > 0,
        };
    }

    @computed
    get audioTranscription(): ITranscriptionSegment[] {
        return (
            getTranscriptByLangCode(
                this.languageCode,
                this.multiLangTranscriptions,
            ) ?? []
        );
    }

    static fromJSON(conversation: Conversation): Conversation {
        const convo = new Conversation(conversation);

        return convo;
    }
}
