import { uuidv4 } from "utils/helpers";
import { MessageAuthor } from "./MessageAuthor";
import { ThreadMessage } from "./ThreadMessage";
import { action, makeObservable, observable } from "mobx";
import type {
    ThreadServerSuggestion,
    ThreadSuggestion,
    ThreadSuggestionAction,
} from "./ThreadSuggestion";
import type { ThreadActionsMap } from "./ThreadAction";

export interface IThreadMessageGroup {
    readonly id: string;
    author: MessageAuthor;
    messages: ThreadMessage[];
    suggestions?: ThreadSuggestion[];
    actions?: ThreadActionsMap;
    transientMessage?: ThreadMessage;
}

/**
 * Represents a collection of messages sent by an individual author.
 */
export class ThreadMessageGroup implements IThreadMessageGroup {
    public readonly id: string;
    @observable author: MessageAuthor;
    @observable messages: ThreadMessage[];
    @observable suggestions?: ThreadSuggestion[];
    @observable actions?: ThreadActionsMap;

    /**
     * A message, managed by the client, to show domain specific or temporary information based
     * on certain conditions.
     */
    @observable transientMessage?: ThreadMessage;

    constructor(author: MessageAuthor) {
        makeObservable(this);

        this.id = uuidv4();
        this.author = author;
        this.messages = [];
        this.suggestions = [];
    }

    public addMessage(message: ThreadMessage): ThreadMessage;
    public addMessage(content: string): ThreadMessage;
    @action
    public addMessage(messageOrContent: string | ThreadMessage): ThreadMessage {
        let message: ThreadMessage;

        if (typeof messageOrContent === "string")
            message = new ThreadMessage(messageOrContent);
        else message = messageOrContent;

        this.messages.push(message);
        return message;
    }

    @action
    public setActions(actions: ThreadActionsMap) {
        this.actions = actions;
    }

    public addSuggestion(suggestion: ThreadSuggestion): ThreadSuggestion;
    public addSuggestion(
        content: string,
        action: ThreadSuggestionAction,
    ): ThreadSuggestion;
    @action
    public addSuggestion(
        suggestionOrContent: string | ThreadSuggestion,
        action?: ThreadSuggestionAction,
    ): ThreadSuggestion {
        let suggestion: ThreadSuggestion;

        if (typeof suggestionOrContent === "string") {
            if (!action) throw new Error("An action must be provided");
            suggestion = { content: suggestionOrContent, action };
        } else suggestion = suggestionOrContent;

        if (!this.suggestions) this.suggestions = [];
        this.suggestions.push(suggestion);
        return suggestion;
    }

    public addServerSuggestion(
        serverSuggestion: ThreadServerSuggestion,
    ): ThreadSuggestion {
        const suggestion: ThreadSuggestion =
            this.compileServerSuggestion(serverSuggestion);
        if (!this.suggestions) this.suggestions = [];
        this.suggestions.push(suggestion);
        return suggestion;
    }

    public addServerSuggestions(
        serverSuggestions: ThreadServerSuggestion[],
    ): ThreadSuggestion[] {
        const suggestions: ThreadSuggestion[] = [];
        for (const serverSuggestion of serverSuggestions)
            suggestions.push(this.addServerSuggestion(serverSuggestion));
        return suggestions;
    }

    private compileServerSuggestion(
        serverSuggestion: ThreadServerSuggestion,
    ): ThreadSuggestion {
        return {
            content: serverSuggestion.content,
            action: this.createActionCallbackFromServerSuggestion(
                serverSuggestion,
            ),
        };
    }

    private createActionCallbackFromServerSuggestion(
        suggestion: ThreadServerSuggestion,
    ): ThreadSuggestionAction {
        if (!suggestion.action)
            return (store, s) =>
                store.streamingSubmitInput({
                    manualInput: s.content,
                    suggestion: true,
                });

        if (suggestion.action.type === "navigate") {
            const data = suggestion.action.data;
            if (
                suggestion.action.domain === "conversations" &&
                Object.hasOwn(data, "conversationIds")
            ) {
                return async (store) => {
                    await store.localStorage.setItem(
                        "agentConversationIds",
                        data["conversationIds"],
                    );
                    window.open(
                        `${window.location.origin}/app/conversations?agent=true`,
                    );
                };
            }
        }

        return () => {
            console.warn(
                "Server suggestion action not implemented yet",
                suggestion,
            );
        };
    }

    @action
    public setSuggestions(suggestions: ThreadSuggestion[]) {
        this.suggestions = suggestions;
    }

    @action
    public clearSuggestions() {
        this.suggestions = [];
    }

    @action
    public setTransientMessage(
        content: string,
        suggestions?: ThreadSuggestion[],
    ) {
        this.transientMessage = new ThreadMessage(content);
        if (suggestions) this.setSuggestions(suggestions);
    }

    /**
     * If a transient message is set, it is added to the `messages` array and
     * `clearTransientMessage` is called.
     */
    @action
    public commitTransientMessage() {
        var message = this.transientMessage;
        if (!message) return;
        this.addMessage(message);
        this.clearTransientMessage();
        return message;
    }

    @action
    public clearTransientMessage() {
        this.transientMessage = undefined;
    }
}
