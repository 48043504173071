import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import { action, makeObservable, observable, runInAction } from "mobx";
import AzureTranscription from "models/AzureTranscription";
import { TranscriptionService } from "services/TranscriptionService";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";

const transcriptionService = new TranscriptionService();

@AcxStore
export default class TranscriptionStatusStore {
    messageStore: MessageStore;

    @observable loading = false;
    @observable transcriptions: AzureTranscription[] = [];

    constructor(rootStore: IRootStore) {
        makeObservable(this);
        this.messageStore = rootStore.getStore(MessageStore);
        this.reloadTranscriptions();
    }

    @action reloadTranscriptions = async () => {
        this.loading = true;
        try {
            var txs = await transcriptionService.getTranscriptions();
        } catch (ex) {
            this.loading = false;
            this.messageStore.logError(ex as string);
        }
        runInAction(() => {
            this.transcriptions = txs;
            this.loading = false;
        });
    };

    @action clearCompleted = async () => {
        this.loading = true;
        try {
            await transcriptionService.clearFinishedTranscriptions();
        } catch (ex) {
            this.loading = false;
            console.error(ex);
            // this.messageStore.logError(ex);
            return;
        }
        this.reloadTranscriptions();
    };
}
