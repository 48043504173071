import { makeObservable, observable, reaction, runInAction } from "mobx";
import { Moment } from "moment";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";
import { AgentCoachingService } from "../../../../services/AgentCoachingService";
import { BaseStore } from "../../../../stores/BaseStore";
import {
    DatePickerComponentStore,
    DateReferenceOption,
} from "../../../../stores/ComponentStores/DatePickerComponentStore";
import { OrgSelectorComponentStore } from "../../../../stores/ComponentStores/OrgSelectorComponentStore";
import { toIsoByDateReference } from "../../../../utils/DateTimeUtils";
import AcxDataGridStore from "../../../UI/AcxDataGrid/AcxDataGridStore";
import { dateColumnType } from "components/UI/AcxDataGrid/ColumnTypes/DateColTypes";

@AcxStore
export class AgentCoachingStore extends BaseStore {
    private readonly agentCoachingService: AgentCoachingService =
        new AgentCoachingService();
    readonly dgStore = new AcxDataGridStore(
        "agentCoachingStore",
        "AgentCoaching",
    );
    @observable orgSelectorStore: OrgSelectorComponentStore =
        new OrgSelectorComponentStore();
    readonly datePickerStore = new DatePickerComponentStore(
        undefined,
        undefined,
        DateReferenceOption.InteractionDate,
    );

    @observable coachingDetails: { label: string; value: string }[] = [];
    @observable scoredModuleNames?: string[];

    constructor(private rootStore: IRootStore) {
        super("Agent Coaching Store");
        makeObservable(this);

        this.dgStore.checkboxSelection = false;

        reaction(
            (r) => this.anyTaskLoading,
            (arg) => {
                this.dgStore.setLoading(arg);
            },
        );

        reaction(
            (r) => ({
                orgId: this.orgSelectorStore.orgId,
                startDate: this.datePickerStore.beginDate,
                endDate: this.datePickerStore.endDate,

                activeLocation: this.rootStore.activeLocation,
                dateReference: this.datePickerStore.referenceOption,
            }),
            (arg) => {
                if (
                    arg.activeLocation &&
                    arg.activeLocation.location !== "/app/agentcoaching"
                ) {
                    return;
                }

                if (!arg.orgId) {
                    return;
                }
                const orgId = arg.orgId;
                this.setupAsyncTask("Load Agent Coaching Metrics", () =>
                    this.loadAgents(
                        orgId,
                        arg.startDate,
                        arg.endDate,
                        arg.dateReference,
                    ),
                );
            },
            { fireImmediately: true },
        );
    }

    private async loadAgents(
        orgId: string,
        startDate: Moment,
        endDate: Moment,
        dateReference: DateReferenceOption,
    ) {
        const { beginDate: start, endDate: end } = toIsoByDateReference(
            startDate,
            endDate,
            dateReference,
        );

        const res = await this.agentCoachingService.getAgents(
            start!,
            end!,
            dateReference,
        );

        //Need this here to get the dates formatted and filter
        res.cols = res.cols.map((item) => {
            if (item.field === "startDate") {
                return {
                    ...item,
                    ...dateColumnType,
                };
            }

            return item;
        });

        runInAction(() => {
            this.dgStore.rows = res.rows;
            this.dgStore.setColumns(res.cols);
            this.coachingDetails = res.details;
        });
    }
}
