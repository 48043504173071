import { Grid, IconButton, Theme, Tooltip, Typography } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LaunchIcon from "@mui/icons-material/Launch";
import ACXCard from "components/UI/ACXCard";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import AcxToast from "components/UI/AcxToast";
import React from "react";
import ReactTextFormat from "react-text-format";
import { ITranscriptionSegment } from "services/TranscriptionService";
import useStyles from "Styles/Styles";

const WINDOW_PADDING = 2; // Number of segments to show on each side of currently playing
const WINDOW_SIZE = WINDOW_PADDING * 2 + 1;

const styles = (theme: Theme) =>
    createStyles({
        mainCard: {
            minHeight: "300px",
            maxHeight: "700px",
            overflow: "auto",
        },
        keyword: {
            fontSize: "16px",
            color: theme.palette.primary.main,
            fontWeight: "bold",
        },
        richTextShow: {
            display: "block",
        },
        richTextHide: {
            display: "none",
        },
        currentSegment: {
            color: theme.palette.secondary.main,
        },
    });

interface OwnProps {
    segments?: ITranscriptionSegment[];
    currentlyPlayingSegmentIdx: number | null;
    highlightKeywords: string[];
    showAll: boolean;
    onClassifyClick: () => void;
    toggleShowAll: () => void;
    errorMessage?: string;
    isLoading: boolean;
}

export default function TranscriptionVisualTranscriptionView(props: OwnProps) {
    const classes = useStyles(styles);

    const redactString = (text: string): string => {
        const txtAr = Array.from(text);
        const txtRe = txtAr.map((_val) => {
            return "_";
        });
        return txtRe.join("");
    };

    const keywordDecorator = (decoratedText: string): React.ReactNode => {
        let textToDecorate: string = decoratedText;
        if (decoratedText === "animation" || decoratedText === "fanimation") {
            textToDecorate = redactString(decoratedText);
        }
        return (
            <Typography color="primary" component="span">
                {textToDecorate}
            </Typography>
        );
    };

    function renderSegment(segment: ITranscriptionSegment, index: number) {
        return (
            <div key={"segment_" + index}>
                <ReactTextFormat
                    termDecorator={keywordDecorator}
                    terms={props.highlightKeywords}
                >
                    <Typography
                        component="span"
                        className={
                            index === props.currentlyPlayingSegmentIdx
                                ? classes.currentSegment
                                : ""
                        }
                    >
                        {segment.text}
                    </Typography>
                </ReactTextFormat>
            </div>
        );
    }

    function renderContent() {
        if (props.isLoading) {
            return (
                <AcxLoadingIndicator
                    color="secondary"
                    alternate="PuffLoader"
                    size={50}
                />
            );
        }

        if (
            props.errorMessage ||
            props.segments === null ||
            props.segments === undefined
        ) {
            const message = props.errorMessage ?? "Transcription was empty";
            return (
                <AcxToast
                    severity="error"
                    title="Error loading transcription text"
                    message={message}
                />
            );
        }

        let window_start = 0; // window start is inclusive
        let window_end = props.segments.length; // window_end is exclusive

        if (!props.showAll) {
            // Create a window over the segments of size WINDOW_SIZE
            window_end = Math.min(WINDOW_SIZE, props.segments.length);
            if (props.currentlyPlayingSegmentIdx !== null) {
                window_end = Math.min(
                    Math.max(
                        props.currentlyPlayingSegmentIdx + WINDOW_PADDING + 1,
                        WINDOW_SIZE,
                    ),
                    props.segments.length,
                );
                window_start = Math.max(
                    Math.min(
                        props.currentlyPlayingSegmentIdx - WINDOW_PADDING,
                        window_end - WINDOW_SIZE,
                    ),
                    0,
                );
            }
        }

        return (
            <div
                className="pendo-ignore"
                style={{ height: "100%", overflow: "scroll" }}
            >
                {props.segments.map((segment, s) => {
                    if (s >= window_start && s < window_end)
                        return renderSegment(segment, s);
                    else return null;
                })}
            </div>
        );
    }

    return <>
        <ACXCard
            mainClassName={classes.mainCard}
            title={<Typography variant="h5">Transcribed Text</Typography>}
            controls={
                <Grid container className="pendo-ignore">
                    <Grid item>
                        <Tooltip title="Process and Redact">
                            <IconButton onClick={props.onClassifyClick} color="secondary" size="large">
                                <LaunchIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip
                            title={
                                props.showAll
                                    ? "Collapse Text"
                                    : "Expand Text"
                            }
                        >
                            <IconButton onClick={props.toggleShowAll} color="secondary" size="large">
                                {props.showAll ? (
                                    <ExpandLessIcon />
                                ) : (
                                    <ExpandMoreIcon />
                                )}
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            }
            content={renderContent()}
        />
    </>;
}
