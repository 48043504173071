import React from "react";
import AcxChip from "./AcxChip";
import { Grid, Theme, Tooltip } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import AcxButton from "./AcxButton";
import AcxMainTextField from "components/UI/AcxMainTextField";
import useStyles from "Styles/Styles";

interface OwnProps {
    size: "small" | "medium";
    color: "green" | "red" | "blue" | "gray";
    tooltip?: React.ReactNode;
    values?: string[];
    value?: string;
    placeholderText?: string;
    isDisabled?: boolean;
    btnDisabled?: boolean;
    disableTooltip?: boolean;
    labelText?: string;
    loading?: boolean;
    onDelete?: (value: string) => void;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onClick?: () => void;
}

const styles = (theme: Theme) =>
    createStyles({
        chipListWrapper: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            maxWidth: "100%",
            marginTop: "0.5rem",
        },
        chipContainer: {
            flexShrink: 1,
            overflow: "hidden",
        },
    });

const AcxChipListInput = (props: OwnProps) => {
    const getStyle = () => {
        return { width: "100%", overflow: "hidden" };
    };

    const classes = useStyles(styles);
    return (
        <Grid
            container
            item
            alignItems="center"
            style={{ marginTop: "0.5rem" }}
        >
            <Tooltip title={props.tooltip ?? ""}>
                <Grid item>
                    <AcxMainTextField
                        isDisabled={props.isDisabled}
                        disableTooltip={props.disableTooltip}
                        showAllErrors
                        onChange={props.onChange}
                        id="chip-list-input"
                        labelText={props.labelText}
                        placeholderText={props.placeholderText}
                        value={props.value}
                        containerStyle={{
                            width: "190px",
                        }}
                        textareaRootStyles={{
                            marginRight: 0,
                            height: "32px",
                        }}
                        type="text"
                    />
                </Grid>
            </Tooltip>
            <AcxButton
                loading={props.loading}
                leftRightSpacing={1}
                onClick={props.onClick}
                fullWidth={false}
                buttonDisabled={props.btnDisabled}
                containerStyle={{ width: "min-content", top: "10px" }}
                color="secondary"
            >
                Add
            </AcxButton>
            <div className={classes.chipListWrapper} style={getStyle()}>
                {props.values &&
                    props.values?.map((value) => (
                        <div key={`${value}`} className={classes.chipContainer}>
                            <AcxChip
                                key={value}
                                size={props.size}
                                color={props.color}
                                label={value}
                                onDelete={() =>
                                    props.onDelete && props.onDelete(value)
                                }
                                style={{ marginRight: 10 }}
                            />
                        </div>
                    ))}
            </div>
        </Grid>
    );
};

export default AcxChipListInput;
