import { CircularProgress, Theme, Tooltip } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import { ToggleButton } from "@mui/material";
import clsx from "clsx";
import React from "react";
import useStyles from "Styles/Styles";
import hexToRGB from "utils/hexToRGB";

export interface IAcxButtonProps {
    onClick?: () => void;
    buttonDisabled: boolean;
    color: "inherit" | "primary" | "secondary" | "default" | undefined;
    children: any;
    size?: "small" | "medium" | "large" | undefined;
    tooltip?: string;
    fullWidth: boolean;
    loading?: boolean;
    marginRightAuto?: boolean;
    marginLeftAuto?: boolean;
    rootStyle?: Object;
    buttonClass?: string;
    containerStyle?: Object;
    leftRightSpacing: number;
    startIcon?: React.ReactNode;
    height?: string;
    id?: string;
    customRootStyles?: React.CSSProperties;
    selected?: boolean;
}
export default function AcxStandaloneToggleButton(props: IAcxButtonProps) {
    const styles = (theme: Theme) =>
        createStyles({
            root: {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                color: theme.palette.white.main,
                fontFamily: theme.typography.fontFamily,
                backgroundColor: "#3564D5",
                fontWeight: "bold",
                letterSpacing: 0,
                textAlign: "center",
                lineHeight: "20px",
                fontSize: "13px",
                textTransform: "none",
                marginLeft: theme.spacing(props.leftRightSpacing),
                marginRight: theme.spacing(props.leftRightSpacing),
                height: 30,
                "&:hover": {
                    backgroundColor: ["primary", "secondary"].includes(
                        props.color as any,
                    )
                        ? theme.palette[props.color as any].light
                        : hexToRGB(theme.palette.secondary.main, 0.5),
                },
                "&:disabled": {
                    backgroundColor: ["primary", "secondary"].includes(
                        props.color as any,
                    )
                        ? hexToRGB(theme.palette[props.color as any].main, 0.25)
                        : hexToRGB(theme.palette.secondary.main, 0.25),

                    color: theme.palette.white.main,
                },
            },
            buttonProgress: {
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: -10,
                marginLeft: -10,
            },
            buttonProgressPrimary: {
                color: theme.palette.primary.main,
            },
            buttonProgressSecondary: {
                color: theme.palette.secondary.main,
            },
            buttonProgressLight: {
                color: theme.palette.white.main,
            },
            disabled: {
                opacity: 0.4,
                color: theme.palette.secondary.dark,
            },
        });

    const classes = useStyles(styles);

    function getLoadingClass() {
        if (props.color === "primary") {
            return classes.buttonProgressSecondary;
        } else if (props.color === "secondary") {
            return classes.buttonProgressPrimary;
        } else {
            return classes.buttonProgressLight;
        }
    }
    function getStyle() {
        const obj: Object = {};
        if (props.marginRightAuto) {
            obj["marginRight"] = "auto";
        }
        if (props.marginLeftAuto) {
            obj["marginLeft"] = "auto";
        }
        if (props.height) {
            obj["height"] = props.height;
        }
        Object.assign(obj, props.rootStyle);
        return obj;
    }
    function getContainerStyle() {
        const obj: Object = { position: "relative", display: "flex" };
        if (props.containerStyle) {
            Object.assign(obj, props.containerStyle);
        }
        if (props.fullWidth) {
            Object.assign(obj, { width: "100%" });
        }
        return obj;
    }
    return (
        <Tooltip
            title={props.tooltip || (props.buttonDisabled ? "Disabled" : "")}
        >
            <span style={getContainerStyle()}>
                <ToggleButton
                    id={props?.id}
                    style={
                        props.customRootStyles
                            ? { ...props.customRootStyles, ...getStyle() }
                            : getStyle()
                    }
                    size={props.size ? props.size : undefined}
                    onClick={props.onClick}
                    disabled={props.buttonDisabled}
                    className={clsx(props.buttonClass)}
                    selected={props.selected}
                    classes={{ root: classes.root, disabled: classes.disabled }}
                    value={[]}
                >
                    {props.children}
                </ToggleButton>
                {props.loading && (
                    <CircularProgress
                        size={20}
                        className={clsx(
                            classes.buttonProgress,
                            getLoadingClass(),
                        )}
                    />
                )}
            </span>
        </Tooltip>
    );
}

AcxStandaloneToggleButton.defaultProps = {
    buttonDisabled: false,
    color: "primary",
    children: "Button",
    fullWidth: true,
    loading: false,
    leftRightSpacing: 3,
};
