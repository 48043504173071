import Classifier, {
    AddClassifierGroupResult,
    ClassifierLibrary,
    CreateClassifierReturn,
} from "models/ClassifierModel";
import { ClassifierResultAnswerTag } from "models/ClassifierResultAnswerTag";
import { EddyEffectP2ResultsResponse } from "models/EddyEffectP2Results";
import { ClassifierResult } from "../models/ClassifierResult";
import { ClassifierResultValidation } from "../models/ClassifierResultValidation";
import { BaseService, ServiceError } from "./BaseService";
import { isNullableType } from "utils/TypeGuards";
import ClassifierGroup from "models/ClassifierGroup";
import { SAID_WITHIN_MAX } from "constants/FullTextSearch";

interface IsFTSCompatible {
    success: true;
}

interface IsNotFTSCompatible {
    success: false;
    message: string;
}

type FTSCompatibleResult = IsFTSCompatible | IsNotFTSCompatible;

export class ClassifierService extends BaseService {
    protected urlBase = "api/Classifier";

    public async loadClassifiersForCurrentUser(organizationId?: string) {
        const apiPath = "GetForUser";
        return this.post<Classifier[]>(JSON.stringify({ organizationId }), new URLSearchParams({}), apiPath);
    }

    public async getPublishedClassifiers(orgId: string) {
        const apiPath = "GetPublishedByOrgId";
        return this.get<Classifier[]>(
            new URLSearchParams({ orgId: orgId }),
            apiPath,
        );
    }

    public async configureAutoScoring(
        organizationId: string,
        questionId: string,
        classifierResultAnswerTags: ClassifierResultAnswerTag[],
    ) {
        await this.post(
            JSON.stringify(classifierResultAnswerTags),
            new URLSearchParams({
                organizationId,
                questionId,
            }),
            "ConfigureAutoScoring",
        );
    }

    public async getAutoScoringConfiguration(
        organizationId: string,
        questionId: string,
    ): Promise<ClassifierResultAnswerTag[]> {
        try {
            const params = new URLSearchParams({
                questionId: questionId,
            });

            const response = await this.post(
                JSON.stringify({ organizationId }),
                params,
                "GetAutoScoringConfiguration",
            );

            return response;
        } catch (error) {
            //@ts-ignore
            console.error(error.message);
            //@ts-ignore
            return error.message;
        }
    }

    public async updateClassifier(
        classifierName: string,
        userName: string,
        queryString: string,
        classifierId: string,
        classifierDescription?: string,
        classifierCategoryId?: string,
        classifierGroupId?: string,
    ) {
        const apiPath = "UpdateLuceneClassifier";
        const params = new URLSearchParams({});
        const body = {
            classifierName,
            userName,
            queryString,
            classifierId,
            classifierDescription,
            classifierCategoryId,
            classifierGroupId,
        };

        try {
            const response = await this.put(
                params,
                apiPath,
                JSON.stringify(body),
            );

            return response;
        } catch (error) {
            //@ts-ignore
            console.error(error.message);
            //@ts-ignore
            throw error;
        }
    }

    public async deleteClassifier(classifierId: string) {
        const apiPath = "";
        const params = new URLSearchParams({
            ClassifierId: classifierId,
        });
        return this.delete(params, apiPath);
    }

    public async createClassifier(
        name: string,
        searchString: string,
        orgId?: string,
        description?: string,
        categoryId?: string,
        groupId?: string,
    ) {
        const apiPath = "CreateLucene";
        const params = new URLSearchParams({
            orgId: orgId || "",
        });

        const body = {
            classifierName: name,
            queryString: searchString,
            classifierDescription: description,
            classifierCategoryId: categoryId,
            classifierGroupId: groupId,
        };

        return this.post<CreateClassifierReturn>(
            JSON.stringify(body),
            params,
            apiPath,
        );
    }

    public async publishClassifier(classifierId: string) {
        const apiPath = "Publish";
        const params = new URLSearchParams({
            ClassifierId: classifierId,
        });

        return this.get<Classifier>(params, apiPath);
    }

    public async unpublishClassifier(classifierId: string) {
        const apiPath = "Unpublish";
        const params = new URLSearchParams({
            ClassifierId: classifierId,
        });

        return this.get<Classifier>(params, apiPath);
    }

    public async getClassifierResultValidation(evaluationId: string) {
        const apiPath = "ClassificationValidation";
        const params = new URLSearchParams({
            evaluationId,
        });

        return this.get<ClassifierResultValidation[]>(params, apiPath);
    }

    public async positiveClassifications(audioMetadataId: string) {
        const apiPath = "PositiveClassifications";
        const params = new URLSearchParams({
            audioMetadataId,
        });

        return this.get<ClassifierResult[]>(params, apiPath);
    }

    public async allClassifications(audioMetadataId: string) {
        const apiPath = "AllClassifications";
        const params = new URLSearchParams({
            audioMetadataId,
        });

        return this.get<ClassifierResult[]>(params, apiPath);
    }
    public async getEddyEffectP2Results(audioMetadataId: string) {
        const apiPath = "EddyEffectP2Results";
        const params = new URLSearchParams({
            audioMetadataId,
        });
        return this.get<EddyEffectP2ResultsResponse[]>(params, apiPath);
    }

    public async isFTSCompatible(
        userQueryDefinition: string,
    ): Promise<FTSCompatibleResult> {
        const apiPath = "IsFTSCompatible";
        const params = new URLSearchParams();

        let errorMessage: string | undefined;

        try {
            await this.post(
                JSON.stringify(userQueryDefinition),
                params,
                apiPath,
            );
        } catch (error: unknown) {
            if (error instanceof ServiceError) {
                const match = error.message.match(/Message":"(.*?)"/);
                if (match && match[1]) {
                    errorMessage = match[1];
                }
            } else {
                errorMessage = String(error);
            }
        } finally {
            const lines = userQueryDefinition
                .split("\n")
                .filter((line) => line.length > 0);
            for (let i = 0; i < lines.length; i++) {
                const line = lines[i];
                let proximityIndex = line.indexOf("~");
                let foundError = false;

                do {
                    // jump past the ~
                    proximityIndex += 1;

                    let nextSpaceIndex = line.indexOf(" ", proximityIndex);
                    if (nextSpaceIndex < 0) nextSpaceIndex = line.length;

                    const proximityNumber = Number.parseInt(
                        line.substring(proximityIndex, nextSpaceIndex),
                    );

                    // Bad proximity value, will be caught by isFTSCompatible, break out now to show og message
                    if (isNaN(proximityNumber)) {
                        foundError = true;
                        break;
                    }

                    if (
                        proximityNumber <= 0 ||
                        proximityNumber > SAID_WITHIN_MAX
                    ) {
                        return {
                            success: false,
                            message: `Line ${
                                i + 1
                            } failed with error: Error at character with position ${proximityIndex}: Specified proximity is not valid. Proximity must be (inclusive) between 1 and ${SAID_WITHIN_MAX}`,
                        };
                    }

                    // go to next ~ after the current one
                    proximityIndex = line.indexOf("~", proximityIndex + 1);
                } while (proximityIndex >= 0);

                if (foundError) break;
            }

            if (errorMessage) return { success: false, message: errorMessage };
            return { success: true };
        }
    }

    public async getUserClassifiers(organizationId?: string, isPublished?: boolean) {
        const apiPath = "GetForUser";
        const params = new URLSearchParams({});
        if (!isNullableType(isPublished)) {
            params.append("isPublished", isPublished.toString())
        }
        return this.post<Classifier[]>(JSON.stringify({ organizationId }), params, apiPath);
    }

    public async getClassifierGroups() {
        const apiPath = "GetClassifierGroups";
        const params = new URLSearchParams();
        return this.get<ClassifierGroup[]>(params, apiPath);
    }

    public async createClassifierGroup(name: string) {
        const apiPath = "CreateClassifierGroup";
        const params = new URLSearchParams({
            name,
        });
        return this.get<ClassifierGroup>(params, apiPath);
    }

    public async updateClassifierGroup(id: string, name: string) {
        const apiPath = "UpdateClassifierGroup";
        const params = new URLSearchParams({
            id,
            name,
        });
        return this.get<ClassifierGroup>(params, apiPath);
    }

    public async deleteClassifierGroup(id: string) {
        const apiPath = "DeleteClassifierGroup";
        const params = new URLSearchParams({
            id,
        });
        return this.get<ClassifierGroup>(params, apiPath);
    }

    public async getClassifierCategories() {
        const apiPath = "GetClassifierCategories";
        const params = new URLSearchParams();
        return this.get<ClassifierGroup[]>(params, apiPath);
    }

    public async getClassifierLibrary() {
        const apiPath = "GetLuceneClassifierLibrary";
        const params = new URLSearchParams();
        return this.get<ClassifierLibrary>(params, apiPath);
    }

    public async getCoreClassifier(coreClassifierId: string) {
        const apiPath = "GetCoreClassifier";
        const params = new URLSearchParams({
            coreClassifierId,
        });
        return this.get<Classifier>(params, apiPath);
    }

    /**
     * Returns an object with two properties: "successfulClassifierNames" and "failedClassifierNames".
     * Respectively, the first property will contain classifier names that were successfully added to the tenant
     * and the second property will contain classifier names that were failed to be added due to a naming conflict
     * @param coreClassifierGroupId
     * @returns
     */
    public async addClassifiersFromCoreGroupToTenant(
        coreClassifierGroupId: string,
    ) {
        const apiPath = "AddClassifiersFromCoreGroupToTenant";
        const params = new URLSearchParams({
            coreClassifierGroupId,
        });
        return this.get<AddClassifierGroupResult>(params, apiPath);
    }

    public async addCoreClassifierToTenant(coreClassifierId: string) {
        const apiPath = "AddCoreClassifierToTenant";
        const params = new URLSearchParams({
            coreClassifierId,
        });
        return this.get(params, apiPath);
    }

    public async addClassifierToLibrary(coreClassifierId: string) {
        const apiPath = "AddCoreClassifierToLibrary";
        const params = new URLSearchParams({
            coreClassifierId,
        });
        return this.get(params, apiPath);
    }

    public async removeClassifierFromLibrary(coreClassifierId: string) {
        const apiPath = "RemoveCoreClassifierFromLibrary";
        const params = new URLSearchParams({
            coreClassifierId,
        });
        return this.get(params, apiPath);
    }
}

export default ClassifierService;
