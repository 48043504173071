import { GridValueFormatterParams } from "@mui/x-data-grid-pro";
import AcxChipList from "components/UI/AcxChipList";
import { isObject } from "lodash";
import React from "react";

export const ArrayTagFormatter = (
    params: GridValueFormatterParams | string,
) => {
    let tags: string[];

    if (isObject(params)) {
        tags = params.getValue(params.id, params.field) as any;
        if (Array.isArray(tags)) {
            const res = (
                <AcxChipList
                    size={"small"}
                    color={"blue"}
                    values={tags}
                    height="100%"
                    toolTip={tags.join(", ")}
                />
            );
            return res;
        }
    }

    return null;
};
