import { Theme } from "@mui/material/styles";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import theme from "Theme/AppTheme";

const colors = [
    "#2EC674",
    "#5451FF",
    // "#5451FF",
    // "#2EC674"
];

export const customSecondaryButtonStyle = {
    color: theme.palette.black.main,
    backgroundColor: theme.palette.white.main,
    border: "1px solid",
    borderColor: theme.palette.grey[200],
};

export const blueButtonWhiteBackgroundStyle = {
    color: theme.palette.blue.main,
    backgroundColor: theme.palette.white.main,
    border: "0.5px solid",
    borderColor: theme.palette.grey[200],
};

const makeClasses = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: theme.spacing(1),
        },
        buttonPaper: {
            height: "100%",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
        },
        paperBg0: { background: colors[0] },
        paperBg1: { background: colors[1] },

        segmentContainer: {
            marginBottom: theme.spacing(1),
            borderTopLeftRadius: "4px",
            borderBottomLeftRadius: "4px",
        },
        segmentContainer0: { border: "1px solid " + colors[0] },
        segmentContainer1: { border: "1px solid " + colors[1] },

        tagContainer: {
            display: "flex",
        },
        fontWeightMedium: {
            fontWeight: theme.typography.fontWeightMedium as any,
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            // maxWidth: 300
        },
        hasChanges: {
            backgroundColor: "#FFFFE0",
        },
        chips: {
            display: "flex",
            flexWrap: "wrap",
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(2),
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
        error: {
            color: "darkred",
            backgroundColor: "pink",
        },
        success: {
            color: "darkgreen",
            backgroundColor: "lightgreen",
        },
    }),
);

export default makeClasses;
