export function isFunctionType(arg: unknown): arg is Function {
    return typeof arg === "function";
}

export function isArrayType<T>(arg: unknown): arg is Array<T> {
    return Array.isArray(arg);
}

export function isStringType(arg: unknown): arg is string {
    return isType<string>(arg, "charAt");
}

export function isUndefinedType(arg: unknown): arg is undefined {
    return isNullableType(arg);
}

export function isNullableType(arg: unknown): arg is undefined {
    return arg === null || arg === undefined;
}

export function isType<T>(arg: unknown, propertyOfTypeT: keyof T): arg is T {
    return isNullableType(arg)
        ? false
        : (arg as T)[propertyOfTypeT] !== undefined;
}

export function isPromise<T>(arg: unknown): arg is Promise<T> {
    return isType<Promise<T>>(arg, "catch");
}

export function isStringJsonArray(arg: unknown): arg is string[] {
    if (!isStringType(arg)) {
        return false;
    }
    try {
        const res = JSON.parse(arg);
        return isArrayType<string>(res);
    } catch {
        return false;
    }
}
