import { useState, useEffect } from "react";
import { ServiceError } from "services/BaseService";

function useServiceData<T>(
    serviceFunction: () => Promise<T>,
): [T | undefined, boolean, string | undefined] {
    const [data, setData] = useState<T | undefined>();
    const [isLoading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                setData(await serviceFunction());
                setLoading(false);
            } catch (er) {
                if (er instanceof ServiceError) {
                    setErrorMessage(await er.response.text());
                } else {
                    throw er;
                }
            } finally {
                setLoading(false);
            }
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [data, isLoading, errorMessage];
}

export default useServiceData;
