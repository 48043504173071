import { AppUser } from "components/Admin/UserManager/UserStore";
import AcxConfirmationDialog from "components/UI/AcxConfirmationDialog";
import AcxMainTextField from "components/UI/AcxMainTextFieldGrid";
import AcxCalendarPopup from "components/UI/Calendar/AcxCalendarPopup";
import AcxSelect from "components/UI/Select/BaseSelectComponents/AcxSelect";
import AcxSelectSingleAsync from "components/UI/Select/BaseSelectComponents/AcxSelectSingleAsync";
import { observer } from "mobx-react";
import moment, { Moment } from "moment";
import React from "react";
import { useStore } from "utils/useStore";
import ServiceHierarchySelect from "../../OrganizationModules/fields/ServiceHierarchySelect";
import { OrganizationAgentStore } from "../stores/OrganizationAgentStore";

const UpdateAgentsDialog = observer(() => {
    const agentStore = useStore(OrganizationAgentStore);
    const selectedRows = agentStore.dgStore.getSelectedRows();

    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [agentEmail, setAgentEmail] = React.useState("");
    const [agentCode, setAgentCode] = React.useState("");
    const [startDate, setStartDate] = React.useState<Moment | undefined>();
    const [isActive, setIsActive] = React.useState();
    const [userId, setUserId] = React.useState("");
    const [user, setUser] = React.useState<AppUser | undefined>();
    const [managerId, setManagerId] = React.useState<string>("");

    React.useEffect(() => {
        if (selectedRows[0] && selectedRows.length === 1) {
            setFirstName(selectedRows[0].firstName);
            setLastName(selectedRows[0].lastName);
            setAgentEmail(selectedRows[0].agentEmail);
            setAgentCode(selectedRows[0].agentCode);
            setStartDate(
                !!selectedRows[0].startDate
                    ? moment(selectedRows[0].startDate)
                    : undefined,
            );
            setIsActive(selectedRows[0].isActive);
            setUserId(selectedRows[0].userId);
            setUser(selectedRows[0].user);
            setManagerId(selectedRows[0].managerId ?? "");
        }

        // Because we may remove the include Manager in the query, we may need to fetch the user
        // using the manager id here
    }, [selectedRows, selectedRows.length]);

    function onSubmit() {
        agentStore.onAgentUpdateConfirm(
            firstName,
            lastName,
            agentEmail,
            agentCode,
            isActive,
            agentStore.selectedAgentHierarchies,
            userId,
            startDate,
            managerId,
        );
    }

    function onClose() {
        agentStore.updateAgentsDialogStore.close();
    }
    const processOptions = [
        {
            label: "Active",
            value: true,
        },
        {
            label: "Inactive",
            value: false,
        },
    ];

    const selectUser = (user: AppUser) => {
        setUserId(user?.id || "");
    };

    const selectManager = (user: AppUser) => {
        setManagerId(user?.id || "");
    };

    // const getUserDefault = () => {
    //     console.log(user);
    //     return user;
    // };

    let updateAgentsFormComponents: React.ReactChild[] = [];
    if (Object.entries(selectedRows).length === 1 && selectedRows[0]) {
        updateAgentsFormComponents = [
            <AcxMainTextField
                key="firstName"
                containerStyle={{ marginBottom: "0.5rem" }}
                id="firstName"
                labelText="First Name"
                placeholderText='e.g. "Jane"'
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                autofocus
                required
            />,
            <AcxMainTextField
                key="lastName"
                containerStyle={{ marginBottom: "0.5rem" }}
                id="lastName"
                labelText="Last Name"
                placeholderText='e.g. "Doe"'
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
            />,
            <AcxMainTextField
                key="agentEmail"
                containerStyle={{ marginBottom: "0.5rem" }}
                id="agentEmail"
                labelText="Email"
                placeholderText='e.g. "jane.doe@domain.com"'
                value={agentEmail}
                onChange={(e) => setAgentEmail(e.target.value)}
                required
            />,
            <AcxMainTextField
                key="agentCode"
                containerStyle={{ marginBottom: "0.5rem" }}
                id="agentCode"
                labelText="Agent Code"
                placeholderText='e.g. "janedoe"'
                value={agentCode}
                onChange={(e) => setAgentCode(e.target.value)}
            />,
            <AcxSelectSingleAsync
                key="userId"
                inputLabel="Connected User"
                fullWidth={true}
                defaultValue={user}
                id="agent-user-option-selector"
                labelField="email"
                valueField="id"
                loadOptions={agentStore.searchUsers}
                cacheOptions
                isClearable
                isLoading={false}
                onChange={selectUser}
            />,

            <AcxSelect
                key="agentStatus"
                inputLabel="Agent Status"
                fullWidth={true}
                defaultValue={processOptions.find((option) => {
                    return option.value === isActive;
                })}
                id="pipeline-processing-option-selector"
                labelField="label"
                valueField="value"
                options={processOptions}
                onChange={(e) => setIsActive(e.value)}
            />,
            <ServiceHierarchySelect
                key="hieararchySelect"
                orgId={agentStore.orgId ?? ""}
                value={agentStore.selectedAgentHierarchies ?? []}
                onChange={(val) => agentStore.setSelectedAgentHierarchies(val)}
                fullWidth
            />,
            <div style={{ marginBottom: "8px", marginTop: "8px" }}>
                <AcxCalendarPopup
                    id={"update-agent-start-date-cal"}
                    inputLabel="Start Date"
                    currentDate={startDate}
                    onSelect={(selectedDate) => setStartDate(selectedDate)}
                />
            </div>,
            <AcxSelectSingleAsync
                key="userId"
                defaultValue={selectedRows[0].manager}
                inputLabel="Agent Manager"
                fullWidth
                id="agent-user-option-selector"
                labelField={(option: any) => {
                    // using any type "any" here because the returned option was not the Option type expected
                    return `${option.firstName} ${option.lastName}`;
                }}
                valueField="id"
                loadOptions={agentStore.searchUsers}
                cacheOptions
                isClearable
                isLoading={false}
                onChange={selectManager}
                noOptionMessage="Please enter First Name, Last Name, Email, or Username"
            />,
        ];
    }

    return (
        <>
            <AcxConfirmationDialog
                isOpen={agentStore.updateAgentsDialogStore.isOpen}
                onClose={onClose}
                title="Update Agent"
                subTitle="Edit the fields you would like to change."
                content={updateAgentsFormComponents}
                confirmButtonText="Update Agent"
                cancelButtonText="Cancel"
                isLoading={agentStore.getTaskLoading("Update Agent")}
                alternateSpinnerType={"BeatLoader"}
                spinnerSize={12}
                onConfirm={onSubmit}
            />
        </>
    );
});

export default UpdateAgentsDialog;
