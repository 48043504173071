import { observer } from "mobx-react";
import React from "react";
import StandardClassifierBuilderStore, {
    IStandardClassifierGroup,
} from "./StandardClassifierBuilderStore";
import StandardClassifierBuilderRule from "./StandardClassifierBuilderRule";
import AcxButton from "components/UI/AcxButton";
import AddIcon from "@mui/icons-material/Add";
import { Box, Card, Divider, Grid, Theme, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Stack } from "@mui/material";

const useGroupStyles = makeStyles((theme: Theme) => ({
    addButton: {
        color: theme.palette.secondary.main,
        "&:hover": {
            color: "white",
        },
    },
    andOrContainer: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        height: "28px",
        position: "relative",
    },
    andOrInner: {
        color: "#1E40AF",
        backgroundColor: theme.palette.lightBlue.background,
        borderRadius: "4px",
        padding: "5px 10px 5px 10px",
        position: "absolute",
        cursor: "pointer",
    },
    orDivider: {
        width: "100%",
        backgroundColor: theme.palette.lightBlue.background,
        height: "2px",
    },
    cardContainer: {
        width: "100%",
        margin: theme.spacing(2),
        padding: theme.spacing(1),
    },
    marginTop1: { marginTop: theme.spacing(1) },
}));

interface Props {
    group: IStandardClassifierGroup;
    store: StandardClassifierBuilderStore;
    groupIndex: number;
}

const StandardClassifierBuilderGroup: React.FunctionComponent<Props> = observer(
    ({ group, store, groupIndex }) => {
        const classes = useGroupStyles();
        const theme = useTheme();
        const currentGroupList = store.classifierGroups;

        const listLength = currentGroupList.length;
        const lastItemIndex = listLength - 1;

        const nextItem =
            groupIndex !== lastItemIndex
                ? currentGroupList[groupIndex + 1]
                : undefined;

        const showAndOrChip = listLength !== 1 && groupIndex !== lastItemIndex;

        const isAnd = group.filterGroupId === nextItem?.filterGroupId;

        return (
            <>
                <Card className={classes.cardContainer}>
                    <Grid container item xs={12}>
                        {!!group.classifierRules.length &&
                            group.classifierRules.map((rule, index) => (
                                <StandardClassifierBuilderRule
                                    key={
                                        "group-" + groupIndex + "-rule-" + index
                                    }
                                    rule={rule}
                                    index={index}
                                    groupIndex={groupIndex}
                                    store={store}
                                />
                            ))}
                    </Grid>
                    <Grid item xs={1} className={classes.marginTop1}>
                        <AcxButton
                            variant="outlined"
                            fullWidth
                            buttonClass={classes.addButton}
                            onClick={() => {
                                store.addClassifierBuilderRule(0, groupIndex);
                            }}
                            leftRightSpacing={0}
                            customRootStyles={{
                                marginTop: "5px",
                                color: theme.palette.primary.main,
                            }}
                        >
                            <AddIcon /> Rule
                        </AcxButton>
                    </Grid>
                </Card>
                {showAndOrChip && (
                    <Grid
                        container
                        className={classes.andOrContainer}
                        justifyContent={isAnd ? "flex-start" : "center"}
                        style={isAnd ? { marginLeft: "14px" } : {}}
                        alignItems="center"
                    >
                        {!isAnd && <Divider className={classes.orDivider} />}
                        <Stack
                            direction="row"
                            className={classes.andOrInner}
                            alignItems={"center"}
                            onClick={() => {
                                store.changeGroupCombinator(
                                    groupIndex,
                                    !isAnd ? 0 : 1,
                                );
                            }}
                        >
                            <Box fontWeight={"bold"}>
                                {isAnd ? "AND" : "OR"}
                            </Box>
                        </Stack>
                    </Grid>
                )}
            </>
        );
    },
);

export default StandardClassifierBuilderGroup;
