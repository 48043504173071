import { Grid, Paper, Tooltip, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { Skeleton } from '@mui/material';
import { observer } from "mobx-react";
import React from "react";
import theme from "Theme/AppTheme";
export interface AcxStatusCardProps {
    primaryContent: React.ReactNode | string | number;
    caption?: string;
    captionVariant?:
        | "h1"
        | "h2"
        | "h3"
        | "h4"
        | "h5"
        | "h6"
        | "subtitle1"
        | "subtitle2"
        | "body1"
        | "body2"
        | "caption"
        | "button";
    fluctuationIsPositive?: boolean;
    fluctuationUnit?: string;
    fluctuationAmount?: number;
    containerStyles?: React.CSSProperties;
    isLoading?: boolean;
    tooltipText?: string;
    wrapTitle?: boolean;
}

const useStyles = makeStyles((props: AcxStatusCardProps) => ({
    paper: {
        display: "flex",
        justifyContent: "center",
    },
    statusWrapper: {
        padding: "0.5rem",
        alignContent: "space-between",
    },
    captionTextWrapper: (props: AcxStatusCardProps) =>
        props.wrapTitle
            ? {
                  display: "block",
                  textOverflow: "ellipsis",
                  wordWrap: "break-word",
                  overflow: "clip",
                  maxHeight: "3.2em",
              }
            : {
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  maxWidth: "calc(100% - 1rem)",
              },
    primaryContentWrapper: {
        alignItems: "center",
        margin: "0.25rem 0",
    },
    fluctuationWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "0.25rem",
    },
    fluctuationUnitWrapper: {
        marginLeft: "0.25rem",
    },
}));

type Props = AcxStatusCardProps;

const AcxStatusCard: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = observer((props) => {
    const {
        caption,
        captionVariant,
        primaryContent,
        fluctuationIsPositive,
        fluctuationUnit,
        fluctuationAmount,
        containerStyles,
        isLoading,
        tooltipText,
    } = props;
    const classes = useStyles(props);
    const [displayFluctuation, setFluctuationDisplay] =
        React.useState<boolean>(false);

    React.useEffect(() => {
        if (
            fluctuationIsPositive !== undefined &&
            fluctuationAmount &&
            fluctuationUnit
        ) {
            setFluctuationDisplay(true);
        } else {
            setFluctuationDisplay(false);
        }
    }, [fluctuationAmount, fluctuationIsPositive, fluctuationUnit]);

    return (
        <Paper className={classes.paper} style={containerStyles ?? {}}>
            <Grid container item xs={12} className={classes.statusWrapper}>
                {isLoading ? (
                    <Grid item style={{ width: "100%", height: "100%" }}>
                        <Skeleton variant="text" width="100%" height="30%" />
                        <Skeleton variant="text" width="100%" height="70%" />
                    </Grid>
                ) : (
                    <>
                        <Grid
                            item
                            xs={12}
                            className={classes.captionTextWrapper}
                        >
                            {caption && (
                                <Tooltip title={tooltipText ?? caption}>
                                    <Typography
                                        variant={captionVariant ?? "caption"}
                                    >
                                        {caption}
                                    </Typography>
                                </Tooltip>
                            )}
                        </Grid>

                        <Grid
                            container
                            item
                            xs={12}
                            className={classes.primaryContentWrapper}
                        >
                            <Grid item xs={12}>
                                {primaryContent}
                            </Grid>

                            {displayFluctuation && (
                                <Grid
                                    container
                                    item
                                    xs={5}
                                    className={classes.fluctuationWrapper}
                                    style={{
                                        backgroundColor: fluctuationIsPositive
                                            ? theme.palette.green.light
                                            : theme.palette.red.light,
                                    }}
                                >
                                    {fluctuationIsPositive ? (
                                        <TrendingUpIcon />
                                    ) : (
                                        <TrendingDownIcon />
                                    )}

                                    <Typography
                                        variant="body2"
                                        className={
                                            classes.fluctuationUnitWrapper
                                        }
                                    >
                                        {fluctuationAmount}
                                        {fluctuationUnit}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </>
                )}
            </Grid>
        </Paper>
    );
});

export default AcxStatusCard;
