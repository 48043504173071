import { observer } from "mobx-react";
import { IAppliedFilterChipComponent } from "../Chips/IAppliedFilterChipComponent";
import AppliedFilterChip from "../Chips/AppliedFilterChipBase";
import { useStore } from "utils/useStore";
import { ApplicationFiltersStore } from "stores/ApplicationFilters/ApplicationFiltersStore";
import _ from "lodash";
import React from "react";

export const DefaultAppliedFilterChip = observer(function (
    props: IAppliedFilterChipComponent,
) {
    const applicationFiltersStore = useStore(ApplicationFiltersStore);

    const value = _.get(applicationFiltersStore, props.item.filterKey);

    return (
        <AppliedFilterChip
            isApplicable={props.isApplicable}
            onRemove={() =>
                // @ts-expect-error
                applicationFiltersStore.resetByKey(props.item.filterKey)
            }
        >
            {props.item.title}: {value?.toString()}
        </AppliedFilterChip>
    );
});
