import { BaseStore } from "stores/BaseStore";
import { action, computed, makeObservable, observable, reaction } from "mobx";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";
import type { ITranscriptionSegment } from "services/TranscriptionService";
import { HighlightedClipWord } from "utils/getHighlightedClip";
import { TranscriptionSearchResult } from "components/Evaluation/Stores/EvalStore";
import { convertTranscriptToBlurbs } from "../utils";
import { EddyEffectP2Results } from "models/EddyEffectP2Results";

export interface EddyEffectStatement {
    startTime: number;
    endTime: number;
    stmt: string;
}

export interface SelectedPlayingBlurb {
    id: string;
    isPlaying: boolean;
}

@AcxStore
export class AcxTranscriptionStore extends BaseStore {
    @observable searchString: string = "";
    @observable scrollIndex: number | null = null;
    @observable selectString: string = "";
    @observable selectIndex: number = -1;
    @observable isCheckedEddyEffectP2: boolean = true;
    @observable eddyEffectP2Data: EddyEffectP2Results[] = [];
    @observable hasEvaluation: boolean = false;
    @observable selectedPlayingBlurb: SelectedPlayingBlurb = {
        id: "0",
        isPlaying: false,
    };
    @observable highlightedText: HighlightedClipWord[] = [];
    @observable words: ITranscriptionSegment[] = [];

    constructor(public rootStore: IRootStore) {
        super("AcxTranscriptionStore");
        makeObservable(this);

        reaction(
            () => this.words,
            () => {
                this.formatTranscriptForHighlight("");
            },
        );
    }

    @action
    setWords(words: ITranscriptionSegment[]) {
        this.words = words;
    }

    @action
    setEddyEffect(eddies: EddyEffectP2Results[]) {
        this.eddyEffectP2Data = eddies;
    }

    @action
    setHasEval(evaluation: boolean) {
        this.hasEvaluation = evaluation;
    }

    @action
    setHighlightedText(
        highlightedText:
            | HighlightedClipWord[]
            | ((prev: HighlightedClipWord[]) => HighlightedClipWord[]),
    ) {
        if (Array.isArray(highlightedText)) {
            this.highlightedText = highlightedText;
        } else {
            this.highlightedText = highlightedText(this.highlightedText);
        }
    }

    @action
    resetHighlightedText() {
        this.highlightedText = [];
        this.selectIndex = -1;
        this.searchString = "";
    }

    @action
    setScrollIndex(index: number | null) {
        this.scrollIndex = index;
    }

    @action
    getSearchResults(): TranscriptionSearchResult[] | undefined {
        if (!this.transcriptWords) return undefined;
        if (this.searchString.length <= 2) {
            return undefined;
        }

        let matchingIndices: number[] = [];

        const searchTextWordCount = this.searchString.split(" ").length;

        if (searchTextWordCount > 1) {
            for (
                let i = 0;
                i < this.transcriptWords.length - searchTextWordCount;
                i++
            ) {
                const curPhrase = this.transcriptWords
                    .slice(i, i + searchTextWordCount)
                    .map((item) => item.text)
                    .join(" ");
                if (
                    curPhrase
                        .toLowerCase()
                        .toString()
                        .includes(this.searchString.toLowerCase())
                ) {
                    matchingIndices.push(i);
                }
            }
        } else {
            this.transcriptWords.forEach(
                (transcriptionWord, transcriptionIndex) => {
                    if (
                        transcriptionWord.text
                            .toLowerCase()
                            .toString()
                            .includes(this.searchString.toLowerCase())
                    ) {
                        matchingIndices.push(transcriptionIndex);
                    }
                },
            );
        }

        let searchStatementResults: any[][] = [];

        matchingIndices.forEach((matchingwordIndex: number) => {
            let searchStatement: ITranscriptionSegment[] = [];
            let wordArray: any[] = this.transcriptWords ?? [];

            let statementIndices: number[] = this.getStatementIndex(
                wordArray?.length,
                matchingwordIndex,
            );

            // Find the startTime for the phrase where word was found for auto-scroll
            const foundWordIndex = statementIndices[2];
            const foundWordChannel = wordArray[foundWordIndex].channel;

            let currentIndex = foundWordIndex;
            let foundWordPhraseStart: number | null = null;

            while (!foundWordPhraseStart) {
                const currentWord = wordArray[currentIndex];
                if (
                    currentWord?.channel === undefined ||
                    foundWordChannel !== currentWord.channel
                ) {
                    const nextWord = wordArray[currentIndex + 1];

                    foundWordPhraseStart = nextWord.startTime;
                } else {
                    currentIndex -= 1;
                }
            }

            statementIndices.forEach((wordIndex) => {
                if (foundWordPhraseStart && wordArray[wordIndex]) {
                    wordArray[wordIndex].phraseStartTime = foundWordPhraseStart;
                }
                searchStatement.push(wordArray[wordIndex]);
            });

            searchStatementResults.push(searchStatement);
        });

        let searchResults: TranscriptionSearchResult[] = [];

        searchStatementResults.forEach((result: any) => {
            let stmt = "";
            result = result.filter((v) => v !== undefined);

            result.forEach((resultObj) => {
                stmt = stmt + resultObj?.text + " ";
            });

            let resultObject: TranscriptionSearchResult = {
                stmt: stmt,
                startTime: result[0].startTime,
                endTime: result[result.length - 1].endTime,
                phraseStartTime: result[0].phraseStartTime,
            };
            searchResults.push(resultObject);
        });

        return searchResults;
    }

    private getStatementIndex(
        wordCount: number | undefined,
        matchingWordPosition: number,
    ): number[] {
        if (!wordCount) return [];

        let wordIndices: number[] = [];

        wordIndices.push(matchingWordPosition);

        for (var i = 1; i <= 2; i++) {
            if (matchingWordPosition - i >= 0) {
                wordIndices.push(matchingWordPosition - i);
            }
            if (matchingWordPosition + i <= wordCount) {
                wordIndices.push(matchingWordPosition + i);
            }
        }

        return wordIndices.sort();
    }

    @computed
    get blurbs() {
        const blurbs = convertTranscriptToBlurbs(
            this.transcriptWords,
            this.eddies,
        );

        if (!this.hasEvaluation) return blurbs;

        return blurbs.map((blurb, index) => {
            const highlightedBlurb = this.highlightedText.find(
                (h) => h.index === index,
            );
            if (highlightedBlurb) {
                return { ...blurb, text: highlightedBlurb.text };
            }
            return {
                ...blurb,
                text: blurb.text.replace(/<mark>(.*?)<\/mark>/g, "$1"),
            };
        });
    }

    @computed
    get eddies() {
        if (this.isCheckedEddyEffectP2) {
            return this.eddyEffectP2Data;
        } else {
            return [];
        }
    }

    @computed
    get transcriptWords() {
        return this.words;
    }

    formatTranscriptForHighlight(
        statement: string,
        index?: number,
        statementInterval?: number[],
    ) {
        let filterText =
            this.selectIndex !== -1
                ? this.selectString
                : this.searchString.toLowerCase();
        let returnStatement = statement;

        if (this.highlightedText.length > 1) {
            this.highlightedText.map((item) => {
                if (index === item.index) {
                    returnStatement = returnStatement.replace(
                        returnStatement,
                        `<span style="background-color:yellow;" id="highlightText">${returnStatement}</span>`,
                    );
                }
                return returnStatement;
            });
        }
        if (index === this.selectIndex) {
            returnStatement = returnStatement.replace(
                returnStatement,
                `<span style="background-color:yellow;" id="highlightText">${returnStatement}</span>`,
            );
        } else if (
            this.selectIndex === -1 &&
            filterText.length > 2 &&
            returnStatement.toLowerCase().includes(filterText)
        ) {
            let startIndex = 0;
            let foundIndex: number = 0;
            const foundIndices: number[] = [];
            const finalStatementArray: string[] = [];
            const returnStatementArray: string[] = returnStatement.split("");

            while (foundIndex !== -1) {
                foundIndex = returnStatement
                    .toLowerCase()
                    .indexOf(filterText, startIndex);

                foundIndices.push(foundIndex);
                startIndex = foundIndex + 1;
            }

            for (let i = 0; i < returnStatementArray.length; i++) {
                const foundText: string = returnStatementArray
                    .slice(i, i + filterText.length)
                    .join("");

                if (foundIndices.includes(i)) {
                    finalStatementArray.push(
                        `<span style="background-color:yellow;" id="highlightText">`,
                        foundText,
                        "</span>",
                    );
                    i += filterText.length - 1;
                } else {
                    const curChar: string = returnStatementArray[i];
                    finalStatementArray.push(curChar);
                }
            }

            returnStatement = finalStatementArray.join("");
        } else {
            returnStatement = returnStatement
                .trim()
                .replace(filterText, `<span>${filterText}</span>`);
        }

        return returnStatement;
    }
}
