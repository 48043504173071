import { GridValueFormatterParams } from "@mui/x-data-grid-pro";
import React from "react";
import { SoundClipService } from "services/SoundClipService";
import AcxMicroPlayer from "../../Audio/AcxMicroPlayer";
import { ProgressFormatter } from "./AcxProgressFormatter";
import { ArrayJoinComparator, ArrayJoinFormatter } from "./ArrayJoinFormatter";
import { ArrayTagFormatter } from "./ArrayTagFormatter";
import { BreadCrumbFormatter } from "./BreadCrumbFormatter";
import {
    DateComparator,
    DateFilter,
    DateFormatter,
    DateFormatterIgnoreTime,
    DateTimeFormatter,
    getDateFormatterOperators,
} from "./DateFormatters";
import { DefaultFilter, StringFormatter } from "./DefaultFormatters";
import {
    DurationComparator,
    DurationFilter,
    DurationFormatter,
    getDurationFormatterOperators,
} from "./DurationFormatter";
import {
    GenericLinkComparator,
    GenericLinkFormatter,
} from "./GenericLinkFormatter";
import { HierarchyFormatter } from "./HierarchyFormatter";
import {
    getLinkFormatterOperators,
    LinkComparator,
    LinkFormatter,
    LinkFormatterFilter,
} from "./LinkFormatter";
import {
    getPercentFilterOperators,
    PercentComparator,
    PercentFormatter,
} from "./PercentFormatter";
import {
    getStatusFormatterFilterOperators,
    statusFormatter,
    StatusFormatterFilter,
} from "./StatusFormatter";
import { ChipComparator, TagFormatter } from "./TagFormatter";
import { BooleanComparator, BooleanFormatter } from "./AcxBooleanFormatter";

export default class AcxFormatters {
    soundClipService: SoundClipService = new SoundClipService();

    defaultFilter = DefaultFilter;

    DateFormatterFilterOperators = getDateFormatterOperators;
    dateTimeFormatter = DateTimeFormatter;
    dateFormatter = DateFormatter;
    dateFormatterIgnoreTime = DateFormatterIgnoreTime;
    dateComparator = DateComparator;
    dateFilter = DateFilter;

    stringFormatter = StringFormatter;

    arrayJoinFormatter = ArrayJoinFormatter;
    arrayJoinComparator = ArrayJoinComparator;

    percentFormatter = PercentFormatter;
    percentComparator = PercentComparator;
    percentFilterOperator = getPercentFilterOperators;

    DurationFormatterFilterOperators = getDurationFormatterOperators;
    durationFormatter = DurationFormatter;
    durationFilter = DurationFilter;
    durationComparator = DurationComparator;

    statusFormatter = statusFormatter;

    StatusFormatterFilter = StatusFormatterFilter;
    StatusFormatterFilterOperators = getStatusFormatterFilterOperators;

    tagFormatter = TagFormatter;
    chipComparator = ChipComparator;

    hierarchyFormatter = HierarchyFormatter;

    breadCrumbFormatter = BreadCrumbFormatter;

    arrayTagFormatter = ArrayTagFormatter;

    progressFormatter = ProgressFormatter;

    //filePath: string, row: SoundClip
    playerFormatter = (params: GridValueFormatterParams) => {
        const value = params.getValue(params.id, params.field)?.toString();
        if (value === undefined) {
            return <></>;
        }

        return (
            <AcxMicroPlayer
                style={{ marginLeft: "auto", marginRight: "auto" }}
                getFunction={() => {
                    return this.getSoundClip(value);
                }}
            />
        );
    };

    getSoundClip = async (filePath: string) => {
        if (filePath) {
            return await this.soundClipService.downloadAudioClipAsBlob(
                filePath,
            );
        }
        return new Blob([], { type: "audio/wav" });
    };

    booleanFormatter = BooleanFormatter;
    booleanComparator = BooleanComparator;

    linkFormatter = LinkFormatter;
    linkComparator = LinkComparator;

    genericLinkFormatter = GenericLinkFormatter;
    genericLinkComparator = GenericLinkComparator;

    LinkFormatterFilterOperator = getLinkFormatterOperators;

    LinkFormatterFilter = LinkFormatterFilter;
}
