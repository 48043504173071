import { AssignmentInd, CheckCircle, Create, Sms } from "@mui/icons-material";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import {
    INavigation,
    INavigationGroup,
} from "Layouts/SideNavigation/NavigationStore";
import SideNavigation from "Layouts/SideNavigationNew/SideNavigation";
import { observer } from "mobx-react";
import { AppDomains } from "models/Permission/AppDomains";
import React, { useState } from "react";
import { useLocation } from "react-router";
import { AuthStore } from "stores/AuthStore";
import AgentsSvg from "SvgIcons/ConfigIcons/AgentsSvg";
import BuildingSvg from "SvgIcons/ConfigIcons/BuildingSvg";
import HierarchySvg from "SvgIcons/ConfigIcons/HierarchySvg";
import ModulesSvg from "SvgIcons/ConfigIcons/ModulesSvg";
import RolesSvg from "SvgIcons/ConfigIcons/RolesSvg";
import UsersSvg from "SvgIcons/ConfigIcons/UsersSvg";
import { ThemeColors } from "Theme/AppTheme";
import { useStore } from "utils/useStore";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";

export interface ISecuredNavigation extends INavigation {
    permissions: string[];
    domain: AppDomains;
}

const orgSetupItems: ISecuredNavigation[] = [
    {
        title: "Org Details",
        permissions: [],
        domain: AppDomains.Administrative,
        link: `/admin/organizations/:organizationId`,
        icon: <BuildingSvg />,
        selectedIcon: <BuildingSvg color={ThemeColors.blue} opacity={1} />,
    },
    {
        title: "Modules",
        permissions: [],
        domain: AppDomains.Administrative,
        link: `/admin/organizations/:organizationId/modules`,
        icon: <ModulesSvg />,
        selectedIcon: <ModulesSvg color={ThemeColors.blue} opacity={1} />,
    },
    {
        title: "Workflows",
        permissions: [],
        domain: AppDomains.Administrative,
        link: `/admin/organizations/:organizationId/workflows`,
        icon: (
            <AccountTreeIcon
                style={{
                    width: "19px",
                    height: "20px",
                    fill: "#1F1F1F",
                    opacity: 0.5,
                }}
            />
        ),
        selectedIcon: (
            <AccountTreeIcon
                style={{
                    color: ThemeColors.blue,
                    opacity: 1,
                    width: "19px",
                    height: "20px",
                }}
            />
        ),
    },
    {
        title: "Service Hierarchy",
        permissions: [],
        domain: AppDomains.Administrative,
        link: `/admin/organizations/:organizationId/service-hierarchy`,
        icon: <HierarchySvg />,
        selectedIcon: <HierarchySvg color={ThemeColors.blue} opacity={1} />,
    },
    {
        title: "Contact Types",
        permissions: [],
        domain: AppDomains.Administrative,
        link: `/admin/organizations/:organizationId/contact-types`,
        icon: <UsersSvg />,
        selectedIcon: <UsersSvg color={ThemeColors.blue} opacity={1} />,
    },
    {
        title: "Model Controls",
        permissions: [],
        domain: AppDomains.Administrative,
        link: `/admin/organizations/:organizationId/model-controls`,
        icon: <SettingsSuggestIcon style={{ opacity: 0.5 }} />,
        selectedIcon: <SettingsSuggestIcon color={"primary"} />,
    },
];

const managementItems: ISecuredNavigation[] = [
    {
        title: "Roles",
        permissions: [],
        domain: AppDomains.Administrative,
        link: `/admin/organizations/:organizationId/roles`,
        icon: <RolesSvg />,
        selectedIcon: <RolesSvg color={ThemeColors.blue} opacity={1} />,
    },
    {
        title: "Users",
        permissions: [],
        domain: AppDomains.Administrative,
        link: `/admin/organizations/:organizationId/users`,
        icon: <UsersSvg />,
        selectedIcon: <UsersSvg color={ThemeColors.blue} opacity={1} />,
    },
    {
        title: "Agents",
        permissions: [],
        domain: AppDomains.Administrative,
        link: `/admin/organizations/:organizationId/agents`,
        icon: <AgentsSvg />,
        selectedIcon: <AgentsSvg color={ThemeColors.blue} opacity={1} />,
    },
];

const LearningItems: ISecuredNavigation[] = [
    {
        title: "Labeled Topics",
        permissions: [],
        domain: AppDomains.Administrative,
        link: `/admin/organizations/:organizationId/topics`,
        icon: <CheckCircle />,
        selectedIcon: (
            <CheckCircle style={{ color: ThemeColors.blue }} opacity={1} />
        ),
    },
    {
        title: "Unlabeled Topics",
        permissions: [],
        domain: AppDomains.Administrative,
        link: `/admin/organizations/:organizationId/uidtopics`,
        icon: <Sms />,
        selectedIcon: <Sms style={{ color: ThemeColors.blue }} opacity={1} />,
    },
];

type Props = {
    organizationId: string;
};

const OrganizationDetailNavigation: React.FC<Props> = observer(
    ({ organizationId }) => {
        const location = useLocation();
        const authStore = useStore(AuthStore);
        const permStore = authStore.permStore;
        const [currentNavItem, setCurrentNavItem] = useState<string>("");

        const MotfItems: ISecuredNavigation[] = React.useMemo(
            () => [
                {
                    title: "Definitions",
                    permissions: ["Sample Automation"],
                    domain: AppDomains.Administrative,
                    link: `/admin/organizations/:organizationId/motf/definitions`,
                    icon: <AssignmentInd />,
                    enabled: authStore.canUserView("Sample Automation"),
                    selectedIcon: (
                        <AssignmentInd
                            style={{ color: ThemeColors.blue }}
                            opacity={1}
                        />
                    ),
                },
                {
                    title: "Create Definition",
                    permissions: ["Sample Automation"],
                    domain: AppDomains.Administrative,
                    link: `/admin/organizations/:organizationId/motf/create`,
                    icon: <Create />,
                    enabled: authStore.canUserEdit("Sample Automation"),
                    selectedIcon: (
                        <Create
                            style={{ color: ThemeColors.blue }}
                            opacity={1}
                        />
                    ),
                },
            ],
            [authStore],
        );

        const [navGroupItems, setNavGroupItems] = useState<INavigationGroup[]>([
            { header: "Management", items: managementItems },
            { header: "Organization Setup", items: orgSetupItems },
            { header: "Learning", items: LearningItems },
            { header: "Sample Automation", items: MotfItems },
        ]);

        // Hide Workflows tab and Sample Automation expansion if permission not enabled
        React.useEffect(() => {
            if (!authStore.canUserEdit("Workflow Conditions")) {
                setNavGroupItems((prev) => {
                    const orgSetupGroup = prev.find(
                        (g) => g.header === "Organization Setup",
                    );

                    if (orgSetupGroup) {
                        orgSetupGroup.items = orgSetupGroup.items.filter(
                            (item) => item.title !== "Workflows",
                        );
                    }
                    return [...prev];
                });
            }

            if (!authStore.canUserView("Sample Automation"))
                setNavGroupItems((oldItems) =>
                    oldItems.filter(
                        (item) => item.header !== "Sample Automation",
                    ),
                );

            if (!authStore.canUserEdit("Organization Contact Types")) {
                setNavGroupItems((prev) => {
                    const orgSetupGroup = prev.find(
                        (g) => g.header === "Organization Setup",
                    );

                    if (orgSetupGroup) {
                        orgSetupGroup.items = orgSetupGroup.items.filter(
                            (item) => item.title !== "Contact Types",
                        );
                    }
                    return [...prev];
                });
            }

            if (!authStore.canUserEdit("Model Controls"))
                setNavGroupItems((prev) => {
                    const orgSetupGroup = prev.find(
                        (g) => g.header === "Organization Setup",
                    );

                    if (orgSetupGroup) {
                        orgSetupGroup.items = orgSetupGroup.items.filter(
                            (item) => item.title !== "Model Controls",
                        );
                    }
                    return [...prev];
                });
        }, [permStore, authStore, organizationId, MotfItems]);

        React.useEffect(() => {
            const maxDepth = 6;
            const path = location.pathname;
            const pathSplit = path.split("/");
            setCurrentNavItem(pathSplit.slice(0, maxDepth).join("/"));
        }, [location.pathname]);

        return (
            <SideNavigation
                items={navGroupItems}
                currentNavItem={currentNavItem}
                organizationId={organizationId}
            />
        );
    },
);

export default OrganizationDetailNavigation;
