import { Container, Grid } from "@mui/material";
import React from "react";
import TranscriptionVisualTranscriptionController from "../Transcription/TranscriptionVisualTranscriptionController";
import TranscriptionVisualAudioController from "../Audio/TranscriptionVisualAudioController";
import TranscriptionVisualClassifiersController from "../Classifiers/TranscriptionVisualClassifiersController";

interface OwnProps {
    transcriptionId: string;
}

export default function TranscriptionView(props: OwnProps) {
    return (
        <Container maxWidth={false} style={{ height: "90vh", width: "100%" }}>
            <Grid container style={{ height: "100%", width: "100%" }}>
                <Grid container spacing={1}>
                    <Grid
                        item
                        container
                        spacing={1}
                        style={{ height: "100%" }}
                        direction="column"
                        xs={9}
                    >
                        <Grid item>
                            <TranscriptionVisualTranscriptionController
                                transcriptionId={props.transcriptionId}
                            />
                        </Grid>
                        <Grid item>
                            <TranscriptionVisualAudioController
                                transcriptionId={props.transcriptionId}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <TranscriptionVisualClassifiersController />
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}
