import { action, makeObservable, observable } from "mobx";
import { BaseStore } from "stores/BaseStore";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";
import theme from "Theme/AppTheme";

export enum WorkflowsPage {
    WORKFLOWS_AGING = "aging",
}

@AcxStore
export default class AWOverviewChartStore extends BaseStore {
    @observable
    selectedChartType: "Priority" | "Status" = "Priority";

    @observable
    chartData: any[][] = []; // [statusSeriesName, seriesCount][];

    @observable
    chartColors: Record<string, string[]> = {}; //{[seriesName]: [graph color, chip color, count bg color]}

    @observable
    selectedSeriesName: string = "";

    priorityChartColors = {
        Priority: [
            theme.palette.orange.main,
            "orange",
            theme.palette.orange.main,
        ],
        Normal: [
            theme.palette.primary[800],
            "primary800",
            theme.palette.primary[800],
        ],
    };

    statusChartColors = {
        "In Progress": [
            theme.palette.blue.main,
            "blue",
            theme.palette.blue.main,
        ],
        Closed: [
            theme.palette.green.main,
            "secondary800",
            theme.palette.secondary[800],
        ],
        Overdue: [theme.palette.red.main, "red", theme.palette.red.main],
    };

    constructor(private rootStore: IRootStore) {
        super("WorkflowsNavigationStore");

        makeObservable(this);

        this.chartColors = this.priorityChartColors;
    }

    @action
    setCurrentChartType = (chartType: "Priority" | "Status") => {
        if (chartType === "Priority") {
            this.chartColors = this.priorityChartColors;
        } else if (chartType === "Status") {
            this.chartColors = this.statusChartColors;
        }

        this.selectedChartType = chartType;
    };

    @action
    setChartData = (input: any[][]) => {
        this.chartData = input;
    };

    @action
    setSelectedSeriesName = (input: string) => {
        this.selectedSeriesName = input;
    };
}
