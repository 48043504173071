import { Grid, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import clsx from "clsx";
import CreateNewAgentDialog from "components/Admin/Organizations/OrganizationDetail/OrganizationAgents/OrganizationAgentDetails/CreateNewAgentDialog";
import NoRedactionWarning from "components/UI/Chat/Views/NoRedactionWarning";
import SideControlsPage from "Layouts/SideControls/SideControlsPage";
import { Observer, observer } from "mobx-react";
import { EvaluationStatus } from "models/Evaluation";
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { EvaluationService } from "services/EvaluationService";
import { AuthStore } from "stores/AuthStore";
import useStyles from "Styles/Styles";
import { useStore } from "utils/useStore";
import { InteractionType } from "../../../models/InteractionType";
import { useResizeObserver } from "../../../utils/useResizeObserver";
import MessageStore from "../../ManagerInteractions/Stores/MessageStore";
import AcxLoadingIndicator from "../../UI/AcxLoadingIndicator";
import AcxSnackBar from "../../UI/AcxSnackBar";
import ErrorSnackBar from "../../UI/ErrorSnackBar";
import {
    CapaReviewModulesTypeName,
    ClassifierValidationModuleTypeName,
    EvalLicensedModule,
    EvalStore,
} from "../Stores/EvalStore";
import ClassifierResultFeedbackCard from "./ClassifierFeedback/ClassifierResultFeedbackCard";
import ClipList from "./Clips/ClipList";
import ConvertToHumanEvalDialog from "./Dialogs/ConvertToHumanEvalDialog";
import NoActionThreadsFoundDialog from "./Dialogs/NoActionThreadsFoundDialog";
import ReassignHierarchyDialog from "./Dialogs/ReassignHierarchyDialog";
import RequestDisputeDialog from "./Dialogs/RequestDisputeDialog";
import RequestReviewActionDialog from "./Dialogs/RequestReviewActionDialog";
import SaveEvaluationDialog from "./Dialogs/SaveEvaluationDialog";
import EvalNav from "./EvalNav";
import InteractionMeta from "./InteractionMeta";
import InteractionMetadata from "./InteractionMetadata";
import MediaViewerDock from "./MediaViewerDock";
import CapaModuleCard from "./Modules/Capa/CapaModuleCard";
import ModuleCard from "./Modules/ModuleCard";
import ReviewRequestDetailsCard from "./Modules/ReviewDetails/ReviewRequestDetailsCard";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            minWidth: "815px",
        },
        content: {
            height: "100%",
            width: "100%",
        },
        padding: {
            paddingLeft: "45px",
            paddingRight: "45px",
            paddingTop: "6px",
        },
        fullSize: {
            height: "100%",
            width: "100%",
        },
        interaction: {
            height: "100%",
            width: "100%",
        },
        clipList: {
            height: "100%",
            width: "100%",
        },
        metadataList: {
            height: "100%",
            width: "100%",
        },
        smallButtons: {
            maxHeight: "36px",
            textTransform: "none",
            margin: "0.5rem",
        },
        noWrapText: {
            whiteSpace: "nowrap",
        },
        audioRedactionWarning: {
            position: "absolute",
            bottom: "130px",
            zIndex: 2,
        },
        interactionDrawer: {
            zIndex: 1097,
        },
    });

const textMediaDockMinSize = 233;
const textMediaDockMaxSize = 545;

interface AnalystTimeSpentTrackerProps {
    evaluationId: string;
}

const trackingIntervalSeconds = 30;

function AnalystTimeSpentTracker(props: AnalystTimeSpentTrackerProps) {
    const intervalHandleRef = useRef<NodeJS.Timeout | null>(null);
    const lastTimeRef = useRef<number | null>(null);

    const evaluationService = useMemo(() => new EvaluationService(), []);

    const startTracking = useCallback(() => {
        if (intervalHandleRef.current) return;

        lastTimeRef.current = Date.now();
        intervalHandleRef.current = setInterval(() => {
            lastTimeRef.current = Date.now();
            evaluationService.addAnalystTimeSpent(
                props.evaluationId,
                trackingIntervalSeconds,
            );
        }, trackingIntervalSeconds * 1000);
    }, [props.evaluationId, evaluationService]);

    const stopTracking = useCallback(() => {
        if (intervalHandleRef.current) {
            clearInterval(intervalHandleRef.current);
            intervalHandleRef.current = null;
        }

        // any leftover time not hit by the interval is sent along
        if (lastTimeRef.current) {
            const leftoverTimeInMs = Date.now() - lastTimeRef.current;
            const leftoverTimeInSeconds = Math.floor(leftoverTimeInMs / 1000);
            lastTimeRef.current = null;

            if (leftoverTimeInSeconds <= 0) return;

            evaluationService.addAnalystTimeSpent(
                props.evaluationId,
                leftoverTimeInSeconds,
            );
        }
    }, [props.evaluationId, evaluationService]);

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.hidden) {
                stopTracking();
            } else {
                startTracking();
            }
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);
        window.addEventListener("beforeunload", stopTracking);
        startTracking();

        return () => {
            stopTracking();

            document.removeEventListener(
                "visibilitychange",
                handleVisibilityChange,
            );
            window.removeEventListener("beforeunload", stopTracking);
        };
    }, [startTracking, stopTracking]);

    return null;
}

const EvalPage = observer((props: { navigateBack: () => void }) => {
    const classes = useStyles(styles);
    const store = useStore(EvalStore);
    const messageStore = useStore(MessageStore);
    const authStore = useStore(AuthStore);
    const [isCurrentUserAnalyst, setIsCurrentUserAnalyst] = useState(false);

    const [isInteractionMetaClosed, setIsInteractionMetaClosed] =
        useState(false);

    useEffect(() => {
        store.showInteractionMetadata = false;
    }, [store]);

    useEffect(() => {
        if (!store.currentEval) return setIsCurrentUserAnalyst(false);

        authStore.getUserObject().then((user) => {
            if (!store.currentEval || !user)
                return setIsCurrentUserAnalyst(false);

            setIsCurrentUserAnalyst(
                store.currentEval.analystId === user.profile.uId,
            );
        });
    }, [store.currentEval, authStore]);

    const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        elementHeight: centerHeight,
        elementWidth: centerWidth,
        theRef: centerRef,
    } = useResizeObserver<HTMLDivElement>({
        name: "EvalCenterView",
        pixelDelta: 1,
    });

    const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        elementHeight: leftHeight,
        elementWidth: leftWidth,
        theRef: leftRef,
    } = useResizeObserver<HTMLDivElement>({
        name: "EvalLeftPanelView",
        pixelDelta: 1,
    });

    const canSeeTranscript = authStore.canUserView("Transcription");

    let avMediaDockMinSize = 80;
    let avMediaDockMaxSize = 195;

    if (canSeeTranscript) {
        avMediaDockMinSize = 195;
        avMediaDockMaxSize = 545;
    }

    return (
        <Observer>
            {() => (
                <>
                    {store.currentEval &&
                        store.currentEval.evaluationStatus ===
                            EvaluationStatus.InProgress &&
                        isCurrentUserAnalyst && (
                            <AnalystTimeSpentTracker
                                evaluationId={store.currentEval.id}
                            />
                        )}
                    <CreateNewAgentDialog
                        isOpen={store.createAgentDialogStore.isOpen}
                        onClose={store.createAgentDialogStore.close}
                        onSubmitHandler={store.onAgentCreateConfirm}
                        isLoading={store.getTaskLoading("Create Agent")}
                        orgId={store.organizationId}
                    />
                    <EvalNav
                        evalNumber={store.currentEval?.evaluationQbId ?? ""}
                        navigateBack={props.navigateBack}
                        moduleDisplayNames={store.evaluationModuleNames}
                    />
                    <AcxSnackBar
                        open={messageStore.showMessage}
                        onClose={messageStore.showNextMessage}
                        message={messageStore.currentMessage?.text || ""}
                        severity={messageStore.currentMessage?.severity}
                        options={messageStore.currentMessage?.options}
                    />
                    {store.noActionsFoundDialogStore.isOpen && (
                        <NoActionThreadsFoundDialog
                            dialogStore={store.noActionsFoundDialogStore}
                        />
                    )}
                    {store.saveEvalDialogStore.isOpen && (
                        <SaveEvaluationDialog
                            dialogStore={store.saveEvalDialogStore}
                        />
                    )}
                    {store.requestActionDialogStore.isOpen && (
                        <RequestReviewActionDialog
                            navigateBack={props.navigateBack}
                            dialogStore={store.requestActionDialogStore}
                        />
                    )}
                    {store.requestDisputeDialogStore.isOpen && (
                        <RequestDisputeDialog evalStore={store} />
                    )}
                    {store.reassignHierarchyDialogStore.isOpen && (
                        <ReassignHierarchyDialog evalStore={store} />
                    )}
                    <ConvertToHumanEvalDialog evalStore={store} />
                    <ErrorSnackBar
                        store={store}
                        onClose={store.clearLastTaskError}
                    />
                    <SideControlsPage
                        enableMinimize
                        drawerLocation="left"
                        drawerMaxWidth={480}
                        wideDrawerOverride
                        mainGridSpacing={0}
                        mainGridJustify={"flex-start"}
                        mainGridAlignItems={"stretch"}
                        containerOffsetPixels={64}
                        drawerVerticalOffsetPixels={0}
                        onDrawerChange={setIsInteractionMetaClosed}
                        drawerClosed={isInteractionMetaClosed}
                        drawerContentClasses={classes.interactionDrawer}
                        toggleButtonStyles={
                            isTextMediaType(store.mediaType)
                                ? {
                                      zIndex: 9999,
                                  }
                                : {}
                        }
                        mainContentClasses={clsx(
                            classes.fullSize,
                            classes.root,
                        )}
                        drawerContent={
                            <Grid
                                ref={leftRef}
                                container
                                item
                                className={classes.interaction}
                            >
                                <InteractionMeta
                                    drawerPixelOffset={store.mediaDrawerHeight}
                                    interactionType={store.mediaType}
                                    rightOffset={20}
                                />
                            </Grid>
                        }
                        mainContent={
                            <Grid
                                container
                                item
                                xs={12}
                                direction={"row"}
                                className={clsx(classes.fullSize, classes.root)}
                                sx={{ height: "100%", width: "100%" }}
                            >
                                <Grid
                                    ref={centerRef}
                                    item
                                    xs={
                                        (store.currentEval?.interaction
                                            ?.interactionType !== 4 &&
                                            (store.authStore.isUserUltra() ||
                                                !store.authStore.canUserView(
                                                    "Restrict Media",
                                                ))) ||
                                        store.showInteractionMetadata
                                            ? 9
                                            : 12
                                    }
                                    className={classes.fullSize}
                                >
                                    <div
                                        className={classes.padding}
                                        style={{
                                            height: `calc(100% -  ${store.mediaDrawerHeight}px)`,
                                            width: "100%",
                                        }}
                                    >
                                        {store.evalAndDependenciesLoading ? (
                                            <AcxLoadingIndicator
                                                color={"secondary"}
                                                alternate={"PuffLoader"}
                                                size={175}
                                            />
                                        ) : (
                                            <>
                                                {Boolean(
                                                    store.currentModule,
                                                ) && (
                                                    <Scrollbars autoHide>
                                                        {store.showReviewDetailsCard && (
                                                            <ReviewRequestDetailsCard
                                                                enableComments={
                                                                    false
                                                                }
                                                                navigateBack={
                                                                    props.navigateBack
                                                                }
                                                                {...store.reviewDetailsCardProps}
                                                            />
                                                        )}

                                                        <ModuleCard
                                                            module={
                                                                store.currentModule as EvalLicensedModule
                                                            }
                                                        />
                                                    </Scrollbars>
                                                )}
                                                {store.currentDynamicModule ===
                                                    ClassifierValidationModuleTypeName &&
                                                    store.classifierValidationEnabled && (
                                                        <Scrollbars autoHide>
                                                            <ClassifierResultFeedbackCard />
                                                        </Scrollbars>
                                                    )}

                                                {store.currentDynamicModule ===
                                                    CapaReviewModulesTypeName && (
                                                    <Scrollbars autoHide>
                                                        <CapaModuleCard
                                                            navigateBack={
                                                                props.navigateBack
                                                            }
                                                        />
                                                    </Scrollbars>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </Grid>
                                {store.showInteractionMetadata && (
                                    <Grid
                                        container
                                        item
                                        xs={2}
                                        className={clsx(classes.metadataList)}
                                        justifyContent="center"
                                    >
                                        <InteractionMetadata
                                            drawerPixelOffset={
                                                store.mediaDrawerHeight
                                            }
                                            interactionType={store.mediaType}
                                        />
                                    </Grid>
                                )}
                                {!store.showInteractionMetadata &&
                                    store.currentEval?.interaction
                                        ?.interactionType !== 4 &&
                                    (store.authStore.isUserUltra() ||
                                        !store.authStore.canUserView(
                                            "Restrict Media",
                                        )) && (
                                        <Grid
                                            container
                                            item
                                            xs={3}
                                            className={clsx(classes.clipList)}
                                            justifyContent="center"
                                        >
                                            <ClipList
                                                drawerPixelOffset={
                                                    store.mediaDrawerHeight
                                                }
                                                interactionType={
                                                    store.mediaType
                                                }
                                            />
                                        </Grid>
                                    )}
                            </Grid>
                        }
                    />

                    {Boolean(store.mediaType !== undefined) &&
                        (store.authStore.isUserUltra() ||
                            !store.authStore.canUserView("Restrict Media")) && (
                            <>
                                {store.showNotRedactedWarning === true &&
                                    store.mediaType ===
                                        InteractionType.Audio && (
                                        <Grid
                                            item
                                            container
                                            xs={12}
                                            // Need huge zIndex to go on top of side controls drawer
                                            style={{ zIndex: 9999 }}
                                            className={
                                                classes.audioRedactionWarning
                                            }
                                        >
                                            <NoRedactionWarning />
                                        </Grid>
                                    )}
                                <MediaViewerDock
                                    miniHeight={
                                        isTextMediaType(store.mediaType)
                                            ? textMediaDockMinSize
                                            : avMediaDockMinSize
                                    }
                                    maxHeight={
                                        isTextMediaType(store.mediaType)
                                            ? textMediaDockMaxSize
                                            : avMediaDockMaxSize
                                    }
                                    altLeftPosition={
                                        isTextMediaType(store.mediaType)
                                            ? isInteractionMetaClosed
                                                ? 0
                                                : leftWidth
                                            : undefined
                                    }
                                    altWidth={
                                        isTextMediaType(store.mediaType)
                                            ? centerWidth
                                            : undefined
                                    }
                                    store={store}
                                    canSeeTranscript={canSeeTranscript}
                                    collapsible
                                />
                            </>
                        )}
                </>
            )}
        </Observer>
    );
});

export function isTextMediaType(mediaType?: InteractionType) {
    return (
        mediaType === InteractionType.ProcessedChat ||
        mediaType === InteractionType.Email
    );
}

export default EvalPage;
