import type { GridCellParams, GridRowParams } from "@mui/x-data-grid-pro";
import UserService from "components/Admin/Organizations/OrganizationDetail/OrganizationUsers/User.service";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import AcxDataGridStore from "components/UI/AcxDataGrid/AcxDataGridStore";
import { dateColumnType } from "components/UI/AcxDataGrid/ColumnTypes/DateColTypes";
import IColDef from "components/UI/AcxDataGrid/IColDef";
import {
    action,
    makeObservable,
    observable,
    reaction,
    runInAction,
} from "mobx";
import type Classifier from "models/ClassifierModel";
import { ScoredNGram } from "models/NGram";
import {
    ReportDataField,
    ReportFieldDataTypes,
} from "models/Reporting/ReportDataField";
import {
    DateAggregation,
    FieldUse,
    ReportField,
} from "models/Reporting/ReportField";
import { ReportModel } from "models/Reporting/ReportModel";
import ClassifierService from "services/ClassifierService";
import { ModelDashboardService } from "services/ModelDashboardService";
import { OrganizationService } from "services/OrganizationService";
import { AuthStore } from "stores/AuthStore";
import { BaseStore } from "stores/BaseStore";
import {
    DatePickerComponentStore,
    DateReferenceOption,
} from "stores/ComponentStores/DatePickerComponentStore";
import type { IRootStore } from "stores/RootStore";
import { AcxStore } from "stores/RootStore";
import { reportDataToGoogleFormat2 } from "utils/ReportDataToGoogleFormat";

export const LOAD_STATS = "Load Interaction Stat Cards";
export const LOAD_MODELS = "Load Available Models List";
export const LOAD_COST_METRICS = "Load Cost Metrics";
export const LOAD_CALL_METRICS = "Load Call Metrics";
export const LOAD_SENTIMENT_METRICS = "Load Sentiment Metrics";
export const LOAD_TRENDING = "Load Trending Eddy Effect";
export const LOAD_BY_RBC = "Load Eddy By RBC";
export const LOAD_SENTIMENT = "Load Eddy By Sentiment";
export const LOAD_TOPIC = "Load Eddy By Topic";
export const LOAD_AMD_STATS = "Load Amd stats";

export interface AvailableStatsObject {
    averageScore: number;
    callDuration: number;
    sentiment: number;
    estimatedCost: number;
}

@AcxStore
export default class ModelDashboardStore extends BaseStore {
    private readonly classifierService: ClassifierService;
    private readonly modelDashboardService: ModelDashboardService;

    public readonly userService: typeof UserService;
    public readonly orgService: OrganizationService;
    public readonly messageStore: MessageStore;
    public readonly datePickerStore = new DatePickerComponentStore();
    public selectedDataDgStore: AcxDataGridStore = new AcxDataGridStore();
    public readonly authStore: AuthStore;

    @observable.ref
    organizationId: string;

    @observable.ref
    availableModels: Classifier[] = [];

    @observable.ref
    availableModelData?: any[] = [];

    @observable.ref
    availableWordCloudData: any[] = [];

    @observable.ref
    selectedModel?: Classifier;

    @observable
    selectedWordCloudData?: ScoredNGram[] = [];

    @observable
    selectedWordCloudDataForAggregatedCard?: ScoredNGram[] = [];

    @observable
    callDirection?: { label: string; value: string };

    @observable
    amdStats?: any;

    @observable
    callMetrics?: any;

    @observable
    sentimentMetrics?: any;

    @observable
    costMetrics?: any;

    @observable
    showWordCloudDialog: boolean = false;

    @observable
    showSelectedDataDialog: boolean = false;

    @observable
    drillEddyMetadata: Map<string, any[]> = new Map();

    @observable
    eddyByTopicTrueData: any[] = [];

    @observable
    eddyByTopicFalseData: any[] = [];

    @observable
    drillEddyMetadataLabel: string = "";

    @observable
    drillEddyDialogTitle: string = "";

    @observable
    eddyModelId: string | undefined;

    @observable
    eddyModel: ReportModel;

    @observable
    prevOnselectData: {
        series: string | null;
        x: string | number | null;
        y: any;
        chartId?: string;
        metadata?: any[] | null;
    };

    @observable
    hierarchyIds: string[] = [];

    constructor(public rootStore: IRootStore) {
        super("ModelDashboardStore");

        makeObservable(this);

        this.authStore = rootStore.getStore(AuthStore);
        this.messageStore = rootStore.getStore(MessageStore);

        this.classifierService = new ClassifierService();
        this.modelDashboardService = new ModelDashboardService();
        this.orgService = new OrganizationService();
        this.userService = UserService;

        // Selection enabled only for rows that are ready to sample
        // Select no more than 10 rows at a time for drill eddy interactions
        this.selectedDataDgStore.checkboxSelection = true;
        this.selectedDataDgStore.checkboxSelectionVisibleOnly = true;
        this.selectedDataDgStore.isRowSelectable = (param: GridRowParams) => {
            const isRowReadyToSample = param.row.isReadyToSample;
            const selectedRowCount =
                this.selectedDataDgStore.selectedRows.length;
            const isSelected = this.selectedDataDgStore.selectedRowIds.includes(
                param.row.id,
            );
            return isRowReadyToSample && (selectedRowCount < 10 || isSelected);
        };

        this.selectedDataDgStore.controlsColumnStyle = {
            height: "5px",
        };

        const columns: IColDef[] = [
            {
                headerName: "Audio Metadata Id",
                field: "audioMetadataId",
                renderType: "longString",
                flex: 1,
            },
            {
                headerName: "Agent Name",
                field: "agentName",
                renderType: "longString",
                flex: 1,
            },

            {
                headerName: "Call Length",
                field: "callLength",
                renderType: "longString",
                flex: 1,
            },
            {
                headerName: "Call Direction",
                field: "callDirection",
                renderType: "longString",
                flex: 1,
            },
            {
                headerName: "Media Type",
                field: "mediaType",
                flex: 1,
                renderType: "longString",
            },

            {
                headerName: this.datePickerStore.dateRangeFilterLabel,
                field: "createdDate",
                flex: 1,
                ...dateColumnType,
            },
            {
                field: "blobUrl",
                headerName: " ",
                renderType: "playerFormatter",
                hideSortIcons: true,
                filterable: false,
                width: 50,
                align: "right",
            },
            {
                field: "callSummary",
                headerName: "Call Summary",
                renderType: "longString",
                flex: 1,
            },
            {
                headerName: "File Path",
                field: "filePath",
                flex: 1,
                renderType: "longString",
            },
            {
                headerName: "Status",
                field: "isReadyToSample",
                flex: 1,
                renderCell: (params: GridCellParams) =>
                    params.row.isReadyToSample ? "Ready To Sample" : "Sampled",
            },
        ];

        this.selectedDataDgStore.setColumns(columns);
        this.selectedDataDgStore.removeHeight = "50px";

        this.datePickerStore.referenceOption =
            DateReferenceOption.InteractionDate;

        reaction(
            () => ({
                storeError: this.nextTaskError,
            }),
            (arg) => {
                if (arg && arg.storeError?.message) {
                    const msg = arg.storeError?.message;
                    this.messageStore.logError(msg);
                    this.clearLastTaskError();
                }
            },
            { fireImmediately: true },
        );

        reaction(
            () => ({
                orgId: this.authStore.orgStore.selectedOrganization?.id,
                activeLocation: this.rootStore.activeLocation,
            }),
            (args) => {
                if (
                    args.activeLocation &&
                    args.activeLocation.location.includes(
                        "nlp/modeldashboard",
                    ) &&
                    args.orgId
                ) {
                    this.setOrganizationId(args.orgId);
                    this.resetFilters();

                    this.setupAsyncTask(LOAD_MODELS, async () => {
                        // Include RBCs & Tensorflow Models
                        const allClassifiers =
                            await this.classifierService.getPublishedClassifiers(
                                this.organizationId,
                            );

                        // Find the ML-EddyEffect tensorflow model
                        const mlEddyEffect = allClassifiers.find(
                            (classifier) => classifier.name === "ML-EddyEffect",
                        );

                        if (mlEddyEffect) {
                            this.setAvailableModels([mlEddyEffect]);
                        } else {
                            this.setAvailableModels([]);
                        }

                        if (!this.selectedModel) {
                            this.setSelectedModel(this.availableModels[0]);
                        }
                    });

                    this.messageStore.logMessage(
                        "Please apply the desired filters and press refresh to generate reports.",
                        "info",
                    );
                }
            },
            { fireImmediately: true },
        );

        reaction(
            () => this.callDirection,
            () => {
                this.loadReports();
            },
        );

        reaction(
            () => this.availableWordCloudData,
            () => {
                this.setSelectedWordCloudDataForAggregated();
            },
        );

        reaction(
            (r) => ({
                orgId: this.authStore.orgStore.selectedOrganization?.id,
            }),
            () => this.setHierarchyIds([]),
            { fireImmediately: true },
        );
    }

    getOrgEddyCost = () => {
        return this.authStore.orgStore.selectedOrganization?.eddyHourlyRate;
    };

    @action
    setOrganizationId = (orgId: string) => {
        this.organizationId = orgId;
    };

    @action
    toggleShowWordCloudDialog = (
        index: number | null,
        chartName?: string | null,
    ) => {
        this.setSelectedWordCloudData(index, chartName);
        this.showWordCloudDialog = !this.showWordCloudDialog;
    };

    @action
    toggleShowSelectedData = () => {
        this.showSelectedDataDialog = !this.showSelectedDataDialog;
    };

    @action
    setSelectedWordCloudData = (
        index: number | null,
        chartName?: string | null,
    ) => {
        if (index !== null) {
            const { topicWords, wordScores } =
                this.availableWordCloudData[index];
            const highlightArray = this.availableModelData?.filter(
                (d: any) => d.chartName === chartName,
            );
            let highlightString = "";
            if (highlightArray) {
                highlightString = highlightArray[0]["data"][index + 1][0]
                    .split("(n=")[0]
                    .split(",")
                    .map((element) => element.trim());
            }

            const formattedData = [] as ScoredNGram[];

            topicWords.forEach((topicWord: string, i: number) => {
                const currentScore = wordScores[i];
                if (highlightString.indexOf(topicWord) !== -1) {
                    formattedData.push({
                        nGram: topicWord,
                        score: wordScores[0],
                    } as ScoredNGram);
                } else {
                    formattedData.push({
                        nGram: topicWord,
                        score: currentScore,
                    } as ScoredNGram);
                }
            });

            this.selectedWordCloudData = formattedData;
        } else {
            this.selectedWordCloudData = [];
        }
    };

    @action
    setAvailableModels = (models: Classifier[]) => {
        this.availableModels = models;
    };

    @action
    setSelectedModel = (model: Classifier) => {
        this.selectedModel = model;
    };

    public reOrderGrid() {
        const displayOrder = [
            "Eddy Effect Monthly Trend",
            "Eddy Effect by Classifiers",
            "Eddy Effect by Topic",
            "Aggregated Eddy Effect Topic Word Cloud",
            "Eddy Effect by Sentiment Change (Ending Sentiment - Beginning Sentiment)",
        ];
        const currentOrder = this.availableModelData;
        let newOrder: any = [];
        displayOrder.forEach((modelItem: any, adjustIndex: number) => {
            currentOrder?.forEach((model: any) => {
                if (modelItem === model.chartName) {
                    newOrder[adjustIndex] = model;
                } else if (
                    modelItem === "Aggregated Eddy Effect Topic Word Cloud"
                ) {
                    newOrder[adjustIndex] = {
                        chartName: "Aggregated Eddy Effect Topic Word Cloud",
                    };
                }
            });
        });

        this.availableModelData = newOrder;
    }
    @action
    setAvailableModelData = (data: any) => {
        if (this.availableModelData && this.availableModelData.length > 0) {
            const filteredArray = this.availableModelData.filter(
                (d: any) => d.chartName !== data.chartName,
            );

            this.availableModelData = [...filteredArray, data];
            this.reOrderGrid();
        } else {
            this.availableModelData = [data];
            this.reOrderGrid();
        }
    };

    @action
    setDrillEddyDialogTitle = (
        y: number,
        series: string | null,
        chartId?: string,
    ) => {
        if (chartId !== undefined) {
            if (chartId === "MonthlyTrend") {
                this.drillEddyDialogTitle = this.drillEddyMetadataLabel
                    ? "Selected trending data:  " + this.drillEddyMetadataLabel
                    : "Selected trending data";
            } else if (chartId === "Classifiers") {
                this.drillEddyDialogTitle = this.drillEddyMetadataLabel
                    ? "Selected classifier data:  " +
                      this.drillEddyMetadataLabel
                    : "Selected classifier data";
            } else if (chartId === "Topics" && series !== null) {
                this.drillEddyDialogTitle = this.drillEddyMetadataLabel
                    ? "Selected topic data:  " +
                      this.drillEddyMetadataLabel +
                      "  " +
                      series +
                      "  " +
                      y +
                      "%"
                    : "Selected topic data";
            } else if (chartId === "SentimentChange" && series !== null) {
                this.drillEddyDialogTitle = this.drillEddyMetadataLabel
                    ? "Selected sentiment data:  " +
                      this.drillEddyMetadataLabel +
                      ",  " +
                      series +
                      "  " +
                      y +
                      "%"
                    : "Selected sentiment data";
            } else {
                this.drillEddyDialogTitle = " ";
            }
        }
    };

    @action
    setEddyModelId = (chartId?: string) => {
        if (chartId === "MonthlyTrend") {
            this.eddyModelId = "TrendingEddyEffect";
        } else if (chartId === "Classifiers") {
            this.eddyModelId = "EddyByRbc";
        } else if (chartId === "Topics") {
            this.eddyModelId = "EddyByTopic";
        } else if (chartId === "SentimentChange") {
            this.eddyModelId = "SentimentByEddy";
        } else {
            this.eddyModelId = "";
        }
    };

    @action
    setEddyModel = () => {
        runInAction(() => {
            this.eddyModel = new ReportModel(
                this.eddyModelId,
                undefined,
                undefined,
                this.organizationId,
            );

            this.eddyModel.reportFields = [
                {
                    fieldUse: FieldUse.X,
                    dateAggregation: DateAggregation.MonthYear,
                    reportDataField: {
                        datatype: ReportFieldDataTypes.Date,
                    } as ReportDataField,
                } as ReportField,
            ];
        });
    };

    setTopicDrillEddyMetadata = (series: string) => {
        if (series === "Eddy True") {
            this.eddyByTopicTrueData.map((data) =>
                this.drillEddyMetadata.set(data.x, data.metadata),
            );
        } else if (series === "Eddy False") {
            this.eddyByTopicFalseData.map((data) =>
                this.drillEddyMetadata.set(data.x, data.metadata),
            );
        }
    };

    @action
    setAvailableWordCloudData = (data: any) => {
        this.availableWordCloudData = data;
    };

    @action
    setCallDirection = (callDirection) => {
        this.callDirection = callDirection;
    };

    @action
    setHierarchyIds = (selectedBranchIds: string[]) => {
        this.hierarchyIds = selectedBranchIds;
    };

    @action
    resetFilters = () => {
        runInAction(() => {
            this.callDirection = undefined;
        });
    };

    isLoading = () => {
        if (
            this.getTaskLoading(LOAD_SENTIMENT) &&
            this.getTaskLoading(LOAD_BY_RBC) &&
            this.getTaskLoading(LOAD_TRENDING) &&
            this.getTaskLoading(LOAD_TOPIC)
        ) {
            return true;
        }

        return false;
    };

    setSelectedWordCloudDataForAggregated = () => {
        const index = 0;

        if (index !== null) {
            if (!this.availableWordCloudData.length) return;

            let allTopicWordsArray = [];
            let allWordScoresArray = [];

            for (const wordCloudItem of this.availableWordCloudData) {
                const { topicWords, wordScores } = wordCloudItem;
                allTopicWordsArray = allTopicWordsArray.concat(topicWords);
                allWordScoresArray = allWordScoresArray.concat(wordScores);
            }

            // Filtering out repeating words
            const allTopicWordsArrayRepeatedWordsFiltered: string[] = [];
            const allWordScoresArrayRepeatedWordsFiltered: number[] = [];

            for (let i = 0; i < allTopicWordsArray.length; i++) {
                const isAlreadyThereIndex =
                    allTopicWordsArrayRepeatedWordsFiltered.findIndex(
                        (item) => item === allTopicWordsArray[i],
                    );

                if (isAlreadyThereIndex !== -1) {
                    if (
                        allWordScoresArray[i] >
                        allWordScoresArrayRepeatedWordsFiltered[
                            isAlreadyThereIndex
                        ]
                    ) {
                        allWordScoresArrayRepeatedWordsFiltered[
                            isAlreadyThereIndex
                        ] = allWordScoresArray[i];
                    }
                } else {
                    allTopicWordsArrayRepeatedWordsFiltered.push(
                        allTopicWordsArray[i],
                    );
                    allWordScoresArrayRepeatedWordsFiltered.push(
                        allWordScoresArray[i],
                    );
                }
            }

            // NGram object formatting
            const formattedData = [] as ScoredNGram[];

            allTopicWordsArrayRepeatedWordsFiltered.forEach(
                (topicWord: string, i: number) => {
                    const currentScore =
                        allWordScoresArrayRepeatedWordsFiltered[i];

                    const newWordObj: ScoredNGram = {
                        nGram: topicWord,
                        score: currentScore,
                    };

                    formattedData.push(newWordObj);
                },
            );

            this.selectedWordCloudDataForAggregatedCard = formattedData;
        } else {
            this.selectedWordCloudDataForAggregatedCard = [];
        }
    };

    onSelect = (
        series: string | null,
        x: string | number | null,
        y: any,
        chartId?: string,
        metadata?: any[] | null,
    ) => {
        this.prevOnselectData = {
            series,
            x,
            y,
            chartId,
            metadata,
        };
        this.drillEddyMetadataLabel = x?.toString() ?? "";
        this.showSelectedDataDialog = true;
        this.setDrillEddyDialogTitle(y, series, chartId);
        this.setEddyModelId(chartId);

        if (chartId === "Topics" && series !== null) {
            this.setTopicDrillEddyMetadata(series);
        }

        this.setupAsyncTask("Load Drill Down Data", async () => {
            if (chartId === "Topics" && series !== null) {
                const drillEddyData =
                    await this.modelDashboardService.drillEddy(
                        this.organizationId,
                        this.callDirection?.value,
                        this.datePickerStore.beginDate,
                        this.datePickerStore.endDate,
                        this.datePickerStore.referenceOption,
                        this.hierarchyIds,
                        chartId,
                        this.drillEddyMetadata.get(x?.toString() ?? ""),
                    );
                this.selectedDataDgStore.rows = drillEddyData.map((item) => ({
                    ...item,
                    id: item.audioMetadataId,
                }));
                this.selectedDataDgStore.clearSelected();
            } else {
                const drillEddyData =
                    await this.modelDashboardService.drillEddy(
                        this.organizationId,
                        this.callDirection?.value,
                        this.datePickerStore.beginDate,
                        this.datePickerStore.endDate,
                        this.datePickerStore.referenceOption,
                        this.hierarchyIds,
                        chartId,
                        metadata,
                    );
                this.selectedDataDgStore.rows = drillEddyData.map((item) => ({
                    ...item,
                    id: item.audioMetadataId,
                }));
                this.selectedDataDgStore.clearSelected();
            }

            this.selectedDataDgStore.columns.forEach((column) => {
                if (column.field === "createdDate") {
                    column.headerName =
                        this.datePickerStore.dateRangeFilterLabel;
                }
            });
        });
    };

    public loadReports = () => {
        const errorFormatter = (value: any) => {
            const error = value.stack.slice(
                value.stack.indexOf(`"Message":`) + `"Message":`.length,
                value.stack.indexOf(`,"Data"`),
            );

            return error;
        };

        if (this.callDirection || this.hierarchyIds.length) {
            this.messageStore.logMessage(
                "Filters have been applied.",
                "success",
            );
        }

        this.setupAsyncTask(
            LOAD_COST_METRICS,
            async () => {
                if (this.organizationId && this.selectedModel) {
                    const costMetrics =
                        await this.modelDashboardService.getEstimatedCostMetricForEddy(
                            this.organizationId,
                            this.selectedModel.id,
                            this.callDirection?.value,
                            this.datePickerStore.beginDate,
                            this.datePickerStore.endDate,
                            this.datePickerStore.referenceOption,
                            this.hierarchyIds,
                        );

                    runInAction(() => {
                        this.costMetrics = costMetrics;
                    });
                }
            },
            undefined,
            errorFormatter,
        );

        this.setupAsyncTask(
            LOAD_AMD_STATS,
            async () => {
                if (this.organizationId && this.selectedModel) {
                    const amdStats =
                        await this.modelDashboardService.getAmdStatus(
                            this.organizationId,
                            this.selectedModel.id,
                            this.callDirection?.value,
                            this.datePickerStore.beginDate,
                            this.datePickerStore.endDate,
                            this.datePickerStore.referenceOption,
                            this.hierarchyIds,
                        );

                    runInAction(() => {
                        this.amdStats = amdStats.result;
                    });
                }
            },
            undefined,
            errorFormatter,
        );

        this.setupAsyncTask(LOAD_TRENDING, async () => {
            if (this.organizationId && this.selectedModel) {
                const trendingEddyEffect =
                    (await this.modelDashboardService.getDataForTrendingEddyEffect(
                        this.organizationId,
                        this.selectedModel.id,
                        this.callDirection?.value,
                        this.datePickerStore.beginDate,
                        this.datePickerStore.endDate,
                        this.datePickerStore.referenceOption,
                        this.hierarchyIds,
                    )) as any;

                this.setEddyModel();

                trendingEddyEffect.data.map((data) =>
                    this.drillEddyMetadata.set(data.x, data.metadata),
                );

                trendingEddyEffect.data = reportDataToGoogleFormat2(
                    trendingEddyEffect.data,
                    this.eddyModel,
                    false,
                    true,
                    undefined,
                    undefined,
                    undefined,
                    true,
                );

                this.setAvailableModelData(trendingEddyEffect);
            }
        });

        this.setupAsyncTask(LOAD_BY_RBC, async () => {
            if (this.organizationId && this.selectedModel) {
                const eddyByRbc =
                    (await this.modelDashboardService.getDataForEddyByRbc(
                        this.organizationId,
                        this.selectedModel.id,
                        this.callDirection?.value,
                        this.datePickerStore.beginDate,
                        this.datePickerStore.endDate,
                        this.datePickerStore.referenceOption,
                        this.hierarchyIds,
                    )) as any;

                this.setEddyModel();

                eddyByRbc.data.map((data) =>
                    this.drillEddyMetadata.set(data.x, data.metadata),
                );

                eddyByRbc.data = reportDataToGoogleFormat2(
                    eddyByRbc.data,
                    this.eddyModel,
                    false,
                    false,
                    true,
                    true,
                    true,
                    true,
                );

                this.setAvailableModelData(eddyByRbc);
            }
        });

        this.setupAsyncTask(LOAD_SENTIMENT, async () => {
            if (this.organizationId && this.selectedModel) {
                const eddyBySentiment =
                    (await this.modelDashboardService.getDataForEddyBySentiment(
                        this.organizationId,
                        this.selectedModel.id,
                        this.callDirection?.value,
                        this.datePickerStore.beginDate,
                        this.datePickerStore.endDate,
                        this.datePickerStore.referenceOption,
                        this.hierarchyIds,
                    )) as any;

                this.setEddyModel();

                eddyBySentiment.data.map((data) =>
                    this.drillEddyMetadata.set(data.x, data.metadata),
                );

                eddyBySentiment.data = reportDataToGoogleFormat2(
                    eddyBySentiment.data,
                    this.eddyModel,
                    false,
                    false,
                    true,
                    true,
                    undefined,
                    true,
                );

                this.setAvailableModelData(eddyBySentiment);
            }
        });

        this.setupAsyncTask(LOAD_TOPIC, async () => {
            if (this.organizationId && this.selectedModel) {
                const eddyByTopic =
                    (await this.modelDashboardService.getTopicsForEddy(
                        this.organizationId,
                        this.selectedModel.id,
                        this.callDirection?.value,
                        this.datePickerStore.beginDate,
                        this.datePickerStore.endDate,
                        this.datePickerStore.referenceOption,
                        this.hierarchyIds,
                    )) as any;

                this.setEddyModel();

                this.eddyByTopicTrueData = eddyByTopic.chartRequest.data.filter(
                    (data) => data.grouping === "Eddy True",
                );

                this.eddyByTopicFalseData =
                    eddyByTopic.chartRequest.data.filter(
                        (data) => data.grouping === "Eddy False",
                    );

                // this initially sets all response data for drillEddyMetadata
                // until OnSelect, where setTopicDrillEddyMetadata is called and series is passed
                // if series t/f, the filtered data response is set as the new drillEddyMetadata
                eddyByTopic.chartRequest.data.map((data) =>
                    this.drillEddyMetadata.set(data.x, data.metadata),
                );

                eddyByTopic.chartRequest.data = reportDataToGoogleFormat2(
                    eddyByTopic.chartRequest.data,
                    this.eddyModel,
                    false,
                    false,
                    true,
                    true,
                    undefined,
                    true,
                );

                this.setAvailableModelData(eddyByTopic.chartRequest);
                this.setAvailableWordCloudData(eddyByTopic.wordCloudData);
            }
        });

        // this.setupAsyncTask(
        //     LOAD_CALL_METRICS,
        //     async () => {
        //         if (this.organizationId && this.selectedModel) {
        //             const callMetrics =
        //                 await this.modelDashboardService.getCallDurationMetricForEddy(
        //                     this.organizationId,
        //                     this.selectedModel.id,
        //                     this.callDirection?.value,
        //                     this.datePickerStore.beginDate,
        //                     this.datePickerStore.endDate,
        //                     this.datePickerStore.referenceOption,
        //                     this.selectedHierarchy?.id,
        //                 );

        //             runInAction(() => {
        //                 this.callMetrics = callMetrics;
        //             });
        //         }
        //     },
        //     undefined,
        //     errorFormatter,
        // );

        // this.setupAsyncTask(
        //     LOAD_SENTIMENT_METRICS,
        //     async () => {
        //         if (this.organizationId && this.selectedModel) {
        //             const sentimentMetrics =
        //                 await this.modelDashboardService.getSentimentMetricForEddy(
        //                     this.organizationId,
        //                     this.selectedModel.id,
        //                     this.callDirection?.value,
        //                     this.datePickerStore.beginDate,
        //                     this.datePickerStore.endDate,
        //                     this.datePickerStore.referenceOption,
        //                     this.selectedHierarchy?.id,
        //                 );

        //             runInAction(() => {
        //                 this.sentimentMetrics = sentimentMetrics.result;
        //             });
        //         }
        //     },
        //     undefined,
        //     errorFormatter,
        // );
    };
}
