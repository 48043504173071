import { BaseStore } from "stores/BaseStore";
import RootStore, { AcxStore } from "stores/RootStore";
import type { IRootStore } from "../RootStore";
import { action, computed, makeObservable, observable, reaction } from "mobx";
import {
    ApplicationFilters,
    ApplicationFiltersStore,
} from "./ApplicationFiltersStore";
import { MetaLabelService } from "services/MetaLabelService";
import QuestionMetadataService from "services/QuestionMetadataService";
import { AuthStore } from "stores/AuthStore";
import _, { startCase } from "lodash";
import { ApplicationFiltersInstanceStore } from "./ApplicationFiltersInstanceStore";
import { IRBCFilter } from "components/Conversations/Views/Drawer/components/DeepFilter/RBCFilterStore";
import { OrganizationMetadataField } from "models/OrganizationModels/OrganizationMetadataField";

// A "filter key" for a filter that modifies more than 1
// filter value at a time. ex. dateRange would provide a component
// to modify  startDate, endDate, dateRangeLabel, and dateReferenceOption
type CompoundFilterKey = "callDuration" | "dateRange";

export type FilterKey =
    | keyof ApplicationFilters
    | CompoundFilterKey
    | `extendedMetadata.${string}`;

export interface IApplicationFilterItem {
    title: string;
    filterKey: FilterKey;
    permission?: string;
}

export interface ApplicationFilterGroup {
    title: string;
    items: IApplicationFilterItem[];
}

const filterGroupTitles = {
    basicFilters: "Basic Filters",
    mlModelIdentification: "ML Model Identification",
    additionalMetadata: "Additional Metadata",
} as const;

type FilterGroupTitle =
    typeof filterGroupTitles[keyof typeof filterGroupTitles];

const defaultFilterGroups: ApplicationFilterGroup[] = [
    {
        title: filterGroupTitles.basicFilters,
        items: [
            {
                title: "Agent",
                filterKey: "agentId",
            },
            {
                title: "Call Direction",
                filterKey: "callDirections",
            },
            {
                title: "Call Duration",
                filterKey: "callDuration",
            },
            {
                title: "Classifiers",
                filterKey: "rbcFilterItems",
            },
            {
                title: "Client Call Id",
                filterKey: "clientCallId",
            },
            {
                title: "Date Range",
                filterKey: "dateRange",
            },
            {
                title: "Date Type",
                filterKey: "dateReferenceOption",
            },
            {
                title: "Evaluation Type",
                filterKey: "evaluationTypes",
            },
            {
                title: "Hierarchy",
                filterKey: "hierarchyIds",
            },
            {
                title: "Media Type",
                filterKey: "mediaTypes",
            },
            {
                title: "Words or Phrases",
                filterKey: "wordsAndPhrasesSearchString",
            },
        ],
    },
    {
        title: filterGroupTitles.mlModelIdentification,
        items: [
            {
                title: "Adverse Event",
                filterKey: "adverseEvent",
                permission: "Safety Events Model",
            },
            {
                title: "Contact Type",
                filterKey: "contactTypes",
            },
            {
                title: "Eddy Effect Signal",
                filterKey: "eddyEffectStatus",
            },
            {
                title: "Ending Sentiment",
                filterKey: "endingSentiment",
            },
            {
                title: "HIPAA Compliance",
                filterKey: "hipaaCompliance",
                permission: "HIPAA Compliance Model",
            },
            {
                title: "Safety Event",
                filterKey: "safetyEvent",
                permission: "Safety Events Model",
            },
            {
                title: "Starting Sentiment",
                filterKey: "beginningSentiment",
            },
            {
                title: "Topics",
                filterKey: "topics",
                permission: "Topics",
            },
        ],
    },
    {
        title: filterGroupTitles.additionalMetadata,
        // Meta 1-5 title are updated when meta labels are loaded
        // Extended metadata items are added once loaded
        items: [
            {
                title: "Meta 1",
                filterKey: "meta1",
            },
            {
                title: "Meta 2",
                filterKey: "meta2",
            },
            {
                title: "Meta 3",
                filterKey: "meta3",
            },
            {
                title: "Meta 4",
                filterKey: "meta4",
            },
            {
                title: "Meta 5",
                filterKey: "meta5",
            },
        ],
    },
];

@AcxStore
export class ApplicationFilterItemsStore extends BaseStore {
    public static readonly Tasks = {
        LOAD_META_LABELS: "Load Meta Labels",
        LOAD_AVAILABLE_METADATA_FIELDS: "Load Available Metadata Fields",
    };

    @observable
    public filterGroups: ApplicationFilterGroup[] =
        _.cloneDeep(defaultFilterGroups);

    private readonly authStore: AuthStore;

    private readonly questionMetadataService = new QuestionMetadataService();
    private readonly metaLabelService = new MetaLabelService();

    @observable
    public selectedFilterItems: IApplicationFilterItem[] = [];

    private organizationId: string;

    constructor(private rootStore: IRootStore) {
        super("Application Filter Items");
        makeObservable(this);

        this.authStore = rootStore.getStore(AuthStore);

        reaction(
            () => this.authStore.orgStore.selectedOrganization,
            async (organization) => {
                if (!organization) return;

                this.organizationId = organization.id;

                this.resetForOrgChange();

                await this.loadMetadataLabels();
            },
            { fireImmediately: true },
        );
    }

    @computed get availableFilterGroups() {
        const groups: ApplicationFilterGroup[] = [];

        for (const group of this.filterGroups) {
            const remainingItems: IApplicationFilterItem[] = [];

            for (const item of group.items) {
                // if we don't have the authStore yet and the item does have a permission
                // don't show it by default
                if (!this.authStore && !!item.permission) continue;
                if (
                    !!item.permission &&
                    !this.authStore.canUserView(item.permission)
                )
                    continue;

                const isSelected = this.selectedFilterItems.includes(item);

                if (!isSelected) remainingItems.push(item);
            }

            if (remainingItems.length > 0)
                groups.push({ title: group.title, items: remainingItems });
        }

        return groups;
    }

    /**
     * Given a set of filters, returns an array of filter items
     * that had a corresponding value in the provided filters.
     * @param filters
     */
    getAppliedFilterItems(filters: ApplicationFiltersInstanceStore) {
        const filterItems = this.filterGroups.flatMap((group) => group.items);
        const selectedItems: IApplicationFilterItem[] = [];
        const applicationFiltersStore = RootStore().getStore(
            ApplicationFiltersStore,
        );

        for (const filterItem of filterItems) {
            // Date range and reference are always present
            if (
                filterItem.filterKey === "dateReferenceOption" ||
                filterItem.filterKey === "dateRange"
            ) {
                selectedItems.push(filterItem);
                continue;
            }
            if (
                filterItem.filterKey === "hierarchyIds" &&
                !!applicationFiltersStore.quickFiltersStore.hierarchyIds &&
                applicationFiltersStore.quickFiltersStore.hierarchyIds.length >
                    0
            ) {
                selectedItems.push(filterItem);
                continue;
            }

            if (!_.has(filters, filterItem.filterKey)) continue;

            const filterValue = _.get(filters, filterItem.filterKey);
            if (filterValue === undefined || filterValue === null) continue;
            if (Array.isArray(filterValue) && filterValue.length === 0)
                continue;
            if (typeof filterValue === "string" && filterValue === "") continue;

            if (
                filterItem.filterKey === "rbcFilterItems" &&
                (filterValue as IRBCFilter[])[0]?.classifierIds.length === 0
            )
                continue;
            if (
                filterItem.filterKey === "callDuration" &&
                (!filters.minCallDuration || filters.minCallDuration === 0) &&
                (!filters.maxCallDuration || filters.maxCallDuration === 0)
            )
                continue;
            selectedItems.push(filterItem);
        }

        return selectedItems;
    }

    @action
    updateSelectedFilterItems(filters: ApplicationFiltersInstanceStore) {
        this.selectedFilterItems = this.getAppliedFilterItems(filters);
    }

    @action
    private resetForOrgChange() {
        this.selectedFilterItems = [];
        this.filterGroups = _.cloneDeep(defaultFilterGroups);
    }

    @action
    public setSelectedFilterItems(filterItems: IApplicationFilterItem[]) {
        this.selectedFilterItems = filterItems;
    }

    @action
    public addSelectedFilterItem(filterItem: IApplicationFilterItem) {
        this.selectedFilterItems.push(filterItem);
    }

    @action
    public removeSelectedFilterItem(filterItem: IApplicationFilterItem) {
        const index = this.selectedFilterItems.findIndex(
            (item) =>
                item.title === filterItem.title &&
                item.filterKey === filterItem.filterKey,
        );

        if (index < 0) return;

        const newItems = [...this.selectedFilterItems];
        newItems.splice(index, 1);

        this.selectedFilterItems = newItems;
    }

    public getFilterGroup(
        groupTitle: FilterGroupTitle,
    ): ApplicationFilterGroup {
        const group = this.filterGroups.find((g) => g.title === groupTitle);

        if (!group) throw new Error("Invalid filter title");

        return group;
    }

    /**
     * Loads this organizations metadata labels for meta 1 - 5 as well as
     * available extended metadata fields and adds them to filterGroups
     * under the additional metadata group
     */
    @action
    public async loadMetadataLabels() {
        const organizationIdAtRequest = String(this.organizationId);

        const metaLabelsTask = this.setupAsyncTask(
            ApplicationFilterItemsStore.Tasks.LOAD_META_LABELS,
            async () => {
                const metaLabels = await this.metaLabelService.getMetaLabels();

                const additionalMetadataGroup = this.getFilterGroup(
                    filterGroupTitles.additionalMetadata,
                );

                if (organizationIdAtRequest !== this.organizationId) return;

                for (const [metaKey, metaLabel] of Object.entries(metaLabels)) {
                    const metaItem = additionalMetadataGroup.items.find(
                        (item) => item.filterKey === metaKey.toLowerCase(),
                    );
                    if (!metaItem) continue;

                    metaItem.title = metaLabel;
                }
            },
        );

        const availableMetaFieldsTask = this.setupAsyncTask(
            ApplicationFilterItemsStore.Tasks.LOAD_AVAILABLE_METADATA_FIELDS,
            async () => {
                // get meta fields for org
                const availableMetaFields =
                    await this.questionMetadataService.getMetadataFields();

                // check if call direction meta field is found
                const hasCallDirectionMetaField =
                    availableMetaFields.find(
                        (field) =>
                            field.item1 ===
                            OrganizationMetadataField.CallDirection.toString(),
                    ) !== null;

                // check if client call id meta field is found
                const hasClientCallIdMetaField =
                    availableMetaFields.find(
                        (field) =>
                            field.item1 ===
                            OrganizationMetadataField.ClientCallId.toString(),
                    ) !== null;

                // get the basic filters group, its where call dir and client
                // call id filter items live
                const basicFiltersGroup = this.getFilterGroup(
                    filterGroupTitles.basicFilters,
                );

                // if we dont have call direction meta field, remove from basic filters
                if (!hasCallDirectionMetaField)
                    basicFiltersGroup.items = basicFiltersGroup.items.filter(
                        (item) => item.filterKey !== "callDirections",
                    );
                // if we dont have client call id meta field, remove from basic filters
                if (!hasClientCallIdMetaField)
                    basicFiltersGroup.items = basicFiltersGroup.items.filter(
                        (item) => item.filterKey !== "clientCallId",
                    );

                const extendedMetadataFields = availableMetaFields.filter(
                    (field) => field.item2 === 1,
                );

                // Get additional meta group and add all extended meta fields
                const additionalMetadataGroup = this.getFilterGroup(
                    filterGroupTitles.additionalMetadata,
                );

                if (organizationIdAtRequest !== this.organizationId) return;

                for (const field of extendedMetadataFields) {
                    additionalMetadataGroup.items.push({
                        title: startCase(field.item1),
                        filterKey: `extendedMetadata.${field.item1}`,
                    });
                }
            },
        );

        const results = await Promise.all([
            metaLabelsTask,
            availableMetaFieldsTask,
        ]);

        const additionalMetadataGroup = this.getFilterGroup(
            filterGroupTitles.additionalMetadata,
        );

        additionalMetadataGroup.items.sort((a, b) =>
            a.title
                .toLocaleLowerCase()
                .localeCompare(b.title.toLocaleLowerCase()),
        );

        return results;
    }
}
