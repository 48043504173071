import React from "react";

interface AgentLogoProps {
    gradient?: boolean;
}

export function AgentLogo({
    gradient = false,
    ...props
}: Omit<React.ComponentPropsWithoutRef<"svg">, "xmlns" | "viewBox"> &
    AgentLogoProps) {
    return (
        <svg
            width="28"
            height="28"
            fill="none"
            {...props}
            viewBox="0 0 28 28"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="0.9"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.6359 7.71408L16.3391 7.20213C15.8857 6.51683 15.2184 5.9397 14.0929 5.9397C13.1717 5.92551 12.3149 6.40843 11.8528 7.20213L11.556 7.71408H16.6359ZM9.66058 10.9826L10.6895 9.20827H12.0425C12.5324 9.20827 12.9296 9.60547 12.9296 10.0954C12.9296 10.5854 12.5324 10.9826 12.0425 10.9826H9.66058ZM7.76519 14.2511L8.79412 12.4768H14.5639C15.0539 12.4768 15.4511 12.874 15.4511 13.364C15.4511 13.8539 15.0539 14.2511 14.5639 14.2511H7.76519ZM5.93924 17.5197C5.99506 17.3396 6.07222 17.166 6.16965 17.0026L6.89873 15.7453H17.5523C18.0423 15.7453 18.4395 16.1425 18.4395 16.6325C18.4395 17.1225 18.0423 17.5197 17.5523 17.5197H5.93924ZM12.3473 20.7879H8.36183C7.12248 20.7879 6.2824 19.9526 5.96898 19.0139H12.3226C12.8126 19.0139 13.2098 19.4111 13.2098 19.901C13.2098 20.3828 12.8259 20.7748 12.3473 20.7879ZM22.2197 19.0139C21.8849 19.9588 20.9831 20.7879 19.8321 20.7879H15.5665C15.0879 20.7748 14.704 20.3828 14.704 19.901C14.704 19.4111 15.1012 19.0139 15.5912 19.0139H22.2197ZM21.2926 15.7453L22.0216 17.0026C22.1238 17.1649 22.2039 17.3388 22.2606 17.5197H20.8208C20.3308 17.5197 19.9336 17.1225 19.9336 16.6325C19.9336 16.1425 20.3308 15.7453 20.8208 15.7453H21.2926ZM19.3974 12.4768L20.4263 14.2511H17.8324C17.3425 14.2511 16.9453 13.8539 16.9453 13.364C16.9453 12.874 17.3425 12.4768 17.8324 12.4768H19.3974ZM17.5023 9.20827L18.5311 10.9826H15.311C14.821 10.9826 14.4238 10.5854 14.4238 10.0954C14.4238 9.60547 14.821 9.20827 15.311 9.20827H17.5023Z"
                fill={
                    gradient
                        ? "url(#paint0_linear_15057_42474)"
                        : "currentColor"
                }
            />
            {gradient && (
                <defs>
                    <linearGradient
                        id="paint0_linear_15057_42474"
                        x1="0.830509"
                        y1="1.10698"
                        x2="29.7275"
                        y2="26.3149"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#32D1B7" />
                        <stop offset="0.695" stopColor="#349BC7" />
                        <stop offset="1.0001" stopColor="#3664D6" />
                    </linearGradient>
                </defs>
            )}
        </svg>
    );
}
