import EditIcon from "@mui/icons-material/Edit";
import LinkIcon from "@mui/icons-material/Link";
import { GridRowModel } from "@mui/x-data-grid-pro";
import AcxDataGrid from "components/UI/AcxDataGrid/AcxDataGrid";
import AcxDataGridStore from "components/UI/AcxDataGrid/AcxDataGridStore";
import AcxMainTextField from "components/UI/AcxMainTextFieldGrid";
import { AcxMenuItemProps, StyledMenuLabel } from "components/UI/Menu/AcxMenu";
import { delay } from "lodash";
import { observer } from "mobx-react";
import React from "react";
import { useNavigate } from "react-router-dom";

// const styles = (theme: Theme) => createStyles({});

function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
}

const statusAr: string[] = ["Good", "Bad", "Ok"];
const bigArray = (cnt: number) => {
    const rowAr: GridRowModel[] = [];
    for (let i = 0; i < cnt; i++) {
        rowAr.push({
            id: i,
            name: `user: ${i}`,
            description: `Description of row ${i} to test that the truncation works properly with whole words and an ellipsis, so it should handle punctuation too.`,
            tags: "tag1,,tag2",
            status: statusAr[getRandomInt(3)],
            progress: i,
        });
    }
    return rowAr;
};
const AcxGridTest = observer(() => {
    const navigate = useNavigate();
    const controls = [
        <AcxMainTextField
            id="test-controls-text-1"
            labelText="Text Field Grid 1"
        />,
        <AcxMainTextField
            id="test-controls-text-2"
            labelText="Text Field Grid 2"
        />,
    ];

    const vertMenu: AcxMenuItemProps[] = [
        {
            id: `Reportid-go-to-link`,
            label: <StyledMenuLabel>Go To Report</StyledMenuLabel>,
            icon: <LinkIcon />,
            props: {
                onClick: () => {
                    setTimeout(() => {
                        navigate(`/app `);
                    }, 75);
                },
            },
        },
        {
            id: `Menu-Action-open-widget-config-drawer`,
            label: <StyledMenuLabel>Edit Widget</StyledMenuLabel>,
            icon: <EditIcon />,
            props: {
                onClick: () => {
                    alert("Widget action thing");
                },
            },
        },
    ];

    const dgStore = new AcxDataGridStore();
    const dgStoreDeuce = new AcxDataGridStore();
    dgStoreDeuce.density = "compact";
    dgStoreDeuce.controls = controls;
    React.useEffect(() => {
        dgStore.vertIconMenuItems = vertMenu;
        dgStore.setColumns([
            { field: "id", type: "number", headerName: "Id", width: 150 },
            { field: "name", type: "string", headerName: "Name", width: 150 },
            {
                field: "description",
                type: "string",
                renderType: "longString",
                flex: 1,
                headerName: "Description",
            },
        ]);
        dgStore.rows = bigArray(500);
        dgStore.density = "compact";
        dgStore.controls = controls;
        delay(() => {
            dgStore.setSelectById(["1"]);
        }, 4000);
        dgStoreDeuce.setColumns([
            { field: "id", type: "number", headerName: "Id", width: 150 },
            { field: "name", type: "string", headerName: "Name", width: 150 },
            {
                field: "description",
                type: "string",
                renderType: "longString",
                flex: 1,
                headerName: "Description",
            },
        ]);
        delay(() => {
            dgStoreDeuce.rows = bigArray(1000);
        }, 5000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div style={{ height: "200px" }}>
                <AcxDataGrid dataGridStore={dgStore} />
            </div>
            <div style={{ height: "300px" }}>
                <AcxDataGrid dataGridStore={dgStoreDeuce} />
            </div>
        </>
    );
});

export default AcxGridTest;
