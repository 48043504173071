import { Grid, LinearProgress, Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import Gutters from "Layouts/Responsive/Gutters";
import { observer } from "mobx-react";
import React from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { useStore } from "utils/useStore";
import useStyles from "../../../Styles/Styles";
import { useResizeObserver } from "../../../utils/useResizeObserver";
import ClassifierReportFilters from "../../Reports/Common/ReportFilters";
import ClassifierChipSelector from "./ClassifierChipSelector";
import ClassifierReportSearchResultsDialog from "./ClassifierReportSearchResultsDialog";
import ClassifierReportStore, {
    loadOverviewStatsTask,
    LoadPublishedClassifiersTask,
} from "./ClassifierReportStore";
import ClassifierReportOverview from "./Overview/ClassifierReportOverview";
import ReportCardList from "./Reports/ReportCardList";

const styles = (theme: Theme) => {
    return createStyles({
        reportSection: {
            paddingBottom: theme.spacing(4),
        },
        spinnerContainer: {
            height: theme.spacing(1),
        },
        filterSection: {
            marginBottom: theme.spacing(0.75),
            paddingBottom: theme.spacing(0.25),
        },
    });
};

const ClassifierReport = observer(() => {
    const classes = useStyles(styles);
    const store = useStore(ClassifierReportStore);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { elementHeight, elementWidth, theRef } =
        useResizeObserver<HTMLDivElement>({
            debounceWait: 33,
        });

    return (
        <div style={{ height: "calc(100% - 0px)", overflowY: "auto" }}>
            <div className={classes.spinnerContainer}>
                {(store.getTaskLoading(loadOverviewStatsTask) ||
                    store.getTaskLoading(LoadPublishedClassifiersTask)) && (
                    <LinearProgress />
                )}
            </div>

            <Gutters height={"calc(100% - 16px)"} tinyGutter>
                <Grid
                    container
                    justifyContent={"center"}
                    className={classes.filterSection}
                    ref={theRef}
                >
                    <ClassifierReportFilters
                        orgSelectStore={store.orgSelectStore}
                        datePickerStore={store.datePickerStore}
                    />

                    <ClassifierReportOverview />

                    <ClassifierChipSelector store={store} />
                </Grid>

                <Grid
                    container
                    className={classes.reportSection}
                    style={{
                        height: `calc(100% - ${(elementHeight ?? 0) + 16}px)`,
                    }}
                >
                    <Scrollbars autoHide>
                        <ReportCardList store={store} />
                    </Scrollbars>
                </Grid>
            </Gutters>

            <ClassifierReportSearchResultsDialog />
        </div>
    );
});

export default ClassifierReport;
