import { IconButton, Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import clsx from "clsx";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { BarLoader } from "react-spinners";
import MessageStoreBase from "stores/RealTimeMessages/MessageStoreBase";
import { default as hexToRGB, default as hextToRGB } from "utils/hexToRGB";
import { useStore } from "utils/useStore";
import { LayoutDrawerStore } from "../../stores/Layout/LayoutDrawerStore";
import theme from "../../Theme/AppTheme";
import NotificationDrawerContent from "./Views/NotificationDrawerContent";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        "&:hover": {
            backgroundColor: theme.palette.white.main,
            color: hexToRGB(theme.palette.secondary.main, 0.25),
        },
    },
    notificationButton: (props: Props) => ({
        backgroundColor:
            props.inactiveButtonBackground ??
            hextToRGB(theme.palette.black.main, 0.05),
    }),
    notificationButtonActive: (props: Props) => ({
        backgroundColor:
            props.activeButtonBackground ??
            hextToRGB(theme.palette.secondary.main, 0.1),
    }),
    icon: (props: Props) => ({
        color:
            props.inactiveIconColor ??
            hextToRGB(theme.palette.black.main, 0.25),
    }),
    activeIcon: (props: Props) => ({
        color:
            props.activeIconColor ??
            hextToRGB(theme.palette.secondary.main, 0.75),
    }),
}));
interface NotificationMenuIconProps {
    activeButtonBackground?: string;
    inactiveButtonBackground?: string;
    activeIconColor?: string;
    inactiveIconColor?: string;
    loaderColor?: string;

    drawerAnchor?: "left" | "right" | "top" | "bottom";
    drawerVariant?: "permanent" | "persistent" | "temporary";
    drawerOffsetPx?: number;
    drawerOffsetPosition?: "Left" | "Top" | "Right" | "Bottom";
}

type Props = NotificationMenuIconProps;

const NotificationMenuIcon = observer((props: Props) => {
    const notificationStore = useStore(MessageStoreBase);
    const layoutDrawerStore = useStore(LayoutDrawerStore);

    const classes = useStyles(props);

    useEffect(() => {
        layoutDrawerStore.setAnchor(props.drawerAnchor ?? "right");
        layoutDrawerStore.setCollapseSize("full");
        layoutDrawerStore.setVariant(props.drawerVariant ?? "permanent");
        layoutDrawerStore.setOffsetPixels(props.drawerOffsetPx ?? 1);
        layoutDrawerStore.setOffsetPosition(
            props.drawerOffsetPosition ?? "Top",
        );
    }, [
        props.drawerOffsetPosition,
        props.drawerOffsetPx,
        props.drawerVariant,
        props.drawerAnchor,
        layoutDrawerStore,
    ]);

    function handleUserClick(event: React.MouseEvent<HTMLButtonElement>) {
        if (layoutDrawerStore.isOpen) {
            layoutDrawerStore.closeDrawer();
            layoutDrawerStore.reset();
        } else {
            layoutDrawerStore.reset();
            layoutDrawerStore.setOffsetPixels(1);
            layoutDrawerStore.setCustomRootStyles({ zIndex: 1202 });
            layoutDrawerStore.setAnchor(props.drawerAnchor ?? "right");
            layoutDrawerStore.setCollapseSize("full");
            layoutDrawerStore.setVariant(props.drawerVariant ?? "permanent");
            layoutDrawerStore.setOffsetPosition(
                props.drawerOffsetPosition ?? "Top",
            );

            layoutDrawerStore.setContentFactory(() => (
                <NotificationDrawerContent
                    onClose={layoutDrawerStore.closeDrawer}
                />
            ));
            layoutDrawerStore.setOpen(true);
        }
    }

    return (
        <div style={{ position: "relative" }}>
            <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={"profile-menu"}
                aria-haspopup="true"
                classes={{ root: classes.root }}
                onClick={handleUserClick}
                size="small"
                className={clsx(
                    notificationStore.notifications.length === 0 &&
                        classes.notificationButton,
                    notificationStore.notifications.length > 0 &&
                        classes.notificationButtonActive,
                )}
            >
                {notificationStore.notifications.length > 0 ? (
                    <NotificationsActiveIcon className={classes.activeIcon} />
                ) : (
                    <NotificationsIcon className={classes.icon} />
                )}
            </IconButton>
            {notificationStore.anyRunningOperations && (
                <div style={{ position: "absolute" }}>
                    <BarLoader
                        color={
                            props.loaderColor ?? theme.palette.secondary.light
                        }
                        height={3}
                        width={28}
                        loading={true}
                    />
                </div>
            )}
        </div>
    );
});

export default NotificationMenuIcon;
