import { observer } from "mobx-react";
import { IAppliedFilterChipComponent } from "../Chips/IAppliedFilterChipComponent";
import AppliedFilterChipBase from "../Chips/AppliedFilterChipBase";
import { useStore } from "utils/useStore";
import { ApplicationFiltersStore } from "stores/ApplicationFilters/ApplicationFiltersStore";
import React from "react";
import { EvaluationType } from "stores/ApplicationFilters/Filters/EvaluationType";

export const EvaluationTypesChips = observer(function (
    props: IAppliedFilterChipComponent,
) {
    const applicationFiltersStore = useStore(ApplicationFiltersStore);

    return applicationFiltersStore.evaluationTypes.map((evaluationType) => (
        <AppliedFilterChipBase
            isApplicable={props.isApplicable}
            onRemove={() =>
                applicationFiltersStore.toggleEvaluationType(evaluationType)
            }
        >
            {EvaluationType.toString(evaluationType)}
        </AppliedFilterChipBase>
    ));
});
