import React from "react";
import TranscriptionVisualView from "./TranscriptionVisualView";

type Props = {
    transcriptionId: string;
};

export default function TranscriptionVisualController(props: Props) {
    return <TranscriptionVisualView transcriptionId={props.transcriptionId} />;
}
