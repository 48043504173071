import { Grid, Menu, MenuItem, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import Classifier from "../../../models/ClassifierModel";
import useStyles from "../../../Styles/Styles";
import AcxChip from "../../UI/AcxChip";
import ClassifierReportStore from "./ClassifierReportStore";

const styles = (theme: Theme) => {
    return createStyles({
        chipSection: {
            marginTop: theme.spacing(1),
        },
        chipTextHeader: {
            marginBottom: theme.spacing(1),
            fontWeight: "bold",
        },
    });
};

interface OwnProps {
    store: ClassifierReportStore;
}

type Props = OwnProps;

const ClassifierChipSelector: FunctionComponent<
    React.PropsWithChildren<React.PropsWithChildren<Props>>
> = observer((props) => {
    const classes = useStyles(styles);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (classifier: Classifier) => {
        props.store.addClassifierToReportList(classifier);
        handleClose();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            {props.store.overviewStats && (
                <Grid item xs={12} className={classes.chipSection}>
                    <Typography
                        variant={"h3"}
                        className={classes.chipTextHeader}
                    >
                        Add Classifiers:
                    </Typography>

                    {props.store.selectedClassifiers.map((value) => (
                        <AcxChip
                            key={value.id}
                            label={value.name}
                            color="blue"
                            size="small"
                            clickable={false}
                            disabled={props.store.getTaskLoading(value.id)}
                            onDelete={() =>
                                props.store.removeClassifierReportCard(value.id)
                            }
                        />
                    ))}

                    <AcxChip
                        label="Add Classifier"
                        color="gray"
                        size="small"
                        icon="plus"
                        clickable={true}
                        disabled={
                            !props.store.publishedClassifiers.filter(
                                (value) =>
                                    !props.store.selectedClassifiers.some(
                                        (value1) => value1.id === value.id,
                                    ),
                            ).length
                        }
                        onClick={handleClick}
                    />

                    <Menu
                        id="published-classifiers-menu"
                        keepMounted
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {props.store.publishedClassifiers
                            .filter(
                                (value) =>
                                    !props.store.selectedClassifiers.some(
                                        (value1) => value1.id === value.id,
                                    ),
                            )
                            .map((value) => (
                                <MenuItem
                                    key={value.id}
                                    onClick={() => handleMenuItemClick(value)}
                                >
                                    {value.name}
                                </MenuItem>
                            ))}
                    </Menu>
                </Grid>
            )}
        </>
    );
});

export default ClassifierChipSelector;
