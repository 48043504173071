import { Divider, styled } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useStore } from "utils/useStore";
import { EvalReviewStore } from "./Stores/EvalReviewStore";
import ReviewDetails from "./Views/ReviewDetails";
import { AgentDashboardStore } from "../Dashboard/Stores/AgentDashboardStore";
import AcxButton from "components/UI/AcxButton";
import { Link } from "react-router-dom";
import { Routes } from "components/Navigation/Routes";
import { customSecondaryButtonStyle } from "utils/Style";
import EvalReviewCard from "./Views/EvalReviewCard";
import { AuthStore } from "stores/AuthStore";

interface ScoreCardData {
    title: string;
    score: string;
}

const formatAgentName = (encodedName: string): string => {
    return decodeURIComponent(encodedName)
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
};

function formatScore(score: number) {
    return `${score.toFixed(1)}%`;
}

interface SectionProps {
    title: string;
}

function Section({
    title,
    children,
    ...props
}: React.PropsWithChildren<
    SectionProps & React.ComponentPropsWithoutRef<typeof Grid>
>) {
    return (
        <Grid
            container
            item
            direction="column"
            flexWrap="nowrap"
            sx={(theme) => ({
                backgroundColor: theme.palette.white.main,
                borderRadius: theme.spacing(1),
                boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.07)",
                height: "fit-content",
            })}
        >
            <Grid item padding={2}>
                <Typography
                    sx={(theme) => ({
                        fontSize: "16px",
                        fontWeight: 700,
                        lineHeight: "20px",
                        color: theme.palette.gray[600],
                    })}
                >
                    {title}
                </Typography>
            </Grid>
            <Grid item>
                <Divider />
            </Grid>
            <Grid item padding={2} {...props}>
                {children}
            </Grid>
        </Grid>
    );
}

const StyledLink = styled(Link)(({ theme }) => ({
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: 600,
    color: theme.palette.gray[600],
}));

const EvalReview: React.FC = observer((props) => {
    const store = useStore(EvalReviewStore);
    const agentStore = useStore(AgentDashboardStore);
    const authStore = useStore(AuthStore);
    const navigate = useNavigate();
    const [params] = useSearchParams();

    useEffect(() => {
        if (
            !params.has("agentId") ||
            !params.has("agentName") ||
            !params.has("evaluationId") ||
            !params.has("evaluationNumber")
        )
            return;

        const agentId = params.get("agentId")!;
        const agentName = params.get("agentName")!;
        const evaluationId = params.get("evaluationId")!;
        const evaluationNumber = params.get("evaluationNumber")!;

        store.loadSingleEvalAndInitializeStore(
            agentId,
            agentName,
            evaluationId,
            evaluationNumber,
        );
    }, [params, store]);

    const cards: ScoreCardData[] = useMemo(() => {
        if (!store.moduleScoresForEvaluations) return [];

        const cardDatas: ScoreCardData[] = [];

        for (const module of store.moduleScoresForEvaluations) {
            if (!module) continue;

            const score = module.avgScore * 100;

            cardDatas.push({
                title: `Average ${module.moduleName} Score`,
                score: formatScore(score),
            });
        }

        return cardDatas;
    }, [store.moduleScoresForEvaluations]);

    const goBack = useCallback(() => {
        const backUrl = store.backUrl;
        if (backUrl === -1) navigate(-1);
        else navigate(backUrl as string);
    }, [store.backUrl, navigate]);

    // // for the analyst
    async function onSave() {
        let isSuccess = false;
        if (store.isEditing) isSuccess = await store.updateAgentCoaching(true);
        else isSuccess = await store.createAgentCoaching(false);

        if (isSuccess) goBack();
    }

    async function onSaveAs() {
        let isSuccess = false;
        if (store.isEditing) isSuccess = await store.updateAgentCoaching(false);
        else isSuccess = await store.createAgentCoaching(true);

        if (isSuccess) goBack();
    }

    // for the agent to respond
    async function onUpdate() {
        const isSuccess = await store.saveAgentResponse();
        if (isSuccess) goBack();
    }

    function onCancel() {
        goBack();
        store.reset();
    }

    return (
        <Grid
            container
            direction="column"
            rowGap={3}
            flexWrap="nowrap"
            padding={5}
        >
            <Grid
                container
                item
                justifyContent="space-between"
                flexWrap="nowrap"
            >
                <Grid item minWidth="fit-content">
                    <Typography
                        sx={(theme) => ({
                            color: theme.palette.gray[700],
                            fontSize: "24px",
                            fontWeight: 600,
                            lineHeight: "32px",
                        })}
                    >
                        {formatAgentName(agentStore.agentName)}
                    </Typography>
                </Grid>
                <Grid container item columnGap={1} justifyContent="flex-end">
                    <Grid item>
                        <AcxButton
                            leftRightSpacing={0}
                            fullWidth={false}
                            onClick={onCancel}
                            variant="text"
                            sx={(theme) => ({
                                color: theme.palette.primary.main,
                                ":hover": {
                                    backgroundColor: theme.palette.white.main,
                                    opacity: "75%",
                                },
                            })}
                        >
                            Cancel
                        </AcxButton>
                    </Grid>
                    {!store.isCoached && (
                        <Grid item>
                            <AcxButton
                                leftRightSpacing={0}
                                fullWidth={false}
                                tooltip="Save progress on this review"
                                onClick={onSaveAs}
                                customRootStyles={customSecondaryButtonStyle}
                            >
                                Save as Draft
                            </AcxButton>
                        </Grid>
                    )}
                    <Grid item>
                        <AcxButton
                            leftRightSpacing={0}
                            fullWidth={false}
                            tooltip={
                                store.isCoached
                                    ? "Submit acknowledgement"
                                    : "Submit review, cannot be edited later"
                            }
                            onClick={store.isCoached ? onUpdate : onSave}
                        >
                            Complete
                        </AcxButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                container
                item
                display="grid"
                gridTemplateColumns="1fr 1fr 1fr"
                columnGap={3}
                rowGap={2}
            >
                {cards.map((card) => (
                    <EvalReviewCard
                        key={card.title}
                        title={card.title}
                        score={card.score}
                    />
                ))}
            </Grid>
            <Grid
                container
                item
                display="grid"
                gridTemplateColumns="1fr 3fr"
                columnGap={3}
            >
                <Section
                    title="Review Evaluations"
                    container
                    item
                    direction="column"
                    rowGap={1}
                    flexWrap="nowrap"
                >
                    {store.targetedEvaluations &&
                        store.targetedEvaluations.map((evaluation) => (
                            <Grid
                                container
                                item
                                justifyContent="space-between"
                                flexWrap="nowrap"
                                padding={1}
                                sx={(theme) => ({
                                    border: "1px solid",
                                    borderColor: theme.palette.gray[200],
                                    borderRadius: theme.spacing(0.5),
                                })}
                            >
                                <Grid item>
                                    <StyledLink
                                        to={Routes.makeEvaluationRoute(
                                            authStore.orgStore
                                                .selectedOrganization?.id!,
                                            evaluation.id,
                                        )}
                                    >
                                        Evaluation #{evaluation.evaluationQbId}
                                    </StyledLink>
                                </Grid>
                                {/* call duration | message count goes here */}
                                {/* <Grid item></Grid> */}
                            </Grid>
                        ))}
                </Section>
                <Section title="Coaching Notes" padding={0}>
                    <ReviewDetails isCoached={store.isCoached} />
                </Section>
            </Grid>
        </Grid>
    );
});

export default EvalReview;
