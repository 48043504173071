import { observer } from "mobx-react";
import { IAppliedFilterChipComponent } from "../Chips/IAppliedFilterChipComponent";
import AppliedFilterChip from "../Chips/AppliedFilterChipBase";
import { useStore } from "utils/useStore";
import { ApplicationFiltersStore } from "stores/ApplicationFilters/ApplicationFiltersStore";
import React from "react";
import _ from "lodash";
import { Sentiment } from "stores/ApplicationFilters/Filters/Sentiment";

function isSentiment(value: unknown): value is Sentiment {
    return (
        value === Sentiment.Positive ||
        value === Sentiment.Neutral ||
        value === Sentiment.Negative
    );
}

export const SentimentChip = observer(function (
    props: IAppliedFilterChipComponent,
) {
    const applicationFiltersStore = useStore(ApplicationFiltersStore);

    const value = _.get(applicationFiltersStore, props.item.filterKey);
    if (value === undefined || value === null) return null;
    if (!isSentiment(value)) {
        console.error("Invalid sentiment value provided to sentiment chip");
        return null;
    }

    return (
        <AppliedFilterChip
            isApplicable={props.isApplicable}
            onRemove={() =>
                // @ts-expect-error
                applicationFiltersStore.resetByKey(props.item.filterKey)
            }
        >
            {props.item.title}: {Sentiment.toString(value)}
        </AppliedFilterChip>
    );
});
