import { Grid, Theme, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx";
import { observer } from "mobx-react";
import React from "react";
import {
    ReportDataField,
    ReportDataFieldVariation,
    ReportFieldDataTypes,
} from "../../../../../models/Reporting/ReportDataField";
import {
    AggregateFunction,
    DateAggregation,
    FieldUse,
    ReportField,
} from "../../../../../models/Reporting/ReportField";
import { useStore } from "../../../../../utils/useStore";
import AcxBooleanFormControlGroup, {
    AcxBooleanFormFieldControllers,
} from "../../../../UI/AcxBooleanFormControlGroup";
import AcxMainTextField from "../../../../UI/AcxMainTextFieldGrid";
import AcxSelectSingle from "../../../../UI/Select/BaseSelectComponents/AcxSelectSingle";
import { ReportEditorStore } from "../../../Stores/ReportEditorStore";

const useStyles = makeStyles((theme: Theme) => ({
    sectionTitle: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: "bold",
        letterSpacing: "0",
        lineHeight: "24px",
        paddingBottom: theme.spacing(1),
    },
    vertSpace: {
        marginTop: theme.spacing(3),
    },

    sectionSpace: {
        marginTop: theme.spacing(6),
    },
}));

export const dateGroupings = [
    { id: DateAggregation.DateId, value: "Date" },
    { id: DateAggregation.WeekOfYear, value: "Week" },
    { id: DateAggregation.MonthYear, value: "Month" },
    { id: DateAggregation.Quarter, value: "Quarter" },
];

export const aggregateFunctions = [
    { id: AggregateFunction.Avg, value: "Avg" },
    { id: AggregateFunction.Count, value: "Count" },
    { id: AggregateFunction.Sum, value: "Sum" },
    { id: AggregateFunction.Max, value: "Max" },
    { id: AggregateFunction.Min, value: "Min" },
];

export function aggregateFunctionsForReportField(reportField?: ReportField) {
    const fieldDataType = reportField?.reportDataField?.datatype; //?? translateFieldUseToDatatype(reportField);

    switch (fieldDataType) {
        case ReportFieldDataTypes.Date:
            return aggregateFunctions.filter(
                (value) =>
                    value.id === AggregateFunction.Count ||
                    value.id === AggregateFunction.Min ||
                    value.id === AggregateFunction.Max,
            );

        case ReportFieldDataTypes.Hierarchy:
        case ReportFieldDataTypes.Categorical:
        case ReportFieldDataTypes.Guid:
        case ReportFieldDataTypes.Text:
            return aggregateFunctions.filter(
                (value) => value.id === AggregateFunction.Count,
            );

        case ReportFieldDataTypes.Number:
        default:
            return aggregateFunctions;
    }
}

export function dateAggregationSelector(dateAggregation?: DateAggregation) {
    switch (dateAggregation) {
        case DateAggregation.DateId:
            return { value: "Date", id: dateAggregation };

        case DateAggregation.MonthYear:
            return { value: "Month", id: dateAggregation };

        case DateAggregation.Quarter:
            return { value: "Quarter", id: dateAggregation };

        case DateAggregation.WeekOfYear:
            return { value: "Week", id: dateAggregation };
        default:
            return undefined;
    }
}

export function aggregateFuncSelector(aggregateFunction?: AggregateFunction) {
    switch (aggregateFunction) {
        case AggregateFunction.Avg:
            return { value: "Avg", id: aggregateFunction };
        case AggregateFunction.Count:
            return { value: "Count", id: aggregateFunction };
        case AggregateFunction.Max:
            return { value: "Max", id: aggregateFunction };
        case AggregateFunction.Min:
            return { value: "Min", id: aggregateFunction };
        case AggregateFunction.Sum:
            return { value: "Sum", id: aggregateFunction };
        default:
            return undefined;
    }
}

function canBeDistinct(aggregateFunction?: AggregateFunction) {
    switch (aggregateFunction) {
        case AggregateFunction.Count:
        case AggregateFunction.Sum:
            return true;
        default:
            return false;
    }
}

interface OwnProps {}

type Props = OwnProps;

const VizDataSection: React.FC<Props> = observer((props) => {
    const classes = useStyles(props);
    const store = useStore(ReportEditorStore);

    const xField = store.currentReport?.activeVizXAxisField;
    const yField = store.currentReport?.activeVizYAxisField;
    const vizGroupField = store.currentReport?.activeVizGroupingField;

    function handleFieldAxisTitleChange(
        evt: React.ChangeEvent<HTMLInputElement>,
        fieldUse: FieldUse,
    ) {
        if (fieldUse === FieldUse.X) {
            const field =
                xField ?? store.currentReport?.addVizReportField(FieldUse.X);
            field?.setDisplayName(evt.currentTarget.value);
        } else {
            const field =
                yField ?? store.currentReport?.addVizReportField(FieldUse.Y);
            field?.setDisplayName(evt.currentTarget.value);
        }
    }

    function handleReportDataFieldChange(
        reportDataField: ReportDataField | undefined,
        fieldUse: FieldUse,
    ) {
        if (fieldUse === FieldUse.X) {
            if (reportDataField) {
                const field =
                    xField ??
                    store.currentReport?.addVizReportField(FieldUse.X);
                field?.resetField();
                // NOTE - important for virtual RDF to reportField matching
                field?.setName(reportDataField.displayName);
                field?.setDisplayName(reportDataField.displayName);

                if (
                    reportDataField.variation !==
                    ReportDataFieldVariation.Virtual
                ) {
                    field?.setReportDataField(reportDataField);
                }
            }
        } else if (fieldUse === FieldUse.Y) {
            if (reportDataField) {
                const field =
                    yField ??
                    store.currentReport?.addVizReportField(FieldUse.Y);
                field?.resetField();

                field?.setName(reportDataField.fieldName);
                field?.setDisplayName(reportDataField.displayName);

                if (
                    reportDataField.variation !==
                    ReportDataFieldVariation.Virtual
                ) {
                    field?.setReportDataField(reportDataField);
                }
            }
        } else if (fieldUse === FieldUse.VizGroup) {
            if (reportDataField) {
                const field =
                    vizGroupField ??
                    store.currentReport?.addVizReportField(FieldUse.VizGroup);
                field?.resetField();
                field?.setName(reportDataField.fieldName);
                field?.setDisplayName(reportDataField.displayName);

                if (
                    reportDataField.variation !==
                    ReportDataFieldVariation.Virtual
                ) {
                    field?.setReportDataField(reportDataField);
                }
            } else {
                const vizGroupField = store.currentReport?.reportFields.find(
                    (value) =>
                        value.isActive && value.fieldUse === FieldUse.VizGroup,
                );
                if (vizGroupField) {
                    store.currentReport?.removeReportField(vizGroupField);
                }
            }
        }
    }

    function handleXAxisDateAggregationChange(dateAgg?: {
        id: DateAggregation;
        value: string;
    }) {
        xField?.setDateAggregation(dateAgg?.id);
    }

    function handleVizGroupDateAggregationChange(dateAgg?: {
        id: DateAggregation;
        value: string;
    }) {
        vizGroupField?.setDateAggregation(dateAgg?.id);
    }

    function handleYAxisAggregationChange(agg?: {
        id: AggregateFunction;
        value: string;
    }) {
        yField?.setAggregateFunction(agg?.id);

        if (!canBeDistinct(agg?.id)) {
            yField?.setDistinct(false);
        }

        // NOTE This is a hard constraint that ReportDataFields that are distinct per answer; not per evaluation must have
        // their fieldName prefixed by "Answer" e.g. "AnswerSentiment", "AnswerNote", "AnswerAlert"
        // yField?.setDistinct(
        //     agg?.id === AggregateFunction.Count &&
        //         !yField?.reportDataField?.fieldName
        //             .toLowerCase()
        //             .startsWith("answer"),
        // );
    }

    return (
        <>
            <Typography className={classes.sectionTitle}>X Axis</Typography>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={4}>
                    <AcxMainTextField
                        id={"report-editor-x-axis-label"}
                        value={xField?.displayName}
                        disabled={!Boolean(store.currentReportDataView)}
                        labelText={"X Axis Label"}
                        onChange={(evt) =>
                            handleFieldAxisTitleChange(evt, FieldUse.X)
                        }
                    />
                </Grid>
                <Grid item xs={4}>
                    <AcxSelectSingle
                        options={store.availableReportAxisFields ?? []}
                        id={"report-editor-x-datafield"}
                        defaultValue={xField?.reportDataField}
                        valueField={"fieldName"}
                        labelField={"displayName"}
                        fullWidth
                        isDisabled={!Boolean(store.currentReportDataView)}
                        onChange={(arg0) =>
                            handleReportDataFieldChange(arg0, FieldUse.X)
                        }
                        inputLabel={"Data Field"}
                    />
                </Grid>

                {xField?.reportDataField?.datatype?.valueOf() ===
                    ReportFieldDataTypes.Date.valueOf() && (
                    <Grid item xs={4}>
                        <AcxSelectSingle
                            options={dateGroupings}
                            id={"report-editor-x-groupby"}
                            isDisabled={!Boolean(store.currentReportDataView)}
                            defaultValue={dateAggregationSelector(
                                xField?.dateAggregation,
                            )}
                            enableNoSelection
                            valueField={"id"}
                            labelField={"value"}
                            fullWidth
                            onChange={handleXAxisDateAggregationChange}
                            inputLabel={"Grouped By"}
                        />
                    </Grid>
                )}
            </Grid>

            <Typography
                className={clsx(classes.sectionTitle, classes.vertSpace)}
            >
                Y Axis
            </Typography>

            <Grid container item xs={12} spacing={2}>
                <Grid item xs={4}>
                    <AcxMainTextField
                        id={"report-editor-y-axis-label"}
                        value={yField?.displayName}
                        disabled={!Boolean(store.currentReportDataView)}
                        onChange={(evt) =>
                            handleFieldAxisTitleChange(evt, FieldUse.Y)
                        }
                        labelText={"Y Axis Label"}
                    />
                </Grid>
                <Grid item xs={4}>
                    <AcxSelectSingle
                        options={store.availableReportAxisFields ?? []}
                        isDisabled={!Boolean(store.currentReportDataView)}
                        id={"report-editor-y-datafield"}
                        defaultValue={yField?.reportDataField}
                        valueField={"fieldName"}
                        labelField={"displayName"}
                        fullWidth
                        onChange={(arg0) =>
                            handleReportDataFieldChange(arg0, FieldUse.Y)
                        }
                        inputLabel={"Data Field"}
                    />
                </Grid>

                {Boolean(yField) && (
                    <Grid item xs={4}>
                        <AcxSelectSingle
                            options={aggregateFunctionsForReportField(yField)}
                            isDisabled={!Boolean(store.currentReportDataView)}
                            id={"report-editor-y-datafield-aggregator"}
                            enableNoSelection
                            defaultValue={aggregateFuncSelector(
                                yField?.aggregateFunction,
                            )}
                            valueField={"id"}
                            labelField={"value"}
                            fullWidth
                            onChange={handleYAxisAggregationChange}
                            inputLabel={"Aggregated By"}
                        />
                        {canBeDistinct(yField?.aggregateFunction) && (
                            <AcxBooleanFormControlGroup
                                formControlModel={yField}
                                control={"checkbox"}
                                modelFieldControls={[
                                    {
                                        fieldName: "distinct",
                                        fieldSetter: "setDistinct",
                                        displayName: "Distinct",
                                        infoText:
                                            "Perform aggregation over distinct values",
                                    } as AcxBooleanFormFieldControllers<ReportField>,
                                ]}
                            />
                        )}
                    </Grid>
                )}
            </Grid>

            <Typography
                className={clsx(classes.sectionTitle, classes.vertSpace)}
            >
                Series
            </Typography>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={4}>
                    <AcxSelectSingle
                        options={store.availableReportAxisFields ?? []}
                        isDisabled={!Boolean(store.currentReportDataView)}
                        fullWidth
                        id={"report-data-series-selector"}
                        defaultValue={vizGroupField?.reportDataField}
                        valueField={"fieldName"}
                        labelField={"displayName"}
                        enableNoSelection
                        onChange={(arg0) =>
                            handleReportDataFieldChange(arg0, FieldUse.VizGroup)
                        }
                        inputLabel={"Series Grouping"}
                    />
                </Grid>
                {vizGroupField?.reportDataField?.datatype?.valueOf() ===
                    ReportFieldDataTypes.Date.valueOf() && (
                    <Grid item xs={4}>
                        <AcxSelectSingle
                            options={dateGroupings}
                            id={"report-editor-vizGroupDate-groupby"}
                            isDisabled={!Boolean(store.currentReportDataView)}
                            defaultValue={dateAggregationSelector(
                                vizGroupField?.dateAggregation,
                            )}
                            enableNoSelection
                            valueField={"id"}
                            labelField={"value"}
                            fullWidth
                            onChange={handleVizGroupDateAggregationChange}
                            inputLabel={"Grouped By"}
                        />
                    </Grid>
                )}
            </Grid>
        </>
    );
});

export default VizDataSection;
