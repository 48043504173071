import { Divider, Paper, Typography, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { observer } from "mobx-react";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Stack, Grid, IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import AcxInputLabel from "components/UI/AcxInputLabel";
import AcxTextField from "components/UI/AcxTextField";
import { standardDateTimeFormat } from "utils/DateTimeUtils";
import ClassifierCategory from "models/ClassifierCategoryId";
import AcxSelect from "components/UI/Select/BaseSelectComponents/AcxSelect";
import ClassifierGroup from "models/ClassifierGroup";
import AcxButton from "components/UI/AcxButton";
import { OpenInNew, Add } from "@mui/icons-material";
import AcxDialog from "components/UI/Dialog/AcxDialog";
import { useStore } from "utils/useStore";
import { ClassifierBuilderV2Store } from "../../Stores/ClassifierBuilderV2Store";
import theme from "Theme/AppTheme";
import { Edit, Delete } from "@mui/icons-material";

interface ManageGroupDialogProps {
    isOpen: boolean;
    onOpenChange(open: boolean): void;
    onSuccess?(group: ClassifierGroup): void;
    isEditDialog: boolean;
    defaultGroup?: ClassifierGroup;
}

function ManageGroupDialog(props: ManageGroupDialogProps) {
    const store = useStore(ClassifierBuilderV2Store);
    const [isSaveLoading, setIsSaveLoading] = useState(false);
    const [value, setValue] = useState(props.defaultGroup?.name ?? "");

    useEffect(() => {
        if (props.defaultGroup) setValue(props.defaultGroup.name);
    }, [props.defaultGroup]);

    return (
        <AcxDialog
            isOpen={props.isOpen}
            onClose={() => props.onOpenChange(false)}
            contentWidth="480px"
            title={props.isEditDialog ? "Edit Group" : "Create New Group"}
            dialogContentChildren={
                <>
                    <AcxInputLabel
                        htmlFor="group-name"
                        labelText="Group Name"
                    />
                    <AcxTextField
                        id="group-name"
                        label=""
                        type="input"
                        fullWidth
                        other={{ value }}
                        placeholder="Enter..."
                        onChange={(event) => setValue(event.target.value)}
                        error={false}
                    />
                </>
            }
        >
            <AcxButton
                color="secondary"
                fullWidth={false}
                leftRightSpacing={0}
                loading={isSaveLoading}
                buttonDisabled={value === ""}
                onClick={async () => {
                    setIsSaveLoading(true);

                    let group: ClassifierGroup | undefined;
                    if (props.isEditDialog && props.defaultGroup) {
                        group = await store.updateClssifierGroup(
                            props.defaultGroup.id,
                            value,
                        );
                    } else {
                        group = await store.createClassifierGroup(value);
                        setValue("");
                    }

                    setIsSaveLoading(false);
                    props.onOpenChange(false);
                    if (group) props.onSuccess?.(group);
                }}
            >
                Save
            </AcxButton>
        </AcxDialog>
    );
}

interface ManageGroupsDialogProps {
    isOpen: boolean;
    onOpenChange(open: boolean): void;
    onGroupUpdate?(group: ClassifierGroup): void;
    onGroupDelete?(group: ClassifierGroup): void;
}

function WarningIcon() {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.5142 6.1982C18.0442 3.4782 21.9582 3.4782 23.4862 6.1982L34.6462 26.0382C36.1462 28.7062 34.2202 31.9982 31.1622 31.9982H8.84019C5.78019 31.9982 3.85418 28.7062 5.35418 26.0382L16.5142 6.1982ZM22.0002 26.0002C22.0002 26.5306 21.7895 27.0393 21.4144 27.4144C21.0393 27.7895 20.5306 28.0002 20.0002 28.0002C19.4698 28.0002 18.961 27.7895 18.586 27.4144C18.2109 27.0393 18.0002 26.5306 18.0002 26.0002C18.0002 25.4698 18.2109 24.9611 18.586 24.586C18.961 24.2109 19.4698 24.0002 20.0002 24.0002C20.5306 24.0002 21.0393 24.2109 21.4144 24.586C21.7895 24.9611 22.0002 25.4698 22.0002 26.0002ZM20.0002 10.0002C19.4698 10.0002 18.961 10.2109 18.586 10.586C18.2109 10.9611 18.0002 11.4698 18.0002 12.0002V18.0002C18.0002 18.5306 18.2109 19.0393 18.586 19.4144C18.961 19.7895 19.4698 20.0002 20.0002 20.0002C20.5306 20.0002 21.0393 19.7895 21.4144 19.4144C21.7895 19.0393 22.0002 18.5306 22.0002 18.0002V12.0002C22.0002 11.4698 21.7895 10.9611 21.4144 10.586C21.0393 10.2109 20.5306 10.0002 20.0002 10.0002Z"
                fill="#EF4444"
            />
        </svg>
    );
}

function ManageGroupsDialog(props: ManageGroupsDialogProps) {
    const store = useStore(ClassifierBuilderV2Store);

    const [isCreateGroupDialogOpen, setIsCreateGroupDialogOpen] =
        useState(false);
    const [isEditGroupDialogOpen, setIsEditGroupDialogOpen] = useState(false);
    const [targetGroup, setTargetGroup] = useState<ClassifierGroup>();

    const [isDeleteGroupDialogOpen, setIsDeleteGroupDialogOpen] =
        useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);

    return (
        <>
            <AcxDialog
                isOpen={props.isOpen}
                onClose={() => props.onOpenChange(false)}
                title="Manage Groups"
                maxWidth="lg"
                contentWidth="728px"
                dialogContentChildren={
                    <Grid container direction="column" gap={1}>
                        {store.classifierGroups.map((group) => {
                            const isGroupInLibrary = store.classifiers.some(
                                (c) =>
                                    c.createdBy ===
                                        "AuthenticxClassifierLibrary" &&
                                    c.classifierGroup?.name === group.name,
                            );

                            return (
                                <Paper
                                    key={group.id}
                                    style={{
                                        borderRadius: "0.5rem",
                                        backgroundColor:
                                            theme.palette.lightgray.main,
                                    }}
                                >
                                    <Grid
                                        container
                                        item
                                        direction="row"
                                        justifyContent="space-between"
                                        padding={2}
                                        flexWrap="nowrap"
                                        alignItems="center"
                                    >
                                        <Grid item>
                                            <Typography
                                                style={{
                                                    fontSize: "16px",
                                                    color: theme.palette
                                                        .blackFont.main,
                                                }}
                                            >
                                                {group.name}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            direction="row"
                                            gap={1}
                                            width="fit-content"
                                            alignItems="center"
                                        >
                                            <IconButton
                                                size="small"
                                                color="secondary"
                                                onClick={() => {
                                                    setTargetGroup(group);
                                                    setIsEditGroupDialogOpen(
                                                        true,
                                                    );
                                                }}
                                            >
                                                <Edit />
                                            </IconButton>
                                            <Tooltip
                                                title={
                                                    isGroupInLibrary
                                                        ? "Groups with classifiers in the library cannot be deleted"
                                                        : "Delete group"
                                                }
                                            >
                                                <span>
                                                    <IconButton
                                                        size="small"
                                                        disabled={
                                                            isGroupInLibrary
                                                        }
                                                        onClick={() => {
                                                            setTargetGroup(
                                                                group,
                                                            );
                                                            setIsDeleteGroupDialogOpen(
                                                                true,
                                                            );
                                                        }}
                                                    >
                                                        <Delete />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            );
                        })}
                        <AcxButton
                            color="secondary"
                            startIcon={<Add />}
                            fullWidth={false}
                            leftRightSpacing={0}
                            onClick={() => setIsCreateGroupDialogOpen(true)}
                        >
                            New Group
                        </AcxButton>
                    </Grid>
                }
            ></AcxDialog>
            {targetGroup && (
                <>
                    <ManageGroupDialog
                        isOpen={isEditGroupDialogOpen}
                        onOpenChange={setIsEditGroupDialogOpen}
                        isEditDialog={true}
                        defaultGroup={targetGroup}
                        onSuccess={props.onGroupUpdate}
                    />
                    <AcxDialog
                        isOpen={isDeleteGroupDialogOpen}
                        onClose={() => setIsDeleteGroupDialogOpen(false)}
                        title={<WarningIcon />}
                        subTitle="All Classifiers will be Unassigned"
                        text="If you delete this group all the classifiers in the group will be unassigned. Please reassign these classifiers to a group."
                    >
                        <AcxButton
                            color="secondary"
                            fullWidth={false}
                            leftRightSpacing={0}
                            loading={isDeleteLoading}
                            onClick={async () => {
                                setIsDeleteLoading(true);

                                await store.deleteClassifierGroup(
                                    targetGroup.id,
                                );

                                setIsDeleteLoading(false);
                                setIsDeleteGroupDialogOpen(false);
                                props.onGroupDelete?.(targetGroup);
                            }}
                        >
                            Ok
                        </AcxButton>
                    </AcxDialog>
                </>
            )}
            <ManageGroupDialog
                isOpen={isCreateGroupDialogOpen}
                onOpenChange={setIsCreateGroupDialogOpen}
                isEditDialog={false}
            />
        </>
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    input: {
        padding: theme.spacing(1.8, 2.3),
    },
    fullWidth: {
        width: "100%",
    },
    inputName: {
        paddingRight: theme.spacing(2),
    },
    header: {
        fontSize: theme.typography.h2.fontSize,
        fontWeight: 600,
    },
    headerContainer: {
        paddingTop: theme.spacing(2.3),
        paddingBottom: theme.spacing(1.8),
        paddingLeft: theme.spacing(1.8),
        paddingRight: theme.spacing(1.8),
    },
    paddingBottom: {
        paddingBottom: theme.spacing(2.5),
    },
    lastUpdated: {
        color: "#9CA3AF",
        fontSize: "14px",
        fontWeight: 400,
    },
    container: {
        paddingBottom: theme.spacing(2.5),
    },
    selectButton: {
        backgroundColor: theme.palette.white.main,
        color: theme.palette.lightgray.dark,
        justifyContent: "start",
        alignItems: "center",
        paddingInline: theme.spacing(2.5),
        paddingBlock: theme.spacing(2.5),
        "&:hover": {
            backgroundColor: theme.palette.lightgray.light,
        },
    },
}));

interface Props {
    name: string;
    onNameChange: (e: any) => void;
    description: string;
    onDescriptionChange: (e: any) => void;
    category: ClassifierCategory | null;
    onCategoryChange: (e: any) => void;
    categoryOptions: ClassifierCategory[];
    group?: ClassifierGroup;
    onGroupChange: (e: any) => void;
    groupOptions: ClassifierGroup[];
    modifiedOn?: string;
}

const ClassifierBuilderDetails = observer(
    ({
        name,
        onNameChange,
        description,
        onDescriptionChange,
        category,
        onCategoryChange,
        categoryOptions,
        group,
        onGroupChange,
        groupOptions,
        modifiedOn,
    }: Props) => {
        const classes = useStyles();
        const [isCreateGroupDialogOpen, setIsCreateGroupDialogOpen] =
            useState(false);

        const [isManageGroupsDialogOpen, setIsManageGroupsDialogOpen] =
            useState(false);

        return (
            <Grid item className={classes.container}>
                <Paper>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        className={classes.headerContainer}
                    >
                        <Typography
                            sx={{
                                fontSize: theme.typography.h2.fontSize,
                                fontWeight: 600,
                            }}
                        >
                            Classifier Details
                        </Typography>
                        {modifiedOn && (
                            <Typography
                                sx={{
                                    color: "#9CA3AF",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                }}
                            >
                                {`Last Updated: ${standardDateTimeFormat(
                                    moment(modifiedOn),
                                )}`}
                            </Typography>
                        )}
                    </Stack>

                    <Divider />
                    <Grid container className={classes.input}>
                        <Grid
                            item
                            xs={6}
                            className={clsx(
                                classes.inputName,
                                classes.paddingBottom,
                            )}
                        >
                            <AcxInputLabel
                                htmlFor="classifier-name"
                                labelText="Classifier Name"
                                required
                            />
                            <AcxTextField
                                id="classifier-name"
                                label={""}
                                required
                                type="text"
                                other={{
                                    className: classes.fullWidth,
                                    value: name,
                                }}
                                onChange={onNameChange}
                                error={false}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={3}
                            className={clsx(
                                classes.inputName,
                                classes.paddingBottom,
                            )}
                        >
                            <AcxInputLabel
                                htmlFor="classifier-category"
                                labelText="Classifier Category"
                            />
                            <AcxSelect
                                id="classifier-category"
                                valueField="name"
                                labelField="name"
                                options={categoryOptions}
                                onChange={onCategoryChange}
                                defaultValue={category}
                                enableNoSelection
                                fullWidth
                                customStyle={{
                                    container: (provided) => ({
                                        ...provided,
                                        marginTop: "11.5px",
                                        border: "1px rgba(0, 0, 0, 0.87) solid",
                                        borderRadius: "3px",
                                        height: "36px",
                                    }),
                                    singleValue: (provided) => ({
                                        ...provided,
                                        fontSize: "13px",
                                    }),
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={3}
                            className={clsx(
                                classes.inputName,
                                classes.paddingBottom,
                            )}
                        >
                            <AcxInputLabel
                                htmlFor="classifier-group"
                                labelText="Classifier Group"
                            />
                            <AcxSelect
                                id="classifier-group"
                                valueField="name"
                                labelField="name"
                                options={groupOptions}
                                onChange={onGroupChange}
                                defaultValue={group}
                                enableNoSelection
                                fullWidth
                                customStyle={{
                                    container: (provided) => ({
                                        ...provided,
                                        marginTop: "11.5px",
                                        border: "1px rgba(0, 0, 0, 0.87) solid",
                                        borderRadius: "3px",
                                        height: "36px",
                                    }),
                                    singleValue: (provided) => ({
                                        ...provided,
                                        fontSize: "13px",
                                    }),
                                }}
                                dropdownMenuEndChildren={
                                    <Grid container direction="column" gap={0}>
                                        <Grid item>
                                            <AcxButton
                                                color="default"
                                                buttonClass={
                                                    classes.selectButton
                                                }
                                                startIcon={<Add />}
                                                fullWidth
                                                leftRightSpacing={0}
                                                onClick={() =>
                                                    setIsCreateGroupDialogOpen(
                                                        true,
                                                    )
                                                }
                                            >
                                                Create New Group
                                            </AcxButton>
                                        </Grid>
                                        <Grid item>
                                            <AcxButton
                                                color="default"
                                                buttonClass={
                                                    classes.selectButton
                                                }
                                                startIcon={<OpenInNew />}
                                                fullWidth
                                                leftRightSpacing={0}
                                                onClick={() =>
                                                    setIsManageGroupsDialogOpen(
                                                        true,
                                                    )
                                                }
                                            >
                                                Manage Groups
                                            </AcxButton>
                                        </Grid>
                                    </Grid>
                                }
                            />
                            <ManageGroupDialog
                                isOpen={isCreateGroupDialogOpen}
                                onOpenChange={setIsCreateGroupDialogOpen}
                                isEditDialog={false}
                                onSuccess={(group) => onGroupChange(group)}
                            />
                            <ManageGroupsDialog
                                isOpen={isManageGroupsDialogOpen}
                                onOpenChange={setIsManageGroupsDialogOpen}
                                onGroupUpdate={(updatedGroup) => {
                                    // currently selected group was updated
                                    if (group && updatedGroup.id === group.id)
                                        onGroupChange(updatedGroup);
                                }}
                                onGroupDelete={(deletedGroup) => {
                                    // currently selected group was deleted
                                    if (
                                        group !== undefined &&
                                        deletedGroup.id === group.id
                                    )
                                        onGroupChange({ name: "No Selection" });
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.paddingBottom}>
                            <AcxInputLabel
                                htmlFor="classifier-description"
                                labelText="Description"
                            />
                            <AcxTextField
                                id="classifier-description"
                                label={""}
                                type="text"
                                other={{
                                    value: description,
                                    className: classes.fullWidth,
                                }}
                                onChange={onDescriptionChange}
                                error={false}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        );
    },
);

export default ClassifierBuilderDetails;
