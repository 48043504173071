import {
    Grid,
    IconButton,
    LinearProgress,
    Theme,
    Tooltip,
    Typography,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import { ViewColumn } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import {
    DataGridPro,
    GridApi,
    GridOverlay,
    useGridApiRef,
} from "@mui/x-data-grid-pro";
import { delay, isFunction } from "lodash";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import useStyles from "Styles/Styles";
import hexToRGB from "utils/hexToRGB";
import { isStringType, isType } from "utils/TypeGuards";
import AcxMenu, { AcxMenuItemProps, StyledMenuLabel } from "../Menu/AcxMenu";
import AcxDataGridStore, { CustomControlItem } from "./AcxDataGridStore";
import AcxGridSelection from "./AcxGridSelection";
import FilterIcon from "./FilterOverride/FilterIcon";
import FilterPanel from "./FilterV4/FilterPanel";
import NoRowsOverlay from "./GridComponents/NoRowsOverlay";
import HideColumn from "./HideColumnOverride/HideColumn";
import VertIcon from "./VertIcon";

const GRID_COLUMN_ORDER_CHANGE = "columnOrderChange";
const GRID_COLUMN_RESIZE_STOP = "columnResizeStop";
const styles = (theme: Theme) =>
    createStyles({
        tableContainer: {
            boxShadow:
                "0 0 3px 0 rgba(0, 0, 0, 0.05), 0 1px 1px 0 rgba(0, 0, 0, 0.05)",
            overscrollBehaviorX: "contain",
            overscrollBehaviorY: "auto",
            backgroundColor: theme.palette.white.main,
        },
        controlSection: {
            marginTop: theme.spacing(1.5),
            marginBottom: theme.spacing(0.5),
        },
        root: {
            "& .MuiDataGrid-root": {
                borderRadius: "5px",
            },
            "& .MuiDataGrid-columnHeader": {
                paddingLeft: "4px",
                paddingRight: "4px",
            },
            "& .MuiDataGrid-columnHeaderTitleContainer": {
                paddingLeft: "2px",
                paddingRight: "2px",
            },
            "& .acx-app-theme-header": {
                backgroundColor: "rgba(255, 7, 0, 0.55)",
                borderRadius: "5px 5px 0px 0px",
            },
            "& .MuiDataGrid-columnsContainer": {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.white.main,
                fontSize: "13px",
            },
            "& .MuiDataGrid-colCell": {
                paddingLeft: "8px",
                paddingRight: "8px",
            },
            "& .MuiDataGrid-cell": {
                paddingLeft: "8px",
                paddingRight: "8px",
            },
            "& .MuiDataGrid-row": {
                fontSize: "13px",
                "&:hover": {
                    backgroundColor: hexToRGB(theme.palette.gray.main, 0.2),
                },
            },
            "& .MuiDataGrid-row.Mui-selected": {
                backgroundColor: "rgba(48, 219, 184, 0.08) !important",
            },
            "& .MuiDataGrid-footer": {
                height: "42px",
            },
            "& .MuiDataGrid-row.Mui-odd": {
                backgroundColor: hexToRGB(theme.palette.lightgray.main, 0.75),
            },
            "& .MuiDataGrid-columnSeparator": {
                opacity: ".35 !important",
            },

            //Hiding the checkbox header for now because it is causing confusion
            "& .MuiDataGrid-colCellCheckbox": {
                // display: 'hidden'
            },
            "& .MuiDataGrid-colCellCheckbox input": {
                display: "none",
            },
            "& .MuiDataGrid-colCellCheckbox .MuiCheckbox-root svg": {
                display: "none",
            },
            "& .MuiDataGrid-colCellCheckbox .MuiCheckbox-root svg path": {
                display: "none",
            },
            "& .MuiDataGrid-colCellCheckbox .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg":
                {
                    display: "none",
                },
            "& .MuiDataGrid-colCellCheckbox .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after":
                {
                    display: "none",
                },
            "& .MuiDataGrid-colCellCheckbox .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after":
                {
                    display: "none",
                },
            "& .MuiCheckbox-root svg": {
                borderRadius: 2,
            },
        },
    });

function CustomLoadingOverlay() {
    return (
        <GridOverlay>
            <div style={{ position: "absolute", top: 0, width: "100%" }}>
                <LinearProgress />
            </div>
        </GridOverlay>
    );
}

export interface IAcxDataGrid {
    dataGridStore: AcxDataGridStore;
}

const AcxDataGrid = observer((props: IAcxDataGrid) => {
    const classes = useStyles(styles);
    const navigate = useNavigate();
    const [apiCurrent, setApiCurrent] = React.useState<GridApi | null>(null);
    const store = props.dataGridStore;
    const gridApi = useGridApiRef();
    const filterIconRef = React.useRef<HTMLDivElement>(null);

    const handleVertClick = (event: React.MouseEvent<HTMLElement>) => {
        store.menuAnchor = event.currentTarget;
        event.stopPropagation();
        event.preventDefault();
    };

    const handleVertMenuClose = () => {
        store.menuAnchor = null;
    };

    const onClear = () => {
        store.clearSelected();
    };

    React.useEffect(() => {
        if (apiCurrent) {
            apiCurrent.on(GRID_COLUMN_RESIZE_STOP, () => {
                store.onColResizeReorderOrVisChange(apiCurrent.getAllColumns());
            });

            apiCurrent.on(GRID_COLUMN_ORDER_CHANGE, () => {
                store.onColResizeReorderOrVisChange(apiCurrent.getAllColumns());
            });
        }
    }, [apiCurrent, store]);

    // Assign the current grid api object
    React.useEffect(() => {
        const waitOnThis = () => {
            setApiCurrent(gridApi.current);
            store.apiRef = gridApi;
        };

        delay(waitOnThis, 100);
    }, [apiCurrent, gridApi, store]);

    function isTypeElementAndSize(
        arg: React.ReactElement | CustomControlItem,
    ): arg is CustomControlItem {
        return isType<CustomControlItem>(arg, "controlElement");
    }

    const getHeader = () => {
        if (store.title) {
            return <Typography variant="subtitle2">{store.title}</Typography>;
        }

        if (isStringType(store.rowTerm)) {
            const header = `${store.rows?.length} ${store.rowTerm ?? "Items"} `;
            return (
                <Typography variant="subtitle1">
                    {header}
                    {store.selectedRowIds &&
                        store.selectedRowIds.length > 0 &&
                        !store.selectionContextOptions && (
                            <>
                                ({store.selectedRowIds.length} selected
                                <Tooltip title="Clear all selections">
                                    <IconButton
                                        size="small"
                                        color="secondary"
                                        onClick={onClear}
                                    >
                                        <ClearIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                                )
                            </>
                        )}
                </Typography>
            );
        } else {
            return store.rowTerm;
        }
    };

    const headerColumnSpan = store.headerColumnSpan
        ? store.headerColumnSpan
        : 3;

    const controlsColumnSpan = store.controlsColumnSpan
        ? store.controlsColumnSpan
        : 9;

    React.useEffect(() => {
        const hideColumnMenuItem = {
            props: {
                onClick: () => {
                    store.closeMenu();
                    store.showHideColumns();
                },
            },
            icon: <ViewColumn />,
            id: `${store.gridId}-hide-col-menu-item`,
            label: <StyledMenuLabel>Hide Columns</StyledMenuLabel>,
        } as AcxMenuItemProps;
        store.vertIconMenuItems = isFunction(store.vertIconMenuItemsBuilder)
            ? store.vertIconMenuItemsBuilder(store.closeMenu, navigate)
            : store.vertIconMenuItemsBuilder ?? [];

        store.vertIconMenuItems.push(hideColumnMenuItem);
    }, [navigate, store, store.vertIconMenuItemsBuilder]);

    React.useEffect(() => {
        if (!store.saveFilterModel && store.filterModel?.items) {
            store.filterModel.items = [];
            store.updateFiltered(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Grid
                container
                alignItems="flex-end"
                className={classes.controlSection}
                sx={{
                    position: "relative",
                    marginRight: "-5px",
                    ...store.headerColumnsStyle,
                }}
            >
                {Boolean(store.selectionContextOptions) && (
                    <Grid item xs={12} style={{ marginBottom: "16px" }}>
                        <AcxGridSelection store={store} />
                    </Grid>
                )}

                <Grid
                    container
                    item
                    xs={headerColumnSpan}
                    justifyContent={store.headersJustifyProperty}
                >
                    {getHeader()}
                </Grid>

                <Grid item xs={controlsColumnSpan}>
                    <Grid
                        container
                        justifyContent={
                            store.controlsJustifyProperty ?? "flex-end"
                        }
                        alignItems={store.controlsAlignProperty ?? "flex-end"}
                        alignContent="flex-end"
                        style={{
                            ...store.controlsColumnStyle,
                            marginLeft: "0px",
                        }}
                    >
                        {store.controls?.map((arg, index) => {
                            let control = arg;

                            if (isTypeElementAndSize(arg)) {
                                control = arg.controlElement;
                            }

                            const controlMargin = store.controlMargin ?? {
                                marginRight: "0.5rem",
                                marginBottom: "0.5rem",
                            };

                            return (
                                <span
                                    key={
                                        (control as React.ReactElement).key ??
                                        "control-" + index
                                    }
                                    style={{
                                        ...controlMargin,
                                        ...(arg as CustomControlItem).style,
                                    }}
                                >
                                    {control as React.ReactNode}
                                </span>
                            );
                        })}
                        {!store.hideFilter && (
                            <FilterIcon
                                store={store}
                                onClick={() => apiCurrent?.showFilterPanel()}
                                filtered={store.filtered}
                                vertIconEnabled={!!store.vertIconMenuItems}
                                ref={filterIconRef}
                            />
                        )}
                        {store.vertIconMenuItems &&
                            Array.isArray(store.vertIconMenuItems) &&
                            store.vertIconMenuItems.length > 0 &&
                            !store.hideVertIcon && (
                                <Grid item>
                                    <VertIcon onClick={handleVertClick} />

                                    <AcxMenu
                                        menuItems={
                                            isFunction(store.vertIconMenuItems)
                                                ? store.vertIconMenuItems(
                                                      store.closeMenu,
                                                      navigate,
                                                  )
                                                : store.vertIconMenuItems
                                        }
                                        anchorOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "left",
                                        }}
                                        anchorElement={store.menuAnchor}
                                        onMenuClose={handleVertMenuClose}
                                    />
                                </Grid>
                            )}
                    </Grid>
                </Grid>
            </Grid>

            {store.preHeader && <Grid item>{store.preHeader}</Grid>}

            <div
                className={classes.tableContainer}
                style={{
                    width: "100%",
                    height: `calc(100% - ${store.removeHeight ?? "0px"})`,
                    marginTop: "0.25rem",
                }}
            >
                <DataGridPro
                    filterModel={store.filterModel}
                    onFilterModelChange={(newFilterModel) => {
                        store.setFilterModel(newFilterModel);
                    }}
                    sortModel={store.sortModel}
                    onSortModelChange={(newSortModel) => {
                        store.setSortModel(newSortModel);
                    }}
                    className={classes.root}
                    columns={toJS(store.gridCols)}
                    rows={toJS(store.rows)}
                    density={store.density}
                    checkboxSelection={store.checkboxSelection}
                    checkboxSelectionVisibleOnly={
                        store.checkboxSelectionVisibleOnly
                    }
                    onSelectionModelChange={store.handleSelectionChange}
                    selectionModel={toJS(store.selectedRowIds)}
                    loading={store.isLoading}
                    apiRef={gridApi}
                    isRowSelectable={store.isRowSelectable}
                    components={{
                        LoadingOverlay: CustomLoadingOverlay,
                        NoRowsOverlay: NoRowsOverlay,
                        FilterPanel: FilterPanel,
                    }}
                    disableColumnMenu
                    componentsProps={{
                        panel: {
                            anchorEl: filterIconRef.current,
                        },
                        filterPanel: {
                            store: store,
                        },
                    }}
                    disableSelectionOnClick
                    pagination={store.pagination}
                    pageSize={store.paginationSize}
                    rowsPerPageOptions={store.rowsPerPageOptions}
                    rowCount={store.rowCount}
                    onPageSizeChange={store.onPageSizeChange}
                    filterMode={store.filterMode}
                    sortingMode={store.sortingMode}
                    paginationMode={store.paginationMode}
                    onPageChange={store.onPageChange}
                    // onRowsScrollEnd={store.handleOnRowsScrollEnd}
                />
            </div>
            {apiCurrent && <HideColumn store={store} api={gridApi} />}
        </>
    );
});

export default AcxDataGrid;
