import { Grid } from "@mui/material";
import AcxConfirmationDialog from "components/UI/AcxConfirmationDialog";
import AcxMainTextField from "components/UI/AcxMainTextField";
import { observer } from "mobx-react-lite";
import React from "react";
import { useStore } from "../../../../utils/useStore";
import { ClassifierBuilderStore } from "../ClassifierBuilderStore";
import { ClassifierBuilderEditorStore } from "./ClassifierBuilderEditorStore";
import ClassifierBuilderEditorView from "./ClassifierBuilderEditorView";

interface Props {}

const ClassifierBuilderEditor = observer((props: Props) => {
    const editorStore = useStore(ClassifierBuilderEditorStore);
    const cbStore = useStore(ClassifierBuilderStore);

    async function handleSaveClick() {
        editorStore.createClassifier();
    }

    async function handleUpdateClick() {
        editorStore.updateClassifier();
    }

    function handleClassifierNameChange(
        e: React.ChangeEvent<HTMLInputElement>,
    ) {
        editorStore.dialog.setClassifierName(e.target.value);
    }

    return (
        <>
            <AcxConfirmationDialog
                isOpen={editorStore.updateDialog.isOpen}
                title={`Update ${
                    cbStore.selectedClassifier?.name ?? "Classifier"
                }`}
                content={
                    <Grid container direction="column" spacing={3}>
                        <Grid item>
                            Would you like to overwrite the pre-existing
                            classifier?
                        </Grid>
                        <Grid item>
                            <AcxMainTextField
                                labelText="Update Classifier Name (optional)"
                                value={
                                    editorStore.cbStore.selectedClassifier?.name
                                }
                                onChange={(e) =>
                                    editorStore.setNewClassifierName(
                                        e.target.value,
                                    )
                                }
                                id="classifier-name-field"
                            />
                        </Grid>
                    </Grid>
                }
                onClose={editorStore.updateDialog.close}
                onConfirm={handleUpdateClick}
                buttonsDisabled={editorStore.updateDialog.isLoading}
                isLoading={editorStore.updateDialog.isLoading}
                alternateSpinnerType="PuffLoader"
                errorMessage={editorStore.updateDialog.errorMessage}
            />

            <AcxConfirmationDialog
                isOpen={editorStore.dialog.isOpen}
                title="Save New Classifier"
                content={
                    <Grid container direction="column" spacing={3}>
                        <Grid item>
                            Save this classifier to your personal profile. No
                            one else will be able to see this classifier until
                            you publish it.
                        </Grid>
                        <Grid item>
                            <AcxMainTextField
                                labelText="Classifier Name"
                                value={editorStore.dialog.classifierName}
                                onChange={handleClassifierNameChange}
                                id="classifier-name-field"
                            />
                        </Grid>
                    </Grid>
                }
                onClose={editorStore.dialog.close}
                onConfirm={handleSaveClick}
                buttonsDisabled={editorStore.dialog.isLoading}
                isLoading={editorStore.dialog.isLoading}
                alternateSpinnerType="PuffLoader"
                errorMessage={editorStore.dialog.errorMessage}
            />

            <ClassifierBuilderEditorView
                store={cbStore}
                onQueryChange={editorStore.setEditorQuery}
                query={editorStore.editorQuery}
                onSaveButtonClick={() => editorStore.dialog.open()}
                onUpdateButtonClick={() => editorStore.updateDialog.open()}
            />
        </>
    );
});

export default ClassifierBuilderEditor;
