import { Theme, Tooltip } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Tier } from "components/Admin/Organizations/types/Tier.type";
import React from "react";
import useStyles from "Styles/Styles";
import { OrganizationServiceHierarchyStore } from "../stores/OrganizationServiceHierarchyStore";

export interface AddRowIconProps {
    tier: Tier;
    osmStore: OrganizationServiceHierarchyStore;
    isLeaf?: boolean;
}

type Props = AddRowIconProps;

const styles = (theme: Theme) => {
    return createStyles({
        icon: {
            display: "flex",
            alignItems: "center",
            marginRight: theme.spacing(1),
            marginLeft: theme.spacing(1),
            cursor: "pointer",
        },
    });
};

const AddRowIcon: React.FC<
    React.PropsWithChildren<React.PropsWithChildren<Props>>
> = ({ tier, isLeaf, osmStore }) => {
    const classes = useStyles(styles);

    const enableAddition = (tier): boolean => {
        if (tier.branchDepth === osmStore.orgServiceSetup?.length - 1) {
            return false;
        }

        return true;
    };

    const handleOnClick = () => {
        osmStore.setActiveTier(tier);
        osmStore.setShowAddTierDialog(true);
    };

    return enableAddition(tier) ? (
        <Tooltip title={"Add Tier"}>
            <AddCircleIcon
                color="primary"
                onClick={handleOnClick}
                cursor="pointer"
                className={classes.icon}
            />
        </Tooltip>
    ) : (
        <Tooltip
            title={
                "No more tiers are available in this organization service hierarchy."
            }
        >
            <AddCircleIcon htmlColor="#d5dff6" className={classes.icon} />
        </Tooltip>
    );
};

export default AddRowIcon;
