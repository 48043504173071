import { useTheme } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import React from "react";
import AcxButton from "../AcxButton";

interface IFilterIcon {
    onClick: (event: React.MouseEvent<HTMLElement>) => void;
    isDisabled?: boolean;
}

const VertIcon = (props: IFilterIcon) => {
    const theme = useTheme();

    return (
        <AcxButton
            buttonDisabled={props.isDisabled ?? false}
            onClick={props.onClick as any}
            fullWidth={false}
            color="secondary"
            leftRightSpacing={0}
            rootStyle={{
                padding: "1rem",
                minWidth: "max-content",
                width: "2rem",
                margin: "0px 0px 0.5rem 0.5rem",
                backgroundColor: theme.palette.white.main,
                color: theme.palette.black.main,
            }}
        >
            <MoreVertIcon style={{ fontSize: "1.45rem" }} />
        </AcxButton>
    );
};

export default VertIcon;
