import { observer } from "mobx-react";
import { IApplicationFilterComponent } from "../Filters/IApplicationFilterComponent";
import React from "react";
import AcxToggleButton from "components/UI/AcxToggleButton";
import { Sentiment } from "stores/ApplicationFilters/Filters/Sentiment";

export const SentimentFilter = observer(function (
    props: IApplicationFilterComponent,
) {
    return (
        <AcxToggleButton
            id={`filter-${props.filterKey}-toggle-button`}
            exclusive
            items={[
                { element: "Negative", value: Sentiment.Negative },
                { element: "Neutral", value: Sentiment.Neutral },
                { element: "Positive", value: Sentiment.Positive },
            ]}
            onChange={(value) => {
                if (props.filterKey === "beginningSentiment")
                    props.store.setBeginningSentiment(value);
                else if (props.filterKey === "endingSentiment")
                    props.store.setEndingSentiment(value);
            }}
            defaultValue={props.store[props.filterKey]}
            justifyContent="center"
            groupFlexGrow={1}
        />
    );
});
