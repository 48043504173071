import AddIcon from "@mui/icons-material/Add";
import { Grid, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import AcxButton from "components/UI/AcxButton";
import { observer } from "mobx-react";
import React from "react";
import { AuthStore } from "stores/AuthStore";
import { useStore } from "utils/useStore";
import OrganizationWorkflowEmailStore from "./Stores/OrganizationWorkflowEmailStore";
import OrganizationWorkflowsStore from "./Stores/OrganizationWorkflowsStore";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        actionButtonBar: {
            padding: "0.5rem 0",
            display: "flex",
            alignItems: "center",
        },
    }),
);

type Props = {
    conditionStore: OrganizationWorkflowsStore;
    emailStore: OrganizationWorkflowEmailStore;
};

const OrganizationWorkflowsActionBar: React.FC<
    React.PropsWithChildren<React.PropsWithChildren<Props>>
> = observer(({ conditionStore, emailStore }) => {
    const classes = useStyles();
    const authStore = useStore(AuthStore);

    return (
        <Grid container item xs={12}>
            <Grid item xs={12} className={classes.actionButtonBar}>
                <AcxButton
                    buttonDisabled={
                        !authStore.canUserEdit("Workflow Conditions") ||
                        conditionStore.isCreateDisabled
                    }
                    color="secondary"
                    leftRightSpacing={0}
                    rootStyle={{
                        padding: "1rem",
                        minWidth: "max-content",
                    }}
                    fullWidth={false}
                    startIcon={<AddIcon />}
                    onClick={() => emailStore.setEmailEditorIsOpen(true)}
                    loading={emailStore.anyTaskLoading}
                >
                    Create Email
                </AcxButton>

                <AcxButton
                    buttonDisabled={
                        !authStore.canUserEdit("Workflow Conditions") ||
                        conditionStore.anyTaskLoading ||
                        conditionStore.isCreateDisabled
                    }
                    color="secondary"
                    leftRightSpacing={1}
                    rootStyle={{
                        padding: "1rem",
                        minWidth: "max-content",
                    }}
                    fullWidth={false}
                    startIcon={<AddIcon />}
                    onClick={conditionStore.handleDrawerOpen}
                    loading={conditionStore.anyTaskLoading}
                >
                    Create Condition
                </AcxButton>
            </Grid>
        </Grid>
    );
});

export default OrganizationWorkflowsActionBar;
