import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import {
    action,
    computed,
    makeObservable,
    observable,
    reaction,
    runInAction,
    toJS,
} from "mobx";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";
import { AuthStore } from "../../../stores/AuthStore";
import { BaseStore } from "../../../stores/BaseStore";
import { DatePickerComponentStore } from "stores/ComponentStores/DatePickerComponentStore";
import { SignalsService } from "services/SignalsService";
import {
    getFirstDayOfQuarter,
    serializeToUtc,
    toIsoByDateReference,
} from "utils/DateTimeUtils";
import { signalDataToGoogleFormat } from "utils/ReportDataToGoogleFormat";
import moment from "moment";
import ClassifierService from "services/ClassifierService";
import Classifier from "models/ClassifierModel";
import ModuleService from "components/Admin/Organizations/OrganizationDetail/OrganizationModules/Module.service";
import {
    Module,
    Question,
} from "components/Admin/Organizations/types/Module.type";
import type ClassifierCategory from "models/ClassifierCategoryId";
import AcxDataGridStore from "components/UI/AcxDataGrid/AcxDataGridStore";
import IColDef from "components/UI/AcxDataGrid/IColDef";
import { Grid, Typography } from "@mui/material";
import React from "react";
import * as SignalsModels from "../Models/SignalsModels";
import { THE_MICHAEL_GRAIN, temporalGrain } from "../Utils/DataAggregation";
import { SignalReportDatum } from "models/Reporting/ReportDatum";
import theme from "Theme/AppTheme";
import type { SignalsTemporalLabels } from "../Models/SignalsModels";
import { GoogleChartWrapper } from "react-google-charts/dist/types";
import AISummariesStore from "./AISummariesStore";
import { v4 as uuidv4 } from "uuid";
import ClassifierGroup from "models/ClassifierGroup";
import SignalsStore from "./SignalsStore";
import { ApplicationFiltersStore } from "stores/ApplicationFilters/ApplicationFiltersStore";
import SignalsVizOptions from "../Components/SignalsVizOptions";
import { LayoutDrawerStore } from "stores/Layout/LayoutDrawerStore";
import { getSignalsColumns, populateDataGrid } from "../Utils/SignalsHelpers";
import { contactTypes } from "stores/ApplicationFilters/Filters/ContactTypes";
import CustomerTypesService from "services/CustomerTypesService";
import { UnMappedCustomerType } from "models/CustomerType";

export const LoadInitSignalsConfigData = "Load Initial Signals Config Data";
export const LoadInitSignalsChartData = "Load Initial Signals Chart Data";
export const TotalConversationsCardLoading = "total conversations";
export const AverageCallDurationCardLoading = "avg call duration";
export const LoadingSignalsRefresh = "refresh signals";
export const LoadingInitDashboards = "Loading Initial Dashboards";
const signalsReportLoadingTask = "Load signals reports";
export const LoadDashboards = "Load Dashboards";

@AcxStore
class SignalsReportStore extends BaseStore {
    readonly authStore: AuthStore;

    readonly messageStore: MessageStore;

    readonly aiSummariesStore: AISummariesStore;

    readonly applicationFiltersStore: ApplicationFiltersStore;

    // Services
    signalsService = new SignalsService();
    classifierService = new ClassifierService();
    moduleService = ModuleService;
    private readonly customerTypesService: CustomerTypesService =
        new CustomerTypesService();

    private abortController = new AbortController();

    signalsStore: SignalsStore;

    @observable
    renderedChartData: SignalsModels.ISignalsVizDefinition[] = [];

    @observable
    nonDashChartData: SignalsModels.ISignalsVizDefinition[] = [];

    @observable
    userClassifiers: Classifier[] = [];

    @observable
    topics: SignalsModels.SignalsTopics[] = [];

    @observable
    selectedClassifierCategoryByChartId: {
        string?: ClassifierCategory;
    } = {};

    @observable
    selectedModuleIdByChartId: { string?: string } = {};

    @observable
    selectedQuestionByChartId: {
        string?: Question;
    } = {};

    @observable
    selectedComparePrevBools: { string?: boolean } = {};

    @observable
    chartStateByChartId: {
        string?: SignalsModels.SignalsRequest<Record<string, unknown>>;
    } = {};

    @observable
    chartWrappersByChartId: {
        string?: GoogleChartWrapper;
    } = {};

    @observable
    userClassifierCategories: ClassifierCategory[] | null = [];

    @observable
    orgScoredModules: Module[] = [];

    @observable
    orgModules: Module[] = [];

    @observable
    comparePrevStart: moment.Moment;

    @observable
    comparePrevEnd: moment.Moment;

    maxSelectionCount: number = 5;

    @observable
    aggregationLabel: SignalsTemporalLabels = "Weekly";

    // Small Cards Data
    @observable
    totalConversations: number | undefined;

    @observable
    eddyPercent: number | undefined;

    @observable
    averageCallDuration: number | undefined;

    @observable
    averageCallDurationEddyFilter: SignalsModels.EddyPresence =
        SignalsModels.EddyPresence.WithEddy;

    @observable
    defaultClassifierIds: string[] = [];

    @observable
    defaultTopicIds: string[] = [];

    @observable
    defaultClassifierCategory?: ClassifierCategory;

    @observable
    defaultModuleId?: string;

    @observable
    haveInitLoadedCards?: boolean = false;

    @observable
    allDashboardsUserCanView: SignalsModels.ISignalsUserDashboard[] = [];

    @observable
    showCreateDashboard: boolean = false;

    @observable
    selectedNewChart?: {
        dataEndpoint: SignalsModels.SignalDataEndpoint;
        id: string;
        chartTitle: string;
        chartSubtitle: string;
    } = undefined;

    @observable
    selectedChartIdToRemove?: string = undefined;

    @observable
    dashboardForNewReport?: SignalsModels.ISignalsUserDashboard = undefined;

    @observable
    currentDashboard?: SignalsModels.ISignalsUserDashboard = undefined;

    @observable
    currentVizOptions: any;

    @observable
    showSelectDashboard: boolean = false;

    @observable
    isPresentationMode: boolean = false;

    @observable
    customerTypes: UnMappedCustomerType[] = [];

    @observable
    newDashboardTitle: string = "";

    @observable
    newDashboardVis: SignalsModels.IDashboardVisibility = "User";

    public constructor(private rootStore: IRootStore) {
        super("Eddy Reports Store");
        makeObservable(this);

        this.authStore = rootStore.getStore(AuthStore);
        this.messageStore = rootStore.getStore(MessageStore);
        this.aiSummariesStore = rootStore.getStore(AISummariesStore);
        this.applicationFiltersStore = rootStore.getStore(
            ApplicationFiltersStore,
        );

        this.resetAbortController();

        this.signalsStore = rootStore.getStore(SignalsStore);

        this.initializeNonDashCharts();

        reaction(
            () => ({
                orgId: this.authStore.orgStore.selectedOrganization?.id,
                location: this.authStore.rootStore.activeLocation.location,
            }),
            async (args, prev) => {
                if (
                    !args.orgId ||
                    (args.location === prev?.location &&
                        args.orgId === prev?.orgId) ||
                    !args.location.includes("app/signals")
                ) {
                    return;
                }

                // Classifier and Module loading
                if (
                    !this.userClassifiers.length ||
                    !this.orgModules.length ||
                    !this.topics.length ||
                    !this.customerTypes.length
                ) {
                    this.setTaskLoading(LoadInitSignalsConfigData, true);
                    await this.loadInitData(
                        this.authStore.orgStore.selectedOrganization?.id!,
                    );
                    this.setTaskLoading(LoadInitSignalsConfigData, false);
                }

                if (!this.allDashboardsUserCanView.length) {
                    this.setTaskLoading(LoadingInitDashboards, true);
                    await this.refreshDashboards();
                    this.setTaskLoading(LoadingInitDashboards, false);
                }

                //TODO: extract into reusable function taking in domain
                if (
                    !args.location.includes("trends") &&
                    !args.location.includes("topics") &&
                    !args.location.includes("contacts") &&
                    !args.location.includes("dashboard") &&
                    args.location.includes("app/signals")
                ) {
                    this.renderedChartData = this.allChartData.filter(
                        (c) => c.isEddyReport,
                    );
                    this.currentDashboard = undefined;
                } else if (
                    args.location.includes("app/signals/trends") &&
                    this.authStore.canUserView("Trends")
                ) {
                    this.renderedChartData = this.allChartData.filter(
                        (c) => c.isTrendReport,
                    );
                    this.currentDashboard = undefined;
                } else if (
                    args.location.includes("app/signals/topics") &&
                    this.authStore.canUserView("Topics")
                ) {
                    this.renderedChartData = this.allChartData.filter(
                        (c) => c.isTopicReport,
                    );
                    this.currentDashboard = undefined;
                } else if (
                    args.location.includes("app/signals/contacts") &&
                    this.authStore.canUserView("Contacts")
                ) {
                    this.renderedChartData = this.allChartData.filter(
                        (c) => c.isContactReport,
                    );
                    this.currentDashboard = undefined;
                } else if (args.location.includes("app/signals/dashboard")) {
                    if (!this.allDashboardsUserCanView.length) return;
                    const locLeng = args.location.split("/").length;
                    const dashId = args.location.split("/")[locLeng - 1];

                    this.currentDashboard = this.allDashboardsUserCanView.find(
                        (d) => d.id === dashId,
                    );

                    this.renderedChartData = this.allDashboardsUserCanView.find(
                        (dash) => dash.id === dashId,
                    )!.signalsVizDefinitions;
                }

                const shouldLoadCards = !this.haveInitLoadedCards;

                // we only reload cards on apply click and initial load
                if (shouldLoadCards) {
                    this.refreshSignalsCards();
                    this.haveInitLoadedCards = true;
                }

                await this.refreshReports();
            },
            { fireImmediately: true },
        );

        reaction(
            () => ({
                start: this.applicationFiltersStore.quickFiltersStore.startDate,
                end: this.applicationFiltersStore.quickFiltersStore.endDate,
            }),
            (arg) => {
                const dayDiff = moment(arg.end).diff(moment(arg.start), "days");

                this.comparePrevStart = moment(arg.start)
                    .subtract(dayDiff, "d")
                    .startOf("date");
                this.comparePrevEnd = moment(arg.end)
                    .subtract(dayDiff + 1, "d")
                    .endOf("date");
            },
            { fireImmediately: true },
        );

        reaction(
            () => this.abortController,
            () => console.log("Abort controller reset"),
        );
    }

    private resetAbortController() {
        this.abortController = new AbortController();
        this.signalsService.setAbortSignal(this.abortController.signal);
    }

    public cancelReportRequests() {
        this.abortController.abort();
        this.setAllChartLoading(false);
    }

    @action
    initializeNonDashCharts() {
        SignalsModels.AllSignalDataEndpoints.filter((dataEndpoint) =>
            this.signalsStore.canViewChart(dataEndpoint),
        ).forEach((dataEndpoint) => {
            const chartTitle = SignalsModels.SignalsIdToName[dataEndpoint];
            const chartId = dataEndpoint;
            const dataGridStore = this.createDataGridStoreForChart(
                chartId,
                dataEndpoint,
            );

            // need to make sure we don't duplicate charts from both pages (EEOverTime)
            if (!this.allChartData.find((c) => c.id === chartId)) {
                this.nonDashChartData.push({
                    id: chartId,
                    chartTitle: chartTitle,
                    data: [],
                    countData: [],
                    percentData: [],
                    renderMode: SignalsModels.getDefaultRenderMode(chartId),
                    xTitle: "",
                    yTitle: "",
                    seriesNames: [],
                    chartType: SignalsModels.getChartTypeFromChartID(chartId),
                    selectionType: SignalsModels.getSelectionType(chartId),
                    showComparePrevCheckbox:
                        SignalsModels.getComparePrevControls(chartId),
                    showPercentToggle:
                        SignalsModels.getPercentToggleControls(chartId),
                    showDataGrid: SignalsModels.getDataGridControls(chartId),
                    dataGridStore: dataGridStore,
                    chartSubtitle: SignalsModels.getChartSubtitle(chartId),
                    isTemporal: SignalsModels.getIsTemporal(chartId),
                    showSpotlight: SignalsModels.getShowSpotlight(chartId),
                    isEddyReport: SignalsModels.getIsEddyReport(chartId),
                    isTrendReport: SignalsModels.getIsTrendReport(chartId),
                    isTopicReport: SignalsModels.getIsTopicReport(chartId),
                    isContactReport: SignalsModels.getIsContactReport(chartId),
                    shouldReload: true,
                    isStackedBar: SignalsModels.getIsStackedBar(chartId),
                    dataEndpoint: chartId,
                    order: 1,
                    signalsDashboardId: "default",
                    vizOptions: "{}",
                });
            }
        });
    }

    @action
    getUserClassifiers(orgId: string) {
        return this.setupAsyncTask("load user classifiers", async () => {
            const res = await this.classifierService.getUserClassifiers(
                orgId,
                true,
            );

            runInAction(() => {
                this.userClassifiers = res;
                this.userClassifiers.sort((a, b) => {
                    return a.name.localeCompare(b.name);
                });
            });
        });
    }

    @action
    getUserClassifierCategories() {
        return this.setupAsyncTask("load user classifier groups", async () => {
            const catRes =
                await this.classifierService.getClassifierCategories();

            catRes.push({
                name: "All Classifiers",
                id: "all-classifiers",
            } as ClassifierGroup);

            runInAction(() => {
                this.userClassifierCategories = catRes;
                this.getDefaultClassifiersForOrg();
            });
        });
    }

    @action
    getOrgModules(orgId: string) {
        return this.setupAsyncTask("load org modules", async () => {
            const scoredModules =
                await this.signalsService.getLicensedModulesWithScoredQuestions();
            const allModules = await this.moduleService.getModules(orgId);
            runInAction(async () => {
                const mapModuleDataForSignals = (m: Module) => {
                    const descriptionText = m.description
                        ? " - " + m.description
                        : "";

                    m.displayName = m.name + descriptionText;

                    m.questions = m.questions.flatMap((q1) => {
                        if (q1.questions?.length) {
                            q1.questions = q1.questions.map((childQuestion) => {
                                const questionGroupDisplayText =
                                    q1.shortQuestionText +
                                    " - " +
                                    childQuestion.shortQuestionText;
                                // @ts-ignore
                                childQuestion.displayName =
                                    questionGroupDisplayText;
                                return childQuestion;
                            });
                            return q1.questions;
                        } else {
                            // @ts-ignore
                            q1.displayName = q1.shortQuestionText;
                            return q1;
                        }
                    });

                    // remove questions without any tags (question groups)
                    m.questions = m.questions.filter(
                        (q) => q.isActive && q.tags?.length,
                    );
                    return m;
                };

                this.orgScoredModules = scoredModules
                    .filter((m) => m.isActive)
                    .map(mapModuleDataForSignals);

                this.orgModules = allModules
                    .filter((m) => m.isActive)
                    .map(mapModuleDataForSignals);
                this.getDefaultModuleId();
            });
        });
    }

    @action
    refreshTopics(isInit: boolean) {
        return this.setupAsyncTask("load topics", async () => {
            const res = await this.signalsService.getTrendingTopics(
                serializeToUtc(
                    this.applicationFiltersStore.quickFiltersStore.startDate,
                )!,
                serializeToUtc(
                    this.applicationFiltersStore.quickFiltersStore.endDate,
                )!,
            );

            runInAction(() => {
                this.topics = res;

                if (isInit) {
                    this.defaultTopicIds = res
                        .slice(0, this.maxSelectionCount)
                        .map((t) => t.id);
                } else {
                    this.allChartData
                        .filter((c) => c.selectionType === "Topics")
                        .forEach((c) => {
                            c.dataGridStore.rows = res;
                        });
                }
            });
        });
    }

    getFilters(): SignalsModels.SignalsFilters {
        const [startDate, endDate] = this.getFormattedDates(
            this.applicationFiltersStore.quickFiltersStore.datePickerStore,
        );

        const filters = this.applicationFiltersStore.toRequestObject();

        return {
            ...filters,
            startDate: startDate ?? "",
            endDate: endDate ?? "",
        };
    }

    getChartState(chartId: string): SignalsModels.SignalsRequest {
        const [startDate, endDate, compareStart, compareEnd] =
            this.getFormattedDates(
                this.applicationFiltersStore.quickFiltersStore.datePickerStore,
            );

        const filters = this.applicationFiltersStore.toRequestObject();

        const state: SignalsModels.SignalsRequest = {
            ...filters,
            startDate: startDate ?? "",
            endDate: endDate ?? "",
        };

        const daysInRange = moment(endDate)?.diff(startDate, "days");
        state.aggregateDataByTemporalGrain = daysInRange > THE_MICHAEL_GRAIN;

        if (
            this.authStore.canUserView("AI Summaries") &&
            !this.aiSummariesStore.isInitialSummaryFetched(chartId)
        ) {
            state.requestAiSummary = true;
            state.requestId = uuidv4();
        }

        if (this.selectedComparePrevBools[chartId]) {
            state.previousPeriodStartDate = compareStart;
            state.previousPeriodEndDate = compareEnd;
        }

        const dataEndpoint = this.allChartData.find(
            (cd) => cd.id === chartId,
        )?.dataEndpoint;

        switch (dataEndpoint) {
            case SignalsModels.EEByClassifierID:
                state.classifierIds = this.getSelectedRowIds(chartId);
                break;
            case SignalsModels.EEByClassifiersOverTimeID:
                state.classifierIds = this.getSelectedRowIds(chartId);
                break;
            case SignalsModels.EEPrevalenceByQuestionID:
            case SignalsModels.EEPrevalenceByQuestionOverTimeID:
                state.moduleId = this.selectedModuleIdByChartId[chartId];
                state.questionId = this.selectedQuestionByChartId[chartId]!.id;
                state.tagIds = this.getSelectedRowIds(chartId);
                break;
            case SignalsModels.AverageScoreByQuestionID:
            case SignalsModels.AverageScoreByQuestionAndEddyOverTimeID:
            case SignalsModels.AverageScoreByQuestionOverTimeID:
                state.moduleId = this.selectedModuleIdByChartId[chartId];
                state.questionIds = this.getSelectedRowIds(chartId);
                break;
            case SignalsModels.ClassifierAndEddyID:
                state.classifierIds = this.getSelectedRowIds(chartId);
                break;
            case SignalsModels.ClassifierOverTimeId:
                state.classifierIds = this.getSelectedRowIds(chartId);
                break;
            case SignalsModels.AverageScoreByModuleOverTimeID:
                state.moduleIds = this.getSelectedRowIds(chartId);
                break;
            case SignalsModels.TopicPrevalenceID:
            case SignalsModels.TopicPrevalenceOverTimeID:
            case SignalsModels.TopicPrevalenceAndEEID:
                state.topicIds = this.getSelectedRowIds(chartId);
                break;
        }

        return state;
    }

    @action
    async refreshAISummary(chartId: string) {
        const dataEndpoint = this.allChartData.find(
            (cd) => cd.id === chartId,
        )?.dataEndpoint;

        if (!dataEndpoint) return;
        return this.aiSummariesStore.refreshSummary(
            chartId,
            this.getChartState(chartId),
            dataEndpoint,
        );
    }

    @action
    async refreshReports() {
        // set loading to false for charts that were loading on other pages to prevent infinite load condition
        this.setAllChartLoading(false);

        if (!this.renderedChartData.some((c) => c.shouldReload)) return;

        // set loading for all charts to true to avoid showing stale data while loading
        this.setAllRenderedChartLoading(true);

        this.setTaskLoading(LoadInitSignalsChartData, true);

        this.aggregationLabel = temporalGrain(
            this.applicationFiltersStore.quickFiltersStore.startDate,
            this.applicationFiltersStore.quickFiltersStore.endDate,
        );

        this.aiSummariesStore.clearTimeoutErrors();
        this.setTaskLoading(LoadingSignalsRefresh, true);

        await this.setupAsyncTask(signalsReportLoadingTask, async () => {
            // use different loading task here to prevent setting loading to false prematurely
            for (const cd of this.renderedChartData) {
                this.abortController.signal.throwIfAborted();
                await this.getChartDataOrSetDefaults(cd.id);
            }
        });

        let isStillLoading = false;

        this.allChartData.forEach((i) => {
            if (this.getTaskLoading(i.id)) {
                isStillLoading = true;
            }
        });

        if (!isStillLoading) {
            this.setTaskLoading(LoadingSignalsRefresh, false);
        }

        this.setTaskLoading(LoadInitSignalsChartData, false);

        if (this.abortController.signal.aborted) {
            for (const cd of this.allChartData)
                this.setTaskLoading(cd.id, false);
            this.setTaskLoading(LoadingSignalsRefresh, false);
            this.resetAbortController();
        }
    }

    @action
    async refreshSignalsCards() {
        this.setSignalsCardsLoadingTrue();
        // set loading for all charts to true to avoid showing stale data while loading
        this.setAllRenderedChartLoading(true);
        await this.getTotalConversations();

        // Defaults to WithEddy
        await this.getAvgCallDuration(this.averageCallDurationEddyFilter);
    }

    @action
    async getChartDataAndAISummary(chartId: string) {
        return await this.setupAsyncTask(chartId, async () => {
            try {
                if (this.aiSummariesStore.isInitialSummaryFetched(chartId)) {
                    this.aiSummariesStore.setIsSummaryOutdated(chartId, true);
                    this.aiSummariesStore.setIsSummaryRefreshable(
                        chartId,
                        true,
                    );
                }

                const state = this.getChartState(chartId);

                this.chartStateByChartId[chartId] = state;
                if (
                    state.requestId &&
                    this.renderedChartData
                        .filter((cd) => cd.showSpotlight)
                        .map((cd) => cd.id)
                        .includes(chartId)
                ) {
                    this.aiSummariesStore.prepareForSignalRRequest(
                        state.requestId as string,
                        chartId,
                    );
                }

                const dataModel = this.allChartData.find(
                    (cd) => cd.id === chartId,
                );

                const dataEndpoint = dataModel?.dataEndpoint;

                if (!dataEndpoint) return;

                // handle updating defaults for dashboard reports
                if (dataModel.isDashboardReport && this.dashboardIsEditable) {
                    const parsedVizOptions = JSON.parse(dataModel.vizOptions);
                    if (
                        !!this.getSelectedRowIds(chartId)?.length && // prevent updating defaults to empty
                        JSON.stringify(parsedVizOptions.defaultSelectedIds) !==
                            JSON.stringify(this.getSelectedRowIds(chartId))
                    ) {
                        // defaults have changed so update default ids

                        const newVizOptions = {
                            ...parsedVizOptions,
                            defaultSelectedIds: this.getSelectedRowIds(chartId),
                        };

                        dataModel.vizOptions = JSON.stringify(newVizOptions);

                        await this.signalsService.updateVizDefinition({
                            id: dataModel.id,
                            dashboardId: dataModel.signalsDashboardId,
                            vizOptions: JSON.stringify(newVizOptions),
                            dataEndpoint: dataModel.dataEndpoint,
                            chartTitle: dataModel.chartTitle,
                            chartSubtitle: dataModel.chartSubtitle,
                            order: dataModel.order,
                        });

                        dataModel.vizOptions = JSON.stringify(newVizOptions);
                    }
                }

                const response = await this.signalsService.getChart(
                    dataEndpoint,
                    state,
                );

                runInAction(() => {
                    this.handleSignalsChartResponse(
                        response,
                        state.previousPeriodStartDate,
                        state.previousPeriodEndDate,
                        chartId,
                    );
                });

                return response;
            } catch (error) {
                // Nothing to do here, because we are checking if the task failed by chartId
            }
        });
    }

    async getChartDataOrSetDefaults(id: string) {
        const chartDataModel = this.renderedChartData.find((c) => c.id === id);
        if (!chartDataModel?.shouldReload) return;
        if (
            chartDataModel?.dataGridStore?.selectedRowIds.length ||
            chartDataModel.dataEndpoint === SignalsModels.EEOverTimeID || // these charts have no selection and no defaults, so just hit endpoint
            chartDataModel.dataEndpoint ===
                SignalsModels.ContactTypePrevalenceId ||
            chartDataModel.dataEndpoint ===
                SignalsModels.ContactTypePrevalenceAndEEId
        ) {
            await this.getChartDataAndAISummary(id);
        } else {
            if (chartDataModel?.selectionType === "Classifiers") {
                await this.setDefaultClassifiers(id);
            } else if (
                chartDataModel?.selectionType === "Modules" ||
                chartDataModel?.selectionType === "Question" ||
                chartDataModel?.selectionType === "Questions" ||
                chartDataModel?.selectionType === "Tags"
            ) {
                await this.setDefaultModuleAndQuestions(id);
            } else if (chartDataModel.selectionType === "Topics") {
                await this.setDefaultTopics(id);
            } else if (chartDataModel.selectionType === "HIPAA") {
                await this.setHipaaDefaults(id);
                await this.getChartDataAndAISummary(id);
            } else if (chartDataModel.selectionType === "Safety Event") {
                await this.setSEPrevalenceOverTimeDefaults(id);
                await this.getChartDataAndAISummary(id);
            } else if (chartDataModel.selectionType === "Contact Type") {
                await this.setContactTypeDefaults(id);
                await this.getChartDataAndAISummary(id);
            }
        }
        if (
            !this.dashboardIsEditable &&
            this.authStore.rootStore.activeLocation.location.includes(
                "dashboard",
            )
        ) {
            chartDataModel.dataGridStore.rows =
                chartDataModel.dataGridStore.rows.filter((i) =>
                    chartDataModel.dataGridStore.selectedRowIds.includes(i.id),
                );
        }
        this.setTaskLoading(chartDataModel.id, false);
        if (chartDataModel) chartDataModel.shouldReload = false;
    }

    setSignalsCardsLoadingTrue() {
        this.setTaskLoading(AverageCallDurationCardLoading, true);
        this.setTaskLoading(TotalConversationsCardLoading, true);
    }

    async loadInitData(orgId: string) {
        return await this.setupAsyncTask(
            LoadInitSignalsConfigData,
            async () => {
                await this.getUserClassifiers(orgId);
                await this.getUserClassifierCategories();
                await this.getOrgModules(orgId);
                await this.refreshTopics(true);
                await this.getCustomerTypes(orgId);
            },
        );
    }

    @action
    getCustomerTypes = async (id: string) => {
        const res = await this.customerTypesService.getMappedCustomerTypes();
        runInAction(() => {
            this.customerTypes = res;
        });
    };

    @action
    toggleRenderMode(input: SignalsModels.SignalsRenderMode, chartId?: string) {
        const clickedChart = this.renderedChartData.find(
            (item) => item.id === chartId,
        );

        if (clickedChart && clickedChart.data) {
            clickedChart.renderMode = input;

            clickedChart.data =
                input === "%"
                    ? clickedChart.percentData
                    : clickedChart.countData;

            if (clickedChart.yTitle.includes("%") && input === "#") {
                clickedChart.yTitle = clickedChart.yTitle
                    .split(" ")
                    .slice(0, -1)
                    .join(" ");
            }
            if (!clickedChart.yTitle.includes("%") && input === "%") {
                clickedChart.yTitle += " %";
            }
        }
    }

    @action
    async toggleComparePrevious(chartId: string, val: boolean) {
        this.updateComparePrevBool(chartId, val);
        await this.getChartDataAndAISummary(chartId);
    }

    @action
    async updateSelectedClassifiers(chartId: string, classifierIds: string[]) {
        await this.renderedChartData
            .find((c) => c.id === chartId)
            ?.dataGridStore?.handleSelectionChange(classifierIds);
    }

    @action
    updateSelectedClassifierCategory(
        chartId: string,
        classifierCategory?: ClassifierCategory,
    ) {
        this.selectedClassifierCategoryByChartId[chartId] = classifierCategory;
    }

    @action
    updateSelectedModule(chartId: string, module: any) {
        this.selectedModuleIdByChartId[chartId] = module;
    }

    @action
    updateComparePrevBool(chartId: string, val: boolean) {
        this.selectedComparePrevBools[chartId] = val;
    }

    @action
    async handleSelectedModuleChange(chartId: string, moduleId: string) {
        this.updateSelectedModule(chartId, moduleId);

        const questions =
            this.orgModules?.find((lm) => lm.id === moduleId)?.questions ?? [];

        const questionIds = questions.map((q) => q.id ?? "");

        const firstQuestion = questions[0];

        const tagIds = firstQuestion?.tags
            ?.map((t) => t.id)
            .slice(0, this.maxSelectionCount);

        const dataModel = this.renderedChartData.find(
            (cd) => cd.id === chartId,
        );

        const dataGridStore = dataModel?.dataGridStore;

        const isSingleQuestionSelect =
            dataModel?.dataEndpoint ===
            SignalsModels.AverageScoreByQuestionAndEddyOverTimeID;

        const isTagSelect =
            dataModel?.dataEndpoint ===
                SignalsModels.EEPrevalenceByQuestionID ||
            dataModel?.dataEndpoint ===
                SignalsModels.EEPrevalenceByQuestionOverTimeID;

        if (dataGridStore) {
            if (isSingleQuestionSelect) {
                this.selectedQuestionByChartId[chartId] = firstQuestion;
                await dataGridStore?.handleSelectionChange([
                    firstQuestion.id ?? "",
                ]);
            } else if (isTagSelect) {
                this.selectedQuestionByChartId[chartId] = firstQuestion;
                dataGridStore.rows = firstQuestion.tags ?? [];
                await dataGridStore.handleSelectionChange(tagIds ?? []);
            } else if (
                dataModel?.dataEndpoint ===
                SignalsModels.AverageScoreByQuestionOverTimeID
            ) {
                dataGridStore.rows = questions;
                await dataGridStore?.handleSelectionChange(
                    questionIds.slice(0, this.maxSelectionCount),
                );
            } else {
                await dataGridStore?.handleSelectionChange(
                    questionIds.slice(0, this.maxSelectionCount),
                );
            }
        }
    }

    @action
    async handleQuestionChange(chartId: string, questionIds: string[]) {
        const dataModel = this.renderedChartData.find(
            (cd) => cd.id === chartId,
        );
        const dataGridStore = dataModel?.dataGridStore;
        if (dataGridStore) {
            if (
                dataModel.dataEndpoint ===
                    SignalsModels.EEPrevalenceByQuestionID ||
                dataModel.dataEndpoint ===
                    SignalsModels.EEPrevalenceByQuestionOverTimeID
            ) {
                const selectedQuestionId = questionIds[0];
                const selectedQuestion = this.orgModules
                    .find(
                        (m) => m.id === this.selectedModuleIdByChartId[chartId],
                    )
                    ?.questions.find((q) => q.id === selectedQuestionId);
                this.selectedQuestionByChartId[chartId] = selectedQuestion;
                const selectedTagIds = selectedQuestion?.tags
                    ?.map((t) => t.id)
                    .slice(0, this.maxSelectionCount);
                dataGridStore.rows = selectedQuestion?.tags ?? [];
                await dataGridStore?.handleSelectionChange(
                    selectedTagIds ?? [],
                );
            } else {
                await dataGridStore?.handleSelectionChange(questionIds);
            }
        }
    }

    @action
    async handleTagChange(chartId: string, tagIds: string[]) {
        const dataGridStore = this.renderedChartData.find(
            (cd) => cd.id === chartId,
        )?.dataGridStore;

        if (dataGridStore) {
            await dataGridStore.handleSelectionChange(tagIds);
        }
    }

    @action
    async handleTopicChange(chartId: string, topicIds: string[]) {
        const dataGridStore = this.renderedChartData.find(
            (cd) => cd.id === chartId,
        )?.dataGridStore;

        if (dataGridStore) {
            await dataGridStore.handleSelectionChange(topicIds);
        }
    }

    @action
    async handleSelectedClassifierCategoryChange(
        chartId: string,
        categoryId?: string,
    ) {
        const isAllCategories = !categoryId || categoryId === "all-classifiers";

        const classifiersFromSelectedCategory = isAllCategories
            ? this.userClassifiers
            : this.userClassifiers.filter(
                  (c) => c.classifierCategory?.id === categoryId,
              );

        const foundChart = this.renderedChartData.find((c) => c.id === chartId);

        if (foundChart?.dataGridStore) {
            foundChart.dataGridStore.rows =
                !!classifiersFromSelectedCategory.length
                    ? classifiersFromSelectedCategory
                    : this.userClassifiers;
        }

        let classifierIds: string[] = classifiersFromSelectedCategory
            .map((c) => c.id)
            .slice(0, this.maxSelectionCount);

        this.updateSelectedClassifiers(chartId, classifierIds);

        const foundCategory = this.userClassifierCategories?.find(
            (i) => i.id === categoryId,
        );
        this.updateSelectedClassifierCategory(chartId, foundCategory);
    }

    @action
    captureChartRef = (chartWrapper: GoogleChartWrapper, chartId: string) => {
        this.chartWrappersByChartId[chartId] = chartWrapper;
    };

    getFormattedDates(
        dateStore: DatePickerComponentStore,
    ): (string | undefined)[] {
        const dates = toIsoByDateReference(
            dateStore.beginDate,
            dateStore.endDate,
            dateStore.referenceOption,
        );

        const comparePrevDates = toIsoByDateReference(
            this.comparePrevStart,
            this.comparePrevEnd,
            dateStore.referenceOption,
        );

        return [
            dates.beginDate,
            dates.endDate,
            comparePrevDates.beginDate,
            comparePrevDates.endDate,
        ];
    }

    handleSignalsChartResponse(
        res: SignalsModels.ISignalsChartResponse,
        prevPeriodStart?: string,
        prevPeriodEnd?: string,
        chartId?: string,
    ) {
        let dataToUse = res.data;
        if (!chartId) return;

        const foundChart = this.renderedChartData.find(
            (item) => item.id === chartId,
        );

        const selectedRowIds = this.getSelectedRowIds(chartId);

        // update DataGrid columns and rows with response data
        if (foundChart?.dataGridStore) {
            if (
                foundChart.dataEndpoint ===
                SignalsModels.EEByClassifiersOverTimeID
            ) {
                populateDataGrid({
                    responseData: dataToUse,
                    rowField: "name",
                    dataGridStore: foundChart.dataGridStore,
                    initColCount: 5,
                    selectedRowIds,
                });
            } else if (
                foundChart.dataEndpoint ===
                SignalsModels.AverageScoreByModuleOverTimeID
            ) {
                populateDataGrid({
                    responseData: dataToUse,
                    rowField: "displayName",
                    dataGridStore: foundChart.dataGridStore,
                    initColCount: 2,
                    selectedRowIds,
                    addPercentSigns: true,
                    useGroupingId: true,
                });
            } else if (
                foundChart.dataEndpoint ===
                SignalsModels.AverageScoreByQuestionOverTimeID
            ) {
                populateDataGrid({
                    responseData: dataToUse,
                    rowField: "questionText",
                    dataGridStore: foundChart.dataGridStore,
                    initColCount: 2,
                    selectedRowIds,
                    addPercentSigns: true,
                });
            } else if (
                foundChart.dataEndpoint ===
                SignalsModels.EEPrevalenceByQuestionOverTimeID
            ) {
                populateDataGrid({
                    responseData: dataToUse,
                    rowField: "value",
                    dataGridStore: foundChart.dataGridStore,
                    initColCount: 4,
                    selectedRowIds,
                });
            } else if (
                foundChart.dataEndpoint === SignalsModels.ClassifierOverTimeId
            ) {
                populateDataGrid({
                    responseData: dataToUse,
                    rowField: "name",
                    dataGridStore: foundChart.dataGridStore,
                    initColCount: 4,
                    selectedRowIds,
                });
            } else if (
                foundChart.dataEndpoint ===
                SignalsModels.HipaaCompliancePrevalenceOverTimeId
            ) {
                populateDataGrid({
                    responseData: dataToUse,
                    rowField: "id",
                    dataGridStore: foundChart.dataGridStore,
                    initColCount: 3,
                    selectedRowIds,
                });
            } else if (
                foundChart.dataEndpoint === SignalsModels.SEPrevalenceOverTimeID
            ) {
                populateDataGrid({
                    responseData: dataToUse,
                    rowField: "id",
                    dataGridStore: foundChart.dataGridStore,
                    initColCount: 3,
                    selectedRowIds,
                    useSEIdentifiedCount: true,
                });
            } else if (
                foundChart.dataEndpoint ===
                SignalsModels.TopicPrevalenceOverTimeID
            ) {
                populateDataGrid({
                    responseData: dataToUse,
                    rowField: "topicLabel",
                    dataGridStore: foundChart.dataGridStore,
                    initColCount: 3,
                    selectedRowIds,
                    useTotalConversationsForPercent: true,
                    sortByEstimatedPercent: true,
                    totalConversations: this.totalConversations,
                });
            } else if (
                foundChart.dataEndpoint ===
                SignalsModels.ContactTypePrevalenceOverTimeId
            ) {
                populateDataGrid({
                    responseData: dataToUse,
                    rowField: "label",
                    dataGridStore: foundChart.dataGridStore,
                    initColCount: 3,
                    selectedRowIds,
                    totalConversations: this.totalConversations,
                });
            } else if (
                foundChart.dataEndpoint ===
                SignalsModels.EddyByContactTypeOverTimeId
            ) {
                populateDataGrid({
                    responseData: dataToUse,
                    rowField: "label",
                    dataGridStore: foundChart.dataGridStore,
                    initColCount: 4,
                    selectedRowIds,
                    totalConversations: this.totalConversations,
                });
            }
        }

        const isComparePrev = !!prevPeriodEnd && !!prevPeriodStart;

        if (foundChart?.dataEndpoint === SignalsModels.EEOverTimeID) {
            const info = res.data.reduce(
                (count, data) => {
                    if (!data.y_totalcount) return count;
                    count.total += data.y_totalcount;
                    count.withEddy += data.y;
                    return count;
                },
                { total: 0, withEddy: 0 },
            );

            if (info.total !== 0) {
                this.eddyPercent = (info.withEddy / info.total) * 100;
            }
        }

        if (foundChart?.selectionType === "Topics") {
            res.data = res.data.map((d) => {
                d.y_percent = d.y / (this.totalConversations ?? 1);
                return d;
            });
        }

        if (foundChart?.showComparePrevCheckbox && !isComparePrev) {
            dataToUse = dataToUse.map((i) => {
                delete i.grouping;

                return i;
            });
        }

        if (
            foundChart?.dataEndpoint ===
            SignalsModels.AverageScoreByModuleOverTimeID
        ) {
            dataToUse = dataToUse.map((i, index) => {
                i.grouping = this.orgModules.find(
                    (m) => m.id === i.grouping_identifier,
                )?.displayName;
                return i;
            });
        }

        if (foundChart?.dataEndpoint === SignalsModels.TopicPrevalenceAndEEID) {
            dataToUse = dataToUse.sort((a, b) => {
                if (a.grouping === "Eddy Not Identified") {
                    return 1;
                } else {
                    return -1;
                }
            });
        }

        const { formattedData, formattedPercentData, seriesNames } =
            this.formatDataForChart(chartId, dataToUse, isComparePrev);

        if (foundChart) {
            foundChart.countData = formattedData;
            foundChart.data =
                foundChart.renderMode === "%"
                    ? formattedPercentData
                    : formattedData;
            foundChart.percentData = formattedPercentData;
            foundChart.xTitle = res.xTitle;
            foundChart.yTitle =
                res.yTitle + (foundChart.renderMode === "%" ? " %" : "");
            // foundChart.chartTitle = res.chartTitle;
            foundChart.seriesNames = Array.from(seriesNames);
        }
    }

    formatDataForChart(
        chartId: string,
        dataToUse: SignalReportDatum[],
        isComparePrev?: boolean,
    ) {
        const chartDataModel = this.allChartData.find((c) => c.id === chartId);

        const tempGrain = temporalGrain(
            this.applicationFiltersStore.quickFiltersStore.startDate,
            this.applicationFiltersStore.quickFiltersStore.endDate,
        );

        const countTooltipBuilder = (x, y, series) => (
            <Grid style={{ padding: "1rem" }}>
                <Typography variant="h2">{series}</Typography>
                <span
                    style={{
                        fontSize: "32px",
                        color: theme.palette.blue.main,
                    }}
                >
                    {Number(y).toLocaleString()}
                </span>
                <Typography variant="body1">
                    {chartDataModel?.isTemporal
                        ? this.getXDateRangeLabel(x, tempGrain)
                        : x}
                </Typography>
            </Grid>
        );

        const percentTooltipBuilder = (x, y, series) => (
            <Grid style={{ padding: "1rem" }}>
                <Typography variant="h2">{series}</Typography>
                <span
                    style={{
                        fontSize: "32px",
                        color: theme.palette.blue.main,
                    }}
                >
                    {Number(y).toFixed(1)}%
                </span>
                <Typography variant="body1">
                    {chartDataModel?.isTemporal
                        ? this.getXDateRangeLabel(x, tempGrain)
                        : x}
                </Typography>
            </Grid>
        );

        const count = signalDataToGoogleFormat({
            data: dataToUse,
            tooltipBuilder: countTooltipBuilder,
            isComparePrev,
        });

        const percent = signalDataToGoogleFormat({
            data: dataToUse.map((item) => {
                item.y = item.y_percent * 100;
                return item;
            }),
            showPercent: true,
            tooltipBuilder: percentTooltipBuilder,
            isComparePrev,
        });

        return {
            formattedData: count.data,
            formattedPercentData: percent.data,
            seriesNames: count.seriesNames,
        };
    }

    async setDefaultClassifiers(chartId: string) {
        const chartDataModel = this.allChartData.find((c) => c.id === chartId);
        if (!chartDataModel) return;

        const topicsClassifiers = this.userClassifiers.filter(
            (c) =>
                c.classifierCategory?.name ===
                    this.defaultClassifierCategory?.name ?? "",
        );

        const vizOptions = JSON.parse(chartDataModel.vizOptions ?? "{}");

        if (chartDataModel?.dataGridStore) {
            let columns: IColDef[] = [];

            if (
                chartDataModel.dataEndpoint ===
                SignalsModels.EEByClassifiersOverTimeID
            ) {
                columns = getSignalsColumns("Classifier With Eddy");
            } else {
                columns = getSignalsColumns("Classifier Prevalence");
            }

            chartDataModel.dataGridStore.setColumns(columns);
            chartDataModel.dataGridStore.rows = this.userClassifiers;

            if (
                !chartDataModel.isDashboardReport &&
                !!topicsClassifiers.length
            ) {
                chartDataModel.dataGridStore.rows = topicsClassifiers;
            }
        }

        if (vizOptions.defaultSelectedIds) {
            await this.updateSelectedClassifiers(
                chartId,
                vizOptions.defaultSelectedIds,
            );
        } else {
            this.updateSelectedClassifierCategory(
                chartId,
                this.defaultClassifierCategory,
            );

            await this.updateSelectedClassifiers(
                chartId,
                this.defaultClassifierIds,
            );
        }
    }

    async setDefaultTopics(chartId: string) {
        const chartDataModel = this.allChartData.find((c) => c.id === chartId);

        if (chartDataModel?.showDataGrid && chartDataModel?.dataGridStore) {
            chartDataModel.dataGridStore.rows = this.topics;
            chartDataModel.dataGridStore.setColumns(
                getSignalsColumns("Topics"),
            );
        }

        const vizOptions = JSON.parse(chartDataModel?.vizOptions ?? "{}");

        await chartDataModel?.dataGridStore.handleSelectionChange(
            vizOptions?.defaultSelectedIds ?? this.defaultTopicIds,
        );
    }

    async getDefaultClassifiersForOrg() {
        const defaultCategoryName = "Topics";

        const topicsCategory = this.userClassifierCategories?.find(
            (c) => c?.name === defaultCategoryName,
        );

        if (topicsCategory) {
            this.defaultClassifierCategory = topicsCategory;
        }

        const defaultClassifierIds = this.userClassifiers
            .map((c) => c.id)
            .slice(0, this.maxSelectionCount);

        const topicsClassifierIds = this.userClassifiers
            .filter((c) => c.classifierCategory?.name === defaultCategoryName)
            .map((c) => c.id)
            .slice(0, this.maxSelectionCount);

        if (topicsClassifierIds.length) {
            this.defaultClassifierIds = topicsClassifierIds;
        } else {
            this.defaultClassifierIds = defaultClassifierIds;
        }
    }

    public setHipaaDefaults(chartId: string) {
        const hippaChart = this.renderedChartData.find((c) => c.id === chartId);
        if (!hippaChart) return;

        hippaChart.dataGridStore.setColumns(getSignalsColumns("Hipaa"));
        hippaChart.dataGridStore.rows = [
            {
                id: "Compliant",
                hipaaResult: "HIPAA Compliant",
            },
            {
                id: "Not Compliant",
                hipaaResult: "Not HIPAA Compliant",
            },
            {
                id: "Not Applicable",
                hipaaResult: "N/A",
            },
        ];
        return hippaChart.dataGridStore.handleSelectionChange([
            "Compliant",
            "Not Compliant",
            "Not Applicable",
        ]);
    }

    private setSEPrevalenceOverTimeDefaults(chartId: string) {
        const sePrevChart = this.allChartData.find((c) => c.id === chartId);
        if (!sePrevChart) return;

        sePrevChart.dataGridStore.setColumns(
            getSignalsColumns("Safety Events"),
        );
        sePrevChart.dataGridStore.rows = [
            {
                id: "Identified",
                safetyEventResult: "Identified",
            },
            {
                id: "Not Identified",
                safetyEventResult: "Not Identified",
            },
            {
                id: "Acknowledged",
                safetyEventResult: "Acknowledged",
            },
            {
                id: "Not Acknowledged",
                safetyEventResult: "Not Acknowledged",
            },
        ];
        return sePrevChart.dataGridStore.handleSelectionChange([
            "Identified",
            "Not Identified",
            "Acknowledged",
            "Not Acknowledged",
        ]);
    }

    private setContactTypeDefaults(chartId: string) {
        const contactTypePrevChart = this.allChartData.find(
            (c) => c.id === chartId,
        );
        if (!contactTypePrevChart) return;

        if (chartId === SignalsModels.EddyByContactTypeOverTimeId) {
            contactTypePrevChart.dataGridStore.setColumns(
                getSignalsColumns("Eddy by Contact Type"),
            );
        } else {
            contactTypePrevChart.dataGridStore.setColumns(
                getSignalsColumns("Contact Type"),
            );
        }

        const sourceNames = contactTypes;

        contactTypePrevChart.dataGridStore.rows = sourceNames.map((i) => {
            return {
                id: i,
                label:
                    this.customerTypes.find((j) => j.sourceName === i)?.name ??
                    i,
            };
        });
        return contactTypePrevChart.dataGridStore.handleSelectionChange(
            sourceNames,
        );
    }

    getDefaultModuleId() {
        let moduleIdToUse;
        const qualityModuleId = this.orgModules.find(
            (item) => item.name === "Quality",
        )?.id;

        if (qualityModuleId) {
            moduleIdToUse = qualityModuleId;
        } else {
            const sortedModules = this.orgModules.sort((a, b) => {
                return a.name.localeCompare(b.name);
            });
            moduleIdToUse = sortedModules[0]?.id;
        }
        this.defaultModuleId = moduleIdToUse;
    }

    async setDefaultModuleAndQuestions(chartId: string) {
        const chartDataModel = this.allChartData.find((c) => c.id === chartId);

        if (!chartDataModel) return;

        const vizOptions = JSON.parse(chartDataModel.vizOptions ?? "{}");

        let questions: Question[] =
            this.orgModules.find((lm) => lm.id === this.defaultModuleId)
                ?.questions ?? [];

        if (chartDataModel && chartDataModel.dataGridStore) {
            if (chartDataModel.selectionType === "Question") {
                if (vizOptions.defaultSelectedIds) {
                    const selectedQuestionId = vizOptions.defaultSelectedIds[0];
                    const defaultModule = this.orgModules.find((lm) =>
                        lm.questions.find((q) => q.id === selectedQuestionId),
                    );
                    this.updateSelectedModule(chartId, defaultModule?.id);

                    const selectedQuestion = defaultModule?.questions.find(
                        (q) => q.id === selectedQuestionId,
                    );
                    this.selectedQuestionByChartId[chartId] = selectedQuestion;
                    chartDataModel.dataGridStore.rows =
                        defaultModule?.questions ?? [];
                    await chartDataModel.dataGridStore.handleSelectionChange([
                        selectedQuestion?.id ?? "",
                    ]);
                } else {
                    this.updateSelectedModule(chartId, this.defaultModuleId);
                    const firstQuestion = questions.slice(0, 1)[0];
                    this.selectedQuestionByChartId[chartId] = firstQuestion;
                    chartDataModel.dataGridStore.rows = questions;
                    await chartDataModel.dataGridStore.handleSelectionChange([
                        firstQuestion?.id ?? "",
                    ]);
                }
            } else if (chartDataModel.selectionType === "Tags") {
                if (vizOptions.defaultSelectedIds) {
                    const firstTagId = vizOptions.defaultSelectedIds[0];
                    const defaultModule = this.orgModules.find((lm) =>
                        lm.questions.find((q) =>
                            q.tags?.find((t) => t.id === firstTagId),
                        ),
                    );
                    this.updateSelectedModule(chartId, defaultModule?.id);
                    const defaultQuestion = defaultModule?.questions.find((q) =>
                        q.tags?.find((t) => t.id === firstTagId),
                    );

                    this.selectedQuestionByChartId[chartId] = defaultQuestion;

                    chartDataModel.dataGridStore.setColumns(
                        getSignalsColumns("Question Response"),
                    );
                    chartDataModel.dataGridStore.rows =
                        defaultQuestion?.tags ?? [];
                    await chartDataModel.dataGridStore.handleSelectionChange(
                        vizOptions.defaultSelectedIds ?? [],
                    );
                } else {
                    this.updateSelectedModule(chartId, this.defaultModuleId);
                    const firstQuestion = questions.slice(0, 1)[0];
                    this.selectedQuestionByChartId[chartId] = firstQuestion;

                    const tagIds = firstQuestion?.tags
                        ?.map((t) => t.id)
                        .slice(0, this.maxSelectionCount);

                    chartDataModel.dataGridStore.setColumns(
                        getSignalsColumns("Question Response"),
                    );
                    chartDataModel.dataGridStore.rows =
                        firstQuestion?.tags ?? [];
                    await chartDataModel.dataGridStore.handleSelectionChange(
                        tagIds ?? [],
                    );
                }
            } else if (chartDataModel.selectionType === "Modules") {
                chartDataModel.dataGridStore.setColumns(
                    getSignalsColumns("Module Score"), // update columns
                );
                chartDataModel.dataGridStore.rows = this.orgScoredModules;
                await chartDataModel.dataGridStore.handleSelectionChange(
                    vizOptions.defaultSelectedIds ??
                        this.orgScoredModules
                            .slice(0, this.maxSelectionCount)
                            .map((m) => m.id ?? ""),
                );
            } else if (chartDataModel.selectionType === "Questions") {
                if (vizOptions.defaultSelectedIds) {
                    const firstQuestionId = vizOptions.defaultSelectedIds[0];
                    const defaultModule = this.orgModules.find((lm) =>
                        lm.questions.find((q) => q.id === firstQuestionId),
                    );
                    this.updateSelectedModule(chartId, defaultModule?.id);
                    chartDataModel.dataGridStore.setColumns(
                        getSignalsColumns("Question"), // update columns
                    );
                    chartDataModel.dataGridStore.rows =
                        defaultModule?.questions ?? [];
                    await chartDataModel.dataGridStore.handleSelectionChange(
                        vizOptions.defaultSelectedIds,
                    );
                } else {
                    this.updateSelectedModule(chartId, this.defaultModuleId);
                    chartDataModel.dataGridStore.setColumns(
                        getSignalsColumns("Question"), // update columns
                    );
                    chartDataModel.dataGridStore.rows = questions;
                    await chartDataModel.dataGridStore.handleSelectionChange(
                        questions.map((q) => q.id ?? ""),
                    );
                }
            }
        }
    }

    getSelectedRowIds(chartId: string) {
        const chartDataModel = this.renderedChartData.find(
            (c) => c.id === chartId,
        );

        return (
            chartDataModel?.dataGridStore?.selectedRowIds
                .map((id) => id.toString())
                .slice(0, this.getMaxSelection(chartDataModel)) ?? []
        );
    }

    getMaxSelection = (
        chartDataModel: SignalsModels.ISignalsVizDefinition,
    ): number => {
        if (chartDataModel?.selectionType === "Question") {
            return 1;
        }

        return this.maxSelectionCount;
    };

    getXDateRangeLabel = (x, tempGrain: SignalsTemporalLabels) => {
        const startDateCopy = moment(
            this.applicationFiltersStore.quickFiltersStore.startDate,
        );
        const endDateCopy = moment(
            this.applicationFiltersStore.quickFiltersStore.endDate,
        );
        switch (tempGrain) {
            case "Daily":
                return x;
            case "Weekly":
                const firstDayWeekly =
                    moment(x) < startDateCopy ? startDateCopy : moment(x);
                const lastDayW = moment(x).add(6, "days");
                const lastDayToUse =
                    lastDayW > endDateCopy ? endDateCopy : lastDayW;
                return `${firstDayWeekly.format(
                    "MMM DD, YYYY",
                )} - ${lastDayToUse.format("MMM DD, YYYY")}`;
            case "Monthly":
                const firstDayM =
                    moment(x) < startDateCopy ? startDateCopy : moment(x);
                const lastDayM = moment(x).add(1, "month").subtract(1, "day");
                const lastDayToUseM =
                    lastDayM > endDateCopy ? endDateCopy : lastDayM;
                return `${firstDayM.format(
                    "MMM DD, YYYY",
                )} - ${lastDayToUseM.format("MMM DD, YYYY")}`;
            case "Quarterly":
                const firstDayQ =
                    getFirstDayOfQuarter(x) < startDateCopy
                        ? startDateCopy
                        : getFirstDayOfQuarter(x);

                const lastDayQ = getFirstDayOfQuarter(x)
                    .add(1, "quarter")
                    .subtract(1, "day");

                const lastDayToUseQ =
                    lastDayQ > endDateCopy ? endDateCopy : lastDayQ;

                return `${firstDayQ.format(
                    "MMM DD, YYYY",
                )} - ${lastDayToUseQ.format("MMM DD, YYYY")}`;
        }
    };

    public setAllRenderedChartLoading(value: boolean) {
        this.renderedChartData
            .filter((c) => c?.shouldReload)
            .map((c) => c.id)
            .forEach((id) => this.setTaskLoading(id, value));
    }

    public setAllChartLoading(value: boolean) {
        this.allChartData
            .map((c) => c.id)
            .forEach((id) => this.setTaskLoading(id, value));
    }

    // Card related functions
    @action
    public async getTotalConversations() {
        return await this.setupAsyncTask(
            TotalConversationsCardLoading,
            async () => {
                try {
                    const result =
                        await this.signalsService.getTotalConversations(
                            this.getFilters(),
                        );

                    runInAction(() => {
                        this.totalConversations = result.totalConversations;
                    });
                } catch (error: any) {
                    if (
                        error instanceof DOMException &&
                        error.name === "AbortError"
                    )
                        return;

                    this.totalConversations = undefined;
                    // Eat the error, we display N/A if undefined
                    // this.messageStore.logError(
                    //     "Total Conversations not available.",
                    // );
                }
            },
        );
    }

    @action
    public setAvgCallDurationFilter(eddyPresence: SignalsModels.EddyPresence) {
        this.averageCallDurationEddyFilter = eddyPresence;
    }

    @action
    public async getAvgCallDuration(eddyPresence: SignalsModels.EddyPresence) {
        return await this.setupAsyncTask(
            AverageCallDurationCardLoading,
            async () => {
                try {
                    const request = { ...this.getFilters(), eddyPresence };
                    const result = await this.signalsService.getAvgCallDuration(
                        request,
                    );

                    runInAction(() => {
                        this.averageCallDuration = result.averageCallDuration;
                    });
                } catch (error: any) {
                    if (
                        error instanceof DOMException &&
                        error.name === "AbortError"
                    )
                        return;

                    this.averageCallDuration = undefined;
                    // Eat the error, we display N/A if undefined
                    // this.messageStore.logError("Avg Call Duration not available.");
                }
            },
        );
    }

    public getAvgCallDurationFormatted() {
        if (!this.averageCallDuration) return "N/A";
        const seconds = this.averageCallDuration / 1000;
        const duration = moment.duration(seconds, "seconds");

        const formatted: string[] = [];
        if (duration.hours()) formatted.push(`${duration.hours()}h`);
        if (duration.minutes()) formatted.push(`${duration.minutes()}m`);
        if (duration.seconds()) formatted.push(`${duration.seconds()}s`);

        return formatted.join(" ");
    }

    public getTotalConversationsFormatted() {
        if (!this.totalConversations) return "N/A";
        return this.totalConversations.toLocaleString();
    }

    public getEddyPercentFormatted() {
        if (!this.eddyPercent) return "N/A";

        const asNumber = Number(this.eddyPercent);
        if (!isNaN(asNumber)) return `${asNumber.toFixed(2)}%`;
    }

    private createDataGridStoreForChart = (
        chartId: string,
        dataEndpoint: SignalsModels.SignalDataEndpoint,
    ) => {
        const dataGridStore = new AcxDataGridStore(chartId, "Signals");

        dataGridStore.onChange = async () => {
            if (dataEndpoint === SignalsModels.EEOverTimeID) return;

            if (
                dataEndpoint ===
                    SignalsModels.HipaaCompliancePrevalenceOverTimeId ||
                dataEndpoint === SignalsModels.SEPrevalenceOverTimeID ||
                dataEndpoint ===
                    SignalsModels.ContactTypePrevalenceOverTimeId ||
                dataEndpoint === SignalsModels.EddyByContactTypeOverTimeId
            ) {
                // show/hide data based on selected model results w/o network req
                const renderedData = this.renderedChartData.find(
                    (c) => c.id === chartId,
                );
                if (!renderedData) return;

                if (
                    dataEndpoint ===
                        SignalsModels.ContactTypePrevalenceOverTimeId ||
                    dataEndpoint === SignalsModels.EddyByContactTypeOverTimeId
                ) {
                    renderedData.visibleSeries =
                        dataGridStore.selectedRowIds.map(
                            (rowId) =>
                                this.customerTypes.find(
                                    (i) => i.sourceName === rowId,
                                )?.name ?? rowId.toString(),
                        );
                } else {
                    renderedData.visibleSeries =
                        dataGridStore.selectedRowIds as string[];
                }
                return;
            }

            return await this.getChartDataAndAISummary(chartId);
        };

        dataGridStore.removeHeight = "54px";
        dataGridStore.setHideVertIcon(true);
        return dataGridStore;
    };

    @computed
    get allChartData() {
        return [
            ...this.nonDashChartData,
            ...this.allDashboardsUserCanView.flatMap(
                (d) => d.signalsVizDefinitions,
            ),
        ];
    }

    addToDashboard = async () => {
        if (!this.selectedNewChart) return;
        const { dataEndpoint, id, chartTitle, chartSubtitle } =
            this.selectedNewChart;

        const dataModel = this.allChartData.find((c) => c.id === id);

        const parsedVizOptions = JSON.parse(dataModel?.vizOptions ?? "{}");

        parsedVizOptions.defaultSelectedIds = this.getSelectedRowIds(id);

        await this.signalsService.createVizDefinition({
            dashboardId: this.dashboardForNewReport?.id ?? "",
            dataEndpoint: dataEndpoint,
            chartTitle,
            chartSubtitle,
            vizOptions:
                dataEndpoint === SignalsModels.EEOverTimeID
                    ? "{}"
                    : JSON.stringify(parsedVizOptions),
            xTitle: dataModel?.xTitle ?? "",
            yTitle: dataModel?.yTitle ?? "",
            order: dataModel?.order.toString() ?? "1",
        });
    };

    @action
    removeChartFromDashboard = async (id: string) => {
        try {
            const delRes = await this.signalsService.deleteVizDefinition({
                id,
            });

            this.renderedChartData = this.renderedChartData.filter(
                (cd) => cd.id !== id,
            );
            this.refreshDashboards();
            return delRes;
        } catch (e) {
            this.messageStore.logMessage("Failed to delete report", "error");
        }
    };

    @action
    refreshDashboards = async () => {
        return this.setupAsyncTask(LoadDashboards, async () => {
            try {
                const userDashRes =
                    await this.signalsService.getUserDashboards();

                userDashRes.forEach((cd) => {
                    cd.signalsVizDefinitions = cd.signalsVizDefinitions.filter(
                        (viz) => {
                            if (
                                !this.authStore.canUserView("Topics") &&
                                SignalsModels.getIsTopicReport(viz.dataEndpoint)
                            ) {
                                return false;
                            }
                            if (
                                !this.authStore.canUserView("Trends") &&
                                SignalsModels.getIsTrendReport(viz.dataEndpoint)
                            ) {
                                return false;
                            }

                            if (
                                !this.authStore.canUserView("Contacts") &&
                                SignalsModels.getIsContactReport(
                                    viz.dataEndpoint,
                                )
                            ) {
                                return false;
                            }

                            if (
                                !this.authStore.canUserView(
                                    "HIPAA Compliance Model",
                                ) &&
                                viz.dataEndpoint ===
                                    "HipaaCompliancePrevalenceOverTime"
                            ) {
                                return false;
                            }

                            if (
                                !this.authStore.canUserView(
                                    "Safety Events Model",
                                ) &&
                                viz.dataEndpoint ===
                                    "SafetyEventPrevalenceOverTime"
                            ) {
                                return false;
                            }

                            return true;
                        },
                    );
                    cd.signalsVizDefinitions.forEach((item) => {
                        this.setVizDefDefaults(item);
                    });
                });
                this.allDashboardsUserCanView = userDashRes;

                return userDashRes;
            } catch (e) {
                console.error(JSON.stringify(e));
            }
        });
    };

    @action
    updateVizDefinition = async (
        chartId: string,
        dashboardId: string,
        vizOptions: any,
        chartTitle: string,
        chartSubtitle: string,
        order: number,
    ) => {
        const chartDataModel = this.renderedChartData.find(
            (c) => c.id === chartId,
        );
        if (!chartDataModel) return;

        if (vizOptions.vAxisFontSize) {
            vizOptions.vAxisFontSize = vizOptions.vAxisFontSize.toString();
        }

        try {
            const res = await this.signalsService.updateVizDefinition({
                id: chartId,
                dashboardId,
                vizOptions: JSON.stringify(vizOptions),
                dataEndpoint: chartDataModel?.dataEndpoint,
                chartTitle,
                chartSubtitle,
                order,
            });
            chartDataModel.vizOptions = res.vizOptions;
            chartDataModel.chartTitle = res.chartTitle;
            chartDataModel.chartSubtitle = res.chartSubtitle;

            if (chartDataModel.order !== res.order) {
                chartDataModel.order = res.order;
                this.renderedChartData.sort((a, b) => {
                    return a.order - b.order;
                });
            }

            this.messageStore.logMessage(
                "Successfully updated report.",
                "success",
            );
        } catch (e) {
            this.messageStore.logMessage("Update report failed.", "error");
        }
    };

    @action
    populateChartConfigDrawer = (chartId: string) => {
        const dataModel = this.renderedChartData.find(
            (cd) => cd.id === chartId,
        );
        if (!dataModel) return;
        const drawerStore = this.rootStore.getStore(LayoutDrawerStore);
        drawerStore.closeAndResetDrawer();
        drawerStore.restoreDefaults();

        const dashboardId = this.allDashboardsUserCanView.find((dash) => {
            return dash.signalsVizDefinitions
                .map((def) => def.id)
                .includes(chartId);
        })?.id;

        if (!dashboardId) return;

        this.currentVizOptions = JSON.parse(dataModel.vizOptions);

        drawerStore.setContentFactory(() => (
            <SignalsVizOptions
                chartType={dataModel.chartType}
                chartTitle={dataModel.chartTitle}
                chartId={chartId}
                dashboardId={dashboardId}
                vizOptions={this.currentVizOptions}
                subtitle={dataModel.chartSubtitle}
                order={dataModel.order}
            />
        ));

        drawerStore.setOpen(true);
    };

    setVizDefDefaults = (item: SignalsModels.ISignalsVizDefinition) => {
        item.dataGridStore = this.createDataGridStoreForChart(
            item.id,
            item.dataEndpoint,
        );
        item.shouldReload = true;

        item.renderMode = SignalsModels.getDefaultRenderMode(item.dataEndpoint);
        item.chartType = SignalsModels.getChartTypeFromChartID(
            item.dataEndpoint,
        );
        item.selectionType = SignalsModels.getSelectionType(item.dataEndpoint);
        item.showComparePrevCheckbox = SignalsModels.getComparePrevControls(
            item.dataEndpoint,
        );
        item.showPercentToggle = SignalsModels.getPercentToggleControls(
            item.dataEndpoint,
        );
        item.showDataGrid = SignalsModels.getDataGridControls(
            item.dataEndpoint,
        );
        item.isTemporal = SignalsModels.getIsTemporal(item.dataEndpoint);
        item.showSpotlight = SignalsModels.getShowSpotlight(item.dataEndpoint);
        item.isStackedBar = SignalsModels.getIsStackedBar(item.dataEndpoint);
        item.isDashboardReport = true;
        return item;
    };

    @action
    updateDashboard = async (
        newTitle: string,
        newDashVis: SignalsModels.IDashboardVisibility,
        logoName?: string,
    ) => {
        try {
            if (!this.currentDashboard?.id) return;

            const updateDashRes = await this.signalsService.updateDashboard({
                id: this.currentDashboard?.id,
                title: newTitle,
                visibility: newDashVis,
                logoName,
            });

            await this.refreshDashboards();

            this.currentDashboard.title = updateDashRes.title;
            this.currentDashboard.visibility = updateDashRes.visibility;
            this.currentDashboard.logoName = updateDashRes.logoName;

            this.messageStore.logMessage("Dashboard updated", "success");
        } catch (e) {
            console.error(JSON.stringify(e));
            this.messageStore.logMessage("Failed to update dashboard", "error");
        }
    };

    @action
    duplicateDashboard = async (
        newTitle: string,
        newDashVis: SignalsModels.IDashboardVisibility,
        logoName?: string,
    ) => {
        try {
            const dashCreateRes = await this.signalsService.createDashboard({
                title: newTitle,
                visibility: newDashVis,
                logoName,
            });
            await this.signalsService.addVizDefinitionsToDashboard(
                dashCreateRes.id,

                this.currentDashboard?.signalsVizDefinitions?.map((i) =>
                    toJS(i),
                ) ?? [],
            );
            await this.refreshDashboards();

            this.messageStore.logMessage("Dashboard duplicated", "success");

            return dashCreateRes;
        } catch (e) {
            console.error(JSON.stringify(e));
            this.messageStore.logMessage(
                "Failed to duplicate dashboard",
                "error",
            );
        }
    };

    @computed
    get dashboardIsEditable() {
        return (
            this.authStore._user.profile.sub === this.currentDashboard?.userId
        );
    }
}

export default SignalsReportStore;
