import { Grid, Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import React, { useEffect, useRef, useState } from "react";
import useStyles from "Styles/Styles";
import theme from "Theme/AppTheme";
import usePeaks, { ElementRef } from "./usePeaks";

type Props = {
    audioLocation: string;
    audioBlob?: Blob;
    audioType: string;
    points?: number[];
    onUpdateCurrentPlayingTime?: (arg0: number) => void;
};

const styles = (theme: Theme) =>
    createStyles({
        root: {
            height: "64px",
            padding: "16px 24px",
            backgroundColor: theme.palette.secondary.main,
        },
        inlay: {
            borderRadius: "4px",
            backgroundColor: "#71AAF8",
            color: theme.palette.white.main,
        },
        peaks: {
            width: "95%",
        },
        controls: {
            width: "5%",
        },
        controlButton: {
            cursor: "pointer",
            display: "block",
            margin: "auto",
        },
    });

function InlineAudioPlayer(props: Props) {
    const _isMounted = useRef(true); // Initial value _isMounted = true

    useEffect(() => {
        return () => {
            // ComponentWillUnmount in Class Component
            _isMounted.current = false;
        };
    }, []);

    const [isPlaying, setIsPlaying] = useState(false);
    const [overView, setOverView] = useState<ElementRef>(null);
    const [zoomView, setZoomView] = useState<ElementRef>(null);
    const [mediaElement, setMediaElement] = useState<ElementRef>(null);

    const peaksOptions = {
        waveformColor: theme.palette.white.main.toString(),
        pointMarkerColor: "rgba(0,0,0,0)",
        axisLabelColor: "rgba(0,0,0,0)",
        axisGridlineColor: "rgba(0,0,0,0)",
        editable: false,
        segmentColor: theme.palette.orange.main.toString(),
        showPlayheadTime: true,
        height: 30,
    };

    const peaksInstance = usePeaks(
        overView,
        zoomView,
        mediaElement,
        peaksOptions,
        props.points,
    );

    const { onUpdateCurrentPlayingTime } = props;
    if (onUpdateCurrentPlayingTime !== undefined && _isMounted.current) {
        peaksInstance?.on("points.enter", (point) =>
            onUpdateCurrentPlayingTime(point.time),
        );
        peaksInstance?.on("player_seek", onUpdateCurrentPlayingTime);
    }

    function onPlayClick() {
        if (isPlaying) {
            peaksInstance?.player.pause();
            setIsPlaying(false);
        } else {
            peaksInstance?.player.play();
            setIsPlaying(true);
        }
    }

    function _renderPlayControl() {
        if (isPlaying) {
            return (
                <PauseCircleFilledIcon
                    onClick={onPlayClick}
                    className={classes.controlButton}
                />
            );
        }
        return (
            <PlayCircleFilledIcon
                onClick={onPlayClick}
                className={classes.controlButton}
            />
        );
    }

    const PeaksContainer = (
        <div>
            <div id="peaks-container">
                <div id="zoomview-container" ref={setZoomView}></div>
                <div id="overview-container" ref={setOverView}></div>
            </div>
            <audio ref={setMediaElement}>
                <source src={props.audioLocation} type={props.audioType} />
            </audio>
        </div>
    );

    const classes = useStyles(styles);

    return (
        <div className={classes.root}>
            <Grid
                container
                className={classes.inlay}
                alignItems="center"
                justifyContent="center"
                spacing={1}
            >
                <Grid item className={classes.controls}>
                    {_renderPlayControl()}
                </Grid>
                <Grid item className={classes.peaks}>
                    {PeaksContainer}
                </Grid>
            </Grid>
        </div>
    );
}

export default InlineAudioPlayer;
