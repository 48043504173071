import {
    Button,
    CircularProgress,
    SxProps,
    Theme,
    Tooltip,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import React, { CSSProperties } from "react";
import hexToRGB from "utils/hexToRGB";

interface CustomCSSProperties extends React.CSSProperties {
    [key: string]: any;
}

export interface IAcxButtonProps {
    onClick?: () => void;
    buttonDisabled?: boolean;
    color?: "inherit" | "primary" | "secondary" | "default" | undefined;
    children?: any;
    size?: "small" | "medium" | "large" | undefined;
    tooltip?: string;
    fullWidth?: boolean;
    loading?: boolean;
    marginRightAuto?: boolean;
    marginLeftAuto?: boolean;
    rootStyle?: CSSProperties;
    buttonClass?: string;
    // Adding className allows us to use the styled utility on this component
    className?: string;
    containerStyle?: Object;
    leftRightSpacing?: number;
    startIcon?: React.ReactNode;
    height?: string;
    id?: string;
    customRootStyles?: CustomCSSProperties;
    variant?: "outlined" | "contained" | "text";
    styles?: CSSProperties;
    sx?: SxProps<Theme>;
}

const useButtonStyles = makeStyles((theme) => ({
    root: (props: IAcxButtonProps) => ({
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        color: props.color === "default" ? "black" : theme.palette.white.main,
        fontFamily: theme.typography.fontFamily,
        fontWeight: "bold",
        letterSpacing: 0,
        textAlign: "center",
        lineHeight: "20px",
        fontSize: "13px",
        textTransform: "none",
        marginLeft: theme.spacing(props.leftRightSpacing!),
        marginRight: theme.spacing(props.leftRightSpacing!),
        height: 30,
        "&:hover": {
            backgroundColor: ["primary", "secondary"].includes(
                props.color as any,
            )
                ? theme.palette[props.color as any].light
                : hexToRGB(theme.palette.secondary.main, 0.5),
        },
        "&:disabled": {
            backgroundColor: ["primary", "secondary"].includes(
                props.color as any,
            )
                ? hexToRGB(theme.palette[props.color as any].main, 0.25)
                : hexToRGB(theme.palette.secondary.main, 0.25),

            color: theme.palette.white.main,
        },
    }),
    circle: {
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        margin: "5px",
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -10,
        marginLeft: -10,
    },
    buttonProgressPrimary: {
        color: theme.palette.primary.main,
    },
    buttonProgressSecondary: {
        color: theme.palette.secondary.main,
    },
    buttonProgressLight: {
        color: theme.palette.white.main,
    },
    disabled: {
        opacity: 0.4,
        color: theme.palette.secondary.dark,
    },
}));

const AcxButton = React.forwardRef<
    React.ComponentRef<typeof Button>,
    IAcxButtonProps
>(function AcxButton(props: IAcxButtonProps, ref) {
    const classes = useButtonStyles(props);
    props.leftRightSpacing ??= 3;
    props.buttonDisabled ??= false;
    props.color ??= "primary";
    props.children ??= "Button";
    props.fullWidth ??= true;
    props.loading ??= false;

    function getLoadingClass() {
        if (props.color === "primary") {
            return classes.buttonProgressSecondary;
        } else if (props.color === "secondary") {
            return classes.buttonProgressPrimary;
        } else {
            return classes.buttonProgressLight;
        }
    }
    function getStyle() {
        const obj: Object = {};
        if (props.marginRightAuto) {
            obj["marginRight"] = "auto";
        }
        if (props.marginLeftAuto) {
            obj["marginLeft"] = "auto";
        }
        if (props.height) {
            obj["height"] = props.height;
        }
        Object.assign(obj, props.rootStyle);
        return obj;
    }
    function getContainerStyle() {
        const obj: Object = { position: "relative", display: "flex" };
        if (props.containerStyle) {
            Object.assign(obj, props.containerStyle);
        }
        if (props.fullWidth) {
            Object.assign(obj, { width: "100%" });
        }
        return obj;
    }
    return (
        <Tooltip
            title={props.tooltip || (props.buttonDisabled ? "Disabled" : "")}
            arrow
        >
            <span style={getContainerStyle()}>
                <Button
                    id={props?.id}
                    style={
                        props.customRootStyles
                            ? { ...props.customRootStyles, ...getStyle() }
                            : getStyle()
                    }
                    size={props.size ? props.size : undefined}
                    onClick={props.onClick}
                    variant={props.variant ? props.variant : "contained"}
                    disableElevation
                    disabled={props.buttonDisabled || props.loading}
                    fullWidth={props.fullWidth}
                    className={clsx(props.className, props.buttonClass)}
                    classes={{ disabled: classes.disabled }}
                    startIcon={props.startIcon}
                    ref={ref}
                    sx={[
                        (theme) => ({
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            color:
                                props.color === "default"
                                    ? "black"
                                    : theme.palette.white.main,
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: "bold",
                            letterSpacing: 0,
                            textAlign: "center",
                            lineHeight: "20px",
                            fontSize: "13px",
                            textTransform: "none",
                            marginLeft: theme.spacing(props.leftRightSpacing!),
                            marginRight: theme.spacing(props.leftRightSpacing!),
                            height: 30,
                            "&:hover": {
                                backgroundColor: hexToRGB(
                                    theme.palette.primary.main,
                                    0.5,
                                ),
                            },
                            "&:disabled": {
                                backgroundColor: hexToRGB(
                                    theme.palette.primary.main,
                                    0.5,
                                ),
                                color: theme.palette.white.main,
                            },
                            ...props.styles,
                            ...props.customRootStyles,
                        }),
                        ...(props.sx
                            ? Array.isArray(props.sx)
                                ? props.sx
                                : [props.sx]
                            : []),
                    ]}
                >
                    {props.children}
                </Button>
                {props.loading && (
                    <CircularProgress
                        size={20}
                        className={clsx(
                            classes.buttonProgress,
                            getLoadingClass(),
                        )}
                    />
                )}
            </span>
        </Tooltip>
    );
});
export default AcxButton;

AcxButton.defaultProps = {
    buttonDisabled: false,
    color: "primary",
    children: "Button",
    fullWidth: true,
    loading: false,
    leftRightSpacing: 3,
};
