import { Grid } from "@mui/material";
import AcxMainTextField from "components/UI/AcxMainTextField";
import { Observer } from "mobx-react";
import React from "react";
// import useStyles from "../../../../Styles/Styles";
import { useStore } from "../../../../utils/useStore";
import { TemplateStore } from "./TemplateStore";

// const styles = (theme: Theme) => createStyles({
//     radioLabel: {
//         marginRight: theme.spacing(1),
//         marginLeft: theme.spacing(1),
//         fontSize: "13px"
//     }
// });

export interface IMetadataView {}
export default function MetadataView(props: IMetadataView) {
    // const classes = useStyles(styles);
    const tStore = useStore(TemplateStore);

    function onDelimiterChange(evt: React.ChangeEvent<HTMLInputElement>) {
        tStore.updateModelProp("delimiter", evt.target.value);
    }

    return (
        <Observer>
            {() => (
                <Grid container spacing={1}>
                    <Grid item xs={8}>
                        <AcxMainTextField
                            value={tStore.model.sampleString}
                            id={"sample-string"}
                            labelText="Enter metadata sample: filename or csv record"
                        />
                    </Grid>
                    <Grid item container xs={1} justifyContent="center">
                        <AcxMainTextField
                            onChange={onDelimiterChange}
                            value={tStore.model.delimiter}
                            id={"meta-delimiter"}
                            labelText="Delimiter"
                        />
                    </Grid>
                </Grid>
            )}
        </Observer>
    );
}
