import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import TranscriptionVisualStore from "../../TranscriptionVisualStore";
import TranscriptionVisualClassifiersView from "./TranscriptionVisualClassifiersView";

const TranscriptionVisualClassifiersController = observer(() => {
    const tvStore = useContext(TranscriptionVisualStore);

    return (
        <TranscriptionVisualClassifiersView
            classifiers={tvStore.displayClassifiers}
            currentExpanded={tvStore.expandedClassifier}
            onChangeExpansion={tvStore.handleExpansionChange}
            isLoading={tvStore.classifiersLoading}
        />
    );
});

export default TranscriptionVisualClassifiersController;
