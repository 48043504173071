import type {
    GridSize,
    GridProps
} from "@mui/material";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import {
    getGridDateOperators,
    getGridNumericColumnOperators,
    getGridStringOperators,
    GridRowParams,
} from "@mui/x-data-grid-pro";
import type {
    GridApiRef,
    GridColDef,
    GridFilterModel,
    GridRowData,
    GridRowId,
    GridRowModel,
    GridSelectionModel,
    GridSortModel,
} from "@mui/x-data-grid-pro";
import _, { isString } from "lodash";
import {
    action,
    computed,
    makeObservable,
    observable,
    reaction,
    runInAction,
} from "mobx";
import Papa from "papaparse";
import React from "react";
import LocalStorage from "stores/LocalStorage";
import RootStore from "stores/RootStore";
import { symmetricDifferenceBy } from "utils/SetAlgebraUtils";
import { isFunctionType } from "utils/TypeGuards";
import { StyledMenuLabel, type AcxMenuItemProps } from "../Menu/AcxMenu";
import { CUSTOM_FILTERS } from "./FilterOverride/CustomFilters";
import AcxFormatters from "./Formatters/AcxFormatters";
import IColDef from "./IColDef";
import { customHighPrioritySortComparator } from "./Formatters/AcxBooleanFormatter";

export interface SelectionContextOptions {
    itemName?: string;
    itemNamePluralized?: string;
    renderSelectedRow: (row: GridRowModel) => React.ReactNode;
    action?: React.ReactNode;
}

export interface CustomControlItem {
    controlElement: React.ReactElement;
    xs?: GridSize;
    sm?: GridSize;
    md?: GridSize;
    lg?: GridSize;
    xl?: GridSize;
    style?: React.CSSProperties;
}

export interface UnparseObject {
    data: [];
    fields: [];
}

class AcxDataGridStore {
    private formatTranslation = {
        chip: "tagFormatter",
        longString: "stringFormatter",
        linkFormatter: "linkFormatter",
        genericLinkFormatter: "genericLinkFormatter",
        dateFormatter: "dateFormatter",
        dateFormatterIgnoreTime: "dateFormatterIgnoreTime",
        dateTimeFormatter: "dateTimeFormatter",
        evalStatusFormatter: "statusFormatter",
        durationFormatter: "durationFormatter",
        arrayJoinFormatter: "arrayJoinFormatter",
        playerFormatter: "playerFormatter",
        hierarchyFormatter: "hierarchyFormatter",
        breadCrumbFormatter: "breadCrumbFormatter",
        arrayTagFormatter: "arrayTagFormatter",
        progressFormatter: "progressFormatter",
        percentFormatter: "percentFormatter",
        booleanFormatter: "booleanFormatter"
    };

    private filterTranslation = {
        evalStatusFormatter: "StatusFormatterFilter",
        linkFormatter: "LinkFormatterFilter",
        genericLinkFormatter: "LinkFormatterFilter",
        percentFormatter: "defaultFilter",
        durationFormatter: "durationFilter",
    };

    private filterOperatorsTranslation = {
        linkFormatter: "LinkFormatterFilterOperator",
        genericLinkFormatter: "LinkFormatterFilterOperator",
        durationFormatter: "DurationFormatterFilterOperators",
        dateTimeFormatter: "DateFormatterFilterOperators",
        dateFormatter: "DateFormatterFilterOperators",
        dateFormatterIgnoreTime: "DateFormatterFilterOperators",
        chip: "tagFormatter",
        percentFormatter: "percentFilterOperator",
        evalStatusFormatter: "StatusFormatterFilterOperators",
    };

    private comparatorTranslation = {
        arrayJoinFormatter: "arrayJoinComparator",
        breadCrumbFormatter: "arrayJoinComparator",
        arrayTagFormatter: "arrayJoinComparator",
        percentFormatter: "percentComparator",
        genericLinkFormatter: "genericLinkComparator",
        linkFormatter: "linkComparator",
        chip: "chipComparator",
        hierarchyFormatter: "chipComparator",
        dateTimeFormatter: "dateComparator",
        dateFormatter: "dateComparator",
        dateFormatterIgnoreTime: "dateComparator",
        durationFormatter: "durationComparator",
        booleanFormatter: 'booleanComparator'
    };

    @observable.ref
    headerColumnsStyle?: React.CSSProperties;

    @observable.ref
    controlsColumnStyle?: React.CSSProperties;

    @observable.ref
    controlMargin?: {
        marginTop?: string;
        marginRight?: string;
        marginBottom?: string;
        marginLeft?: string;
    };

    @observable.ref vertIconMenuItemsBuilder?:
        | AcxMenuItemProps[]
        | ((close?: () => void, navigate?) => AcxMenuItemProps[]);

    // Vertical menu vars
    @observable.ref
    vertIconMenuItems?: AcxMenuItemProps[];

    @observable
    private _columns: IColDef[];

    @observable
    private _cachedCols: GridColDef[];

    @observable
    menuAnchor: null | HTMLElement = null;

    @observable
    headerColumnSpan?: GridSize;

    @observable
    controlsColumnSpan?: GridSize;

    @observable
    controlsJustifyProperty?: GridProps["justifyContent"];

    @observable
    controlsAlignProperty?: GridProps["alignItems"];

    @observable
    headersJustifyProperty?: GridProps["justifyContent"];

    // Grid formatting options
    @observable
    removeHeight: string = "1px";

    @observable
    density: "compact" | "standard" | "comfortable";

    @observable
    checkboxSelection: boolean;

    @observable
    checkboxSelectionVisibleOnly: boolean;

    @observable
    isLoading: boolean;

    @observable
    appDomain: string = "AcxGridDefault";

    @observable
    gridId: string = "collumns";

    @observable
    rows: GridRowModel[] = observable.array<GridRowModel>([]);

    @observable
    loadedRows: GridRowModel[] = observable.array<GridRowModel>([]);

    @observable
    selectedRowIds = observable<GridRowId>([]);

    @observable
    selectedRows = observable<GridRowModel>([]);

    @observable
    sortModel: GridSortModel;

    @observable
    apiRef: GridApiRef;

    @observable
    filterColumn?: string | undefined;

    @observable
    filterIsOpen: boolean = false;

    @observable
    hideColumnsIsOpen: boolean = false;

    // final column array for grid
    @observable
    gridCols: GridColDef[] = [];

    @observable
    filtered: boolean = false;

    @observable
    hideFilter: boolean = false;

    @observable
    hideVertIcon: boolean = false;

    history?: any;

    formatters: AcxFormatters = new AcxFormatters();

    //props for controls and selection context expand box
    rowTerm?: React.ReactNode;
    title?: string;
    controls?: (React.ReactElement | CustomControlItem)[];
    selectionContextOptions?: SelectionContextOptions;
    preHeader?: React.ReactElement;

    // todo: implement skeleton rows
    skeletonRows?: number;

    disableLocalConfig = false;

    localStorage: LocalForage;

    disableMultipleColumnFiltering: boolean = true;

    saveFilterModel: boolean = false;

    @observable
    paginationSize: number = 50;

    @observable
    pagination?: boolean = false;

    @observable
    filterModel?: GridFilterModel;

    paginationMode?: "client" | "server";

    rowsPerPageOptions?: number[];

    @observable
    rowCount?: number;

    filterMode?: "client" | "server";

    sortingMode?: "client" | "server";

    filterRequest?: (input: GridFilterModel) => Promise<GridRowData[]>;

    sortRequest?: (input: GridSortModel) => any;

    onPageChange?: ((page: number, details?: any) => void) | undefined;
    onPageSizeChange?: ((pageSize: number, details?: any) => void) | undefined;

    @observable
    isRowSelectable?:
        | ((params: GridRowParams, details?: any) => boolean)
        | undefined;

    @observable
    numOfRowsToLoad?: number;

    @observable
    hasMoreRows?: boolean = true;

    @observable
    onChange?: () => Promise<any>;

    setColumns = async (value: IColDef[], override = false) => {
        if (this._cachedCols && !override) {
            this._columns = await this.updateColConfig(this._cachedCols, value);
        } else {
            this._columns = value;
        }
    };

    get columns() {
        return this._columns;
    }

    public constructor(gridId?: string, appDomain?: string) {
        makeObservable(this);

        if (gridId) {
            this.gridId = gridId;
        }

        if (appDomain) {
            this.appDomain = appDomain;
        }

        this.removeHeight = "20px";
        this.density = "standard";
        this.checkboxSelection = true;
        this.isLoading = false;

        const local = RootStore().getStore(LocalStorage);
        this.localStorage = local.getLocalStore(this.appDomain);

        this.updateColumnsFromCache();

        reaction(
            () => this.columns,
            () => {
                this.updateColDefs(this.columns);
            },
            { fireImmediately: true },
        );
    }

    @action
    setHideVertIcon = (isHidden: boolean) => {
        this.hideVertIcon = isHidden;
    };

    // @action
    // onPageSizeChange = (newPageSize: number) => {
    //     this.paginationSize = newPageSize;
    // };

    // @action
    // handleOnRowsScrollEnd = () => {
    //     this.numOfRowsToLoad && this.loadMoreRows(this.numOfRowsToLoad);
    // };

    @action
    async loadMoreRows(numOfRowsToLoad: number) {
        let totalRowCount = this.rows.length;
        let loadedRowCount = this.loadedRows.length;
        if (loadedRowCount < totalRowCount) {
            this.hasMoreRows = true;
        }
        if (this.hasMoreRows === false || this.isLoading) return;

        const incrementRows = () => {
            if (loadedRowCount && loadedRowCount < totalRowCount) {
                // if we have NOT loaded all of the rows
                // add another batch of rows to the count to load
                loadedRowCount += numOfRowsToLoad;
                // set the loaded rows to include the next batch of rows
                this.loadedRows = this.rows.slice(0, loadedRowCount);
            } else if (loadedRowCount >= totalRowCount) {
                this.hasMoreRows = false;
            }
        };
        this.hasMoreRows && incrementRows();
    }

    @action
    public buildVertMenuOptionForCsvExport(enable: boolean): AcxMenuItemProps {
        const exportToCsvButton: AcxMenuItemProps = {
            id: `export-to-csv-button`,
            label: <StyledMenuLabel>Export to CSV</StyledMenuLabel>,
            icon: <SubdirectoryArrowRightIcon />,
            props: {
                disabled: !enable,
                onClick: () => {
                    let parsedData = {} as any;

                    this.rows.forEach((row, index) => {
                        if (index < 1) {
                            parsedData["fields"] = Object.keys(row);
                            parsedData["data"] = [Object.values(row)];
                        } else {
                            parsedData["data"].push(Object.values(row));
                        }
                    });

                    parsedData = Papa.unparse(parsedData as UnparseObject);

                    let downloadLink = document.createElement("a");
                    const blob = new Blob(["\ufeff", parsedData]);
                    const url = URL.createObjectURL(blob);

                    downloadLink.href = url;
                    downloadLink.download = "My_Report.csv";

                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                    this.closeMenu();
                },
            },
        };
        return exportToCsvButton;
    }

    @action
    closeMenu = () => {
        this.menuAnchor = null;
    };

    getCachedColumnConfig = (): Promise<IColDef[] | null> => {
        return new Promise((resolve) => {
            this.localStorage
                .getItem(`AcxDataGrid${this.gridId}`)
                .then((value) => {
                    if (value) {
                        let c;
                        if (isString(value)) {
                            c = JSON.parse(value as string);
                        } else {
                            c = value;
                        }
                        resolve(c);
                    } else {
                        resolve(null);
                    }
                });
        });
    };

    @action
    updateColumnsFromCache = () => {
        this.getCachedColumnConfig().then((val) => {
            if (val) {
                this._cachedCols = val;
                if (this.columns) {
                    this.updateColConfig(val, this.columns);
                } else {
                    this.updateColDefs(val);
                }
            }
        });
    };

    @action
    setInitialTableReportColumns = (inputColumns: GridColDef[]) => {
        this.getCachedColumnConfig().then(async (val) => {
            if (val) {
                this._cachedCols = val;
                if (this.columns) {
                    await this.updateColConfig(val, this.columns);
                } else {
                    await this.updateColDefs(val);
                }
            }
            this.setColumns(inputColumns);
        });
    };

    updateLocalStorage = async (c: IColDef[]) => {
        await this.localStorage.setItem(
            `AcxDataGrid${this.gridId}`,
            JSON.stringify(c),
        );
    };

    setReportTableColumnTypes = (
        element: IColDef,
        customFilterInput: string,
        customFilterOperators: any,
    ): [IColDef, string, any] => {
        if (typeof element.renderType === "string") {
            const customSortComparator =
                this.formatters[this.comparatorTranslation[element.renderType]];
            if (customSortComparator) {
                element.sortComparator = customSortComparator;
            }

            element.renderCell =
                this.formatters[this.formatTranslation[element.renderType]];

            const getFilterOperators =
                this.formatters[
                    this.filterOperatorsTranslation[element.renderType]
                ];

            if (
                element.renderType === "durationFormatter" ||
                element.renderType === "dateTimeFormatter" ||
                element.renderType === "dateFormatter" ||
                element.renderType === "dateFormatterIgnoreTime"
            ) {
                element.filterOperators = getFilterOperators(element.field);
                element.sortComparator = customSortComparator;

                customFilterOperators = getFilterOperators(element.field);
            }

            if (element.renderType === "evalStatusFormatter") {
                customFilterOperators = getFilterOperators(element.field);
            }

            customFilterInput = this.filterTranslation[element.renderType];
        }

        return [element, customFilterInput, customFilterOperators];
    };

    setStandardFilterOperators = (element: IColDef) => {
        // remove any filter items without a getApplyFilterFn
        element.filterOperators = element.filterOperators?.filter(
            (operator) => {
                return !!operator.getApplyFilterFn;
            },
        );
        if (element.type === "number") {
            element.filterOperators = getGridNumericColumnOperators()
                .filter(
                    (op) => op.value !== "isNotEmpty" && op.value !== "isEmpty",
                )
                .map((operator) => ({
                    ...operator,
                    InputComponent: this.formatters.defaultFilter,
                }));
        } else if (element.type === "boolean") {
            element.filterOperators = [];
        } else if (
            !element.filterOperators?.length &&
            element.field !== "evaluationStatus"
        ) {
            element.filterOperators = getGridStringOperators()
                .filter(
                    (op) => op.value !== "isNotEmpty" && op.value !== "isEmpty",
                )
                .map((operator) => ({
                    ...operator,
                    InputComponent: this.formatters.defaultFilter,
                }));
        }
        return element;
    };

    setCustomRenderTypeProperties = (element: IColDef) => {
        if (element.renderType) {
            let customFilterOperators: any;
            let customFilterInput: string =
                this.filterTranslation[(element.renderType as any)?.type];

            const customCellRenderer =
                this.formatters[
                    this.formatTranslation[(element.renderType as any)?.type]
                ];

            const customSortComparator =
                this.formatters[
                    this.comparatorTranslation[
                        (element.renderType as any)?.type
                    ]
                ];

            const customFilterOperatorGetter =
                this.formatters[
                    this.filterOperatorsTranslation[
                        (element.renderType as any)?.type
                    ]
                ];

            if (isFunctionType(element.renderType)) {
                element.renderCell = element.renderType;
            } else if (typeof element.renderType === "string") {
                // Handles date/dateTime renderTypes which are only used in report tables.
                // DataGrid pro refactor handles setting sortComparator, renderCell, customFilterInput, and
                // filterOperators with custom columnTypes
                [element, customFilterInput, customFilterOperators] =
                    this.setReportTableColumnTypes(
                        element,
                        customFilterInput,
                        customFilterOperators,
                    );
            } else if (element?.renderType.type === "progressFormatter") {
                element.renderCell = customCellRenderer(
                    element.renderType.fields,
                );
            } else if (element?.renderType.type === "percentFormatter") {
                element.sortComparator = customSortComparator;
                element.renderCell = customCellRenderer;

                customFilterOperators = customFilterOperatorGetter(
                    element.renderType.filterFields,
                );
            } else if (element?.renderType.type === 'booleanFormatter') {
                element.renderCell = customCellRenderer(
                    element.renderType.fields,
                );
                if(element.field === 'highPriority'){
                    element.sortComparator = customHighPrioritySortComparator
                } else {
                    element.sortComparator = customSortComparator
                }
            } else {
                element.renderCell = customCellRenderer(
                    element.renderType.fields,
                );

                customFilterOperators = customFilterOperatorGetter(
                    element.renderType.filterFields,
                );

                if (element?.renderType.type === "chip") {
                    element.sortComparator = customSortComparator;
                } else if (
                    element?.renderType.type === "genericLinkFormatter"
                ) {
                    element.sortComparator = customSortComparator(
                        element.renderType.fields,
                    );
                } else if (element?.renderType.type === "linkFormatter") {
                    element.sortComparator = customSortComparator(
                        element.renderType.filterFields,
                    );
                }
            }

            if (customFilterInput) {
                const customFilterInputElement =
                    this.formatters[customFilterInput];

                if (customFilterOperators) {
                    element.filterOperators = customFilterOperators.map(
                        (operator) => ({
                            ...operator,
                            InputComponent: customFilterInputElement,
                        }),
                    );
                } else {
                    if (element.type === "number") {
                        element.filterOperators =
                            getGridNumericColumnOperators().map((operator) => ({
                                ...operator,
                                InputComponent: customFilterInputElement,
                            }));
                    } else if (element.type === "date") {
                        element.filterOperators = getGridDateOperators().map(
                            (operator) => ({
                                ...operator,
                                InputComponent: customFilterInputElement,
                            }),
                        );
                    } else {
                        element.filterOperators = getGridStringOperators().map(
                            (operator) => ({
                                ...operator,
                                InputComponent: customFilterInputElement,
                            }),
                        );
                    }
                }
            }
        }

        return element;
    };

    // process our custom col def into datagrid column type
    @action
    updateColDefs = async (curCols: IColDef[]) => {
        const cols: GridColDef[] = [];

        curCols?.forEach((element) => {
            element = this.setStandardFilterOperators(element);

            element = this.setCustomRenderTypeProperties(element);

            if (
                element.filterOperators &&
                !element.filterOperators?.find((operator) => {
                    return operator.value.includes("Empty");
                }) &&
                element.field !== "evaluationStatus"
            ) {
                element.filterOperators = [
                    ...element.filterOperators,
                    ...CUSTOM_FILTERS,
                ];
            }

            cols.push(element);
        });

        this.gridCols = cols;
    };

    updateColConfig = async (
        cols: GridColDef[],
        refCols: IColDef[],
    ): Promise<IColDef[]> => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [newlyAdded, intersection, removed] = symmetricDifferenceBy(
            cols,
            refCols,
            (arg: GridColDef) => arg.headerName,
            (arg: IColDef) => arg.headerName,
        );

        if (newlyAdded) {
            newlyAdded.forEach((value) => {
                const col = refCols.find((c) => c.headerName === value);

                if (col) {
                    cols.push(col);
                }
            });
        }

        const c: IColDef[] = [];
        cols.forEach((val, index) => {
            const col = refCols.find((r) => r.field === val.field);

            if (col) {
                if (col.width !== val.width) {
                    col.width = val.width;
                    delete col.flex;
                    delete val.flex;
                }
                if (col.hide !== val.hide) {
                    col.hide = val.hide;
                }

                const v = { ...val, ...col };
                c.push(v);
            }
        });

        await this.updateLocalStorage(c);

        return c;
    };

    @action
    onColResizeReorderOrVisChange = _.debounce(async (cols: GridColDef[]) => {
        if (this.disableLocalConfig) {
            return;
        }

        const c = await this.updateColConfig(cols, this.columns);
        this._cachedCols = c;

        await this.updateLocalStorage(c);

        await this.setColumns(c);
    }, 100);

    @action
    reset = () => {
        this.updateFiltered(false);
        this.clearSelected();
        this.rows.splice(0, this.rows.length);
    };

    @action
    updateFiltered = (val) => {
        this.filtered = val;
    };

    @action
    handleSelectionChange = async (param: GridSelectionModel) => {
        this.changeSelected(param);
        if (this.onChange) {
            await this.onChange();
        }
    };

    @action
    clearSelected = () => {
        this.selectedRowIds = observable([]);
        this.selectedRows = observable([]);
    };

    @action
    removeSelectedById = (id) => {
        this.selectedRowIds = observable(
            this.selectedRowIds.filter((val) => val !== id),
        );

        this.selectedRows = observable(
            this.selectedRows.filter((val) => val.id !== id),
        );
    };

    @action
    private changeSelected = (selectedIds: string[] | GridRowId[]) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [newlyAdded, intersection, removed] = symmetricDifferenceBy(
            this.selectedRowIds,
            selectedIds,
            (arg) => arg,
            (arg) => arg,
        );

        newlyAdded.forEach((val) => {
            this.selectedRowIds.push(val);
            const r = this.rows.find((r) => r.id === val);
            if (r) {
                this.selectedRows.push(r);
            }
        });

        removed.forEach((val) => {
            this.selectedRowIds.remove(val);
            const r = this.rows.find((r) => r.id === val);
            if (r) {
                this.selectedRows.remove(r);
            }
        });
    };

    @action
    setSelectById = (idArray: string[]) => {
        this.changeSelected(idArray);
    };

    getRow = (rowId: React.ReactText) => {
        return this.rows.find((r) => (r.id = rowId));
    };

    @computed
    get SelectedRows() {
        return this.selectedRows;
    }

    getSelectedRows() {
        return this.selectedRows;
    }

    @action
    showFilter = (targetColumnField?: string) => {
        this.filterColumn = targetColumnField;
        this.filterIsOpen = true;
    };

    @action
    showHideColumns = () => {
        this.hideColumnsIsOpen = true;
    };

    @action
    setLoading = (val: boolean) => {
        this.isLoading = val;
    };

    @action
    setFilterModel = async (data: GridFilterModel) => {
        if (this.filterMode === "server" && this.filterRequest) {
            this.isLoading = true;

            const res = await this.filterRequest(data);

            runInAction(() => {
                this.rows = res;
                this.isLoading = false;
            });
        }
        this.filterModel = data;
    };

    @action
    setSortModel = async (data: GridSortModel) => {
        if (this.sortingMode === "server" && this.sortRequest) {
            // this.isLoading = true;

            await this.sortRequest(data);

            // runInAction(() => {
            //     this.isLoading = false;
            // });
        }
        // this.sortModel = data;
    };
}

export default AcxDataGridStore;
