import {
    GridProps,
    ToggleButton,
    ToggleButtonGroup,
    useTheme
} from "@mui/material";
import React, { FunctionComponent } from "react";
import { isStringType } from "../../utils/TypeGuards";
import AcxInputLabel from "./AcxInputLabel";

type ValueElement = {
    value: any;
    element: React.ReactNode;
    sizeOverride?: "large" | "default";
};

interface OwnProps {
    id: string;
    defaultValue?: any | any[];
    onChange?: (value: any | any[] | null) => void;
    exclusive?: boolean;
    spacing?: number;
    size?: "large" | "default";
    items: Array<string | ValueElement>;
    orientation?: "horizontal" | "vertical";
    justifyContent?: GridProps["justifyContent"];
    style?: React.CSSProperties;
    disabled?: boolean;
    inputLabel?: string;
    shrink?: boolean;
    infoText?: string;
    groupFlexGrow?: number;
    darkBg?: boolean;
}

type Props = OwnProps;

const AcxToggleButton: FunctionComponent<Props> = (props) => {
    const theme = useTheme();

    return (
        <>
            {props.inputLabel && (
                <AcxInputLabel
                    shrink={props.shrink}
                    id={props.id + "-label"}
                    htmlFor={props.id}
                    infoText={props.infoText}
                    styles={{
                        fontFamily: theme.typography.fontFamily,
                        color: theme.palette.text.primary,
                        fontSize: "12px",
                        lineHeight: "16px",
                        paddingLeft: theme.spacing(0.5),
                    }}
                >
                    {props.inputLabel}
                </AcxInputLabel>
            )}
            <ToggleButtonGroup
                sx={(theme) => ({
                    ...props.style,
                    backgroundColor: theme.palette.lightgray.background,
                    borderRadius: "4px",
                    padding: theme.spacing(0.5),
                    display: "flex",
                    gap: "3px",
                })}
                value={props.defaultValue}
                orientation={props.orientation}
                exclusive={props.exclusive}
                onChange={(event, value) => {
                    props.onChange?.(value);
                }}
                aria-label="toggle button v3"
            >
                {props.items.map((item, index) => {
                    let element: React.ReactNode;
                    let value: any;
                    let size: "large" | "default" | undefined = props.size;
                    if (isStringType(item)) {
                        value = element = item;
                    } else {
                        value = item.value;
                        element = item.element;
                        size = item.sizeOverride ?? size;
                    }

                    return (
                        <ToggleButton
                            key={value}
                            disabled={props.disabled}
                            sx={(theme) => ({
                                flex: .75,
                                paddingTop: theme.spacing(
                                    size === "large" ? 1.5 : 0.5,
                                ),
                                paddingBottom: theme.spacing(
                                    size === "large" ? 1.5 : 0.5,
                                ),
                                paddingLeft: theme.spacing(
                                    size === "large" ? 5.5 : 3.5,
                                ),
                                paddingRight: theme.spacing(
                                    size === "large" ? 5.5 : 3.5,
                                ),
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                userSelect: "none",
                                textTransform: "none",
                                backgroundColor:
                                    theme.palette.lightgray.background,
                                color: theme.palette.blackFont.main,
                                fontFamily: theme.typography.fontFamily,
                                fontSize: "13px",
                                lineHeight: "20px",
                                textAlign: "center",
                                fontWeight: 600,
                                border: 0,
                                borderRadius: "4px",
                                "&:hover": {
                                    backgroundColor:
                                        theme.palette.lightgrayHover.main,
                                    borderRadius: "4px !important",
                                },
                                "&.Mui-selected": {
                                    backgroundColor: theme.palette.white.main,
                                    boxShadow: "0 0 0 1px" + theme.palette.lightgrayBorder.main,
                                    borderRadius: "4px",
                                    color: theme.palette.blackFont.main,
                                    fontWeight: theme.typography.fontWeightBold,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.lightgrayHover.main,
                                    },
                                },
                            })}
                            value={value}
                        >
                            {element}
                        </ToggleButton>
                    );
                })}
            </ToggleButtonGroup>
        </>
    );
};

export default AcxToggleButton;
