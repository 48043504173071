import { Grid, Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import { Observer, observer } from "mobx-react";
import { Moment } from "moment";
import React from "react";
import { AuthStore } from "stores/AuthStore";
import {
    DatePickerComponentStore,
    DateReferenceOption,
} from "stores/ComponentStores/DatePickerComponentStore";
import { OrgSelectorComponentStore } from "stores/ComponentStores/OrgSelectorComponentStore";
import useStyles from "../../../Styles/Styles";
import { useStore } from "../../../utils/useStore";
import ClassifierReportStore from "../../NLP/ClassifierReport/ClassifierReportStore";
import AcxDateRangeInput from "../../UI/Calendar/DateRange/AcxDateRangeInput";
import AcxSelectSingle from "../../UI/Select/BaseSelectComponents/AcxSelectSingle";

const styles = (theme: Theme) => {
    return createStyles({
        root: {
            width: "100%",
            marginTop: "16px",
            marginBottom: theme.spacing(0.5),
        },
    });
};

const referenceOptions = [
    DateReferenceOption.InteractionDate,
    DateReferenceOption.ArrivalDate,
];
interface IReportFiltersProps {
    datePickerStore: DatePickerComponentStore;
    orgSelectStore: OrgSelectorComponentStore;
    menuPortalTarget?: HTMLElement | null | undefined;
}

const ReportFilters = observer((props: IReportFiltersProps) => {
    const classes = useStyles(styles);
    const store = useStore(ClassifierReportStore);
    const orgStore = useStore(AuthStore).orgStore;

    React.useEffect(() => {
        if (orgStore && orgStore.selectedOrganization) {
            store.orgSelectStore.selectOrg(orgStore.selectedOrganization);
        }
    }, [orgStore, orgStore?.selectedOrganization, store]);

    return (
        <Grid
            container
            spacing={1}
            alignItems="center"
            className={classes.root}
        >
            <Grid container item xs={12}>
                <Grid container item xs={12} justifyContent="flex-start">
                    <Observer>
                        {() => (
                            <AcxDateRangeInput
                                defaultStartDate={
                                    props.datePickerStore.beginDate
                                }
                                defaultEndDate={props.datePickerStore.endDate}
                                onSelect={(start: Moment, end: Moment) => {
                                    props.datePickerStore.setBeginDate(start);
                                    props.datePickerStore.setEndDate(end);
                                }}
                                wrapperStyle={{
                                    marginRight: "0.75rem",
                                }}
                                datePickerStore={props.datePickerStore}
                                dateReferenceOptions={referenceOptions}
                                displayDateType
                            />
                        )}
                    </Observer>
                </Grid>
            </Grid>

            <Grid item xs={2}>
                <AcxSelectSingle
                    options={store.hierarchyLevels ?? []}
                    enableNoSelection
                    defaultValue={store.selectedHierarchy}
                    onChange={store.setHierarchyLevel}
                    inputLabel="Service Hierarchy"
                    id="classifier-hierarchy-levels-selector"
                    valueField="id"
                    labelField="label"
                />
            </Grid>
        </Grid>
    );
});

export default ReportFilters;
