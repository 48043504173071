import { Grid, Tooltip, useMediaQuery } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import theme from "Theme/AppTheme";
import SoundClipEditorV2 from "components/SoundClipEditor/SoundClipEditorV2";
import { AcxTranscription } from "components/UI/AcxTranscription";
import { Observer } from "mobx-react";
import React, { FunctionComponent, useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { IMultiLanguageTranscriptionPayload } from "services/TranscriptionService";
import LocalStorage from "stores/LocalStorage";
import RootStore from "stores/RootStore";
import { InteractionType } from "../../../models/InteractionType";
import { PlayerOptions } from "../../SoundClipEditor/SoundClipEditor";
import AcxChatViewer from "../../UI/Chat/AcxChatViewer";
import AcxDrawer from "../../UI/Drawer/AcxDrawer";
import { EvalStore } from "../Stores/EvalStore";

interface OwnProps {
    store: EvalStore;
    collapsible?: boolean;
    altWidth?: number;
    altLeftPosition?: number;
    miniHeight: number;
    maxHeight: number;
    canSeeTranscript: boolean;
}

const soundEditorMinSizeUltraUser = 64;
const soundEditorMaxSizeUltraUser = 195;

const useStyles = makeStyles((theme) => ({
    drawerPaper: {
        boxShadow: "none",
        borderLeft: `1px solid ${theme.palette.lightgrayBorder.main}`,
        borderRight: `1px solid ${theme.palette.lightgrayBorder.main}`,
    },
}));

type Props = OwnProps;

const MediaViewerDock: FunctionComponent<Props> = (props) => {
    const classes = useStyles();

    const [soundEditorOpen, setSoundEditorOpen] = useState(true);
    const [playerOptions, setPlayerOptions] = useState<PlayerOptions>({
        id: "0",
        isPlaying: false,
        startTime: 0,
        endTime: 0,
    });

    const onSelectedClip = (options: PlayerOptions) => {
        options &&
            setPlayerOptions((prev) => ({
                ...prev,
                id: options.id,
                isPlaying: options.isPlaying,
                startTime: options.startTime,
                endTime: options.endTime,
            }));
    };

    const onChangeLanguage = (
        input: IMultiLanguageTranscriptionPayload["languageCode"],
    ) => {
        props.store.changeTranscriptLanguage(input);
    };

    const isAudio =
        props.store.evalAudio &&
        props.store.mediaType === InteractionType.Audio;

    useEffect(() => {
        if (isAudio && props.canSeeTranscript)
            props.store.toggleMediaViewerDrawer();
    }, [isAudio, props.canSeeTranscript, props.store]);

    const soundEditorOpenToggle = () => {
        if (!props.canSeeTranscript || !isAudio)
            props.store.toggleMediaViewerDrawer();
        else setSoundEditorOpen((current) => !current);
    };

    const audioCollapseOpenCheck =
        isAudio && props.canSeeTranscript
            ? soundEditorOpen
            : props.store.isMediaViewerDockOpen;

    const fullheight =
        !isAudio || !props.canSeeTranscript
            ? { height: "100%" }
            : { height: "auto" };

    let editorHeight = soundEditorOpen
        ? soundEditorMaxSizeUltraUser
        : soundEditorMinSizeUltraUser;

    if (!props.canSeeTranscript) {
        editorHeight = props.store.isMediaViewerDockOpen
            ? props.maxHeight - 2
            : props.collapsible
            ? props.miniHeight - 2
            : 0;
    }

    let drawerMinSize = props.miniHeight;

    if (
        !props.store.isMediaViewerDockOpen &&
        !soundEditorOpen &&
        props.canSeeTranscript
    ) {
        drawerMinSize = soundEditorMinSizeUltraUser + 25;
    } else if (
        !props.store.isMediaViewerDockOpen &&
        soundEditorOpen &&
        props.canSeeTranscript &&
        isAudio
    ) {
        drawerMinSize = soundEditorMaxSizeUltraUser + 1;
    }

    const handleOpenTranscript = () => {
        const local = RootStore().getStore(LocalStorage);

        const tempLocal = local.getLocalStore();

        tempLocal.getItem("transcriptOpened").then((val) => {
            if (!val && isAudio && props.canSeeTranscript) {
                tempLocal.setItem("transcriptOpened", true);
            }
        });

        props.store.toggleMediaViewerDrawer();
    };

    useEffect(() => {
        props.store.loadTEddyEffectP2Results(
            props.store.currentEval?.interaction?.audioMetadataId,
        );
    }, [props.store]);

    const fullControls = props.canSeeTranscript
        ? soundEditorOpen
        : props.store.isMediaViewerDockOpen;

    const shouldTruncateButtonText = useMediaQuery(
        theme.breakpoints.down("lg"),
    );

    return (
        <Observer>
            {() => (
                <AcxDrawer
                    anchor={"bottom"}
                    miniSizePx={drawerMinSize}
                    maxSizePx={
                        audioCollapseOpenCheck
                            ? props.maxHeight
                            : props.maxHeight - 110
                    }
                    customRootStyles={{ zIndex: 1098 }}
                    customPaperClasses={classes.drawerPaper}
                    onDrawerResize={props.store.setMediaDrawerSize}
                    variant={"permanent"}
                    alternateLeftPosition={props.altLeftPosition}
                    alternateWidth={props.altWidth}
                    loading={false}
                    collapsible={
                        Boolean(
                            props.store.evalAudio || props.store.evalChat,
                        ) && props.collapsible
                            ? "mini"
                            : "full"
                    }
                    open={props.store.isMediaViewerDockOpen}
                    onDrawerClose={props.store.closeMediaViewerDrawer}
                    hidden={false}
                    content={
                        <Observer>
                            {() => (
                                <>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent={"flex-end"}
                                        alignItems={"stretch"}
                                        style={fullheight}
                                    >
                                        <Grid item xs={12}>
                                            <div style={fullheight}>
                                                {Boolean(
                                                    props.store.evalAudio &&
                                                        props.store
                                                            .mediaType ===
                                                            InteractionType.Audio,
                                                ) && (
                                                    <SoundClipEditorV2
                                                        soundClipEditorStore={
                                                            props.store
                                                                .soundClipEditorStore
                                                        }
                                                        playerOptions={
                                                            playerOptions
                                                        }
                                                        fullControls={
                                                            fullControls
                                                        }
                                                        textButton={
                                                            props.canSeeTranscript
                                                                ? props.store
                                                                      .isMediaViewerDockOpen
                                                                    ? `Hide${
                                                                          shouldTruncateButtonText
                                                                              ? ""
                                                                              : " Transcript"
                                                                      }`
                                                                    : `View${
                                                                          shouldTruncateButtonText
                                                                              ? ""
                                                                              : " Transcript"
                                                                      }`
                                                                : null
                                                        }
                                                        textButtonProps={{
                                                            onClick:
                                                                handleOpenTranscript,
                                                            buttonDisabled:
                                                                !props.canSeeTranscript,
                                                        }}
                                                        noSegmentRemoval
                                                        onSegmentMouseOver={
                                                            props.store
                                                                .setActiveClip
                                                        }
                                                        onSegmentClick={
                                                            props.store
                                                                .setOpenClipId
                                                        }
                                                        alternateClipDownloader={
                                                            props.store
                                                                .redactedClipDownloader
                                                        }
                                                        onClipEditorReady={
                                                            props.store
                                                                .setAudioVisualizerReady
                                                        }
                                                        segmentList={
                                                            props.store
                                                                .clipsToSegmentList
                                                        }
                                                        disabled={
                                                            !Boolean(
                                                                props.store
                                                                    .evalAudio,
                                                            )
                                                        }
                                                        onAudioBufferReady={
                                                            props.store
                                                                .setEvalAudioBuffer
                                                        }
                                                        id={
                                                            "eval-audio-interaction-editor"
                                                        }
                                                        generateMp3={false}
                                                        editorHeight={
                                                            editorHeight
                                                        }
                                                        onClipGenerated={
                                                            props.store
                                                                .onAudioClipCreated
                                                        }
                                                        onClipUpdated={
                                                            props.store
                                                                .onAudioClipUpdated
                                                        }
                                                        clip={
                                                            props.store
                                                                .evalAudio
                                                        }
                                                        enableZoom
                                                        mediaUrl={
                                                            props.store
                                                                .currentMediaUrl
                                                        }
                                                        iconButton={
                                                            audioCollapseOpenCheck ? (
                                                                <Tooltip title="Minimize audio editor">
                                                                    <ExpandMore />
                                                                </Tooltip>
                                                            ) : (
                                                                <Tooltip title="Maximize audio editor">
                                                                    <ExpandLess fontSize="small" />
                                                                </Tooltip>
                                                            )
                                                        }
                                                        iconButtonProps={{
                                                            onClick:
                                                                soundEditorOpenToggle,
                                                        }}
                                                    />
                                                )}
                                                {Boolean(
                                                    props.store.mediaType ===
                                                        InteractionType.ProcessedChat &&
                                                        props.store.evalChat,
                                                ) && (
                                                    <AcxChatViewer
                                                        scrollTo={
                                                            props.store
                                                                .activeClipInverse
                                                        }
                                                        records={
                                                            props.store.evalChat
                                                        }
                                                        setFocusClip={
                                                            props.store
                                                                .setActiveClip
                                                        }
                                                        mediaClips={
                                                            props.store
                                                                .currentEval
                                                                ?.activeSoundClips
                                                        }
                                                        onClip={
                                                            props.store
                                                                .onTextMediaClipCreated
                                                        }
                                                        showHeader
                                                        eddyEffectP2Data={
                                                            props.store
                                                                .eddyEffectP2Data
                                                        }
                                                        showNoRedactionWarning={
                                                            props.store
                                                                .showNotRedactedWarning
                                                        }
                                                        secondaryButton={
                                                            props.canSeeTranscript
                                                        }
                                                        secondaryButtonLabel={
                                                            props.store
                                                                .isMediaViewerDockOpen
                                                                ? "Minimize Chat"
                                                                : "Expand Chat"
                                                        }
                                                        secondaryButtonOnClick={
                                                            handleOpenTranscript
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </Grid>
                                    </Grid>

                                    {isAudio && props.canSeeTranscript && (
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent={"flex-end"}
                                            alignItems={"flex-start"}
                                        >
                                            {props.store.transcriptionData && (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    style={{
                                                        height: "350px",
                                                        width: "100%",
                                                        display: `${
                                                            props.store
                                                                .isMediaViewerDockOpen
                                                                ? "block"
                                                                : "none"
                                                        }`,
                                                    }}
                                                    className="pendo-ignore"
                                                >
                                                    <Scrollbars>
                                                        <AcxTranscription
                                                            onSelectedClip={
                                                                onSelectedClip
                                                            }
                                                            onClip={(
                                                                start,
                                                                end,
                                                            ) => {
                                                                props.store.onAudioClipCreated(
                                                                    start,
                                                                    end,
                                                                );
                                                            }}
                                                            eddyEffectP2Data={
                                                                props.store
                                                                    .eddyEffectP2Data
                                                            }
                                                            transcriptWords={
                                                                props.store
                                                                    .transcriptionData
                                                                    .words
                                                            }
                                                            showTranslationUI={
                                                                !!props.store
                                                                    .spanishTranscript
                                                            }
                                                            isTranslated={
                                                                props.store
                                                                    .selectedTranscriptLanguage !==
                                                                "es"
                                                            }
                                                            onChangeLanguage={
                                                                onChangeLanguage
                                                            }
                                                            selectedLanguageCode={
                                                                props.store
                                                                    .selectedTranscriptLanguage
                                                            }
                                                            hasEvaluation
                                                            amdId={
                                                                props.store
                                                                    .currentEval
                                                                    ?.interaction
                                                                    ?.audioMetadataId
                                                            }
                                                            speakerStore={
                                                                props.store
                                                                    .speakerStore
                                                            }
                                                        />
                                                    </Scrollbars>
                                                </Grid>
                                            )}
                                        </Grid>
                                    )}
                                </>
                            )}
                        </Observer>
                    }
                />
            )}
        </Observer>
    );
};

export default MediaViewerDock;
