import React from "react";
import { Grid, Typography } from "@mui/material";
import theme from "Theme/AppTheme";
import { useStore } from "utils/useStore";
import { OrganizationCustomerTypeStore } from "./stores/OrganizationCustomerTypeStore";
import AcxButton from "components/UI/AcxButton";
import { IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Delete } from "@mui/icons-material";
import { observer } from "mobx-react";
import OrganizationCustomerTypesMappings from "./OrganizationCustomerTypesDetails/OrganizationCustomerTypesMappings";
import OrganizationAdditionalCustomerTypes from "./OrganizationCustomerTypesDetails/OrganizationAdditionalCustomerTypes";
import ConfirmationDialog from "components/UI/AcxConfirmationDialog";
import AcxMainTextField from "components/UI/AcxMainTextField";

type Props = {
    id: string;
};

/**
 * Mui Typography will overwrite any styles passed via className
 * but not via the style prop. Hence why we have this object.
 */
export const customerTypesTypographyStyles = {
    pageTitle: {
        color: theme.palette.gray[700],
        fontWeight: "bold",
        fontSize: "24px",
    },
    title: {
        color: theme.palette.blackFont.main,
        fontWeight: "bold",
        fontSize: theme.typography.h5.fontSize,
    },
    subtitle: {
        color: theme.palette.blackFont.main,
        fontWeight: "bold",
        fontSize: theme.typography.body2.fontSize,
    },
    explanationTitle: {
        color: theme.palette.gray[500],
        fontWeight: "bold",
        fontSize: theme.typography.body2.fontSize,
    },
    explanationText: {
        color: theme.palette.gray[500],
        fontSize: theme.typography.h6.fontSize,
    },
    text: {
        color: theme.palette.gray[700],
        fontSize: theme.typography.body2.fontSize,
    },
};

const useStyles = makeStyles((theme) => ({
    deleteButton: {
        backgroundColor: theme.palette.white.main,
        border: `1px solid ${theme.palette.lightgrayBorder.main}`,
        borderRadius: "1px",
    },
    newLabelButton: {
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.white.main,
        border: `1px solid ${theme.palette.lightgrayBorder.main}`,
        "&:hover": {
            color: theme.palette.secondary.main,
            backgroundColor: theme.palette.white.main,
            border: `1px solid ${theme.palette.lightgrayBorder.main}`,
            opacity: "75%",
        },
    },
}));

const OrganizationCustomerTypes: React.FC<
    React.PropsWithChildren<React.PropsWithChildren<Props>>
> = observer(() => {
    const store = useStore(OrganizationCustomerTypeStore);
    const classes = useStyles();

    const isCustomerTypesLoading = store.getTaskLoading(
        OrganizationCustomerTypeStore.Tasks.LOAD_CUSTOMER_TYPES,
    );

    const isSavingChanges = store.getTaskLoading(
        OrganizationCustomerTypeStore.Tasks.SAVE_CHANGES,
    );

    const isDeleteCustomerTypesLoading = store.getTaskLoading(
        OrganizationCustomerTypeStore.Tasks.DELETE_CUSTOMER_TYPES,
    );

    return (
        <Grid
            container
            sx={{ marginInline: "4rem", marginBlock: "1rem" }}
            direction="column"
            rowSpacing={3}
        >
            <Grid item>
                <Typography style={customerTypesTypographyStyles.pageTitle}>
                    Contact Types
                </Typography>
            </Grid>
            <Grid
                container
                item
                justifyContent="flex-end"
                alignItems="center"
                columnSpacing={2}
            >
                <Grid item>
                    <IconButton
                        size="small"
                        disabled={
                            store.selectedCustomerTypeIds.size === 0 ||
                            isDeleteCustomerTypesLoading
                        }
                        onClick={() => store.deleteSelectedCustomerTypes()}
                        className={classes.deleteButton}
                    >
                        <Delete />
                    </IconButton>
                </Grid>
                <Grid item>
                    <AcxButton
                        color="secondary"
                        leftRightSpacing={0}
                        fullWidth={false}
                        onClick={() => store.addNewCustomerType()}
                    >
                        + Add Custom Contact Type
                    </AcxButton>
                </Grid>
                <Grid item>
                    <AcxButton
                        color="secondary"
                        leftRightSpacing={0}
                        fullWidth={false}
                        buttonDisabled={!store.canSaveChanges}
                        tooltip={
                            store.hasDuplicateCustomerTypes
                                ? "Resolve any errors to save your changes."
                                : undefined
                        }
                        onClick={() => store.saveChanges()}
                        loading={isSavingChanges}
                    >
                        Save Changes
                    </AcxButton>
                </Grid>
            </Grid>
            <Grid item>
                <OrganizationCustomerTypesMappings />
            </Grid>
            {!store.createContactTypesDialogStore.isOpen &&
                (store.optimisticCustomerTypes.length > 0 ||
                    isCustomerTypesLoading) && (
                    <Grid item>
                        <OrganizationAdditionalCustomerTypes />
                    </Grid>
                )}
            <ConfirmationDialog
                isOpen={store.createContactTypesDialogStore.isOpen}
                title="Add Contact Types"
                confirmButtonText="Add New Contact Types"
                cancelButtonText="Cancel"
                confirmButtonDisabled={!store.canSaveChanges}
                isLoading={isSavingChanges}
                onConfirm={async () => {
                    await store.saveChanges();
                    store.onCreateDialogClose();
                }}
                onClose={() => store.onCreateDialogClose()}
                content={
                    <Grid container direction="column" rowSpacing={3}>
                        <Grid container item direction="column" rowSpacing={2}>
                            {store.createdCustomerTypes.map((customerType) => {
                                const [hasError, error] =
                                    store.getCustomerTypeError(customerType);

                                return (
                                    <Grid item>
                                        <AcxMainTextField
                                            id={`contact-type-${customerType.id}`}
                                            labelText="Contact Type Name"
                                            placeholderText="Enter new contact type name"
                                            value={customerType.name}
                                            error={hasError}
                                            errorHelper={error}
                                            onChange={(event) =>
                                                store.updateCustomerTypeName(
                                                    customerType,
                                                    event.target.value,
                                                )
                                            }
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                        <Grid item>
                            <AcxButton
                                fullWidth={false}
                                leftRightSpacing={0}
                                color="secondary"
                                buttonClass={classes.newLabelButton}
                                onClick={() => store.addNewCustomerType()}
                            >
                                + New Contact Type
                            </AcxButton>
                        </Grid>
                    </Grid>
                }
            />
        </Grid>
    );
});

export default OrganizationCustomerTypes;
