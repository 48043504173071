import AnswerType from "./AnswerType";
import BaseEntity from "./BaseEntity";
import LicensedModule from "./LicensedModule";
import { Tag } from "./Tag";

export default class Question extends BaseEntity {
    questionText: string;
    answerTypeId: string;
    answerType: AnswerType;
    licensedModuleId: string;
    licensedModule: LicensedModule;
    order: number;
    weight?: number | null;
    helperText?: string;
    required: boolean;
    type: QuestionType;
    parentId?: string | null;
    shortQuestionText?: string;
    tags: Tag[];
    maxLength?: number;
    dependencies?: QuestionDependency;
}

export type QuestionDependency = {
    conditions: QuestionDependencyCondition[];
};

export type QuestionDependencyCondition =
    | QuestionDependencyQuestionAnswerCondition
    | LicensedModuleQuestionDependencyConditionalGroup;

export type QuestionDependencyQuestionAnswerCondition = {
    dependsOnQuestionId: string;
    op: QuestionDependencyQuestionAnswerOperator;
    value: string;
    type: QuestionDependencyType.QuestionAnswer;
};

export type LicensedModuleQuestionDependencyConditionalGroup = {
    conditions: QuestionDependencyCondition[];
    conditionalGroupType: QuestionConditionalGroupType;
    type: QuestionDependencyType.ConditionalGroup;
};

export enum QuestionDependencyType {
    ConditionalGroup = 1,
    QuestionAnswer = 2,
}

export enum QuestionConditionalGroupType {
    And = 1,
    Or = 2,
}

export enum QuestionDependencyQuestionAnswerOperator {
    Contains = 1,
    IsAnswered = 2,
}

export enum QuestionType {
    Question,
    QuestionHeading,
}
