import { Typography } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import AcxHorizontalBarGraph from "components/UI/Visualization/AcxHorizontalBarGraph";
import AcxLineGraph from "components/UI/Visualization/AcxLineGraph";
import AcxVerticalBarGraph from "components/UI/Visualization/AcxVerticalBarGraph";
import { observer } from "mobx-react";
import React from "react";
import useStyles from "Styles/Styles";
import theme from "Theme/AppTheme";
import ModelDashboardStore from "../Stores/ModelDashboardStore";
import BuildChartContainer from "./BuildChartContainer";

const styles = () =>
    createStyles({
        unavailable: {
            margin: "1rem 0",
        },
    });

export interface IBuildChartForModelDataProps {
    chartType:
        | "VerticalBar"
        | "HorizontalBar"
        | "Pie"
        | "Sankey"
        | "Heat"
        | "Line";
    yTitle: string;
    xTitle: string;
    data: any[];
    store: ModelDashboardStore;
    chartName?: string;
    chartId?: string;
}

const BuildChartForModelData: React.FC<IBuildChartForModelDataProps> = observer(
    ({ chartType, data, yTitle, xTitle, store, chartName, chartId }) => {
        const classes = useStyles(styles);

        switch (chartType) {
            case "VerticalBar":
                return (
                    <BuildChartContainer
                        store={store}
                        data={data}
                        chartName={chartName}
                        chart={
                            <AcxVerticalBarGraph
                                chartAreaHeight={75}
                                showGrid
                                legendPosition="bottom"
                                data={data}
                                yTitle={yTitle}
                                xTitle={xTitle}
                                fontSize="1.25rem"
                                fontBold={false}
                                colorScheme={[
                                    theme.palette.blue.main,
                                    theme.palette.green.main,
                                ]}
                                stacked={
                                    chartName ===
                                        "Eddy Effect by Classifiers" ||
                                    chartName === "Eddy Effect by Topic"
                                        ? true
                                        : false
                                }
                                legendAlignment="center"
                                dataOpacity={1}
                                onSelect={(
                                    series: string | null,
                                    x: string | number | null,
                                    y: any,
                                    chartId?: string,
                                    metadata?: any[],
                                ) => {
                                    store.onSelect(
                                        series,
                                        x,
                                        y,
                                        chartId,
                                        metadata,
                                    );
                                }}
                                chartId={chartId}
                                metadata={store.drillEddyMetadata}
                            />
                        }
                    />
                );

            case "HorizontalBar":
                return (
                    <BuildChartContainer
                        store={store}
                        data={data}
                        chartName={chartName}
                        chart={
                            <AcxHorizontalBarGraph
                                chartAreaHeight={75}
                                showGrid
                                legendPosition="bottom"
                                data={data}
                                xTitle={xTitle}
                            />
                        }
                    />
                );

            case "Line":
                return (
                    <BuildChartContainer
                        store={store}
                        data={data}
                        chartName={chartName}
                        chart={
                            <AcxLineGraph
                                showGrid
                                chartAreaHeight={75}
                                legendPosition="none"
                                data={data}
                                yTitle={yTitle}
                                xTitle={xTitle}
                                colorScheme={[theme.palette.secondary.main]}
                                dataOpacity={1}
                                onSelect={(
                                    series: string | null,
                                    x: string | number | null,
                                    y: any,
                                    chartId?: string,
                                    metadata?: any[],
                                ) =>
                                    store.onSelect(
                                        series,
                                        x,
                                        y,
                                        chartId,
                                        metadata,
                                    )
                                }
                                metadata={store.drillEddyMetadata}
                                chartId={chartId}
                            />
                        }
                    />
                );

            default:
                return (
                    <Typography variant="h2" className={classes.unavailable}>
                        Unavailable
                    </Typography>
                );
        }
    },
);

export default BuildChartForModelData;
