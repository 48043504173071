import { Card, Grid, Theme, Tooltip, Typography } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import React from "react";
import useStyles from "Styles/Styles";

const styles = (theme: Theme) =>
    createStyles({
        card: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
    });

interface Props {
    title: string;
    icon: any;
    value: number;
    label?: string;
    useGrid?: boolean;
    descriptionText?: string;
}

function ClassifierReportOverviewCard(props: Props) {
    const classes = useStyles(styles);
    const card = (
        <Card className={classes.card}>
            <Grid
                container
                direction="column"
                spacing={1}
                justifyContent="center"
                alignItems="center"
            >
                <Grid item>
                    <Typography variant="h3">{props.title}</Typography>
                </Grid>
                <Grid
                    item
                    container
                    spacing={1}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item>{props.icon}</Grid>
                    <Grid item>
                        <Typography variant="h1">{props.value}</Typography>
                    </Grid>
                    {props.label ? <Grid item>{props.label}</Grid> : null}
                </Grid>
            </Grid>
        </Card>
    );
    if (!props.useGrid) return card;
    return (
        <Grid item lg={3} md={3} sm={6} xs={12}>
            <Tooltip title={props.descriptionText || ""} placement={"top"}>
                {card}
            </Tooltip>
        </Grid>
    );
}

export default ClassifierReportOverviewCard;
