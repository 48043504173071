import React from "react";
import { Grid } from "@mui/material";
// import useStyles from "Styles/Styles"
import ACXCard from "components/UI/ACXCard";

import AcxMainTextField from "components/UI/AcxMainTextField";

// import QuoteOfTheDay from "components/TestComponents/Skeleton/Dashboard/QuoteOfTheDay"

// const styles = (theme: Theme) => createStyles({
//         root: {
//             flexGrow: 1,
//             width: 1128,
//             marginTop: theme.spacing(3)
//         },
//     })

function InitiativeView() {
    // const classes = useStyles(styles)
    return (
        <Grid container item xs={4}>
            <Grid container item spacing={3} direction="column">
                <Grid item>
                    <>
                        <AcxMainTextField
                            value="test main text field"
                            id="test-text-2"
                            placeholderText="Enter something"
                            labelText="Enter something"
                            error={false}
                            helperText="Some help for you"
                        />
                    </>
                </Grid>
                <Grid item>
                    <ACXCard
                        title="Initiative 1"
                        content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                    />
                </Grid>
                <Grid item>
                    <ACXCard
                        title="2nd Initiative"
                        content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}

export default InitiativeView;
