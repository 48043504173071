import { Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import FocusControls from "../../../../Layouts/Focus/FocusControls";
import SideControlsPage from "../../../../Layouts/SideControls/SideControlsPage";
import SamplerSteps from "./Views/SamplerSteps";
import StepViewer from "./Views/StepViewer";

const useStyles = makeStyles((theme: Theme) => ({
    drawer: {},
    main: {
        width: "100%",

        paddingTop: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
    },
}));

interface OwnProps {}

type Props = OwnProps;

const AudioFile = (props: Props) => {
    const classes = useStyles();

    return (
        <FocusControls
            title={"Smart Sampler"}
            loading={false}
            controlHeader={null}
            onCancel={() => {}}
            mainContent={
                <SideControlsPage
                    drawerContent={<SamplerSteps />}
                    mainContent={<StepViewer />}
                    mainGridSpacing={0}
                    drawerContentClasses={classes.drawer}
                    mainGridAlignItems={"stretch"}
                    mainContentClasses={classes.main}
                    mainGridJustify={"flex-start"}
                    drawerLocation={"left"}
                    drawerVerticalOffsetPixels={0}
                    drawerMinWidth={300}
                    containerOffsetPixels={0}
                />
            }
            showSave={false}
        />
    );
};

export default AudioFile;
