import BaseService from "./BaseService";

export interface QuestionMetadataRequest {
    OrganizationId: string;
    MetadataName: string;
    QuestionId: string;
    MetadataType: number;
    metadataValueTagMap?: any;
}

export interface QuestionMetadataUpdateRequest extends QuestionMetadataRequest {
    Id: string;
}
export default class QuestionMetadataService extends BaseService {
    protected urlBase: string = "api/QuestionMetadata";

    async getQuestionMetadata(orgId) {
        const metadataFields = await this.get(
            new URLSearchParams({ orgId }),
            "",
        );

        return metadataFields;
    }

    async getMetadataFields(): Promise<{ item1: string; item2: number }[]> {
        const metadataFields = await this.get(
            new URLSearchParams({}),
            "GetAvailableMetadataFields",
        );

        return metadataFields;
    }

    async createQuestionMetadata(
        orgId,
        requestBody: QuestionMetadataRequest[],
    ) {
        const newQuestion = await this.post(
            JSON.stringify(requestBody),
            new URLSearchParams({ orgId }),
            "CreateQuestionMetadata",
        );
        return newQuestion;
    }
    async updateQuestionMetadata(
        orgId,
        requestBody: QuestionMetadataUpdateRequest[],
    ) {
        const newQuestion = await this.post(
            JSON.stringify(requestBody),
            new URLSearchParams({ orgId }),
            "UpdateQuestionMetadata",
        );
        return newQuestion;
    }
}
