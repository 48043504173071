import React from "react";
import { GridValueFormatterParams } from "@mui/x-data-grid-pro";
import moment from "moment";

export const BooleanFormatter =
    (fields: {
        renderTrue: React.ReactNode | null;
        renderFalse: React.ReactNode | null;
    }) =>
    ({ value }: GridValueFormatterParams): React.ReactNode =>
        value ? (
            fields.renderTrue === null ? (
                <></>
            ) : (
                fields.renderTrue
            )
        ) : fields.renderFalse === null ? (
            <></>
        ) : (
            fields.renderFalse
        );

export const BooleanComparator = (a: boolean, b: boolean) => {
    if (a === false && b === true) {
        return -1;
    }
    if (a === true && b === false) {
        return 1;
    }
    return 0;
};

export const customHighPrioritySortComparator = (v1, v2, param1, param2) => {
    const isv1HighPrio = v1;
    const isv2HighPrio = v2;

    const val1 = param1.api.getRow(param1.id)["currentLevelDueDate"];
    const val2 = param2.api.getRow(param2.id)["currentLevelDueDate"];

    const dueDate1 = val1 ? moment(val1) : null;
    const dueDate2 = val2 ? moment(val2) : null;

    if (isv1HighPrio && !isv2HighPrio) {
        return 1;
    } else if (isv2HighPrio && !isv1HighPrio) {
        return -1;
    } else {
        if (dueDate1 && dueDate2) {
            return dueDate1 > dueDate2 ? -1 : 1;
        } else if (!dueDate1) {
            return -1;
        } else if (!dueDate2) {
            return 1;
        }
    }

    return 0;
};
