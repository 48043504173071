import { Theme, Typography } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import React from "react";
import useStyles from "Styles/Styles";
import hexToRGB from "utils/hexToRGB";

const styles = (theme: Theme) =>
    createStyles({
        containerStyles: {
            height: "6px",
            width: "100%",
            backgroundColor: "#e0e0de",
            borderRadius: "2px",
            margin: "1px",
        },
        fillerStyles: {
            height: "100%",
            transition: "width 1s ease-in-out",
            borderRadius: "inherit",
            textAlign: "right",
        },
        labelStyles: {
            padding: 0,
            color: "white",
            fontWeight: "bold",
            fontSize: "5px",
        },
    });
interface IAcxProgress {
    //used for label and rendering background width
    percentComplete: string;
    //change the color of the progress bar
    backgroundColor: string;
    //show label in progress bar
    showInnerLabel: boolean;
    bottomLabel?: string | undefined;
}

const AcxProgress = (props: IAcxProgress) => {
    const classes = useStyles(styles);

    return (
        <div style={{ width: "100%" }}>
            <div className={classes.containerStyles}>
                <div
                    className={classes.fillerStyles}
                    style={{
                        width: `${props.percentComplete}%`,
                        backgroundColor: props.backgroundColor,
                    }}
                >
                    {props.showInnerLabel && (
                        <span className={classes.labelStyles}>
                            {`${props.percentComplete}%`}
                        </span>
                    )}
                </div>
            </div>
            {props.bottomLabel && (
                <Typography
                    component="div"
                    variant="caption"
                    style={{ color: hexToRGB(props.backgroundColor, 0.6) }}
                >
                    {props.bottomLabel}
                </Typography>
            )}
        </div>
    );
};

export default AcxProgress;
