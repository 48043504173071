import { Dialog, Grid, IconButton, Tooltip } from "@mui/material";
import ConversationsStore from "components/Conversations/Stores/ConversationsStore";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useStore } from "utils/useStore";
import CheckIcon from "@mui/icons-material/Check";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AcxMainTextField from "components/UI/AcxMainTextFieldGrid";
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from "@material-ui/core";
import theme from "Theme/AppTheme";
import AcxButton from "components/UI/AcxButton";
import { customSecondaryButtonStyle } from "utils/Style";

const ConversationFilterManagement: React.FunctionComponent = observer(() => {
    const store = useStore(ConversationsStore);

    const [editIndex, setEditIndex] = useState(-1);
    const [deleteIndex, setDeleteIndex] = useState(-1);

    return (
        <>
            {store.unsavedFilters.map((item, index) => (
                <Grid
                    container
                    sx={{
                        borderRadius: "8px",
                        border: `0.5px solid ${theme.palette.gray[200]}`,
                        boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.07)",
                        padding: "16px",
                        marginBottom: "8px",
                        backgroundColor: theme.palette.gray[50],
                    }}
                    key={index}
                >
                    <Grid container item xs={6} alignItems="center">
                        {editIndex === index ? (
                            <AcxMainTextField
                                id={"conversations-edit-filter-input" + index}
                                value={item.name}
                                onChange={(e) => {
                                    store.unsavedFilters[index] = {
                                        ...store.unsavedFilters[index],
                                        name: e.currentTarget.value,
                                    };
                                }}
                                endAdornment={
                                    <IconButton
                                        size="small"
                                        onClick={() => setEditIndex(-1)}
                                    >
                                        <CheckIcon />
                                    </IconButton>
                                }
                            />
                        ) : (
                            <Typography
                                style={{
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    lineHeight: "24px",
                                }}
                            >
                                {item.name}
                            </Typography>
                        )}
                    </Grid>
                    <Grid container item xs={6} justifyContent="flex-end">
                        {editIndex !== index && (
                            <Tooltip
                                title={
                                    editIndex !== -1
                                        ? "Can only edit 1 filter name at a time"
                                        : "Edit filter name"
                                }
                            >
                                <span>
                                    <IconButton
                                        size="small"
                                        onClick={() => setEditIndex(index)}
                                        disabled={editIndex !== -1}
                                    >
                                        <EditOutlinedIcon
                                            color={
                                                editIndex !== -1
                                                    ? "disabled"
                                                    : "primary"
                                            }
                                        />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        )}
                        <Tooltip title="Delete filter">
                            <span>
                                <IconButton
                                    onClick={() => {
                                        setDeleteIndex(index);
                                    }}
                                    size="small"
                                    sx={{ marginLeft: "24px" }}
                                >
                                    <DeleteOutlineIcon color="action" />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Grid>
                </Grid>
            ))}
            <DeleteFilterDialog
                deleteIndex={deleteIndex}
                onCancel={() => {
                    setEditIndex(-1);
                    setDeleteIndex(-1);
                }}
                onDelete={() => {
                    setEditIndex(-1);
                    store.setUnsavedFilters(
                        store.unsavedFilters.filter(
                            (item, i) => i !== deleteIndex,
                        ),
                    );
                    setDeleteIndex(-1);
                }}
            />
        </>
    );
});

interface DeleteFilterDialogProps {
    deleteIndex: number;
    onCancel: () => void;
    onDelete: () => void;
}

const DeleteFilterDialog: React.FC<DeleteFilterDialogProps> = ({
    deleteIndex,
    onDelete,
    onCancel,
}) => (
    <Dialog open={deleteIndex !== -1}>
        <Grid container width={theme.spacing(53)}>
            <Grid
                container
                item
                padding={3}
                justifyContent="center"
                borderRadius={theme.spacing(1)}
            >
                <Grid item>
                    <DialogTitle style={{ padding: 0 }}>
                        <Typography
                            style={{
                                fontSize: "20px",
                                fontWeight: 600,
                                padding: 0,
                            }}
                        >
                            Delete Filter
                        </Typography>
                    </DialogTitle>
                </Grid>
                <Grid item>
                    <DialogContent>
                        <Typography
                            style={{
                                fontSize: "14px",
                                fontWeight: 400,
                                color: theme.palette.gray.main,
                                padding: 0,
                                textAlign: "center",
                            }}
                        >
                            Deleting this filter is immediate and permanant. Are
                            you sure you want to delete?
                        </Typography>
                    </DialogContent>
                </Grid>
            </Grid>
            <Grid
                container
                item
                padding={3}
                justifyContent="center"
                borderRadius={theme.spacing(1)}
            >
                <Grid item>
                    <DialogActions style={{ padding: 0 }}>
                        <AcxButton
                            customRootStyles={customSecondaryButtonStyle}
                            onClick={onCancel}
                        >
                            Cancel
                        </AcxButton>
                        <AcxButton
                            customRootStyles={customSecondaryButtonStyle}
                            rootStyle={{
                                color: theme.palette.error.main,
                            }}
                            onClick={onDelete}
                        >
                            Delete Filter
                        </AcxButton>
                    </DialogActions>
                </Grid>
            </Grid>
        </Grid>
    </Dialog>
);

export default ConversationFilterManagement;
