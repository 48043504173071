import { observer } from "mobx-react";
import { IAppliedFilterChipComponent } from "../Chips/IAppliedFilterChipComponent";
import AppliedFilterChip from "../Chips/AppliedFilterChipBase";
import { useStore } from "utils/useStore";
import { ApplicationFiltersStore } from "stores/ApplicationFilters/ApplicationFiltersStore";
import React from "react";
import { ApplicationFilterValuesStore } from "stores/ApplicationFilters/ApplicationFilterValuesStore";

export const ContactTypesChips = observer(function (
    props: IAppliedFilterChipComponent,
) {
    const applicationFiltersStore = useStore(ApplicationFiltersStore);
    const applicationFilterValuesStore = useStore(ApplicationFilterValuesStore);

    const isContactTypeLabelsLoading =
        applicationFilterValuesStore.getTaskLoading(
            ApplicationFilterValuesStore.Tasks.LOAD_MAPPED_CUSTOMER_TYPES,
        );

    if (isContactTypeLabelsLoading) return null;

    return applicationFiltersStore.contactTypes.map((contactType) => (
        <AppliedFilterChip
            isApplicable={props.isApplicable}
            onRemove={() =>
                applicationFiltersStore.setContactTypes(
                    applicationFiltersStore.contactTypes.filter(
                        (type) => type !== contactType,
                    ),
                )
            }
        >
            {applicationFilterValuesStore.getContactTypeLabel(contactType)}
        </AppliedFilterChip>
    ));
});
