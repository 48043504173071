import { Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import SideControlsPage from "Layouts/SideControls/SideControlsPage";
import React from "react";
import { useParams } from "react-router-dom";
import useStyles from "Styles/Styles";
import OrganizationDetail from "./OrganizationDetail";
import OrganizationDetailNavigation from "./OrganizationDetailNavigation";
import OrganizationDetailRoutes from "./OrganizationDetailRoutes";

const styles = (theme: Theme) =>
    createStyles({
        sideControlMain: {
            padding: 0,
        },
    });

const OrganizationDetailContainer = () => {
    const classes = useStyles(styles);
    const { organizationId } = useParams();

    return organizationId === "add" ? (
        <OrganizationDetail />
    ) : (
        <SideControlsPage
            mainContent={
                <OrganizationDetailRoutes organizationId={organizationId!} />
            }
            enableMinimize
            containerOffsetPixels={64}
            drawerContent={
                <OrganizationDetailNavigation
                    organizationId={organizationId!}
                />
            }
            drawerLocation="left"
            mainGridAlignItems="stretch"
            mainGridSpacing={0}
            mainContentClasses={classes.sideControlMain}
        />
    );
};

export default OrganizationDetailContainer;
