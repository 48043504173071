import { Box, Divider, IconButton, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Grid } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { Routes } from "components/Navigation/Routes";
import { observer } from "mobx-react";
import React from "react";
import { useNavigate } from "react-router";
import { useStore } from "utils/useStore";
import ConversationsStore, {
    loadConversation,
} from "../../Stores/ConversationsStore";
import ConversationEvalCard from "./components/ConversationEvalCard";
import { durationDisplay } from "components/SoundClipEditor/Controls/Player";
import moment from "moment";
import ConversationClassifierChipList from "./components/ConversationClassifierChipList";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import { Stack } from "@mui/material";
import { emptyGUID } from "components/Admin/Organizations/OrganizationDetail/OrganizationModules/OrganizationModuleDetails/OrganizationModuleQuestionDependencies";
import clsx from "clsx";
import { FocusedAdditionalMetadata } from "./components/FocusedAdditionalMetadata";
import { interactionDateHelper } from "components/UI/AcxDataGrid/ColumnTypes/DateColTypes";
import theme from "Theme/AppTheme";

const useFocusedDrawerStyles = makeStyles((theme: Theme) => ({
    containerPadding: {
        padding: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    leftRightPadding: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    floatDivider: {
        marginTop: theme.spacing(2),
    },
    floatContainer: {
        position: "sticky",
        top: 0,
        backgroundColor: theme.palette.white.main,
        maxHeight: "90px",
        zIndex: 10,
    },
    paddingTop1: { paddingTop: theme.spacing(1) },
    paddingTop2: {
        paddingTop: theme.spacing(2),
    },
    backIconPadding: { paddingLeft: theme.spacing(1.5) },
    grayColor: { color: "#71717A" },
}));

const FocusedDrawer: React.FunctionComponent = observer(() => {
    const navigate = useNavigate();

    const store = useStore(ConversationsStore);

    const classes = useFocusedDrawerStyles();

    const classifierLabelStyles = {
        fontWeight: "bold",
        paddingBottom: theme.spacing(1),
    };

    const closeConversation = () => {
        store.setSelectedConversationId(undefined);
        navigate("/app/" + Routes.CONVERSATIONS);
    };

    const nonEmptyEval =
        store.selectedConversation?.evaluationNumber !== 0 &&
        store.selectedConversation?.evaluationId !== emptyGUID;

    return store.getTaskLoading(loadConversation) ? (
        <AcxLoadingIndicator size={24} alternate="PuffLoader" />
    ) : (
        <Stack style={{ width: "100%", maxWidth: "250px" }}>
            <Grid item xs={12} className={classes.floatContainer}>
                <IconButton
                    onClick={closeConversation}
                    className={classes.backIconPadding}
                    size="large"
                >
                    <ArrowBack />
                </IconButton>
                <Grid item className={classes.containerPadding}>
                    <Typography variant="h1">Details</Typography>
                </Grid>
                <Divider className={classes.floatDivider} />
            </Grid>
            <Grid
                container
                item
                direction="column"
                className={classes.paddingTop2}
            >
                <Grid item className={classes.leftRightPadding}>
                    <Box fontSize={14} className={classes.grayColor}>
                        Agent
                    </Box>
                    {store.selectedConversation?.agentFirstName
                        ? store.selectedConversation?.agentFirstName +
                          " " +
                          store.selectedConversation?.agentLastName
                        : "Unknown Agent"}
                </Grid>
                <Grid item className={classes.leftRightPadding}>
                    {store.hierarchyLevels?.map((value) => (
                        <React.Fragment key={value.id}>
                            <Box
                                fontSize={14}
                                className={clsx(
                                    classes.paddingTop2,
                                    classes.grayColor,
                                )}
                            >
                                {value.levelName}
                            </Box>

                            {value.name}
                        </React.Fragment>
                    )) ?? (
                        <>
                            <Box
                                fontSize={14}
                                className={clsx(
                                    classes.paddingTop2,
                                    classes.grayColor,
                                )}
                            >
                                Vendor
                            </Box>
                            Not Available
                            <Box
                                fontSize={14}
                                className={clsx(
                                    classes.paddingTop2,
                                    classes.grayColor,
                                )}
                            >
                                Brand
                            </Box>
                            Not Available
                        </>
                    )}
                </Grid>

                <Grid item className={classes.leftRightPadding}>
                    {store.selectedConversation?.isChat ? (
                        <>
                            <Box
                                fontSize={14}
                                className={clsx(
                                    classes.paddingTop2,
                                    classes.grayColor,
                                )}
                            >
                                Message Count
                            </Box>
                            {`${
                                store.selectedConversation.chatTranscription
                                    .length
                            } ${
                                store.selectedConversation.chatTranscription
                                    .length === 1
                                    ? "Message"
                                    : "Messages"
                            }`}
                        </>
                    ) : (
                        <>
                            <Box
                                fontSize={14}
                                className={clsx(
                                    classes.paddingTop2,
                                    classes.grayColor,
                                )}
                            >
                                Call Duration
                            </Box>
                            {durationDisplay(
                                (store.selectedConversation
                                    ?.callDurationMillis ?? 0) / 1000,
                            )}
                        </>
                    )}
                </Grid>
                <Grid item className={classes.leftRightPadding}>
                    <Box
                        fontSize={14}
                        className={clsx(classes.paddingTop2, classes.grayColor)}
                    >
                        Interaction Date
                    </Box>
                    {moment(
                        interactionDateHelper(
                            store.selectedConversation?.timestamp,
                        ),
                    ).format("MM/DD/YYYY")}
                </Grid>

                <Grid item className={classes.containerPadding} marginTop={2}>
                    <FocusedAdditionalMetadata />
                </Grid>
                {store.selectedConversation?.evaluationId &&
                    store.selectedConversation.evaluationNumber &&
                    nonEmptyEval && (
                        <>
                            <Divider />
                            <ConversationEvalCard
                                evaluationId={
                                    store.selectedConversation.evaluationId
                                }
                                evaluationNumber={
                                    store.selectedConversation.evaluationNumber
                                }
                            />
                        </>
                    )}
                <Divider />
                <Grid item className={classes.containerPadding}>
                    <Typography sx={classifierLabelStyles}>
                        ML Classifiers
                    </Typography>
                    <ConversationClassifierChipList
                        conversationClassifiers={
                            store.selectedConversation?.conversationClassifiers.filter(
                                (item) =>
                                    item.classifierType === "Tensorflow" ||
                                    item.name === "ML-Topic-Cluster",
                            ) ?? []
                        }
                        showAllChips
                    />
                </Grid>
                <Divider />
                <Grid className={classes.containerPadding}>
                    <Typography sx={classifierLabelStyles}>
                        Classifiers
                    </Typography>
                    <ConversationClassifierChipList
                        conversationClassifiers={
                            store.selectedConversation?.conversationClassifiers.filter(
                                (item) => item.classifierType === "Lucene",
                            ) ?? []
                        }
                        showAllChips
                    />
                </Grid>
            </Grid>
        </Stack>
    );
});

export default FocusedDrawer;
