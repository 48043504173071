import moment, { Moment } from "moment";
import { IAudioFileStatsModel } from "../models/AudioFileStats";
import { isNullableType } from "../utils/TypeGuards";
import BaseService from "./BaseService";

export type TemporalValue = "day" | "week" | "month" | "quarter" | "year";

export interface IFileProcessStatsService {
    getProcessedAudioStats(
        beginDate: moment.Moment,
        endDate: moment.Moment,
        temporalValue: TemporalValue,
        orgId?: string,
    ): Promise<IAudioFileStatsModel[]>;
}

export interface IProcessDirectoryStat {
    status: string;
    originalDirectory: string;
    arrivedDate: string;
    mediaType: string;
    dirId: string;
    count: number;
}

export interface IProcessAmd {
    id: string;
    fileName: string;
    origDirectoryPath: string;
    status: string;
    mediaType: string;
    arrivedOn: string;
}

export class FileProcessStatsService
    extends BaseService
    implements IFileProcessStatsService
{
    protected urlBase: string = "api/FileProcessStats";

    public async getOrgDirectoriesStats(
        orgId: string,
        beginDate: Moment,
        endDate: Moment,
    ): Promise<IProcessDirectoryStat[]> {
        let params = new URLSearchParams({
            orgId: orgId,
            beginDate: beginDate.format(),
            endDate: endDate.format(),
        });
        return await this.get(params, `InboundFileStats`);
    }

    public async getProcessedAudioStats(
        beginDate: moment.Moment,
        endDate: moment.Moment,
        temporalValue: TemporalValue,
        orgId?: string,
    ): Promise<IAudioFileStatsModel[]> {
        let urlSearchParams = new URLSearchParams({
            beginDate: beginDate.format(),
            endDate: endDate.format(),
            temporalValue,
        });

        if (!isNullableType(orgId)) {
            urlSearchParams.append("orgId", orgId);
        }

        return await this.get(urlSearchParams, `GetProcessedAudioStats`);
    }

    public async getAudioMetadataByDirectory(
        dirId: string,
        status: string,
        mediaType: string,
        arrivedOn: string,
    ): Promise<IProcessAmd[]> {
        let params = new URLSearchParams({
            dirId,
            status,
            mediaType,
            arrivedOn,
        });

        return await this.get(params, `GetAudioMetadataByDirectory`);
    }
}
