import { Grid, Typography, IconButton } from "@mui/material";
import { observer } from "mobx-react";
import React, { useState } from "react";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import { Box, Collapse, Divider } from "@material-ui/core";
import {
    AssignedWorkflowInstance,
    WorkflowStatus,
} from "models/Workflows/WorkflowInstance";
import AcxChip from "components/UI/AcxChip";
import AcxButton from "components/UI/AcxButton";
import { customSecondaryButtonStyle } from "utils/Style";
import { useNavigate } from "react-router";
import theme from "Theme/AppTheme";
import WorkflowSidebarCategory from "./WorkflowSidebarCategory";
import { getImplementationTypeText, getStatusText } from "./utils";
import { WorkflowConditionType } from "components/Admin/Organizations/OrganizationDetail/OrganizationWorkflows/Types/WorkflowModels";

interface WorkflowInstanceInfoProps {
    instance: AssignedWorkflowInstance;
    orgId?: string;
    hideDivider?: boolean;
}

const WorkflowInstanceInfo: React.FC<WorkflowInstanceInfoProps> = observer(
    ({ instance, orgId, hideDivider }) => {
        const navigate = useNavigate();

        const showCategory =
            instance.implementationType !==
            WorkflowConditionType.EvaluationDispute;
        return (
            <>
                <Grid container item xs={12} paddingBottom={1} paddingTop={1}>
                    <Grid item container xs={12}>
                        <Grid container item xs={6} marginRight={1}>
                            <Box>
                                <Box>
                                    <Typography color={theme.palette.gray.main}>
                                        Type
                                    </Typography>
                                </Box>
                                <Box paddingTop={0.25}>
                                    {getImplementationTypeText(
                                        instance.implementationType,
                                    )}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid container item xs={5}>
                            <Box>
                                <Box>
                                    <Typography color={theme.palette.gray.main}>
                                        Status
                                    </Typography>
                                </Box>
                                <Box>
                                    <AcxChip
                                        size="small"
                                        label={getStatusText(instance.status)}
                                        color={
                                            instance.status ===
                                            WorkflowStatus.Open
                                                ? "lightBlue"
                                                : instance.status ===
                                                  WorkflowStatus.Closed
                                                ? "secondary800"
                                                : "red"
                                        }
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    {showCategory && (
                        <Grid
                            container
                            item
                            xs={12}
                            marginBottom={1}
                            marginTop={1}
                        >
                            <WorkflowSidebarCategory instance={instance} />
                        </Grid>
                    )}
                    <Grid
                        container
                        item
                        xs={6}
                        justifyContent="flex-start"
                        marginTop={1}
                    >
                        <AcxButton
                            styles={{
                                ...customSecondaryButtonStyle,
                                margin: 0,
                            }}
                            onClick={() => {
                                navigate(
                                    `/workflow_review/${orgId ?? ""}/${
                                        instance.id
                                    }`,
                                );
                            }}
                            buttonDisabled={!orgId}
                        >
                            View Workflow
                        </AcxButton>
                    </Grid>
                </Grid>
                {!hideDivider && <Divider />}
            </>
        );
    },
);

interface WorkflowSidebarProps {
    instances: AssignedWorkflowInstance[];
    orgId?: string;
}

const WorkflowSidebar: React.FC<WorkflowSidebarProps> = observer(
    ({ instances, orgId }) => {
        const [open, setOpen] = useState(true);

        return (
            <Grid container padding={theme.spacing(0.5, 1.5, 0.5, 1.5)}>
                <Grid
                    container
                    item
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography
                        color={theme.palette.gray[700]}
                        fontSize="14px"
                        fontWeight={600}
                    >
                        Workflows
                    </Typography>
                    <IconButton
                        onClick={() => {
                            setOpen(!open);
                        }}
                    >
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </Grid>
                <Collapse in={open}>
                    <Box>
                        {instances.map((instance, idx) => (
                            <WorkflowInstanceInfo
                                orgId={orgId}
                                instance={instance}
                                hideDivider={idx === instances.length - 1}
                            />
                        ))}
                    </Box>
                </Collapse>
            </Grid>
        );
    },
);

export default WorkflowSidebar;
