import {
    IconButton,
    Menu,
    Typography,
    Popover,
    useTheme,
    styled,
    Divider,
} from "@mui/material";
import { ExpandMore, FilterAlt, Cancel } from "@mui/icons-material";
import { Grid } from "@mui/material";
import AcxButton from "components/UI/AcxButton";
import { observer } from "mobx-react";
import React, { useMemo, useRef, useState } from "react";
import { ApplicationFilterDomain } from "services/ApplicationFiltersService";
import {
    ApplicationFiltersStore,
    SavedFilter,
} from "stores/ApplicationFilters/ApplicationFiltersStore";
import { useStore } from "utils/useStore";
import ManageApplicationFilters from "./ManageApplicationFilters";
import { ApplicationFiltersInstanceStore } from "stores/ApplicationFilters/ApplicationFiltersInstanceStore";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";

interface ManageFiltersButtonProps {
    onCancel?(): void;
    onApply?(instanceStore: ApplicationFiltersInstanceStore): void;
    title?: string;
    subtitle?: string;
    savedFilterDomains?:
        | readonly ApplicationFilterDomain[]
        | ApplicationFilterDomain[];
}

const ManageFiltersButton = observer(function (
    props: ManageFiltersButtonProps,
) {
    const theme = useTheme();

    const applicationFiltersStore = useStore(ApplicationFiltersStore);
    const anchorEl = useRef<HTMLElement | null>(null);
    const [isSavedFiltersOpen, setIsSavedFiltersOpen] = useState(false);
    const [isManageFiltersOpen, setIsManageFiltersOpen] = useState(false);

    const filterLabel = useMemo(() => {
        const appliedFilterCount =
            applicationFiltersStore.appliedFilterCount ?? 0;
        const selectedFilterName =
            applicationFiltersStore.selectedSavedFilter?.name;

        if (!!selectedFilterName) return selectedFilterName;
        else if (appliedFilterCount === 1) return "1 Filter Applied";
        else if (appliedFilterCount > 1)
            return `${appliedFilterCount} Filters Applied`;
        return "Filter";
    }, [
        applicationFiltersStore.selectedSavedFilter,
        applicationFiltersStore.appliedFilterCount,
    ]);

    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                    padding: 0,
                    borderRadius: theme.spacing(0.5),
                    border: `1px solid ${theme.palette.grey[300]}`,
                    width: "fit-content",
                    backgroundColor: theme.palette.white.main,
                }}
                width="fit-content"
                flexWrap="nowrap"
            >
                <Grid item ref={(elRef) => (anchorEl.current = elRef)}>
                    <AcxButton
                        onClick={() => {
                            setIsSavedFiltersOpen(false);
                            setIsManageFiltersOpen((open) => !open);
                        }}
                        variant="outlined"
                        color="secondary"
                        leftRightSpacing={0}
                        fullWidth={false}
                        tooltip={filterLabel}
                        sx={{
                            color: "black",
                            border: "none",
                            borderRight: `1px solid ${theme.palette.grey[300]}`,
                            borderRadius: 0,
                            fontWeight: "normal",

                            "&:hover": {
                                cursor: "pointer",
                                backgroundColor: "transparent",
                                border: "none",
                                borderRight: `1px solid ${theme.palette.grey[300]}`,
                                color: "black",
                            },
                        }}
                    >
                        <FilterAlt
                            fontSize="small"
                            sx={(theme) => ({
                                marginRight: theme.spacing(1),
                            })}
                            color="action"
                        />
                        <Typography
                            sx={{
                                maxWidth: "164px",
                                overflowX: "hidden",
                                textOverflow: "ellipsis",
                            }}
                        >
                            {filterLabel}
                        </Typography>
                        {!!applicationFiltersStore.selectedSavedFilter && (
                            <IconButton
                                size="small"
                                sx={(theme) => ({
                                    marginLeft: theme.spacing(1),
                                    fontSize: "16px",
                                })}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    applicationFiltersStore.setSelectedSavedFilters();
                                    applicationFiltersStore.resetFilters();
                                }}
                            >
                                <Cancel
                                    fontSize="inherit"
                                    htmlColor={theme.palette.gray[300]}
                                />
                            </IconButton>
                        )}
                    </AcxButton>
                </Grid>
                <Grid item>
                    <IconButton
                        size="small"
                        onClick={() => {
                            setIsManageFiltersOpen(false);
                            setIsSavedFiltersOpen((open) => !open);
                        }}
                    >
                        <ExpandMore />
                    </IconButton>
                </Grid>
            </Grid>
            <Popover
                open={isManageFiltersOpen}
                onClose={() => setIsManageFiltersOpen(false)}
                anchorEl={anchorEl.current}
                anchorReference="anchorEl"
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: -8,
                    horizontal: 0,
                }}
                slotProps={{
                    paper: {
                        sx: {
                            width: "65dvw",
                            height: "55dvh",
                            borderRadius: "8px",
                        },
                    },
                }}
            >
                <ManageApplicationFilters
                    onApply={(instanceStore) => {
                        if (!applicationFiltersStore.selectedSavedFilter) {
                            const quickFilters =
                                applicationFiltersStore.quickFiltersStore;
                            instanceStore.setStartDate(quickFilters.startDate);
                            instanceStore.setEndDate(quickFilters.endDate);
                            instanceStore.setDateReferenceOption(
                                quickFilters.dateReferenceOption,
                            );
                            instanceStore.setDateRangeLabel(
                                quickFilters.dateRangeLabel,
                            );
                            instanceStore.setHierarchyIds(
                                quickFilters.hierarchyIds,
                            );
                        }

                        applicationFiltersStore.applyExternalFilters(
                            instanceStore,
                        );
                        applicationFiltersStore.quickFiltersStore.applyExternalFilters(
                            instanceStore,
                        );
                        props.onApply?.(instanceStore);
                        setIsManageFiltersOpen(false);
                    }}
                    onCancel={() => {
                        props.onCancel?.();
                        setIsManageFiltersOpen(false);
                    }}
                    title={props.title}
                    subtitle={props.subtitle}
                />
            </Popover>
            <Menu
                open={isSavedFiltersOpen}
                onClose={() => setIsSavedFiltersOpen(false)}
                anchorEl={anchorEl.current}
                anchorReference="anchorEl"
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: -8,
                    horizontal: 0,
                }}
                slotProps={{
                    paper: {
                        sx: (theme) => ({
                            padding: theme.spacing(1),
                            borderRadius: theme.spacing(1 / 2),
                        }),
                    },
                }}
            >
                <SavedFiltersList
                    onSelect={(filter) => {
                        applicationFiltersStore.applySavedFilters(filter.id);
                        setIsSavedFiltersOpen(false);
                    }}
                    ignoreChildrenWhenNoSavedFilters={false}
                >
                    <SavedFiltersList.Action
                        onClick={() => {
                            setIsSavedFiltersOpen(false);
                            setIsManageFiltersOpen(true);
                        }}
                    >
                        + Add Saved Filter
                    </SavedFiltersList.Action>
                </SavedFiltersList>
            </Menu>
        </>
    );
});

export default ManageFiltersButton;

interface SavedFiltersListProps {
    domains?: ApplicationFilterDomain[];
    onSelect?(filter: SavedFilter): void;
    ignoreChildrenWhenNoSavedFilters?: boolean;
}

const SavedFilterOption = styled(AcxButton)(({ theme }) => ({
    margin: "none",
    padding: theme.spacing(1),
    color: theme.palette.gray[600],
    backgroundColor: theme.palette.white.main,
    justifyContent: "start",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    display: "flex",
    ":hover": {
        backgroundColor: theme.palette.primary[100],
        color: theme.palette.primary[500],
    },
}));

const OptionText = styled("span")(() => ({
    maxWidth: "40ch",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
}));

const GroupTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.gray[500],
    fontWeight: "bold",
    fontSize: "12px",
}));

export function SavedFiltersList({
    children,
    domains,
    ignoreChildrenWhenNoSavedFilters = true,
    onSelect,
    ...props
}: React.PropsWithChildren<
    SavedFiltersListProps & React.ComponentPropsWithoutRef<typeof Grid>
>) {
    const applicationFiltersStore = useStore(ApplicationFiltersStore);

    const isLoading = applicationFiltersStore.getTaskLoading(
        ApplicationFiltersStore.Tasks.LOAD_SAVED_FILTERS,
    );

    if (isLoading)
        return <AcxLoadingIndicator size={32} alternate="PuffLoader" />;

    const savedFilters: SavedFilter[] =
        applicationFiltersStore.getSavedFiltersByDomains(
            domains ?? ApplicationFiltersStore.AllSavedFilterDomains,
        );

    const showChildren =
        !ignoreChildrenWhenNoSavedFilters ||
        !(ignoreChildrenWhenNoSavedFilters && savedFilters.length === 0);

    return (
        <Grid
            direction="column"
            rowGap={1}
            {...props}
            container
            item
            sx={{ fontSize: "14px", maxWidth: "min-content", minWidth: "40ch" }}
        >
            {savedFilters.length === 0 && (
                <Grid item>
                    <Typography sx={(theme) => ({ padding: theme.spacing(1) })}>
                        No Saved Filters
                    </Typography>
                </Grid>
            )}
            {savedFilters.length > 0 && (
                <React.Fragment>
                    <Grid container item>
                        <Grid item>
                            <GroupTitle>My Filters</GroupTitle>
                        </Grid>
                        <Grid container item direction="column" rowGap={1 / 2}>
                            {applicationFiltersStore.mySavedFilters.map(
                                (filter) => (
                                    <Grid item key={filter.id}>
                                        <SavedFilterOption
                                            leftRightSpacing={0}
                                            fullWidth
                                            tooltip={filter.name}
                                            onClick={() => onSelect?.(filter)}
                                        >
                                            <OptionText>
                                                {filter.name}
                                            </OptionText>
                                        </SavedFilterOption>
                                    </Grid>
                                ),
                            )}
                        </Grid>
                    </Grid>
                    <Grid container item>
                        <Grid item>
                            <GroupTitle>Shared Filters</GroupTitle>
                        </Grid>
                        <Grid container item direction="column" rowGap={1 / 2}>
                            {applicationFiltersStore.sharedSavedFilters.map(
                                (filter) => (
                                    <Grid item key={filter.id}>
                                        <SavedFilterOption
                                            leftRightSpacing={0}
                                            fullWidth
                                            tooltip={filter.name}
                                            onClick={() => onSelect?.(filter)}
                                        >
                                            <OptionText>
                                                {filter.name}
                                            </OptionText>
                                        </SavedFilterOption>
                                    </Grid>
                                ),
                            )}
                        </Grid>
                    </Grid>
                </React.Fragment>
            )}
            {showChildren && React.Children.count(children) > 0 && (
                <Grid item>
                    <Divider />
                </Grid>
            )}
            {showChildren && children}
        </Grid>
    );
}
SavedFiltersList.Action = SavedFiltersListAction;

const SavedFiltersListActionButton = styled(AcxButton)(({ theme }) => ({
    justifyContent: "start",
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.white.main,
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "20px",
    padding: theme.spacing(1),
    ":hover": {
        backgroundColor: theme.palette.gray[100],
    },
}));

function SavedFiltersListAction(
    props: React.ComponentPropsWithoutRef<typeof SavedFiltersListActionButton>,
) {
    return (
        <Grid item>
            <SavedFiltersListActionButton
                leftRightSpacing={0}
                fullWidth
                {...props}
            />
        </Grid>
    );
}
