import { extractCSVFiles } from "components/Admin/AudioUploader/CSVExtractor";
import type {
    ICSVFormat,
    IExtractedCSV,
} from "components/Admin/AudioUploader/CSVExtractor";
import { readTextFilesAsync } from "components/Admin/AudioUploader/UploaderUtils";
import type {
    IDataMatchResults,
    IMatchedCSV,
    IMatchResult,
    IReadFileResult,
    IUploadRecord,
} from "components/Admin/AudioUploader/UploaderUtils";
import Immutable from "immutable";
import {
    action,
    computed,
    makeObservable,
    observable,
    observe,
    runInAction,
} from "mobx";
import AudioMetadataUiModel from "models/AudioMetadataUiModel";
import DirectoryInfoModel from "models/DirectoryInfoModel";
import Organization from "models/Organization";
import moment from "moment";
import { createContext } from "react";
import AudioMetadataModel from "../models/AudioMetadataModel";
import { BlobDirectoryService } from "../services/BlobDirectoryService";
import { delay } from "../utils/helpers";
import { isNullableType, isUndefinedType } from "../utils/TypeGuards";
import { AcxStore } from "./RootStore";
import type { IRootStore } from "./RootStore";

export type AudioFileSortType = "filename" | "duration";

@AcxStore
export class BlobDirectoryStore {
    @observable.deep DirList: DirectoryInfoModel[] = [];

    @observable.deep TargetList: DirectoryInfoModel = new DirectoryInfoModel();

    @observable.deep RecommendedList?: DirectoryInfoModel;

    @observable MetaFiles: File[] = [];

    @observable beginDate: moment.Moment = moment().subtract(1, "week");
    @observable endDate: moment.Moment = moment().endOf("day");

    @observable loading: boolean = false;
    OrgId = observable.box("");

    // @observable sortField: "filename" | "duration"; // = "filename";
    sortField = observable.box<AudioFileSortType>("duration");

    @observable transferReady: boolean = false;

    @action
    updateTransferReady(val) {
        this.transferReady = val;
    }

    @action
    async setRecommendedList(files: AudioMetadataUiModel[]) {
        const recommendedDir = new DirectoryInfoModel();
        recommendedDir.currDirectory = "Recommendation/";
        recommendedDir.numSampledFiles = 0;
        recommendedDir.numUnsampledFiles = files.length;
        recommendedDir.children = observable.array() as AudioMetadataUiModel[];
        recommendedDir.children.push(
            ...files.map((value) => {
                value.isRecommended = true;
                value.includedInSample = true;
                return value;
            }),
        );

        this.RecommendedList = recommendedDir;
        await delay(0);
        this.clearTarget().then((value) => {
            this.addItemToTarget(...files);
        });
    }

    @action
    clearRecommendList() {
        this.clearTarget()
            .then((value) => delay(0))
            .then((value) => {
                runInAction(() => (this.RecommendedList = undefined));
            });
    }

    private onAddToTargetList?: (...val: AudioMetadataModel[]) => void;
    private onRemoveFromTargetList?: (...val: AudioMetadataModel[]) => void;

    setTargetChangeListeners(
        onAddToTargetCallback: (...val: AudioMetadataModel[]) => void,
        onRemoveFromTargetCallback: (...val: AudioMetadataModel[]) => void,
    ) {
        this.onAddToTargetList = onAddToTargetCallback;
        this.onRemoveFromTargetList = onRemoveFromTargetCallback;
    }

    clearTargetChangeListeners() {
        this.onAddToTargetList = undefined;
        this.onRemoveFromTargetList = undefined;
    }

    constructor(private rootStore?: IRootStore) {
        makeObservable(this);
        this.bdService = new BlobDirectoryService();
        observe(this.sortField, async (change) => {
            if (this.TargetList) {
                runInAction(() => {
                    this.TargetList.children.splice(
                        0,
                        this.TargetList.children.length,
                        ...this.sortAudioMetadata(this.TargetList.children),
                    );
                });
            }

            if (this.DirList) {
                const arr = await this.findAllAudioMetadata();
                for (let i = 0; i < arr.length; i++) {
                    let item = arr[i];
                    runInAction(() => {
                        item.children.splice(
                            0,
                            item.children.length,
                            ...this.sortAudioMetadata(item.children),
                        );
                    });
                }
            }
        });
        observe(this.TargetList.children, (change) => {
            if (
                this.TargetList.children &&
                this.TargetList.children.length > 0
            ) {
                this.updateTransferReady(true);
            } else {
                this.updateTransferReady(true);
            }
        });
    }

    private selectionStart?: {
        dir: DirectoryInfoModel;
        item: AudioMetadataUiModel;
    };
    private selectionEnd?: {
        dir: DirectoryInfoModel;
        item: AudioMetadataUiModel;
    };

    private bdService: BlobDirectoryService;

    @observable.ref private _Org: Organization | undefined;

    @action
    async readFilesAndMatchMetadata(
        csvFormat: ICSVFormat,
    ): Promise<IDataMatchResults> {
        const metaPromises = readTextFilesAsync(this.MetaFiles);

        let metaObjects = await Promise.all(metaPromises);

        let unmatchedAudioFileNames = Immutable.Set(
            this.TargetList.children.map((tgt) => tgt.fileName),
        );

        const audioFiles = this.TargetList.children.map((value) => {
            let read: IReadFileResult = {
                fileName: value.fileName,
                status: "ok",
            };
            return read;
        });

        let matchedMetaDataFiles = Immutable.List<IMatchedCSV>();

        const fileSystemErrors = Immutable.List(
            metaObjects
                .filter((obj) => obj.status === "error")
                .map((obj) => obj.fileName),
        );

        metaObjects = metaObjects.filter((obj) => obj.status === "ok");

        // TODO: this is only valid for csv formats
        const extractedFiles = extractCSVFiles(metaObjects, csvFormat);

        for (const extractedFile of extractedFiles) {
            const result = this.matchMetadata(audioFiles, extractedFile);
            matchedMetaDataFiles = matchedMetaDataFiles.push(result.matchedCSV);
            unmatchedAudioFileNames = unmatchedAudioFileNames.intersect(
                result.unmatchedAudioFiles,
            );
        }

        const unmatchedAudioFiles = Immutable.List(
            audioFiles.filter((o) => unmatchedAudioFileNames.has(o.fileName)),
        );

        return { matchedMetaDataFiles, unmatchedAudioFiles, fileSystemErrors };
    }

    matchMetadata(
        audioFileReadResults: IReadFileResult[],
        extractedCSV: IExtractedCSV,
    ): IMatchResult {
        function stripExtension(fileName: string) {
            return fileName.replace(/\.[^/.]+$/, "");
        }

        type AudioFile = {
            originalName: string;
            strippedName: string;
            hasMatch: boolean;
            readResult: IReadFileResult;
        };

        let matchedCSV: IMatchedCSV = {
            ...extractedCSV,
        };

        if (matchedCSV.rows && matchedCSV.rows.size > 0) {
            // If there are rows to match, match them against the audio files
            let audioFilesByStrippedName = Immutable.Map<string, AudioFile>();

            for (const readResult of audioFileReadResults) {
                const originalName = readResult.fileName;
                const strippedName = stripExtension(originalName);
                const file = {
                    originalName,
                    strippedName,
                    readResult,
                    hasMatch: false,
                };
                audioFilesByStrippedName = audioFilesByStrippedName.set(
                    strippedName,
                    file,
                );
            }

            for (const row of matchedCSV.rows) {
                if (!row.fileIdentifier) {
                    continue;
                }

                let foundFileName: string = "";
                if (audioFilesByStrippedName.has(row.fileIdentifier)) {
                    foundFileName = row.fileIdentifier;
                } else if (
                    audioFilesByStrippedName.has(
                        stripExtension(row.fileIdentifier),
                    )
                ) {
                    foundFileName = stripExtension(row.fileIdentifier);
                }

                if (foundFileName !== "") {
                    const audioFile =
                        audioFilesByStrippedName.get(foundFileName)!; //cannot be null because of the
                    // "has" checks above
                    row.matchedFile = audioFile.readResult;
                    audioFile!.hasMatch = true;
                    audioFilesByStrippedName.set(foundFileName, audioFile);
                }
            }

            return {
                matchedCSV,
                unmatchedAudioFiles: Immutable.Set(
                    audioFilesByStrippedName
                        .filter((af) => !af.hasMatch)
                        .map((af) => af.originalName)
                        .values(),
                ),
            };
        } else {
            return {
                matchedCSV,
                unmatchedAudioFiles: Immutable.Set(
                    audioFileReadResults.map((x) => x.fileName),
                ),
            };
        }
    }

    @action reset() {
        this.DirList.splice(0);
        this.TargetList.children?.splice(0);
    }

    @action
    async executeTransfer(orgId: string, batch: string, record: IUploadRecord) {
        const tgtItem = this.TargetList.children.find(
            (f) => f.fileName === record.fileName,
        );

        if (tgtItem) {
            runInAction(() => {
                tgtItem.currentStatus = "Loading";
            });

            let res;
            try {
                res = await this.bdService.transferFiles(
                    orgId,
                    batch,
                    record.metaData.timestamp ? record.metaData.timestamp : "",
                    record.metaData.agentName ? record.metaData.agentName : "",
                    record.metaData.callDirection
                        ? record.metaData.callDirection
                        : "",
                    {
                        SourceFolder: tgtItem?.directory!.thisPath!,
                        FileName: record.fileName,
                    },
                );
                if (res.ok) {
                    runInAction(() => {
                        tgtItem.currentStatus = "Complete";
                        this.TargetList.children.splice(
                            this.TargetList.children.findIndex(
                                (f) => f.fileName === tgtItem.fileName,
                            ),
                            1,
                        );
                    });
                } else {
                    runInAction(() => {
                        tgtItem.currentStatus = "Complete";
                        console.log(res);
                    });
                }
            } catch (err) {
                runInAction(() => {
                    tgtItem.currentStatus = "Complete";
                    console.log(err);
                });
            }
        }
    }

    @computed
    get Org(): Organization | undefined {
        return this._Org;
    }

    set Org(value: Organization | undefined) {
        this.SetOrg(value);
    }

    @computed get DirCount() {
        return this.DirList.length;
    }

    @computed get DirsExist() {
        if (this.DirList.length > 0) {
            return true;
        }
        return false;
    }

    @action
    addSelection = async (
        parent: string,
        fileName: string,
        whichList: "src" | "tgt",
    ) => {
        let item;
        let obj;
        if (whichList === "src") {
            obj = await this.findSrcItemAndDir(parent, fileName);
        } else {
            if (!this.TargetList.children) {
                this.TargetList.children = new Array<AudioMetadataUiModel>();
            }
            item = await this.findTgtItem(fileName);
            obj = { dir: this.TargetList, item: item };
        }

        if (this.selectionEnd) {
            this.clearSelections();
        }

        if (!this.selectionStart) {
            this.beginSelection(obj);
        } else if (this.selectionStart && !this.selectionEnd) {
            this.completeSelection(obj);
        }
    };

    @action
    completeSelection = (obj: {
        dir: DirectoryInfoModel;
        item: AudioMetadataUiModel;
    }) => {
        const looper: AudioMetadataUiModel[] = [];
        obj.item.selected = true;

        this.selectionEnd = obj;

        if (obj.item && obj.dir.children) {
            const selStartIndex = obj.dir.children.findIndex(
                (s) => s.id === this.selectionStart?.item.id,
            );
            const selEndIndex = obj.dir.children.findIndex(
                (s) => s.id === this.selectionEnd?.item.id,
            );
            //swap objects if they are out of order
            if (selStartIndex > selEndIndex) {
                this.selectionEnd = this.selectionStart;
                this.selectionStart = obj;
            }

            let tracker = false;
            let startIndex = 0;
            obj.dir.children.forEach((value, index) => {
                if (value === this.selectionStart?.item) {
                    tracker = true;
                    if (
                        this.selectionStart.item.id ===
                        this.selectionEnd?.item.id
                    ) {
                        tracker = false;
                    }
                    value.selected = true;
                    startIndex = index;
                    looper.push(value);
                } else if (value === this.selectionEnd?.item) {
                    tracker = false;
                    value.selected = true;
                    looper.push(value);
                } else if (!tracker) {
                    value.selected = false;
                } else {
                    value.selected = true;
                    looper.push(value);
                }
            });
            setTimeout(() => {
                this.moveItems(obj.dir.children, looper, startIndex);
            }, 250);
        }
    };

    @action
    beginSelection = (obj: {
        dir: DirectoryInfoModel;
        item: AudioMetadataUiModel;
    }) => {
        if (obj.item) {
            obj.item.selected = true;
            this.selectionStart = obj;
        }
    };

    @action
    SetOrg(value: Organization | undefined) {
        if (!isUndefinedType(value) && !isUndefinedType(value.id)) {
            this._Org = value;
            this.OrgId.set(value.id);
            this.getDirs(value.id).catch((reason) =>
                console.error(`BlobDirectoryStore::getDirs failed: ${reason}`),
            );
        }
    }

    private sortAudioMetadata(
        arr: AudioMetadataUiModel[],
        justFetched: boolean = false,
    ) {
        let sortType = this.sortField.get();

        if (justFetched && sortType === "duration") {
            return arr;
        }
        if (sortType === "filename") {
            return arr.slice().sort((a, b) => {
                return a.fileName.localeCompare(b.fileName);
            });
        } else {
            return arr.slice().sort((a, b) => {
                return (
                    (b.callDurationMillis ?? 0) - (a.callDurationMillis ?? 0)
                );
            });
        }
    }

    private clearSelections = () => {
        this.deSelect();
        this.selectionStart = undefined;
        this.selectionEnd = undefined;
    };

    @action
    private deSelect = () => {
        const ar = this.selectionStart?.dir.children;
        ar?.some((value, index) => {
            value.selected = false;
            return value === this.selectionEnd?.item;
        });
    };

    @action
    async getDirs(orgId: string, prefix: string = "") {
        throw new Error("not used says Rita");
        /*
        if (
            isUndefinedType(this._Org) ||
            isNullableType(this._Org?.fileInStageAccount)
        ) {
            throw new Error("OrgId must be non null");
        }
        this.loading = true;
        const dList = await this.bdService.getDirsAndStats(
            orgId,
            prefix,
            this._Org.fileInStageAccount.replace(
                "-storage-connection-string",
                "",
            ),
            this.beginDate.startOf("date"),
            this.endDate.endOf("date"),
        );

        runInAction(() => {
            this.DirList = dList;
            this.loading = false;
        });
    */
    }

    @action
    async getSubDirs(orgId: string, prefix: string) {
        throw new Error("not used says Rita");
        /*
        if (
            isUndefinedType(this._Org) ||
            isNullableType(this._Org?.fileInStageAccount)
        ) {
            throw new Error("OrgId must be non null");
        }
        this.loading = true;
        const dList = await this.bdService.getDirsAndStats(
            orgId,
            prefix,
            this._Org.fileInStageAccount!.replace(
                "-storage-connection-string",
                "",
            ),
            this.beginDate.startOf("date"),
            this.endDate.endOf("date"),
        );
        const dirParent = await this.findDir(prefix);

        runInAction(() => {
            if (dList && dList.length > 0) {
                const obj = dList[0];
                dirParent.numSampledFiles = obj.numSampledFiles;
                dirParent.numUnsampledFiles = obj.numUnsampledFiles;
                dirParent.subDirectories = obj.subDirectories;
            }

            this.loading = false;
        });
        */
    }

    private async findAllAudioMetadata(): Promise<DirectoryInfoModel[]> {
        const arr: DirectoryInfoModel[] = [];
        for (let i = 0; i < this.DirList.length; i++) {
            const item = this.DirList[i];
            if (item.children) {
                arr.push(item);
            }
            if (item.subDirectories) {
                await delay(0);
                arr.push(
                    ...(await this.recurseSubDirs(item.subDirectories, arr)),
                );
            }
        }
        return arr;
    }

    findSrcItemAndDir = async (
        dir: string,
        fileName: string,
    ): Promise<{
        dir: DirectoryInfoModel;
        item: AudioMetadataUiModel | undefined;
    }> => {
        const d = await this.findDir(dir);
        const fil = d.children?.find((c) => c.fileName === fileName);
        return { dir: d, item: fil };
    };

    findDir(prefix: string): DirectoryInfoModel {
        if (!isUndefinedType(this.RecommendedList)) {
            return this.RecommendedList;
        } else {
            let res = this.DirList.find((d) => d.currDirectory === prefix);
            if (res) {
                return res;
            }
            return this.searchDir(prefix, this.DirList);
        }
    }

    private searchDir(
        prefix: string,
        arr: DirectoryInfoModel[],
    ): DirectoryInfoModel {
        let searchRes;

        for (let i = 0; i < arr.length; i++) {
            const item = arr[i];
            if (item.currDirectory === prefix) {
                searchRes = item;
                break;
            } else if (item.subDirectories) {
                searchRes = this.searchDir(prefix, item.subDirectories);
                if (searchRes) {
                    break;
                }
            }
        }
        return searchRes;
    }

    @action
    async clearTarget() {
        if (isUndefinedType(this.RecommendedList)) {
            const targetFiles = [...this.TargetList.children];
            this.TargetList.children.splice(0, targetFiles.length);

            await delay(0);

            const result = targetFiles.reduce((map, obj) => {
                if (obj.origDirectoryPath) {
                    map[obj.origDirectoryPath] = this.findDir(
                        obj.origDirectoryPath,
                    );
                }

                return map;
            }, {} as Record<string, DirectoryInfoModel>);

            await delay(0);

            const targetFilesMap = new Map<
                DirectoryInfoModel,
                AudioMetadataUiModel[]
            >();
            for (let targetChild of targetFiles) {
                if (targetChild.origDirectoryPath) {
                    const dir = result[targetChild.origDirectoryPath];

                    let arr: AudioMetadataUiModel[] | undefined;
                    if ((arr = targetFilesMap.get(dir))) {
                        arr.push(targetChild);
                        targetFilesMap.set(dir, arr);
                    } else {
                        targetFilesMap.set(dir, [targetChild]);
                    }
                }
            }
            await delay(0);

            for (let targetFilesMapElement of targetFilesMap) {
                const [dirModel, listAms] = targetFilesMapElement;
                this.addItemToSource(dirModel, ...listAms);
            }
        } else {
            this.TargetList.children.splice(0, this.TargetList.children.length);
        }
    }

    @action
    async getFilesAndMoveToTarget(
        orgId: string,
        prefix: string,
        numUnSampled: number,
    ) {
        if (
            isUndefinedType(this._Org) ||
            isNullableType(this._Org?.fileInStageAccount)
        ) {
            throw new Error("OrgId must be non null");
        }

        if (
            this.TargetList.children.some(
                (value) => value.origDirectoryPath !== prefix,
            )
        ) {
            await this.clearTarget();
            await delay(29);
        }

        const metaViewCount = 8;

        const existingFilesInTargetFromPrefix = this.TargetList.children.some(
            (value) => value.origDirectoryPath === prefix,
        );

        if (existingFilesInTargetFromPrefix) {
            return;
        }

        let files;
        const dirParent = await this.findDir(prefix);
        if (
            dirParent &&
            dirParent.children?.length &&
            (dirParent.children.length === numUnSampled ||
                dirParent.children.length >= metaViewCount)
        ) {
            files = dirParent.children.slice(0, metaViewCount);
        } else {
            runInAction(() => (this.loading = true));
            files = await this.bdService.getSampleFiles(
                orgId,
                dirParent.currDirectoryId!,
                this.beginDate.startOf("date"),
                this.endDate.endOf("date"),
                numUnSampled > metaViewCount
                    ? `${metaViewCount}`
                    : numUnSampled.toString(10),
            );
        }

        await delay(0);

        if (!isUndefinedType(dirParent) && files.length > 0) {
            runInAction(() => this.removeItemFromSource(dirParent, ...files));

            runInAction(() => this.addItemToTarget(...files));

            await delay(0);
        }

        runInAction(() => (this.loading = false));
    }

    @action
    async getFiles(orgId: string, prefix: string, sampleSize: string) {
        if (
            isUndefinedType(this._Org) ||
            isNullableType(this._Org?.fileInStageAccount)
        ) {
            throw new Error("OrgId must be non null");
        }

        runInAction(() => (this.loading = true));
        let parsedSampleSize = parseInt(sampleSize, 10);
        if (parsedSampleSize > 100) {
            parsedSampleSize = 100;
        }

        const dirParent = await this.findDir(prefix);
        if (
            dirParent &&
            dirParent.children?.length &&
            dirParent.children.length >= parsedSampleSize
        ) {
            runInAction(() => (this.loading = false));
            return {
                files: dirParent.children.splice(
                    0,
                    dirParent.children.length,
                    ...dirParent.children.slice(0, parsedSampleSize),
                ),
                directory: dirParent,
            };
        }

        const dFiles = await this.bdService.getSampleFiles(
            orgId,
            dirParent.currDirectoryId!,
            this.beginDate.startOf("date"),
            this.endDate.endOf("date"),
            sampleSize,
        );

        await delay(0);

        const newlyFetched = this.sortAudioMetadata(dFiles, true).filter(
            (fetched) => {
                return (
                    !dirParent.children?.some(
                        (existing) =>
                            existing.origFileKey === fetched.origFileKey,
                    ) &&
                    !this.TargetList.children?.some(
                        (existing) =>
                            existing.origFileKey === fetched.origFileKey,
                    )
                );
            },
        );
        await delay(0);
        runInAction(() => {
            this.addItemToSource(dirParent, ...newlyFetched);
        });

        runInAction(() => {
            this.loading = false;
        });

        return { files: dFiles, directory: dirParent };
    }

    @action
    moveItems = (
        srcObj: AudioMetadataUiModel[],
        moveList: AudioMetadataUiModel[],
        startIndex: number,
    ) => {
        srcObj.splice(startIndex, moveList.length);
        for (let i = 0; i < moveList.length; i++) {
            const element = moveList[i];
            element.selected = false;
        }
        this.addItemToTarget(...moveList);
    };

    async recurseSubDirs(
        item: DirectoryInfoModel[],
        arr: DirectoryInfoModel[],
    ): Promise<DirectoryInfoModel[]> {
        for (const element of item) {
            if (element.children) {
                arr.push(element);
            }
            if (element.subDirectories) {
                const ch = await this.recurseSubDirs(
                    element.subDirectories,
                    arr,
                );
                if (ch) {
                    arr.push(...ch);
                }
            }
        }
        return arr;
    }

    @action
    removeItemFromSource = (
        parent: DirectoryInfoModel,
        ...items: AudioMetadataUiModel[]
    ) => {
        for (let item of items) {
            parent.children?.splice(
                parent.children.findIndex((x) => x.fileName === item.fileName),
                1,
            );
        }
    };

    @action
    addItemToSource = (
        dir: DirectoryInfoModel,
        ...item: AudioMetadataUiModel[]
    ) => {
        if (!dir.children) {
            // NOTE JPC dir is undefined on clearMetaView
            dir.children = observable.array(item);
        } else {
            dir.children.push(...item);
        }
    };

    @action
    addItemToTarget = (...item: AudioMetadataUiModel[]) => {
        if (!this.TargetList.children) {
            this.TargetList.children = observable.array(
                item,
            ) as AudioMetadataUiModel[];
        } else {
            this.TargetList.children.push(...item);
        }

        delay(0).then((_) => {
            this.onAddToTargetList?.(...item);
        });
    };

    findTgtItem = async (
        fileName: string,
    ): Promise<AudioMetadataUiModel | undefined> => {
        return this.TargetList.children?.find((i) => i.fileName === fileName);
    };

    @action
    removeItemFromTarget = (item: AudioMetadataUiModel) => {
        this.TargetList.children?.splice(
            this.TargetList.children.findIndex(
                (x) => x.fileName === item.fileName,
            ),
            1,
        );
        delay(0).then((_) => {
            this.onRemoveFromTargetList?.(item);
        });
    };
}

export default createContext(new BlobDirectoryStore());
